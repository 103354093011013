import { Icon } from 'legacy-components/componets';
import { IconSVGName } from 'common/types/types';

export type NextStepsProps = {
    title: string;
    stepsList: StepConfig[];
};

export type StepConfig = {
    id: string;
    icon: IconSVGName;
    description: string;
}

const NextSteps = ({ title, stepsList }: NextStepsProps) => {
    return (
        <div>
            <div className='text-[16px] font-bold mb-5'>
                {title}
            </div>
            <div className='flex flex-col gap-3'>
                {stepsList.map(({ id, icon, description }) => (
                    <div key={id} className='flex flex-row items-center gap-3'>
                        <Icon width={14} height={14} name={icon} />
                        <div className='text-[14px]'>
                            {description}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export { NextSteps };
