import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { getPropertyAmenities } from 'services/api/property-api.service';
import { PropertyAmenitiesDto } from 'common/types/services/api/property/property-amenities-dto.type';

export default function usePropertyAmenities(
  options?: Omit<
    UndefinedInitialDataOptions<PropertyAmenitiesDto[], Error, PropertyAmenitiesDto[], string[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Estates, 'amenities'],
    queryFn: getPropertyAmenities,
    ...options,
  });
}
