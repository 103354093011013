import { AmenitiesIconName } from "common/types/components/ui/icon/amenities-icon.type";
import { FC, SVGProps } from "react";

import { ReactComponent as Refrigerator } from 'assets/icons/amenities-new-version/refrigerator.svg';
import { ReactComponent as Stove } from 'assets/icons/amenities-new-version/stove.svg';
import { ReactComponent as Microwave } from 'assets/icons/amenities-new-version/microwave.svg';
import { ReactComponent as DefaultAmenities } from 'assets/icons/amenities-new-version/default.svg';
import { ReactComponent as Oven } from 'assets/icons/amenities-new-version/oven.svg';
import { ReactComponent as Dishwasher } from 'assets/icons/amenities-new-version/dishwasher.svg';
import { ReactComponent as Cookware } from 'assets/icons/amenities-new-version/cookware.svg';
import { ReactComponent as MarbleFloor } from 'assets/icons/amenities-new-version/marbleFloor.svg';
import { ReactComponent as CarpetFloor } from 'assets/icons/amenities-new-version/carpetFloor.svg';
import { ReactComponent as Hardwood } from 'assets/icons/amenities-new-version/hardwood.svg';
import { ReactComponent as SmartThermostats } from 'assets/icons/amenities-new-version/smartThermostats.svg';
import { ReactComponent as CentralAC } from 'assets/icons/amenities-new-version/centralAC.svg';
import { ReactComponent as CentralHeating } from 'assets/icons/amenities-new-version/centralHeating.svg';
import { ReactComponent as Furnished } from 'assets/icons/amenities-new-version/furnished.svg';
import { ReactComponent as SemiFurnished } from 'assets/icons/amenities-new-version/semiFurnished.svg';
import { ReactComponent as Unfurnished } from 'assets/icons/amenities-new-version/unfurnished.svg';
import { ReactComponent as TV } from 'assets/icons/amenities-new-version/TV.svg';
import { ReactComponent as Internet } from 'assets/icons/amenities-new-version/internet.svg';
import { ReactComponent as DesignerLighting } from 'assets/icons/amenities-new-version/designerLighting.svg';
import { ReactComponent as SmartControlAppliances } from 'assets/icons/amenities-new-version/smartControlAppliances.svg';
import { ReactComponent as Fireplace } from 'assets/icons/amenities-new-version/fireplace.svg';
import { ReactComponent as OfficeRoom } from 'assets/icons/amenities-new-version/officeRoom.svg';
import { ReactComponent as Balcony } from 'assets/icons/amenities-new-version/balcony.svg';
import { ReactComponent as Window } from 'assets/icons/amenities-new-version/window.svg';
import { ReactComponent as Closet } from 'assets/icons/amenities-new-version/closet.svg';
import { ReactComponent as Wardrobe } from 'assets/icons/amenities-new-version/wardrobe.svg';
import { ReactComponent as SmartLEDLighting } from 'assets/icons/amenities-new-version/smartLEDLighting.svg';
import { ReactComponent as Shower } from 'assets/icons/amenities-new-version/shower.svg';
import { ReactComponent as SoakingTub } from 'assets/icons/amenities-new-version/soakingTub.svg';
import { ReactComponent as Jacuzzi } from 'assets/icons/amenities-new-version/jacuzzi.svg';
import { ReactComponent as Washer } from 'assets/icons/amenities-new-version/washer.svg';
import { ReactComponent as Dryer } from 'assets/icons/amenities-new-version/dryer.svg';
import { ReactComponent as Storage } from 'assets/icons/amenities-new-version/storage.svg';
import { ReactComponent as CoveredParking } from 'assets/icons/amenities-new-version/coveredParking.svg';
import { ReactComponent as StreetParking } from 'assets/icons/amenities-new-version/streetParking.svg';
import { ReactComponent as Garage } from 'assets/icons/amenities-new-version/garage.svg';
import { ReactComponent as KeyFob } from 'assets/icons/amenities-new-version/keyFob.svg';
import { ReactComponent as CCTV } from 'assets/icons/amenities-new-version/cctv.svg';
import { ReactComponent as SmartLock } from 'assets/icons/amenities-new-version/smartLock.svg';
import { ReactComponent as GymFitnessCenter } from 'assets/icons/amenities-new-version/gymFitnessCenter.svg';
import { ReactComponent as BBQStations } from 'assets/icons/amenities-new-version/bbqStations.svg';
import { ReactComponent as PlayArea } from 'assets/icons/amenities-new-version/playArea.svg';
import { ReactComponent as PetGarden } from 'assets/icons/amenities-new-version/petGarden.svg';
import { ReactComponent as Theater } from 'assets/icons/amenities-new-version/theater.svg';
import { ReactComponent as SpaRoom } from 'assets/icons/amenities-new-version/spaRoom.svg';
import { ReactComponent as MeditationRoom } from 'assets/icons/amenities-new-version/meditationRoom.svg';
import { ReactComponent as BasketballCourt } from 'assets/icons/amenities-new-version/basketballCourt.svg';
import { ReactComponent as VolleyballCourt } from 'assets/icons/amenities-new-version/volleyballCourt.svg';
import { ReactComponent as TennisCourt } from 'assets/icons/amenities-new-version/tennisCourt.svg';
import { ReactComponent as GolfArea } from 'assets/icons/amenities-new-version/golfArea.svg';
import { ReactComponent as GuestArea } from 'assets/icons/amenities-new-version/guestArea.svg';
import { ReactComponent as ConferenceRooms } from 'assets/icons/amenities-new-version/conferenceRooms.svg';
import { ReactComponent as Arcade } from 'assets/icons/amenities-new-version/arcade.svg';
import { ReactComponent as Courtyards } from 'assets/icons/amenities-new-version/courtyards.svg';
import { ReactComponent as SwimmingPool } from 'assets/icons/amenities-new-version/swimmingPool.svg';
import { ReactComponent as RooftopDeck } from 'assets/icons/amenities-new-version/rooftopDeck.svg';

export const amenitiesIconMapper: Record<AmenitiesIconName, FC<SVGProps<SVGSVGElement>>> = {
    default: DefaultAmenities,
    refrigerator: Refrigerator,
    stove: Stove,
    microwave: Microwave,
    oven: Oven,
    dishwasher: Dishwasher,
    cookware: Cookware,
    marbleFloor: MarbleFloor,
    carpetFloor: CarpetFloor,
    hardwood: Hardwood,
    smartThermostats: SmartThermostats,
    centralAC: CentralAC,
    centralHeating: CentralHeating,
    furnished: Furnished,
    semiFurnished: SemiFurnished,
    unfurnished: Unfurnished,
    TV: TV,
    "internet/Wi-Fi": Internet,
    designerLighting: DesignerLighting,
    smartControlAppliances: SmartControlAppliances,
    fireplace: Fireplace,
    officeRoom: OfficeRoom,
    balcony: Balcony,
    window: Window,
    closet: Closet,
    wardrobe: Wardrobe,
    smartLEDLighting: SmartLEDLighting,
    shower: Shower,
    soakingTub: SoakingTub,
    jacuzzi: Jacuzzi,
    washer: Washer,
    dryer: Dryer,
    storage: Storage,
    coveredParking: CoveredParking,
    streetParking: StreetParking,
    garage: Garage,
    keyFob: KeyFob,
    CCTV: CCTV,
    smartLock: SmartLock,
    gymFitnessCenter: GymFitnessCenter,
    BBQStations: BBQStations,
    playArea: PlayArea,
    petGarden: PetGarden,
    theater: Theater,
    spaRoom: SpaRoom,
    meditationRoom: MeditationRoom,
    basketballCourt: BasketballCourt,
    volleyballCourt: VolleyballCourt,
    tennisCourt: TennisCourt,
    golfArea: GolfArea,
    guestArea: GuestArea,
    conferenceRooms: ConferenceRooms,
    arcade: Arcade,
    courtyards: Courtyards,
    swimmingPool: SwimmingPool,
    rooftopDeck: RooftopDeck
}