import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getUserProfileValidation } from 'services/api/auth-api.service';
import { QueryKey } from 'common/enums/query-key';
import { UserValidationDto } from 'common/generated/types/user-validation-dto';

export default function useUserProfileValidation(
  options?: Omit<
    UndefinedInitialDataOptions<UserValidationDto, Error, UserValidationDto, string[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.User, 'profileValidation'],
    queryFn: () => getUserProfileValidation(),
    ...options,
  });
}
