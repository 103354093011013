import { EstateTypeBadge, LocationPin } from 'legacy-components/componets';

export type EstateInfoProps = {
  location: string;
  address: string;
  type: string;
};

const EstateInfo = ({ location, address, type }: EstateInfoProps) => {
  return (
    <section className='mb-[48px]'>
      <div className='location flex justify-between'>
        <div className='mr-2.5'>
          <div className='flex gap-4 pb-4'>
            <div className='text-[32px] font-bold text-black'>{location}</div>
            <EstateTypeBadge title={type} />
          </div>
          <div className='flex items-center text-base text-trueGray'>
            <LocationPin />
            <span className='pl-3'>{address}</span>
          </div>
        </div>
        <div className='link-wrapper flex gap-4'>
          {/* TODO: COMING SOON */}
          {/* <BookmarkIcon isShadow />
          <ShareIcon isShadow /> */}
        </div>
      </div>
    </section>
  );
};

export { EstateInfo };
