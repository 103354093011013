import { DropdownMenuItem } from './common/types/dropdown-menu-item';
import { generateIconComponentsProps } from 'helpers/components';
import { Icon } from 'legacy-components/ui/icon/icon';

import styles from './styles';
import clsx from 'clsx';
import { Divider } from 'legacy-components/componets';
import { Fragment } from 'react';

export type DropdownMenuProps = {
  menuItems: DropdownMenuItem[];
  headerTitle?: string;
  hasDivider?: boolean;
};

const DropdownMenu = ({ menuItems, headerTitle, hasDivider = false }: DropdownMenuProps) => {
  return (
    <div className={styles.container}>
      {headerTitle && <div className='flex-center font-semibold text-base'>{headerTitle}</div>}
      {menuItems.map(({ title, id, onClick, icon, additionalStyles }, index) => {
        const generatedIconProps = icon && Boolean(Object.keys(icon).length) && generateIconComponentsProps(icon);
        const dropdownStyles = additionalStyles ? clsx(styles.item, additionalStyles) : styles.item;

        return (
          <Fragment key={id}>
            <div className={dropdownStyles} onClick={onClick}>
              {generatedIconProps && <Icon {...generatedIconProps} />}
              <div className='w-full'>{title}</div>
            </div>
            {
              // last item shouldn`t have a divider
              hasDivider && index + 1 !== menuItems.length && <Divider />
            }
          </Fragment>
        );
      })}
    </div>
  );
};

export { DropdownMenu };
