import React, { Fragment, useMemo } from 'react';
import { maxItemPerPage } from './config';
import { ToursTitle } from '../upcoming-tours/components';
import { TourHistoryTable } from './tour-history-table/tour-history-table';
import { ToursTableRow } from './tour-history-table/common/types/tours-table-row';
import { getDayjs } from 'helpers/date';
import { Dropdown, DropdownMenu } from 'legacy-components/componets';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { TablePagination } from 'legacy-components/ui/table/table-pagination';
import { useNavigate } from 'react-router-dom';
import {
  createEnumDelimitedArrayParam,
  createEnumParam,
  NumberParam,
  QueryParamConfig,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import SortOrderEnumParam from 'query-params/sortOrderEnumParam';
import { TourStatusDto } from 'common/enums/services/api/tours/tour-status-dto.enum';
import { SortOrder } from 'common/types/sort-order';
import { EstateTypeDto } from 'common/enums/enums';
import { useToursHistory } from 'hooks/query';
import { formatPriceValue } from 'helpers/string.helpers';
import { RenterTourHistoryFilters } from 'components/organisms/Filters/RenterTourHistoryFilters';
import defaultEstatePhoto from 'assets/images/default_estate_image.png';
import { isNotEmptyString } from 'common/utils/check-empty-string';

export type ToursHistoryQueryParamConfig = {
  sortBy: QueryParamConfig<'date'>;
  order: QueryParamConfig<SortOrder>;
  tourStatuses: QueryParamConfig<`${TourStatusDto}`[]>;
  page: QueryParamConfig<number>;
};

const HistoryTours = () => {
  const queryParamConfig = useMemo<ToursHistoryQueryParamConfig>(
    () => ({
      sortBy: withDefault(createEnumParam(['date']), 'date'),
      order: withDefault(SortOrderEnumParam, 'desc'),
      tourStatuses: withDefault(
        createEnumDelimitedArrayParam(Object.values(TourStatusDto) as `${TourStatusDto}`[], ','),
        [],
      ),
      page: withDefault(NumberParam, 1),
    }),
    [],
  );
  const [query, setQuery] = useQueryParams(queryParamConfig);
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  const { data, isLoading, isFetching } = useToursHistory(
    {
      page: query.page,
      perPage: maxItemPerPage,
      filters: { tourStatus: query.tourStatuses },
      sorters: { slot: query.order },
    },
    {
      placeholderData: (previousValue) => previousValue,
    },
  );
  const { totalCount, items: tours } = data || { totalCount: 0, items: [] };

  const paginationCallback = (newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  };

  const tableData = React.useMemo<ToursTableRow[]>(() => {
    return tours.map((tour) => {
      return {
        date: new Date(tour.slot),
        property: {
          city: tour.city,
          address: tour.location,
          price: tour.price,
          image: tour.thumbnailUrl,
          unitNumber: tour.unitNumber,
          isMulti: tour.estateType === EstateTypeDto.MultiUnit,
        },
        actions: {
          estateId: tour.estateId,
          tourId: tour?.id,
        },
        status: {
          tourStatus: tour.tourStatus,
        },
      };
    });
  }, [tours]);

  const paginationProps = {
    currentPage: query.page,
    totalCount,
    pageSize: maxItemPerPage,
    onPageChange: paginationCallback,
  };
  const tableProps = {
    isLoading,
    data: tableData,
    paginationProps: {
      currentPage: query.page,
      totalCount,
      pageSize: maxItemPerPage,
      onPageChange: paginationCallback,
    },
  };

  const propertyCard = (el: ToursTableRow, key: number) => {
    const imageSrc = isNotEmptyString(el?.property?.image) ? el?.property?.image : defaultEstatePhoto;
    return (
      <Fragment key={key}>
        <div className='shadow-lg rounded-lg flex flex-col relative'>
          <Dropdown
            target={
              <button
                id='dropdownButton'
                data-dropdown-toggle='dropdown'
                className='inline-block text-gray-500 hover:bg-gray-100 focus:outline-none rounded-lg text-sm p-1.5 absolute bg-white opacity-80 top-2 right-2'
                type='button'
              >
                <svg
                  className='w-6 h-6'
                  aria-hidden='true'
                  fill='black'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z'></path>
                </svg>
              </button>
            }
            menu={
              <DropdownMenu
                key={el.date.toString()}
                menuItems={[
                  {
                    id: 1,
                    title: 'View property profile',
                    onClick: () => navigate(`/estates/${el.actions.estateId}`),
                    icon: 'house',
                  },
                  {
                    id: 2,
                    title: 'View details',
                    onClick: () => navigate(`/i-rent/profile/tours/history/${el.actions.estateId}`),
                    icon: 'faEye',
                  },
                ]}
                headerTitle={'Property'}
              />
            }
          />
          <div className='w-full h-40 flex-shrink-0 mr-3 rounded-t-lg overflow-hidden'>
            <img src={imageSrc} alt='property' className='w-full h-full object-cover' />
          </div>

          <div className='text-amber-500 text-xs font-medium leading-tight px-[10px] py-[2px] bg-amber-50 rounded-3xl absolute top-2 left-2'>
            {el.status.tourStatus}
          </div>

          <div className='p-3'>
            <div className='flex justify-between mb-2'>
              <div className='price flex justify-start items-center'>
                <p className='text-primaryDark text-lg font-bold'>${formatPriceValue(el.property.price)}</p>
                <p className='text-sm font-normal text-trueGray ml-[5px]'>/mo</p>
              </div>
            </div>
            <div className='font-bold mb-2'>{getDayjs(el.date).format('MMMM D, hh:mm A').toString()}</div>
            <div className='text'>{el.property.address}</div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <div className='grow p-4 md:px-[34px] md:py-[28px] max-w-full overflow-x-auto shadow-white-xl'>
      <div className='lg:mb-9 mb-2 flex items-center justify-between'>
        <div className='title text-xl font-bold'>Tour History</div>
        <RenterTourHistoryFilters />
      </div>
      <div className='flex flex-col gap-9'>
        {isMobile ? (
          <div>
            <div className='flex flex-col gap-4 mb-4'>
              {tableProps.data.map((el, index) => propertyCard(el, index))}
            </div>
            <TablePagination {...paginationProps} />
          </div>
        ) : (
          <TourHistoryTable
            isLoading={isLoading || isFetching}
            data={tableData}
            order={query.order}
            paginationProps={paginationProps}
            tourStatuses={query.tourStatuses}
            setQuery={setQuery}
          />
        )}
      </div>
    </div>
  );
};
export { HistoryTours };
