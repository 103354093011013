import React from 'react';
import { FormikProps } from 'formik';
import { Button, GoogleMap, Input, Label, PlaceAutocomplete } from 'legacy-components/componets';
import { categoryList, unitList } from './config';
import { Icon } from 'legacy-components/ui/icon/icon';
import { PropertyCategory, PropertyUnitType } from 'common/enums/app/property.enum';
import { IconSVGName } from 'common/types/types';
import { GooglePlaceDetails, SelectedLocation } from 'legacy-components/fields/place-autocomplete/place-autocomplete';
import { PropertyValues } from 'legacy-pages/landlord/properties/landlord-properties';
import { useParams } from 'react-router';
import { stringAsIntegerNumber } from 'helpers/input-field.helper';
import { useIsMutating } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { EstateStatusDto } from 'common/enums/enums';

export interface ITileProps {
  id: string | number;
  title: string;
  icon: IconSVGName;
  isActive?: boolean;
  onClick: () => void;
}

export interface IPropertyBasicValues {
  type: PropertyUnitType;
  unitCount: number;
  category: PropertyCategory;
  location: SelectedLocation;
}

export interface IPropertyBasicsProps
  extends Pick<
    FormikProps<PropertyValues>,
    'values' | 'setFieldValue' | 'errors' | 'touched' | 'submitForm' | 'setFieldTouched'
  > {
  stepTitle?: string;
  setGooglePlaceDetails: (place: GooglePlaceDetails | null) => void;
  // AX-609
  estateStatus: EstateStatusDto | null;
}

const PropertyBasics = ({
  stepTitle,
  values,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  submitForm,
  setGooglePlaceDetails,
  // AX-609
  estateStatus,
}: IPropertyBasicsProps) => {
  const { id } = useParams();
  const isLoading = useIsMutating({ mutationKey: [QueryKey.PropertyAddress] }) > 0;

  const getTile = ({ id, title, icon, isActive, onClick }: ITileProps) => {
    return (
      <div
        key={id}
        onClick={onClick}
        className={`unit-item flex-center w-[200px] h-[100px] text-sm font-medium cursor-pointer
                ${isActive ? 'bg-secondary text-primaryDark border-0' : 'bg-white text-black border border-gray'}
                 ${estateStatus === EstateStatusDto.Available ? 'pointer-events-none' : ''}`}
      >
        <div className='flex flex-col items-center gap-2'>
          <Icon name={icon} height={33} />
          <div className='unit-title'>{title}</div>
        </div>
      </div>
    );
  };

  const getCategory = ({ id, title, icon, isActive, onClick }: ITileProps) => {
    return (
      <div
        key={id}
        onClick={onClick}
        className={`unit-item w-[90px] py-6 flex-center text-center text-sm font-medium cursor-pointer
                ${isActive ? 'text-primaryDark' : 'text-black'}`}
      >
        <div className='flex flex-col items-center gap-2'>
          <Icon name={icon} height={33} />
          <div className='unit-title'>{title}</div>
        </div>
      </div>
    );
  };

  const basicsPropertyTitle = (() => {
    return (
      <div className='title flex flex-col gap-3'>
        {stepTitle && <div className='text-primaryDark uppercase font-semibold'>{stepTitle}</div>}
        <div className='title text-xl font-medium'>Property basics</div>
        <div className='description text-trueGray'>
          Please start filling out your application by filling in your property information below.
        </div>
      </div>
    );
  })();

  const basicsPropertyUnitTiles = (() => {
    const handleClick = (id: PropertyUnitType) => {
      setFieldValue('details.units', JSON.parse(JSON.stringify([values.details.units[0]])));
      setFieldValue('basics.type', id);
      if (id === PropertyUnitType.Single) {
        setFieldValue('basics.unitCount', 1);
      }
      if (id === PropertyUnitType.Multiple) {
        setFieldValue('basics.unitCount', 2);
      }
    };
    return (
      <div className='unit-type flex gap-6'>
        {unitList.map(({ id, title, icon }) => {
          const isActive = id === values.basics.type;
          const onClick = () => handleClick(id);
          return getTile({ id, title, icon, isActive, onClick });
        })}
      </div>
    );
  })();

  const basicsPropertyCategoryTiles = (() => {
    return (
      <div className='category flex justify-between items-center gap-6 px-0 md:px-[50px] max-w-[700px]'>
        {categoryList.map(({ id, title, icon }) => {
          const isActive = id === values.basics.category;
          const onClick = () => setFieldValue('basics.category', id);
          return getCategory({ id, title, icon, isActive, onClick });
        })}
      </div>
    );
  })();

  const basicsPropertyPlaceAutocomplete = (() => {
    return (
      <div className='place-autocomplete'>
        <Label label={'Address'}>
          <PlaceAutocomplete
            mode={id ? 'edit' : 'create'}
            value={values.basics.location.address}
            setValue={(location) => {
              setFieldValue('basics.location', location);
            }}
            setGooglePlaceDetails={setGooglePlaceDetails}
            fieldName={'location'}
            invalid={Boolean(errors.basics?.location?.address && touched.basics?.location?.address)}
            // AX-609
            disabled={estateStatus === EstateStatusDto.Available}
          />
        </Label>
        {errors.basics?.location?.address && touched.basics?.location?.address && (
          <div className='text-red-500 text-sm text-red font-medium mt-2'>{errors.basics?.location?.address}</div>
        )}
      </div>
    );
  })();

  const basicsPropertyMap = React.useMemo(() => {
    return (
      <div className='basics-property-map flex flex-col gap-2'>
        <p className='text-xs text-trueGray font-bold'>Where is your place located?</p>
        <GoogleMap
          location={{ lng: values.basics.location.lng, lat: values.basics.location.lat }}
          width={'100%'}
          height={296}
        />
        {/* <Button
          label='Confirm location pin'
          theme='secondary'
          stylesConfig={{ width: 200, borderRadius: 8 }}
          onClick={handleConfirmLocationPin}
        /> */}
      </div>
    );
  }, [values]);

  const basicsPropertySubmitButton = (() => {
    return (
      <div className='button-wrapper flex justify-end'>
        <Button isLoading={isLoading} label={'Save and continue'} theme={'primary'} onClick={submitForm} />
      </div>
    );
  })();

  return (
    <>
      <div className='property-basics flex flex-col gap-9'>
        {basicsPropertyTitle}
        {basicsPropertyUnitTiles}
        {basicsPropertyCategoryTiles}
        {values.basics.type === PropertyUnitType.Multiple && (
          <div className='units-select-buttons flex flex-col gap-9'>
            <Label label={'How many units does your property have?'}>
              <Input
                value={values.basics.unitCount}
                name={'unitCount'}
                placeholder='Unit count'
                invalid={Boolean(errors.basics?.unitCount && touched.basics?.unitCount)}
                onChange={(e) => {
                  setFieldTouched(`basics.unitCount`);
                  stringAsIntegerNumber(e, `basics.unitCount`, setFieldValue);
                }}
                // AX-609
                disabled={estateStatus === EstateStatusDto.Available}
              />
              {Boolean(errors.basics?.unitCount && touched.basics?.unitCount) && (
                <p className='text-sm text-error py-1'>{errors.basics?.unitCount}</p>
              )}
            </Label>
          </div>
        )}
        {basicsPropertyPlaceAutocomplete}
        {basicsPropertyMap}
        {basicsPropertySubmitButton}
      </div>
      {/* <ProgressBar progressPercentage={progressValue} /> */}
    </>
  );
};

export { PropertyBasics };
