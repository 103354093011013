export enum TourStatusDto {
  Reserved = 'Reserved',
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Finished = 'Finished',
  Skipped = 'Skipped',
  Expired = 'Expired',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  PendingPayment = 'PendingPayment',
}

export enum PaidTourStatusDto {
  Scheduled = 'Scheduled',
  InProgress = 'InProgress',
  Finished = 'Finished',
  Skipped = 'Skipped',
  Expired = 'Expired',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
}

export enum TourHistoryTourStatusDto {
  Finished = 'Finished',
  Skipped = 'Skipped',
  Expired = 'Expired',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
}
