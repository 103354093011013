import { useState } from 'react';
import { DateValidationError } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getDayjs, getDate } from 'helpers/date';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-fill.svg';

type Props = {
  disabled?: boolean
  name: string;
  invalid?: boolean;
  minDate?: Date | null | string;
  maxDate?: Date | null | string;
  placeholder?: string;
  value: Date | null | string;
  onChange: (value: Date | null) => void;
};

const CustomDatePicker = ({ name, value, invalid, minDate, maxDate, placeholder = 'MM/DD/YYYY', onChange, disabled = false }: Props) => {
  const [dateError, setDateError] = useState<DateValidationError | null>(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={`${!disabled ? 'mui-datepicker' : ''}`} aria-label='Select date of birth'>
        <DatePicker
          value={getDayjs(value)}
          minDate={minDate ? getDayjs(minDate) : null}
          maxDate={maxDate ? getDayjs(maxDate) : null}
          onChange={(value) => onChange(getDate(value))}
          slots={{ openPickerIcon: CalendarIcon }}
          onError={(err) => setDateError(err)}
          slotProps={{
            textField: {
              name,
              placeholder,
              size: 'small',
              fullWidth: true,
              sx: { input: { color: !!value ? 'rgb(34 34 34)' : '#9fa3bc' } },
              InputProps: {
                startAdornment: '',
              },
              error: invalid || (!!dateError && !!value) || false,
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
};
export { CustomDatePicker };
