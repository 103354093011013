import React, { Children, cloneElement, useState } from 'react';
import { twMerge } from 'tailwind-merge';

type OptionProps = {
  name?: string;
  label: string;
  value: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  withIcon?: boolean;
  className?: string;
  checkedClassName?: string;
};

const Option: React.FC<OptionProps> = ({
  name,
  label,
  checked,
  onChange,
  withIcon = false,
  className,
  checkedClassName,
}) => {
  return (
    <label
      className={twMerge(
        'px-8 py-3 rounded-lg border border-sky-300 justify-center items-center gap-2.5 inline-flex cursor-pointer select-none transition-colors text-justify text-sky-300 text-[14px] font-bold leading-tight transition-colors',
        className,
        withIcon && 'justify-start',
        checked && 'bg-primary text-white',
        checked && !!checkedClassName && checkedClassName,
      )}
    >
      <input type='radio' hidden name={name} checked={checked} onChange={onChange} />
      {withIcon && (
        <div
          className={twMerge(
            'w-6 h-6 rounded-full border-primary relative',
            checked &&
              'bg-white before:absolute before:left-1/2 before:top-1/2 before:w-2 before:h-3 before:-translate-x-1/2 before:-translate-y-1/2 before:rotate-45 before:border-primary before:border-r-2 before:border-b-2',
          )}
        ></div>
      )}
      <span>{label}</span>
    </label>
  );
};

type RadioGroupProps = {
  value?: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  className?: string;
  children: React.ReactElement<OptionProps>[];
};

const RadioGroup = ({ value: valueProp, onChange, name, className, children }: RadioGroupProps) => {
  const [value, setValue] = useState(valueProp);
  const valueState = valueProp ? valueProp : value;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setValue(value);
    onChange?.(event, value);
  };

  return (
    <div className={twMerge('flex flex-wrap', className)}>
      {Children.map(children, (child) =>
        cloneElement(child, {
          checked: valueState === child.props.value,
          name,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, child.props.value),
        }),
      )}
    </div>
  );
};

RadioGroup.Option = Option;

export default RadioGroup;
