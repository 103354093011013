const styles = {
  container: `
            flex
            flex-col
            gap-3
            py-6 
            bg-white 
            w-[277px] 
            rounded-lg 
            shadow-blue 
            overflow-hidden`,
  item: `
            px-6 
            py-3.5 
            text-base
            text-black 
            hover:text-primary 
            hover:font-bold 
            hover:border-r-2 
            hover:border-r-primary 
            hover:bg-secondary 
            cursor-pointer`,
};

export default styles;
