import React, { useEffect, useState } from 'react';
import Cropper, { CropperProps } from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import getCroppedImg from './helpers/image-crop.helper';
import { Button, Icon, Slider, UploadFiles } from 'legacy-components/componets';
import { uploadFileConfig } from 'legacy-components/fields/upload-files/config';

export type CropImageProps = {
  title?: string;
  onCrop: (file: File) => void;
  onClose: () => void;
  styles?: React.CSSProperties;
};

const CropImage = ({ title = '', styles, onClose, onCrop }: CropImageProps) => {
  //TODO: ad to props
  const minZoom = 1;
  const maxZoom = 5;
  const defaultZoom = 2;
  const stepZoom = 0.1;

  const minRotate = 0;
  const maxRotate = 360;
  const stepRotate = 5;

  const [isImagePrepared, setIsImagePrepared] = React.useState<boolean>(false);
  const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 });
  const [rotation, setRotation] = React.useState<number>(minRotate);
  const [zoom, setZoom] = React.useState<number>(defaultZoom);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [imageUrl, setImageUrl] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);

  const onCropComplete: CropperProps['onCropComplete'] = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCancel = () => {
    removeImage();
    onClose();
  };

  const handleDone = async () => {
    try {
      setIsImagePrepared(true);
      const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels, rotation);
      onCrop(croppedImage);
      onClose();
    } finally {
      setIsImagePrepared(false);
    }
  };

  const removeImage = () => {
    setImageUrl('');
    setImageFile(null);
  };

  const addImage = (image: File) => {
    setImageFile(image);
    setImageUrl(URL.createObjectURL(image));
  };

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, [imageUrl]);

  return (
    <div
      className={'max-w-[544px] w-full bg-white rounded-[15px] px-6 py-8 relative font-manrope'}
      style={{ ...styles }}
    >
      <div className={'flex justify-between items-center'}>
        <div className={'text-2xl font-medium text-black'}>{title}</div>
        <Icon name={'times-circle'} width={20} height={20} onClick={handleCancel} />
      </div>
      {imageFile ? (
        <>
          <Cropper
            style={{
              containerStyle: {
                position: 'relative',
                height: 218,
                margin: 20,
                borderRadius: 12,
              },
            }}
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={16 / 9}
            cropShape={'round'}
            cropSize={{ width: 170, height: 170 }}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
          />
          <div className={'flex-center gap-9 mb-5'}>
            <div className={'flex-center gap-2'}>
              <Icon name={'search-zoom'} />
              <Slider
                value={zoom}
                min={minZoom}
                max={maxZoom}
                step={stepZoom}
                onChange={(e) => setZoom(Number(e.target.value))}
              />
              <div className={'w-10 text-xs text-trueGray'}>{zoom.toFixed(1)}</div>
            </div>
            <div className={'flex-center gap-2'}>
              <Icon name={'rotate-right'} />
              <Slider
                value={rotation}
                min={minRotate}
                max={maxRotate}
                step={stepRotate}
                onChange={(e) => setRotation(Number(e.target.value))}
              />
              <div className={'w-10 text-xs text-trueGray'}>{rotation}</div>
            </div>
          </div>
        </>
      ) : (
        <UploadFiles
          title={uploadFileConfig.images.title}
          accept={uploadFileConfig.images.accept}
          maxFiles={1}
          onDrop={(files) => {
            addImage(files[0]);
          }}
          stylesConfig={{ height: 218, borderRadius: 8, margin: 20, marginBottom: 57 }}
        />
      )}
      <div className={'flex flex-col sm:flex-row items-center gap-6'}>
        <Button onClick={handleCancel} label={'Cancel'} theme={'primary-outline'} isFullWidth />
        {imageFile && (
          <Button
            onClick={handleDone}
            label={isImagePrepared ? 'Loading...' : 'Done'}
            isLoading={isImagePrepared}
            disabled={isImagePrepared}
            isFullWidth
          />
        )}
      </div>
    </div>
  );
};

export { CropImage };
