import React from 'react';
import ReactSelect from 'react-select';
import { SelectProps } from 'common/types/types';

import styles from './styles';

const Select = ({
  name,
  options,
  value,
  defaultValue,
  label,
  placeholder,
  theme = 'default',
  disabled,
  invalid,
  selectStyle,
  labelStyle,
  onChange,
  isSearchable = false,
  selectRef,
  selectDropdownRef,
}: SelectProps) => {
  const selectedValue = React.useMemo(() => {
    if (options) {
      const selectedElement = options.find((option) => option.value?.toString() === value?.toString());
      return selectedElement && selectedElement?.value !== '' && selectedElement;
    }

    return null;
  }, [value, options]);

  const handleChange = (option: any) => {
    onChange(option?.value);
  };

  return (
    <div ref={selectRef}>
      {label && (
        <div className={styles.label[theme]} style={labelStyle}>
          {label}
        </div>
      )}
      <ReactSelect
        ref={selectDropdownRef}
        name={name}
        options={options}
        defaultValue={defaultValue}
        styles={styles.select(theme, invalid, selectStyle)}
        onChange={handleChange}
        value={selectedValue}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isDisabled={disabled}
      />
    </div>
  );
};

export { Select };
