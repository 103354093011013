import * as Yup from 'yup';
import { emailValidation } from './email.validation';

export const helpSupportForNotAuthUserValidationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    email: emailValidation.max(100, 'Email cannot exceed 100 characters'),
    profile: Yup.string().required('Profile type is required'),
    message: Yup.string().required('Feedback is required').max(250, 'Feedback cannot exceed 250 characters'),
});

export const helpSupportForAuthUserValidationSchema = Yup.object({
    message: Yup.string().required('Feedback is required').max(250, 'Feedback cannot exceed 250 characters'),
});