import { Button, Icon, Modal } from 'legacy-components/componets';
import { CANCEL_RESCHEDULE_TOUR, MODAL_DESCRIPTION, MODAL_TITLE } from './PaymentInProgress.const';
import { PaymentInProgressProps } from './PaymentInProgress.types';

export const PaymentInProgressModal: React.FC<PaymentInProgressProps> = ({ open, onClose }) => {
  return (
    <Modal
      isOpen={open}
      styles={{
        content: {
          padding: '25px',
          maxHeight: '100%',
          maxWidth: '550px',
        },
      }}
      onClose={onClose}
    >
      <div className='flex flex-col gap-3'>
        <div className='flex-grow flex items-center justify-center'>
          <Icon name='payment-in-progress' />
        </div>
        <div className='flex flex-col gap-3'>
          <div className='text-xl text-center font-semibold'>{MODAL_TITLE}</div>
          <div className='text-[#87898E] text-base text-center'>{MODAL_DESCRIPTION}</div>
        </div>
        <div className='flex justify-center items-center space-x-4 gap-4'>
          <Button label={CANCEL_RESCHEDULE_TOUR} onClick={onClose} isFullWidth />
        </div>
      </div>
    </Modal>
  );
};
