import { useFormik } from 'formik';
import { Button, Modal, UploadFiles } from 'legacy-components/componets';
import React, { useState } from 'react';
import { useWindowSize } from 'hooks';
import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  EyeOutlined,
} from '@ant-design/icons';
import { twMerge } from 'tailwind-merge';
import {
  DocumentsVerificationFormValues,
  DocumentsVerificationModalProps,
  OfferFile,
  UploadedFileCardProps,
} from './documents-verification-modal.types';
import { validationSchema } from './validationSchema';
import { DocumentViewerDrawer } from 'components/organisms/DocumentViewer/DocumentViewer';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import { uploadFileConfig } from 'legacy-components/fields/upload-files/config';

export const OfferDocumentsVerificationModal = ({
  onClose,
  open,
  styles,
  onSubmit,
  onGoBack,
  onDeleteDocument,
  initialDocumentsProps,
}: DocumentsVerificationModalProps) => {
  const { isMobile } = useWindowSize();
  // const studentIdInputRef = React.useRef<HTMLInputElement>(null);
  const [openPreviewModal, togglePreviewModal] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState<File | string>('');

  const { values, errors, handleSubmit, setFieldValue, isValid, resetForm, isSubmitting, setValues } =
    useFormik<DocumentsVerificationFormValues>({
      initialValues: { studentId: null, bankStatements: [] },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        await onSubmit(values);
        resetForm();
      },
    });

  const previewDocument = (fileUrl: string | File) => {
    setPreviewFileUrl(fileUrl);
    togglePreviewModal(true);
  };

  const onClosePreviewModal = () => {
    togglePreviewModal(false);
    setPreviewFileUrl('');
  };

  React.useEffect(() => {
    if (initialDocumentsProps) {
      setValues({ ...initialDocumentsProps });
    }
  }, [initialDocumentsProps]);

  return (
    <Modal
      isOpen={open}
      styles={{ ...styles, content: { ...styles?.content, maxHeight: '100%', ...(isMobile && { height: '100%' }) } }}
    >
      <DocumentViewerDrawer
        open={openPreviewModal}
        onClose={onClosePreviewModal}
        file={previewFileUrl}
        title={'Document Verification Preview'}
      />
      <form
        className='p-4 bg-white rounded-lg shadow max-w-screen-md w-screen flex flex-col gap-3 sm:gap-6 md:gap-9 h-full'
        onSubmit={handleSubmit}
      >
        <div className='flex justify-between items-center'>
          <button onClick={onGoBack} type={'button'}>
            <ArrowLeftOutlined className={'text-primary text-[24px]'} />
          </button>
          <h3 className='text-xl whitespace-nowrap text-center text-neutral-800 text-[40px] font-semibold leading-10'>
            Documents Verification
          </h3>
          <button onClick={onClose} type={'button'}>
            <CloseOutlined className={'text-primary text-[24px]'} />
          </button>
        </div>
        <div className='text-center'>
          <span className='text-sky-400 text-lg font-bold leading-[27px]'>
            Please upload proof of funds <br />
          </span>
        </div>
        <div className={'flex flex-col gap-3'}>
          <div className='px-4 md:px-8 py-8 bg-sky-50 rounded-xl flex-col justify-start items-center gap-3 flex'>
            <span className='w-[364.12px] max-w-full text-center text-sky-300 text-sm font-bold'>
              Upload recent Bank Statement or Pay Stub or Job Offer Letter or Guarantor's Letter
            </span>
            <UploadFiles
              multiple
              name={'bankStatements'}
              title={uploadFileConfig.images_and_pdf.title}
              accept={uploadFileConfig.images_and_pdf.accept}
              onDrop={(files) => {
                setFieldValue('bankStatements', [...values.bankStatements, ...Array.from(files)]);
              }}
              invalid={Boolean(errors?.bankStatements)}
            />
          </div>
          {values.bankStatements.length > 0 && (
            <div className='flex flex-col gap-3'>
              {values.bankStatements.map((file, index) => (
                <UploadedFileCard
                  key={index}
                  error={Array.isArray(errors.bankStatements) ? (errors?.bankStatements?.[index] as string) : undefined}
                  file={file}
                  onRemove={async () => {
                    await onDeleteDocument?.(file);
                    setFieldValue(
                      'bankStatements',
                      // @ts-ignore
                      values.bankStatements.filter((_: File | OfferFile, i: number) => i !== index),
                    );
                  }}
                  previewDocument={previewDocument}
                  // if file is invalid, we do not show preview
                  canPreviewDoc={!isNotEmptyString(errors.bankStatements?.[index] as string)}
                />
              ))}
            </div>
          )}
          {!Array.isArray(errors.bankStatements) && (
            <p className='text-red-500 text-[16px] font-normal leading-none mt-2'>{errors.bankStatements as string}</p>
          )}
          {/*<div className='border border-sky-50'></div>*/}
          {/*{values.studentId ? (*/}
          {/*  <UploadedFileCard file={values.studentId} onRemove={() => setFieldValue('studentId', null)} />*/}
          {/*) : (*/}
          {/*  <div className='p-4 md:p-8 bg-sky-50 rounded-xl gap-4 flex items-stretch'>*/}
          {/*    <div className='w-[61.19px] h-[61.19px] p-[15.19px] bg-sky-300'></div>*/}
          {/*    <div className='flex-col gap-[5px] flex grow-[2]'>*/}
          {/*      <div className='text-sky-600 text-xl font-bold leading-normal'>Student ID</div>*/}
          {/*      <div className='grow shrink basis-0 text-neutral-500 text-sm font-normal'>Optional</div>*/}
          {/*    </div>*/}
          {/*    <div className={'grow-[1]'}>*/}
          {/*      <input*/}
          {/*        type='file'*/}
          {/*        hidden*/}
          {/*        ref={studentIdInputRef}*/}
          {/*        name={'studentId'}*/}
          {/*        accept={'application/pdf'}*/}
          {/*        onChange={(event) => {*/}
          {/*          const { files } = event.target;*/}
          {/*          if (!files) return;*/}
          {/*          const file = files[0];*/}
          {/*          setFieldValue('studentId', file);*/}
          {/*        }}*/}
          {/*      />*/}
          {/*      <Button*/}
          {/*        label={'Upload'}*/}
          {/*        className={'h-full w-full'}*/}
          {/*        onClick={() => {*/}
          {/*          studentIdInputRef.current?.click();*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
        <Button
          label={'Submit Documents'}
          type={'submit'}
          className={'h-[66px] px-8 py-3'}
          startIcon={<CheckOutlined className={'text-[18px]'} />}
          isFullWidth
          isLoading={isSubmitting}
          disabled={!isValid}
        />
      </form>
    </Modal>
  );
};

const UploadedFileCard = ({
  file,
  onRemove,
  error,
  previewDocument,
  canPreviewDoc: previewDoc,
}: UploadedFileCardProps) => {
  return (
    <div>
      <div
        className={twMerge(
          'p-4 md:p-8 bg-sky-50 rounded-xl justify-between items-center gap-4 flex',
          error && 'border-red-500 border',
        )}
      >
        <CheckCircleFilled className={'text-lime-300 text-[62px]'} />
        <div className='text-sky-600 text-xl font-bold leading-normal grow break-all'>{file.name}</div>
        {previewDoc && (
          <button
            onClick={() => {
              if ('fileUrl' in file) previewDocument(file?.fileUrl);
              else previewDocument(file);
            }}
            type={'button'}
          >
            <EyeOutlined className={'text-primary text-[24px]'} />
          </button>
        )}
        <button onClick={onRemove} type={'button'}>
          <DeleteFilled className={'text-primary text-[24px]'} />
        </button>
      </div>
      {error && <p className='text-red-500 text-[14px] font-normal leading-none mt-2'>{error}</p>}
    </div>
  );
};
