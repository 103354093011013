import { Table } from 'legacy-components/componets';
import { ApplicationsTableRow } from 'legacy-pages/landlord/profile/offers/common/types/applications-table-row';
import { columns } from './config';

export type EstatesApplicationsTableProps = {
  data: ApplicationsTableRow[];
  isLoading: boolean;
};

const EstatesApplicationsTable = ({ data, isLoading }: EstatesApplicationsTableProps) => {
  return (
    <Table
      data={data}
      columns={columns}
      title={'Applications'}
      isLoading={isLoading}
      styleConfig={{
        table: {
          minHeight: '200px',
        },
        mainContainer: {
          dataIsNotExist: {
            minHeight: '200px',
            gap: '50px',
          },
          dataExists: {
            gap: '50px',
            minHeight: 'auto',
          },
        },
      }}
    />
  );
};

export { EstatesApplicationsTable };
