import { FC } from 'react';
import { OfferStatusProps } from './OfferStatus.types';
import { commonStatusStyle, styles, textStyle } from './OfferStatus.styles';
import clsx from 'clsx';
import { offerStatusMap } from 'common/enums/services/api/offers/offer-status-dto.enum';

export const OfferStatus: FC<OfferStatusProps> = ({ offerStatus, size }) => {
  const statusStyle = styles[offerStatus];
  const textStatusStyle = size ? textStyle(size) : 'text-xs md:text-sm lg:text-[15px]';
  const combinedStyle = clsx(statusStyle, commonStatusStyle, textStatusStyle);
  return (
    <div className={combinedStyle}>
      <span>{`${offerStatusMap[offerStatus]}`}</span>
    </div>
  );
};
