import { Fragment } from 'react';
import { AdminProfileMobileProps } from './AdminProfileListing.types';
import { Dropdown } from 'legacy-components/ui/dropdown/dropdown';
import { DropdownMenu } from 'legacy-components/dropdown-menu/dropdown-menu';
import { dateFormatMMMM_DD, dateTimeFormatHHMM_A, getDayjs } from 'helpers/date';
import { AdminEstateTableAccessorEnum } from './AdminProfileListingTable/AdminProfileListingTable.types';
import { EstateCardCategoryBadge } from 'components/organisms/EstateCard/EstateCardCategoryBadge';
import { EstateCategoryDto } from 'common/enums/enums';

export const AdminEstateMobileCard: React.FC<AdminProfileMobileProps> = ({ el, key }) => {
  return (
    <Fragment key={key}>
      <div className='shadow-lg rounded-lg flex flex-col relative'>
        <Dropdown
          target={
            <button
              id='dropdownButton'
              data-dropdown-toggle='dropdown'
              className='inline-block text-gray-500 hover:bg-gray-100 focus:outline-none rounded-lg text-sm p-1.5 absolute bg-white opacity-80 top-2 right-2'
              type='button'
            >
              <svg
                className='w-6 h-6'
                aria-hidden='true'
                fill='black'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z'></path>
              </svg>
            </button>
          }
          menu={
            <DropdownMenu
              key={el.date.toString()}
              menuItems={[
                {
                  id: 1,
                  title: 'View/edit details',
                  onClick: () => el.actions.onView(),
                  icon: 'house',
                },
                {
                  id: 2,
                  title: 'Approve',
                  onClick: () => el.actions.onApprove(),
                  icon: 'check',
                },
                {
                  id: 3,
                  title: 'Reject',
                  onClick: () => el.actions.onCancel(),
                  icon: 'shape',
                },
              ]}
              headerTitle={'Saved Search'}
            />
          }
        />
        <div className='p-3 flex flex-col gap-3'>
          <div className='flex justify-between mb-2'>
            <div className='price flex justify-start items-center'>
              <p className='text-primaryDark text-lg font-bold'>{el?.[AdminEstateTableAccessorEnum.Landlord]}</p>
            </div>
          </div>
          <div className='grid grid-cols-2'>
            <div className='text-trueGray'>Submitted date: </div>
            <div className='font-bold'>
              <span>{`${dateFormatMMMM_DD(el?.[AdminEstateTableAccessorEnum.Date])},`}</span>
              <span>{dateTimeFormatHHMM_A(el?.[AdminEstateTableAccessorEnum.Date])}</span>
            </div>
          </div>
          <div>{el?.[AdminEstateTableAccessorEnum.Location]}</div>

          <div className='flex flex-row gap-4'>
            <div
              className={`text-[11px] inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline font-semibold bg-secondary text-primary rounded-full`}
            >
              {el?.[AdminEstateTableAccessorEnum.Type]}
            </div>
            <EstateCardCategoryBadge category={el?.[AdminEstateTableAccessorEnum.Category] as EstateCategoryDto} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
