import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { createSavedSearch } from 'services/api/saved-search-api.service';
import { NotificationType } from 'common/enums/services/notification/notification-type.enum';
import { CreateSavedSearchRequest } from 'common/types/services/api/user/user-saved-search.type';
import { QueryKey } from 'common/enums/query-key';
import { openNotificationModal } from 'store/use-notification-modal-store';
import { BackendError } from 'common/types/errors/backendError';
import { AxiosError } from 'axios';

export default function useCreateSavedSearch(
  options?: Omit<UseMutationOptions<string, AxiosError<BackendError>, CreateSavedSearchRequest, unknown>, 'mutationFn'>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createSavedSearch,
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'Something went wrong',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.SavedSearch] });
    },
    ...options,
  });
}
