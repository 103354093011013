import { useMemo } from 'react';
import {
  AdminCommisionActionType,
  AdminCommisionEditActionType,
  AdminCommissionsQueryParamConfig,
} from './AdminCommission.types';
import { TablePaginationProps } from 'common/types/types';
import { AdminCommissionTableRow } from './AdminCommissionTable/AdminCommissionTable.types';
import { GoogleAddressComponents, GooglePlaceDetails } from './AdminCommisionPlaceAutomplete';
import { CommissionItem } from 'common/types/services/api/commissions/commission-dto.types';
import { defaultParams } from './AdminCommission.config';
import { customFormatNumber } from 'common/utils/custom-format-float-numbers';

export const useCreateQueryParamConfig = (): AdminCommissionsQueryParamConfig => {
  return useMemo<AdminCommissionsQueryParamConfig>(() => defaultParams, []);
};

export const useCreateTablePaginationProps = (
  currentPage: number,
  totalCount: number | undefined,
  maxItemPerPage: number,
  setCurrentPage: (newPage: number) => void,
): TablePaginationProps =>
  useMemo(() => {
    return {
      currentPage,
      totalCount: totalCount || 0,
      pageSize: maxItemPerPage || 5,
      onPageChange: setCurrentPage,
    };
  }, [currentPage, totalCount, maxItemPerPage]);

export const createAdminTableData = (
  commissions: CommissionItem[],
  onEdit: AdminCommisionEditActionType,
  onDeleteEstate: AdminCommisionActionType,
): AdminCommissionTableRow[] => {
  return commissions.map((commission) => {
    const percent = commission?.percent ? `${customFormatNumber(commission?.percent * 100)?.toString()}` : null;
    const fixedRate = commission?.fixedRate ? `${commission?.fixedRate?.toString()}` : null;
    return {
      state: commission?.state || '',
      city: commission?.city || '',
      fixed: {
        id: commission?.id,
        fixed: !!commission?.fixedRate,
      },
      amount: (!!commission?.fixedRate ? `$${fixedRate}` : `${percent}%`) ?? '',
      actions: {
        onEdit: () =>
          onEdit({
            amount: (!!commission?.fixedRate ? fixedRate : percent) ?? '',
            commissionType: !!commission?.fixedRate ? 'fixedRate' : 'percent',
            location: {
              state: commission?.state || '',
              city: commission?.city || '',
              address: commission?.location,
            },
            id: commission?.id,
          }),
        onCancel: () => onDeleteEstate(commission?.id),
        state: commission?.state,
        city: commission?.city,
      },
    };
  });
};

export const getStateAndCityFromLocation = (details: GooglePlaceDetails | null): any => {
  const getAddressComponentName = (item: GoogleAddressComponents, searchTypes: string[]) => {
    return item.types.every((type) => searchTypes.includes(type));
  };

  const city =
    details?.address_components.find((item) => getAddressComponentName(item, ['locality', 'political']))?.long_name ||
    '' ||
    details?.address_components.find((item) =>
      getAddressComponentName(item, ['administrative_area_level_3', 'political']),
    )?.long_name;

  const state =
    details?.address_components.find((item) =>
      getAddressComponentName(item, ['administrative_area_level_1', 'political']),
    )?.short_name || '';
  return {
    state,
    city,
  };
};

export const getStateInfo = (details: GooglePlaceDetails | null): any => {
  const getAddressComponentName = (item: GoogleAddressComponents, searchTypes: string[]) => {
    return item.types.every((type) => searchTypes.includes(type));
  };

  const stateLabel =
    details?.address_components.find((item) =>
      getAddressComponentName(item, ['administrative_area_level_1', 'political']),
    )?.long_name || '';
  const stateIsoCode =
    details?.address_components.find((item) =>
      getAddressComponentName(item, ['administrative_area_level_1', 'political']),
    )?.short_name || '';
  return {
    stateLabel,
    stateIsoCode,
  };
};
