import React from 'react';

export type BookmarkIconProps = {
  isOn?: boolean;
  onClick?: () => void;
  isShadow?: boolean;
  className?: string;
};

const BookmarkIcon = ({ isOn = false, onClick, isShadow, className }: BookmarkIconProps) => {
  const shadowStyle = 'shadow-share-icon';
  return (
    <div
      className={`
            bg-white 
            flex 
            justify-center 
            items-center 
            rounded-full 
            w-12 
            h-12 
            cursor-pointer 
            ${isShadow ? shadowStyle : ''}
            ${className}
            `}
      onClick={onClick}
    >
      <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M5.20801 21.875V5.20833C5.20801 4.63542 5.41217 4.14479 5.82051 3.73646C6.22815 3.32882 6.71842 3.125 7.29134 3.125H17.708C18.2809 3.125 18.7715 3.32882 19.1799 3.73646C19.5875 4.14479 19.7913 4.63542 19.7913 5.20833V21.875L12.4997 18.75L5.20801 21.875ZM7.29134 18.6979L12.4997 16.4583L17.708 18.6979V5.20833H7.29134V18.6979ZM7.29134 5.20833H17.708H12.4997H7.29134Z'
          fill='#C4C4C4'
        />
      </svg>
    </div>
  );
};

export { BookmarkIcon };
