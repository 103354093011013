import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getSavedSearch } from 'services/api/saved-search-api.service';
import { CreateSavedSearchFiltersDto } from 'common/types/services/api/user/user-saved-search.type';
import { QueryKey } from 'common/enums/query-key';

export default function useSavedSearch(
  options?: Omit<
    UndefinedInitialDataOptions<CreateSavedSearchFiltersDto[], Error, CreateSavedSearchFiltersDto[], string[]>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.SavedSearch],
    queryFn: () => getSavedSearch(),
    ...options,
  });
}
