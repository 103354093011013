import { Fragment, useMemo, useState } from 'react';
import { LandlordUpcomingToursTable } from 'legacy-components/tours-schedule/table';
import { TourStatusDto } from 'common/enums/enums';
import { useAdminScheduledTours } from 'hooks/query';
// import useCancelTourByLandlord from 'hooks/query/tours/use-cancel-tour-by-landlord';
// import { ViewTourDocumentsModal } from 'components/atoms/TourDocumentsModal/TourDocumentsModal';
import {
  NumberParam,
  QueryParamConfig,
  createEnumParam,
  createEnumDelimitedArrayParam,
  withDefault,
  useQueryParams,
  StringParam,
  BooleanParam,
} from 'use-query-params';
import { SortOrder } from 'common/types/sort-order';
import SortOrderEnumParam from 'query-params/sortOrderEnumParam';
// import { CancelTourModal } from 'components/templates/Landlord/ToursSchedulePage/CancelTourModal/CancelTourModal';
import { ViewTourDetailsModal } from 'components/templates/Landlord/ToursSchedulePage/ViewTourDetailsModal/ViewTourDetailsModal';
import {
  parseScheduledToursToEvents,
  parseScheduledToursToTable,
} from 'components/templates/Landlord/ToursSchedulePage/ToursSchedulePage.utils';
import dayjs from 'dayjs';
import Calendar from 'legacy-components/tours-schedule/calendar';
import { Loader } from 'legacy-components/componets';
import { SlotInfo } from 'react-big-calendar';

type LandlordToursQueryParamConfig = {
  sortBy: QueryParamConfig<'date'>;
  order: QueryParamConfig<SortOrder>;
  isCalendarOpen: QueryParamConfig<boolean>;
  status: QueryParamConfig<`${TourStatusDto}`[]>;
  page: QueryParamConfig<number>;
  showOnly: QueryParamConfig<string>;
  dateFrom: QueryParamConfig<string>;
  dateTo: QueryParamConfig<string>;
};

const maxItemPerPage = 5;

const AdminToursCalendar = () => {
  // const [searchParams] = useSearchParams();
  // const navigate = useNavigate();
  // const [cancelTourModalOpen, setCancelTourModalOpen] = useState(false);
  const [viewTourModalOpen, setViewTourModalOpen] = useState(false);
  // const [viewDocumentsModalOpen, setViewDocumentsModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [selectedTourId, setSelectedTourId] = useState('');

  const queryParamConfig = useMemo<LandlordToursQueryParamConfig>(
    () => ({
      isCalendarOpen: withDefault(BooleanParam, true),
      sortBy: withDefault(createEnumParam(['date']), 'date'),
      order: withDefault(SortOrderEnumParam, 'desc'),
      status: withDefault(createEnumDelimitedArrayParam(Object.values(TourStatusDto) as `${TourStatusDto}`[], ','), []),
      page: withDefault(NumberParam, 1),
      showOnly: withDefault(StringParam, ''),
      dateFrom: withDefault(StringParam, dayjs(new Date()).format('YYYY-MM-DD')),
      dateTo: withDefault(StringParam, dayjs(new Date()).format('YYYY-MM-DD')),
    }),
    [],
  );

  const [query, setQuery] = useQueryParams(queryParamConfig);

  const { data: tours, isFetching: isToursFetching } = useAdminScheduledTours(
    {
      page: query.page,
      perPage: maxItemPerPage,
      filters: {
        from: query.dateFrom ? dayjs(query.dateFrom).format('YYYY-MM-DD') : undefined,
        to: query.dateTo ? dayjs(query.dateTo).add(1, 'day').format('YYYY-MM-DD') : undefined,
        status: query.status,
      },
      sorters: { slot: query.order },
    },
    { placeholderData: (previousValue) => previousValue },
  );

  const { data: calendarTours, isFetching: isLandlordCalendarToursFetching } = useAdminScheduledTours(
    {
      page: 1,
      perPage: 3000,
      filters: {
        from: dayjs(currentDate).startOf('month').format('YYYY-MM-DD'),
        to: dayjs(currentDate).endOf('month').add(1, 'day').format('YYYY-MM-DD'),
      },
      sorters: { slot: queryParamConfig.order.default },
    },
    { placeholderData: (previousValue) => previousValue },
  );

  const paginationCallback = (newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  };

  const paginationProps = {
    currentPage: query.page,
    totalCount: tours?.totalCount ?? 0,
    pageSize: maxItemPerPage,
    onPageChange: paginationCallback,
  };

  // const { mutateAsync: cancelTourByLandlord, isPending } = useCancelTourByLandlord();

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    const selectedDate = slotInfo.start;
    const selectedDateFormatted = dayjs(selectedDate).format('YYYY-MM-DD');
    const isSameDate = query.dateFrom === selectedDateFormatted && query.dateTo === selectedDateFormatted;
    setQuery((prev) => ({
      ...prev,
      dateFrom: isSameDate ? '' : selectedDateFormatted,
      dateTo: isSameDate ? '' : selectedDateFormatted,
    }));
  };

  const handleNavigate = (newDate: Date) => {
    setCurrentDate(newDate);
  };

  // const onCancel = (id: string) => {
  //   setSelectedTourId(id);
  //   setCancelTourModalOpen(true);
  // };

  const onView = (id: string) => {
    setSelectedTourId(id);
    setViewTourModalOpen(true);
  };
  const onViewDocuments = (id: string) => {
    // setSelectedTourId(id);
    // setViewDocumentsModalOpen(true);
  };

  const tableEvents = useMemo(() => {
    return parseScheduledToursToTable(tours?.items, { onView, onViewDocuments });
  }, [tours?.items, query.status, tours]);

  const calendarEvents = useMemo(() => parseScheduledToursToEvents(calendarTours?.items), [calendarTours]);

  const currentSelectedDay = useMemo(() => {
    if (!query.dateFrom || !query.dateTo) {
      return null;
    }
    if (dayjs(query.dateFrom).format('YYYY-MM-DD') === dayjs(query.dateTo).format('YYYY-MM-DD')) {
      return new Date(query.dateFrom);
    }
    return null;
  }, [query.dateFrom, query.dateTo]);

  return (
    <Fragment>
      <div className='relative'>
        {isLandlordCalendarToursFetching && (
          <Loader className='h-full absolute z-10 opacity-75 bg-white' iconClassName='w-20 h-20' />
        )}
        <Calendar
          events={calendarEvents}
          selectedDay={currentSelectedDay}
          handleSelectSlot={handleSelectSlot}
          handleNavigate={handleNavigate}
          date={currentDate}
        />
      </div>
      <LandlordUpcomingToursTable
        data={tableEvents}
        isLoading={isToursFetching}
        paginationProps={paginationProps}
        order={query.order}
        setQuery={setQuery}
      />
      {/* <CancelTourModal
        onClose={() => setCancelTourModalOpen(false)}
        open={cancelTourModalOpen}
        onOk={(id: string, reason: string) => cancelTourByLandlord({ id, reason })}
        tourId={selectedTourId}
        isLoadingSubmitBtn={isPending}
      /> */}
      <ViewTourDetailsModal
        onClose={() => {
          setSelectedTourId('');
          setViewTourModalOpen(false);
        }}
        open={viewTourModalOpen}
        onOk={async () => {
          setViewTourModalOpen(false);
        }}
        tourId={selectedTourId}
      />
      {/* <ViewTourDocumentsModal
        open={viewDocumentsModalOpen}
        onClose={() => setViewDocumentsModalOpen(false)}
        tourId={selectedTourId}
      /> */}
    </Fragment>
  );
};

export default AdminToursCalendar;
