import React, { useEffect, useMemo, useState } from 'react';
import { PAGE_TITLE } from './AdminProfileListing.const';
import { AdminEstateTable } from './AdminProfileListingTable/AdminProfileListingTable';
import { useQueryParams } from 'use-query-params';
import { createAdminTableData, useQueryParamConfig, useCreateTablePaginationProps } from './AdminProfileListing.utils';
import { useNavigate } from 'react-router';
import { AppRoute, EstateStatusDto } from 'common/enums/enums';
import { TablePagination } from 'legacy-components/ui/table/table-pagination';
import { useWindowSize } from 'hooks';
import { AdminEstateMobileCard } from './AdminProfileMobile';
import { useAdminEstates, useRejectEstate, useApproveEstate } from 'hooks/query';
import { AdminListingsFilters } from 'components/organisms/Filters/AdminListingsFilters';
import { AdminProfileListingRejectModal } from './AdminProfileListingRejectModal';
import { notification } from 'services/services';
import { FormikHelpers } from 'formik';
import useUpdateToNewStatusEstate from 'hooks/query/estates/use-update-to-new-status-estate';

const maxItemPerPage = 5;

export const AdminProfileListing: React.FC<any> = () => {
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const queryParamConfig = useQueryParamConfig();
  const [query, setQuery] = useQueryParams(queryParamConfig, {
    updateType: 'replace',
  });

  const { data, isLoading, isFetching } = useAdminEstates(
    {
      page: query.page,
      status: query.status.map((s) => `OR%${s}`),
      type: query.type,
      category: query.category,
      perPage: maxItemPerPage,
      sorters: { paidAt: query.order },
    },
    {
      placeholderData: (previousValue) => previousValue,
    },
  );
  const [rejectEstateModalID, setRejectEstateModalID] = useState<null | string>(null);
  const { mutate: approveEstate, isPending: isApprovingEstatePending } = useApproveEstate();
  const { mutate: updateToNewStatusEstate, isPending: isUpdatingToNewEstatePending } = useUpdateToNewStatusEstate();
  const { mutate: rejectEstate, isPending: isRejectingEstatePending } = useRejectEstate();
  const { totalCount: total = 0, items: list = [] } = data || {};

  const onViewEstate = (estateId: string) => navigate(`${AppRoute.ESTATES}/${estateId}`);

  const tableData = useMemo(
    () =>
      createAdminTableData(
        list,
        onViewEstate,
        approveEstate,
        (id: string) => {
          setRejectEstateModalID(id);
        },
        updateToNewStatusEstate,
      ),
    [list],
  );

  const paginationCallback = (newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  };

  const tablePaginationProps = useCreateTablePaginationProps(query.page, total, maxItemPerPage, paginationCallback);

  //TODO: COMPLETE AX-686
  const handleSubminRejectionModal = async (
    values: { rejectionReason: string },
    formikHelpers: FormikHelpers<{ rejectionReason: string }>,
  ) => {
    if (rejectEstateModalID) {
      rejectEstate(
        { estateId: rejectEstateModalID, reason: values?.rejectionReason },
        {
          onSuccess: () => {
            notification.success('You have successfully rejected the estate');
            setRejectEstateModalID(null);
            formikHelpers.resetForm();
          },
          onError: (e) => {
            notification.error(e?.response?.data?.detail || 'You could not reject estate, try again');
            // close window and show error notification
            setRejectEstateModalID(null);
            formikHelpers.resetForm();
          },
        },
      );
    }
  };

  useEffect(() => {
    setQuery((prev) => ({ ...prev, status: [EstateStatusDto.PendingApproval] }));
  }, []);

  return (
    <div className='grow p-4 md:px-[34px] md:py-[28px] shadow-white-xl max-w-full overflow-x-auto'>
      <div className='profile-info-header justify-between flex items-center mb-2 md:mb-9 px-4 md:px-[34px] lg:px-0'>
        <div className='title text-xl font-bold'>{PAGE_TITLE}</div>
        <AdminListingsFilters />
      </div>
      {isMobile ? (
        tableData.length ? (
          <div>
            <div className='flex flex-col gap-4 mb-4'>
              {tableData.map((el, index) => (
                <AdminEstateMobileCard el={el} key={index} />
              ))}
            </div>
            <TablePagination {...tablePaginationProps} />
          </div>
        ) : (
          <div>Currently, you don't have any estates for approval.</div>
        )
      ) : (
        <AdminEstateTable
          setQuery={setQuery}
          paginationProps={tablePaginationProps}
          isLoading={
            isLoading ||
            isFetching ||
            isApprovingEstatePending ||
            isRejectingEstatePending ||
            isUpdatingToNewEstatePending
          }
          data={tableData}
          order={query.order}
        />
      )}
      <AdminProfileListingRejectModal
        isOpen={Boolean(rejectEstateModalID)}
        isSubmitting={isRejectingEstatePending}
        onClose={() => {
          setRejectEstateModalID(null);
        }}
        onSubmit={handleSubminRejectionModal}
      />
    </div>
  );
};
