import { EstateTypeDto } from 'common/enums/enums';
import { TourSheduledDto } from 'common/types/services/api/tours/tour-scheduled-dto.type';
import { ToursScheduleTableRowType } from 'legacy-components/tours-schedule/table/types';
import { ScheduledToursToEventsType, TourActionsType } from './ToursSchedulePage.types';

export const parseScheduledToursToTable = (
  items: TourSheduledDto[] | undefined,
  actions: TourActionsType,
): ToursScheduleTableRowType[] => {
  if (!items) {
    return [];
  }

  const { onView, onViewDocuments } = actions;

  return items?.map((el) => ({
    date: el.slot,
    property: {
      location: el.location,
      img: el.thumbnailUrl,
      isMulti: el.estateType === EstateTypeDto.MultiUnit,
      unitNumber: el.unitNumber,
    },
    status: { tourStatus: el.status },
    tenant: {
      name: el.renterName,
      avatar: el.userThumbnailUrl,
      address: el.address,
    },
    actions: {
      isConfirmed: el.isConfirmed,
      tourStatus: el.status,
      onCancel: () => actions?.onCancel?.(el?.id),
      onView: () => onView(el?.id),
      onViewDocuments: () => onViewDocuments(el?.id),
      onConfirm: () => actions?.onConfirm?.(el?.id),
    },
    isConfirmed: {
      isConfirmed: el.isConfirmed
    }
  }));
};

export const parseScheduledToursToEvents = (items: TourSheduledDto[] | undefined): ScheduledToursToEventsType[] => {
  if (!items) {
    return [];
  }

  return items?.map((el: TourSheduledDto) => ({
    title: el.location,
    start: el.slot,
    end: el.slot,
  }));
};
