import { CellProps } from 'react-table';
import { ToursScheduleTableRowType, ToursScheduleTableStatusCellType } from '../types';
import { FC } from 'react';
import { TourStatus } from 'components/atoms/TourStatus/TourStatus';

export const TourStatusCell: FC<CellProps<ToursScheduleTableRowType, ToursScheduleTableStatusCellType>> = ({
  value: { tourStatus },
}) => {
  return <TourStatus tourStatus={tourStatus} />;
};
