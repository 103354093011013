import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { createAccessAccount } from 'services/api/access-accounts-api.service';
import { CreateAccessAccountDto } from 'common/types/services/api/accessAccounts/create-access-account-dto.type';
import { notification } from 'services/services';
import { QueryKey } from 'common/enums/query-key';

export default function useCreateAccessAccount(
  options?: Omit<UseMutationOptions<CreateAccessAccountDto, Error, void, unknown>, 'mutationFn'>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAccessAccount,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.AccessAccounts] });
    },
    onSuccess: (data) => {
      window.open(data.url, '_blank');
    },
    onError: (e) => {
      notification.error(e.message || 'Something went wrong');
    },
    ...options,
  });
}
