import { FormikErrors } from 'formik';
import { PropertyValues } from 'legacy-pages/landlord/properties/landlord-properties';
import ReactQuill from 'react-quill';

const modules = {
  toolbar: [],
  // Below is the snippet of code from https://stackoverflow.com/questions/61158962/how-to-move-out-of-quill-editor-using-tab which is the solution to the problem of not being able to tab out of the rich text editor.
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function () {
          return true;
        },
      },
    },
  },
};

type Props = {
  value: string;
  placeholder: string;
  onChange: (value: string) => Promise<void | FormikErrors<PropertyValues>>;
};

const RichTextEditor = ({ value, placeholder, onChange }: Props) => {
  return <ReactQuill value={value} onChange={onChange} modules={modules} placeholder={placeholder} />;
};

export { RichTextEditor };
