import {
  CreateLeaseDocument,
  LeaseDocumentResponse,
} from 'common/types/services/api/landlord-lease-documents/landlord-lease-documents.types';
import { PaginationDtoRequest } from 'common/types/types';
import qs from 'qs';
import { axiosInstance } from './axiosInstance';
import { LeaseDocumentsApiPath } from 'common/enums/enums';

export const getLeaseDocuments = (query?: PaginationDtoRequest): Promise<LeaseDocumentResponse> => {
  const queryString = qs.stringify({
    page: query?.page,
    perPage: query?.perPage,
  });
  return axiosInstance.get(`${LeaseDocumentsApiPath.LANDLORD_LEASE_DOCUMENTS}?${queryString}`);
};

export const createLeaseDocument = (request: CreateLeaseDocument): Promise<string> => {
  const formData = new FormData();
  formData.append('file', request?.file);
  formData.append('title', request?.title);
  return axiosInstance.post(`${LeaseDocumentsApiPath.LANDLORD_LEASE_DOCUMENTS}`, formData);
};

export const deleteLeaseDocument = (id: string): Promise<boolean> => {
  return axiosInstance.delete(`${LeaseDocumentsApiPath.LANDLORD_LEASE_DOCUMENTS}/${id}`);
};
