import { Filters } from 'components/molecules/Filters/Filters';
import { LandlordListingsFiltersForm } from './LandlordListingsFiltersForm';
import { QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useMemo } from 'react';
import { defaultFilters } from './config';

type QueryParamConfigType = {
  type: QueryParamConfig<string>;
  status: QueryParamConfig<string>;
  category: QueryParamConfig<string>;
};

const LandlordListingsFilters = () => {
  const queryParamConfig = useMemo<QueryParamConfigType>(
    () => ({
      type: withDefault(StringParam, defaultFilters.type),
      status: withDefault(StringParam, defaultFilters.status),
      category: withDefault(StringParam, defaultFilters.category),
    }),
    [],
  );

  const [query, setQuery] = useQueryParams(queryParamConfig, {
    updateType: 'replace',
  });

  const handleSubmit = (values: any) => {
    setQuery(values);
  };

  const handleReset = () => {
    setQuery(defaultFilters);
  };

  return (
    <Filters
      initialValues={defaultFilters}
      filters={query}
      onSubmit={handleSubmit}
      onReset={handleReset}
      FiltersMenuForm={LandlordListingsFiltersForm}
    />
  );
};

export { LandlordListingsFilters };
