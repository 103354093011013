import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getEstatesList } from 'services/api/estates-api.service';
import { EstateFiltersRequestDto } from 'common/types/services/api/estates/estate-filters-request-dto';
import { EstateListDashboardDto } from 'common/types/services/api/estates/estate-list-dto.type';
import { QueryKey } from 'common/enums/query-key';

export default function useEstates(
  params: EstateFiltersRequestDto,
  options?: Omit<
    UndefinedInitialDataOptions<
      EstateListDashboardDto,
      Error,
      EstateListDashboardDto,
      (string | EstateFiltersRequestDto)[]
    >,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Estates, params],
    queryFn: () => getEstatesList(params),
    ...options,
  });
}
