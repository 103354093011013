import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';
import { verifyEmail } from 'services/api/auth-api.service';

export const useVerifyEmail = (
  options?: Omit<UseMutationOptions<boolean, AxiosError<BackendError>, void, unknown>, 'mutationFn'>,
) => {
  return useMutation({
    mutationFn: verifyEmail,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};
