import { FC } from 'react';
import { AdminFeedbackStatusProps, adminFeedbackStatusMapToText } from './AdminFeedbackStatus.types';
import { commonStatusStyle, styles, textStyle } from './AdminFeedbackStatus.styles';
import clsx from 'clsx';

const AdminFeedbackStatus: FC<AdminFeedbackStatusProps> = ({ status, size }) => {
  const statusStyle = styles[status];
  const textStatusStyle = size ? textStyle(size) : 'text-xs md:text-sm lg:text-[15px]';
  const combinedStyle = clsx(statusStyle, commonStatusStyle, textStatusStyle);
  return (
    <div className={combinedStyle}>
      <span>{`${adminFeedbackStatusMapToText[status]}`}</span>
    </div>
  );
};

export { AdminFeedbackStatus };
