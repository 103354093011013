import { ApplicationPropertyCardProps } from '../common/types/types';
import { ApplicationPropertyIconInfo } from './ApplicationPropertyIconInfo';
import { Button, Divider, Icon } from 'legacy-components/componets';
import { dateFormatMMMM_DD_YYYY_HH_MM_A } from 'helpers/date';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { Image } from 'antd';
import defaultEstatePhoto from 'assets/images/default_estate_image.png';
import { isNotEmptyString } from 'common/utils/check-empty-string';

const ApplicationPropertyCard = ({
  title,
  address,
  image,
  info,
  appliedAt,
  isMultiUnit,
  unitNumber,
}: ApplicationPropertyCardProps) => {
  const { isTablet } = useWindowSize();
  return (
    <div
      className={`property border border-primary rounded-lg overflow-hidden w-full flex ${
        isTablet ? 'flex-col max-w-[340px]' : 'max-h-[240px]'
      }`}
    >
      <Image
        width={isTablet ? 340 : 320}
        src={image}
        style={{ height: '100%', minWidth: 320 }}
        alt='estate'
        fallback={defaultEstatePhoto}
        preview={isNotEmptyString(image)}
      />
      <Divider style={{ borderColor: '#66CCFF' }} theme='vertical' />
      <div className='info p-6 bg-secondary grow'>
        <div className={`flex flex-wrap-reverse gap-3 ${isTablet ? 'flex-col items-center' : 'justify-between'}`}>
          <div>
            <div>
              <div className='tex-lg font-bold mb-1'>{title}</div>
              <span className='text-sm font-medium text-trueGray'>{address}</span>
            </div>
            {isMultiUnit && unitNumber && (
              <div className='flex justify-start align-bottom gap-2'>
                <Icon name={'house-without-a-chimney'} color={'#05A660'} />
                <span className='text-sm font-medium text-trueGray'>{`Unit ${unitNumber}`}</span>
              </div>
            )}
          </div>
          <div className='flex w-full justify-center md:justify-end'>
            <Button
              label={dateFormatMMMM_DD_YYYY_HH_MM_A(appliedAt)}
              theme={'green-outline'}
              className='pointer-events-none'
              icon={'circle-checked'}
              onClick={() => null}
            />
          </div>
        </div>
        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
        <ApplicationPropertyIconInfo {...info} hideUnits={false} />
      </div>
    </div>
  );
};

export { ApplicationPropertyCard };
