import { EstatesCategoryIconProps } from '../estates-category-icon/estates-category-icon';
import { EstatesCategoryIcon } from 'legacy-pages/pages';
import clsx from 'clsx';

export type EstatesCategoryListProps = {
  list: EstatesCategoryIconProps[];
  className?: string;
};

const EstatesCategoryList = ({ list, className }: EstatesCategoryListProps) => {
  return (
    <div className={clsx(`flex md:gap-12 gap-5 sm:gap-2 select-none`, className)}>
      {list.map((category, index) => (
        <EstatesCategoryIcon key={index} {...category} />
      ))}
    </div>
  );
};

export { EstatesCategoryList };
