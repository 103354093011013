import { Fragment, useMemo, useState } from 'react';
import { LandlordUpcomingToursTable } from 'legacy-components/tours-schedule/table';
import { TourStatusDto } from 'common/enums/enums';
import { useAdminScheduledTours } from 'hooks/query';
// import useCancelTourByLandlord from 'hooks/query/tours/use-cancel-tour-by-landlord';
// import { ViewTourDocumentsModal } from 'components/atoms/TourDocumentsModal/TourDocumentsModal';
import {
  NumberParam,
  QueryParamConfig,
  createEnumParam,
  createEnumDelimitedArrayParam,
  withDefault,
  useQueryParams,
  StringParam,
  BooleanParam,
} from 'use-query-params';
import { SortOrder } from 'common/types/sort-order';
import SortOrderEnumParam from 'query-params/sortOrderEnumParam';
// import { CancelTourModal } from 'components/templates/Landlord/ToursSchedulePage/CancelTourModal/CancelTourModal';
import { ViewTourDetailsModal } from 'components/templates/Landlord/ToursSchedulePage/ViewTourDetailsModal/ViewTourDetailsModal';
import {
  parseScheduledToursToTable,
} from 'components/templates/Landlord/ToursSchedulePage/ToursSchedulePage.utils';
import { LandlordToursFilters } from 'components/organisms/Filters/LandlordToursFilters/LandlordToursFilters';
import dayjs from 'dayjs';

type LandlordToursQueryParamConfig = {
  sortBy: QueryParamConfig<'date'>;
  order: QueryParamConfig<SortOrder>;
  isCalendarOpen: QueryParamConfig<boolean>;
  status: QueryParamConfig<`${TourStatusDto}`[]>;
  page: QueryParamConfig<number>;
  showOnly: QueryParamConfig<string>;
  dateFrom: QueryParamConfig<string>;
  dateTo: QueryParamConfig<string>;
};

const maxItemPerPage = 5;

const AdminToursTable = () => {
  // const [searchParams] = useSearchParams();
  // const navigate = useNavigate();
  // const [cancelTourModalOpen, setCancelTourModalOpen] = useState(false);
  const [viewTourModalOpen, setViewTourModalOpen] = useState(false);
  // const [viewDocumentsModalOpen, setViewDocumentsModalOpen] = useState(false);
  const [selectedTourId, setSelectedTourId] = useState('');

  const queryParamConfig = useMemo<LandlordToursQueryParamConfig>(
    () => ({
      isCalendarOpen: withDefault(BooleanParam, true),
      sortBy: withDefault(createEnumParam(['date']), 'date'),
      order: withDefault(SortOrderEnumParam, 'desc'),
      status: withDefault(
        createEnumDelimitedArrayParam(Object.values(TourStatusDto) as `${TourStatusDto}`[], ','),
        [],
      ),
      page: withDefault(NumberParam, 1),
      showOnly: withDefault(StringParam, ''),
      dateFrom: withDefault(StringParam, ''),
      dateTo: withDefault(StringParam, ''),
    }),
    [],
  );

  const [query, setQuery] = useQueryParams(queryParamConfig);

  const { data: tours, isFetching: isToursFetching } = useAdminScheduledTours(
    {
      page: query.page,
      perPage: maxItemPerPage,
      filters: {
        from: query.dateFrom ? dayjs(query.dateFrom).format('YYYY-MM-DD') : undefined,
        to: query.dateTo ? dayjs(query.dateTo).add(1, 'day').format('YYYY-MM-DD') : undefined,
        status: query.status,
        isConfirmed: query.showOnly === 'confirmed' ? true : query.showOnly === 'unconfirmed' ? false : undefined,
      },
      sorters: { slot: query.order },
    },
    { placeholderData: (previousValue) => previousValue },
  );

  const paginationCallback = (newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  };

  const paginationProps = {
    currentPage: query.page,
    totalCount: tours?.totalCount ?? 0,
    pageSize: maxItemPerPage,
    onPageChange: paginationCallback,
  };

  // const { mutateAsync: cancelTourByLandlord, isPending } = useCancelTourByLandlord();

  // const onCancel = (id: string) => {
  //   setSelectedTourId(id);
  //   setCancelTourModalOpen(true);
  // };

  const onView = (id: string) => {
    setSelectedTourId(id);
    setViewTourModalOpen(true);
  };
  const onViewDocuments = (id: string) => {
    // setSelectedTourId(id);
    // setViewDocumentsModalOpen(true);
  };

  const tableEvents = useMemo(() => {
    return parseScheduledToursToTable(tours?.items, { onView, onViewDocuments });
  }, [tours?.items, query.status, tours]);

  const title = useMemo(() => {
    if (!query.dateTo && !query.dateFrom) {
      return 'Tours';
    }

    const dateTo = dayjs(query.dateTo);
    const dateFrom = dayjs(query.dateFrom);

    const dateFromFormatted = dayjs(query.dateFrom).format('YYYY-MM-DD');
    const dateToFormatted = dayjs(query.dateTo).format('YYYY-MM-DD');

    const fromFormattedStr = dateFrom.isValid() ? `from ${dateFromFormatted} ` : '';
    const toFormattedStr = dateTo.isValid() ? `to ${dateToFormatted}` : '';

    if (dateFrom.isValid() && dateTo.isValid() && dateFromFormatted === dateToFormatted) {
      return `Tours for ${dateFromFormatted}`
    }

    return `Tours ${fromFormattedStr}${toFormattedStr}`;
  }, [query.dateFrom, query.dateTo]);

  return (
    <Fragment>
      <div className='lg:mb-9 mb-2 flex items-center justify-between'>
        <div className='title text-xl font-bold'>{title}</div>
        <LandlordToursFilters />
      </div>
      <LandlordUpcomingToursTable
        data={tableEvents}
        isLoading={isToursFetching}
        paginationProps={paginationProps}
        order={query.order}
        setQuery={setQuery}
      />
      {/* <CancelTourModal
        onClose={() => setCancelTourModalOpen(false)}
        open={cancelTourModalOpen}
        onOk={(id: string, reason: string) => cancelTourByLandlord({ id, reason })}
        tourId={selectedTourId}
        isLoadingSubmitBtn={isPending}
      /> */}
      <ViewTourDetailsModal
        onClose={() => {
          setSelectedTourId('');
          setViewTourModalOpen(false);
        }}
        open={viewTourModalOpen}
        onOk={async () => {
          setViewTourModalOpen(false);
        }}
        tourId={selectedTourId}
      />
      {/* <ViewTourDocumentsModal
        open={viewDocumentsModalOpen}
        onClose={() => setViewDocumentsModalOpen(false)}
        tourId={selectedTourId}
      /> */}
    </Fragment>
  );
};

export default AdminToursTable;
