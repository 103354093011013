import { PaymentCardDto } from 'common/types/services/api/payment/payment-cards-dto.type';
import { FC, ReactNode } from 'react';

import visaImg from 'assets/images/payment-brand/visa.png';
import amexImg from 'assets/images/payment-brand/amex.png';
import mastercardImg from 'assets/images/payment-brand/mastercard.png';

type Props = {
  item: PaymentCardDto;
  actions?: ReactNode;
};

const brandImages: any = {
  visa: visaImg,
  amex: amexImg,
  mastercard: mastercardImg
};

const PaymentCardsListItem: FC<Props> = ({ item, actions }) => {
  const { brand, last } = item;

  return (
    <div className='w-full flex items-center justify-between p-3 border-slate-100 border-2 rounded select-none'>
      <div className='flex items-center gap-4 mr-5'>
        <div>
          {brandImages[brand] ? (
            <img src={brandImages[brand]} className='min-w-[34px] min-h-[24px]' alt='payment-brand-icon' width={34} height={24} />
          ) : (
            brand
          )}
        </div>
        <div className='whitespace-nowrap text-sm sm:text-base'>**** **** **** {last}</div>
      </div>

      <div className='flex items-center gap-4'>{actions}</div>
    </div>
  );
};

export { PaymentCardsListItem };
