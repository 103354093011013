import { Modal } from 'components/organisms/Modal';
import { ConfirmModalProps } from './ConfirmModal.types';
import { Button } from 'legacy-components/componets';

export const ConfirmModal = ({ open, onCancel, onOk, styles, title, isLoadingSubmitBtn }: ConfirmModalProps) => {
  return (
    <Modal isOpen={open} onClose={onCancel}>
      <div
        className='md:w-[500px] bg-white rounded-lg md:max-w-full md:h-auto relative flex flex-col gap-9 min-w-[200px]'
        style={styles?.content}
      >
        <h3 className='text-center text-xl text-neutral-800 text-[40px] font-semibold leading-10' style={styles?.title}>
          {title}
        </h3>
        <div className={'flex gap-4 flex-col sm:flex-row justify-center sm:justify-between'}>
          <Button
            label={'No'}
            theme={'red-text'}
            onClick={onCancel}
            className={'w-full'}
            disabled={isLoadingSubmitBtn}
          />
          <Button label={'Yes'} onClick={onOk} className={'w-full'} isLoading={isLoadingSubmitBtn} />
        </div>
      </div>
    </Modal>
  );
};
