import { TourStatusDto } from 'common/enums/enums';

export const styles = {
  [TourStatusDto.Finished]: 'bg-[#F1FFF5] text-[#A0D911]',
  [TourStatusDto.Canceled]: 'bg-[#FEF2F2] text-[#FF385C]',
  [TourStatusDto.Rejected]: 'bg-[#FEF2F2] text-[#FF385C]',
  [TourStatusDto.Expired]: 'bg-[#FFFBEB] text-[#F59E0B]',
  [TourStatusDto.InProgress]: 'bg-[#FFFBEB] text-[#F59E0B]',
  [TourStatusDto.Skipped]: 'bg-[#F0F0F0] text-[#6C6C6C]',
  [TourStatusDto.Scheduled]: 'bg-[#F1FBFF] text-[#66CCFF]',
  [TourStatusDto.Reserved]: 'bg-[#F1FBFF] text-[#0996CE]',
  [TourStatusDto.PendingPayment]: 'bg-[#FFFBEB] text-[#F59E0B]',
};

export const commonStatusStyle = 'font-medium leading-tight px-[10px] py-[2px] rounded-3xl text-center';

export const textStyle = (size: 's' | 'm' | 'l') => {
  switch (size) {
    case 's':
      return 'text-xs';
    case 'm':
      return 'text-sm';
    case 'l':
      return 'text-base';
  }
};
