import React from 'react';
import { Icon, NotificationTypeIcon } from 'legacy-components/componets';
import { getNotificationMessageColorClass } from 'helpers/helpers';
import { ToastMessageProps } from 'common/types/types';

const ToastMessage = ({ closeToast, toastProps, data }: ToastMessageProps) => {
  const messageColor = getNotificationMessageColorClass(toastProps.type);

  return (
    <div className={`flex items-center justify-between w-[320px] justify-between rounded-xl !w-full`}>
      <div className='flex items-center gap-4'>
        <NotificationTypeIcon type={toastProps.type} />
        <span className={`text-base font-medium ${messageColor}`}>{data}</span>
      </div>
      {closeToast && (
        <div onClick={closeToast}>
          <Icon name={'close-circle'} />
        </div>
      )}
    </div>
  );
};

export { ToastMessage };
