import { Card, Divider, Image, Skeleton, Tag } from 'antd';
import { FC, Fragment } from 'react';
import { EstateCardProps } from './types';
import moduleStyles from './style.module.css';
import { EstateCardPrice } from './EstateCardPrice';
import { EstateCardLocation } from './EstateCardLocation';
import { EstateCardInfo } from './EstateCardInfo';
import { EstateCardStatusTag } from './EstateCardStatusTag';
import { EstateCardTypeDetails } from './EstateCardDetails';
import { SubcriptionStatus } from 'components/atoms/SubcriptionStatus/SubcriptionStatus';
import { EstateSubscriptionPlan } from 'components/atoms/EstateSubscriptionPlan/EstateSubscriptionPlan';
import { Link } from 'react-router-dom';
import { Icon } from 'legacy-components/componets';
import { EstateStatusDto } from 'common/enums/enums';
import defaultEstatePhoto from 'assets/images/default_estate_image.png';

const EstateCard: FC<EstateCardProps> = ({
  isHorizontal,
  city,
  category,
  location,
  price,
  priceMax,
  priceMin,
  units,
  type,
  thumbnailUrl,
  onClick,
  bathrooms,
  beds,
  propertyArea,
  status,
  style,
  subscriptionStatus,
  chargePlan,
  //AX-609
  gotoEditMode,
}) => (
  <Card
    onClick={onClick}
    hoverable
    bordered={false}
    className={isHorizontal ? moduleStyles.estateCardHorizontal : moduleStyles.estateCardVertical}
    style={style}
    aria-label='Estate image'
    cover={
      <Fragment>
        <div className='absolute top-2 left-2' style={{ zIndex: 1 }}>
          <EstateCardStatusTag status={status} />
          {chargePlan && !isHorizontal && (
            <Tag className='bg-secondary text-primary rounded-xl'>
              {'Subscription plan: '}
              {chargePlan}
            </Tag>
          )}
          {(status === EstateStatusDto.Available || status === EstateStatusDto.Rejected) && gotoEditMode && !isHorizontal && (
            <Tag className='border-0 bg-transparent'>
              <Link to={gotoEditMode?.() || ''}>
                <Icon
                  name={'commision-edit'}
                  className='rounded-full w-8 h-8 text-white bg-black'
                  clickable
                  height={18}
                  width={18}
                />
              </Link>
            </Tag>
          )}
        </div>
        <Image
          preview={false}
          src={thumbnailUrl}
          loading='lazy'
          placeholder={<Skeleton.Image rootClassName='!w-full' style={{ width: '100%', height: '200px' }} active />}
          // // TODO: CHANGE BY NEW PLACEHOLDER IMAGE
          fallback={defaultEstatePhoto}
          aria-label='Estate image'
        />
      </Fragment>
    }
  >
    <div className='flex flex-col flex-grow'>
      <div className='flex justify-between mb-[5px]'>
        <EstateCardPrice price={price} priceMax={priceMax} priceMin={priceMin} />
        {isHorizontal ? <EstateCardTypeDetails category={category} units={units} type={type} /> : null}
      </div>
      <div className='flex flex-col'>
        <div className={`flex flex-wrap ${isHorizontal ? 'gap-2' : ''}`}>
          <div className='flex-grow truncate'>
            <EstateCardLocation city={city} location={location} />
          </div>
          <div>
            {chargePlan && isHorizontal && <EstateSubscriptionPlan plan={chargePlan} />}
            {subscriptionStatus && isHorizontal && (
              <div>
                <SubcriptionStatus status={subscriptionStatus} size='medium' />
              </div>
            )}
          </div>
        </div>
        {!isHorizontal ? (
          <div className='mt-2 flex gap-2'>
            <EstateCardTypeDetails category={category} units={units} type={type} />
            {subscriptionStatus && !isHorizontal && <SubcriptionStatus status={subscriptionStatus} />}
          </div>
        ) : null}
        <Divider className={'border-[#EFF7FB] my-3'} />
        {
          //AX-609
        }
        <div className='flex justify-between'>
          <div className='flex-grow'>
            <EstateCardInfo beds={beds} bathrooms={bathrooms} propertyArea={propertyArea} />
          </div>
          {(status === EstateStatusDto.Available || status === EstateStatusDto.Rejected) && gotoEditMode && isHorizontal && (
            <Link className='flex items-center justify-end' to={gotoEditMode?.() || ''}>
              <Icon
                name={'commision-edit'}
                className='rounded-full w-8 h-8 text-white bg-primary'
                clickable
                height={18}
                width={18}
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  </Card>
);

export { EstateCard };
