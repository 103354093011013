import { useWindowSize } from 'hooks/use-windows-size.hook';
import { MANAGE_LIST, TITLE } from './SavedSearch.const';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { maxItemPerPage } from './SavedSearch.config';
import { SavedSearchTable } from './saved-search-table/SavedSearchTable';
import { useQueryParams } from 'use-query-params';
import { createSavedSearchTableData, useCreateTablePaginationProps, useQueryParamConfig } from './SavedSearch.utils';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'common/enums/enums';
import { SavedSearchModal } from './saved-search-modal/SavedSearchModal';
import { SavedSearchMobileCardProps } from './saved-search-table/SavedSearchTable.types';
import { getDayjs } from 'helpers/date';
import { SavedSearchEstate } from 'common/types/services/api/user/user-saved-search.type';
import { Dropdown } from 'legacy-components/ui/dropdown/dropdown';
import { DropdownMenu } from 'legacy-components/dropdown-menu/dropdown-menu';
import { TablePagination } from 'legacy-components/ui/table/table-pagination';
import { Icon } from 'legacy-components/componets';
import { getNewCurrentPage } from 'helpers/pagination';
import { useDeleteSavedSearchResult, useReadAllSavedSearch, useSavedSearchResult } from 'hooks/query';
import { formatPriceValue } from 'helpers/string.helpers';
import defaultEstatePhoto from 'assets/images/default_estate_image.png';
import { isNotEmptyString } from 'common/utils/check-empty-string';

export const SavedSearchMobileCard: React.FC<SavedSearchMobileCardProps> = ({ el, key }) => {
  const mapRangePrice = (minValue: number, maxValue: number) => {
    return minValue === maxValue
      ? formatPriceValue(minValue)
      : `${formatPriceValue(minValue)}-${formatPriceValue(maxValue)}`;
  };

  const isString = typeof el.property.price === 'string';
  const isPriceRangeExist = el.property.priceMax && el.property.priceMin;

  const formattedPrice = isString
    ? Number(el.property.price)
      ? formatPriceValue(Number(el.property.price))
      : isPriceRangeExist
      ? mapRangePrice(el.property.priceMin, el.property.priceMax)
      : el.property.price
    : formatPriceValue(el.property.price as number);

  const imageSrc = isNotEmptyString(el?.property?.image) ? el?.property?.image : defaultEstatePhoto;

  return (
    <Fragment key={key}>
      <div className='shadow-lg rounded-lg flex flex-col relative'>
        <Dropdown
          target={
            <button
              id='dropdownButton'
              data-dropdown-toggle='dropdown'
              className='inline-block text-gray-500 hover:bg-gray-100 focus:outline-none rounded-lg text-sm p-1.5 absolute bg-white opacity-80 top-2 right-2'
              type='button'
            >
              <svg
                className='w-6 h-6'
                aria-hidden='true'
                fill='black'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z'></path>
              </svg>
            </button>
          }
          menu={
            <DropdownMenu
              key={el.date.toString()}
              menuItems={[
                {
                  id: 1,
                  title: 'View/edit details',
                  onClick: () => el.actions.onView(),
                  icon: 'house',
                },
                {
                  id: 2,
                  title: 'Delete saved search',
                  onClick: () => el.actions.onDelete(),
                  icon: 'shape',
                },
              ]}
              headerTitle={'Saved Search'}
            />
          }
        />
        <div className='w-full h-40 flex-shrink-0 mr-3  rounded-t-lg overflow-hidden'>
          <img src={imageSrc} alt='property' className='w-full h-full object-cover' />
        </div>

        <div className='p-3'>
          <div className='flex justify-between mb-2'>
            <div className='price flex justify-start items-center'>
              <p className='text-primaryDark text-lg font-bold'>${formattedPrice}</p>
              <p className='text-sm font-normal text-trueGray ml-[5px]'>/mo</p>
            </div>
          </div>
          <div className='font-bold mb-2'>{getDayjs(el.date).format('MMMM D, hh:mm A').toString()}</div>
          <div className='text'>{el.property.address}</div>
        </div>
      </div>
    </Fragment>
  );
};

export const SavedSearch = () => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const [openModal, toggleModal] = useState(false);
  const queryParamConfig = useQueryParamConfig();
  const [query, setQuery] = useQueryParams(queryParamConfig);
  const { mutate: readAllSavedSearch } = useReadAllSavedSearch();
  const params = {
    page: query.page,
    perPage: maxItemPerPage,
    sorters: { postedAt: query.order ?? 'desc' },
  };
  const { mutate: deleteSavedSearchResult, isPending: isDeletingPending } = useDeleteSavedSearchResult(params);
  const {
    data: savedSearchResult,
    isLoading,
    isFetching,
  } = useSavedSearchResult(params, {
    placeholderData: (previousValue) => previousValue,
  });

  const savedSearchEstates = savedSearchResult?.items || [];
  const total = savedSearchResult?.totalCount || 0;

  const paginationCallback = (newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  };

  const tablePaginationProps = useCreateTablePaginationProps(
    query.page,
    savedSearchResult?.totalCount,
    maxItemPerPage,
    paginationCallback,
  );

  const onDeleteEstate = React.useCallback(
    async (item: SavedSearchEstate) => {
      const newCurrentPage = getNewCurrentPage(total, maxItemPerPage, query.page);
      deleteSavedSearchResult(item?.estateId);
      setQuery((prev) => ({ ...prev, page: newCurrentPage }));
    },
    [query.page, total, maxItemPerPage],
  );

  const onViewEstate = (item: SavedSearchEstate) => navigate(`${AppRoute.ESTATES}/${item?.estateId}`);

  const tableData = useMemo(
    () => createSavedSearchTableData(savedSearchEstates, onViewEstate, onDeleteEstate),
    [savedSearchEstates],
  );

  useEffect(() => {
    readAllSavedSearch();
  }, []);

  return (
    <div className='grow overflow-x-auto shadow-white-xl p-4 md:px-[34px] md:py-[28px]'>
      <div className='profile-info-header flex justify-between items-center mb-2 md:mb-9 px-1 sm:px-[34px] lg:px-0'>
        <div className='title text-xl font-bold'>{TITLE}</div>
        <div
          className='flex bg-secondary items-center gap-2 p-2 rounded-lg cursor-pointer lg:mr-2'
          onClick={() => toggleModal(true)}
        >
          <Icon name={'faSliders'} style={{ color: '#222222' }} clickable />
          <p>{MANAGE_LIST}</p>
        </div>
      </div>

      {isMobile ? (
        tableData.length ? (
          <div>
            <div className='flex flex-col gap-4 mb-4 '>
              {tableData.map((el, index) => (
                <SavedSearchMobileCard el={el} key={index} />
              ))}
            </div>
            <TablePagination {...tablePaginationProps} />
          </div>
        ) : (
          <div>Currently, you don't have any saved search.</div>
        )
      ) : (
        <SavedSearchTable
          setQuery={setQuery}
          paginationProps={tablePaginationProps}
          isLoading={isLoading || isFetching || isDeletingPending}
          data={tableData}
          order={query.order}
        />
      )}

      <SavedSearchModal open={openModal} onClose={() => toggleModal(false)} />
    </div>
  );
};
