import { DatepickerTheme } from 'common/types/types';

const styles: Record<DatepickerTheme, { container: string; label: string; input: string; error: string }> = {
  default: {
    container: 'w-full',
    label: 'block mb-1 text-sm font-medium text-black',
    input:
      'block w-full h-11 p-3 text-black border border-warmGray rounded-lg focus:border-trueGray focus:outline-none',
    error: 'border-error focus:border-error',
  },
  primary: {
    container: '',
    label: 'block mb-1 text-sm font-medium text-black',
    input:
      'block w-full h-11 pt-3 pb-3 pl-0 text-black border-b border-warmGray focus:border-primary focus:outline-none',
    error: 'border-b-error focus:border-b-error',
  },
};

export default styles;
