const styles = {
  container: 'bg-white mb-20 mx-0 md:mx-[102px]',
  wrapper: 'flex justify-center w-full',
  list: `
    grid grid-cols-1 
    sm:grid-cols-2 
    lg:grid-cols-3 
    xl:grid-cols-4 
    2xl:grid-cols-5 
    3xl:grid-cols-6 
    5xl:grid-cols-8 
    gap-y-9 
    gap-x-[17px]`,
};

export default styles;
