import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { TourAccessDto } from 'common/types/services/api/tours/tour-access-dto.type';
import { getTourKeyInfo } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useTourKeyInfo(
  tourId?: string,
  options?: Omit<
    UndefinedInitialDataOptions<TourAccessDto | undefined, Error, TourAccessDto | undefined, (string | undefined)[]>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    ...options,
    queryKey: [QueryKey.Tours, tourId, 'key-info'],
    queryFn: () => {
      if (!tourId) return;
      return getTourKeyInfo(tourId);
    },
  });
}
