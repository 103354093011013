import { TablePaginationProps } from 'common/types/components/ui/table/table-pagination-props.type';
import { TableColumn } from 'common/types/components/ui/table/table-props.type';
import { AdminCommissionTableAccessorEnum, AdminCommissionTableRow } from './AdminCommissionTable.types';
import { ACTIONS_HEADER, STATE_HEADER, CITY_HEADER, AMOUNT } from './AdminCommissionTable.const';
import { Table } from 'legacy-components/ui/table/table';
import { AdminTextCell } from './AdminTableCells/AdminTextCell';
import { AdminActionsMenuCell } from './AdminTableCells/AdminActionsCell';
import { EMPTY_TITLE } from '../AdminCommission.const';

export type AdminEstateTableProps = {
  data: AdminCommissionTableRow[];
  isLoading: boolean;
  paginationProps: TablePaginationProps;
};

const styleConfig = {
  header: {
    backgroundColor: '#F1FBFF',
    height: 54,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginBottom: 10,
  },
};

export const AdminEstateTable = ({ data, isLoading, paginationProps }: AdminEstateTableProps) => {
  const columns: TableColumn<AdminCommissionTableRow>[] = [
    {
      Header: STATE_HEADER,
      accessor: AdminCommissionTableAccessorEnum.State,
      Cell: AdminTextCell,
      width: '20%',
      minWidth: 50,
    },
    {
      Header: CITY_HEADER,
      accessor: AdminCommissionTableAccessorEnum.City,
      Cell: AdminTextCell,
      width: '30%',
      minWidth: 125,
    },
    {
      Header: AMOUNT,
      accessor: AdminCommissionTableAccessorEnum.Amount,
      Cell: AdminTextCell,
      width: '30%',
      minWidth: 100,
    },
    {
      Header: ACTIONS_HEADER,
      accessor: AdminCommissionTableAccessorEnum.Actions,
      Cell: AdminActionsMenuCell,
      width: '20%',
      minWidth: 100,
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      isLoading={isLoading}
      styleConfig={styleConfig}
      paginationProps={paginationProps}
      placeholder={{
        title: EMPTY_TITLE,
        height: 400,
      }}
    />
  );
};
