import { useFormik } from 'formik';
import { Button, Icon, Label } from 'legacy-components/componets';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';
import { Select } from 'legacy-components/fields/fields';
import { FC, useEffect, useState } from 'react';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { Modal } from 'components/organisms/Modal';
import { AdminCommissionModalProps, AdminCommissionModalValues } from './AdminCommissionModal.types';
import { filtersOptions } from '../AdminCommission.config';
import { AMOUNT_LABEL, COMMISSION_TYPE_PLACEHOLDER, ADDRESS_LABEL, COMMISSION_TYPE_LABEL } from '../AdminCommission.const';
import { stringAsFloatNumber } from 'helpers/helpers';
import cn from 'clsx';
import styles from './AdminCommissionModal.styles';
import { GooglePlaceDetails } from 'legacy-components/fields/place-autocomplete/place-autocomplete';
import { AdminCommisionPlaceAutomplete } from '../AdminCommisionPlaceAutomplete';
import { getStateAndCityFromLocation } from '../AdminCommission.utils';
import { commissionsValidationSchema } from 'validations/admin/admin-commissions';

export const AdminCommissionModal: FC<AdminCommissionModalProps> = ({
    onClose,
    open: openProp,
    onSubmit,
    title,
    initStateProps
}) => {
    const inputClassName = (invalid: Boolean) => cn(styles.input, invalid && styles.error);
    const [googlePlaceDetails, setGooglePlaceDetails] = useState<GooglePlaceDetails | null>(null);
    const defaultInitState: AdminCommissionModalValues = {
        location: {
            address: '',
            state: '',
            city: '',
        },
        amount: '',
        commissionType: 'percent',
    };
    const initState = initStateProps ?? defaultInitState;
    const { isMobile } = useWindowSize();
    const { values, errors, touched, setFieldValue, submitForm, resetForm, } = useFormik<AdminCommissionModalValues>({
        initialValues: initState,
        validationSchema: commissionsValidationSchema,
        onSubmit: async () => {
            onSubmit(values);
            onClose();
        },
    });

    useEffect(() => {
        if (openProp) {

            if (initStateProps !== null) resetForm({ values: initStateProps });
            else resetForm({ values: defaultInitState });
        }
    }, [openProp, initStateProps]);

    useEffect(() => {
        const { city, state } = getStateAndCityFromLocation(googlePlaceDetails);
        setFieldValue('location', {
            ...values?.location,
            city,
            state
        });
    }, [googlePlaceDetails]);

    return (
        <>
            <Modal
                title={title}
                isOpen={openProp}
                isFullScreen={isMobile}
                contentClassName={'w-[715px]'}
                onOpenChange={(open) => {
                    if (open) return;
                    onClose();
                }}
            >
                <div className='flex flex-col gap-4'>
                    <Label label={ADDRESS_LABEL} className={'col-span-1'}>
                        <ErrorValidationMessage touched={touched?.location?.address} message={errors?.location?.address
                            || errors?.location?.state || errors?.location?.city}>
                            <div className='place-autocomplete'>
                                <AdminCommisionPlaceAutomplete
                                    value={values?.location.address}
                                    setValue={(location) => {
                                        setFieldValue('location.address', location.address);
                                    }}
                                    setGooglePlaceDetails={setGooglePlaceDetails}
                                    fieldName={'location'}
                                    invalid={Boolean((errors?.location?.address
                                        || errors?.location?.state || errors?.location?.city) && touched?.location?.address)}
                                />
                            </div>
                        </ErrorValidationMessage>
                    </Label>
                    <Label label={COMMISSION_TYPE_LABEL} className={'col-span-1'}>
                        <ErrorValidationMessage touched={touched.commissionType} message={errors.commissionType}>
                            <Select
                                name={'commissionType'}
                                options={filtersOptions}
                                value={values?.commissionType}
                                placeholder={COMMISSION_TYPE_PLACEHOLDER}
                                onChange={(value) => {
                                    setFieldValue('commissionType', value)
                                }}
                                selectStyle={{ menu: { minWidth: 'max-content' }, menuList: { maxHeight: 120 } }}
                            />
                        </ErrorValidationMessage>
                    </Label>
                    <Label label={AMOUNT_LABEL} className={'col-span-1'}>
                        <ErrorValidationMessage touched={touched.amount} message={errors.amount}>
                            <div className='relative'>
                                <div
                                    className={`absolute inset-y-0 left-0 -z-1 flex items-center pl-3 pointer-events-none`}
                                >
                                    <Icon name={values.commissionType === 'percent' ? 'commission-percent' : 'commission-fixed'} width={20} height={20} />
                                </div>
                                <input
                                    id={'amount'}
                                    name={'amount'}
                                    type={'text'}
                                    className={inputClassName(Boolean(errors.amount && touched.amount))}
                                    onChange={(e) => stringAsFloatNumber(e, `amount`, setFieldValue)}
                                    value={values.amount}
                                    autoComplete='off'
                                    maxLength={10}
                                />
                            </div>
                        </ErrorValidationMessage>
                    </Label>
                    <div className='w-full'>
                        <Button label={'Submit'} theme={'primary'} onClick={submitForm} className={'w-full mt-4'} />
                    </div>
                </div>
            </Modal>
        </>
    );
};
