import { CellProps } from 'react-table';
import { DropdownMenu, Icon } from 'legacy-components/componets';
import { DropdownMenuItem } from 'legacy-components/dropdown-menu/common/types/dropdown-menu-item';
import { TourActionsCell, ToursTableRow } from '../common/types/types';
import { useNavigate } from 'react-router-dom';

import Dropdown from 'rc-dropdown';

const TourActionsMenuCell = ({ value }: CellProps<ToursTableRow, TourActionsCell>) => {
  const { estateId, tourId } = value;
  const navigate = useNavigate();

  const menuItems: DropdownMenuItem[] = [
    {
      id: 1,
      title: 'View property profile',
      onClick: () => navigate(`/estates/${estateId}`),
      icon: 'house',
    },
    {
      id: 2,
      title: 'View details',
      onClick: () => navigate(`/i-rent/profile/tours/history/${tourId}`),
      icon: 'faEye',
    },
  ].filter(Boolean) as DropdownMenuItem[];

  return (
    <Dropdown trigger={['click']} overlay={<DropdownMenu menuItems={menuItems} headerTitle={'Property'} />}>
      <Icon name={'dots-menu'} onClick={() => {}} />
    </Dropdown>
  );
};

export { TourActionsMenuCell };
