import { ArrowProps } from 'common/types/types';

const ArrowRight = ({ width, height, color = 'white', onClick }: ArrowProps) => (
  <div className='absolute top-0 right-0 flex-center h-full pr-6 cursor-pointer z-10' onClick={onClick}>
    <div className='flex flex-center bg-opacity-75 bg-primary rounded-full w-12 h-12'>
      <svg aria-label='Next picture' xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 17 30' fill='none'>
        <path
          d='M0.678055 1.31999C0.852226 1.14537 1.05913 1.00684 1.28693 0.912314C1.51472 0.81779 1.75892 0.769135 2.00555 0.769135C2.25218 0.769135 2.49638 0.81779 2.72418 0.912314C2.95197 1.00684 3.15888 1.14537 3.33305 1.31999L15.9481 13.935C16.0871 14.0738 16.1974 14.2386 16.2727 14.42C16.348 14.6015 16.3867 14.796 16.3867 14.9925C16.3867 15.1889 16.348 15.3835 16.2727 15.5649C16.1974 15.7464 16.0871 15.9112 15.9481 16.05L3.33305 28.665C2.59805 29.4 1.41305 29.4 0.678055 28.665C-0.0569452 27.93 -0.0569452 26.745 0.678055 26.01L11.6881 15L0.663054 3.97499C-0.0569455 3.23999 -0.0569452 2.05499 0.678055 1.31999Z'
          fill={color}
        />
      </svg>
    </div>
  </div>
);

export default ArrowRight;
