import { SelectOption } from 'common/types/types';
import { PropertyPersonType } from 'common/enums/app/property.enum';
import {
  PropertyDetailValues,
  PropertyFeesValues,
  PropertyOtherFeeValues,
  PropertyUnitValues,
} from './property-detail';
import { defaultAccessValues } from '../property-building-access/config';
import { defaultAvailabilityValues } from '../property-building-availability/config';

export const propertyPersonTypeToTypeLabelName: Record<PropertyPersonType, string> = {
  [PropertyPersonType.Owner]: 'I am an owner',
  [PropertyPersonType.Agent]: 'I am an agent/broker',
  [PropertyPersonType.Manager]: 'I am a property manager',
};

export const propertyPersonTypeList: SelectOption[] = Object.values(PropertyPersonType).map((value) => ({
  value,
  label: propertyPersonTypeToTypeLabelName[value],
}));

export const bedroomsOptions: SelectOption[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

export const bathsOptions: SelectOption[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

export const leaseTermOptions: SelectOption[] = [
  { label: '3', value: 3 },
  { label: '6', value: 6 },
  { label: '12', value: 12 },
  { label: '18', value: 18 },
  { label: '24', value: 24 },
  { label: '36', value: 36 },
];

export const leaseDurationOptions: SelectOption[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

export const defaultFeeValue: PropertyFeesValues = {
  checked: false,
  price: '',
};

export const defaultOtherFeeValue: PropertyOtherFeeValues = {
  // checked: false,
  name: '',
  price: '',
};

export const defaultUnitItemValue: PropertyUnitValues = {
  unitNumber: '',
  beds: 0,
  baths: 0,
  squareFeet: '',
  leaseDuration: 0,
  availableOn: '',
  deposit: '',
  rent: '',
  description: '',
  firstMonth: '',
  lastMonth: '',
  securityDeposit: defaultFeeValue,
  otherFeestable: [defaultOtherFeeValue],
  amenities: {},
  access: defaultAccessValues,
  availability: defaultAvailabilityValues,
};

export const defaultPropertyDetailsValues: PropertyDetailValues = {
  units: [defaultUnitItemValue],
  images: [],
  videos: [],
  amenities: {},
  generalDescription: '',
};
