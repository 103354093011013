// case when we delete not from last page
const shouldChangeCurrentPage = (totalItemsCount: number, itemsPerPage: number, currentPage: number): boolean => {
    const currentPageItemsCount = currentPage * itemsPerPage;
    return currentPageItemsCount > totalItemsCount;
}

export const getNewCurrentPage = (totalItemsCount: number, itemsPerPage: number, currentPage: number): number => {
    const totalCountAfterSuccessfulAction = totalItemsCount - 1;
    if (shouldChangeCurrentPage(totalItemsCount, itemsPerPage, currentPage)) {
        const result = totalCountAfterSuccessfulAction / itemsPerPage;
        const roundedValue = Math.ceil(result);
        return roundedValue > 1 ? roundedValue : 1;
    }
    return currentPage;
}
