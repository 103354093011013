import _ from 'lodash';
import * as Yup from 'yup';

export const commissionsValidationSchema = Yup.object({
  commissionType: Yup.string(),
  amount: Yup.string()
    .required('Please enter amount of commission')
    .test('can-be-saved', 'Please input valid number', function (value) {
      if (!_.isNumber(Number(value))) return false;
      const commissionType = this.resolve(Yup.ref('commissionType')) as string;
      const amount = Number(value);
      if (commissionType === 'percent' && amount > 999) {
        throw new Yup.ValidationError('Max percent is 999%', value, this.path);
      } else if (commissionType === 'fixedRate' && amount > 9999) {
        throw new Yup.ValidationError('Max fixed count is $9999', value, this.path);
      }
      return true;
    })
    .test('is-positive-number', 'Amount must be a more than 0', (value) => {
      const numericValue = parseFloat(value);
      return !isNaN(numericValue) && numericValue > 0;
    }),
  location: Yup.object({
    address: Yup.string().required('Address is required'),
    state: Yup.string().required('Please enter valid state'),
    city: Yup.string().required('Please enter valid city'),
  }),
});
