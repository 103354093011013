import { getEstateExtra } from 'services/api/estates-api.service';
import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { EstateExtraDto } from 'common/types/services/api/estates/estate-extra-dto.type';
import { QueryKey } from 'common/enums/query-key';

export default function useEstateExtra(
  estateId?: string,
  options?: Omit<
    UndefinedInitialDataOptions<EstateExtraDto | undefined, Error, EstateExtraDto | undefined, (string | undefined)[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    ...options,
    queryKey: [QueryKey.Estates, estateId, 'extra'],
    queryFn: () => {
      if (!estateId) return;
      return getEstateExtra(estateId);
    },
    enabled: !!estateId && !!options?.enabled,
  });
}
