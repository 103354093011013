import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { createNewPaymentMethod } from 'services/api/payment-api.service';

export default function useCreateNewPaymentMethod(
  options?: Omit<
    UndefinedInitialDataOptions<{ secret: string }, Error, { secret: string }, string[]>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    queryFn: createNewPaymentMethod,
    queryKey: ['create-new-payment-method'],
    ...options,
  });
}
