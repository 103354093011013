import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';
import React from 'react';

type TimeSlotProps = {
  slot: string;
  onClick: () => void;
  active: boolean;
};

export const TimeSlot = ({ slot, onClick, active }: TimeSlotProps) => {
  const [hours, minutes] = slot.split(':');
  const startTime = dayjs().set('hours', Number(hours)).set('minutes', Number(minutes));
  const endTime = dayjs(startTime).add(30, 'minutes');

  return (
    <button
      className={twMerge(
        'p-3 rounded-md text-base font-semibold leading-tight bg-zinc-100 text-neutral-800',
        active && 'text-sky-600 bg-sky-50',
      )}
      onClick={onClick}
    >
      {startTime.format('hh:mm')} - {endTime.format('hh:mm')} {startTime.format('A')}
    </button>
  );
};
