import { TourDetailsDto } from 'common/types/types';
import { IEstateCard } from 'legacy-components/card/card';
import { fromEstateCategoryDtoToEstateCategory } from 'common/mappers/estates';

export const staticText = {
  tourDetail: `Please ensure you arrive early to access your keys.`,
                // You will be able to extend your tour by x minutes if you need extra time. 
                // Extend your tour by an additional 15 mins for a minimal charge of $5.`,
  keyDetail: `There are additional details about accessing your property that will be visible shortly before 
                your self-tour. We will notify you when they are available.`,
  checkoutDetail: `Please ensure you lock each door (unit, all access doors) after your tour ends and double check.  
                     Then click Finish Tour on your app to ensure you receive your refundable deposit.  
                     Failure to Finish Tour and secure property may lead to forfeiture of deposit.`,
  contactUs: `Blandit vitae sit pellentesque praesent. Nunc diam ullamcorper sociis cursus in mattis venenatis
                risus. Enim sit volutpat dapibus sagittis diam nec.`,
};

export const fromTourDtoToEstateCard = (data: TourDetailsDto | null): IEstateCard => {
  return {
    id: data?.id || '',
    title: data?.city || '',
    address: data?.location || '',
    category: fromEstateCategoryDtoToEstateCategory(data?.category),
    price: data?.price || 0,
    images: data?.thumbnailUrl ? [data.thumbnailUrl] : [],
    beds: data?.beds || 0,
    bathrooms: data?.bathrooms || 0,
    propertyArea: data?.propertyArea || 0,
    theme: 'horizontal',
  };
};
