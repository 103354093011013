import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';
import { Button, Logo, NavBar } from 'legacy-components/componets';
import ProfileSection from './profile-section/ProfileSection';

const Header = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleAuthNavigateClick = () => {
    navigate('/auth/sign-in');
  };

  return (
    <nav
      className='bg-white'
      style={{
        boxShadow: '0px 4px 36px 4px #97979714',
      }}
    >
      <div className='px-7 md:px-28 md:border-b-0 border-b border-b-[#EFF7FB]'>
        <div className='relative flex h-[74px] items-center justify-between'>
          <Logo />
          <NavBar isAuthenticated={isAuthenticated} />
          {isAuthenticated ? (
            <ProfileSection />
          ) : (
            <Button label={'Sign in'} onClick={handleAuthNavigateClick} theme={'default'} />
          )}
        </div>
      </div>
    </nav>
  );
};

export { Header };
