import { Button, Icon } from 'legacy-components/componets';
import {
  buttonText,
  PAGE_DESCRIPTION,
  PAGE_SUB_TITLE,
  PAGE_TITLE,
  SMS_DESCRIPTION,
  SMS_TITLE,
} from './AccountSecurity.const';
import useGetTwoFactorState from 'hooks/query/user/use-get-two-factor-auth';
import { useChange2FaState } from 'hooks/query/user/use-2fa-user';
import { notification } from 'services/services';

export const AccountSecurity = () => {
  const { data: isUserHasTwoFactorAuth } = useGetTwoFactorState();

  const { mutateAsync: change2FaState, isPending: isLoadingChangingState } = useChange2FaState({
    onError: (e) => {
      notification.error(
        e?.response?.data.detail || 'An error occurred while changing the status of two-factor authentication.',
      );
    },
  });

  return (
    <div className='content grow overflow-x-auto px-2 md:px-0 md:pr-11'>
      <div className='profile-info-header flex items-center mb-2 lg:mb-9 px-4 md:px-[34px]'>
        <p className='title text-xl font-bold'>{PAGE_TITLE}</p>
      </div>
      <div className='grow shadow-white-xl p-4 md:px-[34px] md:py-[28px]'>
        <div className='flex flex-col items-start mb-2 md:mb-9 px-1 sm:px-[34px] lg:px-0 gap-4'>
          <p className='text-xl font-bold uppercase'>{PAGE_SUB_TITLE}</p>
          <p className='text-base text-trueGray'>{PAGE_DESCRIPTION}</p>
        </div>
        <div className='flex items-center bg-secondary max-w-[500px] p-8 rounded-xl mb-2 md:mb-9 gap-7'>
          <Icon name='sms' />
          <div className='flex flex-col '>
            <p className='text-base font-bold'>{SMS_TITLE}</p>
            <p className='text-sm text-trueGray'>{SMS_DESCRIPTION}</p>
          </div>
        </div>
        {isUserHasTwoFactorAuth !== undefined && (
          <Button
            onClick={() => change2FaState(!isUserHasTwoFactorAuth)}
            label={buttonText(isUserHasTwoFactorAuth ? 'Disable' : 'Enable')}
            theme={isUserHasTwoFactorAuth ? 'red-outline' : 'primary'}
            className='w-full max-w-[500px] p-5 md:p-8'
            isLoading={isLoadingChangingState}
          />
        )}
      </div>
    </div>
  );
};
