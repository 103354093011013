import { Icon } from 'legacy-components/componets';
import { ApplicationPropertyIconInfoProps } from '../common/types/applications-summary.type';

const ApplicationPropertyIconInfo = ({ hideUnits, beds, bathrooms, area }: ApplicationPropertyIconInfoProps) => {
  return (
    <div className='icon-info flex gap-4'>
      <div className='flex items-center gap-2 text-[#394150]'>
        <Icon name={'bed'} color={'#66CCFF'} height={20} />
        <span className='text-sm font-medium whitespace-nowrap'>{`${beds || 0} ${hideUnits ? '' : 'Beds'}`}</span>
      </div>
      <div className='flex items-center gap-2 text-[#394150]'>
        <Icon name={'bath'} color={'#66CCFF'} height={20} />
        <span className='text-sm font-medium whitespace-nowrap'>{`${bathrooms || 0} ${hideUnits ? '' : 'Baths'}`}</span>
      </div>
      <div className='flex items-center gap-2 text-[#394150]'>
        <Icon name={'triangle'} color={'#66CCFF'} height={20} />
        <div>
          <span className='text-sm font-medium whitespace-nowrap'>{`${area || 0} m`}</span>
          <sup>2</sup>
        </div>
      </div>
    </div>
  );
};

export { ApplicationPropertyIconInfo };
