import { DatepickerProps, SelectProps } from 'common/types/types';
import {
  GenericPropertyBuildingAvailabilityProps,
  PropertyAvailabilityRangeTime,
  PropertyTourAvailability,
} from './property-building-availability.types';
import { timeList } from 'helpers/selectOptions';
import { tourAvailabilityDays } from './config';
import dayjs from 'dayjs';
import { Button, Datepicker, Icon, Label, Select, Textarea, Toggle } from 'legacy-components/componets';

export const GenericPropertyBuildingAvailability = ({
  setFieldValue,
  nameOfValue,
  itemAvailabilityValue,
  errorAvailabilityType,
  touchedAvailabilityType,
  minDate,
}: GenericPropertyBuildingAvailabilityProps) => {
  const getSelectProps = (
    dayName: keyof PropertyTourAvailability,
    nameKey: keyof PropertyAvailabilityRangeTime,
    placeholder: string,
  ): SelectProps => {
    return {
      name: dayName,
      placeholder,
      options: timeList(),
      value: itemAvailabilityValue?.tourAvailability[dayName].range[nameKey],
      onChange: (value) => setFieldValue(`${nameOfValue}.tourAvailability.${dayName}.range.${nameKey}`, value),
      selectStyle: { container: { width: 120 } },
      disabled: !itemAvailabilityValue?.tourAvailability[dayName].include,
    };
  };

  const getDatepickerProps = (index: number): DatepickerProps<true> => {
    return {
      name: `${nameOfValue}.unavailableDates[${index}].range`,
      onChange: (dates) => {
        const [startDate, endDate] = dates;
        setFieldValue(`${nameOfValue}.unavailableDates[${index}].range.from`, startDate);
        setFieldValue(`${nameOfValue}.unavailableDates[${index}].range.to`, endDate);
      },
      selectsRange: true,
      stylesConfig: { container: { width: '100%' } },
      startDate: itemAvailabilityValue.unavailableDates[index]?.range.from,
      endDate: itemAvailabilityValue.unavailableDates[index]?.range.to,
    };
  };

  const handleAddUnavailableDates = () => {
    setFieldValue(`${nameOfValue}.unavailableDates`, [
      ...itemAvailabilityValue.unavailableDates,
      { range: { from: null, to: null }, reason: '' },
    ]);
  };

  const handleRemoveUnavailableDates = (index: number) => {
    setFieldValue(
      `${nameOfValue}.unavailableDates`,
      itemAvailabilityValue.unavailableDates.filter((item, i) => i !== index),
    );
  };

  return (
    <>
      <div className='tour-availability'>
        <div className='title text-base font-bold pb-2.5'>Self tour availability</div>
        <div className='flex flex-col justify-center gap-[20px]'>
          {tourAvailabilityDays.map((key, index) => {
            const openProps = getSelectProps(key, 'from', 'Open');
            const closedProps = getSelectProps(key, 'to', 'Closed');

            const convertTo24 = (time: string | number) =>
              Number((time ? dayjs(`1/1/1 ${time}`).format('HH:mm:00') : null)?.slice(0, 2));

            const filteredOptions = {
              ...closedProps,
              options: timeList().map((time) => {
                return convertTo24(openProps.value) > convertTo24(time.value) ? { ...time, isDisabled: true } : time;
              }),
            };

            return (
              <div key={index} className='flex flex-wrap items-end gap-[0px] md:gap-[20px]'>
                <div className='mr-0 md:mr-5 flex-grow md:flex-grow-0'>
                  <Toggle
                    width={130}
                    name={`${nameOfValue}.tourAvailability.${key}.include`}
                    value={itemAvailabilityValue?.tourAvailability[key].include}
                    onChange={() =>
                      setFieldValue(
                        `${nameOfValue}.tourAvailability.${key}.include`,
                        !itemAvailabilityValue?.tourAvailability[key].include,
                      )
                    }
                    checked={itemAvailabilityValue?.tourAvailability[key].include}
                    label={`${key}`}
                    stylesConfig={{ labelStyle: { textTransform: 'capitalize' } }}
                  />
                </div>
                <div className='flex gap-[10px] md:gap-[20px]'>
                  <Label label={'Open'}>
                    <Select
                      {...getSelectProps(key, 'from', 'Open')}
                      invalid={
                        Boolean(errorAvailabilityType?.tourAvailability?.[key]?.range?.from) &&
                        touchedAvailabilityType?.tourAvailability?.[key]?.range?.from
                      }
                    />
                  </Label>

                  <Label label={'Closed'}>
                    <Select
                      {...filteredOptions}
                      invalid={
                        Boolean(errorAvailabilityType?.tourAvailability?.[key]?.range?.to) &&
                        touchedAvailabilityType?.tourAvailability?.[key]?.range?.to
                      }
                    />
                  </Label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className='custom-overrides hidden'>
        <div className='title text-base font-bold pb-2.5'>Custom overrides</div>
        <div className='description text-base text-trueGray'>
          Are there specific units that have a different availability?
        </div>
        <div className='flex justify-end pt-2.5'>
          <Button label={'Add custom override'} icon={'plus'} theme={'primary-outline'} onClick={() => null} />
        </div>
      </div>

      <div className='unavailable-dates'>
        <div className='title text-base font-bold pb-2.5'>Unavailable dates</div>
        <div className='description text-base text-trueGray mb-4'>
          Are there specific dates you would like to mark the property as unavailable for a viewing?
        </div>

        <div className='unavailable-dates-list flex flex-col gap-3'>
          {itemAvailabilityValue.unavailableDates.map((dates, index) => {
            return (
              <div key={index} className='flex items-start gap-2.5'>
                <div className='flex flex-col w-full'>
                  <div className='w-full'>
                    <Label label={'Unavailable dates'}>
                      <Datepicker {...getDatepickerProps(index)} minDate={minDate} />
                    </Label>
                  </div>
                  <div className='w-full'>
                    <Label label={'Reason'}>
                      <Textarea
                        name={'note'}
                        placeholder='Reason'
                        value={itemAvailabilityValue.unavailableDates[index]?.reason}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setFieldValue(`${nameOfValue}.unavailableDates.${index}.reason`, e.target.value)
                        }
                      />
                    </Label>
                  </div>
                </div>
                <div className='delete-icon-wrapper flex items-center h-6 pt-6 w-1/12 mt-6'>
                  <div className='cursor-pointer' onClick={() => handleRemoveUnavailableDates(index)}>
                    <Icon name={'delete'} color={'#66CCFF'} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className='flex justify-start pt-2.5'>
          <Button icon={'plus'} label={'Add date'} theme={'primary-outline'} onClick={handleAddUnavailableDates} />
        </div>
      </div>
    </>
  );
};
