import { Link, useNavigate } from 'react-router-dom';
import { Divider } from 'legacy-components/componets';
import { AuthRole } from 'common/enums/enums';
import { landlordDropdownMenuList, renterDropdownMenuList } from './config';
import styles from './styles';
import { AdminProfileDropdown } from './admin';
import React from 'react';
import Dropdown from 'rc-dropdown';
import { useSavedSearchUnreadCount } from 'hooks/query';
import { logout } from 'services/api/auth-api.service';

export type ProfileDropdownMenuProps = {
  authRoles: AuthRole[];
  children: React.ReactElement;
};

export const ProfileDropdown = ({ authRoles, children }: ProfileDropdownMenuProps) => {
  const navigate = useNavigate();
  const { data } = useSavedSearchUnreadCount();
  const { count } = data || {};

  const handleRenterChangePasswordMenuItemClick = () => {
    navigate('i-rent/change-password');
  };

  const handleLandlordChangePasswordMenuItemClick = () => {
    navigate('i-landlord/change-password');
  };

  const renterDropdown = (() => {
    return (
      <div className={styles.container}>
        {renterDropdownMenuList.map(({ title, id, link, notification }) => (
          <Link key={id} className={styles.item} to={link}>
            <div className='flex gap-3'>
              <div>{title}</div>
              {notification && !!count && count > 0 && (
                <div className='bg-primary rounded-full p-1 text-xs w-7 h-7 flex items-center justify-center'>
                  <p className='text-white'>{count}</p>
                </div>
              )}
            </div>
          </Link>
        ))}
        <Divider />
        <div className={styles.item} onClick={handleRenterChangePasswordMenuItemClick}>
          Change Password
        </div>
        <div className={styles.item} onClick={logout}>
          Log Out
        </div>
      </div>
    );
  })();

  const landlordDropdown = (() => {
    return (
      <div className={styles.container}>
        {landlordDropdownMenuList.map(({ title, id, link }) => (
          <Link key={id} className={styles.item} to={link}>
            {title}
          </Link>
        ))}
        <Divider />
        <div className={styles.item} onClick={handleLandlordChangePasswordMenuItemClick}>
          Change Password
        </div>
        <div className={styles.item} onClick={logout}>
          Log Out
        </div>
      </div>
    );
  })();

  const content = (() => {
    switch (Boolean(true)) {
      case authRoles.includes(AuthRole.Renter):
        return renterDropdown;
      case authRoles.includes(AuthRole.Landlord):
        return landlordDropdown;
      case authRoles.includes(AuthRole.SuperUser):
        return <AdminProfileDropdown />;
    }
  })();

  return (
    <Dropdown trigger={'click'} overlay={content} placement={'bottomRight'}>
      <button>{children}</button>
    </Dropdown>
  );
};
