import * as Yup from 'yup';

export enum PasswordValidation {
  Required = 'Password is required',
  MinLength = 'At least 8 characters',
  LowerCase = 'Lower-case letters (a-z)',
  UpperCase = 'Upper case letters (A-Z)',
  SpecialCharacters = 'Special characters (e.g.! @#$)',
  Numbers = 'Numbers (0-9)',
}

export const passwordValidation = Yup.string()
  .required(PasswordValidation.Required)
  .min(8, PasswordValidation.MinLength)
  .matches(/[a-z]+/, PasswordValidation.LowerCase)
  .matches(/[A-Z]+/, PasswordValidation.UpperCase)
  .matches(/[@$!%*#?&]+/, PasswordValidation.SpecialCharacters)
  .matches(/\d+/, PasswordValidation.Numbers);
