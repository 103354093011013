import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { deleteSavedSearch } from 'services/api/saved-search-api.service';
import { notification } from 'services/services';
import { CreateSavedSearchFiltersDto } from 'common/types/services/api/user/user-saved-search.type';
import { QueryKey } from 'common/enums/query-key';

export default function useDeleteSavedSearch(
  options?: Omit<UseMutationOptions<boolean, Error, string, unknown>, 'mutationFn' | 'onMutate'>,
) {
  const queryClient = useQueryClient();

  return useMutation<boolean, Error, string, { previousSavedSearch?: CreateSavedSearchFiltersDto[] }>({
    mutationFn: deleteSavedSearch,
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: [QueryKey.SavedSearch] });
      const previousSavedSearch = queryClient.getQueryData<CreateSavedSearchFiltersDto[]>([QueryKey.SavedSearch]);
      queryClient.setQueryData<CreateSavedSearchFiltersDto[]>([QueryKey.SavedSearch], (old) => {
        if (!old) return undefined;
        return old.filter((el) => el.id !== id);
      });
      return { previousSavedSearch };
    },
    onError: (err, id, context) => {
      queryClient.setQueryData([QueryKey.SavedSearch], context?.previousSavedSearch);
      notification.error('Saved Search filter was not deleted');
    },
    onSuccess: () => {
      notification.success('Saved Search filter was deleted');
    },
    ...options,
  });
}
