import { CellProps } from 'react-table';
import { dateFormatMMMM_DD, dateTimeFormatHHMM_A } from 'helpers/date';
import dayjs from 'dayjs';
import { SavedSearchDateCellProps, SavedSearchTableRow } from '../SavedSearchTable.types';

const SavedSearchDateCell = ({ value }: CellProps<SavedSearchTableRow, SavedSearchDateCellProps>) => {
  const utcDate = dayjs(value);

  return (
    <div className='flex flex-col text-base text-trueGray gap-0.5 pl-2.5'>
      <span>{`${dateFormatMMMM_DD(utcDate)},`}</span>
      <span>{dateTimeFormatHHMM_A(utcDate)}</span>
    </div>
  );
};

export { SavedSearchDateCell };
