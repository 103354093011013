import { notification } from 'services/services';

export const fetchFile = async (
  url: string,
  setIsLoading: (value: React.SetStateAction<boolean>) => void,
  setFile: (value: React.SetStateAction<File>) => void,
  onClose: () => void,
) => {
  try {
    setIsLoading(true);
    const response = await fetch(url);
    const blob = await response.blob();
    const filename = getFileNameFromUrl(url);
    const downloadedFile = new File([blob], filename, { type: blob.type });
    setFile(downloadedFile);
  } catch (error) {
    notification.error('Sory, but we could not upload file');
    onClose();
    setFile({} as File);
  } finally {
    setIsLoading(false);
  }
};

export const getFileNameFromUrl = (url: string) => {
  const segments = url.split('/');
  return segments[segments.length - 1];
};
