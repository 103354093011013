export type VerificationFileDto = {
  id: string;
  fileName: string;
  fileUrl: string;
  type: VerificationFileType | DocumentsFileType;
  status: string;
};

export type UploadVerificationFileDto = {
  file: File;
  type: VerificationFileType | DocumentsFileType;
};

export enum VerificationFileType {
  Passport = 'Passport',
  DriverLicense = 'DriverLicense',
  Education = 'Education',
  Employee = 'Employee',
  OfferLetter = 'OfferLetter',
  GuarantorLetter = 'GuarantorLetter',
}

export enum DocumentsFileType {
  Passport = 'Passport',
  DriverLicense = 'DriverLicense',
}

type FileValue = { url?: string; file?: File; id?: string };

export type UpdateVerificationFiles = {
  filesToUpload: [string, FileValue][];
  filesToRemove: [string, FileValue][];
};
