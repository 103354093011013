enum MimeTypes {
  JSON = 'application/json',
  CSV = 'text/csv',
  XML = 'text/xml',
  TXT = 'text/plain',
  PDF = 'application/pdf',
  JPG = 'image/jpeg',
  PNG = 'image/png',
}

const downloadFile = (data: BlobPart, fileName: string = 'File', format: MimeTypes) => {
  const blob = new Blob([data], { type: `${format};charset=utf-8;` });
  const link = document.createElement('a');

  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export { downloadFile, MimeTypes };
