import { FC } from 'react';
import clsx from 'clsx';
import { TourStatusProps } from './TourStatus.types';
import { commonStatusStyle, styles, textStyle } from './TourStatus.styles';
import { mapTourStatus } from './TourStatus.utils';

export const TourStatus: FC<TourStatusProps> = ({ tourStatus, size }) => {
  const statusStyle = styles[tourStatus];
  const textStatusStyle = size ? textStyle(size) : 'text-xs md:text-sm lg:text-[15px]';
  const combinedStyle = clsx(statusStyle, commonStatusStyle, textStatusStyle);
  return (
    <div className={combinedStyle}>
      <span>{`${mapTourStatus[tourStatus]}`}</span>
    </div>
  );
};
