import { EstatesApiPath } from 'common/enums/services/api/paths/estates-api-path.enum';
import { ListingRequestDto } from 'common/types/landlord/listing-dto.type';
import { EstateDto, EstatePrice } from 'common/types/services/api/estates/estate-dto.type';
import { EstateExtraDto } from 'common/types/services/api/estates/estate-extra-dto.type';
import { EstateFiltersRequestDto } from 'common/types/services/api/estates/estate-filters-request-dto';
import {
  EstateListDashboardDto,
  EstateListForApprovaldDto,
} from 'common/types/services/api/estates/estate-list-dto.type';
import { EstatePriceRequest } from 'common/types/services/api/estates/estate-price-request';
import qs from 'qs';
import { axiosInstance } from './axiosInstance';
import { EstateSubscriptionType } from 'common/types/services/api/estates/estate-sub-dto.type';
import { EstatePlanDto } from 'common/types/services/api/estates/estate-plan-dto.type';

export const getAdminEstates = (query: EstateFiltersRequestDto): Promise<EstateListForApprovaldDto> => {
  return axiosInstance.get(`${EstatesApiPath.GET_ADMIN_ESTATES}?${qs.stringify(query)}`);
};

export const approveEstate = (estateId: string): Promise<void> => {
  return axiosInstance.post(`${EstatesApiPath.GET_ADMIN_ESTATES}/${estateId}/approve`);
};

export const updateToNewStatusEstate = (estateId: string): Promise<void> => {
  return axiosInstance.post(`${EstatesApiPath.GET_ADMIN_ESTATES}/${estateId}/new`);
};

export const rejectEstate = ({ estateId, reason }: { estateId: string; reason: string }): Promise<void> => {
  return axiosInstance.post(`${EstatesApiPath.GET_ADMIN_ESTATES}/${estateId}/reject`, { reason });
};

export const getMyEstates = (query: ListingRequestDto): Promise<EstateListDashboardDto> => {
  const queryParams = {
    sorters: query.sorters,
    filters: {
      status: query.status ? query.status : undefined,
      type: query.type ? query.type : undefined,
      category: query.category ? query.category : undefined,
    },
    page: query.page ? query.page : undefined,
    perPage: query.perPage ? query.perPage : undefined,
  };
  const queryString = qs.stringify(queryParams, { encode: false, arrayFormat: 'repeat' });
  const url = `${EstatesApiPath.GET_MY_ESTATES}?${queryString}`;
  return axiosInstance.get(url);
};

export const getEstatesList = (query: EstateFiltersRequestDto): Promise<EstateListDashboardDto> => {
  const sortersQuery = query?.order && query?.sortBy ? { [query.sortBy]: query?.order } : undefined;
  const queryParams = {
    filters: {
      city: query.city ? query.city : undefined,
      zip: query.zip ? query.zip : undefined,
      street: query.street ? query.street : undefined,
      state: query.state ? query.state : undefined,
      lat: query.lat ? query.lat.split(',') : undefined,
      lng: query.lng ? query.lng.split(',') : undefined,
      type: query.type ? query.type : undefined,
      location: query.location ? query.location : undefined,
      beds: query.beds ? query.beds : undefined,
      bathrooms: query.bathrooms ? query.bathrooms : undefined,
      category: query.categories ? query.categories.map((category) => `OR%${category}`) : undefined,
      price: [query.priceMin ? `>=${query.priceMin}` : undefined, query.priceMax ? `<=${query.priceMax}` : undefined],
    },
    sorters: {
      ...sortersQuery,
    },
    page: query.page ? query.page : undefined,
    perPage: query.perPage ? query.perPage : undefined,
  };
  const queryString = qs.stringify(queryParams, { encode: false, arrayFormat: 'repeat' });
  const url = `${EstatesApiPath.GET_ESTATES}?${queryString}`;
  return axiosInstance.get(url);
};

export const getLastEstate = (): Promise<EstateListDashboardDto> => {
  const queryParams = {
    page: 1,
    perPage: 1,
    sorters: {
      postedAt: 'DESC',
    },
  };
  const queryString = qs.stringify(queryParams, { encode: false, arrayFormat: 'repeat' });
  const url = `${EstatesApiPath.GET_ESTATES}/simple?${queryString}`;
  return axiosInstance.get(url);
};

export const getEstateDetails = (id: string): Promise<EstateDto> => {
  return axiosInstance.get(`${EstatesApiPath.GET_ESTATES}/${id}`);
};

export const getEstateExtra = (id: string): Promise<EstateExtraDto> => {
  return axiosInstance.get(`${EstatesApiPath.GET_ESTATES}/${id}/extra`);
};

export const getEstateUnitPrice = ({ estateId, unitId }: EstatePriceRequest): Promise<EstatePrice> => {
  return axiosInstance.get(`${EstatesApiPath.GET_ESTATES}/${estateId}/unit/${unitId}/price`);
};

export const getEstatesSubscription = (estateId: string): Promise<EstateSubscriptionType> => {
  return axiosInstance.get(`${EstatesApiPath.GET_ESTATES}/${estateId}/subscription`);
};

export const paymentPendingEstate = (estateId: string): Promise<void> => {
  return axiosInstance.post(`${EstatesApiPath.GET_ESTATES}/${estateId}/paymentPending`);
};

export const getEstatePlans = (): Promise<EstatePlanDto[]> => {
  return axiosInstance.get(`${EstatesApiPath.GET_ESTATES}/plan`);
};

export const updateEstateExtraViews = ({ estateId, unitId }: EstatePriceRequest): Promise<void> => {
  return axiosInstance.put(`${EstatesApiPath.GET_ESTATES}/${estateId}/unit/${unitId}/views`);
};

export const getEstateExtraNotAuthorized = (id: string): Promise<EstateExtraDto> => {
  return axiosInstance.get(`${EstatesApiPath.GET_ESTATES}/${id}/extraNotAuthorized`);
};
