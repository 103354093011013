import { FC } from 'react';
import { Button, Input, Label, Modal, PhoneInput, Select, Textarea } from 'legacy-components/componets';
import { useAuth, useWindowSize } from 'hooks';
import { Link } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import {
  ALREADY_HAVE_ACCOUNT,
  AUTH_MODAL_TITLE,
  CONFIRM_BTN,
  DESCRIPTION_TITLE,
  DETAILED_MSG,
  LOGIN,
  MESSAGE_PLACEHOLDER,
  PERSONAL_DETAILS,
} from './HelpSupportModal.const';
import { InputProps } from 'common/types/types';
import { useFormik } from 'formik';
import { labelStyleConfig, questionTitleOptions, userProfileTypeOptions } from './HelpSupportModal.config';
import { HelpSupportModalProps, HelpSupportValues } from './HelpSupportModal.types';
import {
  helpSupportForAuthUserValidationSchema,
  helpSupportForNotAuthUserValidationSchema,
} from 'validations/help-support.validation';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';
import { useContactUs } from 'hooks/query';
import { NotificationType } from 'common/enums/services/notification/notification-type.enum';
import { openNotificationModal } from 'store/use-notification-modal-store';

export const HelpSupportModal: FC<HelpSupportModalProps> = ({ open, onClose }) => {
  const { isMobile } = useWindowSize();
  const { isAuthenticated } = useAuth();

  const { mutate: contactUs, isPending } = useContactUs({
    onSuccess: () => {
      openNotificationModal({
        type: NotificationType.SUCCESS,
        title: 'Thank you for reaching out!',
        description: 'Our team will respond to your message shortly.',
      });
    },
    onError: () => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: 'Something went wrong',
      });
    },
    onSettled: () => {
      closeForm();
    },
  });

  const { values, errors, touched, handleChange, setFieldValue, submitForm, resetForm } = useFormik<HelpSupportValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      profile: 'General',
      message: '',
      title: 'Other'
    },
    validationSchema: isAuthenticated
      ? helpSupportForAuthUserValidationSchema
      : helpSupportForNotAuthUserValidationSchema,
    onSubmit: async (value) => {
      const request = isAuthenticated
        ? {
            message: value.message,
            title: value.title
          }
        : {
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            phoneNumber: value.phoneNumber,
            profile: value.profile,
            message: value.message,
            title: value.title
          };
      contactUs(request);
    },
  });

  const getCommonInputProps = (name: keyof HelpSupportValues, placeholder: string): InputProps => {
    return {
      name,
      placeholder,
      value: values[name],
      onChange: handleChange,
      invalid: Boolean(errors[name] && touched[name]),
    };
  };

  const closeForm = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal
      onClose={closeForm}
      isOpen={open}
      styles={{
        overlay: { zIndex: 100 },
        content: {
          maxHeight: '100%',
          ...(isMobile && {
            bottom: 'auto',
            height: 'auto',
            top: !isAuthenticated ? 'auto' : '25%',
            transform: 'none',
            left: 0,
            right: 0,
          }),
        },
      }}
    >
      <div className='p-8 md:min-w-[750px]'>
        <div className='flex items-top justify-center gap-4'>
          <div className='flex-grow flex items-center justify-center'>
            <h3 className={`text-black mb-5 font-semibold xs:text-2xl md:text-4xl`}>{AUTH_MODAL_TITLE}</h3>
          </div>
          <button onClick={closeForm} type={'button'} className='flex justify-start'>
            <CloseOutlined className={'text-primary text-[22px]'} />
          </button>
        </div>
        {!isAuthenticated ? (
          <>
            <h3 className='text-black font-semibold text-xl mb-2'>{PERSONAL_DETAILS}</h3>
            <h3 className='text-trueGray text-base mt-2 mb-2'>{DETAILED_MSG}</h3>
            <div className='flex flex-col gap-4'>
              <div className='flex items-center gap-2'>
                <h3 className='text-trueGray text-sm mt-2 mb-2 font-bold'>{ALREADY_HAVE_ACCOUNT}</h3>
                <Link to={'/auth/sign-in'} className={`text-primary text-sm`}>
                  {LOGIN}
                </Link>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div className='w-full'>
                  <Label label={'First Name'} styleConfig={labelStyleConfig}>
                    <ErrorValidationMessage touched={touched.firstName} message={errors.firstName}>
                      <Input {...getCommonInputProps('firstName', 'First Name')} />
                    </ErrorValidationMessage>
                  </Label>
                </div>
                <div className='w-full'>
                  <Label label={'Last Name'} styleConfig={labelStyleConfig}>
                    <ErrorValidationMessage touched={touched.lastName} message={errors.lastName}>
                      <Input {...getCommonInputProps('lastName', 'Last Name')} />
                    </ErrorValidationMessage>
                  </Label>
                </div>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div className='w-full'>
                  <Label label={'Email'} styleConfig={labelStyleConfig}>
                    <ErrorValidationMessage touched={touched.email} message={errors.email}>
                      <Input {...getCommonInputProps('email', 'Email')} />
                    </ErrorValidationMessage>
                  </Label>
                </div>
                <div className='w-full'>
                  <Label label={'Account Type'} styleConfig={labelStyleConfig}>
                    <ErrorValidationMessage touched={touched.profile} message={errors.profile}>
                      <Select
                        name={'profile'}
                        isSearchable={false}
                        options={userProfileTypeOptions}
                        onChange={(option: any) => setFieldValue('profile', option.value)}
                        value={values.profile}
                        placeholder=''
                      />
                    </ErrorValidationMessage>
                  </Label>
                </div>
              </div>
              <div className='w-full mb-4'>
                <Label label={'Phone Number'} styleConfig={labelStyleConfig}>
                  <ErrorValidationMessage touched={touched.phoneNumber} message={errors.phoneNumber}>
                    <PhoneInput
                      isValid={!Boolean(errors.phoneNumber && touched.phoneNumber)}
                      value={values.phoneNumber}
                      onChange={(value) => {
                        setFieldValue('phoneNumber', value);
                      }}
                    />
                  </ErrorValidationMessage>
                </Label>
              </div>
            </div>
          </>
        ) : null}
        {/* <div className='w-full mb-3'>
          <Label label={'Question title'} styleConfig={labelStyleConfig}>
            <ErrorValidationMessage touched={touched.title} message={errors.title}>
              <Select
                name={'title'}
                options={questionTitleOptions}
                isSearchable={false}
                onChange={(option: any) => setFieldValue('title', option.value)}
                value={values.title}
                placeholder=''
              />
            </ErrorValidationMessage>
          </Label>
        </div> */}
        <Label label={DESCRIPTION_TITLE} styleConfig={labelStyleConfig}>
          <ErrorValidationMessage touched={touched.message} message={errors.message}>
            <Textarea
              name={'message'}
              value={values.message}
              onChange={(e) => setFieldValue('message', e.target.value)}
              inputClassName='resize-none'
              invalid={Boolean(errors.message && touched.message)}
              placeholder={MESSAGE_PLACEHOLDER}
            />
          </ErrorValidationMessage>
        </Label>
        <Button
          className='w-full py-3 mt-4'
          label={CONFIRM_BTN}
          onClick={async () => {
            await submitForm();
          }}
          isLoading={isPending}
        />
      </div>
    </Modal>
  );
};
