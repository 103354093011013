import { Alert } from 'antd';
import { TourStatusDto } from 'common/enums/enums';
import { useWindowSize } from 'hooks';
import { useLandlordScheduledTours } from 'hooks/query';
import { Button, Icon } from 'legacy-components/componets';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const LandlordUnconfirmedToursAlert = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isTablet } = useWindowSize();
  const { data: tours, refetch } = useLandlordScheduledTours(
    {
      page: 1,
      perPage: 1,
      filters: {
        status: [TourStatusDto.Scheduled],
        isConfirmed: false,
      },
      sorters: { slot: 'desc' },
    },
    {
      placeholderData: (previousValue) => previousValue,
      refetchInterval: 3 * 60 * 1000,
    },
  );



  const handleView = () => {
    navigate('/i-landlord/profile/schedule/table?status=Scheduled&showOnly=unconfirmed');
  };

  useEffect(() => {
    refetch()
  }, [location])

  if (!tours || !tours?.totalCount) {
    return null;
  }

  return (
    <Alert
      style={{
        margin: '8px',
        paddingLeft: !isTablet ? '110px' : undefined,
        paddingRight: !isTablet ? '100px' : undefined,
        background: '#f1fbff',
        borderColor: '#66ccff'
      }}
      action={
        <Button onClick={handleView} className='ml-3' startIcon={<Icon name={'faEye'} />} label={'View'} theme={'primary-outline'} />
      }
      message={<span className='font-bold text-lg'>{tours?.totalCount > 1 ? 'You have an unconfirmed tours' : 'You have an unconfirmed tour'}: {tours?.totalCount}</span>}
      description={tours?.totalCount > 1 ? 'Please, confirm these tours!' : 'Please, confirm this tour!'}
      type='info'
      showIcon
    />
  );
};

export { LandlordUnconfirmedToursAlert };
