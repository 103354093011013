import { Fragment } from 'react';
import { TabPanel } from 'legacy-components/componets';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminToursCalendar from './AdminToursCalendar';
import AdminToursTable from './AdminToursTable';

const AdminTours = () => {
  return (
    <Fragment>
      <div className='grow p-4 md:px-[34px] md:py-[28px] max-w-full overflow-x-auto shadow-white-xl'>
        <TabPanel
          tabs={[
            { to: 'table', title: 'Tours Table' },
            { to: 'calendar', title: 'Tours Calendar' },
          ]}
        />
        <Routes>
          <Route
            path={'/'}
            element={
              <div className='flex flex-1 w-full h-[500px] shadow-white-xl'>
                <Navigate to={'table'} replace />
              </div>
            }
          />
          <Route path='table' index element={<AdminToursTable />} />
          <Route path='calendar' element={<AdminToursCalendar />} />
        </Routes>
      </div>
    </Fragment>
  );
};

export default AdminTours;
