import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NotificationsDto } from 'common/types/services/api/notification/notifications-dto.type';
import { PaginationDtoRequest } from 'common/types/services/api/common/pagination-dto.type';
import { readNotifications } from 'services/api/notification-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useReadNotifications(params?: PaginationDtoRequest) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: readNotifications,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: [QueryKey.Notifications] });
      const previousNotifications = queryClient.getQueryData([QueryKey.Notifications, params]);
      queryClient.setQueryData<NotificationsDto>([QueryKey.Notifications, params], (old) => {
        if (!old) return undefined;
        return {
          ...old,
          items: old.items.map((item) => ({ ...item, isRead: true })),
          totalUnread: 0,
        };
      });
      return { previousNotifications };
    },
    onError: (err, newNotifications, context) => {
      queryClient.setQueryData([QueryKey.Notifications, params], context?.previousNotifications);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Notifications] });
    },
  });
}
