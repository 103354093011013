import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { OffersItemDto } from 'common/types/services/api/offers/offer-item-dto.type';
import { getOfferDetails } from 'services/api/offers-api.service';
import { QueryKey } from 'common/enums/query-key';
import { useQueryCustomErrorHandler } from 'hooks/query/use-query-custom-error-handler';

export default function useOfferDetails(
  offerId: string,
  options?: Omit<UndefinedInitialDataOptions<OffersItemDto | undefined, Error, OffersItemDto, string[]>, 'queryFn' | 'queryKey'>,
) {
  const { onError } = useQueryCustomErrorHandler();
  return useQuery({
    queryKey: [QueryKey.Offers, offerId],
    queryFn: () => getOfferDetails(offerId).catch(onError),
    ...options,
  });
}
