import { InputTheme } from 'common/types/types';

const styles: Record<InputTheme, { container: string; label: string; input: string; error: string }> = {
  default: {
    container: '',
    label: 'block mb-1 text-sm font-medium text-black',
    input:
      'block shadow-input-shadow w-full h-11 py-2 px-3 text-black border border-neutral-200 rounded-md hover:border-primary focus:border-primary focus:outline-none transition',
    error: '!border-error !focus:border-error',
  },
  primary: {
    container: '',
    label: 'block mb-1 text-sm font-medium text-black',
    input:
      'block w-full h-11 pt-3 pb-3 pl-0 text-black border-b border-warmGray focus:border-primary focus:outline-none',
    error: '!border-b-error !focus:border-b-error',
  },
};

export default styles;
