import React, { useMemo } from 'react';
import { Button, Table } from 'legacy-components/componets';
import { accessAccountTableColumns } from './access-accounts-table/helpers/acces-accounts-table-columns.helper';
import { AccessAccountsTableRow, TablePaginationProps } from 'common/types/types';
import { accessAccountsDtoToTable } from './access-accounts-table/mappers/access-accounts-dto-to-table.mappers';
import { useWindowSize } from 'hooks';
import { useConnectedAccessAccounts, useCreateAccessAccount } from 'hooks/query';
import { NumberParam, QueryParamConfig, useQueryParams, withDefault } from 'use-query-params';

export type AccessAccountsQueryParamConfig = {
  page: QueryParamConfig<number>;
};

const AccessAccounts = () => {
  const maxItemsPerPage = 5;
  const queryParamConfig = useMemo<AccessAccountsQueryParamConfig>(
    () => ({
      page: withDefault(NumberParam, 1),
    }),
    [],
  );
  const [query, setQuery] = useQueryParams(queryParamConfig);
  const { data, isLoading, isFetching } = useConnectedAccessAccounts(
    { page: query.page, perPage: maxItemsPerPage },
    {
      placeholderData: (previousValue) => previousValue,
    },
  );
  const { totalCount: total = 0 } = data || {};
  const { isMobile } = useWindowSize();
  const { mutate: createAccessAccount, isPending: isCreateAccessAccountPending } = useCreateAccessAccount();

  const paginationCallback = React.useCallback((newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  }, []);

  const formattedTableData = React.useMemo<AccessAccountsTableRow[]>(() => {
    return accessAccountsDtoToTable(data?.items || []);
  }, [data]);

  const paginationTableProps = React.useMemo<TablePaginationProps>(() => {
    return {
      currentPage: query.page,
      totalCount: total,
      pageSize: maxItemsPerPage,
      onPageChange: paginationCallback,
    };
  }, [query.page, total, maxItemsPerPage, paginationCallback]);

  return (
    <div className='flex flex-col gap-9 w-full overflow-x-auto shadow-white-xl px-4 md:px-[34px] py-4 md:py-6 relative'>
      <div className='flex justify-between items-center'>
        <div className='title text-xl font-bold'>{'Access Accounts'}</div>
        <Button
          onClick={() => createAccessAccount()}
          label={isMobile ? '' : 'Add Access Account'}
          theme={'primary'}
          icon={'plus'}
          isLoading={isCreateAccessAccountPending}
        />
      </div>
      <Table
        data={formattedTableData}
        columns={accessAccountTableColumns}
        isLoading={isLoading || isFetching}
        paginationProps={paginationTableProps}
      />
    </div>
  );
};

export { AccessAccounts };
