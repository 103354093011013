import SortOrderEnumParam from 'query-params/sortOrderEnumParam';
import { useMemo } from 'react';
import { NumberParam, StringParam, createEnumDelimitedArrayParam, withDefault } from 'use-query-params';
import { AdminEstateQueryParamConfig } from './AdminProfileListing.types';
import { TablePaginationProps } from 'common/types/types';
import { AdminEstateTableRow } from './AdminProfileListingTable/AdminProfileListingTable.types';
import { EstateDtoForApproval } from 'common/types/services/api/estates/estate-dto.type';
import { EstateStatusDto, PropertyCategory, PropertyDtoType } from 'common/enums/enums';
import { estateCategoryToTypeName, estateTypeToTypeName } from 'legacy-pages/estates/estates-list/config';
import { EstateAdminList } from 'common/types/services/api/estates/estate-list-dto.type';

export const useQueryParamConfig = () => {
  const defaultStatuses = [EstateStatusDto.PendingApproval, EstateStatusDto.Available, EstateStatusDto.Rejected];
  return useMemo<AdminEstateQueryParamConfig>(
    () => ({
      status: withDefault(
        createEnumDelimitedArrayParam(Object.values(EstateStatusDto) as `${EstateStatusDto}`[], ','),
        defaultStatuses,
      ),
      category: withDefault(StringParam, ''),
      type: withDefault(StringParam, ''),
      page: withDefault(NumberParam, 1),
      order: withDefault(SortOrderEnumParam, 'desc'),
    }),
    [],
  );
};

export const useCreateTablePaginationProps = (
  currentPage: number,
  totalCount: number | undefined,
  maxItemPerPage: number,
  setCurrentPage: (newPage: number) => void,
): TablePaginationProps =>
  useMemo(() => {
    return {
      currentPage,
      totalCount: totalCount || 0,
      pageSize: maxItemPerPage || 5,
      onPageChange: setCurrentPage,
    };
  }, [currentPage, totalCount, maxItemPerPage]);

type AdminEstateActionType = (item: string) => void;

export const createAdminTableData = (
  estates: EstateAdminList[],
  onViewEstate: AdminEstateActionType,
  onApprove: AdminEstateActionType,
  onDeleteEstate: AdminEstateActionType,
  updateToNew: AdminEstateActionType
): AdminEstateTableRow[] => {
  return estates.map((estate) => {
    return {
      landlord: estate?.createdByName,
      date: new Date(estate.paidAt),
      location: estate?.location,
      category: mapEstateCategory(estate?.category),
      type: mapEstateType(estate?.type),
      status: estate.status,
      actions: {
        onView: () => onViewEstate(estate?.id),
        onApprove: () => onApprove(estate?.id),
        onCancel: () => onDeleteEstate(estate?.id),
        updateToNew: () => updateToNew(estate?.id),
        status: estate.status,
        canRejectEstate: estate?.canRejectEstate
      },
    };
  });
};

const mapEstateType = (type: string) => estateTypeToTypeName[type as PropertyDtoType];
const mapEstateCategory = (type: string) => estateCategoryToTypeName[type as PropertyCategory];
