import { Notification } from 'services/notification.service';

export const downloadFile = (file: File) => {
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = file.name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export async function downloadFileByUrl(url: string) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const filename = getFileNameFromUrl(url);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (error) {
    const notification = new Notification();
    notification.error('Sory, but we could not download file');
  }
}

function getFileNameFromUrl(url: string) {
  const segments = url.split('/');
  return segments[segments.length - 1];
}

export const maxItemPerPage = 10;
