import React from 'react';
import clsx from 'clsx';
import ReactPhoneInput, { PhoneInputProps as ReactPhoneInputProps } from 'react-phone-input-2';
import { colors } from 'colors';

const PhoneInput = ({
  value,
  isValid,
  placeholder,
  onChange,
  country = 'us',
  preferredCountries = ['us'],
  ...restProps
}: ReactPhoneInputProps) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const borderStyle = isValid && !isFocused ? colors.lightGrey : isValid && isFocused ? colors.primary : colors.error;

  return (
    <ReactPhoneInput
      specialLabel={''}
      value={value}
      onChange={onChange}
      isValid={isValid}
      inputStyle={{
        boxShadow: 'unset',
        borderColor: borderStyle,
      }}
      inputClass={clsx(
        'phone-input !w-full !h-11 p-3 text-black border-neutral-200 rounded-md !shadow-input-shadow hover:border-primary',
        !isValid && '!hover:border-error',
      )}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      country={country}
      preferredCountries={preferredCountries}
      {...restProps}
    />
  );
};

export { PhoneInput };
