import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { OfferItemDto } from 'common/types/services/api/offers/offer-item-dto.type';
import { getRenterOfferById } from 'services/api/offers-api.service';
import { QueryKey } from 'common/enums/query-key';
import { useQueryCustomErrorHandler } from 'hooks/query/use-query-custom-error-handler';

export default function useRenterOfferById(
  offerId: string,
  options?: Omit<
    UndefinedInitialDataOptions<OfferItemDto | undefined, Error, OfferItemDto, string[]>,
    'queryFn' | 'queryKey'
  >,
) {
  const { onError } = useQueryCustomErrorHandler();
  return useQuery({
    queryKey: [QueryKey.Offers, 'renter', offerId],
    queryFn: () => getRenterOfferById(offerId).catch(onError),
    ...options,
  });
}
