import { FC, Fragment, useMemo } from 'react';
import { Label } from 'legacy-components/ui/label/label';
import { UploadFiles } from 'legacy-components/fields/upload-files/upload-files';
import { ImageResponse, LocalImage } from 'common/mappers/property';
import { setMainPropertyImage } from 'services/api/property-api.service';
import { FormikErrors, FormikTouched } from 'formik';
import _ from 'lodash';
import { EstateUploadedImageList } from 'components/organisms/EstateUploadedImage/EstateUploadedImageList';
import { InfoToolTip } from 'components/atoms/InfoToolTip/InfoToolTip';
import { TOOLTIP_TEXT } from 'components/templates/EstateForm/config';
import useDeletePropertyImage from 'hooks/profile/use-delete-lease-document';
import { uploadFileConfig } from 'legacy-components/fields/upload-files/config';

type Props = {
  isEdit: boolean;
  images: (ImageResponse | LocalImage)[];
  setImages: (images: (ImageResponse | LocalImage)[]) => void;
  name?: string;
  touched?: FormikTouched<ImageResponse | LocalImage>[];
  errors?: string | string[] | FormikErrors<ImageResponse | LocalImage>[];
  estateId: string;
};

const PropertyPhotos: FC<Props> = ({ images, setImages, errors, touched, estateId, isEdit, name }) => {
  // to exсlude displayng errors like this ',,,error text
  const uniqueNonEmptyElements = useMemo(() => {
    if (Array.isArray(errors)) {
      const filteredArray = _.uniq(_.compact(errors as string[]));
      return filteredArray?.toString();
    }
    return errors?.toString();
  }, [errors]);

  const { mutateAsync: deletePropertyImage, isPending } = useDeletePropertyImage();

  const handleRemoveUploadedImage = async (index: number) => {
    const imageToRemove = images[index];
    if ('id' in imageToRemove) {
      await deletePropertyImage({ estateId, id: imageToRemove.id });
    }
    setImages(images.filter((_, i) => i !== index));
  };

  const handleClickMainImageBtn = async (index: number) => {
    const image = images[index];
    if ('id' in image) {
      await setMainPropertyImage(estateId, image.id, !image.isDefault);
    }
    setImages(
      images.map((img, idx) => {
        return {
          ...img,
          isDefault: idx === index,
        };
      }),
    );
  };

  const handleDropFiles = (newImages: File[]) => {
    setImages([
      ...images,
      ...newImages.map((el) => ({
        file: el,
        isDefault: false,
      })),
    ]);
  };

  const memoizedImages = useMemo(() => {
    return images.map((img) => {
      if ('file' in img) {
        return URL.createObjectURL(img.file);
      } else {
        return img.originalUrl;
      }
    });
  }, [images]);

  return (
    <div className='upload-photos'>
      <Label
        label={'Add photos of your property'}
        labelChildren={isEdit ? <InfoToolTip toolTipText={TOOLTIP_TEXT} /> : undefined}
      >
        <Fragment>
          <UploadFiles
            title={uploadFileConfig.images.title}
            accept={uploadFileConfig.images.accept}
            onDrop={handleDropFiles}
            invalid={Boolean(errors && touched)}
            name={name}
          />
          {Boolean(errors && touched) && (
            <div className='font-bold mb-2truncate ... text-error mt-2'>{uniqueNonEmptyElements}</div>
          )}
          {!!images.length && (
            <EstateUploadedImageList
              maxShowMoreImages={15}
              setImages={setImages}
              inputName={name}
              errors={errors}
              imagesProps={images}
              imgSize={{
                width: 128,
                height: 128,
              }}
              images={memoizedImages}
              onRemove={handleRemoveUploadedImage}
              onSetMainImg={handleClickMainImageBtn}
              isLoadingRemovingImg={isPending}
            />
          )}
        </Fragment>
      </Label>
    </div>
  );
};

export default PropertyPhotos;
