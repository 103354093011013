import { AuthRole } from 'common/enums/enums';
import { RolesGuard } from 'guard/role-guard/RolesGuard';
import { Fragment, lazy } from 'react';
import { Navigate } from 'react-router-dom';

const EstatesList = lazy(() => import('legacy-pages/estates/estates-list/estates-list'));
const EstatesMapView = lazy(() => import('legacy-pages/estates/estates-map-view'));
const EstatesDetails = lazy(() => import('legacy-pages/estates/estates-details/estates-details'));
const ContactUsPage = lazy(() => import('legacy-pages/ContactUs/ContactUs'));
const TermsOfServicePage = lazy(() => import('legacy-pages/Terms/TermsOfServicePage'));
const TermsPage = lazy(() => import('legacy-pages/Terms/Terms'));
const PrivacyPolicy = lazy(() => import('legacy-pages/PrivacyPolicy/PrivacyPolicy'));
const NonDiscriminationPolicy = lazy(() => import('legacy-pages/NonDiscriminationPolicy/NonDiscriminationPolicy'));

export const publicRoutes = [
  {
    element: (
      <Fragment>
        <Navigate to='/estates/grid' replace />
        <RolesGuard notAllowedRoles={[AuthRole.Landlord]}>
          <EstatesList />
        </RolesGuard>
      </Fragment>
    ),
    index: true,
  },
  { path: '', element: <Navigate to='/estates/grid' replace /> },
  { path: 'estates', element: <Navigate to='/estates/grid' replace /> },
  {
    path: 'estates/map',
    element: (
      <RolesGuard notAllowedRoles={[AuthRole.Landlord]}>
        <EstatesMapView />
      </RolesGuard>
    ),
  },
  {
    path: 'estates/grid',
    element: (
      <RolesGuard notAllowedRoles={[AuthRole.Landlord]}>
        <EstatesList />{' '}
      </RolesGuard>
    ),
  },
  {
    path: 'estates/:id',
    element: (
      <RolesGuard notAllowedRoles={[AuthRole.Landlord]}>
        <EstatesDetails />
      </RolesGuard>
    ),
  },
  // { path: 'contact-us', element: <ContactUsPage /> },
  { path: 'terms-of-service', element: <TermsOfServicePage /> },
  { path: 'payment-terms-of-service', element: <TermsPage /> },
  { path: 'privacy-policy', element: <PrivacyPolicy /> },
  { path: 'non-discrimination-policy', element: <NonDiscriminationPolicy /> },
];
