import { Input } from 'legacy-components/componets';
import { DeleteFilled } from '@ant-design/icons';
import { DOCUMENT_TITLE } from './VerifiedLeaseDocument.constants';
import { UploadedLeaseDocumentProps } from './VerifiedLeaseDocument.types';
import { ReactComponent as LeaseDocumentSvg } from 'assets/icons/lease-document.svg';
import React from 'react';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';

export const VerifiedLeaseDocument = ({ values, errors, touched, setFieldValue }: UploadedLeaseDocumentProps) => {
  const { file, title } = values;

  return (
    <div className={`flex flex-col gap-4 max-w-[500px]`}>
      <p className='font-semibold'>{DOCUMENT_TITLE}</p>
      <ErrorValidationMessage touched={touched?.title} message={errors?.title}>
        <Input
          name={file?.name || ''}
          value={title as string}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('title', e.target.value)}
        />
      </ErrorValidationMessage>
      <ErrorValidationMessage touched={touched?.file} message={errors?.file}>
        <div className='p-4 md:p-8 bg-sky-50 rounded-xl justify-between items-center flex gap-3'>
          <div className='flex items-center gap-4' style={{ overflow: 'hidden' }}>
            <div className='bg-primary p-2 rounded-full'>
              <LeaseDocumentSvg className={`text-white text-[24px]`} />
            </div>
            <div
              className='text-sky-600 text-base font-bold leading-normal grow'
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {file?.name}
            </div>
          </div>
          <button
            onClick={() => {
              setFieldValue('file', undefined);
              setFieldValue('title', '');
            }}
            type={'button'}
          >
            <DeleteFilled className={'text-primary text-[22px]'} />
          </button>
        </div>
      </ErrorValidationMessage>
    </div>
  );
};
