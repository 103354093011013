export const SUBMIT_BTN = 'Continue Browsing';
export const MODAL_TITLE = 'Self-tour details';
export const WARNING_MSG = 'This self-tour was canceled';
export const REASON = 'Reason:';

export const RENT = 'Rent';
export const PERMONTH = '/mo';

export const TOUR_DATE = 'Tour Date:';
export const ARRIVAL_TIME = 'Arrival time:';
export const TOUR_DURATION = 'Tour duration:';
export const TOUR_ENDS = 'Tour ends:';
export const TOUR_DURATION_MIN = '30 minutes';

export const TOUR_DETAILS = 'Tour Details';
export const STATUS_TEXT = 'Status:';
