import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { InputProps } from 'common/types/types';
import {
  Avatar,
  Button,
  CropImage,
  FormDescription,
  Input,
  Label,
  Loader,
  Modal,
  PhoneInput,
} from 'legacy-components/componets';
import { notification } from 'services/services';
import { pick } from 'lodash-es';
import { removeUserProfileImage, updateUserProfileImage } from 'services/api/auth-api.service';
import { useUserProfile } from 'hooks/query';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateCurrentUser, useUpdateCurrentUserPhone } from 'hooks/use-user-profile.hook';
import { Notification } from 'services/notification.service';
import { adminProfileValidation } from 'validations/admin/admin-profile.validation';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';
import { ConfirmModal } from 'components/atoms/ConfirmModal/ConfirmModal';
import useDeleteProfileImage from 'hooks/profile/use-delete-renter-image';
export type AdminProfileValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  image: File | null;
};

const AdminProfileInformationForm = () => {
  const [isCropImageOpen, setIsCropImageOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const { data: userProfile, isLoading: isUserProfileLoading } = useUserProfile();
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser({
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'One of these fields is invalid: First Name, Last Name, DoB.');
    },
  });
  const { mutateAsync: updateCurrentUserPhone } = useUpdateCurrentUserPhone({
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'User phone is not updated');
    },
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!userProfile) return;
    setValues({
      ...(pick(userProfile, ['firstName', 'lastName', 'email', 'phoneNumber', 'jobTitle']) as AdminProfileValues),
      image: null,
    });
    setImageUrl(userProfile.thumbnailUrl);
  }, [userProfile]);

  const { values, errors, touched, handleChange, setFieldValue, submitForm, setValues, isSubmitting } =
    useFormik<AdminProfileValues>({
      initialValues: {
        firstName: '',
        lastName: '',
        image: null,
        email: '',
        phoneNumber: '',
        jobTitle: '',
      },
      validationSchema: adminProfileValidation,
      onSubmit: async (value) => await handleFormSubmit(value),
    });

  const getCommonInputProps = (name: keyof Omit<AdminProfileValues, 'image'>, placeholder: string): InputProps => {
    return {
      name,
      placeholder,
      value: values[name],
      onChange: handleChange,
      invalid: Boolean(errors[name] && touched[name]),
    };
  };

  const handleFormSubmit = async (values: AdminProfileValues) => {
    await Promise.all([updateCurrentUser(values), updateCurrentUserPhone({ phoneNumber: values.phoneNumber })]);
    // if (values.image) {
    //   await updateUserProfileImage(values.image);
    // }
    // if (!values.image && !imageUrl) {
    //   await removeUserProfileImage();
    // }
    await queryClient.invalidateQueries({ queryKey: ['user'] });
    notification.success('Thank you for updating your profile. Your changes are now saved.');
  };

  const { mutateAsync: deleteProfileImage, isPending: isLoadingRemovingImg } = useDeleteProfileImage();
  const removeImage = async () => {
    await deleteProfileImage();
    setFieldValue('image', null);
    setImageUrl('');
  };

  const addImage = async (image: File) => {
    await updateUserProfileImage(image);
    setFieldValue('image', image);
    setImageUrl(URL.createObjectURL(image));
    await queryClient.invalidateQueries({ queryKey: ['user'] });
  };

  const handleCloseModal = () => {
    setIsCropImageOpen(false);
  };

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, [imageUrl]);

  return (
    <div className='flex flex-col flex-1 gap-0 md:gap-9 mb-5'>
      <Modal isOpen={isCropImageOpen} onClose={handleCloseModal} styles={{ content: { borderRadius: 15 } }}>
        <CropImage title={'Edit Photo'} onClose={handleCloseModal} onCrop={addImage} />
      </Modal>
      <ConfirmModal
        open={confirmModalOpen}
        onOk={async () => {
          await removeImage();
          setConfirmModalOpen(false);
        }}
        onCancel={() => setConfirmModalOpen(false)}
        title='Do you want to delete your profile photo ?'
        isLoadingSubmitBtn={isLoadingRemovingImg}
      />
      <div className='personal-details w-full flex flex-col gap-9 px-4 md:px-[34px] py-4 md:py-6 shadow-white-xl'>
        {isUserProfileLoading ? (
          <Loader
            style={{
              minHeight: 300,
            }}
          />
        ) : (
          <div className='personal-details-form'>
            <FormDescription
              title={'Personal Details'}
              subTitle={'Please start filling out your application by filling in your personal information below.'}
            />
            <div className='flex flex-col gap-[18px] pt-4'>
              <Label label={'Profile photo'}>
                <div className={'flex justify-start gap-5'}>
                  <Avatar src={imageUrl} />
                  {!imageUrl && <Button onClick={() => setIsCropImageOpen(true)} label={'Add photo'} />}
                  {imageUrl && (
                    <Button theme={'red'} onClick={() => setConfirmModalOpen(true)} label={'Delete photo'} />
                  )}
                </div>
              </Label>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div className='w-full'>
                  <Label label={'First Name'}>
                    <ErrorValidationMessage touched={touched.firstName} message={errors.firstName}>
                      <Input {...getCommonInputProps('firstName', 'First Name')} />
                    </ErrorValidationMessage>
                  </Label>
                </div>
                <div className='w-full'>
                  <Label label={'Last Name'}>
                    <ErrorValidationMessage touched={touched.lastName} message={errors.lastName}>
                      <Input {...getCommonInputProps('lastName', 'Last Name')} />
                    </ErrorValidationMessage>
                  </Label>
                </div>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div className='w-full'>
                  <Label label={'Email'}>
                    <Input {...getCommonInputProps('email', 'Email')} disabled />
                  </Label>
                </div>
                <div className='w-full'>
                  <Label label={'Phone Number'}>
                    <ErrorValidationMessage touched={touched.phoneNumber} message={errors.phoneNumber}>
                      <PhoneInput
                        isValid={!Boolean(errors.phoneNumber && touched.phoneNumber)}
                        value={values.phoneNumber}
                        onChange={(value) => {
                          setFieldValue('phoneNumber', value);
                        }}
                      />
                    </ErrorValidationMessage>
                  </Label>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='button-wrapper flex justify-end'>
          <Button
            label={'Save'}
            theme={'primary'}
            onClick={submitForm}
            isLoading={isSubmitting}
            disabled={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export { AdminProfileInformationForm };
