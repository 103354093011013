import { useState } from 'react';
import { PasswordValidation } from 'validations/password.validation';
import { twMerge } from 'tailwind-merge';
import { InputProps } from 'common/types/components/fields/input/input-props.type';
import Dropdown from 'rc-dropdown';
import { Input } from 'legacy-components/componets';

type Props = {
  errors?: string[] | string;
  showError: boolean;
  showHintPopup?: boolean;
} & InputProps;

const PasswordInput = ({ errors, showError = false, showHintPopup = false, ...inputProps }: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [visible, setVisible] = useState(false);

  const passwordContainErrors = [
    PasswordValidation.MinLength,
    PasswordValidation.LowerCase,
    PasswordValidation.UpperCase,
    PasswordValidation.Numbers,
    PasswordValidation.SpecialCharacters,
  ];

  const overlay = (
    <div className={'bg-white shadow rounded-lg p-4'} onClick={(e) => e.stopPropagation()}>
      <div className={'text-sm font-bold mb-2'}>Your password must contain:</div>
      <ul className={'text-green-500 ml-4'}>
        <li>✓ {PasswordValidation.MinLength}</li>
        <li>
          ✓ Must contain combination of:
          <ul className={'ml-8 list-disc'}>
            <li>{PasswordValidation.LowerCase}</li>
            <li>{PasswordValidation.UpperCase}</li>
            <li>{PasswordValidation.Numbers}</li>
            <li>{PasswordValidation.SpecialCharacters}</li>
          </ul>
        </li>
      </ul>
    </div>
  );

  return (
    <>
      <Dropdown overlay={overlay} placement={'top'} visible={showHintPopup && visible && !showError}>
        <Input
          {...inputProps}
          onFocus={() => setVisible(true)}
          onBlur={() => setVisible(false)}
          type={showPassword ? 'text' : 'password'}
          icon={{ name: showPassword ? 'hide' : 'show', color: '#66CCFF' }}
          onIconClick={() => setShowPassword((prevState) => !prevState)}
        />
      </Dropdown>
      {showError && (
        <>
          {errors?.includes(PasswordValidation.Required) ? (
            <p className='text-sm text-error py-1'>{PasswordValidation.Required}</p>
          ) : (
            <>
              {Array.isArray(errors) && errors.every((e) => passwordContainErrors.includes(e as PasswordValidation)) ? (
                <div className={'my-4'}>
                  <span className={'text-sm text-error py-1 font-bold'}>Your password must contain:</span>
                  <br />
                  <br />
                  <ul>
                    {passwordContainErrors.map((e) => {
                      const isApproved = !errors?.includes(e);
                      return (
                        <li key={e} className={twMerge('text-sm', isApproved ? 'text-green-500' : 'text-error')}>
                          {isApproved ? '✓' : '✘'} {e}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <p className='text-sm text-error py-1.5'>{errors}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PasswordInput;
