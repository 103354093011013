import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { updateToNewStatusEstate } from 'services/api/estates-api.service';
import { notification } from 'services/services';
import { QueryKey } from 'common/enums/query-key';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';

export default function useUpdateToNewStatusEstate(
  options?: Omit<UseMutationOptions<void, AxiosError<BackendError>, string, unknown>, 'mutationFn'>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateToNewStatusEstate,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Estates, 'admin'] });
    },
    onSuccess: () => {
      notification.success('You have successfully updated the estate');
    },
    onError: (e) => {
      notification.error(e?.response?.data?.detail || 'You could not update estate, try again');
    },
    ...options,
  });
}
