import { useState } from 'react';
import { Carousel, Modal } from 'legacy-components/componets';
import { ImageResponse, LocalImage } from 'common/mappers/property';
import { EstateUploadedImage } from '.';
import { EstateUploadedImageDeleteButton } from './EstateUploadedImageDeleteButton';
import { ReactSortable, ItemInterface } from 'react-sortablejs';
import { FormikErrors } from 'formik';
import { ConfirmModal } from 'components/atoms/ConfirmModal/ConfirmModal';

export type UploadedImagesIndexProps = {
  images?: string[];
  imagesProps?: (ImageResponse | LocalImage)[];
  errors?: string | string[] | FormikErrors<ImageResponse | LocalImage>[];
  inputName?: string;
  onRemove: (index: number) => void;
  onSetMainImg: (index: number) => void;
  setImages: (images: (ImageResponse | LocalImage)[]) => void;
  maxShowMoreImages?: number;
  imgSize?: {
    width: number | string;
    height: number | string;
  };
  isLoadingRemovingImg?: boolean;
};

const MAX_SHOW_MORE_COUNT = 4;

const EstateUploadedImageList = ({
  images,
  onRemove,
  onSetMainImg,
  setImages,
  maxShowMoreImages,
  imagesProps,
  imgSize,
  errors,
  inputName,
  isLoadingRemovingImg,
}: UploadedImagesIndexProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);
  const files = images || [];
  const maxShowMore = maxShowMoreImages ?? MAX_SHOW_MORE_COUNT;
  const isShowMore = files.length > maxShowMore;
  const numberOtherImages = files.length - maxShowMore;

  return (
    <>
      <ReactSortable
        list={imagesProps as ItemInterface[]}
        setList={(newState) => {
          setImages([...(newState as (ImageResponse | LocalImage)[])]);
        }}
        animation={200}
        easing='ease-out'
        className={`w-full rounded-lg bg-[#FAFAFA] mt-4 ${maxShowMoreImages ? 'flex gap-3 flex-wrap' : 'flex gap-2'}`}
      >
        {images?.slice(0, maxShowMore)?.map((img, index) => {
          const isLastIndex = index === maxShowMore - 1;
          return (
            <EstateUploadedImage
              //@ts-ignore
              key={imagesProps?.[index]?.id ?? `estate_uploaded_img_${index}`}
              src={img}
              isShowMore={isShowMore && isLastIndex}
              numberOtherImages={numberOtherImages}
              onDeleteBtnClick={() => {
                setSelectedItemIndex(index);
                setConfirmModalOpen(true);
              }}
              onImageClick={() => setIsOpen(true)}
              onShowMoreLayerClick={() => setIsOpen(true)}
              onMainBtnClick={() => onSetMainImg(index)}
              imgSize={imgSize}
              isDefault={imagesProps?.[index].isDefault}
              isError={Array.isArray(errors) && Boolean(errors?.[index])}
              dataErrorKey={`${inputName}[${index}]`}
            />
          );
        })}
      </ReactSortable>

      <ConfirmModal
        open={confirmModalOpen}
        onOk={async () => {
          await onRemove(selectedItemIndex);
          setConfirmModalOpen(false);
        }}
        onCancel={() => setConfirmModalOpen(false)}
        title='Do you want to delete image?'
        isLoadingSubmitBtn={isLoadingRemovingImg}
      />

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        styles={{
          content: {
            width: '90%',
            maxWidth: 700,
          },
        }}
      >
        <Carousel
          images={files}
          showThumbs
          height={300}
          renderThumbs={() =>
            files.map((file, i) => (
              <div className={'group relative h-full w-full'} key={`carousel_preview_image_${i}`}>
                <EstateUploadedImageDeleteButton
                  onClick={() => {
                    onRemove(i);
                  }}
                />
                <img src={file} style={{ height: 300 }} className={'object-cover w-full h-full'} alt='gallery' />
              </div>
            ))
          }
        />
      </Modal>
    </>
  );
};

export { EstateUploadedImageList };
