import { alphabetValidation } from 'validations/alphabet.validation';
import { emailValidation } from 'validations/email.validation';
import * as Yup from 'yup';

export const adminProfileValidation = Yup.object({
  firstName: alphabetValidation.required('First Name is required'),
  lastName: alphabetValidation.required('Last Name is required'),
  email: emailValidation,
  jobTitle: Yup.string(),
  phoneNumber: Yup.string().required('Phone number is required'),
});
