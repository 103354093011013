import { ProfileIcon } from 'legacy-components/componets';
import styles from './styles';
import BurgerMenu from 'legacy-components/burger-menu/burger-menu';

export interface ILoggedInInfoProps {
  name: string;
  thumbnailUrl: string;
}

const LoggedInfo = ({ name, thumbnailUrl }: ILoggedInInfoProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <ProfileIcon src={thumbnailUrl} />
        <h5 className={styles.name}>{name}</h5>
      </div>
      <BurgerMenu />
    </div>
  );
};

export { LoggedInfo };
