import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ENV } from 'common/enums/enums';
import { Button } from 'legacy-components/componets';
import { useMemo, useState } from 'react';
import { AddPaymentMethodModal } from './add-payment-method-modal';
import { useCreateNewPaymentMethod } from 'hooks/query';

const AddPaymentMethod = () => {
  const [isStripeLoading, setIsStripeLoading] = useState<boolean>(true);
  const stripePromise = loadStripe(ENV.REPLENISH_PUBLIC_KEY);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const { data, refetch: createNewPaymentMethod } = useCreateNewPaymentMethod({ enabled: false });
  const { secret = '' } = data || {};

  const isPaymentFormReady = useMemo(() => {
    return Boolean(secret || !isStripeLoading);
  }, [secret, isStripeLoading]);

  const options = useMemo(() => {
    return {
      clientSecret: secret,
    };
  }, [secret]);

  const handleAddPaymentMethod = async () => {
    setBtnLoading(true);
    await createNewPaymentMethod();
    setModalOpen(true);
  };

  return (
    <div className='mt-5 flex items-center justify-center'>
      <Button
        label={'Add new payment method'}
        theme={'primary'}
        onClick={handleAddPaymentMethod}
        isFullWidth
        isLoading={isBtnLoading}
      />
      {isPaymentFormReady ? (
        <>
          <Elements options={options} stripe={stripePromise}>
            <AddPaymentMethodModal
              secret={secret}
              onReady={() => {
                setIsStripeLoading(false);
                setBtnLoading(false);
              }}
              isOpen={isModalOpen}
              onClose={() => setModalOpen(false)}
              onOk={() => setModalOpen(false)}
            />
          </Elements>
        </>
      ) : null}
    </div>
  );
};

export { AddPaymentMethod };
