import { CSSProperties } from 'react';
import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal } from 'legacy-components/ui/modal/modal';
import { Button } from 'legacy-components/ui/button/button';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'hooks';

type SelfTourRescheduledModalProps = {
  onClose: () => void;
  open: boolean;
  onContinue: () => void;
  styles?: {
    overlay?: CSSProperties;
    content?: CSSProperties;
  };
};

export const SelfTourRescheduledModal = ({ open, onClose, onContinue, styles }: SelfTourRescheduledModalProps) => {
  const { isMobile } = useWindowSize();

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      styles={{
        ...styles,
        content: {
          ...styles?.content,
          maxHeight: '100%',
          ...(isMobile && { height: '100%' }),
        },
      }}
    >
      <div className='md:w-[544px] bg-white rounded-lg p-4 md:p-8 md:max-w-full w-screen md:h-auto relative flex flex-col gap-8'>
        <div className='flex justify-between items-center'>
          <div className='w-6 h-6' />
          <h3 className='text-center text-emerald-600 text-2xl font-medium leading-loose'>Self-Tour Rescheduled</h3>
          <button onClick={onClose}>
            <CloseOutlined className={'text-primary text-[24px]'} />
          </button>
        </div>
        <div className={'flex justify-center'}>
          <div className={'w-20 h-20 bg-emerald-50 rounded-full flex justify-center items-center'}>
            <CheckCircleOutlined className={'text-[48px] text-[#05A660]'} />
          </div>
        </div>
        <p className='text-center text-zinc-500 text-base font-medium leading-snug'>
          Your self-tour was successfully rescheduled and updated. If you have any further questions or concerns, please
          do not hesitate to{' '}
          <Link
            className='text-sky-300 text-base font-medium leading-snug'
            onClick={onClose}
            replace
            to={{
              search: 'contactUs=1',
            }}
          >
            contact us
          </Link>
          . Thank you again, and have a great day!
          <br />
          <br />
          You can review and edit the details on your upcoming tours tab within your profile.
        </p>
        <Button label={'Continue Browsing'} onClick={onContinue} className={'w-full'} />
      </div>
    </Modal>
  );
};
