import React, { FC, useMemo } from 'react';
import { usePropertyAmenities } from 'hooks/query';
import { useFormikContext } from 'formik';
import { PropertyValues } from '../landlord-properties';
import Amenities from './Amenities';
import { FormDescription } from 'legacy-components/form-description/form-description';

type Props = {
  isMultiUnit: boolean;
};

const GeneralPropertyAmenities: FC<Props> = ({ isMultiUnit }) => {
  const { data: amenitiesList } = usePropertyAmenities();
  const filteredAmenities = useMemo(
    () => (amenitiesList ? (isMultiUnit ? amenitiesList.filter((item) => item.type === 'Estate') : amenitiesList) : []),
    [isMultiUnit, amenitiesList],
  );
  const { values, setFieldValue } = useFormikContext<PropertyValues>();

  return (
    <>
      <FormDescription title={'General Amenities'} />
      <Amenities
        amenities={filteredAmenities}
        onChange={async (amenity) => {
          const fieldName = `details.amenities.${amenity.id}`;
          await setFieldValue(fieldName, !values.details.amenities[amenity.id], false);
        }}
        values={values.details.amenities}
      />
    </>
  );
};

export default GeneralPropertyAmenities;
