import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Button } from 'legacy-components/ui/button/button';
import { Badge, Popover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { pickBy } from 'lodash';
import { ButtonProps } from 'common/types/components/ui/button/button-props.type';
import { FiltersMenu } from './FiltersMenu';

// NOTE: WORKING WITH ONLY STRING AND []STRING QUERY VALUES
type StringDictionary = {
  [key: string]: any;
  //TODO: rework to [key: string]: string | string[];
  
};

export type FiltersMenuFormProps = {
  values: StringDictionary;
  setFieldValue: (field: string, value: any) => void;
  id: string;
};

type Props = {
  initialValues: StringDictionary;
  filters: StringDictionary;
  onSubmit: (values: StringDictionary) => void;
  onReset: () => void;
  placement?: TooltipPlacement | undefined;
  buttonProps?: ButtonProps;
  FiltersMenuForm: FC<FiltersMenuFormProps>;
};

const Filters: FC<Props> = ({ initialValues, filters, onReset, onSubmit, placement, buttonProps, FiltersMenuForm }) => {
  // TODO: REWORK TO REFS
  // The ID is required for the component close function to work correctly
  const id = useRef(Math.random().toString(16).slice(2));
  const buttonID = `${id.current}_filter-button`;
  const menuID = `${id.current}_filter-menu`;
  const [isOpen, setIsOpen] = useState(false);
  const { values, submitForm, resetForm, setFieldValue, setValues } = useFormik<StringDictionary>({
    initialValues,
    onSubmit,
    onReset,
  });

  const appliedFiltersCounter = useMemo(() => {
    return Object.keys(pickBy([...Object.values(filters)], (value: string) => value?.length > 0)).length;
  }, [filters]);

  const handleClosePopover = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const element = document.getElementById(menuID);
    const btnElement = document.getElementById(buttonID);

    const outsideClick =
      //@ts-ignore
      !element?.contains(event.target) && !btnElement?.contains(event.target);
    if (outsideClick) {
      handleClosePopover();
    }
  };

  useEffect(() => {
    // NOTE: Required to update form data according to filters
    setValues(filters);
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Popover
      open={isOpen}
      placement={placement}
      onOpenChange={(open: boolean) => {
        if (open) {
          // NOTE: Required to update form data according to filters
          setValues(filters);
        }
      }}
      content={
        <FiltersMenu id={menuID} onClose={handleClosePopover} onSubmit={submitForm} onReset={resetForm}>
          <FiltersMenuForm values={values} setFieldValue={setFieldValue} id={menuID} />
        </FiltersMenu>
      }
      trigger='click'
    >
      <Button
        className='min-w-[104px]'
        id={buttonID}
        label={'Filter'}
        theme='secondary'
        icon={'filters'}
        iconClassName='cursor-pointer'
        endIcon={appliedFiltersCounter ? <Badge color='#66CCFF' count={appliedFiltersCounter} /> : null}
        onClick={() => setIsOpen(true)}
        {...buttonProps}
      />
    </Popover>
  );
};

export { Filters };
