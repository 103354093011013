import { TablePaginationProps } from 'common/types/components/ui/table/table-pagination-props.type';
import { TableColumn } from 'common/types/components/ui/table/table-props.type';
import { SortOrder } from 'common/types/sort-order';
import { SetQuery } from 'use-query-params';
import { AdminEstateTableAccessorEnum, AdminEstateTableRow } from './AdminProfileListingTable.types';
import {
  ACTIONS_HEADER,
  CATEGORY_HEADER,
  DATE_HEADER,
  LANDLORD_HEADER,
  LOCATION_HEADER,
  TYPE_HEADER,
  STATUS_HEADER,
} from './AdminProfileListingTable.const';
import { Table } from 'legacy-components/ui/table/table';
import { AdminTextCell } from './AdminTableCells/AdminTextCell';
import { AdminEstateQueryParamConfig } from '../AdminProfileListing.types';
import { AdminDateCell } from './AdminTableCells/AdminDateCell';
import { AdminProfileListingTableActionsMenuCell } from './AdminTableCells/AdminActionsCell';
import { AdminTableEstateStatusCell } from './AdminTableCells/AdminTableEstateStatusCell';

export type AdminEstateTableProps = {
  data: AdminEstateTableRow[];
  isLoading: boolean;
  paginationProps: TablePaginationProps;
  order: SortOrder;
  setQuery: SetQuery<AdminEstateQueryParamConfig>;
};

const styleConfig = {
  header: {
    backgroundColor: '#F1FBFF',
    height: 54,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginBottom: 10,
  },
};

export const AdminEstateTable = ({ data, isLoading, paginationProps, order, setQuery }: AdminEstateTableProps) => {
  const columns: TableColumn<AdminEstateTableRow>[] = [
    {
      Header: LANDLORD_HEADER,
      accessor: AdminEstateTableAccessorEnum.Landlord,
      Cell: AdminTextCell,
      width: '20%',
      minWidth: 150,
    },
    {
      Header: LOCATION_HEADER,
      accessor: AdminEstateTableAccessorEnum.Location,
      Cell: AdminTextCell,
      width: '22%',
      minWidth: 250,
    },
    {
      Header: CATEGORY_HEADER,
      accessor: AdminEstateTableAccessorEnum.Category,
      Cell: AdminTextCell,
      width: '15%',
      minWidth: 100,
    },
    {
      Header: TYPE_HEADER,
      accessor: AdminEstateTableAccessorEnum.Type,
      Cell: AdminTextCell,
      width: '15%',
      minWidth: 100,
    },
    {
      Header: DATE_HEADER,
      accessor: AdminEstateTableAccessorEnum.Date,
      Cell: AdminDateCell,
      width: '15%',
      minWidth: 170,
      sorter: true,
      sortOrder: order,
      onSort: (sortOrder) => setQuery((prev) => ({ ...prev, page: 1, order: sortOrder })),
    },
    {
      Header: STATUS_HEADER,
      accessor: AdminEstateTableAccessorEnum.Status,
      Cell: AdminTableEstateStatusCell,
      width: '15%',
      minWidth: 150,
    },
    {
      Header: ACTIONS_HEADER,
      accessor: AdminEstateTableAccessorEnum.Actions,
      Cell: AdminProfileListingTableActionsMenuCell,
      width: '13%',
      minWidth: 100,
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      isLoading={isLoading}
      styleConfig={styleConfig}
      paginationProps={paginationProps}
      placeholder={{
        title: `Currently, you don't have any estates for approval.`,
        height: 400,
      }}
    />
  );
};
