import { ChangeEvent } from 'react';

export const stringAsFloatNumber = (
  e: ChangeEvent<HTMLInputElement>,
  fieldName: string,
  setFieldValue: (field: string, value: any) => void,
) => {
  const regex = /^-?[0-9]\d*\.?\d*$/;
  if (e.target.value === '' || regex.test(e.target.value)) {
    setFieldValue(fieldName, e.target.value);
  }
};

export const stringAsIntegerNumber = (
  e: ChangeEvent<HTMLInputElement>,
  fieldName: string,
  setFieldValue: (field: string, value: any) => void,
) => {
  const regex = /^\d+$/;
  if (e.target.value === '' || regex.test(e.target.value)) {
    setFieldValue(fieldName, e.target.value);
  }
};

export const stringAsIntegerNumberMaxValue = (
  e: ChangeEvent<HTMLInputElement>,
  max: number,
  fieldName: string,
  setFieldValue: (field: string, value: any) => void,
) => {
  const regex = /^\d+$/;
  if (e.target.value === '' || regex.test(e.target.value)) {
    if (e.target.value.toString().length < max + 1) setFieldValue(fieldName, e.target.value);
    else e.target.value = e.target.value.toString().slice(0, 2);
  }
};
