import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { defaultFilters, maxItemPerPage } from './config';
import { EstateStatusDto } from 'common/enums/enums';
import { TablePagination } from 'legacy-components/ui/table/table-pagination';
import { NumberParam, QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { EstateCard } from 'components/organisms/EstateCard';
import { useWindowSize } from 'hooks';
import { useMyEstates } from 'hooks/query';
import { LandlordListingsFilters } from 'components/organisms/Filters/LandlordListingsFilters';
import { Loader } from 'legacy-components/componets';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';

export type ListingSelectorFilters = {
  status: string;
  category: string;
  type: string;
};

export type ListingFilters = ListingSelectorFilters & {
  published: string;
  page: number;
};

export type EstatesListQueryParamConfig = {
  status: QueryParamConfig<string>;
  category: QueryParamConfig<string>;
  type: QueryParamConfig<string>;
  published: QueryParamConfig<string>;
  page: QueryParamConfig<number>;
};

export const LandlordListing = () => {
  const { width } = useWindowSize();
  const queryParamConfig = useMemo<EstatesListQueryParamConfig>(
    () => ({
      status: withDefault(StringParam, defaultFilters.status),
      category: withDefault(StringParam, defaultFilters.category),
      type: withDefault(StringParam, defaultFilters.type),
      published: withDefault(StringParam, defaultFilters.published),
      page: withDefault(NumberParam, defaultFilters.page),
    }),
    [],
  );
  const [query, setQuery] = useQueryParams(queryParamConfig);
  const { data, isLoading: isListLoading } = useMyEstates({
    ...query,
    perPage: maxItemPerPage,
    sorters: { createdAt: 'desc' },
  });
  const { totalCount: total = 0, items: list = [] } = data || {};

  const mapPrice = (minPrice: number, maxPrice: number) => {
    return minPrice === maxPrice ? minPrice : `${minPrice}-${maxPrice}`;
  };

  const propertyListingRoute = '/i-landlord/properties';

  const getCardLink = (id: string, status: string) => {
    if (status === EstateStatusDto.Incomplete) {
      return propertyListingRoute + `/${id}`;
    } else {
      return propertyListingRoute + `/details/${id}`;
    }
  };

  return (
    <div className='content grow px-2 md:px-0 md:pr-11'>
      <div className='profile-info-header justify-between flex items-center mb-2 md:mb-4 px-4 md:px-[34px]'>
        <h4 className='title text-xl font-bold'>Property Listings</h4>
        <LandlordListingsFilters />    
      </div>
      <div className={`flex flex-col gap-9 px-4 md:px-[34px] py-4 md:py-6 
           ${list != null && list.length > 0 ? 'shadow-white-xl': ''}`}>
        <div className='flex flex-col items-center gap-6 w-full'>
          {isListLoading ? (
            <Loader />
          ) : list == null || list.length === 0 ? (
            <div className="p-8">
              <p className="text-sm text-trueGray">There is no data to show</p>
            </div>
          ) : (
            list.map((item) => {
              return (
                <Link
                  className='w-full flex items-center justify-center'
                  to={getCardLink(item.id, item?.status ?? '')}
                  key={item.id}
                >
                  <EstateCard
                    style={{ width: '100%' }}
                    key={item.id}
                    isHorizontal={!width || width > 550}
                    {...item}
                    price={mapPrice(item?.priceMin, item?.priceMax)}
                    gotoEditMode={() => {
                      if (item?.status === EstateStatusDto.Available || item?.status === EstateStatusDto.Rejected) {
                        return propertyListingRoute + `/${item?.id}`;
                      }
                      return '';
                    }}
                  />
                </Link>
              );
            })
          )}
        </div>
        { (list != null && list.length > 0) && (<TablePagination
          currentPage={query.page}
          totalCount={total}
          pageSize={maxItemPerPage}
          onPageChange={(newPage) => {
            setQuery((prev) => ({ ...prev, page: newPage }));
          }}
        /> )}
      </div>
      <Fab color='primary' size='small' aria-label="list-new-property" href={propertyListingRoute}
        title='Create new listing'
        sx={{position: 'fixed', bottom: '95px', right: '18px;'}}>
         <AddIcon />
      </Fab> 
    </div>
  );
};
