import { Table } from 'legacy-components/componets';
import { ToursTableRow } from './common/types/tours-table-row';
import { TablePaginationProps } from 'legacy-components/ui/table/table-pagination';
import { styleConfig } from './config';
import { ToursTableAccessor } from './common/enum/tour-table-accessor';
import { TourDateCell } from './components/TourDateCell';
import { TourPropertyCell } from './components/TourPropertyCell';
import { TourActionsMenuCell } from './components/TourActionsMenuCell';
import { TableColumn } from 'common/types/components/ui/table/table-props.type';
import { SetQuery } from 'use-query-params';
import { TourStatusCell } from './components/TourStatusCell';
import { TourStatusDto } from 'common/enums/services/api/tours/tour-status-dto.enum';
import { SortOrder } from 'common/types/sort-order';
import { ToursHistoryQueryParamConfig } from '../history-tours';

type ToursHistoryTableProps = {
  data: ToursTableRow[];
  isLoading: boolean;
  paginationProps: TablePaginationProps;
  order: SortOrder;
  tourStatuses: `${TourStatusDto}`[];
  setQuery: SetQuery<ToursHistoryQueryParamConfig>;
};

const TourHistoryTable = ({
  data,
  isLoading,
  paginationProps,
  order,
  setQuery,
  tourStatuses,
}: ToursHistoryTableProps) => {
  const columns: TableColumn<ToursTableRow>[] = [
    {
      Header: 'Date',
      accessor: ToursTableAccessor.Date,
      Cell: TourDateCell,
      sorter: true,
      sortOrder: order,
      onSort: (sortOrder) => setQuery((prev) => ({ ...prev, page: 1, order: sortOrder })),
      width: '20%',
      minWidth: 150,
    },
    {
      Header: 'Property',
      accessor: ToursTableAccessor.Property,
      Cell: TourPropertyCell,
      width: '60%',
      minWidth: 300,
    },
    {
      Header: 'Status',
      accessor: ToursTableAccessor.Status,
      Cell: TourStatusCell,
      width: '10%',
      minWidth: 120,
    },
    {
      Header: 'Actions',
      accessor: ToursTableAccessor.Actions,
      Cell: TourActionsMenuCell,
      width: '10%',
      minWidth: 100,
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      isLoading={isLoading}
      styleConfig={styleConfig}
      paginationProps={paginationProps}
      placeholder={{
        title: `Currently, you don't have any tour history. Don't worry, you can still explore our listings and schedule a self tour to find your perfect rental!`,
        height: 400,
      }}
    />
  );
};

export { TourHistoryTable };
