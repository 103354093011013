import React from 'react';
import { Outlet } from 'react-router-dom';

const PublicLayout = () => {
  return (
    <div className='public-container grow'>
      <Outlet />
    </div>
  );
};

export { PublicLayout };
