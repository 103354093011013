import React from 'react';
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from 'legacy-components/componets';
import { emailValidation } from 'validations';
import { Icon } from 'legacy-components/ui/icon/icon';

export interface IEmailValues {
  email: string;
}

export interface IEmailFormProps {
  errorMessage: string;
  setErrorMessage: (error: string) => void;
  onSubmit: (values: IEmailValues) => Promise<void>;
}

const EmailForm = ({ errorMessage, setErrorMessage, onSubmit }: IEmailFormProps) => {
  const { values, errors, touched, handleChange, submitForm, isSubmitting } = useFormik<IEmailValues>({
    initialValues: { email: '' },
    validationSchema: Yup.object({ email: emailValidation }),
    onSubmit,
  });

  const showError = (fieldName: keyof IEmailValues): boolean => {
    return Boolean(errors[fieldName] && touched[fieldName]);
  };

  return (
    <div className='flex justify-center items-center pt-[calc(100vh/2_-_95px_-_260px)]'>
      <div className='flex-col md:w-[350px] w-[327px]'>
        <div className={`title text-[24px] font-bold text-primary md:text-center text-left w-full mb-9`}>
          Forgot password
        </div>
        {errorMessage && (
          <div className={`text-sm text-error p-2 bg-red-100 rounded-lg flex justify-between mb-9`}>
            <p>{errorMessage}</p>
            <div className='cursor-pointer' onClick={() => setErrorMessage('')}>
              <Icon name={'times-circle'} />
            </div>
          </div>
        )}
        <div className={showError('email') ? 'mb-2' : 'mb-9'}>
          <Input
            name={'email'}
            placeholder={'Email'}
            value={values.email}
            onChange={handleChange}
            invalid={Boolean(errors.email && touched.email)}
          />
          {showError('email') && <p className='text-xs text-error py-1.5'>{errors.email}</p>}
        </div>
        <div className='mb-6'>
          <Button
            label={'Submit'}
            type='submit'
            theme='primary'
            onClick={submitForm}
            isFullWidth
            isLoading={isSubmitting}
            disabled={isSubmitting}
          />
        </div>
        <div className='w-full text-sm text-warmGray text-left'>
          <NavLink to={'/auth/sign-in'} className='text-primary'>
            Go back to Login
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export { EmailForm };
