import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { deleteSavedSearchResult } from 'services/api/saved-search-api.service';
import {
  GetSavedSearchResultRequestDto,
  SavedSearchEstateDto,
} from 'common/types/services/api/user/user-saved-search.type';
import { notification } from 'services/services';
import { QueryKey } from 'common/enums/query-key';

export default function useDeleteSavedSearchResult(
  params?: GetSavedSearchResultRequestDto,
  options?: Omit<
    UseMutationOptions<boolean, Error, string, { previousSavedSearchResult: SavedSearchEstateDto | undefined }>,
    'mutationFn'
  >,
) {
  const queryClient = useQueryClient();

  return useMutation<boolean, Error, string, { previousSavedSearchResult: SavedSearchEstateDto | undefined }>({
    mutationFn: deleteSavedSearchResult,
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: [QueryKey.SavedSearch, 'result'] });
      const previousSavedSearchResult = queryClient.getQueryData<SavedSearchEstateDto>([
        QueryKey.SavedSearch,
        'result',
        params,
      ]);
      queryClient.setQueryData<SavedSearchEstateDto>([QueryKey.SavedSearch, 'result', params], (old) => {
        if (!old) return undefined;
        return {
          ...old,
          totalCount: old.totalCount - 1,
          items: old.items === null ? null : old.items.filter((el) => el.estateId !== id),
        };
      });
      return { previousSavedSearchResult };
    },
    onError: (err, id, context) => {
      queryClient.setQueryData([QueryKey.SavedSearch, 'result', params], context?.previousSavedSearchResult);
      notification.error('Saved Search estate was not deleted');
    },
    onSuccess: () => {
      notification.success('Saved Search estate was deleted');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.SavedSearch, 'result'] });
    },
    ...options,
  });
}
