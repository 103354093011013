import React from 'react';
import { Modal } from 'legacy-components/ui/modal/modal';
import { Button } from 'legacy-components/componets';

type CancelTourModalProps = {
  open: boolean;
  onClose: () => void;
  onOk: () => void | Promise<void>;
};

export const CancelTourModal: React.FC<CancelTourModalProps> = ({ open, onClose, onOk }) => {
  return (
    <>
      <Modal isOpen={open} onClose={onClose}>
        <div className='p-4 bg-white rounded-lg shadow'>
          <h3 className='mb-4 text-black font-semibold text-xl whitespace-nowrap'>
            Are you sure you want to cancel a tour?
          </h3>
          <div className='flex justify-center items-center space-x-4'>
            <Button label={'No'} theme={'black-outline'} onClick={onClose} />
            <Button label={'Yes'} onClick={onOk} />
          </div>
        </div>
      </Modal>
    </>
  );
};
