import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import { Button, Icon, Input, PhoneInput } from 'legacy-components/componets';
import { AgreeAndContinue } from 'legacy-pages/pages';
import { InputProps } from 'common/types/types';
import { passwordValidation } from 'validations';
import { emailRegularExpression } from 'common/constants/formValidation';
import { groupBy, mapValues } from 'lodash-es';
import PasswordInput from 'legacy-components/ui/password-input/password-input';
import { ENV } from 'common/enums/enums';
import ReCAPTCHA from 'react-google-recaptcha';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import { signUpLandlordManagerFormToDtoSignUpRequest } from 'common/mappers/auth';
import { alphabetValidationForSignUp } from 'validations/sign-up.validation';
import { useSignUp } from 'hooks/query';
import { isAxiosError } from 'axios';

export interface ISignUpPropertyManagerValues {
  unitsCount: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  companyName: string;
  companyWebsite: string;
  businessEmail: string;
  businessPhone: string;
  captchaToken: string;
}

const signUpPropertyManagerValidationSchema = Yup.object({
  firstName: alphabetValidationForSignUp('First Name'),
  lastName: alphabetValidationForSignUp('Last Name'),
  phone: Yup.string().required('Phone is required'),
  email: Yup.string().matches(emailRegularExpression, 'Email address is invalid').required('Email is required'),
  password: passwordValidation,
  companyName: Yup.string().required('Company name is required'),
  companyWebsite: Yup.string().required('Website is required').url('Website is invalid'),
  businessEmail: Yup.string().email('Business email is invalid').required('Business email is required'),
  businessPhone: Yup.string().required('Business phone is required'),
  captchaToken: isNotEmptyString(ENV.REACT_APP_RECAPTCHA)
    ? Yup.string().required('Captcha is required')
    : Yup.string().notRequired(),
});

const SignUpPropertyManagerForm = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const reCaptchaRef = useRef<ReCAPTCHA | null>(null);
  const { values, errors, touched, setFieldValue, submitForm } = useFormik<ISignUpPropertyManagerValues>({
    initialValues: {
      unitsCount: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      companyName: '',
      companyWebsite: '',
      businessEmail: '',
      businessPhone: '',
      captchaToken: '',
    },
    validate: async (values) => {
      try {
        await signUpPropertyManagerValidationSchema.validate(values, { abortEarly: false });
        return {};
      } catch (err) {
        if (!ValidationError.isError(err)) return {};
        return mapValues(groupBy(err.inner, 'path'), (e) => e.flatMap((e) => e.errors));
      }
    },
    onSubmit: (values: ISignUpPropertyManagerValues) => handleManagerSignUp(values),
  });
  const { mutateAsync: signUp, isPending } = useSignUp();

  const getCommonInputProps = (
    name: keyof ISignUpPropertyManagerValues,
    label: string,
    placeholder: string,
  ): InputProps => {
    return {
      name,
      label,
      placeholder,
      theme: 'default',
      value: values[name],
      onChange: ({ target: { value } }) => setFieldValue(name, value),
      invalid: Boolean(errors[name] && touched[name]),
    };
  };

  const showError = (fieldName: keyof ISignUpPropertyManagerValues): boolean => {
    return Boolean(errors[fieldName] && touched[fieldName]);
  };

  const handleRecaptcha = (value: any) => {
    setFieldValue('captchaToken', value);
  };

  const handleManagerSignUp = async (values: ISignUpPropertyManagerValues) => {
    try {
      await signUp(signUpLandlordManagerFormToDtoSignUpRequest(values));
    } catch (e) {
      if (isAxiosError(e)) {
        setErrorMessage(e.response?.data.detail || e.message);
      }
      // if we receive error we need to reset captcha
      setFieldValue('captchaToken', '');
      reCaptchaRef?.current?.reset();
    }
  };

  return (
    <>
      {errorMessage && (
        <div
          className={`text-sm text-error p-2 bg-red-100 rounded-lg flex justify-between ${
            errorMessage ? 'mt-4 mb-4' : ''
          }`}
        >
          <p>{errorMessage}</p>
          <div className='cursor-pointer' onClick={() => setErrorMessage('')}>
            <Icon name={'times-circle'} />
          </div>
        </div>
      )}
      {showError('captchaToken') && (
        <div
          className={`text-sm text-error p-2 bg-red-100 rounded-lg flex justify-between ${
            errorMessage ? 'mt-4 mb-4' : ''
          }`}
        >
          <p>{errors.captchaToken}</p>
        </div>
      )}
      <div className='flex flex-col gap-1'>
        <div
          className={`flex md:flex-row flex-col gap-4 
                ${showError('email') || showError('password') ? '' : 'mb-6'}`}
        >
          <div className='md:w-1/2 w-full'>
            <Input {...getCommonInputProps('email', 'Email', 'hi@example.com')} />
            {showError('email') && <p className='text-sm text-error py-1'>{errors.email}</p>}
          </div>
          <div className='md:w-1/2 w-full'>
            <PasswordInput
              {...getCommonInputProps('password', 'Password', '')}
              showHintPopup
              showError={showError('password')}
              errors={errors.password}
              autoCompleteType={'one-time-code'}
            />
          </div>
        </div>
        <div
          className={`flex md:flex-row flex-col gap-4 
                ${showError('firstName') || showError('lastName') ? '' : 'mb-6'}`}
        >
          <div className='md:w-1/2 w-full'>
            <Input {...getCommonInputProps('firstName', 'First Name', 'First Name')} />
            {showError('firstName') && <p className='text-sm text-error py-1'>{errors.firstName}</p>}
          </div>
          <div className='md:w-1/2 w-full'>
            <Input {...getCommonInputProps('lastName', 'Last Name', 'Last Name')} />
            {showError('lastName') && <p className='text-sm text-error py-1'>{errors.lastName}</p>}
          </div>
        </div>

        <div className={`w-full ${showError('phone') ? '' : 'mb-6'}`}>
          <PhoneInput
            isValid={!showError('phone')}
            value={values.phone}
            onChange={(value, data, event, formattedValue) => {
              setFieldValue('phone', value);
            }}
          />
          {showError('phone') && <p className='text-sm text-error py-1'>{errors.phone}</p>}
        </div>
        <div
          className={`flex md:flex-row flex-col gap-4
        ${showError('companyName') || showError('companyWebsite') ? '' : 'mb-6'}`}
        >
          <div className='md:w-1/2 w-full'>
            <Input {...getCommonInputProps('companyName', 'Company name', 'Company name')} />
            {showError('companyName') && <p className='text-sm text-error py-1'>{errors.companyName}</p>}
          </div>
          <div className='md:w-1/2 w-full'>
            <Input {...getCommonInputProps('companyWebsite', 'Company website', 'Company website')} />
            {showError('companyWebsite') && <p className='text-sm text-error py-1'>{errors.companyWebsite}</p>}
          </div>
        </div>
        <div
          className={`flex md:flex-row flex-col gap-4
        ${showError('businessEmail') || showError('businessPhone') ? '' : 'mb-6'}`}
        >
          <div className='md:w-1/2 w-full'>
            <Input {...getCommonInputProps('businessEmail', 'Business email', 'Business email')} />
            {showError('businessEmail') && <p className='text-sm text-error py-1'>{errors.businessEmail}</p>}
          </div>
          <div className='md:w-1/2 w-full'>
            <label className='block mb-1 text-sm font-medium text-black'>Business phone</label>
            <PhoneInput
              value={values.businessPhone}
              isValid={!showError('businessPhone')}
              onChange={(value, data, event, formattedValue) => {
                setFieldValue('businessPhone', value);
              }}
            />
            {showError('businessPhone') && <p className='text-sm text-error py-1'>{errors.businessPhone}</p>}
          </div>
        </div>
        <div className='mb-2'>
          <AgreeAndContinue />
        </div>
        <div className='w-full flex flex-wrap flex-col sm:flex-row gap-4 justify-between'>
          {isNotEmptyString(ENV.REACT_APP_RECAPTCHA) && (
            <div className='flex justify-start'>
              <ReCAPTCHA ref={reCaptchaRef} sitekey={ENV.REACT_APP_RECAPTCHA} onChange={handleRecaptcha} hl='en' />
            </div>
          )}
          <div className='flex justify-end items-end flex-1'>
            <Button
              label={'Agree and continue'}
              type={'submit'}
              theme={'primary'}
              onClick={submitForm}
              isLoading={isPending}
              className='mb-[2px]'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { SignUpPropertyManagerForm };
