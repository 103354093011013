import React from 'react';
import avatar from 'assets/icons/avatar-default.svg';

export interface IProfileIconProps {
  src: string;
  width?: number;
  height?: number;
}

const ProfileIcon = ({ src, width, height }: IProfileIconProps) => {
  return <img className='w-5 h-5 rounded-full' width={width} height={height} src={src || avatar} alt='Avatar' />;
};

export { ProfileIcon };
