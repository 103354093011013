import React from 'react';
import { Icon } from 'legacy-components/ui/icon/icon';
import { IconSVGName } from 'common/types/types';
import cn from 'clsx';

export type EstatesCategoryIconProps = {
  name: string;
  icon: IconSVGName;
  onClick?: () => void;
  isActive?: boolean;
};

const EstatesCategoryIcon = ({ name, icon, onClick, isActive }: EstatesCategoryIconProps) => {
  const containerClassNane = cn(
    'sm:w-[90px] sm:h-[98px] min-w-[60px] h-[68px] flex items-center flex-col text-sm font-medium cursor-pointer',
    `${isActive ? 'text-primary' : 'text-black'}`,
  );

  return (
    <div className={containerClassNane} onClick={onClick}>
      <Icon name={icon} height={40} onClick={() => {}} />
      <div className={`flex-center text-center grow`}>{name}</div>
    </div>
  );
};

export { EstatesCategoryIcon };
