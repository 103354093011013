import { FC } from 'react';
import { FormDescription, FormDescriptionProps } from 'legacy-components/form-description/form-description';
import { Label } from 'legacy-components/ui/label/label';
import { RichTextEditor } from 'legacy-components/fields/rich-text-editor/rich-text-editor';
import { InfoToolTip } from 'components/atoms/InfoToolTip/InfoToolTip';
import { TOOLTIP_TEXT } from 'components/templates/EstateForm/config';

type Props = {
  isMultiUnit: boolean;
  isEdit: boolean;
  generalDescription: string;
  setGeneralDescription: (generalDescription: string) => void;
};

const GeneralPropertyInfo: FC<Props> = ({ isMultiUnit, generalDescription, setGeneralDescription, isEdit }) => {
  const formDescription: FormDescriptionProps = {
    title: isMultiUnit ? 'General Building information' : 'General property information',
    subTitle: isMultiUnit ? 'This information will appear on the property details page.' : '',
  };

  return (
    <div className='flex flex-col gap-9'>
      <FormDescription {...formDescription} />
      <Label
        label={'Property description'}
        labelChildren={isEdit ? <InfoToolTip toolTipText={TOOLTIP_TEXT} /> : undefined}
      >
        <RichTextEditor
          value={generalDescription}
          placeholder={'Property description'}
          onChange={async (e) => {
            setGeneralDescription(e);
          }}
        />
      </Label>
    </div>
  );
};

export default GeneralPropertyInfo;
