import { CSSProperties, useState } from 'react';
import { ArrowLeftOutlined, ClockCircleFilled, CloseOutlined } from '@ant-design/icons';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { Modal } from 'legacy-components/ui/modal/modal';
import { Button } from 'legacy-components/componets';
import dayjs from 'dayjs';
import { TourDto } from 'common/types/types';
import { LocationPin } from 'legacy-components/componets';

type SelfTourScheduleModalProps = {
  onClose: () => void;
  open: boolean;
  onGoBack: () => void;
  onConfirm: () => Promise<void>;
  styles?: {
    overlay?: CSSProperties;
    content?: CSSProperties;
  };
  previousDate: Date;
  nextDate: Date;
  tour: TourDto;
};

export const SelfTourScheduleModal = ({
  open,
  onClose,
  onGoBack,
  onConfirm,
  styles,
  previousDate,
  nextDate,
  tour,
}: SelfTourScheduleModalProps) => {
  const { isMobile } = useWindowSize();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      styles={{
        ...styles,
        content: {
          ...styles?.content,
          maxHeight: '100%',
          ...(isMobile && { height: '100%' }),
        },
      }}
    >
      <div className='md:w-[751px] bg-white rounded-lg p-4 md:p-8 md:max-w-full w-screen md:h-auto relative flex flex-col gap-9'>
        <div className='flex justify-between items-center'>
          <button onClick={onGoBack}>
            <ArrowLeftOutlined className={'text-primary text-[24px]'} />
          </button>
          <h3 className='text-center text-neutral-800 text-[40px] font-semibold leading-[48px]'>Self-tour schedule</h3>
          <button onClick={onClose}>
            <CloseOutlined className={'text-primary text-[24px]'} />
          </button>
        </div>
        <div className={'p-6 bg-sky-50 rounded-2xl flex-col justify-start items-start gap-4 flex'}>
          <div className='flex-col justify-start items-start gap-2 flex'>
            <div className='justify-start items-center gap-2 flex'>
              <LocationPin width={20} height={20} />
              <div className='text-neutral-500 text-base font-normal'>{tour.location}</div>
            </div>
          </div>
          <div className='px-8 py-6 bg-white rounded-lg border border-rose-500 flex-col justify-start items-start gap-2 flex w-full'>
            <div className='text-rose-500 text-sm font-bold uppercase leading-[16.80px]'>Previous date and time</div>
            <div className='justify-start items-start flex w-full'>
              <div className='text-neutral-500 text-base font-bold leading-relaxed w-1/3'>
                Date:
                <br />
                Time:
              </div>
              <div className='text-neutral-500 text-base font-normal leading-relaxed w-2/3'>
                {dayjs(previousDate).utc().format('MMM D, YYYY')}
                <br />
                {dayjs(previousDate).utc().format('hh:mm')} -{' '}
                {dayjs(previousDate).add(30, 'minutes').utc().format('hh:mm')} {dayjs(previousDate).utc().format('A')}
              </div>
            </div>
            <div className='flex-col justify-start items-start gap-2.5 flex'>
              <div className='flex-col justify-start items-start gap-2 flex'>
                <div className='justify-start items-center gap-2 inline-flex'>
                  <ClockCircleFilled className={'text-[20px]'} />
                  <div className='text-neutral-800 text-base font-semibold leading-tight'>30 min</div>
                </div>
              </div>
            </div>
          </div>
          <div className='px-8 py-6 bg-white rounded-lg border border-sky-300 flex-col justify-start items-start gap-2 flex w-full'>
            <div className='text-sky-600 text-sm font-bold uppercase leading-[16.80px]'>new date and time</div>
            <div className='justify-start items-start flex w-full'>
              <div className='text-neutral-500 text-base font-bold leading-relaxed w-1/3'>
                Date:
                <br />
                Time:
              </div>
              <div className='text-neutral-500 text-base font-normal leading-relaxed w-2/3'>
                {dayjs(nextDate).format('MMM D, YYYY')}
                <br />
                {dayjs(nextDate).format('hh:mm')} - {dayjs(nextDate).add(30, 'minutes').format('hh:mm')}{' '}
                {dayjs(nextDate).format('A')}
              </div>
            </div>
            <div className='flex-col justify-start items-start gap-2.5 flex'>
              <div className='flex-col justify-start items-start gap-2 flex'>
                <div className='justify-start items-center gap-2 inline-flex'>
                  <ClockCircleFilled className={'text-[20px]'} />
                  <div className='text-neutral-800 text-base font-semibold leading-tight'>30 min</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          label={'Confirm and Reschedule'}
          isLoading={isSubmitting}
          onClick={async () => {
            setIsSubmitting(true);
            await onConfirm();
            setIsSubmitting(false);
          }}
          className={'w-full'}
        />
      </div>
    </Modal>
  );
};
