import React, { useCallback, useMemo } from 'react';
import { AccessAccountsStatusDto, PropertyAccessKey, PropertyUnitType } from 'common/enums/enums';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import {
  Button,
  Icon,
  Label,
  Loader,
  RadioButton,
  Select,
  Textarea,
  UploadedImagesIndex,
  UploadFiles,
} from 'legacy-components/componets';
import { PropertyValues } from 'legacy-pages/landlord/properties/landlord-properties';
import { propertyKeyAccessTypeList } from './config';
import { SelectOption } from 'common/types/components/common/select-option.type';
import { joinValues } from 'helpers/helpers';
import { ImageResponse } from 'common/mappers/property';
import { PropertyUnitValues } from '../property-detail/property-detail';
import clsx from 'clsx';
import { EstateDetailsShortInfo } from 'legacy-components/estate/estate-details-header/EstateDetailsShortInfo';
import { useAccessAccountLocks, useConnectedAccessAccounts } from 'hooks/query';
import { deletePropertyAccessFile, deletePropertyUnitAccessFile } from 'services/api/property-api.service';
import { QueryKey } from 'common/enums/query-key';
import { useIsMutating } from '@tanstack/react-query';
import { uploadFileConfig } from 'legacy-components/fields/upload-files/config';

export type PropertyBuildingAccessValues = {
  forAllUnits: boolean;
  keyAccess: PropertyAccessKey;
  accessInstructions: string;
  accountId: string;
  deviceId: string;
  files: File[] | ImageResponse[];
};

export type PropertyBuildingAccessProps = Pick<
  FormikProps<PropertyValues>,
  'values' | 'setFieldValue' | 'errors' | 'touched' | 'submitForm' | 'resetForm'
> & {
  stepTitle?: string;
  propertyId: string;
};

export type GenericAccessTypeFormProps = Pick<FormikProps<PropertyValues>, 'setFieldValue' | 'resetForm' | 'values'> & {
  unitId?: string;
  estateId: string;
  nameOfValue: string;
  itemAccessValue: PropertyBuildingAccessValues;
  errorAccessType: FormikErrors<PropertyBuildingAccessValues> | undefined;
  touchedAccessType: FormikTouched<PropertyBuildingAccessValues> | undefined;
};

export type SeparateAccessTypeComponentProps = Pick<
  FormikProps<PropertyValues>,
  'values' | 'setFieldValue' | 'errors' | 'touched' | 'resetForm'
> & {
  estateId: string;
  index: number;
  useExpandableList: {
    expandedIds: number[];
    handleExpand: (id: number) => void;
  };
};

const GenericAccessTypeForm = ({
  setFieldValue,
  values,
  // resetForm,
  nameOfValue,
  itemAccessValue,
  errorAccessType,
  touchedAccessType,
  estateId,
  unitId,
}: GenericAccessTypeFormProps) => {
  const handleSelectRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    // resetForm();
    setFieldValue(`${nameOfValue}.keyAccess`, e.target.value);
  };

  const isSelectedElectronicLock = useMemo(
    () => itemAccessValue.keyAccess === PropertyAccessKey.ElectronicLock,
    [itemAccessValue.keyAccess],
  );

  const { data, isLoading } = useConnectedAccessAccounts({}, { enabled: isSelectedElectronicLock });

  const accountOptions = React.useMemo<SelectOption[]>(() => {
    return (
      data?.items
        .filter((item) => item.status === AccessAccountsStatusDto.Finished)
        .map((item) => ({
          label: joinValues([item.accountType, item.id], ' - '),
          value: item.id,
        })) || []
    );
  }, [data]);

  const selectedAccount = itemAccessValue.accountId;

  const { data: locksData, isLoading: isLoadingLocks } = useAccessAccountLocks(selectedAccount, {
    enabled: !!selectedAccount,
  });

  const deviceOptions = React.useMemo<SelectOption[]>(() => {
    return (
      locksData?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || []
    );
  }, [locksData]);

  const handleRemoveUploadedImage = useCallback(
    (index: number | string) => {
      if (values.access.forAllUnits) {
        // same access
        setFieldValue(
          `${nameOfValue}.files`,
          // @ts-ignore
          itemAccessValue.files.filter((_, i) => i !== index),
        );

        // check if file exists and delete files for same access
        const currentFile = itemAccessValue.files.at(Number(index)) as ImageResponse;
        if (currentFile?.id) {
          deletePropertyAccessFile({ fileId: currentFile?.id, estateId: estateId });
        }
      } else {
        setFieldValue(
          `${nameOfValue}.files`,
          // @ts-ignore
          itemAccessValue.files.filter((_, i) => i !== index),
        );

        // check if file exists and delete files for separate unit access
        const currentFile = itemAccessValue.files.at(Number(index)) as ImageResponse;
        if (currentFile?.id && unitId) {
          deletePropertyUnitAccessFile({ fileId: currentFile?.id, estateId: estateId, unitId: unitId });
        }
      }
    },
    [setFieldValue, itemAccessValue.files],
  );
  const imagePreviewBlock = useMemo(() => {
    return (
      !!itemAccessValue.files.length && (
        <UploadedImagesIndex
          images={itemAccessValue.files.map((img) =>
            img instanceof File ? URL.createObjectURL(img) : img.originalUrl,
          )}
          onRemove={handleRemoveUploadedImage}
        />
      )
    );
  }, [handleRemoveUploadedImage, itemAccessValue.files]);

  return (
    <div>
      <div className='title text-base font-bold pb-2.5'>Key details</div>
      <div className='description text-base text-trueGray mb-4'>
        Help visitors understand how to access the building by providing clear instructions and selecting the access
        options below.
      </div>
      <Label label={'How to renters access the key?'}>
        <div className='flex flex-wrap gap-2.5'>
          {propertyKeyAccessTypeList.map(({ value, label }, index) => {
            return (
              <div key={index} className='w-[221px] flex items-center gap-3'>
                <RadioButton
                  label={label}
                  name={nameOfValue}
                  value={value}
                  checked={itemAccessValue.keyAccess === value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectRadio(e)}
                />
              </div>
            );
          })}
        </div>
      </Label>
      {isSelectedElectronicLock ? (
        isLoading ? (
          <Loader style={{ height: 68 }} />
        ) : (
          <>
            <Label label={'Account ID'}>
              <Select
                name={`${nameOfValue}.accountId`}
                placeholder={'Account'}
                value={itemAccessValue.accountId}
                options={accountOptions}
                onChange={(value) => setFieldValue(`${nameOfValue}.accountId`, value)}
                invalid={Boolean(errorAccessType?.accountId && touchedAccessType?.accountId)}
              />
            </Label>
          </>
        )
      ) : null}
      {Boolean(selectedAccount && isSelectedElectronicLock) ? (
        isLoadingLocks ? (
          <Loader style={{ height: 68 }} />
        ) : (
          <>
            <Label label={'Device ID'}>
              <Select
                name={`${nameOfValue}}.deviceId`}
                placeholder={'Device'}
                value={itemAccessValue.deviceId}
                options={deviceOptions}
                onChange={(value) => setFieldValue(`${nameOfValue}.deviceId`, value)}
                invalid={Boolean(errorAccessType?.deviceId && touchedAccessType?.deviceId)}
              />
            </Label>
          </>
        )
      ) : null}
      <Label label={'Access instructions'}>
        <Textarea
          name={`${nameOfValue}.accessInstructions`}
          value={itemAccessValue.accessInstructions}
          placeholder={'Access instructions'}
          theme={'default'}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setFieldValue(`${nameOfValue}.accessInstructions`, e.target.value)
          }
        />
      </Label>
      <Label label={'Optionally add photos to support the visitor in finding they keys'}>
        <UploadFiles
          title={uploadFileConfig.images.title}
          accept={uploadFileConfig.images.accept}
          onDrop={(files) => setFieldValue(`${nameOfValue}.files`, [...itemAccessValue.files, ...files])}
          invalid={Boolean(errorAccessType?.files && touchedAccessType?.files)}
        />
        {imagePreviewBlock}
      </Label>
    </div>
  );
};

const SeparateAccessTypeComponent = ({
  values,
  errors,
  touched,
  index,
  useExpandableList,
  setFieldValue,
  resetForm,
  estateId,
}: SeparateAccessTypeComponentProps) => {
  const unitErrors = errors?.details?.units?.[index] as FormikErrors<PropertyUnitValues>;
  const unitTouched = touched?.details?.units?.[index] as FormikTouched<PropertyUnitValues>;
  const unitData = values?.details?.units[index];

  const { expandedIds, handleExpand } = useExpandableList;
  const isExpanded = expandedIds.includes(index);

  return (
    <>
      <div key={index} className='flex flex-col gap-9 my-4'>
        <div
          className={`flex px-5 py-2 rounded-lg flex-col md:flex-row gap-[14px] bg-[#FAFAFA] border box-border relative border-[#FAFAFA]`}
        >
          <div className={`flex flex-col w-full `}>
            <div className='mb-4 flex flex-col lg:flex-row w-full'>
              <div className={clsx('w-full', 'max-w-[400px] mr-0 md:mr-2')}>
                <Label label={'Unit / Apt. number'}>
                  <p>{unitData?.unitNumber}</p>
                </Label>
              </div>
              <div className='flex items-center justify-between min-w-[250px] max-w-[289px] gap-2'>
                <EstateDetailsShortInfo
                  values={values.details}
                  unitErrors={unitErrors}
                  unitTouched={unitTouched}
                  index={index}
                />
              </div>
            </div>
            {isExpanded && (
              <GenericAccessTypeForm
                setFieldValue={setFieldValue}
                resetForm={resetForm}
                nameOfValue={`details.units.[${index}].access`}
                itemAccessValue={unitData?.access}
                errorAccessType={unitErrors?.access}
                touchedAccessType={unitTouched?.access}
                values={values}
                estateId={estateId}
                unitId={unitData?.id || ''}
              />
            )}
          </div>

          {
            <div className='p-1 cursor-pointer absolute right-4 top-2' onClick={() => handleExpand(index)}>
              <Icon name={!isExpanded ? 'arrow-down' : 'arrow-up'} color={'black'} />
            </div>
          }
        </div>
      </div>
    </>
  );
};

const PropertyBuildingAccess = ({
  stepTitle,
  values,
  errors,
  touched,
  setFieldValue,
  submitForm,
  resetForm,
  propertyId,
}: PropertyBuildingAccessProps) => {
  const isLoading = useIsMutating({ mutationKey: [QueryKey.PropertyAccess] }) > 0;
  const isMultiple = values?.basics?.type === PropertyUnitType.Multiple;

  const accessPropertyTitle = (() => {
    return (
      <div className='title flex flex-col gap-3'>
        {stepTitle && <div className='text-primaryDark uppercase font-semibold'>{stepTitle}</div>}
        <div className='title text-xl font-medium'>Building access</div>
      </div>
    );
  })();

  const sameAccess = values?.access?.forAllUnits;
  const accessTypeStyles = (sameAccess: boolean) =>
    `cursor-pointer p-10 w-1/2 flex justify-center items-center ${
      sameAccess ? 'bg-secondary text-primary' : 'border border-trueGray'
    }`;

  // Open close units access
  const [expandedIds, setExpandedIds] = React.useState<number[]>([]);

  const handleExpand = (id: number) => {
    setExpandedIds((prevExpandedIds) => {
      const isExpanded = prevExpandedIds.includes(id);
      if (isExpanded) {
        return prevExpandedIds.filter((expandId) => expandId !== id);
      }
      return [...prevExpandedIds, id];
    });
  };
  const useExpandableList = { expandedIds, handleExpand };

  return (
    <>
      <div className='property-basics flex flex-col gap-9'>
        {accessPropertyTitle}
        <div className='flex flex-col gap-2'>
          {isMultiple && (
            <div className='flex w-full gap-1 m-2'>
              <div
                className={accessTypeStyles(sameAccess)}
                onClick={() => {
                  setFieldValue('access.forAllUnits', true);
                }}
              >
                Same access to all units
              </div>
              <div
                className={accessTypeStyles(!sameAccess)}
                onClick={() => {
                  setFieldValue('access.forAllUnits', false);
                }}
              >
                Specific access to each unit
              </div>
            </div>
          )}
          {isMultiple && !sameAccess && (
            <div
              onClick={() => {
                setExpandedIds([]);
              }}
              className='text-sm font-semibold text-primary cursor-pointer flex w-full justify-end'
            >
              Collapse all
            </div>
          )}
          {isMultiple && !sameAccess ? (
            values?.details?.units?.map((unitItem, index) => {
              return (
                <SeparateAccessTypeComponent
                  key={unitItem?.id || index}
                  values={values}
                  errors={errors}
                  touched={touched}
                  index={index}
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                  useExpandableList={useExpandableList}
                  estateId={propertyId}
                />
              );
            })
          ) : (
            <GenericAccessTypeForm
              setFieldValue={setFieldValue}
              resetForm={resetForm}
              nameOfValue={'access'}
              itemAccessValue={values?.access}
              errorAccessType={errors?.access}
              touchedAccessType={touched?.access}
              values={values}
              estateId={propertyId}
            />
          )}
        </div>

        <div className='button-wrapper flex justify-end'>
          <Button label={'Save and continue'} theme={'primary'} onClick={submitForm} isLoading={isLoading} />
        </div>
      </div>

      {/* <ProgressBar progressPercentage={progressValue} /> */}
    </>
  );
};

export { PropertyBuildingAccess };
