import React from 'react';
import {
  Badge,
  BookmarkIcon,
  Button,
  Divider,
  FavouriteIcon,
  EstateShortPropertyInfo,
  StarIcon,
} from 'legacy-components/componets';

import styles from './styles';
import { CardCarousel } from './card-carousel';
import { EstateStatusDto, EstateTypeDto, PropertyUnitType } from 'common/enums/enums';
import { isNumber } from 'lodash-es';

export enum IEstateCardStatus {
  Incomplete = 'incomplete',
  PendingApproval = 'pendingApproval',
  Available = 'available',
  Unavailable = 'unavailable',
  Occupied = 'occupied',
  Rejected = 'rejected',
  PendingPayment = 'pendingPayment',
}

export type EstateCardTheme = 'vertical' | 'horizontal';

export interface IEstateCard {
  id: string;
  title: string;
  address: string;
  category: string;
  status?: IEstateCardStatus;
  price: number | string;
  images: string[];
  beds: number | string;
  bathrooms: number | string;
  propertyArea: number;
  isAuth?: boolean;
  scheduleAt?: string;
  theme?: EstateCardTheme;
  onClick?: (id: string, status: string) => void;
  onFavouriteClick?: (id: string) => void;
  onBookmarkClick?: (id: string) => void;
  onApplyClick?: (id: string) => void;
  onPreviewClick?: (id: string) => void;
  onCompleteListingClick?: (id: string) => void;
  manager?: {
    name: string;
    avatar: string;
    position: string;
  };
  stylesConfig?: {
    container?: React.CSSProperties;
  };
  isBadge?: boolean;
  isList?: boolean;
  isMultiBadge?: boolean;
  type?: EstateTypeDto | PropertyUnitType;
  units?: any;
}

const cardStatusToEstateStatusDto: Record<IEstateCardStatus, EstateStatusDto> = {
  [IEstateCardStatus.Available]: EstateStatusDto.Available,
  [IEstateCardStatus.Incomplete]: EstateStatusDto.Incomplete,
  [IEstateCardStatus.PendingApproval]: EstateStatusDto.PendingApproval,
  [IEstateCardStatus.Unavailable]: EstateStatusDto.Unavailable,
  [IEstateCardStatus.Occupied]: EstateStatusDto.Occupied,
  [IEstateCardStatus.Rejected]: EstateStatusDto.Rejected,
  [IEstateCardStatus.PendingPayment]: EstateStatusDto.PendingPayment,
};
const isMultiUnit = (type: EstateTypeDto | PropertyUnitType) =>
  type === EstateTypeDto.MultiUnit || type === PropertyUnitType.Multiple;

const Card = ({
  id,
  title,
  address,
  images,
  category,
  status,
  price,
  beds,
  bathrooms,
  propertyArea,
  onClick,
  isAuth,
  onFavouriteClick,
  onBookmarkClick,
  onApplyClick,
  onPreviewClick,
  onCompleteListingClick,
  manager,
  theme = 'vertical',
  stylesConfig,
  isBadge,
  isList,
  isMultiBadge,
  type,
  units,
}: IEstateCard) => {
  const isCardHorizontal = theme === 'horizontal';

  const handleClick = () => {
    onClick?.(id, status ?? '');
  };

  const handleFavoriteClick = React.useCallback(() => {
    onFavouriteClick?.(id);
  }, [id, onFavouriteClick]);

  const handleBookmarkClick = React.useCallback(() => {
    onBookmarkClick?.(id);
  }, [id, onBookmarkClick]);

  const handleApplyClick = React.useCallback(() => {
    onApplyClick?.(id);
  }, [id, onApplyClick]);

  const imageContent = React.useMemo(() => {
    const statusText: Record<IEstateCardStatus, string> = {
      [IEstateCardStatus.Available]: 'Available',
      [IEstateCardStatus.Incomplete]: 'Incomplete',
      [IEstateCardStatus.PendingApproval]: 'Pending Approval',
      [IEstateCardStatus.Unavailable]: 'Unavailable',
      [IEstateCardStatus.Occupied]: 'Occupied',
      [IEstateCardStatus.Rejected]: 'Rejected',
      [IEstateCardStatus.PendingPayment]: 'Pending Payment',
    };

    return (
      <div id='carouselExampleCaptions' className={styles[theme].imageContainer}>
        {isAuth && onFavouriteClick && (
          <FavouriteIcon className={styles[theme].favouriteIcon} onClick={handleFavoriteClick} />
        )}
        {!isAuth && onBookmarkClick && (
          <BookmarkIcon className={styles[theme].bookmarkIcon} onClick={handleBookmarkClick} />
        )}
        {isBadge && status && (
          <Badge
            size={'m'}
            theme={'black'}
            title={statusText[status]}
            style={{ position: 'absolute', top: 12, left: 12, zIndex: 1 }}
          />
        )}
        {isList && status ? (
          <CardCarousel images={images} status={cardStatusToEstateStatusDto[status]} />
        ) : (
          <CardCarousel images={images} />
        )}
      </div>
    );
  }, [handleBookmarkClick, handleFavoriteClick, images, isAuth, onBookmarkClick, onFavouriteClick, status, theme]);

  const priceContent = React.useMemo(() => {
    return (
      <div className={styles[theme].price.container}>
        <p className={styles[theme].price.amount}>{`$${price}`}</p>
        <p className={styles[theme].price.unit}>/mo</p>
      </div>
    );
  }, [price, theme]);

  const typeContent = React.useMemo(() => {
    const displayUnitsCount = (count: number): string => {
      return count === 1 ? `${count} unit` : `${count} units`;
    };

    return (
      <div className='flex gap-1'>
        <div className={styles[theme].type.container}>
          <div className={styles[theme].type.title}>{category}</div>
        </div>
        {isMultiBadge && type && isMultiUnit(type) && units && isNumber(units) && units > 0 && (
          <div className={styles[theme].units.container}>
            <div className={styles[theme].units.title}>{displayUnitsCount(units)}</div>
          </div>
        )}
      </div>
    );
  }, [theme, category]);

  const locationContent = React.useMemo(() => {
    return (
      <div className={styles[theme].location.container} style={{ paddingBottom: isCardHorizontal ? undefined : 26 }}>
        <h5 className={styles[theme].location.title}>{title}</h5>
        <p className={styles[theme].location.address}>{address}</p>
      </div>
    );
  }, [theme, isCardHorizontal, title, address]);

  const infoContent = React.useMemo(() => {
    return (
      <EstateShortPropertyInfo
        beds={beds}
        bathrooms={bathrooms}
        propertyArea={propertyArea}
        iconProps={{ color: '#66CCFF' }}
      />
    );
  }, [beds, bathrooms, propertyArea]);

  const managerContent = React.useMemo(() => {
    return (
      manager && (
        <div className='manager flex items-center p-6 gap-3'>
          <img src={manager.avatar} className='rounded-full w-[40px] h-[40px] mx-auto cursor-pointer' alt='Avatar' />
          <div className='manager-info grow'>
            <div className='text-sm text-black'>{manager.name}</div>
            <div className='text-sm text-warmGray'>{manager.position}</div>
          </div>
          <div className='flex justify-between items-center gap-1'>
            <StarIcon color={'#C4C4C4'} />
            <div className='text-sm text-warmGray'>{4.5}</div>
            <div className='text-sm text-warmGray'>{'(23)'}</div>
          </div>
        </div>
      )
    );
  }, [manager]);

  const applyButton = React.useMemo(() => {
    return (
      onApplyClick && (
        <div className='px-6'>
          <Button label={'Apply Now'} theme={'primary'} onClick={handleApplyClick} />
        </div>
      )
    );
  }, [onApplyClick, handleApplyClick]);

  const previewButton = React.useMemo(() => {
    return (
      onPreviewClick && (
        <div className='p-3 hidden'>
          <Button
            label={'Show preview'}
            theme={'primary-outline'}
            onClick={() => onPreviewClick(id)}
            stylesConfig={{ width: '100%' }}
          />
        </div>
      )
    );
  }, [onPreviewClick, id]);

  const isIncomplete = status === IEstateCardStatus.Incomplete;

  const handleCompleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCompleteListingClick?.(id);
  };

  return (
    <div
      className={`flex justify-center cursor-pointer w-full rounded-lg ${
        isIncomplete ? 'border  border-[#F3C521]' : ''
      } `}
      onClick={handleClick}
      style={stylesConfig?.container}
    >
      <div className={styles[theme].container}>
        {imageContent}
        <div className='flex flex-col p-3 flex-grow'>
          <div className='flex flex-col gap-2 mb-[5px]'>
            {priceContent}
            {typeContent}
          </div>
          <div className='flex justify-between flex-grow gap-3'>
            <div className={`${isCardHorizontal ? 'w-[312px]' : 'w-full'} flex flex-col overflow-hidden`}>
              {locationContent}
              {isCardHorizontal && <Divider style={{ margin: '32px 0' }} />}
              {infoContent}
            </div>
            {onCompleteListingClick && isIncomplete && (
              <Button
                onClick={handleCompleteClick}
                label={'complete listing'}
                stylesConfig={{ backgroundColor: '#EF4444' }}
              />
            )}
          </div>
        </div>
        {previewButton}
        {managerContent}
        {applyButton}
      </div>
    </div>
  );
};

export { Card };
