import React from 'react';
import { AvatarProps } from 'common/types/types';
import defaultAvatar from 'assets/images/default-avatar.png';

const Avatar = ({ src, width, height }: AvatarProps) => {
  return (
    <img
      src={src || defaultAvatar}
      className='rounded-full w-[40px] h-[40px] cursor-pointer'
      alt='Avatar'
      style={{ width, height }}
    />
  );
};

export { Avatar };
