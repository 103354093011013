import { Filters } from 'components/molecules/Filters/Filters';
import { OffersFiltersMenuForm } from './OffersFiltersForm';
import { QueryParamConfig, StringParam, createEnumDelimitedArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { useMemo } from 'react';
import { OfferStatusDto } from 'common/enums/services/api/offers/offer-status-dto.enum';

export type QueryParamConfigType = {
  proposedRentMin: QueryParamConfig<string>;
  proposedRentMax: QueryParamConfig<string>;
  statuses: QueryParamConfig<`${OfferStatusDto}`[]>;
};

const defaultQueryParams = {
  proposedRentMin: '',
  proposedRentMax: '',
  statuses: [],
};

const OffersFilters = () => {
  const queryParamConfig = useMemo<QueryParamConfigType>(
    () => ({
      proposedRentMin: withDefault(StringParam, defaultQueryParams.proposedRentMin),
      proposedRentMax: withDefault(StringParam, defaultQueryParams.proposedRentMax),
      statuses: withDefault(
        createEnumDelimitedArrayParam(Object.values(OfferStatusDto) as `${OfferStatusDto}`[], ','),
        [],
      ),
    }),
    [],
  );

  const [query, setQuery] = useQueryParams(queryParamConfig, {
    updateType: 'replace',
  });

  const handleSubmit = (values: any) => {
    setQuery(values);
  };

  const handleReset = () => {
    setQuery(defaultQueryParams);
  };

  return (
    <Filters
      initialValues={defaultQueryParams}
      filters={query}
      onSubmit={handleSubmit}
      onReset={handleReset}
      FiltersMenuForm={OffersFiltersMenuForm}
    />
  );
};

export { OffersFilters };
