import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NotificationsDto } from '../../../common/types/services/api/notification/notifications-dto.type';
import { PaginationDtoRequest } from '../../../common/types/services/api/common/pagination-dto.type';
import { readNotification } from 'services/api/notification-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useReadNotification(params?: PaginationDtoRequest) {
  const queryClient = useQueryClient();

  return useMutation<boolean, Error, string, { previousNotifications?: NotificationsDto }>({
    mutationFn: readNotification,
    onMutate: async (notificationId) => {
      await queryClient.cancelQueries({ queryKey: [QueryKey.Notifications] });
      const previousNotifications = queryClient.getQueryData<NotificationsDto>([QueryKey.Notifications, params]);
      queryClient.setQueryData<NotificationsDto>([QueryKey.Notifications, params], (old) => {
        if (!old) return undefined;
        return {
          ...old,
          items: old.items.map((item) => (item.id === notificationId ? { ...item, isRead: true } : item)),
          totalUnread: old.totalUnread - 1,
        };
      });
      return { previousNotifications };
    },
    onError: (err, notificationId, context) => {
      queryClient.setQueryData([QueryKey.Notifications, params], context?.previousNotifications);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Notifications] });
    },
  });
}
