import { FC } from 'react';
import { EstateCardUnitsBadgeProps } from './types';

const EstateCardUnitsBadge: FC<EstateCardUnitsBadgeProps> = ({ units }) => {

  const displayUnitsCount = (count: number): string => {
    return count === 1 ? `${count} unit` : `${count} units`;
  };

  if (!units) {
    return null;
  }

  return (
    <div className={'flex justify-center items-center'}>
      <div
        className={`text-[11px] inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline 
            font-semibold bg-secondary text-primary rounded-full`}
      >
        {displayUnitsCount(units)}
      </div>
    </div>
  );
};

export { EstateCardUnitsBadge };
