import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { rejectOffer } from 'services/api/offers-api.service';
import { QueryKey } from 'common/enums/query-key';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';

export default function useRejectOffer(
  options?: Omit<
    UseMutationOptions<boolean, AxiosError<BackendError>, { applicationId: string; reason: string }, unknown>,
    'mutationFn'
  >,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: rejectOffer,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Offers] });
    },
    ...options,
  });
}
