export enum NotificationItemTypeDto {
  Link = 'Link',
  Download = 'Download',
  Information = 'Information',
}

export enum NotificationItemCategoryDto {
  RenterOfferAccepted = 'RenterOfferAccepted',
  RenterOfferRejected = 'RenterOfferRejected',
  RenterOfferPaid = 'RenterOfferPaid',
  RenterTourNew = 'RenterTourNew',
  LandlordTourFinised = 'LandlordTourFinised',
  LandlordOfferNew = 'LandlordOfferNew',
  LandlordOfferPaid = 'LandlordOfferPaid',
  LandlordTourNew = 'LandlordTourNew',
  LandlordTourEndFeedback = 'LandlordTourEndFeedback',
  RenterTourRescheduled = 'RenterTourRescheduled',
  LandlordTourRescheduled = 'LandlordTourRescheduled',
  RenterEstateUnavaliable = 'RenterEstateUnavaliable',
  RenterUnitOccupied = 'RenterUnitOccupied',
  LandlordTrialWillEnd = 'LandlordTrialWillEnd',
  LandlordOfferUpdated = 'LandlordOfferUpdated',
  RenterTourRejected = 'RenterTourRejected',
  NewTourSccheduleConfirmedRenter = 'NewTourSccheduleConfirmedRenter',
  UserGetGiftCard = 'UserGetGiftCard',
  LandlordEstateRejectedByAdmin = 'LandlordEstateRejectedByAdmin',
  RenterTourExpired = 'RenterTourExpired',
  PreApplicationPaidLandLord = 'PreApplicationPaidLandLord',
  PreApplicationPaidRenter = 'PreApplicationPaidRenter',
  PreApplicationAcceptedRenter = 'PreApplicationAcceptedRenter',
  PreApplicationAcceptedLandLord = 'PreApplicationAcceptedLandLord'
}

export enum NotificationItemStatusDto {
  Created = 'Created',
  InProgress = 'InProgress',
  Success = 'Success',
  Error = 'Error',
}
