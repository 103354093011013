import { Icon } from 'legacy-components/ui/ui';

export type RatingProps = {
  value: number;
};

const Rating = ({ value }: RatingProps) => {
  return (
    <div className='flex justify-between w-[62px] border-b-2 border-[#66CCFF] py-1.5 pr-1'>
      <div className='text-xl font-semibold text-primary'>{value}</div>
      <Icon name={'star'} />
    </div>
  );
};

export { Rating };
