import { FC, ReactNode, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

type Props = {
    children: ReactNode;
}

const InvestorAuthGuard: FC<Props> = ({ children }) => {
  const [cookies] = useCookies(['user-access-key']);
  const { state, pathname } = useLocation();

  const navigateToEstate = useMemo(() => {
    if (!cookies['user-access-key']) {
      return <Navigate to='/strict-auth' state={{ ...state, from: { pathname } }} replace />;
    } else {
      return children;
    }
  }, [children, pathname, cookies]);

  return <>{navigateToEstate}</>;
};

export { InvestorAuthGuard };
