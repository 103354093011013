import dayjs from 'dayjs';
import { PropertyUnitValues } from '../property-detail/property-detail';

const today = dayjs();

export const getCommonMinDate = (units: PropertyUnitValues[]) => {
  const availableOnArray = units?.map((item: any) => dayjs(item.availableOn));
  const minDate = dayjs.min(availableOnArray);
  const resultDate = minDate?.isBefore(today) ? today : minDate;
  return resultDate.toDate();
};

export const getSeparateMinDate = (unitDate: string | Date) => {
  const minDate = dayjs(unitDate);
  const resultDate = minDate?.isBefore(today) ? today : minDate;
  return resultDate.toDate();
};
