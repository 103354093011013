import { Filters } from 'components/molecules/Filters/Filters';
import { AdminCommissionsFiltersForm } from './AdminCommissionsFiltersForm';
import { QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useMemo } from 'react';
import { useWindowSize } from 'hooks';

type QueryParamConfigType = {
  state: QueryParamConfig<string>;
  city: QueryParamConfig<string>;
};

const defaultQueryParams = {
  state: '',
  city: '',
};

const AdminCommissionsFilters = () => {
  const { isMobile } = useWindowSize();
  const queryParamConfig = useMemo<QueryParamConfigType>(
    () => ({
      state: withDefault(StringParam, defaultQueryParams.state),
      city: withDefault(StringParam, defaultQueryParams.city),
    }),
    [],
  );

  const [query, setQuery] = useQueryParams(queryParamConfig, {
    updateType: 'replace',
  });

  const handleSubmit = (values: any) => {
    setQuery(values);
  };

  const handleReset = () => {
    setQuery(defaultQueryParams);
  };

  return (
    <Filters
      initialValues={defaultQueryParams}
      filters={query}
      onSubmit={handleSubmit}
      onReset={handleReset}
      FiltersMenuForm={AdminCommissionsFiltersForm}
      buttonProps={
        isMobile
          ? {
              label: '',
              stylesConfig: {
                minWidth: 'initial',
              },
              className: 'min-w-[104px] px-3',
            }
          : undefined
      }
    />
  );
};

export { AdminCommissionsFilters };
