import React from 'react';
import {
  Button,
  EstateShortPropertyInfo,
  Loader,
  LocationPin,
  NotificationTypeIcon,
} from 'legacy-components/componets';
import { ViewTourDetailsModalProps } from './ViewTourDetailsModal.types';
import {
  ARRIVAL_TIME,
  MODAL_TITLE,
  PERMONTH,
  REASON,
  STATUS_TEXT,
  SUBMIT_BTN,
  TOUR_DATE,
  TOUR_DETAILS,
  TOUR_DURATION,
  TOUR_DURATION_MIN,
  TOUR_ENDS,
  WARNING_MSG,
} from './ViewTourDetailsModal.const';
import { Modal } from 'components/organisms/Modal';
import { useWindowSize } from 'hooks';
import { formatPriceValue } from 'helpers/string.helpers';
import { DOLLAR } from 'components/molecules/SubscriptionStatusComponent/SubscriptionStatusComponent.const';
import { dateFormatMM_DD_YY, dateTimeFormatHHMM_a, getDayjs } from 'helpers/date';
import useLandlordTourDetails from 'hooks/query/tours/use-landlord-tour-details';
import { TourStatusDto } from 'common/enums/enums';
import dayjs from 'dayjs';
import { Image } from 'antd';
import defaultEstatePhoto from 'assets/images/default_estate_image.png';
import { TourStatus } from 'components/atoms/TourStatus/TourStatus';

export const ViewTourDetailsModal: React.FC<ViewTourDetailsModalProps> = ({ open, onClose, onOk, tourId }) => {
  const { isMobile, isMiniTablet } = useWindowSize();

  const { data, isLoading } = useLandlordTourDetails(tourId, { enabled: !!tourId && open });
  const utcDate = dayjs(data?.slot).utc();

  return (
    <>
      <Modal
        isOpen={open}
        isFullScreen={isMobile || isMiniTablet}
        onOpenChange={(open) => {
          if (open) return;
          onClose();
        }}
        title={MODAL_TITLE}
      >
        <div className='sm:min-w-[500px] flex flex-col gap-3 sm:max-w-[550px]'>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {data?.tourStatus === TourStatusDto.Rejected && (
                <>
                  <div className='flex p-2 items-center justify-start gap-3 rounded-xl bg-secondary'>
                    <NotificationTypeIcon type='error' />
                    <div className='text-base text-error'>{WARNING_MSG}</div>
                  </div>
                  <div className='flex flex-col p-2 items-start'>
                    <div className='text-base font-bold mb-2'>{REASON}</div>
                    <div className='text-base border border-primary w-full pl-4 p-2 rounded-lg text-wrapper text-ellipsis overflow-hidden ...'>
                      {data?.rejectReason}
                    </div>
                  </div>
                </>
              )}
              <Image
                src={data?.thumbnailUrl}
                alt='self-tour details'
                className={'w-full object-contain h-[300px]'}
                fallback={defaultEstatePhoto}
                preview={false}
              />
              {data?.tourStatus && (
                <div className='flex justify-between my-2'>
                  <p className='font-bold'>{STATUS_TEXT}</p>
                  <TourStatus tourStatus={data?.tourStatus} />
                </div>
              )}
              <section>
                <div className='location flex justify-between'>
                  <div className='mr-2.5'>
                    <div className='flex gap-4 pb-4'>
                      <div className='text-[20px] font-bold text-black'>{data?.city}</div>
                    </div>
                    <div className='flex items-center text-base text-trueGray'>
                      <LocationPin />
                      <span className='pl-3'>{data?.location}</span>
                    </div>
                  </div>
                  <div className='flex items-center gap-[5px]'>
                    <p className='text-primaryDark text-2xl font-bold'>{`${DOLLAR}${formatPriceValue(
                      data?.price || 0,
                    )}`}</p>
                    <p className='text-base font-normal text-trueGray ml-[5px]'>{PERMONTH}*</p>
                  </div>
                </div>
              </section>
              <EstateShortPropertyInfo
                beds={data?.beds || 0}
                bathrooms={data?.bathrooms || 0}
                propertyArea={data?.propertyArea || 0}
                iconProps={{ color: '#66CCFF' }}
              />
              <div className='description flex flex-col gap-3 bg-secondary p-6 rounded-lg'>
                <div className='font-bold'>{TOUR_DETAILS}</div>
                <div className='text-base font-normal gap-9 flex justify-between'>
                  <div className='flex flex-col'>
                    <span>{TOUR_DATE}</span>
                    <span>{ARRIVAL_TIME}</span>
                    <span>{TOUR_DURATION}</span>
                    <span>{TOUR_ENDS}</span>
                  </div>
                  <div className='flex flex-col text-trueGray'>
                    <span>{dateFormatMM_DD_YY(utcDate)}</span>
                    <span>{dateTimeFormatHHMM_a(utcDate)}</span>
                    <span>{TOUR_DURATION_MIN}</span>
                    <span>{dateTimeFormatHHMM_a(getDayjs(utcDate).add(30, 'minutes'))}</span>
                  </div>
                </div>
              </div>
              <Button label={SUBMIT_BTN} onClick={onOk} className='w-full mt-4' />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
