import React from 'react';
import { Carousel, Icon, Modal } from 'legacy-components/componets';
import { ImageResponse, LocalImage } from 'common/mappers/property';

export type UploadedImagesIndexProps = {
  images?: string[];
  imagesProps?: (ImageResponse | LocalImage)[];
  videos?: any[];
  onRemove: (index: number) => void;
  maxShowMoreImages?: number;
  BottomExtra?: (
    params: (ImageResponse | LocalImage) & {
      index: number;
    },
  ) => React.ReactNode;
  imgSize?: {
    width: number | string;
    height: number | string;
  };
};

const MAX_SHOW_MORE_COUNT = 4;

const UploadedImagesIndex = ({
  images,
  videos,
  onRemove,
  maxShowMoreImages,
  BottomExtra,
  imagesProps,
  imgSize,
}: UploadedImagesIndexProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const files = images || videos || [];

  const maxShowMore = maxShowMoreImages ?? MAX_SHOW_MORE_COUNT;

  const isShowMore = files.length > maxShowMore;
  const numberOtherImages = files.length - maxShowMore;

  return (
    <>
      <div
        className={`w-full rounded-lg bg-[#FAFAFA] mt-4 ${maxShowMoreImages ? 'flex gap-3 flex-wrap' : 'flex gap-2'}`}
      >
        {videos &&
          videos.slice(0, maxShowMore).map((video, index) => {
            const isLastIndex = index === maxShowMore - 1;
            return (
              <div key={index} className='group w-20 h-20 rounded-lg overflow-hidden relative cursor-pointer'>
                <video src={video} className='w-full h-full object-cover'></video>

                {!(isShowMore && isLastIndex) && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onRemove(index);
                    }}
                    className='p-[4px] absolute rounded-full top-[6px] right-[6px] text-white bg-rose-500'
                  >
                    <Icon name={'faTrash'} className='cursor-pointer' height={12} width={12} />
                  </div>
                )}

                {isShowMore && isLastIndex && (
                  <div
                    className='absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed'
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
                  >
                    <div className='flex h-full items-center justify-center'>
                      <p className='text-white opacity-100 font-bold'>{`+ ${numberOtherImages} more`}</p>
                    </div>
                  </div>
                )}
              </div>
            );
          })}

        {images &&
          images.slice(0, maxShowMore).map((img, index) => {
            const isLastIndex = index === maxShowMore - 1;
            return (
              <div
                key={index}
                className='group w-20 h-20 relative cursor-pointer'
                onClick={() => setIsOpen(true)}
                style={{
                  width: imgSize?.width,
                  height: imgSize?.height,
                }}
              >
                <img src={img} alt='estate' className='w-full h-full object-cover rounded-lg' />
                {BottomExtra && imagesProps ? BottomExtra({ ...imagesProps[index], index }) : null}
                {!(isShowMore && isLastIndex) && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onRemove(index);
                    }}
                    className='p-[4px] absolute rounded-full top-[6px] right-[6px] text-white bg-rose-500'
                  >
                    <Icon name={'faTrash'} className='cursor-pointer' height={12} width={12} />
                  </button>
                )}
                {isShowMore && isLastIndex && (
                  <div
                    onClick={() => setIsOpen(true)}
                    className='absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed'
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
                  >
                    <div className='flex h-full items-center justify-center'>
                      <p className='text-white opacity-100 font-bold'>{`+ ${numberOtherImages} more`}</p>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        styles={{
          content: {
            width: '90%',
            maxWidth: 700,
          },
        }}
      >
        <Carousel
          images={files}
          showThumbs
          height={300}
          renderThumbs={() =>
            files.map((file, i) => (
              <div className={'group relative h-full w-full'}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(i);
                  }}
                  className='p-[4px] absolute rounded-full top-[6px] right-[6px] text-white bg-rose-500'
                >
                  <Icon name={'faTrash'} className='cursor-pointer' height={12} width={12} />
                </button>
                <img src={file} style={{ height: 300 }} className={'object-cover w-full h-full'} alt='gallery' />
              </div>
            ))
          }
        />
      </Modal>
    </>
  );
};

export { UploadedImagesIndex };
