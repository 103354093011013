export enum AuthApiPath {
  SIGN_UP = 'userAccess/UserRegistrations',
  SIGN_IN = 'connect/token',
  CURRENT_USER = 'userAccess/authenticatedUser',
  SEND_RESET_PASSWORD_EMAIL = 'userAccess/PasswordReset/sendEmail',
  SEND_PHONE_CONFIRMATION_CODE = '/userAccess/PhoneConfirmation',
  GET_USER_BY_RESET_PASSWORD_TOKEN = 'userAccess/PasswordReset/user',
  RESET_PASSWORD = 'userAccess/PasswordReset',
  CHANGE_PASSWORD = 'userAccess/PasswordReset/newPassword',
  CONFIRM_EMAIL = 'userAccess/authenticatedUser/confirm',
  VERIFY_EMAIL = 'userAccess/authenticatedUser/email',
  SIGN_IN_INVESTOR = 'userAccess/preregistration',
  TWO_FACTOR_AUTH = 'userAccess/TwoFactorAuthentication',
  SEND_USER_VERIFICATION_CODE = 'userAccess/authenticatedUser/SendUserVerificationCode',
  UPDATE_USER_EMAIL = 'userAccess/authenticatedUser/UpdateUserEmail',
  IS_EMAIL_EXISTS = 'userAccess/authenticatedUser/IsEmailExists',
  PERSONA_VERIFICATION = 'userAccess/personaverification'
}
