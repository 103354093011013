import Icon from '@ant-design/icons';
import { formatToLocaleString, joinValues } from 'helpers/helpers';
import { FC } from 'react';
import { ReactComponent as BedIcon } from 'assets/icons/bed.svg';
import { ReactComponent as BathIcon } from 'assets/icons/bath.svg';
import { ReactComponent as TriangleIcon } from 'assets/icons/ruler-triangle.svg';
import { EstateCardInfoProps } from './types';

const EstateCardInfo: FC<EstateCardInfoProps> = ({ beds, bathrooms, propertyArea }) => {
  return (
    <div className='w-full max-w-xs'>
      <div className='flex justify-between text-sm text-trueGray font-normal'>
        <div className='flex flex-col justify-center items-center gap-2'>
          <Icon component={BedIcon} className='text-2xl text-primary' />
          <span>{`${beds} Beds`}</span>
        </div>
        <div className='flex flex-col justify-center items-center gap-2'>
          <Icon component={BathIcon} className='text-2xl text-primary' />
          <span>{`${bathrooms} Baths`}</span>
        </div>
        <div className='flex flex-col justify-center items-center gap-2'>
          <Icon component={TriangleIcon} className='text-2xl text-primary' />
          <span>{joinValues([formatToLocaleString(propertyArea), 'sq ft'], ' ')}</span>
        </div>
      </div>
    </div>
  );
};

export { EstateCardInfo };
