import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { SelfTourScheduleModal } from './self-tour-schedule-modal';
import { SelfTourRescheduledModal } from './self-tour-rescheduled-modal';
import { TourDto } from 'common/types/services/api/tours/tour-dto.type';
import dayjs from 'dayjs';
import { FreeRescheduleTour } from './free-reschedule-tour-modal';
import { PaidPaidRescheduleTourModal } from 'components/organisms/RescheduleTour/PaidRescheduleTour/PaidRescheduleTourModal';
import { Loader, Modal } from 'legacy-components/componets';
import { PayForRescheduleTour } from 'components/organisms/RescheduleTour/PayForResheduleTour/PayForResheduleTour';
import { PaymentInProgressModal } from 'components/organisms/RescheduleTour/PaymentInProgress/PaymentInProgress';
import { useRescheduleTourInfo } from 'hooks/query';

type RescheduleTourModalProps = {
  tour: TourDto;
  onClose: () => void;
  open: boolean;
  onConfirm: (nextDate: Date) => Promise<void>;
  styles?: {
    overlay?: CSSProperties;
    content?: CSSProperties;
  };
};

type OpenedModal =
  | 'select-date-and-time'
  | 'self-tour-schedule'
  | 'self-tour-rescheduled'
  | 'paid-self-tour-rescheduled'
  | 'pay-for-self-tour'
  | 'payment-in-progress';

export const RescheduleTourModal = ({ open, onClose, onConfirm, styles, tour }: RescheduleTourModalProps) => {
  const { data: rescheduleTourInfo, isLoading: isRescheduleTourInfoLoading } = useRescheduleTourInfo(tour.id);
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState<string>('');
  const [openedModal, setOpenedModal] = useState<OpenedModal>('select-date-and-time');
  const [startDate, setStartDate] = useState<Date>(dayjs().toDate());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const nextDate = useMemo<Date>(() => {
    if (!selectedTimeSlot || !selectedDate) return new Date();
    const [hours, minutes] = selectedTimeSlot.split(':');
    return dayjs(selectedDate).set('hours', Number(hours)).set('minutes', Number(minutes)).toDate();
  }, [selectedDate, selectedTimeSlot]);

  const handleClose = () => {
    setOpenedModal('select-date-and-time');
    setSelectedDate(null);
    setSelectedTimeSlot('');
    setStartDate(dayjs().toDate());
    onClose();
  };

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date);
    setSelectedTimeSlot('');
  };

  const onMonthChange = (date: Date) => {
    setStartDate(date);
    setSelectedDate(null);
    setSelectedTimeSlot('');
  };

  const getTimeSlot = () => [selectedTimeSlot, setSelectedTimeSlot];
  const getSelectedDate = () => [selectedDate, setSelectedDate];

  const showNewTimeModal = () => setOpenedModal('self-tour-schedule');
  const showPaymentModal = () => setOpenedModal('pay-for-self-tour');
  const showRescheduleCalendar = () => setOpenedModal('select-date-and-time');

  useEffect(() => {
    if (rescheduleTourInfo?.isWaitPaymentApprove) {
      setOpenedModal('payment-in-progress');
    } else if (!rescheduleTourInfo?.canReschedule) {
      setOpenedModal('paid-self-tour-rescheduled');
    } else {
      setOpenedModal('select-date-and-time');
    }
  }, [rescheduleTourInfo]);

  if (isRescheduleTourInfoLoading) {
    return (
      <Modal isOpen>
        <div className='p-3 flex flex-col items-center'>
          <Loader />
          <p className='text-xl text-primaryDark'>{'Loading...'}</p>
          <p className='text-base text-trueGray'>{'Please wait while we set things for you!'}</p>
        </div>
      </Modal>
    );
  }

  return (
    <>
      <SelfTourScheduleModal
        tour={tour}
        previousDate={new Date(tour.slot)}
        nextDate={nextDate}
        onClose={handleClose}
        onGoBack={() => {
          setOpenedModal('select-date-and-time');
        }}
        open={open && openedModal === 'self-tour-schedule'}
        onConfirm={async () => {
          await onConfirm(nextDate);
          setOpenedModal('self-tour-rescheduled');
        }}
      />
      <SelfTourRescheduledModal
        onClose={handleClose}
        open={open && openedModal === 'self-tour-rescheduled'}
        onContinue={onClose}
      />
      <FreeRescheduleTour
        startDate={startDate}
        tour={tour}
        open={open && openedModal === 'select-date-and-time'}
        handleChangeDate={handleChangeDate}
        getTimeSlot={getTimeSlot}
        getSelectedDate={getSelectedDate}
        openSecondModal={showNewTimeModal}
        onMonthChange={onMonthChange}
        onClose={onClose}
      />

      <PaidPaidRescheduleTourModal
        open={open && openedModal === 'paid-self-tour-rescheduled'}
        onClose={onClose}
        onConfirm={showPaymentModal}
        tour={tour}
      />

      <PayForRescheduleTour
        open={open && openedModal === 'pay-for-self-tour'}
        onClose={onClose}
        estateId={tour?.estateId}
        unitId={tour?.unitId}
        openRescheduleModal={showRescheduleCalendar}
        tourId={tour?.id}
      />

      <PaymentInProgressModal open={open && openedModal === 'payment-in-progress'} onClose={onClose} />
    </>
  );
};
