import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { VerificationPaymentDto } from 'common/types/services/api/payment/verification-payment-dto.type';
import { VerificationPaymentRequest } from 'common/types/services/api/payment/verification-payment-request.type';
import { getVerificationPayment } from 'services/api/payment-api.service';


export default function useGetPersonaVerificationPayment(
  param: VerificationPaymentRequest,  
  options?: Omit<
      UndefinedInitialDataOptions<
          VerificationPaymentDto, 
          Error, 
          VerificationPaymentDto, 
          (string | VerificationPaymentRequest)[]>,
      'queryFn' | 'queryKey'
    >   
  ) {
    return useQuery({
      queryKey: [QueryKey.PersonaVerification, "payment", param],
      queryFn: () => getVerificationPayment(param?.verificationId),
      ...options,
    });
  }