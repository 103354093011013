import React, { FC } from 'react';
import { PropertyUnitValues } from './property-detail';
import { FormikErrors } from 'formik/dist/types';
import { FormikTouched, useFormikContext } from 'formik';
import { Icon } from 'legacy-components/ui/icon/icon';
import clsx from 'clsx';
import { Label } from 'legacy-components/ui/label/label';
import { Input } from 'legacy-components/fields/input/input';
import { Select } from 'legacy-components/fields/select/select';
import { bathsOptions, bedroomsOptions, defaultOtherFeeValue, leaseTermOptions } from './config';
import { stringAsFloatNumber } from 'helpers/input-field.helper';
import { CustomDatePicker } from 'legacy-components/fields/custom-datepicker/custom-datepicker';
import { RichTextEditor } from 'legacy-components/fields/rich-text-editor/rich-text-editor';
import { Divider } from 'legacy-components/ui/divider/divider';
import { FormDescription } from 'legacy-components/form-description/form-description';
import { Button } from 'legacy-components/ui/button/button';
import PropertyUnitAmenities from './PropertyUnitAmenities';
import { PropertyValues } from '../landlord-properties';
import { SelectOption } from 'common/types/components/common/select-option.type';
import { SelectProps } from 'common/types/components/fields/select/select-props.type';
import { InputProps } from 'common/types/components/fields/input/input-props.type';
import ShortIconInfo from './ShortIconInfo';
import OtherFeesTable from './OtherFeesTable';
import { isEmpty } from 'lodash-es';

type UnitInputField = 'unitNumber' | 'squareFeet' | 'deposit' | 'rent' | 'description' | 'firstMonth' | 'lastMonth';
type UnitSelectField = 'beds' | 'baths' | 'deposit' | 'leaseDuration';

type UnitInputFieldsName = keyof Pick<PropertyUnitValues, UnitInputField>;
type UnitSelectFieldsName = keyof Pick<PropertyUnitValues, UnitSelectField>;

type UnitInputErrorsFieldsName = keyof FormikErrors<Pick<PropertyUnitValues, UnitInputField>>;
type UnitSelectErrorsFieldsName = keyof FormikErrors<Pick<PropertyUnitValues, UnitSelectField>>;

type UnitInputTouchedFieldsName = keyof FormikTouched<Pick<PropertyUnitValues, UnitInputField>>;
type UnitSelectTouchedFieldsName = keyof FormikTouched<Pick<PropertyUnitValues, UnitSelectField>>;

type Props = {
  index: number;
  unit: PropertyUnitValues;
  isMultiUnit: boolean;
  error?: FormikErrors<PropertyUnitValues>;
  touched?: FormikTouched<PropertyUnitValues>;
  isExpanded: boolean;
  feesSectionRef?: React.MutableRefObject<HTMLDivElement | null>;
  onExpand: () => void;
};

const PropertyUnit: FC<Props> = ({
  unit,
  isMultiUnit,
  error,
  touched,
  isExpanded,
  index,
  feesSectionRef,
  onExpand,
}) => {
  const { validateField, setFieldValue } = useFormikContext<PropertyValues>();
  const isUnitError = error && !isEmpty(error) && touched;
  const labelValidText = isMultiUnit ? 'Unit / Apt. number' : 'Unit number';
  const errorLabel = (
    <div>
      <div className='text-xs font-bold mb-2 text-trueGray'>
        {labelValidText} <span className='text-error'>Missing Information</span>
      </div>
    </div>
  );

  const setFieldValueWithOnlyFieldValidation = async (fieldName: string, value: any) => {
    await setFieldValue(fieldName, value, false);
    validateField(fieldName);
  };

  const showError = (fieldName: keyof PropertyUnitValues): boolean => {
    return Boolean(error?.[fieldName] && touched?.[fieldName]);
  };

  const getSelectProps = (
    name: UnitSelectFieldsName,
    options: SelectOption[],
    placeholder: string,
    index: number,
  ): SelectProps => {
    const fieldName = `details.units[${index}].${name}`;
    const unitFieldValue = unit[name as UnitSelectFieldsName];
    const unitFieldError = error?.[name as UnitSelectErrorsFieldsName];
    const unitFieldTouched = touched?.[name as UnitSelectTouchedFieldsName];

    return {
      name: fieldName,
      options,
      placeholder,
      value: unitFieldValue,
      invalid: Boolean(unitFieldError && unitFieldTouched),
      onChange: async (value) => {
        await setFieldValueWithOnlyFieldValidation(fieldName, value);
      },
    };
  };

  const getInputProps = (name: UnitInputFieldsName, placeholder: string, index: number): InputProps => {
    const fieldName = `details.units[${index}].${name}`;
    const unitFieldValue = unit[name];
    const unitFieldError = error?.[name as UnitInputErrorsFieldsName];
    const unitFieldTouched = touched?.[name as UnitInputTouchedFieldsName];

    return {
      value: unitFieldValue,
      name: fieldName,
      placeholder,
      invalid: Boolean(unitFieldError && unitFieldTouched),
      onChange: async (e) => {
        await setFieldValueWithOnlyFieldValidation(fieldName, e.target.value);
      },
    };
  };

  const unitLabel = isUnitError ? errorLabel : isMultiUnit ? 'Unit / Apt. number' : 'Unit number';

  // const handleDeleteUnitItem = async () => {
  //   setExpandedIds(expandedIds.filter((expandId) => expandId !== index));
  //   const deletedUnit = values.details.units[index];
  //   if (id && deletedUnit.id) {
  //     try {
  //       await deletePropertyUnit({ estateId: id, id: deletedUnit?.id });
  //       setFieldValue(
  //         'details.units',
  //         JSON.parse(JSON.stringify(values.details.units.filter((unit, i) => i !== index))),
  //       );
  //       notification.success('Unit was deleted');
  //     } catch (e) {
  //       notification.error('Something went wrong');
  //     }
  //   } else {
  //     setFieldValue('details.units', JSON.parse(JSON.stringify(values.details.units.filter((unit, i) => i !== index))));
  //   }
  // };

  return (
    <div key={unit.id} className='flex flex-col gap-9'>
      <div
        className={`flex px-5 py-2 rounded-lg flex-col md:flex-row gap-[14px] bg-[#FAFAFA] border box-border relative ${
          !isExpanded && isMultiUnit && isUnitError ? 'border-error' : 'border-[#FAFAFA]'
        }`}
      >
        <div className={`flex flex-col ${isExpanded ? '' : isMultiUnit ? 'w-full' : 'w-full'} `}>
          <div className='mb-4 flex flex-col lg:flex-row w-full'>
            <div className={clsx('w-full', !isExpanded && isMultiUnit && 'max-w-[400px] mr-0 md:mr-2')}>
              <Label label={unitLabel}>
                <Input {...getInputProps(`unitNumber`, 'Unit number', index)} />
              </Label>
              {!!touched?.unitNumber && !!error?.unitNumber && (
                <Label label={<span className='text-error'>{error?.unitNumber}</span>} />
              )}
            </div>

            {isMultiUnit && !isExpanded && (
              <div className='flex items-center justify-between min-w-[250px] max-w-[289px] gap-2'>
                <ShortIconInfo unit={unit} error={error} touched={touched} />
              </div>
            )}
          </div>

          <div className={`flex flex-1 w-full flex-col gap-5 ${isExpanded ? '' : isMultiUnit ? 'hidden' : ''}`}>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
              <div className='w-full'>
                <Label label={'Bedrooms'}>
                  <Select {...getSelectProps('beds', bedroomsOptions, 'Bedrooms', index)} />
                </Label>
              </div>

              <div className='w-full'>
                <Label label={'Bathrooms'}>
                  <Select {...getSelectProps('baths', bathsOptions, 'Bathrooms', index)} />
                </Label>
              </div>

              <div className='w-full'>
                <Label label={'Sq. Ft.'}>
                  <Input
                    {...getInputProps(`squareFeet`, 'Sq. Ft.', index)}
                    onBlur={async (value) => {
                      if (parseInt(value.toString()) > 9999) {
                        await setFieldValueWithOnlyFieldValidation(`details.units[${index}].squareFeet`, 9999);
                      }
                    }}
                    onChange={(e) => {
                      stringAsFloatNumber(
                        e,
                        `details.units[${index}].squareFeet`,
                        setFieldValueWithOnlyFieldValidation,
                      );
                    }}
                  />
                </Label>
              </div>
            </div>

            <div className={`${isExpanded ? '' : isMultiUnit ? 'hidden' : ''}`}>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                <div className='w-full'>
                  <Label label={'Lease term'}>
                    <Select {...getSelectProps('leaseDuration', leaseTermOptions, 'Months', index)} />
                  </Label>
                </div>
                <div className='w-full'>
                  <Label label={'Available on'}>
                    <CustomDatePicker
                      minDate={new Date()}
                      name={`details.units[${index}].availableOn`}
                      value={unit.availableOn}
                      onChange={async (date) => {
                        await setFieldValueWithOnlyFieldValidation(
                          `details.units[${index}].availableOn`,
                          date?.toDateString(),
                        );
                      }}
                      invalid={Boolean(error?.availableOn && touched?.availableOn)}
                    />
                  </Label>
                </div>
                <div className='w-full'>
                  <Label label={'Rent'}>
                    <Input
                      {...getInputProps(`rent`, '$/mo', index)}
                      onChange={(e) => {
                        stringAsFloatNumber(e, `details.units[${index}].rent`, setFieldValueWithOnlyFieldValidation);
                        //TODO: MAYBE ADD AUTOCOMPLETE
                        // if(!values.details.units[index]?.lastMonth){
                        //   stringAsFloatNumber(e, `details.units[${index}].lastMonth`, setFieldValue)
                        // }
                        // if(!values.details.units[index]?.firstMonth){
                        //   stringAsFloatNumber(e, `details.units[${index}].firstMonth`, setFieldValue)
                        // }
                      }}
                    />
                  </Label>
                </div>
                <div className='w-full'>
                  <Label label={'Deposit'}>
                    <Input
                      {...getInputProps(`deposit`, 'Deposit', index)}
                      onBlur={async (value) => {
                        if (parseInt(value.toString()) > 99999) {
                          await setFieldValueWithOnlyFieldValidation(`details.units[${index}].deposit`, 99999);
                        }
                      }}
                      onChange={(e) =>
                        stringAsFloatNumber(e, `details.units[${index}].deposit`, setFieldValueWithOnlyFieldValidation)
                      }
                    />
                  </Label>
                </div>
              </div>
              <div className='w-full mt-3'>
                {showError('rent') && <p className='text-sm text-error py-1'>{error?.rent}</p>}
              </div>
            </div>

            <Label label={'Listing description'}>
              <RichTextEditor
                value={unit.description}
                placeholder={'Listing description'}
                onChange={async (e) => {
                  await setFieldValueWithOnlyFieldValidation(`details.units[${index}].description`, e);
                }}
              />
            </Label>
            <Divider />
            <FormDescription title={'Move-In Fees'} />
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4' ref={feesSectionRef}>
              <div className='w-full'>
                <Label label={'First Month Rent'}>
                  <div className='flex gap-2'>
                    {/* <Checkbox
                     name={'firstMonth.checked'}
                     value={values.details.units[index]?.firstMonth?.checked}
                     checked={values.details.units[index]?.firstMonth?.checked}
                     onChange={() => {
                     setFieldValue(
                     `details.units[${index}].firstMonth.checked`,
                     !values.details.units[index].firstMonth?.checked,
                     ).then(() => {
                     if (!values.details.units[index]?.firstMonth?.checked) {
                     setFieldValue(`details.units[${index}].firstMonth.price`, values.details.units[index].rent);
                     } else {
                     setFieldValue(`details.units[${index}].firstMonth.price`, '');
                     }
                     })
                     }}
                     /> */}
                    <div className='w-full'>
                      <Input
                        {...getInputProps(`firstMonth`, '$', index)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          stringAsFloatNumber(
                            e,
                            `details.units[${index}].firstMonth`,
                            setFieldValueWithOnlyFieldValidation,
                          )
                        }
                      />
                      {showError('firstMonth') && <p className='text-sm text-error py-1'>{error?.firstMonth}</p>}
                    </div>
                  </div>
                </Label>
              </div>
              <div className='w-full'>
                <Label label={'Last Month Rent'}>
                  <div className='flex gap-2'>
                    {/* <Checkbox
                     name={'checkedLastMonth.checked'}
                     value={values.details.units[index]?.lastMonth?.checked}
                     checked={values.details.units[index]?.lastMonth?.checked}
                     onChange={() => {
                     setFieldValue(
                     `details.units[${index}].lastMonth.checked`,
                     !values.details.units[index]?.lastMonth?.checked,
                     ).then(() => {
                     if (!values.details.units[index]?.lastMonth?.checked) {
                     setFieldValue(`details.units[${index}].lastMonth.price`, values.details.units[index].rent);
                     } else {
                     setFieldValue(`details.units[${index}].lastMonth.price`, '');
                     }
                     });
                     }}
                     /> */}
                    <div className='w-full'>
                      <Input
                        {...getInputProps(`lastMonth`, '$', index)}
                        onChange={(e) =>
                          stringAsFloatNumber(
                            e,
                            `details.units[${index}].lastMonth`,
                            setFieldValueWithOnlyFieldValidation,
                          )
                        }
                      />
                      {showError('lastMonth') && <p className='text-sm text-error py-1'>{error?.lastMonth}</p>}
                    </div>
                  </div>
                </Label>
              </div>
            </div>

            <div className='flex flex-col'>
              <Label label={'Other Fees'}>
                <div className='flex flex-col gap-2'>
                  {unit.otherFeestable?.map((fee, feeIndex) => (
                    <OtherFeesTable
                      key={feeIndex}
                      index={feeIndex}
                      unitIndex={index}
                      setFieldValue={setFieldValue}
                      otherFeestable={fee}
                      handleDelete={async () => {
                        await setFieldValueWithOnlyFieldValidation(
                          `details.units[${index}].otherFeestable`,
                          JSON.parse(JSON.stringify(unit.otherFeestable.filter((fees, i) => i !== feeIndex))),
                        );
                      }}
                    />
                  ))}
                </div>
              </Label>
            </div>
            <div className='add-unit flex justify-end'>
              <Button
                label={'Add other fee'}
                theme={'black-outline'}
                stylesConfig={{ width: 175 }}
                onClick={() =>
                  setFieldValueWithOnlyFieldValidation(
                    `details.units.[${index}].otherFeestable`,
                    JSON.parse(JSON.stringify([...unit.otherFeestable, defaultOtherFeeValue])),
                  )
                }
              />
            </div>

            {isMultiUnit && <PropertyUnitAmenities unit={unit} unitIndex={index} />}
          </div>
        </div>

        {isMultiUnit && (
          <div className='p-1 cursor-pointer absolute right-4 top-2' onClick={onExpand}>
            <Icon name={!isExpanded ? 'arrow-down' : 'arrow-up'} color={'black'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyUnit;
