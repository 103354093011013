import { getEstatesSubscription } from 'services/api/estates-api.service';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';

export default function useEstateSubscription(estateId?: string) {
  return useQuery({
    queryKey: [QueryKey.Estates, 'subscription'],
    queryFn: () => {
      if (!estateId) return;
      return getEstatesSubscription(estateId);
    },
    enabled: !!estateId,
  });
}
