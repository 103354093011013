import { AdminFeedbacksFilters } from "components/organisms/Filters/AdminFeedbacksFilters/AdminFeedbacksFilters";
import { AdminFeedbacksList } from "./AdminFeedbacksList";

const AdminFeedbacks = () => {
  return (
    <div className='w-full overflow-x-auto shadow-white-xl px-4 py-4 md:px-[34px] md:py-[28px]'>
      <div className='flex gap-1 justify-between items-center mb-2 md:mb-9'>
        <div className='title text-xl font-bold'>Feedbacks</div>
        <div className='flex justify-end gap-2'>
          <AdminFeedbacksFilters />
        </div>
      </div>
      <AdminFeedbacksList />
    </div>
  );
};
export { AdminFeedbacks };
