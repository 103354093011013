import heic2any from 'heic2any';

const heicFileTypes = ['image/heic', 'image/heif'];

const heicToJpeg = async <T extends File>(file: T) => {
  const conversionResult = await heic2any({
    blob: file,
    toType: 'image/jpeg',
    quality: 1,
  });
  return new File([conversionResult as Blob], `${file.name.substring(0, '.heic'.length)}.jpeg`, { type: 'image/jpeg' });
};

const formatFiles = async <T extends File>(files: T[]) => {
  const promises = files.map(async (file) => {
    if (heicFileTypes.includes(file.type)) {
      return await heicToJpeg(file);
    } else {
      return file;
    }
  });
  const result = await Promise.all(promises);
  return result;
};

export { heicToJpeg, formatFiles };
