import { Checkbox } from 'legacy-components/fields/checkbox/checkbox';
import React, { FC } from 'react';
import { PropertyAmenitiesDto } from 'common/types/services/api/property/property-amenities-dto.type';
import { PropertyAmenity } from './property-detail';
import { PropertyAmenityDto } from 'common/types/services/api/property/property-amenity-dto.type';

type Props = {
  amenities: PropertyAmenitiesDto[];
  onChange: (amenity: PropertyAmenityDto) => void;
  values: PropertyAmenity;
  checkboxNameFn?: (amenityId: string) => string;
};

const Amenities: FC<Props> = ({ values, amenities, onChange, checkboxNameFn }) => {
  return (
    <div className='flex flex-col gap-9'>
      {amenities.map((item) => (
        <div key={item.id}>
          <div className='amenity-title text-base font-bold mb-2'>{item.name}</div>
          <div className='amenities grid grid-cols-2 lg:grid-cols-4 gap-4 '>
            {item.amenities.map((amenity) => (
              <Checkbox
                key={amenity.id}
                name={checkboxNameFn ? checkboxNameFn(amenity?.id) : amenity.name}
                value={values[amenity.id]}
                checked={values[amenity.id]}
                onChange={() => onChange(amenity)}
                label={amenity.name}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Amenities;
