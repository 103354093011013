import React from 'react';
import { Button } from 'legacy-components/componets';
import { AuthRole } from 'common/enums/enums';
import { AuthButtonsGroup, IAuthButtonsGroupProps } from '../auth-social-buttons-group/auth-buttons-group';
import { ButtonProps } from 'common/types/types';
import { NavLink, useNavigate } from 'react-router-dom';

const SelectAuthFlow = () => {
  const [authRole, setAuthRole] = React.useState<AuthRole>(AuthRole.Renter);
  const handleSetRenterFlow = () => setAuthRole(AuthRole.Renter);
  const handleSetLandlordFlow = () => setAuthRole(AuthRole.Landlord);
  const navigate = useNavigate();

  const handleGoogleClick = () => {};

  const handleFacebookClick = () => {};

  const handleAppleClick = () => {};

  const handleContinueWithEmailClick = () => {
    navigate(`/auth/sign-up/${authRole.toLocaleLowerCase()}`);
  };

  const authSocialButtonsProps: IAuthButtonsGroupProps = {
    onGoogleClick: handleGoogleClick,
    onFacebookClick: handleFacebookClick,
    onAppleClick: handleAppleClick,
    onEmailClick: handleContinueWithEmailClick,
  };

  const getButtonProps = (label: string, callback: () => void): ButtonProps => {
    return {
      label,
      onClick: callback,
      stylesConfig: { borderRadius: 8, width: 170, height: 44 },
    };
  };

  return (
    <div className='select-auth-flow w-[350px] mt-32 flex flex-col items-center mx-auto gap-9'>
      <div className='title text-primary font-bold text-2xl mb-9'>Register</div>
      <div>
        <p className='text-sm font-medium mb-3'>What best describes you?</p>
        <div className='buttons flex gap-3'>
          <Button
            {...getButtonProps('I want to rent', handleSetRenterFlow)}
            theme={authRole === AuthRole.Renter ? 'primary-outline' : 'black-outline'}
          />
          <Button
            {...getButtonProps('I want to list', handleSetLandlordFlow)}
            theme={authRole === AuthRole.Landlord ? 'primary-outline' : 'black-outline'}
          />
        </div>
      </div>
      <AuthButtonsGroup {...authSocialButtonsProps} />
      <div className='sign-in-link text-xs mb-3'>
        <span className='font-bold text-trueGray'>Already have an account?</span>
        <span>&nbsp;</span>
        <NavLink to={'/auth/sign-in'} className='text-primary'>
          Log in
        </NavLink>
      </div>
    </div>
  );
};

export { SelectAuthFlow };
