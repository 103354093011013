import { FC } from 'react';
import { EstateUploadedImageMainButton } from './EstateUploadedImageMainButton';
import { EstateUploadedImageDeleteButton } from './EstateUploadedImageDeleteButton';
import { EstateUploadedImageShowMoreLayer } from './EstateUploadedImageShowMoreLayer';

type Props = {
  src: string;
  onImageClick?: () => void;
  onShowMoreLayerClick?: () => void;
  onMainBtnClick?: () => void;
  onDeleteBtnClick: () => void;
  numberOtherImages?: number;
  isShowMore?: boolean;
  isDefault?: boolean;
  isError?: boolean;
  imgSize?: {
    width: number | string;
    height: number | string;
  };
  dataErrorKey?: string;
};

const EstateUploadedImage: FC<Props> = ({
  src,
  onImageClick,
  onShowMoreLayerClick,
  onMainBtnClick,
  onDeleteBtnClick,
  imgSize,
  isShowMore,
  isDefault,
  isError,
  numberOtherImages,
  dataErrorKey,
}) => {
  return (
    <div
      className={`group w-20 h-20 relative cursor-pointer ${isError ? 'border !border-error rounded-lg' : ''}`}
      onClick={onImageClick}
      style={{
        width: imgSize?.width,
        height: imgSize?.height,
      }}
      data-error-key={dataErrorKey}
    >
      <img src={src} alt='estate' className='w-full h-full object-cover rounded-lg' />
      <EstateUploadedImageMainButton onClick={onMainBtnClick} isDefault={Boolean(isDefault)} />
      {isShowMore ? (
        <EstateUploadedImageShowMoreLayer onClick={onShowMoreLayerClick} numberOtherImages={numberOtherImages} />
      ) : (
        <EstateUploadedImageDeleteButton onClick={onDeleteBtnClick} />
      )}
    </div>
  );
};

export { EstateUploadedImage };
