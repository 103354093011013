import { useNavigate } from 'react-router-dom';
import { Button, Icon } from 'legacy-components/componets';
import { Alert } from 'antd';

export const RenterIncompleteProfileActionAlert = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/i-rent/profile/info');
    window.scrollTo(0, 0);
  };

  return (
    <Alert
      message={<span className='font-bold'>Additional information required</span>}
      description={
        <div>
          You are unable to make an offer or schedule a tour because your profile is incomplete.
          <br />
          To ensure a smooth and secure experience,
          <span className='font-bold'> please fill out your profile with all the required information</span>
          <div className='mt-2'> 
            <Button
              onClick={handleClick}
              startIcon={<Icon name={'faEye'} />}
              label={'Visit Profile'}
              theme={'primary-outline'}
            />
          </div>
        </div>
      }
      type='warning'
      showIcon
      icon={<Icon name={'danger'} height={24} width={24} />}
    />
  );
};
