import { Table } from 'legacy-components/componets';
import { ToursTableRow } from './common/types/tours-table-row';
import { TablePaginationProps } from 'legacy-components/ui/table/table-pagination';
import { styleConfig } from './config';
import { ToursTableAccessor } from './common/enum/tour-table-accessor';
import { TourDateCell } from './components/TourDateCell';
import { TourPropertyCell } from './components/TourPropertyCell';
import { TourActionsMenuCell } from './components/TourActionsMenuCell';
import { SetQuery } from 'use-query-params';
import { TableColumn } from 'common/types/components/ui/table/table-props.type';
import { SortOrder } from 'common/types/sort-order';
import { UpcomingToursQueryParamConfig } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/upcoming-tours';
import { TourStatusCell } from './components/TourStatusCell';

export type UpcomingToursTableProps = {
  data: ToursTableRow[];
  isLoading: boolean;
  paginationProps: TablePaginationProps;
  order: SortOrder;
  setQuery: SetQuery<UpcomingToursQueryParamConfig>;
};

const UpcomingToursTable = ({ data, isLoading, paginationProps, order, setQuery }: UpcomingToursTableProps) => {
  const columns: TableColumn<ToursTableRow>[] = [
    {
      Header: 'Date',
      sorter: true,
      sortOrder: order,
      onSort: (sortOrder) => setQuery((prev) => ({ ...prev, page: 1, order: sortOrder })),
      accessor: ToursTableAccessor.Date,
      Cell: TourDateCell,
      width: '20%',
      minWidth: 150,
    },
    {
      Header: 'Property',
      accessor: ToursTableAccessor.Property,
      Cell: TourPropertyCell,
      width: '70%',
      minWidth: 300,
    },
    {
      Header: 'Status',
      accessor: ToursTableAccessor.Status,
      Cell: TourStatusCell,
      minWidth: 120,
    },
    {
      Header: 'Actions',
      accessor: ToursTableAccessor.Actions,
      Cell: TourActionsMenuCell,
      width: '10%',
      minWidth: 100,
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      isLoading={isLoading}
      styleConfig={styleConfig}
      paginationProps={paginationProps}
      placeholder={{
        title: `Currently, you don't have any upcoming tours scheduled. Don't worry, you can still explore our listings and schedule a self tour to find your perfect rental!`,
        height: 400,
      }}
    />
  );
};

export { UpcomingToursTable };
