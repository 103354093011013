/* tslint:disable */
/* eslint-disable */
/**
 * Axxelist API
 * Axxelist API for modular monolith .NET application.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ContactUsFormDto
 */
export interface ContactUsFormDto {
    /**
     * 
     * @type {string}
     * @memberof ContactUsFormDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsFormDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsFormDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactUsFormDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactUsFormDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactUsFormDto
     */
    'profile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactUsFormDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactUsFormDto
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactUsFormDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsFormDto
     */
    'status'?: ContactUsFormDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactUsFormDto
     */
    'title'?: string | null;
}

export const ContactUsFormDtoStatusEnum = {
    New: 'New',
    InProgress: 'InProgress',
    Done: 'Done'
} as const;

export type ContactUsFormDtoStatusEnum = typeof ContactUsFormDtoStatusEnum[keyof typeof ContactUsFormDtoStatusEnum];


