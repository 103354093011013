import { formatPriceValue } from 'helpers/string.helpers';
import { FC, useMemo } from 'react';
import { EstateCardPriceProps } from './types';

const mapRangePrice = (minValue: number, maxValue: number) => {
  return minValue === maxValue
    ? formatPriceValue(minValue)
    : `${formatPriceValue(minValue)}-${formatPriceValue(maxValue)}`;
};

const EstateCardPrice: FC<EstateCardPriceProps> = ({ price, priceMax, priceMin }) => {

  const formattedPrice = useMemo(() => {
    const isString = typeof price === 'string';
    const isNumber = typeof price === 'number';
    const isValidStringNumber = Boolean(isString && Number(price));
    const isPriceRangeExist = priceMax && priceMin;

    if (isNumber || isValidStringNumber) {
      return formatPriceValue(Number(price));
    }

    if (isPriceRangeExist) {
      return mapRangePrice(priceMin, priceMax);
    }

    return price ?? 0;
  }, [price, priceMax, priceMin]);

  return (
    <div className='price flex justify-start items-center'>
      <p className='text-primaryDark text-lg font-bold'>${formattedPrice}</p>
      <p className={'text-sm font-normal text-trueGray ml-[5px]'}>/mo</p>
    </div>
  );
};

export { EstateCardPrice };
