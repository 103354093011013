const styles = {
  container: 'hidden md:flex justify-between items-center grow',
  dropdownMenu: `
    bg-white 
    w-[277px] 
    text-sm 
    text-black 
    rounded-lg 
    shadow-blue 
    cursor-pointer 
    overflow-hidden 
    absolute z-10 
    top-14
    py-6
    `,
  dropdownItem: 'flex items-center block h-16 py-4 px-6 divide-y hover:bg-secondary',
  navItem: 'flex items-center text-sm font-bold cursor-pointer',
};

export default styles;
