import { TourStatusDto } from 'common/enums/enums';

export const mapTourStatus: Record<TourStatusDto, string> = {
  [TourStatusDto.Canceled]: 'Canceled',
  [TourStatusDto.Finished]: 'Finished',
  [TourStatusDto.Skipped]: 'Skipped',
  [TourStatusDto.InProgress]: 'In Progress',
  [TourStatusDto.Reserved]: 'Reserved',
  [TourStatusDto.Scheduled]: 'Scheduled',
  [TourStatusDto.Expired]: 'Expired',
  [TourStatusDto.Rejected]: 'Canceled by landlord',
  [TourStatusDto.PendingPayment]: 'Pending Payment',
};
