import React from 'react';
import { CellProps } from 'react-table';
import { AccessAccountsTableRow } from 'common/types/types';
import { AccessAccountStatus } from 'common/enums/enums';

const AccessAccountsStatusCell = ({ value }: CellProps<AccessAccountsTableRow, AccessAccountStatus>) => {
  return <div className='text-base font-medium capitalize'>{value}</div>;
};

export { AccessAccountsStatusCell };
