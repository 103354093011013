import { Filters } from 'components/molecules/Filters/Filters';
import { AdminFeedbacksFiltersForm } from './AdminFeedbacksFiltersForm';
import {
  QueryParamConfig,
  StringParam,
  createEnumDelimitedArrayParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useMemo } from 'react';
import { ContactUsFormDtoStatusEnum } from 'common/generated/types/contact-us-form-dto';

type QueryParamConfigType = {
  status: QueryParamConfig<`${ContactUsFormDtoStatusEnum}`[]>;
  title: QueryParamConfig<string>;
};

const defaultQueryParams = {
  status: [],
};

const AdminFeedbacksFilters = () => {
  const queryParamConfig = useMemo<QueryParamConfigType>(
    () => ({
      title: withDefault(StringParam, ''),
      status: withDefault(
        createEnumDelimitedArrayParam(
          Object.values(ContactUsFormDtoStatusEnum) as `${ContactUsFormDtoStatusEnum}`[],
          ',',
        ),
        [],
      ),
    }),
    [],
  );

  const [query, setQuery] = useQueryParams(queryParamConfig, {
    updateType: 'replace',
  });

  const handleSubmit = (values: any) => {
    setQuery(values);
  };

  const handleReset = () => {
    setQuery(defaultQueryParams);
  };

  return (
    <Filters
      initialValues={defaultQueryParams}
      filters={query}
      onSubmit={handleSubmit}
      onReset={handleReset}
      FiltersMenuForm={AdminFeedbacksFiltersForm}
    />
  );
};

export { AdminFeedbacksFilters };
