import { wrapRoutesWithAuthGuard } from './utils';
import { InvestorModalWindow } from 'layouts/investor-layout/investor-layout';
import { PasswordModalWindow } from 'legacy-components/local-password-modal';
import { AuthRole } from 'common/enums/enums';
import { AuthGuard } from 'guard/guard';
import { AuthLayout, MainLayout, PublicLayout, RenterLayout, LandlordLayout } from 'layouts/layouts';
import NotFound from 'legacy-pages/not-found/not-found';
import { lazy } from 'react';
import { authRoutes } from './auth';
import { publicRoutes } from './public';
import { renterRoutes } from './renter';
import { landlordRoutes } from './landlord';
import { adminRoutes } from './admin';

const ConfirmEmail = lazy(() => import('legacy-pages/auth/confirm-email/confirm-email'));
const RenterPaymentPage = lazy(() => import('legacy-pages/RenterPaymentPage/RenterPaymentPage'));

const reactAppRoutes = [
  {
    path: 'strict-auth',
    element: <InvestorModalWindow />,
  },
  {
    path: 'password-auth',
    element: <PasswordModalWindow />,
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: authRoutes,
  },
  //TODO: FIX NESTED '/' LAYOUTS
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <PublicLayout />,
        children: publicRoutes,
      },
      {
        path: 'i-rent',
        element: (
          <AuthGuard allowedRoles={[AuthRole.Renter]}>
            <RenterLayout />
          </AuthGuard>
        ),
        children: renterRoutes,
      },
      {
        path: 'i-landlord',
        element: (
          <AuthGuard allowedRoles={[AuthRole.Landlord]}>
            <LandlordLayout />
          </AuthGuard>
        ),
        children: landlordRoutes,
      },
      {
        path: 'admin',
        element: (
          <AuthGuard allowedRoles={[AuthRole.SuperUser]}>
            <LandlordLayout />
          </AuthGuard>
        ),
        children: adminRoutes,
      },
    ],
  },
  // TODO: REPLACE ROUTE TO AUTH ROUTER
  {
    path: 'confirm-email',
    element: <AuthLayout />,
    children: [{ path: '', element: <ConfirmEmail /> }],
  },
  // TODO: REPLACE ROUTE TO RENTER ROUTER
  {
    path: 'offer-payment/:id',
    element: (
      <AuthGuard allowedRoles={[AuthRole.Renter]}>
        <RenterPaymentPage />
      </AuthGuard>
    ),
  },
  { path: 'not-found', element: <NotFound /> },
  { path: '*', element: <NotFound /> },
];

// TODO: SAVE FOR LATER
// {
//   path: 'sign-lease/:id',
//   element: (
//     <AuthGuard allowedRoles={[AuthRole.Renter]}>
//       <SignLeaseLayout />
//     </AuthGuard>
//   ),
//   children: [{ path: '', element: <SignLeasePage /> }],
// },

export default wrapRoutesWithAuthGuard(reactAppRoutes);
