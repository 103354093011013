import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getRescheduleTourPayment } from 'services/api/tours-api.service';
import { RescheduleTourBillingPaymentRequestDto } from 'common/types/services/api/tours/tour-billing-request-dto.type';
import { RescheduleTourPaymentIntentDto } from 'common/types/services/api/tours/tour-payment-intent-dto.type';
import { QueryKey } from 'common/enums/query-key';

export default function useRescheduleTourPayment(
  params: RescheduleTourBillingPaymentRequestDto,
  options?: Omit<
    UndefinedInitialDataOptions<
      RescheduleTourPaymentIntentDto,
      Error,
      RescheduleTourPaymentIntentDto,
      (string | RescheduleTourBillingPaymentRequestDto)[]
    >,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Tours, 'reschedule', 'payment', params],
    queryFn: () => getRescheduleTourPayment(params),
    ...options,
  });
}
