import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getSavedSearchResult } from 'services/api/saved-search-api.service';
import {
  GetSavedSearchResultRequestDto,
  SavedSearchEstateDto,
} from 'common/types/services/api/user/user-saved-search.type';
import { QueryKey } from 'common/enums/query-key';

export default function useSavedSearchResult(
  params: GetSavedSearchResultRequestDto,
  options?: Omit<
    UndefinedInitialDataOptions<
      SavedSearchEstateDto,
      Error,
      SavedSearchEstateDto,
      (string | GetSavedSearchResultRequestDto)[]
    >,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.SavedSearch, 'result', params],
    queryFn: () => getSavedSearchResult(params),
    ...options,
  });
}
