import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { createPropertyAddress } from 'services/api/property-api.service';
import { QueryKey } from 'common/enums/query-key';
import { CreateRentEstateRequest } from 'common/generated/types/create-rent-estate-request';

export default function useCreatePropertyAddress(
  options?: Omit<UseMutationOptions<string, Error, CreateRentEstateRequest, unknown>, 'mutationFn'>,
) {
  return useMutation({
    mutationFn: createPropertyAddress,
    mutationKey: [QueryKey.PropertyAddress],
    ...options,
  });
}
