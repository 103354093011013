import AddOutlined from '@mui/icons-material/AddOutlined';

type PlaceholderProps = { 
    text?: string, 
    className?: string
};

function Placeholder({ text, className }: PlaceholderProps) {
    const style = { 
        //backgroundColor: 'rgba(252,252,252,.8)'
        backgroundColor: 'transparent' 
    };

    const classCollection: string[] = [
        'flex', 
        'items-center',
        'justify-center',
        //'border-dotted',
        //'border-2',
        //'border-zinc-200',
        //'rounded-md'        
    ];

    return (
        <div className={classCollection.join(' ').trim().concat(' ').concat(className ?? '')}
            style={style}
        >
            { 
                text != null
                    ? (<span>{text}</span>)
                    : (<p className="text-sm text-zinc-300">
                        Press &nbsp; <AddOutlined fontSize="small" /> &nbsp; button to add more
                      </p>)
            }
        </div>
    );
}

export default Placeholder;