import { EstateListDtoStatusEnum } from "common/generated/types/estate-list-dto";

export type EstateStatusProps = {
  estateStatus: EstateListDtoStatusEnum;
  size?: 's' | 'm' | 'l';
};

export const estateStatusMapToText: Record<EstateListDtoStatusEnum, string> = {
  // Available -> Approved 
  [EstateListDtoStatusEnum.Available]: 'Approved',
  [EstateListDtoStatusEnum.Incomplete]: 'Incomplete',
  [EstateListDtoStatusEnum.Occupied]: 'Rented',
  // PendingApproval -> New 
  [EstateListDtoStatusEnum.PendingApproval]: 'New',
  [EstateListDtoStatusEnum.Unavailable]: 'Unavailable',
  [EstateListDtoStatusEnum.Rejected]: 'Rejected',
  [EstateListDtoStatusEnum.PendingPayment]: 'Pending Payment',
};