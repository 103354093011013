import * as Yup from 'yup';
import { getDate, getDayjs } from 'helpers/date';
import { passwordValidation } from './password.validation';
import { emailValidation } from './email.validation';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import { ENV } from 'common/enums/enums';

export const alphabetValidationForSignUp = (name: string) =>
  Yup.string()
    .required(`${name} is required`)
    .matches(/^[A-Za-z\s]*$/, 'Field can not be numeric or contain special characters')
    .max(25);

export const signUpValidationSchema = Yup.object({
  firstName: alphabetValidationForSignUp('First Name'),
  lastName: alphabetValidationForSignUp('Last Name'),
  phone: Yup.string().required('Phone is required'),
  email: emailValidation,
  password: passwordValidation,
  dateOfBirth: Yup.date()
    .min(new Date(new Date().getFullYear() - 100, 0, 1), `Date can't be earlier than 100 years ago`)
    .nullable()
    .transform((curr, orig) => {
      return orig === '' && orig === 'Invalid Date' ? null : curr;
    })
    .typeError('Invalid date')
    .required('Date of birth is required')
    .max(getDayjs(getDate()).subtract(18, 'years'), 'Age must be at least 18 years old'),
  captchaToken: isNotEmptyString(ENV.REACT_APP_RECAPTCHA)
    ? Yup.string().required('Captcha is required')
    : Yup.string().notRequired(),
});
