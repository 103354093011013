import { FC } from 'react';
import { EstateCardLocationProps } from './types';

const EstateCardLocation: FC<EstateCardLocationProps> = ({ city, location }) => {
  return (
    <div className={'card-location flex-grow max-w-full'}>
      <h5 className={'text-black text-xl font-bold mb-0.5 text-ellipsis overflow-hidden max-w-full whitespace-nowrap'}>
        {city}
      </h5>
      <p className={'text-[#6C6C6C] font-normal text-sm truncate text-ellipsis'}>{location}</p>
    </div>
  );
};
export { EstateCardLocation };
