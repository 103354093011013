import React from 'react';
import { CellProps } from 'react-table';
import { SavedSearchActionsCell, SavedSearchTableRow } from '../SavedSearchTable.types';
import { Icon } from 'legacy-components/componets';

const SavedSearchActionsMenuCell: React.FC<CellProps<SavedSearchTableRow, SavedSearchActionsCell>> = ({ value }) => {
  const { onView, onDelete } = value;

  return (
    <div className='flex gap-4 items-center'>
      <div className='cursor-pointer' onClick={onView}>
        <Icon name={'faEye'} color={'#222222'} clickable />
      </div>
      <div onClick={onDelete}>
        <Icon name={'faTrash'} color={'#222222'} clickable />
      </div>
    </div>
  );
};

export { SavedSearchActionsMenuCell };
