import { ContactUsFormDtoStatusEnum } from 'common/generated/types/contact-us-form-dto';

export const styles = {
  [ContactUsFormDtoStatusEnum.New]: 'bg-[#F1FBFF] text-[#66CCFF]',
  [ContactUsFormDtoStatusEnum.InProgress]: 'bg-[#FFFBEB] text-[#F59E0B]',
  [ContactUsFormDtoStatusEnum.Done]: 'bg-[#F1FFF5] text-[#A0D911]',
};

export const commonStatusStyle = 'font-medium leading-tight px-[10px] py-[2px] rounded-3xl text-center max-w-[110px]';

export const textStyle = (size: 's' | 'm' | 'l') => {
  switch (size) {
    case 's':
      return 'text-xs';
    case 'm':
      return 'text-sm';
    case 'l':
      return 'text-base';
  }
};
