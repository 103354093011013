import { LoggedInfo, ProfileDropdown } from 'legacy-components/componets';
import { ILoggedInInfoProps } from './logged- info/logged- info';
import { AuthRole } from 'common/enums/enums';

export type ProfileInfoProps = {
  profile: ILoggedInInfoProps;
  roles: AuthRole[];
};

export const ProfileInfo = ({ profile, roles }: ProfileInfoProps) => {
  return (
    <ProfileDropdown authRoles={roles}>
      <LoggedInfo {...profile} />
    </ProfileDropdown>
  );
};
