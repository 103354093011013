export enum EstateCategoryDto {
  SingleFamilyHouse = 'SingleFamilyHouse',
  MultiFamilyHouse = 'MultiFamilyHouse',
  Apartment = 'Apartment',
  Condo = 'Condo',
  GuestsHouse = 'GuestsHouse',
  Cabin = 'Cabin',
  Barn = 'Barn',
  Farm = 'Farm',
  Mobile = 'Mobile',
  HouseBoat = 'HouseBoat',
  TinyHome = 'TinyHome',
  House = 'House',
  Townhouse = 'Townhouse'
}
