import { SelectOption } from 'common/types/types';
import { EstateCategoryDto, EstateStatusDto, EstateTypeDto } from 'common/enums/enums';

type ListingFilters = {
  status: string;
  category: string;
  type: string;
};

export const defaultFilters: ListingFilters = {
  status: '',
  category: '',
  type: '',
};

const availabilityLabelName: Record<EstateStatusDto, string> = {
  [EstateStatusDto.Available]: 'Approved',
  [EstateStatusDto.Incomplete]: 'Incomplete',
  [EstateStatusDto.PendingApproval]: 'New',
  [EstateStatusDto.Unavailable]: 'Unavailable',
  [EstateStatusDto.Occupied]: 'Rented',
  [EstateStatusDto.Rejected]: 'Rejected',
  [EstateStatusDto.PendingPayment]: 'Pending Payment',
};

const getAvailabilityOption = (value: EstateStatusDto): SelectOption => ({
  value,
  label: availabilityLabelName[value],
});

const statusOptions = [
  EstateStatusDto.PendingApproval,
  EstateStatusDto.Available,
  EstateStatusDto.Rejected,
  // EstateStatusDto.Incomplete,
  // EstateStatusDto.Unavailable,
  // EstateStatusDto.Occupied,
  // EstateStatusDto.PendingPayment,
]
  .map(getAvailabilityOption)
  .filter((item) => item.label);

const propertyLabelName: Partial<Record<EstateCategoryDto, string>> = {
  [EstateCategoryDto.Apartment]: 'Apartment',
  [EstateCategoryDto.Townhouse]: 'Townhouse',
  [EstateCategoryDto.Condo]: 'Condo',
  [EstateCategoryDto.House]: 'House',
};

const getPropertyOption = (value: EstateCategoryDto): SelectOption => ({
  value,
  label: propertyLabelName[value],
});

const categoryOptions = Object.values(EstateCategoryDto)
  .map(getPropertyOption)
  .filter((item) => item.label);

const typeLabelName: Partial<Record<EstateTypeDto, string>> = {
  [EstateTypeDto.SingleUnit]: 'Single Unit',
  [EstateTypeDto.MultiUnit]: 'Multi Unit',
};

const getTypeOption = (value: EstateTypeDto): SelectOption => ({
  value,
  label: typeLabelName[value],
});

const typeOptions = Object.values(EstateTypeDto)
  .map(getTypeOption)
  .filter((item) => item.label);

export const filtersOptions: Record<keyof Omit<ListingFilters, 'page'>, SelectOption[]> = {
  status: statusOptions,
  category: categoryOptions,
  type: typeOptions,
};
