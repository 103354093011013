export const cardListMock = [
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    slot: '2023-02-28T15:17:54.447Z',
    tourStatus: 'Reserved',
    estateStatus: 'ReadyForRent',
    location: 'string',
    price: 0,
    estateType: 'SingleFamilyHouse',
    beds: 0,
    bathrooms: 0,
    propertyArea: 0,
    thumbnailUrl: 'string',
  },
];

export const maxItemPerPage = 5;
