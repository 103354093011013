import { SubscriptionStatusType } from "common/enums/services/api/estates/estate-status-dto.enum";
import { IconSVGName } from "common/types/types";

export const styles: Record<SubscriptionStatusType, { container: string; label: string; icon: IconSVGName; iconColor?: string; }> = {
    [SubscriptionStatusType.Active]: {
        container: 'border-[#27AE60]',
        label: 'text-[#27AE60]',
        icon: 'circle-checked',
        iconColor: '#05A660'
    },
    [SubscriptionStatusType.PendingCanceling]: {
        container: 'border-[#F3C521]',
        label: 'text-[#F3C521]',
        icon: 'pendingPayment',
    },
    [SubscriptionStatusType.Inactive]: {
        container: 'border-[#FF385C]',
        label: 'text-[#FF385C]',
        icon: 'shape',
        iconColor: '#FF385C'
    }
};

export const generateStyles = (size: 'small' | 'medium' | 'large') => {
    let containerSize = 'p-0';
    let labelSize = 'text-[10px]';

    if (size === 'medium') {
        containerSize = 'p-1';
    } else if (size === 'large') {
        containerSize = 'p-2';
        labelSize = 'text-[16px]';
    }

    const styles = {
        container: `inline-flex bg-white items-center gap-3 align-middle border-solid border-2 rounded-lg ${containerSize} min-w-[120px] justify-center`,
        label: `font-semibold ${labelSize}`,
    };

    return styles;
};
