import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { getAvailabilityDays } from 'common/mappers/property';
import { UnitPropertyUpdateAccessSchedule } from 'common/types/services/api/property/property-update-access-schedule-dto.type';
import {
  updatePropertyScheduler,
  updateUnitPropertyScheduler,
} from 'services/api/property-api.service';
import {
  defaultTourAvailability
} from 'legacy-pages/landlord/properties/property-building-availability/config';
import dayjs from 'dayjs';

export default function useUpdatePropertyUnitsScheduler(
  options?: Omit<UseMutationOptions<void, Error, UnitPropertyUpdateAccessSchedule, unknown>, 'mutationFn'>,
) {
  return useMutation({
    mutationFn: async (data) => {
      const { estateId, units } = data;
      const sameAvailabilityRequest = {
        estateId: estateId,
        fromDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        toDate: '',
        dailyTimetables: getAvailabilityDays(defaultTourAvailability),
        unavailableDays: [],
        forAllUnits: false,
      };
      await updatePropertyScheduler(sameAvailabilityRequest);
      await Promise.all(
        units.map(async (unit) => {
          await updateUnitPropertyScheduler(unit);
        }),
      );
    },
    mutationKey: [QueryKey.PropertyAvailability],
    ...options,
  });
}
