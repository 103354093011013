import { PropertyUnitType } from 'common/enums/enums';
import * as Yup from 'yup';

const invalidAddressMsg = 'To proceed, please provide a valid address ( Property Number/St Name/City, State, Zip)'
const addressComponentsValidation = Yup.string().required(invalidAddressMsg)

export const propertyBasicValidationSchema = Yup.object({
  location: Yup.object({
    address: Yup.string().required('Please enter valid address'),
    street: addressComponentsValidation,
    city: addressComponentsValidation,
    zipcode: addressComponentsValidation,
    state: addressComponentsValidation,
  }),
  unitCount: Yup.number().when('type', {
    is: (type: PropertyUnitType) => type === PropertyUnitType.Multiple,
    then: (schema) =>
      schema
        .min(2, 'Minimum number of units is 2, or select a Single unit type')
        .max(99, 'Max unit count is 99')
        .required('Required field'),
    otherwise: (schema) => schema,
  }),
});
