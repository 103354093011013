import { NavLink } from 'react-router-dom';

export type Tab = {
  to: string;
  title: string;
};

type Props = {
  tabs: Array<Tab>;
};

const TabPanel = ({ tabs }: Props) => {
  return (
    <div className='text-sm font-medium text-center text-gray-500  mb-4'>
      <ul className='tab-panel-sm flex overflow-y-scroll -mb-px'>
        {tabs.map((tab) => (
          <li className='mr-2 flex-shrink-0' key={`${tab.title}-${tab.to}`}>
            <NavLink
              to={tab.to}
              className={({ isActive }) =>
                isActive
                  ? 'inline-block p-4 text-primaryDark border-b-2 border-cyan-400 rounded-t-lg active'
                  : 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
              }
            >
              {tab.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { TabPanel };
