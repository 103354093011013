import { Icon } from 'legacy-components/ui/icon/icon';
import { FC } from 'react';

type Props = {
  onClick: () => void;
};

const EstateUploadedImageDeleteButton: FC<Props> = ({ onClick }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    onClick();
  };

  return (
    <button
      onClick={handleClick}
      className='p-[4px] absolute rounded-full top-[6px] right-[6px] text-white bg-rose-500'
    >
      <Icon name={'faTrash'} className='cursor-pointer' height={12} width={12} />
    </button>
  );
};

export { EstateUploadedImageDeleteButton };
