import { AdminApiPath } from 'common/enums/services/api/paths/commission.enum.path';
import { axiosInstance } from './axiosInstance';
import {
  CommissionCreateDto,
  CommissionDto,
  CommissionEditRequest,
  CommissionItem,
  CommissionRequest,
} from 'common/types/services/api/commissions/commission-dto.types';
import qs from 'qs';

export const addNewCommission = (body: CommissionCreateDto): Promise<void> => {
  return axiosInstance.post(`${AdminApiPath.COMMISSION_ADMIN_PATH}`, body);
};

export const editCommission = (payload: CommissionEditRequest): Promise<void> => {
  return axiosInstance.put(`${AdminApiPath.COMMISSION_ADMIN_PATH}/${payload?.id}`, payload?.body);
};

export const getCommissionsList = (query: CommissionRequest): Promise<CommissionDto> => {
  const queryParams = {
    page: query.page ? query.page : undefined,
    perPage: query.perPage ? query.perPage : undefined,
    filters: {
      state: query.filters?.state ? query.filters?.state : undefined,
      city: query.filters?.city ? query.filters?.city : undefined,
    },
  };
  const queryString = qs.stringify(queryParams, { encode: false, arrayFormat: 'repeat' });
  return axiosInstance.get(`${AdminApiPath.COMMISSION_ADMIN_PATH}?${queryString}`);
};

export const deleteCommission = (id: string): Promise<void> => {
  return axiosInstance.delete(`${AdminApiPath.COMMISSION_ADMIN_PATH}/${id}`);
};

export const getDefaultCommission = (): Promise<CommissionItem> => {
  return axiosInstance.get(`${AdminApiPath.COMMISSION_ADMIN_PATH}/default`);
};
