enum PropertyStepperItems {
  PropertyBasics = 'propertyBasics',
  Plan = 'plan',
  PropertyDetail = 'propertyDetail',
  BuildingAccess = 'buildingAccess',
  BuildingAvailability = 'buildingAvailability',  
  // RentalSpecials = 'propertyRentalSpecials',
  // Lease = 'lease',
  Publish = 'publish',
}

enum PropertyUnitType {
  Single = 'single',
  Multiple = 'multiple',
}

enum PropertyUnitsCountType {
  OneToTen = 'oneToTen',
  ElevenToTwenty = 'elevenToTwenty',
  OverTwenty = 'overTwenty',
}

enum PropertyCategory {
  Apartment = 'Apartment',
  Townhouse = 'Townhouse',
  // MultiFamilyHouse = 'MultiFamilyHouse',
  // SingleFamilyHouse = 'SingleFamilyHouse',
  Condo = 'Condo',
  House = 'House',
  // GuestHouse = 'guestHouse',
  // Cabin = 'cabin',
  // Barn = 'barn',
  // Farm = 'farm',
  // Mobile = 'mobile',
  // HouseBoat = 'houseBoat',
  // TinyHome = 'tinyHome',
}

enum PropertyPersonType {
  Owner = 'owner',
  Agent = 'agent',
  Manager = 'manager',
}

enum PropertyPlan {
  Basic = 'Basic',
  Pro = 'Pro',
}

enum PropertyAccessKey {
  Lockbox = 'Lockbox',
  LeasingOffice = 'leasingOffice',
  DiscreetLocation = 'discreetLocation',
  QRCode = 'qrcode',
  ElectronicLock = 'electronicLock',
  OtherKey = 'otherKey',
}

enum PropertyDtoType {
  Single = 'SingleUnit',
  Multiple = 'MultiUnit',
}

enum PropertyDtoCategory {
  SingleFamilyHouse = 'SingleFamilyHouse',
  MultiFamilyHouse = 'MultiFamilyHouse',
  Apartment = 'Apartment',
  Condo = 'Condo',
  GuestHouse = 'GuestHouse',
  Cabin = 'Cabin',
  Barn = 'Barn',
  Farm = 'Farm',
  Mobile = 'Mobile',
  HouseBoat = 'HouseBoat',
  TinyHome = 'TinyHome',
  House = 'House',
  Townhouse = 'Townhouse'
}

enum PropertyDtoAccessKey {
  KeyInLockbox = 'KeyInLockbox',
  KeyAtLeasingOffice = 'KeyAtLeasingOffice',
  KeyInDiscreetLocation = 'KeyInDiscreetLocation',
  QRCodeInBuilding = 'QRCodeInBuilding',
  ElectronicLock = 'ElectronicLock',
  Other = 'Other',
}

export {
  PropertyStepperItems,
  PropertyUnitType,
  PropertyCategory,
  PropertyPersonType,
  PropertyPlan,
  PropertyAccessKey,
  PropertyUnitsCountType,
  PropertyDtoType,
  PropertyDtoCategory,
  PropertyDtoAccessKey,
};
