import { isElementVisible } from './is-element-visible';

// scroll to an element that is visible and is closest to the top of the page from the list of elements passed in the argument
export const scrollTopTopElement = (elements: HTMLElement[]) => {
  if (elements.length === 0) return;
  // Filter out elements that are not visible
  // If element is not visible, the first visible parent element will be used
  const visibleElements = elements.map((element) => {
    let visibleElement = element;
    while (!isElementVisible(visibleElement)) {
      const parentElement = visibleElement.parentElement;
      if (parentElement) {
        visibleElement = parentElement;
      }
    }
    return visibleElement;
  });
  const elementsWithTop = visibleElements.map((element) => {
    const rect = element.getBoundingClientRect();
    const top = window.scrollY + rect.top;
    return { element, top };
  });
  const sortedElementsByTop = [...elementsWithTop].sort((a, b) => a.top - b.top);
  const firstTopElement = sortedElementsByTop[0];
  const topToScroll = firstTopElement.top - window.innerHeight / 2;
  window.scrollTo({ behavior: 'smooth', top: topToScroll });
};
