import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';
import { SubscribeEmailNotificationDto } from 'common/types/services/api/notification/notifications-dto.type';
import { subscribeEmailNotification } from 'services/api/notification-api.service';

export default function useSubscribeEmailNotification(
  options?: UseMutationOptions<any, AxiosError<BackendError>, SubscribeEmailNotificationDto, unknown>,
) {
  return useMutation({
    mutationFn: subscribeEmailNotification,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
}
