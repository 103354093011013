import { CheckCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { colors } from 'colors';
import { StepperProps } from 'common/types/types';
import { useUserProfileValidation } from 'hooks/query';
import { Icon, Stepper } from 'legacy-components/componets';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const WarningIcon = ({ title }: { title: string }) => {
  return (
    <Tooltip
      title={title}
      color={colors.white}
      mouseEnterDelay={0}
      arrow={false}
      overlayInnerStyle={{ color: colors.danger }}
    >
      <button>
        <Icon name={'danger'} height={24} width={24} />
      </button>
    </Tooltip>
  );
};

const RenterProfileInfoStepper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, isLoading } = useUserProfileValidation();

  const stepList: StepperProps['steps'] = useMemo(() => {
    return [
      {
        id: 'personal',
        title: 'Personal',
        extra: isLoading ? null : data?.isProfileValid ? null : <WarningIcon title='Please, complete your profile' />,
      },
      {
        id: 'payment-details',
        title: 'Payment',
      },
      {
        id: 'verification',
        title: 'Verification',
        extra: isLoading ? null : data?.isIdentityValid ? <CheckCircleFilled className={'text-lime-300 text-[24px]'} /> : <WarningIcon title='Please, verify your identity' />,
      },
    ];
  }, [data, isLoading]);

  return (
    <Stepper
      className={'w-[180px]'}
      steps={stepList}
      activeItemId={location.pathname.split('/').slice(-1)[0]}
      onClick={(activeItemId) => {
        navigate(activeItemId);
      }}
    />
  );
};

export { RenterProfileInfoStepper };
