import { Select } from 'antd';
import { TourHistoryTourStatusDto } from 'common/enums/services/api/tours/tour-status-dto.enum';
import { FiltersMenuFormProps } from 'components/molecules/Filters/Filters';
import { FC } from 'react';
import { filterStatusTourHistoryLabel } from './RenterTourHistoryFiltersForm.utils';

const RenterTourHistoryFiltersForm: FC<FiltersMenuFormProps> = ({ values, setFieldValue, id }) => {
  const { tourStatuses } = values;

  const getSelectPopupContainer = (): HTMLElement => document.getElementById(id) as HTMLElement;

  return (
    <div className='flex flex-col gap-3 flex-1 w-full'>
      <div className='flex flex-col w-full'>
        <label className={'block mb-2 text-base font-semibold text-black'}>{'Status'}</label>
        <Select
          style={{
            maxWidth: '375px',
          }}
          allowClear
          mode='multiple'
          placeholder='Select statuses'
          getPopupContainer={getSelectPopupContainer}
          size='large'
          value={tourStatuses || null}
          onChange={(value: string) => {
            setFieldValue('tourStatuses', value);
          }}
          options={Object.values(TourHistoryTourStatusDto).map((v) => ({
            value: v,
            label: filterStatusTourHistoryLabel[v],
          }))}
        />
      </div>
    </div>
  );
};

export { RenterTourHistoryFiltersForm };
