import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { UserPersonaVerificationDto } from 'common/generated/types/user-persona-verification-dto';
import { getUserPersonaVerification, } from 'services/api/auth-api.service';

export default function useGetUserPersonaVerification(
    options?: Omit<
      UndefinedInitialDataOptions<UserPersonaVerificationDto, Error, UserPersonaVerificationDto, string[]>,
      'queryFn' | 'queryKey'
    >
  ) {
    return useQuery({
      queryKey: [QueryKey.User, QueryKey.PersonaVerification],
      queryFn: () => getUserPersonaVerification(),
      ...options,
    });
  }