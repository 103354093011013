import Marquee from 'react-fast-marquee';
import { Icon } from 'legacy-components/componets';
import { IconSVGName } from 'common/types/types';
import { FC, memo } from 'react';
import { BannerDto } from 'common/generated/types/banner-dto';

export const Banner: FC<BannerDto> = memo(({ backgroundColor, content, icon }) => {
  return (
    <Marquee className={`text-white select-none`} autoFill style={{ backgroundColor: `#${backgroundColor}` }}>
      <div className='flex gap-5 p-1 items-center min-h-[40px]'>
        <Icon name={icon as IconSVGName} className='ml-4' />
        <p>{content}</p>
      </div>
    </Marquee>
  );
});
