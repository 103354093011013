import { IconSVGName } from 'common/types/types';
import { formatToLocaleString, joinValues } from 'helpers/string.helpers';
import { FC } from 'react';
import { EstateDetailsShortInfoProps } from './EstateDetailsShortInfo.types';
import { Icon } from 'legacy-components/componets';

export const EstateDetailsShortInfo: FC<EstateDetailsShortInfoProps> = ({ values, unitErrors, unitTouched, index }) => {
  const shortInfo: Record<string, { value: string | number; isError: boolean; units?: string }> = {
    bed: {
      value: values.units?.[index]?.beds,
      isError: Boolean(unitErrors?.beds && unitTouched?.beds),
    },
    bath: {
      value: values.units?.[index]?.baths,
      isError: Boolean(unitErrors?.baths && unitTouched?.baths),
    },
    triangle: {
      value: values.units?.[index]?.squareFeet,
      isError: Boolean(unitErrors?.squareFeet && unitTouched?.squareFeet),
      units: 'ft',
    },
    dollar: {
      value: values.units?.[index]?.rent,
      isError: Boolean(unitErrors?.rent && unitTouched?.rent),
      units: '/mo',
    },
  };
  return (
    <>
      {Object.keys(shortInfo).map((item, index) => {
        return (
          <div key={index} className='beds flex items-center pt-2 md:pt-6 gap-1 h-full mx-1'>
            <Icon name={item as IconSVGName} />
            <div className='flex-shrink-0'>
              {shortInfo[item].value ? (
                <span className='text-black flex-shrink-0'>
                  {joinValues([formatToLocaleString(Number(shortInfo[item].value)), shortInfo[item].units], ' ')}
                </span>
              ) : (
                <span className={shortInfo[item].isError ? 'text-error' : 'text-black'}>- -</span>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
