import { useRoutes } from 'react-router-dom';
import routes from 'routes';
import { AuthProvider } from 'context/AuthProvider';
import { SignalRProvider } from '../context/SignalRProvider';
import { CookiesProvider } from 'react-cookie';
import { Suspense } from 'react';
import { Loader } from 'legacy-components/componets';

function App() {
  const content = useRoutes(routes);

  return (
    <CookiesProvider>
      <AuthProvider>
        <SignalRProvider>
          <Suspense fallback={<Loader />}>{content}</Suspense>
        </SignalRProvider>
      </AuthProvider>
    </CookiesProvider>
  );
}

export default App;
