import { CellProps } from 'react-table';
import { ToursScheduleTableIsConfirmedCellType, ToursScheduleTableRowType } from '../types';

const UpcomingToursConfirmedCell = ({
  value,
}: CellProps<ToursScheduleTableRowType, ToursScheduleTableIsConfirmedCellType>) => {
  const { isConfirmed } = value;
  return (
    <div className='flex max-w-[100%]'>
      <span className={`text-base font-medium ${isConfirmed ? 'text-[#A0D911]' : 'text-trueGray'}`}>
        {(isConfirmed ? 'YES' : 'NO') ?? '-'}
      </span>
    </div>
  );
};

export { UpcomingToursConfirmedCell };
