import { Filters } from 'components/molecules/Filters/Filters';
import { PaidTourStatusDto, TourStatusDto } from 'common/enums/services/api/tours/tour-status-dto.enum';
import { QueryParamConfig, StringParam, createEnumDelimitedArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { useMemo } from 'react';
import { LandlordToursFiltersForm } from './LandlordToursFiltersForm';
import { useAuth } from 'hooks';
import { AuthRole } from 'common/enums/enums';

type QueryParamConfigType = {
  status: QueryParamConfig<`${TourStatusDto}`[]>;
  showOnly: QueryParamConfig<string>;
  dateFrom: QueryParamConfig<string>,
  dateTo: QueryParamConfig<string>;
};

const defaultQueryParams = {
  status: [],
  showOnly: '',
  dateFrom: '',
  dateTo: '',
};

const LandlordToursFilters = () => {
  const { user } = useAuth();
  const isAdmin = user?.roles.includes(AuthRole.SuperUser);
  
  const queryParamConfig = useMemo<QueryParamConfigType>(
    () => ({
      status: withDefault(createEnumDelimitedArrayParam(Object.values(isAdmin ? TourStatusDto : PaidTourStatusDto ) as `${TourStatusDto}`[], ','), []),
      showOnly: withDefault(StringParam, defaultQueryParams.showOnly),
      dateFrom: withDefault(StringParam, defaultQueryParams.dateFrom),
      dateTo: withDefault(StringParam, defaultQueryParams.dateTo),
    }),
    [isAdmin],
  );

  //TODO: FIX ALL FILTERS: replace -> replaceIn
  const [query, setQuery] = useQueryParams(queryParamConfig, {
    updateType: 'replaceIn',
  });

  const handleSubmit = (values: any) => {
    setQuery(values);
  };

  //TODO: FIX ALL FILTERS: replace -> replaceIn
  const handleReset = () => {
    setQuery(defaultQueryParams);
  };

  return (
    <Filters
      initialValues={defaultQueryParams}
      filters={query}
      onSubmit={handleSubmit}
      onReset={handleReset}
      FiltersMenuForm={LandlordToursFiltersForm}
    />
  );
};

export { LandlordToursFilters };
