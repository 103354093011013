import React from 'react';
import { Button } from 'legacy-components/componets';
import dayjs from 'dayjs';
import { PropertyUnitType } from 'common/enums/enums';
import { useIsMutating } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import minMax from 'dayjs/plugin/minMax';
import { PropertyBuildingAvailabilityProps } from './property-building-availability.types';
import { GenericPropertyBuildingAvailability } from './GenericPropertyBuildingAvailability';
import { SeparateBuildingAvailabilityComponent } from './SeparateBuildingAvailabilityComponent';
import { getCommonMinDate } from './property-building-availability.utils';
dayjs.extend(minMax);

const PropertyBuildingAvailability = ({
  stepTitle,
  values,
  errors,
  touched,
  setFieldValue,
  submitForm,
}: PropertyBuildingAvailabilityProps) => {
  const [expandedIds, setExpandedIds] = React.useState<number[]>([]);
  const isLoading = useIsMutating({ mutationKey: [QueryKey.PropertyAvailability] }) > 0;
  const isMultiple = values.basics.type === PropertyUnitType.Multiple;
  const forAllUnits = values?.availability?.forAllUnits;

  const handleExpand = (id: number) => {
    setExpandedIds((prevExpandedIds) => {
      const isExpanded = prevExpandedIds.includes(id);
      if (isExpanded) {
        return prevExpandedIds.filter((expandId) => expandId !== id);
      }
      return [...prevExpandedIds, id];
    });
  };
  const useExpandableList = { expandedIds, handleExpand };
  const accessTypeStyles = (sameAccess: boolean) =>
    `cursor-pointer p-10 w-1/2 flex justify-center items-center ${
      sameAccess ? 'bg-secondary text-primary' : 'border border-trueGray'
    }`;

  return (
    <>
      <div className='property-basics flex flex-col gap-9'>
        <div className='title flex flex-col gap-3'>
          {stepTitle && <div className='text-primaryDark uppercase font-semibold'>{stepTitle}</div>}
          <div className='title text-xl font-medium'>Building availability</div>
        </div>
        <div className='flex flex-col gap-2'>
          {isMultiple && (
            <div className='flex w-full gap-1 m-2'>
              <div
                className={accessTypeStyles(forAllUnits)}
                onClick={() => {
                  setFieldValue('availability.forAllUnits', true);
                }}
              >
                Same availability to all units
              </div>
              <div
                className={accessTypeStyles(!forAllUnits)}
                onClick={() => {
                  setFieldValue('availability.forAllUnits', false);
                }}
              >
                Specific availability to each unit
              </div>
            </div>
          )}
          {isMultiple && !forAllUnits && (
            <div
              onClick={() => {
                setExpandedIds([]);
              }}
              className='text-sm font-semibold text-primary cursor-pointer flex w-full justify-end'
            >
              Collapse all
            </div>
          )}
          {isMultiple && !forAllUnits ? (
            values?.details?.units?.map((unitItem, index) => {
              return (
                <SeparateBuildingAvailabilityComponent
                  key={unitItem?.id || index}
                  values={values}
                  errors={errors}
                  touched={touched}
                  index={index}
                  setFieldValue={setFieldValue}
                  useExpandableList={useExpandableList}
                />
              );
            })
          ) : (
            <GenericPropertyBuildingAvailability
              setFieldValue={setFieldValue}
              nameOfValue={'availability'}
              itemAvailabilityValue={values?.availability}
              errorAvailabilityType={errors?.availability}
              touchedAvailabilityType={touched?.availability}
              minDate={getCommonMinDate(values?.details?.units)}
            />
          )}
        </div>

        <div className='button-wrapper flex justify-end'>
          <Button label={'Save and continue'} theme={'primary'} onClick={submitForm} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export { PropertyBuildingAvailability };
