import { FC, ReactNode } from 'react';
import { Button } from 'legacy-components/componets';

export type FiltersMenuProps = {
  onSubmit: () => void | Promise<void>;
  onReset: () => void | Promise<void>;
  onClose: () => void;
  id: string;
  children: ReactNode;
};

const FiltersMenu: FC<FiltersMenuProps> = ({ onSubmit, onReset, onClose, id, children }) => {
  return (
    <div
      id={id}
      className='px-[18px] py-[15px] md:px-[34px] md:py-[30px] flex flex-col gap-3 sm:gap-6 md:gap-9 max-w-[700px] min-w-[320px]'
    >
      <div className='flex-1'>{children}</div>
      <div className={`flex flex-col items-center gap-4`}>
        <div className='flex justify-between items-center gap-4 w-full'>
          <Button
            onClick={() => {
              onReset();
              onClose();
            }}
            label={'Clear all filters'}
            theme={'primary-outline'}
            isFullWidth
          />
          <Button
            onClick={() => {
              onSubmit();
              onClose();
            }}
            label={'Apply Filters'}
            isFullWidth
          />
        </div>
      </div>
    </div>
  );
};

export { FiltersMenu };
