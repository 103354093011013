export enum EstateStatusDto {
  Available = 'Available',
  Incomplete = 'Incomplete',
  PendingApproval = 'PendingApproval',
  PendingPayment = 'PendingPayment',
  Rejected = 'Rejected',
  Occupied = 'Occupied',
  Unavailable = 'Unavailable',
}

export enum SubscriptionStatusType {
  Active = 'Active',
  PendingCanceling = 'PendingCanceling',
  Inactive = 'Inactive',
}
