import { CellProps } from 'react-table';
import { AdminEstateTableRow, LanlordCellProps } from '../AdminProfileListingTable.types';

export const AdminTextCell = ({ value: lanlordName }: CellProps<AdminEstateTableRow, LanlordCellProps>) => {
  return (
    <div className='flex gap-3 max-w-[100%]'>
      <div className={'text-black text-base text-start truncate'}>{lanlordName}</div>
    </div>
  );
};
