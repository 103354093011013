import React, { useEffect, useState } from 'react';
import { CollectKeyModalPropsType } from './types/collect-key-modal-props.type';
import { Button, Carousel, Icon, Modal } from 'legacy-components/componets';
import { TourStatusDto } from 'common/enums/enums';
import { StartTourModal } from 'legacy-components/modals/start-tour-modal/StartTourModal';
import { splitPascalCaseWords } from 'helpers/string.helpers';
import { getSupport } from 'services/api/auth-api.service';

const CollectKeyModal = ({
  keyInfo,
  onStartTour,
  isAvailableToStartTour,
  tour,
  open,
  onClose,
}: CollectKeyModalPropsType) => {
  const [showImages, setShowImages] = useState(true);
  const [support, setSupport] = useState('');
  const [isStartTourModalOpen, setIsStartTourModalOpen] = useState(false);

  useEffect(() => {
    getSupport().then((res) => {
      setSupport(res.email);
    });
  }, []);

  const handleStartTour = () => {
    onStartTour(keyInfo.id);
  };

  return (
    <>
      <StartTourModal
        isOpen={isStartTourModalOpen}
        onClose={() => {
          setIsStartTourModalOpen(false);
        }}
        onContinue={() => {
          setIsStartTourModalOpen(false);
          handleStartTour();
        }}
      />
      <Modal isOpen={open} onClose={onClose} styles={{ content: { maxHeight: '100%' } }}>
        <div
          className={
            'px-[18px] py-[15px] md:px-[34px] md:py-[30px] bg-white rounded-2xl shadow flex flex-col gap-3 sm:gap-6 md:gap-9 max-w-screen-lg w-screen'
          }
        >
          <div className={'relative'}>
            <button onClick={onClose} className={'absolute right-0 top-[18px] -translate-y-1/2 md:top-[24px]'}>
              <Icon name={'xmark'} className={'cursor-pointer text-primary'} width={24} height={24} />
            </button>
            <div
              className={
                'text-center text-neutral-800 text-[24px] leading-[36px] md:text-[32px] font-semibold md:leading-[48px] px-8'
              }
            >
              Collect your keys
            </div>
          </div>
          <div className={'flex flex-col gap-[30px] px-[30px] py-[20px]'}>
            <div className='h-[84px] px-5 py-[30px] bg-sky-50 rounded-lg justify-center items-center gap-3 flex'>
              <Icon name={'key'} width={16} height={16} />
              <div className={'text-neutral-800 text-xl font-bold leading-normal'}>
                <span className='font-bold'>Access type: </span>
                <span className='font-normal'>{splitPascalCaseWords(keyInfo.type)}</span>
              </div>
            </div>
            <div>{keyInfo.description}</div>
            {showImages && (
              <div>
                <Carousel
                  onImageError={() => {
                    setShowImages(false);
                  }}
                  images={keyInfo.images.map((img) => img) || []}
                  arrowSize={28}
                  height={400}
                  arrowColor={'white'}
                />
              </div>
            )}
          </div>
          <div className={'flex justify-between gap-3 md:gap-6'}>
            <a
              href={`mailto:${support}`}
              className='flex justify-center items-center text-sm font-bold rounded-lg focus:outline-none bg-white text-primary border border-primary w-1/2 h-[66px]'
            >
              Contact support
            </a>
            {isAvailableToStartTour && tour?.tourStatus === TourStatusDto.Scheduled ? (
              <Button
                label={'Start tour'}
                theme={'primary'}
                stylesConfig={{ width: '50%', height: 66 }}
                onClick={() => {
                  onClose();
                  setIsStartTourModalOpen(true);
                }}
              />
            ) : (
              <Button
                label={'Got it'}
                theme={'primary'}
                stylesConfig={{ width: '50%', height: 66 }}
                onClick={() => {
                  onClose();
                }}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export { CollectKeyModal };
