import React, { Fragment } from 'react';
import { CellProps } from 'react-table';
import { Icon } from 'legacy-components/componets';
import { AdminActionsCell, AdminEstateTableRow } from '../AdminProfileListingTable.types';
import { EllipsisOutlined } from '@ant-design/icons';
import Dropdown from 'rc-dropdown';
import { EstateListDtoStatusEnum } from 'common/generated/types/estate-list-dto';

const AdminProfileListingTableActionsMenuCell: React.FC<CellProps<AdminEstateTableRow, AdminActionsCell>> = ({
  value,
}) => {
  const { onView, onApprove, onCancel, status, updateToNew, canRejectEstate } = value;

  const options: {
    label: string;
    icon?: React.JSX.Element;
    isHiden?: boolean;
    onClick: () => void;
  }[] = [
    {
      label: 'View',
      icon: <Icon name={'faEye'} width={'1em'} height={'1em'} />,
      onClick: onView,
    },
    {
      label: 'Update to New',
      icon: <Icon name={'add-new-item-dropdown'} width={'1em'} height={'1em'} />,
      onClick: updateToNew,
      isHiden:
        !canRejectEstate ||
        (status !== EstateListDtoStatusEnum.Rejected && status !== EstateListDtoStatusEnum.Available),
    },
    {
      label: 'Approve',
      icon: <Icon name={'check'} width={'1em'} height={'1em'} />,
      onClick: onApprove,
      isHiden:
        !canRejectEstate ||
        (status !== EstateListDtoStatusEnum.PendingApproval && status !== EstateListDtoStatusEnum.Rejected),
    },
    {
      label: 'Reject',
      icon: <Icon name={'xmark'} width={'1em'} height={'1em'} />,
      onClick: onCancel,
      isHiden:
        !canRejectEstate ||
        (status !== EstateListDtoStatusEnum.PendingApproval && status !== EstateListDtoStatusEnum.Available),
    },
  ];

  const overlay = (
    <div
      className={
        'w-[200px] py-6 bg-white rounded-2xl shadow border border-zinc-100 flex-col justify-start items-start flex gap-3'
      }
    >
      {options
        .filter((opt) => !opt.isHiden)
        .map((opt) => (
          <button
            type={'button'}
            key={opt.label}
            className={
              'w-full px-6 py-4 hover:bg-sky-50 border-r border-transparent hover:border-sky-300 justify-start items-center gap-2.5 flex hover:text-sky-600'
            }
            onClick={opt.onClick}
          >
            {opt.icon && React.cloneElement(opt.icon, { className: 'text-[16px]' })}
            <span className={'text-base font-normal leading-tight'}>{opt.label}</span>
          </button>
        ))}
    </div>
  );

  return (
    <Fragment>
      <Dropdown trigger={['click']} overlay={overlay} placement={'bottomRight'}>
        <button type={'button'}>
          <EllipsisOutlined className={'text-[24px]'} />
        </button>
      </Dropdown>
    </Fragment>
  );
};

export { AdminProfileListingTableActionsMenuCell };
