import { Card, Skeleton } from 'antd';

const EstateCardSkeletonVertical = () => (
  <Card
    bordered={false}
    cover={<Skeleton.Image style={{ width: '100%', height: '200px' }} active />}
    bodyStyle={{
      minHeight: 208,
    }}
  >
    <Skeleton active />
  </Card>
);

export { EstateCardSkeletonVertical };
