import React, { CSSProperties } from 'react';
import { Button, Modal, Textarea } from 'legacy-components/componets';
import { twMerge } from 'tailwind-merge';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TourDetailsDto } from 'common/types/services/api/tours/tour-details-dto.type';
import { Notification } from 'services/notification.service';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import RadioGroup from 'legacy-components/fields/radio-group/radio-group';
import { leaveFeedbackAboutTour } from 'services/api/tours-api.service';

type LetUsKnowWhyModalProps = {
  tour: TourDetailsDto;
  open: boolean;
  onClose: () => void;
  styles?: {
    overlay?: CSSProperties;
    content?: CSSProperties;
  };
  onSubmit: () => void;
  onGoBack: () => void;
};

type FormValues = {
  reason: string | null;
  rate: number | null;
  doBetter: string | null;
  description: string;
};

const validationSchema = yup.object({
  reason: yup.string().required('Reason is required'),
  rate: yup.number().required('Rate is required'),
  doBetter: yup.string().required('Do better is required'),
  description: yup.string().max(500),
});

export const LetUsKnowWhyModal: React.FC<LetUsKnowWhyModalProps> = ({
  open,
  onClose,
  styles,
  tour,
  onSubmit,
  onGoBack,
}) => {
  const { values, handleChange, handleSubmit, errors, setFieldValue, touched, setFieldTouched } = useFormik<FormValues>(
    {
      initialValues: { reason: null, rate: null, doBetter: null, description: '' },
      onSubmit: async ({ reason, rate, doBetter, description }) => {
        await leaveFeedbackAboutTour(tour.id, {
          answerReason: reason as string,
          description,
          experienceRate: rate as number,
          improvment: doBetter as string,
        });
        const notification = new Notification();
        notification.success('Feedback was sent successfully');
        onSubmit();
      },
      validationSchema,
    },
  );

  return (
    <>
      <Modal isOpen={open} onClose={onClose} styles={{ ...styles, content: { ...styles?.content, maxHeight: '100%' } }}>
        <form
          className='px-4 py-3 md:px-8 md:py-7 rounded-lg shadow max-w-screen-md w-screen flex flex-col gap-3 sm:gap-6 md:gap-9'
          onSubmit={handleSubmit}
        >
          <div className='flex justify-between items-center'>
            <button onClick={onGoBack}>
              <ArrowLeftOutlined className={'text-primary text-[24px]'} />
            </button>
            <h3 className='text-xl whitespace-nowrap text-center text-neutral-800 text-[40px] font-semibold leading-10'>
              Let us know why
            </h3>
            <button onClick={onClose}>
              <CloseOutlined className={'text-primary text-[24px]'} />
            </button>
          </div>
          <div className={'bg-sky-50 py-4 px-8 rounded-xl flex gap-3'}>
            <p className='text-sky-600 text-[18px] font-bold leading-relaxed'>
              We value your feedback and would appreciate your honest opinion regarding the property's suitability for
              your needs.
            </p>
          </div>
          <p className='text-neutral-800 text-[16px] font-normal leading-normal'>
            Your insights will assist us in determining whether this property aligns with your preferences and if we can
            assist you further in your search for a suitable rental. Please feel free to share your thoughts and any
            additional information that might influence your decision.
          </p>
          <div className='border border-zinc-100'></div>
          <label className='text-neutral-800 text-[18px] font-bold leading-snug'>
            What is the primary reason for your answer?
          </label>
          <div>
            <RadioGroup
              name={'reason'}
              className='grid grid-cols-1 gap-2.5 md:grid-cols-4 sm:grid-cols-2'
              onChange={(e, value) => {
                setFieldTouched('reason');
                setFieldValue('reason', value);
              }}
            >
              {['Location', 'Size', 'Amenities', 'Price'].map((el) => (
                <RadioGroup.Option label={el} value={el} withIcon key={el} />
              ))}
            </RadioGroup>
            {errors.reason && touched.reason && (
              <p className='text-red-500 text-xs font-normal leading-none mt-2'>{errors.reason}</p>
            )}
          </div>
          <label className='text-neutral-800 text-[18px] font-bold leading-snug'>
            Please rate your experience. (1 to 5)
          </label>
          <div>
            <RadioGroup
              name={'rate'}
              className='grid grid-cols-2 gap-2.5 md:grid-cols-5 sm:grid-cols-3'
              onChange={(e, value) => {
                setFieldTouched('rate');
                setFieldValue('rate', value);
              }}
            >
              {new Array(5)
                .fill(0)
                .map((_, i) => `${i + 1}`)
                .map((el) => (
                  <RadioGroup.Option label={el} value={el} key={el} />
                ))}
            </RadioGroup>
            {errors.rate && touched.rate && (
              <p className='text-red-500 text-xs font-normal leading-none mt-2'>{errors.rate}</p>
            )}
            <div className={'mt-2.5 md:hidden flex flex-col gap-2'}>
              <div className='text-neutral-500 text-xs font-normal leading-none'>1 - Not Satisfied</div>
              <div className='text-neutral-500 text-xs font-normal leading-none'>5 - Very Satisfied</div>
            </div>
            <div className={'justify-between mt-2.5 hidden md:flex'}>
              <div className='text-neutral-500 text-xs font-normal leading-none'>Not Satisfied</div>
              <div className='text-neutral-500 text-xs font-normal leading-none'>Very Satisfied</div>
            </div>
          </div>
          <label className='text-neutral-800 text-[18px] font-bold leading-snug'>What can we do better?</label>
          <div>
            <RadioGroup
              name={'doBetter'}
              className='grid grid-cols-1 gap-2.5 md:grid-cols-3 sm:grid-cols-2'
              onChange={(e, value) => {
                setFieldTouched('doBetter');
                setFieldValue('doBetter', value);
              }}
            >
              {['Building access', 'Location', 'Tour Duration'].map((el) => (
                <RadioGroup.Option label={el} value={el} withIcon key={el} />
              ))}
            </RadioGroup>
            {errors.doBetter && touched.doBetter && (
              <p className='text-red-500 text-xs font-normal leading-none mt-2'>{errors.doBetter}</p>
            )}
          </div>
          <label className='text-neutral-800 text-[18px] font-bold leading-snug'>Want to share anything else?</label>
          <div>
            <Textarea
              name={'description'}
              inputClassName={'h-[118px]'}
              onChange={handleChange}
              value={values.description}
            />
            <div className={twMerge('flex justify-end', errors.description && 'text-red-500')}>
              {values.description.length}/500
            </div>
            {errors.description && (
              <p className='text-red-500 text-xs font-normal leading-none mt-2'>{errors.description}</p>
            )}
          </div>
          <Button label={'Submit Feedback'} isFullWidth type={'submit'} />
        </form>
      </Modal>
    </>
  );
};
