import { dateFormatMMMM_DD, dateTimeFormatHHMM_A } from 'helpers/date';
import { CellProps } from 'react-table';
import { ToursScheduleTableRowType, ToursScheduleTableDateCellType } from '../types';
import dayjs from 'dayjs';

export const ScheduledToursDateCell = ({
  value,
}: CellProps<ToursScheduleTableRowType, ToursScheduleTableDateCellType>) => {
  const utcDate = dayjs(value).utc();

  return (
    <div className='flex flex-col text-base text-trueGray gap-0.5 pl-2.5'>
      <span>{`${dateFormatMMMM_DD(utcDate)},`}</span>
      <span>{dateTimeFormatHHMM_A(utcDate)}</span>
    </div>
  );
};
