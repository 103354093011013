import { AutoComplete, Select } from 'antd';
import CanadaStates from 'common/utils/canada-states';
import UsStates from 'common/utils/us-states';
import { FiltersMenuFormProps } from 'components/molecules/Filters/Filters';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { FC, useMemo, useRef } from 'react';
import { ENV } from 'common/enums/enums';

const AdminCommissionsFiltersForm: FC<FiltersMenuFormProps> = ({ values, setFieldValue, id }) => {
  const { state, city } = values;
  const sessionToken = useRef(new google.maps.places.AutocompleteSessionToken());

  const getSelectPopupContainer = (): HTMLElement => document.getElementById(id) as HTMLElement;

  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey: ENV.REACT_APP_GOOGLE,
    libraries: ['places'],
    debounce: 200,
    options: {
      componentRestrictions: { country: ['us', 'ca'] },
      types: ['locality', 'administrative_area_level_3'],
      input: '',
      sessionToken: sessionToken.current,
    },
    language: 'en',
  });

  const handleSelectCity = (value: string) => {
    setFieldValue('city', value);
  };

  const handleSearch = (value: string) => {
    getPlacePredictions({
      input: value,
      language: 'en',
      componentRestrictions: { country: ['us', 'ca'] },
      types: ['locality', 'administrative_area_level_3'],
      sessionToken: sessionToken.current,
    });
    setFieldValue('city', value);
  };

  const cityAutocompleteOptions = useMemo(() => {
    return placePredictions.map((el) => {
      return {
        label: el.description,
        value: el.structured_formatting.main_text,
      };
    });
  }, [placePredictions]);

  const statesSelectOptions = [...CanadaStates, ...UsStates].map((item) => {
    return {
      label: item.name,
      value: item.abbreviation,
    };
  });

  return (
    <div className='flex flex-col gap-3 flex-1 w-full'>
      <div className='flex flex-col w-full'>
        <label className={'block mb-2 text-base font-semibold text-black'}>{'State'}</label>
        <Select
          showSearch
          allowClear
          placeholder='Select state'
          getPopupContainer={getSelectPopupContainer}
          size='large'
          value={state || null}
          onChange={(value: string) => {
            setFieldValue('state', value);
          }}
          optionFilterProp='children'
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={statesSelectOptions}
        />
      </div>
      <div className='flex flex-col w-full'>
        <label className={'block mb-2 text-base font-semibold text-black'}>{'City'}</label>
        <AutoComplete
          value={city || null}
          size='large'
          allowClear
          placeholder={'Select city'}
          options={cityAutocompleteOptions}
          onSelect={handleSelectCity}
          onSearch={handleSearch}
          getPopupContainer={getSelectPopupContainer}
        />
      </div>
    </div>
  );
};

export { AdminCommissionsFiltersForm };
