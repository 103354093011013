function findFirstNonZeroDigitPosition(num: number): number {
  const decimalPart = num.toString().split('.')[1];

  if (decimalPart) {
    // Find the position of the first digit after the decimal point
    for (let i = 0; i < decimalPart.length; i++) {
      if (decimalPart[i] !== '0') {
        return i + 1; // Mark the position of the digit, starting from 1
      }
    }
  }

  // If all digits after the decimal point are zeros or there is no decimal part
  return 0;
}

export function customFormatNumber(num: number | null, floatNumber: number = 2): string | null {
  if (!num) {
    return null;
  }
  // Check if the number has a non-zero decimal part
  const decimalPart = num % 1;
  if (decimalPart !== 0) {
    // Determine the number of digits after the decimal point that are not zeros
    const decimalCount = findFirstNonZeroDigitPosition(num);

    // If the count is less than or equal to the specified floatNumber, format accordingly
    if (decimalCount <= floatNumber) {
      return num.toFixed(floatNumber);
    }
  }

  // If there is no decimal part or the non-zero digits exceed the specified floatNumber, format as an integer
  return num.toFixed(0);
}
