const styles = {
    container: '',
    label: 'block mb-1 text-sm font-medium text-black',
    input:
        'block shadow-input-shadow w-full h-11 py-2 px-3 pl-10 text-black border border-neutral-200 rounded-md hover:border-primary focus:border-primary focus:outline-none transition',
    error: '!border-error !focus:border-error',
    icon: 'block shadow-input-shadow h-11 py-2 px-3 text-black border border-neutral-200 rounded-l-md hover:border-primary focus:border-primary focus:outline-none transition',
};

export default styles;
