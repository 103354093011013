import { PaymentCardsDto } from 'common/types/services/api/payment/payment-cards-dto.type';
import { Loader } from 'legacy-components/componets';
import { FC } from 'react';
import { PaymentCardsListItem } from './item';
import { Button, DropdownMenu, Icon } from 'legacy-components/componets';
import Dropdown from 'rc-dropdown';

type Props = {
  list?: PaymentCardsDto | null;
  handleDeleteItem: (id: string) => void;
};

const PaymentCardsListControlled: FC<Props> = ({ list, handleDeleteItem }) => {

  if (!list) {
    return <Loader />;
  }

  return (
    <div className='flex flex-col gap-3'>
      {list.map((el) => {
        return (
          <PaymentCardsListItem
            key={el.id}
            item={el}
            actions={
              <>
                {/* <Button label={'Use'} theme={'primary'} disabled onClick={() => {}} /> */}
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <DropdownMenu
                      menuItems={[
                        {
                          icon: 'delete',
                          id: `delete`,
                          title: 'Delete',
                          onClick: () => handleDeleteItem(el.id),
                        },
                      ]}
                    />
                  }
                >
                  <Icon name={'dots-menu'} onClick={() => {}} />
                </Dropdown>
              </>
            }
          />
        );
      })}
    </div>
  );
};

export { PaymentCardsListControlled };
