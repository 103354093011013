import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { OffersDto } from 'common/types/services/api/offers/offers-dto.type';
import { getOffersByUnitId } from 'services/api/offers-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useOffersByUnitId(
  unitId: string,
  options?: Omit<
    UndefinedInitialDataOptions<OffersDto, Error, OffersDto, (string | { unitId: string })[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Offers, { unitId }],
    queryFn: () => getOffersByUnitId(unitId),
    ...options,
  });
}
