import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { deletePaymentCard } from 'services/api/payment-api.service';
import { notification } from 'services/services';
import { QueryKey } from 'common/enums/query-key';

export default function useDeletePaymentCard(
  options?: Omit<UseMutationOptions<void, Error, string, unknown>, 'mutationFn'>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePaymentCard,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.PaymentCards] });
    },
    onSuccess: () => {
      notification.success('The payment method has been removed');
    },
    ...options,
  });
}
