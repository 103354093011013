import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QueryKey } from 'common/enums/query-key';
import { BackendError } from 'common/types/errors/backendError';
import { UserSignInDto } from 'common/types/types';
import { chate2FAState, refreshToken, sendTwoFactorPhoneCode } from 'services/api/auth-api.service';

export const useChange2FaState = (
  options?: Omit<UseMutationOptions<void, AxiosError<BackendError>, boolean, unknown>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: chate2FAState,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.User, 'two-factor-auth'] });
    },
    ...options,
  });
};

export const useSend2FaPhoneConfirmationToUser = (
  options?: UseMutationOptions<void, AxiosError<BackendError>, string, unknown>,
) => {
  return useMutation({
    mutationFn: sendTwoFactorPhoneCode,
    ...options,
  });
};

export const useRefreshToken = (
  options?: UseMutationOptions<UserSignInDto, AxiosError<BackendError>, string, unknown>,
) => {
  return useMutation({
    mutationFn: refreshToken,
    ...options,
  });
};
