import { createContext, useRef, FC, ReactNode }from 'react';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ENV } from 'common/enums/enums';

type SignalRProviderProps = {
    children?: ReactNode;
}

const SignalRContext = createContext<HubConnection | undefined>(undefined);

export const SignalRProvider: FC<SignalRProviderProps> = ({ children }) => {
  const hubConnectionRef = useRef<HubConnection | undefined>(undefined);

  //const { isAuthenticated } = useAuth();
    
    try {
      const accessToken = localStorage.getItem('accessToken') as string;
      
      if(!hubConnectionRef.current) {
        const hubConnection = new HubConnectionBuilder()
            .withUrl(`${ENV.APP_ORIGIN_PATH}/hubs/actionEvent`, {
              accessTokenFactory: () => accessToken,
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Error)
            .build();

      // socketConnection.on('tourPaymentCreated', ({ secret }) => {
      //   // TODO Figure out how to use react-query queryClient here
      //   // dispatch(setTourPayment(secret));
      // });

      // socketConnection.on('estatePaymentCreated', ({ secret }) => {
      //   // TODO Figure out how to use react-query queryClient here
      //   // dispatch(setEstatePayment(secret));
      // });

      //socketConnection.start();
      //connectionRef.current = socketConnection;
        hubConnectionRef.current = hubConnection;
      }

    } catch (error) {
      console.error("Unable to initialize SignalR hubConnection context: ", error);
    }


  // React.useEffect(() => {
  //   if (isAuthenticated) {
  //     connectToSocket();
  //     return () => {
  //       connectionRef.current?.stop();
  //     };
  //   }
  // }, [isAuthenticated, connectToSocket]);

  return (
  <SignalRContext.Provider value={hubConnectionRef.current}>
    {children}
   </SignalRContext.Provider>
  );
};

export { SignalRContext };
