import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { OfferFileDto } from 'common/types/services/api/offers/offer-file-dto.type';
import { getOfferFiles } from 'services/api/offers-api.service';

export const useGetRenterDocsForOffer = (
  applicationId: string,
  options?: Omit<UndefinedInitialDataOptions<OfferFileDto[], Error, OfferFileDto[], string[]>, 'queryFn' | 'queryKey'>,
) => {
  return useQuery({
    queryKey: [QueryKey.Offers, 'renter'],
    queryFn: () => getOfferFiles(applicationId),
    ...options,
  });
};
