import { Carousel } from 'legacy-components/componets';
import { EstateStatusDto } from 'common/enums/enums';

export interface ICardCarouselProps {
  images: string[];
  status?: EstateStatusDto
}

const CardCarousel = ({ images, status }: ICardCarouselProps) => {
  return <Carousel images={images} height={199} status={status} />;
};

export { CardCarousel };
