import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getMyEstates } from 'services/api/estates-api.service';
import { EstateListDashboardDto } from 'common/types/services/api/estates/estate-list-dto.type';
import { ListingRequestDto } from 'common/types/landlord/listing-dto.type';
import { QueryKey } from 'common/enums/query-key';

export default function useMyEstates(
  params: ListingRequestDto,
  options?: Omit<
    UndefinedInitialDataOptions<EstateListDashboardDto, Error, EstateListDashboardDto, (string | ListingRequestDto)[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Estates, 'my', params],
    queryFn: () => getMyEstates(params),
    ...options,
  });
}
