import { BadgeSize, BadgeTheme } from 'common/types/types';

const common = 'flex-center leading-none text-center whitespace-nowrap align-middle rounded-lg';

const sSize = `${common} text-[11px] py-0.5 px-2 font-semibold`;
const mSize = `${common} text-sm py-1 px-4 font-bold`;
const lSize = `${common} text-sm py-1 px-2 font-bold`;

const grayTheme = 'bg-gray text-trueGray';
const blackTheme = 'bg-black/60 text-white';
const greenTheme = 'bg-[#6FCF97]/10 text-[#27AE60]';
const greenPrimary = 'bg-primary text-white';
const pinkTheme = 'bg-[#FF385C]/60 text-white';

const styles: Record<BadgeTheme, Record<BadgeSize, string>> = {
  gray: {
    s: `${sSize} ${grayTheme}`,
    m: `${mSize} ${grayTheme}`,
    l: `${lSize} ${grayTheme}`,
  },
  black: {
    s: `${sSize} ${blackTheme}`,
    m: `${mSize} ${blackTheme}`,
    l: `${lSize} ${blackTheme}`,
  },
  green: {
    s: `${sSize} ${greenTheme}`,
    m: `${mSize} ${greenTheme}`,
    l: `${lSize} ${greenTheme}`,
  },
  primary: {
    s: `${sSize} ${greenPrimary}`,
    m: `${mSize} ${greenPrimary}`,
    l: `${lSize} ${greenPrimary}`,
  },
  pink: {
    s: `${sSize} ${pinkTheme}`,
    m: `${mSize} ${pinkTheme}`,
    l: `${lSize} ${pinkTheme}`,
  }
};

export default styles;
