import React, { FC } from 'react';
import { Icon } from 'legacy-components/ui/icon/icon';
import { IconSVGName } from 'common/types/components/ui/icon/icon-svg-name.type';
import { formatToLocaleString, joinValues } from 'helpers/string.helpers';
import { PropertyUnitValues } from './property-detail';
import { FormikErrors } from 'formik/dist/types';
import { FormikTouched } from 'formik';
import { IconProps } from 'common/types/types';

type Props = {
  unit: PropertyUnitValues;
  error?: FormikErrors<PropertyUnitValues>;
  touched?: FormikTouched<PropertyUnitValues>;
  iconOptions?: Omit<IconProps, "name" | "onClick">;
};

const ShortIconInfo: FC<Props> = ({ unit, error, touched, iconOptions }) => {
  const shortInfo: Record<string, { value: string | number; isError: boolean; units?: string }> = {
    bed: {
      value: unit.beds,
      isError: Boolean(error?.beds && touched?.beds),
    },
    bath: {
      value: unit.baths,
      isError: Boolean(error?.baths && touched?.baths),
    },
    triangle: {
      value: unit.squareFeet,
      isError: Boolean(error?.squareFeet && touched?.squareFeet),
      units: 'ft',
    },
    dollar: {
      value: unit.rent,
      isError: Boolean(error?.rent && touched?.rent),
      units: '/mo',
    },
  };

  return (
    <>
      {Object.keys(shortInfo).map((item, index) => (
        <div key={index} className='beds flex items-center pt-2 md:pt-6 gap-1 h-full'>
          <Icon name={item as IconSVGName} {...iconOptions} />
          <div className='flex-shrink-0'>
            {shortInfo[item].value ? (
              <span className='text-black text-sm flex-shrink-0'>
                {joinValues([formatToLocaleString(Number(shortInfo[item].value)), shortInfo[item].units], ' ')}
              </span>
            ) : (
              <span className={shortInfo[item].isError ? 'text-error' : 'text-black'}>- -</span>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default ShortIconInfo;
