export enum OfferStatusDto {
  PendingPayment = 'PendingPayment',
  New = 'New',
  Accept = 'Accept',
  Reject = 'Reject',
  Paid = 'Paid',
  Cancel = 'Cancel',
  // Expired = 'Expire',
  PaymentInProcess = 'PaymentInProcess',
  AcceptLandLordPreApplication = 'AcceptLandLordPreApplication',
  IncompleteOffer = 'IncompleteOffer'
}

export const offerStatusMap: Record<OfferStatusDto, string> = {
  [OfferStatusDto.Accept]: 'Accepted',
  [OfferStatusDto.Reject]: 'Rejected',
  [OfferStatusDto.New]: 'New',
  [OfferStatusDto.Paid]: 'Paid',
  [OfferStatusDto.PendingPayment]: 'Pending payment',
  [OfferStatusDto.Cancel]: 'Canceled',
  [OfferStatusDto.PaymentInProcess]: 'Processing payment',
  [OfferStatusDto.AcceptLandLordPreApplication]: 'Accepted by Landlord',
  [OfferStatusDto.IncompleteOffer]: 'Incomplete',
};
