import { CellProps } from 'react-table';
import { AccessAccountsNameCellProps, AccessAccountsTableRow } from 'common/types/types';

export type AccessAccountsTableProps = {};

const AccessAccountsNameCell = ({ value }: CellProps<AccessAccountsTableRow, AccessAccountsNameCellProps>) => {
  const { name, email } = value;

  return (
    <div className='flex gap-4'>
      <div className='flex flex-col font-medium gap-0.5'>
        <span className='text-base'>{name}</span>
        <span className='text-sm text-trueGray'>{email}</span>
      </div>
    </div>
  );
};

export { AccessAccountsNameCell };
