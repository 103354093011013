import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getAllBanners } from 'services/api/banner-api.service';
import { QueryKey } from 'common/enums/query-key';
import { BannerResponse } from 'common/types/services/api/banners/banner-dto.type';

export const useGetBanners = (
  options?: Omit<UndefinedInitialDataOptions<BannerResponse, Error, BannerResponse, string[]>, 'queryFn' | 'queryKey'>,
) => {
  return useQuery({
    queryKey: [QueryKey.Banners],
    queryFn: getAllBanners,
    ...options,
  });
};
