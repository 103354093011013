export enum AdminAccountSideBarItems {
  ProfileInformation = 'profileInformation',
  Listings = 'listings',
  Commissions = 'commissions',
  // Gifts = 'gifts',
  // Referral = 'referral',
  TourSchedule = 'tourSchedule',
  Offers = 'offers',
  Feedbacks = 'feedbacks',
  Security = 'security',
}

export enum AdminAccountNavItems {
  ProfileInformation = 'profileInformation',
  Listings = 'listings',
  TourSchedule = 'tourSchedule',
  Offers = 'offers',
  Commissions = 'commissions',
  // Referral = 'referral',
  Security = 'security',
}
