import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { TourAvailableSlotsDto } from 'common/types/services/api/tours/tour-available-slots-dto.type';
import { TourAvailableSlotsRequestDto } from 'common/types/services/api/tours/tour-available-slots-request-dto';
import { getAvailableTours } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useAvailableTours(
  params: Omit<TourAvailableSlotsRequestDto, 'signal'>,
  options?: Omit<
    UndefinedInitialDataOptions<
      TourAvailableSlotsDto,
      Error,
      TourAvailableSlotsDto,
      (string | Omit<TourAvailableSlotsRequestDto, 'signal'>)[]
    >,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    ...options,
    queryKey: [QueryKey.Tours, 'available', params],
    queryFn: ({ signal }) => getAvailableTours({ ...params, signal }),
  });
}
