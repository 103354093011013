import { FC } from 'react';
import { EstateCardCategoryBadgeProps } from './types';

const EstateCardCategoryBadge: FC<EstateCardCategoryBadgeProps> = ({ category }) => {
  return (
    <div className={'card-estate-type flex justify-center items-center'}>
      <div
        className={`text-[11px] inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline 
          font-semibold bg-gray text-trueGray rounded-full`}
      >
        {category}
      </div>
    </div>
  );
};

export { EstateCardCategoryBadge };
