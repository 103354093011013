import { CellProps } from 'react-table';
import { AccessAccountsActionCellProps, AccessAccountsTableRow } from 'common/types/types';
import { DropdownMenuItem } from 'legacy-components/dropdown-menu/common/types/dropdown-menu-item';
import { Icon, DropdownMenu } from 'legacy-components/componets';
import Dropdown from 'rc-dropdown';

const AccessAccountsActionCell = ({ value }: CellProps<AccessAccountsTableRow, AccessAccountsActionCellProps>) => {
  const { onConfirm, isConfirmed } = value;

  if (isConfirmed) {
    return null;
  }

  const menuItems: DropdownMenuItem[] = isConfirmed
    ? []
    : [
        {
          id: 'access_accounts_action_confirm',
          title: 'Confirm',
          onClick: () => onConfirm(),
        },
      ];

  return (
    <Dropdown trigger={['click']} overlay={<DropdownMenu menuItems={menuItems} headerTitle={'Access'} />}>
      <Icon name={'dots-menu'} onClick={() => {}} />
    </Dropdown>
  );
};

export { AccessAccountsActionCell };
