import React from 'react';
import { Button } from 'legacy-components/componets';

export interface IAuthButtonsGroupProps {
  onGoogleClick: () => void;
  onFacebookClick: () => void;
  onAppleClick: () => void;
  onEmailClick: () => void;
}

const AuthButtonsGroup = ({ onGoogleClick, onFacebookClick, onAppleClick, onEmailClick }: IAuthButtonsGroupProps) => {
  const socialButtonsStyle: React.CSSProperties = { width: 108, height: 48 };

  return (
    <div className='auth-buttons-container w-full flex flex-col gap-3'>
      <Button
        icon='email'
        type='button'
        label='Continue with email'
        theme='black-outline'
        onClick={onEmailClick}
        stylesConfig={{ height: 48, width: '100%' }}
      />
      {Boolean(false) && (
        <div className='social-buttons-wrapper flex justify-between'>
          <Button
            icon='google'
            type='button'
            theme='black-outline'
            onClick={onGoogleClick}
            stylesConfig={socialButtonsStyle}
          />
          <Button
            icon='facebook'
            type='button'
            theme='black-outline'
            onClick={onFacebookClick}
            stylesConfig={socialButtonsStyle}
          />
          <Button
            icon='apple'
            type='button'
            theme='black-outline'
            onClick={onAppleClick}
            stylesConfig={socialButtonsStyle}
          />
        </div>
      )}
    </div>
  );
};

export { AuthButtonsGroup };
