import * as yup from 'yup';
import { Button, Modal } from 'legacy-components/componets';
import { CloseOutlined } from '@ant-design/icons';
import { AddLeaseDocumentsProps, AddNewLeaseDocumentValues } from './AddNewLeaseDocument.types';
import { ADD_LEASE_BTN, MODAL_TITLE } from './AddNewLeaseDocument.constants';
import { UploadLeaseDocumentsFiles } from './UploadLeaseDocument/UploadleaseDocument';
import { VerifiedLeaseDocument } from './VerifiedLeaseDocument/VerifiedLeaseDocument';
import { stringWithOutExtraSpaces } from 'common/utils/string-without-extra-spaces';
import { useCreateLeaseDocument } from 'hooks/query';
import { useFormik } from 'formik';
import { FILE_REQUIRED_ERROR, FILE_SIZE_ERROR, FILE_TYPE_ERROR } from '../LeaseDocuments.constants';

export const validationSchema = yup.object({
  title: yup
    .string()
    .required('Document title is required')
    .max(50, 'Length of document title should be less than 50 symbols'),
  file: yup
    .mixed()
    .test({
      message: FILE_SIZE_ERROR,
      test: (file) => (file as File).size <= 75 * 1024 * 1024,
    })
    .test({
      message: FILE_TYPE_ERROR,
      test: (file) => (file as File).type === 'application/pdf',
    })
    .required(FILE_REQUIRED_ERROR),
});

export const AddLeaseDocument = ({ open, onClose }: AddLeaseDocumentsProps) => {
  const { values, submitForm, isSubmitting, resetForm, setFieldValue, errors, touched } =
    useFormik<AddNewLeaseDocumentValues>({
      initialValues: { title: '', file: undefined },
      validationSchema,
      onSubmit: async ({ file, title }) => {
        if (!file) return;
        await addNewLeaseDocument({ file, title: stringWithOutExtraSpaces(title) });
        resetForm();
        onClose();
      },
    });

  const closeModal = () => {
    onClose();
    resetForm();
  };

  const { mutateAsync: addNewLeaseDocument } = useCreateLeaseDocument();

  return (
    <Modal isOpen={open} onClose={closeModal}>
      <div className='flex flex-col gap-4 p-8 bg-white rounded-lg shadow min-w-[360px] sm:min-w-[500px]'>
        <div className='flex items-center justify-center gap-4'>
          <div className='flex-grow flex items-center justify-center'>
            <h3 className='text-black font-semibold text-xl whitespace-nowrap'>{MODAL_TITLE}</h3>
          </div>
          <button onClick={closeModal} type={'button'}>
            <CloseOutlined className={'text-primary text-[22px]'} />
          </button>
        </div>
        {!values.file ? (
          <UploadLeaseDocumentsFiles
            onDrop={(files: File[]) => {
              setFieldValue('file', files[0], false);
            }}
          />
        ) : (
          <VerifiedLeaseDocument values={values} errors={errors} touched={touched} setFieldValue={setFieldValue} />
        )}
        <Button
          className='w-full py-3 '
          label={ADD_LEASE_BTN}
          onClick={submitForm}
          disabled={isSubmitting}
          isLoading={isSubmitting}
        />
      </div>
    </Modal>
  );
};
