import React from 'react';
import { useMediaQuery } from './use-media-query';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState<{ width: number | undefined; height: number | undefined }>({
    width: undefined,
    height: undefined,
  });

  const isMobile = useMediaQuery('(max-width: 426px)');
  const isMiniTablet = useMediaQuery('(max-width: 640px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return {
    ...windowSize,
    isMobile,
    isMiniTablet,
    isTablet,
    isDesktop,
  };
};
