import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { UserSignInRequestDto } from 'common/types/services/api/user/user-sign-in-request-dto.type';
import { getTwoFactorStateWithHeaders, signIn } from 'services/api/auth-api.service';
import { UserSignInDto } from 'common/types/services/api/user/user-sign-in-dto.type';
import { StorageKey } from '../../../common/enums/services/storage/storage-key.enum';
import { notification, storage } from 'services/services';
import { useNavigate } from 'react-router-dom';
import { useSend2FaPhoneConfirmationToUser } from '../user/use-2fa-user';

export default function useSignIn(
  options?: Omit<UseMutationOptions<UserSignInDto, Error, UserSignInRequestDto, unknown>, 'mutationFn'>,
) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutateAsync: requestPhoneConfirmation } = useSend2FaPhoneConfirmationToUser({
    onError: (e) => {
      notification.error(
        e?.response?.data.detail || 'There was an error while obtaining the code for two-factor authentication.',
      );
    },
    onSuccess: () => {
      navigate('/auth/sign-in/two-factor-authentication');
    },
  });

  return useMutation({
    ...options,
    mutationFn: signIn,
    onSuccess: async (data, variables, context) => {
      await getTwoFactorStateWithHeaders(data?.access_token).then(async (response) => {
        if (!response?.data) {
          storage.setItem(StorageKey.ACCESS_TOKEN, data.access_token);
          storage.setItem(StorageKey.REFRESH_TOKEN, data.refresh_token);
          await queryClient.invalidateQueries({ queryKey: ['user'] });
          options?.onSuccess?.(data, variables, context);
        } else {
          storage.setItem(StorageKey.TEMP_ACCESS_TOKEN, data.access_token);
          storage.setItem(StorageKey.TEMP_REFRESH_TOKEN, data.refresh_token);
          await requestPhoneConfirmation(data.access_token);
        }
      });
    },
  });
}
