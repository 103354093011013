import { Drawer, Empty } from 'antd';
import styles from './style.module.css';
import { PdfDocumentViewer } from 'legacy-components/pdf/document-viewer';
import { Image } from 'antd';
import defaultEstatePhoto from 'assets/images/default_estate_image.png';
import { MimeTypes } from 'helpers/download-file';
import { useEffect, useState } from 'react';
import { isFile, isString } from 'common/utils/check-type';
import { Loader } from 'legacy-components/componets';
import { fetchFile } from './DocumentViewer.utils';
import { formatFiles } from 'legacy-components/fields/upload-files/helpers';

export type PreviewLeaseDocumentsProps = {
  open: boolean;
  onClose: () => void;
  file: File | string;
  title?: string;
};

export const DocumentViewerDrawer = ({
  open,
  onClose,
  file: fileProps,
  title: titleProps,
}: PreviewLeaseDocumentsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File>({} as File);
  const isImage = file.type === MimeTypes.PNG || file.type === MimeTypes.JPG;
  const isPDF = file.type === MimeTypes.PDF;

  useEffect(() => {
    const loadFile = async () => {
      if (!open) {
        setFile({} as File);
        return;
      }
      if (fileProps && isString(fileProps)) {
        fetchFile(fileProps, setIsLoading, setFile, onClose);
      }
      if (fileProps && isFile(fileProps)) {
        setFile(fileProps);
        const formattedFiles = await formatFiles([fileProps as File]);
        if (formattedFiles.length > 0) {
          setFile(formattedFiles[0]);
        }
      }
    };

    loadFile();
  }, [open, fileProps]);

  return (
    <>
      <Drawer
        destroyOnClose
        height={'100%'}
        placement={'top'}
        classNames={{
          header: styles.drawerHeader,
        }}
        open={open}
        onClose={onClose}
      >
        <div className='flex items-center justify-center gap-4'>
          <div className='flex-grow flex items-center justify-center'>
            <h3 className='text-black font-semibold text-xl whitespace-nowrap mb-3'>{titleProps}</h3>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {isImage && isFile(file) && (
              <div className='flex justify-center'>
                <Image
                  src={window.URL.createObjectURL(file)}
                  alt='self-tour details'
                  className={'object-contain'}
                  fallback={defaultEstatePhoto}
                />
              </div>
            )}
            {isPDF && <PdfDocumentViewer file={file} />}
            {!isImage && !isPDF && <Empty description={'Invalid file type'} />}
          </>
        )}
      </Drawer>
    </>
  );
};
