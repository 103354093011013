import { ToggleTheme } from 'common/types/types';

const styles: Record<ToggleTheme, { container: string; label: string; input: string; toggle: string; error: string }> =
  {
    default: {
      container: 'flex items-center gap-2 h-11',
      label: 'text-sm font-semibold text-black cursor-pointer block',
      input: 'sr-only peer',
      toggle: `w-8
                h-5
                bg-gray
                rounded-full
                peer
                peer-checked:after:translate-x-3
                peer-checked:after:border-white
                after:content-['']
                after:absolute
                after:top-[2px]
                after:left-[2px]
                after:bg-white
                after:rounded-full
                after:h-4
                after:w-4
                after:transition-all
                peer-checked:bg-primary`,
      error: 'border-error focus:border-error',
    },
    primary: {
      container: 'flex items-center gap-2 h-11',
      label: 'text-sm font-semibold text-black',
      input: 'sr-only peer',
      toggle: `w-8
                h-5
                bg-gray
                rounded-full
                peer
                peer-checked:after:translate-x-3
                peer-checked:after:border-white
                after:content-['']
                after:absolute
                after:top-[2px]
                after:left-[2px]
                after:bg-white
                after:rounded-full
                after:h-4
                after:w-4
                after:transition-all
                peer-checked:bg-primary`,
      error: 'border-b-error focus:border-b-error',
    },
  };

export default styles;
