import { useDropzone } from 'react-dropzone';
import { Button } from 'legacy-components/componets';
import React, { useRef } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { basestyle } from './UploadleaseDocument.styles';
import { UploadLeaseDocumentsProps } from './UploadLeaseDocument.types';
import { FILE_UPLOADER_TITLE, FILE_UPLOADER_SUBTITLE, UPLOAD } from './UploadLeaseDocuments.constants';

export const UploadLeaseDocumentsFiles = ({ onDrop }: UploadLeaseDocumentsProps) => {
  const leaseDocumentsInputRef = React.useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { getRootProps, getInputProps } = useDropzone({
    // accept: { 'application/pdf': ['.pdf'] },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      onDrop(acceptedFiles);
    },
    multiple: false,
  });

  const handleDragEnter = () => {
    if (leaseDocumentsInputRef.current) {
      leaseDocumentsInputRef.current.blur();
    }
  };

  const handleContainerClick = () => {
    leaseDocumentsInputRef.current?.click();
  };

  return (
    <div
      {...getRootProps({ className: 'dropzone', baseStyle: basestyle })}
      ref={containerRef}
      className='px-4 md:px-8 py-8 md:py-16 bg-sky-50 rounded-xl flex-col justify-start items-center gap-3 flex'
    >
      <section
        className='upload-files-container cursor-pointer'
        onClick={handleContainerClick}
        onDragEnter={handleDragEnter}
      >
        <div>
          <input ref={leaseDocumentsInputRef} accept={'application/pdf'} {...getInputProps()} />

          <div className='flex flex-col justify-center items-center gap-2.5'>
            <Button startIcon={<UploadOutlined className={'text-[18px]'} />} className='px-10 py-8' label={UPLOAD} />
            <div className='flex flex-col items-center text-xs md:flex-row md:text-sm font-medium rounded-md text-primary'>
              {FILE_UPLOADER_TITLE}
            </div>
            <div className='text-trueGray text-center text-sm'>{FILE_UPLOADER_SUBTITLE}</div>
          </div>
        </div>
      </section>
    </div>
  );
};
