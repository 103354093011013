import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { StorageKey } from 'common/enums/enums';
import { UserSignUpRequestDto } from 'common/types/services/api/user/user-sign-up-request-dto.type';
import { useNavigate } from 'react-router-dom';
import { signUp } from 'services/api/auth-api.service';
import { storage } from 'services/services';
import useRequestPhoneConfirmation from '../user/use-request-phone-confirmation';

export default function useSignUp(
  options?: Omit<UseMutationOptions<string, Error, UserSignUpRequestDto, unknown>, 'mutationFn'>,
) {
  const navigate = useNavigate();

  const { mutateAsync: requestPhoneConfirmation } = useRequestPhoneConfirmation({
    onSuccess: (_, registrationId) => {
      storage.setItem(StorageKey.REGISTRATION_ID, registrationId);
      navigate('/auth/sign-up/phone-confirmation');
    },
  });

  return useMutation({
    mutationFn: signUp,
    onSuccess: async (registrationId) => {
      await requestPhoneConfirmation(registrationId);
    },
    ...options,
  });
}
