import { RenterPaymentDetailsForm, RenterPersonalDetailsForm, RenterVerificationDetailsForm } from 'legacy-pages/pages';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import { UserIncompleteProfileAlert } from 'components/organisms/Alert/UserIncompleteProfileAlert';
import { RenterProfileInfoStepper } from 'components/organisms/Renter/RenterProfileInfoStepper/RenterProfileInfoStepper';

const RenterProfileInformation = () => {
  return (
    <div className='content grow px-2 md:px-0 md:pr-11'>
      <div className='flex justify-between items-center mb-2 lg:mb-9 px-4 md:px-[34px]'>
        <div className='title text-xl font-bold'>Profile Information</div>
      </div>
      <div className='mb-3'>
        <UserIncompleteProfileAlert />
      </div>
      <div className='flex w-full justify-between gap-6 lg:flex-row flex-col-reverse'>
        <Suspense fallback={<div className='flex flex-1 w-full h-[500px] shadow-white-xl' />}>
          <Routes>
            <Route
              path={'/'}
              element={
                <div className='flex flex-1 w-full h-[500px] shadow-white-xl'>
                  <Navigate to={'personal'} replace />
                </div>
              }
            />
            <Route path='personal' index element={<RenterPersonalDetailsForm />} />
            <Route path='payment-details' element={<RenterPaymentDetailsForm />} />
            <Route path='verification' element={<RenterVerificationDetailsForm />} />
          </Routes>
        </Suspense>

        <div>
          <div className='stepper flex flex-col pl-6 lg:pl-0'>
            <RenterProfileInfoStepper />
          </div>
        </div>
      </div>
    </div>
  );
};

export { RenterProfileInformation };
