import {
  PropertyAmenitiesDto,
  PropertyCreateAccessFileDto,
  PropertyCreateBasicInfoRequestDto,
  PropertyCreateDocumentRequestDto,
  PropertyCreateUnitAccessFileDto,
  PropertyCreateUnitRequestDto,
  PropertyDeleteAccessFileDto,
  PropertyDeleteUnitAccessFileDto,
  PropertyDeleteUnitRequestDto,
  PropertyUpdateAccessKeyDto,
  PropertyUpdateAccessRequestDto,
  PropertyUpdateAccessScheduleDto,
  PropertyUpdateAmenitiesRequestDto,
  PropertyUpdatePlanRequestDtoType,
  PropertyUpdateUnitAccessKeyDto,
  PropertyUpdateUnitAccessRequestDto,
  UnitPropertyUpdateAccessScheduleDto,
} from 'common/types/types';
import { PropertyApiPath } from 'common/enums/services/api/paths/property-api-path.enum';
import { PropertyEstateRentSpecialsDto, PropertyUnitRentSpecialsDto } from 'common/types/services/api/api';
import {
  DeletePropertyEstateRentSpecialsRequest,
  DeletePropertyUnitRentSpecialsRequest,
} from 'common/types/services/api/property/property-rent-specials-dto.type';
import { axiosInstance } from './axiosInstance';
import { EstateImageDto } from 'common/generated/types/estate-image-dto';
import { PropertyUpdateCreationStepDto } from 'common/types/services/api/property/property-update-creation-step-dto.type';
import { CreateRentEstateRequest } from 'common/generated/types/create-rent-estate-request';

export function createPropertyAddress(data: CreateRentEstateRequest): Promise<string> {
  return axiosInstance.post(`${PropertyApiPath.CREATE_PROPERTY_ADDRESS}`, data);
}

export function updatePropertyAddress(data: CreateRentEstateRequest & { id: string }): Promise<string> {
  return axiosInstance.put(`${PropertyApiPath.CREATE_PROPERTY_ADDRESS}/${data.id}`, data);
}

export function createPropertyUnit({ estateId, ...unit }: PropertyCreateUnitRequestDto): Promise<string> {
  return axiosInstance.post(`${PropertyApiPath.CREATE_PROPERTY_UNIT}/${estateId}/unit`, unit);
}

export function updatePropertyUnit({ estateId, ...unit }: PropertyCreateUnitRequestDto): Promise<string> {
  return axiosInstance.put(`${PropertyApiPath.UPDATE_PROPERTY_UNIT}/${estateId}/unit/${unit.id}`, unit);
}

export function deletePropertyUnit({ estateId, id }: PropertyDeleteUnitRequestDto): Promise<string> {
  return axiosInstance.delete(`${PropertyApiPath.UPDATE_PROPERTY_UNIT}/${estateId}/unit/${id}`);
}

export function updatePropertyPlan({ estateId, plan }: PropertyUpdatePlanRequestDtoType): Promise<boolean> {
  return axiosInstance.put(`${PropertyApiPath.UPDATE_PROPERTY_PLAN}/${estateId}/plan`, { plan });
}

export function updatePropertyAccessKey({ estateId, ...payload }: PropertyUpdateAccessKeyDto): Promise<boolean> {
  return axiosInstance.put(`${PropertyApiPath.UPDATE_PROPERTY_ACCESS}/${estateId}/access`, payload);
}

export function updatePropertyAccess({ accessKeyData }: PropertyUpdateAccessRequestDto): Promise<any> {
  return Promise.all([updatePropertyAccessKey(accessKeyData)]);
}

export function updatePropertyUnitAccessKey({
  estateId,
  unitId,
  ...payload
}: PropertyUpdateUnitAccessKeyDto): Promise<boolean> {
  return axiosInstance.put(`${PropertyApiPath.UPDATE_PROPERTY_ACCESS}/${estateId}/unit/${unitId}/access`, payload);
}

export function updatePropertyUnitAccess({ accessKeyData }: PropertyUpdateUnitAccessRequestDto): Promise<any> {
  return Promise.all([updatePropertyUnitAccessKey(accessKeyData)]);
}

export function updatePropertyEstateRentalSpecials({
  estateId,
  ...payload
}: PropertyEstateRentSpecialsDto): Promise<boolean> {
  return axiosInstance.put(`${PropertyApiPath.UPDATE_PROPERTY_RENTAL_SPECIALS}/${estateId}/special`, payload);
}

export function deletePropertyEstateRentalSpecials({
  estateId,
}: DeletePropertyEstateRentSpecialsRequest): Promise<boolean> {
  return axiosInstance.delete(`${PropertyApiPath.UPDATE_PROPERTY_RENTAL_SPECIALS}/${estateId}/special`);
}

export function deletePropertyUnitRentalSpecials({
  estateId,
  unitId,
}: DeletePropertyUnitRentSpecialsRequest): Promise<boolean> {
  return axiosInstance.delete(`${PropertyApiPath.UPDATE_PROPERTY_RENTAL_SPECIALS}/${estateId}/unit/${unitId}/special`);
}

export function updatePropertyUnitRentalSpecials({
  estateId,
  unitId,
  ...payload
}: PropertyUnitRentSpecialsDto): Promise<boolean> {
  return axiosInstance.put(
    `${PropertyApiPath.UPDATE_PROPERTY_RENTAL_SPECIALS}/${estateId}/unit/${unitId}/special`,
    payload,
  );
}

export function createPropertyAccessFile({ estateId, file }: PropertyCreateAccessFileDto): Promise<boolean> {
  const formData = new FormData();
  formData.append('file', file);
  return axiosInstance.post(`${PropertyApiPath.CREATE_PROPERTY_ACCESS_FILE}/${estateId}/access/file`, formData);
}

export function deletePropertyAccessFile({ estateId, fileId }: PropertyDeleteAccessFileDto): Promise<boolean> {
  return axiosInstance.delete(`${PropertyApiPath.CREATE_PROPERTY_ACCESS_FILE}/${estateId}/access/file/${fileId}`);
}

export function deletePropertyUnitAccessFile({
  estateId,
  fileId,
  unitId,
}: PropertyDeleteUnitAccessFileDto): Promise<boolean> {
  return axiosInstance.delete(
    `${PropertyApiPath.CREATE_PROPERTY_ACCESS_FILE}/${estateId}/unit/${unitId}/access/file/${fileId}`,
  );
}

export function createPropertyUnitAccessFile({
  estateId,
  unitId,
  file,
}: PropertyCreateUnitAccessFileDto): Promise<boolean> {
  const formData = new FormData();
  formData.append('file', file);
  return axiosInstance.post(
    `${PropertyApiPath.CREATE_PROPERTY_ACCESS_FILE}/${estateId}/unit/${unitId}/access/file`,
    formData,
  );
}

export function updatePropertyScheduler({ estateId, ...payload }: PropertyUpdateAccessScheduleDto): Promise<boolean> {
  return axiosInstance.put(`${PropertyApiPath.UPDATE_PROPERTY_SCHEDULER}/${estateId}/schedule`, payload);
}

export function updateUnitPropertyScheduler({
  estateId,
  unitId,
  ...payload
}: UnitPropertyUpdateAccessScheduleDto): Promise<boolean> {
  return axiosInstance.put(`${PropertyApiPath.UPDATE_PROPERTY_SCHEDULER}/${estateId}/unit/${unitId}/schedule`, payload);
}

export function changeOrderPropertyImages(estateId: string, data: EstateImageDto['id'][]) {
  return axiosInstance.put(`${PropertyApiPath.UPLOAD_PROPERTY_IMAGES}/${estateId}/order`, data);
}

export function uploadPropertyImage(estateId: string, file: File, isDefault?: boolean): Promise<EstateImageDto> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('estateId', estateId);
  if (isDefault) {
    formData.append('isDefault', 'true');
  }
  return axiosInstance.post(`${PropertyApiPath.UPLOAD_PROPERTY_IMAGES}`, formData);
}

export function updateDescription(estateId: string, value: { description: string }) {
  return axiosInstance.put(`${PropertyApiPath.CREATE_PROPERTY_UNIT}/${estateId}/description`, value);
}

export function updatePropertyCreationStep(data: PropertyUpdateCreationStepDto): Promise<void> {
  return axiosInstance.put(`${PropertyApiPath.CREATE_PROPERTY_UNIT}/${data.estateId}/step`, { step: data.step });
}

export function updatePropertyAmenities({ estateId, amenities }: PropertyUpdateAmenitiesRequestDto): Promise<boolean> {
  return axiosInstance.put(`${PropertyApiPath.UPDATE_PROPERTY_AMENITIES}/${estateId}/amenities`, { amenities });
}

export function setMainPropertyImage(estateId: string, fileId: string, isDefault: boolean): Promise<string> {
  return axiosInstance.put(`${PropertyApiPath.UPLOAD_PROPERTY_IMAGES}/${estateId}/file/${fileId}`, {
    isDefault: isDefault,
  });
}

export function getPropertyAmenities(): Promise<PropertyAmenitiesDto[]> {
  return axiosInstance.get(`${PropertyApiPath.GET_PROPERTY_AMENITIES}`);
}

export function createPropertyDocument({ estateId, file }: PropertyCreateDocumentRequestDto): Promise<string> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('estateId', estateId);
  return axiosInstance.post(`${PropertyApiPath.CREATE_PROPERTY_DOCUMENT}`, formData);
}

export function createPropertyDocuments(documents: PropertyCreateDocumentRequestDto[]): Promise<string[]> {
  return Promise.all(documents.map((document) => createPropertyDocument(document)));
}

export function addOneLeaseDocumentToProperty(document: PropertyCreateDocumentRequestDto): Promise<string> {
  const formData = new FormData();
  formData.append('estateId', document.estateId);
  formData.append('leaseId', document.leaseId);
  formData.append('file', document.file);
  formData.append('isTemplate', document.isTemplate.toString());
  formData.append('title', document.title);
  return axiosInstance.post(`${PropertyApiPath.CREATE_PROPERTY_DOCUMENT}`, formData);
}

export function publishProperty(estateId: string): Promise<boolean> {
  return axiosInstance.put(`${PropertyApiPath.PUBLISH_PROPERTY}/${estateId}/publish`);
}

export function getPropertyForEdit(id: string): Promise<any> {
  return axiosInstance.get(`${PropertyApiPath.GET_LISTING}/${id}`);
}

export function deletePropertyImage(data: any): Promise<any> {
  return axiosInstance.delete(`${PropertyApiPath.DELETE_PROPERTY_IMAGE}/${data.estateId}/file/${data.id}`);
}
