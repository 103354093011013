import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { TourBillingPaymentRequestDto } from 'common/types/services/api/tours/tour-billing-request-dto.type';
import { TourPaymentIntentDto } from 'common/types/services/api/tours/tour-payment-intent-dto.type';
import { getTourPayment } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useTourPayment(
  params: TourBillingPaymentRequestDto,
  options?: Omit<
    UndefinedInitialDataOptions<
      TourPaymentIntentDto,
      Error,
      TourPaymentIntentDto,
      (string | TourBillingPaymentRequestDto)[]
    >,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    ...options,
    queryKey: [QueryKey.Tours, params, 'payment'],
    queryFn: () => getTourPayment(params),
  });
}
