export const uploadFileConfig = {
  images: {
    title: 'Photos must be in png, jpeg, jpg, heic/heif (on iphone) format and no larger than 75MB in size',
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'image/heic': ['.heic'], // - converts to jpeg
      'image/heif': ['.heif'], // - converts to jpeg
    },
  },
  pdf: {
    title: 'Files must be in PDF format and no larger than 75MB in size',
    accept: {
      'application/pdf': ['.pdf'],
    },
  },
  images_and_pdf: {
    title: 'Files must be in PDF, png, jpeg, jpg, heic/heif (on iphone) format and no larger than 75MB in size',
    accept: {
      'application/pdf': ['.pdf'],
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'image/heic': ['.heic'], // - converts to jpeg
      'image/heif': ['.heif'], // - converts to jpeg
    },
  },
};
