import { CreateSavedSearchFiltersDto } from 'common/types/services/api/user/user-saved-search.type';
import { SavedSearchFilters } from './SavedSearchModal.types';
import { formatPriceValue } from 'helpers/string.helpers';

export function createStringFromObject(obj: CreateSavedSearchFiltersDto): string {
  const values = [
    obj.title,
    obj.location,
    obj.street,
    obj.state,
    obj.city,
    obj.zip,
    obj.beds ? `${obj.beds} Bedrooms` : '',
    obj.baths ? `${obj.baths} Bathrooms` : '',
    obj.estateType,
    obj.minPrice ? `Min price: ${formatPriceValue(obj.minPrice)}` : '',
    obj.maxPrice ? `Max price: ${formatPriceValue(obj.maxPrice)}` : '',
    obj.category,
  ];

  return values.filter(Boolean).join(', ');
}

export function createFilteredObject(obj: CreateSavedSearchFiltersDto): Record<keyof SavedSearchFilters, string> {
  const result: any = {};

  if (obj.location) result.location = obj.location;
  if (obj.street) result.street = obj.street;
  if (obj.state) result.state = obj.state;
  if (obj.city) result.city = obj.city;
  if (obj.zip) result.zip = obj.zip;
  if (obj.beds) result.beds = obj.beds;
  if (obj.baths) result.bathrooms = obj.baths;
  if (obj.estateType) result.type = obj.estateType;
  if (obj.minPrice) result.minPrice = obj.minPrice;
  if (obj.maxPrice) result.maxPrice = obj.maxPrice;
  if (obj.category) result.categories = obj.category.replace(/\s+/g, '');

  return result;
}
