import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { paymentPendingEstate } from 'services/api/estates-api.service';
import { notification } from 'services/services';

export default function usePaymentPendingEstate(
  options?: Omit<UseMutationOptions<void, Error, string, unknown>, 'mutationFn'>,
) {
  return useMutation({
    mutationFn: paymentPendingEstate,
    onError: (e) => {
      notification.error(e.message || 'Something went wrong');
    },
    ...options,
  });
}
