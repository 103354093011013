import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { TourDto } from 'common/types/types';
import dayjs from 'dayjs';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { Button, Loader, Modal } from 'legacy-components/componets';
import { FC, useEffect, useMemo, useState } from 'react';
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { twMerge } from 'tailwind-merge';
import { TimeSlot } from './time-slot';
import { useAvailableTours, useEstateDetails } from 'hooks/query';
import { getDateWithZeroHours } from 'helpers/date';

type FreeRescheduleTourProps = {
  open: boolean;
  onClose: () => void;
  styles?: Record<string, any>;
  handleChangeDate: (date: Date) => void;
  onMonthChange: (newMonth: Date) => void;
  getTimeSlot: () => any;
  getSelectedDate: () => any;
  openSecondModal: () => void;
  startDate: Date;
  tour: TourDto;
};

export const FreeRescheduleTour: FC<FreeRescheduleTourProps> = ({
  open,
  onClose,
  styles,
  handleChangeDate,
  onMonthChange,
  getTimeSlot,
  getSelectedDate,
  openSecondModal,
  startDate,
  tour,
}) => {
  const { isMobile } = useWindowSize();
  const [selectedTimeSlot, setSelectedTimeSlot] = getTimeSlot();
  const [selectedDate, setSelectedDate] = getSelectedDate();
  const [calendarDate, setCalendarDate] = useState<Date | null>(null);
  const { data: availableTours, isLoading: isAvailableToursLoading } = useAvailableTours(
    {
      id: tour.estateId,
      forDate: startDate.toDateString(),
      unitId: tour.unitId,
    },
    { enabled: open },
  );

  const { data: estateData, isLoading: isEstateInfoLoading } = useEstateDetails(tour?.estateId);
  useEffect(() => {
    const unitData = estateData?.units?.find((unit) => unit?.id === tour?.unitId);
    const startCalendDate = dayjs(unitData?.availableOn).isAfter(getDateWithZeroHours())
      ? dayjs(unitData?.availableOn).toDate()
      : getDateWithZeroHours();
    setCalendarDate(startCalendDate);
  }, [estateData]);

  const dayClassName = (date: Date) => {
    const isActive = dayjs(date).isSame(selectedDate, 'day');
    const disabled = availableDates.every((d) => !dayjs(date).isSame(d, 'day'));

    return twMerge(
      'flex-center w-10 h-[33px] text-sm hover:bg-secondary hover:rounded-[23px] text-neutral-500 font-bold outline-none m-0 bg-white mb-3',
      (disabled || isAvailableToursLoading) && 'text-stone-300 hover:bg-white',
      isActive && 'bg-primary text-white rounded-[23px] hover:bg-primary',
    );
  };

  const availableSlotsBySelectedDate = useMemo(
    () => (availableTours || []).find((item) => item.date === dayjs(selectedDate).format('YYYY-MM-DD')),
    [selectedDate, availableTours],
  );

  const renderCustomHeader = ({ date, decreaseMonth, increaseMonth }: ReactDatePickerCustomHeaderProps) => {
    return (
      <div className={'flex justify-between items-center mb-[24px]'}>
        <div className={'text-neutral-800 text-base font-semibold leading-tight flex gap-2.5'}>
          <span>{dayjs(date).format('MMMM')}</span>
          <span>{dayjs(date).format('YYYY')}</span>
        </div>
        <div className={'flex gap-2.5'}>
          <button onClick={decreaseMonth}>
            <LeftOutlined className={'text-zinc-800 text-base font-normal'} />
          </button>
          <button onClick={increaseMonth}>
            <RightOutlined className={'text-zinc-800 text-base font-normal'} />
          </button>
        </div>
      </div>
    );
  };

  const renderDayContents = (day: number, date: Date) => {
    const isNotAvailable = date > new Date() && availableDates.every((d) => !dayjs(date).isSame(d, 'day'));

    return (
      <div className={'relative'}>
        {day}
        {isNotAvailable && (
          <span className={`absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-red-500`}>
            <CloseOutlined className={'text-[18px]'} />
          </span>
        )}
      </div>
    );
  };

  const availableDates = useMemo(
    () => (availableTours || []).map(({ date }) => dayjs(date).toDate()),
    [availableTours],
  );

  useEffect(() => {
    setSelectedDate(availableDates[0] || null);
  }, [availableDates]);

  return (
    <Modal
      onClose={onClose}
      isOpen={open}
      styles={{
        ...styles,
        content: {
          ...styles?.content,
          maxHeight: '100%',
          ...(isMobile && { height: '100%' }),
        },
      }}
    >
      <div className='md:w-[449px] bg-white rounded-lg p-4 md:p-8 md:max-w-full w-screen md:h-auto relative flex flex-col gap-9'>
        <div className='flex justify-between items-center'>
          <div className='w-6 h-6' />
          <h3 className='text-xl whitespace-nowrap text-center text-neutral-800 text-[40px] font-semibold leading-10'>
            Select a new Date and Time
          </h3>
          <button onClick={onClose}>
            <CloseOutlined className={'text-primary text-[24px]'} />
          </button>
        </div>
        <div className={'schedule-calendar relative bg-white select-none h-[346px] flex justify-center'}>
          {isEstateInfoLoading ? (
            <Loader />
          ) : (
            <DatePicker
              onMonthChange={onMonthChange}
              selected={calendarDate}
              renderDayContents={renderDayContents}
              renderCustomHeader={renderCustomHeader}
              weekDayClassName={() => 'w-10 text-neutral-500 text-sm font-semibold leading-[16.80px] m-0 mb-3'}
              dayClassName={dayClassName}
              calendarClassName={'border-0 font-manrope'}
              onChange={handleChangeDate}
              inline
              includeDates={availableDates}
            />
          )}
        </div>
        <div>
          {isAvailableToursLoading ? (
            <div className={'flex justify-center h-[253px]'}>
              <Loader />
            </div>
          ) : (
            <>
              {selectedDate && (
                <div className={'text-neutral-500 text-sm font-medium leading-tight mb-3'}>
                  {dayjs(selectedDate).format('dddd, MMMM D')}
                </div>
              )}
              <div className={'grid grid-cols-2 gap-3 max-h-[224px] overflow-y-auto scrollbar pr-2'}>
                {availableSlotsBySelectedDate?.slots.map((slot) => (
                  <TimeSlot
                    key={slot}
                    slot={slot}
                    active={selectedTimeSlot === slot}
                    onClick={() => {
                      setSelectedTimeSlot(slot);
                    }}
                  />
                ))}
              </div>
            </>
          )}
        </div>
        <Button
          disabled={!selectedTimeSlot || !selectedDate}
          label={'Confirm Schedule'}
          onClick={() => {
            openSecondModal();
          }}
          className={'w-full'}
        />
      </div>
    </Modal>
  );
};
