import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { TourDetailsDto } from 'common/types/services/api/tours/tour-details-dto.type';
import { getTourDetails } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';
import { useQueryCustomErrorHandler } from 'hooks/query/use-query-custom-error-handler';

export default function useTourDetails(
  tourId: string,
  options?: Omit<UndefinedInitialDataOptions<TourDetailsDto | undefined, Error, TourDetailsDto, string[]>, 'queryFn' | 'queryKey'>,
) {
  const { onError } = useQueryCustomErrorHandler();
  return useQuery({
    ...options,
    queryKey: [QueryKey.Tours, tourId],
    queryFn: () => getTourDetails(tourId).catch(onError),
  });
}
