import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { SavedSearchUnreadResult } from 'common/types/services/api/user/user-saved-search.type';
import { getSavedSearchUnreadCount } from 'services/api/saved-search-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useSavedSearchUnreadCount(
  options?: Omit<
    UndefinedInitialDataOptions<SavedSearchUnreadResult, Error, SavedSearchUnreadResult, string[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.SavedSearch, 'unread-count'],
    queryFn: () => getSavedSearchUnreadCount(),
    ...options,
  });
}
