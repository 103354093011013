import { LandlordUnconfirmedToursAlert } from 'components/organisms/Alert/LandlordUnconfirmedToursAlert';
import { Outlet } from 'react-router-dom';

const LandlordLayout = () => {
  return (
    <div className='landlord-container grow'>
      <LandlordUnconfirmedToursAlert />
      <Outlet />
    </div>
  );
};

export { LandlordLayout };
