import * as Yup from 'yup';
import { emailValidation } from './email.validation';

export const investorSignUpValidationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phone: Yup.string().required('Phone is required'),
    email: emailValidation,
    companyName: Yup.string().required('Company name is required'),
    companyWebSite: Yup.string().required('Company website is required'),
    checkIfInvestor: Yup.string().test('Investor-state-is-must', 'Please fill out this field', function (value) {
        const checkIfInvestor = this.resolve(Yup.ref('checkIfInvestor')) as string;
        if (checkIfInvestor === 'yes' || checkIfInvestor === 'no') return true;
        else return false;
    }),
});
