import { SelectOption } from 'common/types/types';
import { Label, Select } from 'legacy-components/componets';

export type EstateUnitSelectionProps = {
  isMultiUnit: boolean;
  options: SelectOption[];
  value: string;
  selectCallback: (value: string) => void;
};

const EstateUnitSelection = ({ options, value, isMultiUnit, selectCallback }: EstateUnitSelectionProps) => {
  return isMultiUnit ? (
    <div className='max-w-[180px] w-full mb-10'>
      <Label label={'Select Unit'}>
        <Select name='UnitSelect' options={options} value={value} onChange={selectCallback} />
      </Label>
    </div>
  ) : null;
};

export { EstateUnitSelection };
