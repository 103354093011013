import React, { useMemo } from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import { CarouselProps } from 'common/types/types';
import ArrowLeft from './arrow-left';
import ArrowRight from './arrow-right';
import { getStatusStyles, statusText } from './carousel.utils';

const Carousel = ({ images, height, arrowSize = 20, arrowColor, status, onImageError, ...carouselProps }: CarouselProps) => {

  const specificStatus = useMemo(() => {
    if (status !== undefined) {
      return getStatusStyles(status);
    }
  }, [status]);
  const renderImages = (() => {
    return images.map((img, index) => (
      <div key={index} className={`${specificStatus?.isActive && specificStatus?.styles?.div} w-full h-full`}>
        <img
          key={index}
          src={img}
          style={{ height }}
          className={`overflow-hidden object-cover object-center w-full h-full ${specificStatus?.isActive && specificStatus?.styles?.img}`}
          onError={onImageError}
          alt='gallery'
          aria-label={`Image ${index + 1}`}
        />
        <p className={`${specificStatus?.isActive && specificStatus?.styles?.text}`}>
          {status && specificStatus?.isActive && statusText[status]}
        </p>
      </div>
    ));
  })();

  const showIndicators = images.length > 1;

  const handleArrowClick = (e: React.MouseEvent<HTMLDivElement>, clickHandler: () => void) => {
    e.stopPropagation();
    clickHandler();
  };

  return (
    <ResponsiveCarousel
      showIndicators={showIndicators}
      showStatus={false}
      showArrows={true}
      showThumbs={false}
      renderArrowPrev={(clickHandler, hasNext) =>
        hasNext && (
          <ArrowLeft
            color={arrowColor}
            height={arrowSize}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => handleArrowClick(e, clickHandler)}
          />
        )
      }
      renderArrowNext={(clickHandler, hasNext) =>
        hasNext && (
          <ArrowRight
            color={arrowColor}
            height={arrowSize}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => handleArrowClick(e, clickHandler)}
          />
        )
      }
      {...carouselProps}
    >
      {renderImages}
    </ResponsiveCarousel>
  );
};

export { Carousel };
