import { AdminProfileInformationForm } from './form';

const AdminProfileInformation = () => {
  return (
    <div className='content grow px-2 md:px-0 md:pr-11'>
      <div className='profile-info-header flex items-center mb-2 lg:mb-9 px-4 md:px-[34px]'>
        <div className='title text-xl font-bold'>Profile Information</div>
      </div>
      <div className='flex w-full'>
        <AdminProfileInformationForm />
      </div>
    </div>
  );
};

export { AdminProfileInformation };
