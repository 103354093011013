import { CSSProperties } from 'react';
import { useWindowSize } from 'hooks/use-windows-size.hook';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal } from 'legacy-components/componets';

type ConfirmEscapingModalProps = {
  onClose: () => void;
  open: boolean;
  onContinue: () => void;
  onCancel: () => void;
  styles?: {
    overlay?: CSSProperties;
    content?: CSSProperties;
  };
};

export const ConfirmEscapingModal = ({ open, onCancel, onClose, onContinue, styles }: ConfirmEscapingModalProps) => {
  const { isMobile } = useWindowSize();

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      styles={{
        ...styles,
        content: {
          ...styles?.content,
          maxHeight: '100%',
          ...(isMobile && { height: '100%' }),
        },
      }}
    >
      <div className='md:w-[715px] bg-white rounded-lg p-4 md:p-8 md:max-w-full w-screen md:h-auto relative flex flex-col gap-9'>
        <div className='flex justify-between items-center'>
          <div className='w-6 h-6' />
          <h3 className='text-xl whitespace-nowrap text-center text-neutral-800 text-[40px] font-semibold leading-10'>
            Are you sure you want to close the renting process?
          </h3>
          <button onClick={onClose}>
            <CloseOutlined className={'text-primary text-[24px]'} />
          </button>
        </div>
        <p>
          You've made progress in your rental application. Closing this process now will discard any unsaved changes. If
          you intend to continue, please click "Cancel"
        </p>
        <div className={'flex justify-between'}>
          <Button label={'Close anyway'} theme={'red-text'} onClick={onCancel} />
          <Button label={'Continue renting process'} onClick={onContinue} />
        </div>
      </div>
    </Modal>
  );
};
