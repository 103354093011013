export type TextCellProps = string;
export type AdminAction = () => void;

export type AdminActionsCell = {
  onEdit: AdminAction;
  onCancel: AdminAction;
  city: string | null;
  state: string | null;
};

export enum AdminCommissionTableAccessorEnum {
  State = 'state',
  City = 'city',
  Amount = 'amount',
  Actions = 'actions',
}

export type AdminCommissionTableRow = {
  [AdminCommissionTableAccessorEnum.State]: TextCellProps;
  [AdminCommissionTableAccessorEnum.City]: TextCellProps;
  [AdminCommissionTableAccessorEnum.Amount]: TextCellProps;
  [AdminCommissionTableAccessorEnum.Actions]: AdminActionsCell;
};
