import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { OffersDto } from 'common/types/services/api/offers/offers-dto.type';
import { OffersRequestDto } from 'common/types/services/api/offers/offers-request-dto.type';
import { getRenterOffers } from 'services/api/offers-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useRenterOffers(
  params: OffersRequestDto,
  options?: Omit<
    UndefinedInitialDataOptions<OffersDto, Error, OffersDto, (string | OffersRequestDto)[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Offers, 'renter', params],
    queryFn: () => getRenterOffers(params),
    ...options,
  });
}
