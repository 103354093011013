import { Header } from 'legacy-components/componets';
import { Fragment } from 'react';

const NotFound = () => {
  return (
    <Fragment>
      <Header />
      <div className='bg-secondary w-full h-screen flex items-center justify-center flex-col'>
        <div className='text-9xl text-primary'>404</div>
        <div className='text-xl text-primary'>Oops, page not found</div>
      </div>
    </Fragment>
  );
};

export default NotFound;
