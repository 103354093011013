import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { sendUserVerificationCode } from 'services/api/auth-api.service';

export default function useSendUserVerificationCode(
  options?: UseMutationOptions<any, Error, string, unknown>,
) {
  return useMutation({
    mutationFn: sendUserVerificationCode,
    ...options,
  });
}
