import React, { ReactNode } from 'react';
import { EstateAmenitiesIcon } from './EstateAmenitiesIcon';
import { AmenitiesIconName, amenitiesMapping } from 'common/types/components/ui/icon/amenities-icon.type';

export type EstateAmenitiesItem = {
  name: string;
  icon: AmenitiesIconName;
};

export type EstateAmenitiesProps = {
  amenities: EstateAmenitiesItem[];
};

const EstateAmenities = ({ amenities }: EstateAmenitiesProps) => {
  const propertyItems = React.useMemo<ReactNode>(() => {
    return amenities.map((amenity, index) => (
      <div key={index} className='amenity flex flex-col xs:flex-row items-center text-base gap-[14px]'>
        <EstateAmenitiesIcon name={amenitiesMapping(amenity.name)} />
        <div className='amenity-name text-trueGray'>{amenity.name}</div>
      </div>
    ));
  }, [amenities]);

  return (
    <section className='amenity-data'>
      <div className='amenity-title flex justify-between pb-12'>
        <h5 className='font-bold text-black text-[22px]'>Amenities</h5>
      </div>
      <div className='amenity-list grid lg:grid-cols-3 grid-cols-2 grid-flow-row gap-x-[48px] gap-y-[16px]'>
        {propertyItems}
      </div>
    </section>
  );
};

export { EstateAmenities };
