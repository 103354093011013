import React from 'react';
import menuIcon from 'assets/icons/menu.svg';

const BurgerMenu = () => (
  <div className='flex-center md:hidden w-9 h-9'>
    <img src={menuIcon} alt='Menu' />
  </div>
);

export default BurgerMenu;
