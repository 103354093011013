import { NotificationApiPath } from 'common/enums/enums';
import { NotificationsDto, PaginationDtoRequest } from 'common/types/types';
import { SubscribeEmailNotificationDto } from 'common/types/services/api/api';
import { ContactUsDto, FeedbacksListRequest } from 'common/types/services/api/notification/notifications-dto.type';
import { axiosInstance } from './axiosInstance';
import qs from 'qs';
import { ContactUsFormDtoPagedResults } from 'common/generated/types/contact-us-form-dto-paged-results';

export const getNotifications = (query: PaginationDtoRequest): Promise<NotificationsDto> => {
  return axiosInstance.get(`${NotificationApiPath.GET_NOTIFICATIONS}?${qs.stringify(query)}`);
};

export const getFeedbacksList = (query: FeedbacksListRequest): Promise<ContactUsFormDtoPagedResults> => {
  const queryString = qs.stringify(
    {
      filters: {
        status: query.filters?.status ? query.filters.status.map((s) => `OR%${s}`) : undefined,
        title: query.filters?.title ? `OR%${query.filters?.title}` : undefined,
      },
      page: query.page,
      perPage: query.perPage,
      sorters: query.sorters,
    },
    { encode: false, arrayFormat: 'repeat' },
  );

  return axiosInstance.get(`${NotificationApiPath.CONTACT_US}?${queryString}`);
};

export const setDoneFeedback = (contactUsFormId: string): Promise<any> => {
  return axiosInstance.put(`${NotificationApiPath.CONTACT_US}/${contactUsFormId}/done`);
};

export const setInProgressFeedback = (contactUsFormId: string): Promise<any> => {
  return axiosInstance.put(`${NotificationApiPath.CONTACT_US}/${contactUsFormId}/inProgress`);
};

export const setNewFeedback = (contactUsFormId: string): Promise<any> => {
  return axiosInstance.put(`${NotificationApiPath.CONTACT_US}/${contactUsFormId}/new`);
};

export const readNotifications = (): Promise<boolean> => {
  return axiosInstance.put(`${NotificationApiPath.READ_NOTIFICATIONS}`);
};

export const readNotification = (notificationId: string): Promise<boolean> => {
  return axiosInstance.put(`${NotificationApiPath.READ_NOTIFICATIONS}/${notificationId}`);
};

export const subscribeEmailNotification = (query: SubscribeEmailNotificationDto): Promise<any> => {
  return axiosInstance.post(`${NotificationApiPath.SUBSCRIBE_EMAIL}?${qs.stringify(query)}`);
};

export const contactUs = (payload: ContactUsDto): Promise<boolean> => {
  return axiosInstance.post(`${NotificationApiPath.CONTACT_US}`, payload);
};
