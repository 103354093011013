import React, { useState } from 'react';
import { Badge, Loader, NotificationItem } from 'legacy-components/componets';
import { ReactComponent as BellIcon } from 'assets/icons/black-bell.svg';
import styles from './styles';
import Dropdown from 'rc-dropdown';
import DoubleCheckIcon from 'legacy-components/double-check-icon/double-check-icon';
import { twMerge } from 'tailwind-merge';
import { useNotifications, useReadNotifications } from 'hooks/query';

export const NotificationButton = () => {
  const maxNotificationsAmount = 2;
  const [visible, setVisible] = useState(false);
  const [isAllNotificationsView, setIsAllNotificationsView] = useState(false);
  const params = {
    perPage: isAllNotificationsView ? undefined : maxNotificationsAmount,
  };
  const { data: notifications, isLoading } = useNotifications(params);
  const { mutate: readNotifications } = useReadNotifications(params);

  const totalUnread = notifications ? notifications.totalUnread : 0;
  const totalCount = notifications ? notifications.totalCount : 0;

  const overlay = (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`flex flex-col max-w-[300px] md:max-w-[450px] gap-4 px-4 py-5 bg-white notifications rounded-lg shadow border border-sky-100 mt-5`}
    >
      {isLoading ? (
        <Loader className={'w-[266px] h-[362px]'} />
      ) : (
        <>
          <div className='flex items-center justify-between text-base font-medium gap-3'>
            <div className='flex gap-2 items-center text-black'>
              <h4 className={'text-slate-950 text-base font-medium leading-normal'}>Notifications</h4>
              {totalUnread > 0 && (
                <Badge className={'px-2.5 py-1 rounded-2xl'} title={`${totalUnread} NEW`} theme={'primary'} />
              )}
            </div>
            {totalUnread > 0 && (
              <button className='flex gap-1 text-primary' onClick={() => readNotifications()}>
                <DoubleCheckIcon />
                <span className='hidden sm:inline-block'>Mark all as read</span>
                <span className='sm:hidden'>Read all</span>
              </button>
            )}
          </div>
          {totalCount === 0 && <span className={'text-center text-sm font-[]'}>There are no notifications</span>}
          <div
            className={twMerge(
              'flex flex-col gap-4',
              isAllNotificationsView && 'max-h-[374px] overflow-y-auto scrollbar pr-2',
            )}
          >
            {notifications?.items.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onClick={() => setVisible(false)}
                params={params}
              />
            ))}
          </div>
          {totalCount > maxNotificationsAmount && !isAllNotificationsView && (
            <div
              className='text-base font-medium text-primary cursor-pointer'
              onClick={() => {
                setIsAllNotificationsView(true);
              }}
            >
              View all notifications
            </div>
          )}
        </>
      )}
    </div>
  );

  return (
    <Dropdown trigger={['click']} overlay={overlay} visible={visible} onVisibleChange={setVisible}>
      <button className={styles.container}>
        {totalUnread > 0 && <div className={styles.counter}>{totalUnread}</div>}
        <BellIcon fill={totalUnread > 0 ? '#0996CE' : '#C4C4C4'} />
      </button>
    </Dropdown>
  );
};
