import { Select } from 'antd';
import { FiltersMenuFormProps } from 'components/molecules/Filters/Filters';
import { FC } from 'react';
import { filtersOptions } from './config';

const LandlordListingsFiltersForm: FC<FiltersMenuFormProps> = ({ values, setFieldValue, id }) => {
  const { category, type, status } = values;

  const getSelectPopupContainer = (): HTMLElement => document.getElementById(id) as HTMLElement;

  return (
    <div className='flex flex-col gap-3 flex-1 w-full'>
      <div className='flex flex-col w-full'>
        <label className={'block mb-2 text-base font-semibold text-black'}>{'Status'}</label>
        <Select
          allowClear
          placeholder='Select status'
          getPopupContainer={getSelectPopupContainer}
          size='large'
          value={status || null}
          onChange={(value: string) => {
            setFieldValue('status', value);
          }}
          options={filtersOptions.status}
        />
      </div>
      <div className='flex flex-col w-full'>
        <label className={'block mb-2 text-base font-semibold text-black'}>{'Category'}</label>
        <Select
          allowClear
          placeholder='Select category'
          getPopupContainer={getSelectPopupContainer}
          size='large'
          value={category || null}
          onChange={(value: string) => {
            setFieldValue('category', value);
          }}
          options={filtersOptions.category}
        />
      </div>
      <div className='flex flex-col w-full'>
        <label className={'block mb-2 text-base font-semibold text-black'}>{'Type'}</label>
        <Select
          allowClear
          placeholder='Select type'
          getPopupContainer={getSelectPopupContainer}
          size='large'
          value={type || null}
          onChange={(value: string) => {
            setFieldValue('type', value);
          }}
          options={filtersOptions.type}
        />
      </div>
    </div>
  );
};

export { LandlordListingsFiltersForm };
