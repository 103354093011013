import { CellProps } from 'react-table';
import { Avatar, Icon } from 'legacy-components/componets';
import { ToursScheduleTableRowType, ToursScheduleTableTenantCellType } from '../types';
import { useHasRoles } from 'hooks';
import { AuthRole } from 'common/enums/enums';

const UpcomingToursTenantCell = ({ value }: CellProps<ToursScheduleTableRowType, ToursScheduleTableTenantCellType>) => {
  const isLandlord = useHasRoles([AuthRole.Landlord]);
  const { name, avatar, address } = value;
  return (
    <div className='flex gap-4 justify-start max-w-[100%]'>
      {avatar && <Avatar src={avatar} height={50} width={50} />}
      <div className='flex flex-col font-medium gap-0.5 max-w-[80%]'>
        <div className='flex items-center gap-1'>
          {isLandlord && <Icon name={'circle-checked'} className='text-[#27AE60]' height={12} width={12} />}
          <span className={'text-base truncate'}>{name}</span>
        </div>
        <span className='text-sm font-medium text-trueGray'>{address}</span>
      </div>
    </div>
  );
};

export { UpcomingToursTenantCell };
