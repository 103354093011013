import React from 'react';
import cn from 'clsx';
import { ToggleProps } from 'common/types/types';

import styles from './styles';

const Toggle = ({
  name,
  value,
  checked,
  theme = 'default',
  label,
  width,
  placeholder,
  invalid,
  onChange,
  stylesConfig,
}: ToggleProps) => {
  const inputClassName = cn(styles[theme].input, invalid && styles[theme].error);

  return (
    <div className={styles[theme].container} style={stylesConfig?.container}>
      <div
        className={'relative flex gap-3 cursor-pointer'}
        onClick={onChange}
        style={{ width, ...stylesConfig?.toggleContainer }}
      >
        <input
          id={name}
          name={name}
          type='checkbox'
          placeholder={placeholder}
          className={inputClassName}
          onChange={onChange}
          value={value}
          checked={checked}
          autoComplete='off'
          style={stylesConfig?.inputStyle}
        />
        <div className={styles[theme].toggle} style={stylesConfig?.toggle} />

        {label && (
          <span className={styles[theme].label} style={stylesConfig?.labelStyle}>
            {label}
          </span>
        )}
      </div>
    </div>
  );
};

export { Toggle };
