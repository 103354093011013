import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { updatePropertyScheduler } from 'services/api/property-api.service';
import { QueryKey } from 'common/enums/query-key';
import { PropertyUpdateAccessScheduleDto } from 'common/types/services/api/property/property-update-access-schedule-dto.type';

export default function useUpdatePropertyScheduler(
  options?: Omit<UseMutationOptions<boolean, Error, PropertyUpdateAccessScheduleDto, unknown>, 'mutationFn'>,
) {
  return useMutation({
    mutationFn: updatePropertyScheduler,
    mutationKey: [QueryKey.PropertyAvailability],
    ...options,
  });
}
