import { Fragment, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { InputProps } from 'common/types/types';
import {
  Avatar,
  Button,
  CropImage,
  FormDescription,
  Input,
  Label,
  Loader,
  Modal,
  PhoneInput,
} from 'legacy-components/componets';
import { personalDetailsFormDescription } from './config';
import { landlordProfileValidation } from 'validations/landlord/landlord-profile.validation';
import { notification } from 'services/services';
import { useUserProfile, useUserProfileValidation } from 'hooks/query';
import { updateUserProfileImage } from 'services/api/auth-api.service';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateCurrentUser, useUpdateCurrentUserPhone, useUpdateUserProfile } from 'hooks/use-user-profile.hook';
import { Notification } from 'services/notification.service';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';
import {
  phoneNumberDisabledButtonStyles,
  phoneNumberDisabledInputStyles,
} from 'common/styles/phoneNumberDisabledStyles';
import { ConfirmModal } from 'components/atoms/ConfirmModal/ConfirmModal';
import useDeleteProfileImage from 'hooks/profile/use-delete-renter-image';
import { RenterPersonalDetailsFormAddressField } from 'legacy-pages/renter/profile/renter-profile/renter-profile-information/renter-personal-details-form/address-field';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import { CustomDatePicker } from 'legacy-components/fields/fields';
import { VerificationErrors } from 'legacy-pages/renter/schedule-tour/config';
import { useVerifyEmail } from 'hooks/query/user/use-verify-email';
import { EmailVerificationModal } from 'legacy-pages/renter/profile/renter-profile/renter-profile-information/renter-personal-details-form/email-verification-modal';
import { EditTwoTone } from '@ant-design/icons';

export type LandlordProfileValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  image: File | null;
  addressField: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  dateOfBirth: string;
};

const formatAddress = (value: { address: string; city: string; state: string; zip: string }) => {
  return [value.address, value.city, value.state, value.zip].filter(Boolean).join(', ');
};

const LandlordPersonalDetailsForm = () => {
  const [isCropImageOpen, setIsCropImageOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const { data: userProfile, isLoading: isUserProfileLoading } = useUserProfile();
  const { data: userProfileVerification } = useUserProfileValidation();
  const [isEmailValid, setEmailValidation] = useState<boolean>(true);
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser({
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'One of these fields is invalid: First Name, Last Name, DoB.');
    },
  });
  // const { mutateAsync: updateUserProfile } = useUpdateUserProfile({
  //   onError: (e) => {
  //     const notification = new Notification();
  //     notification.error(e?.response?.data?.detail || 'User details is not updated');
  //   },
  // });
  const { mutateAsync: updateCurrentUserPhone } = useUpdateCurrentUserPhone({
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'User phone is not updated');
    },
  });
  const { mutateAsync: verifyEmail, isPending: isVerificationOfEmail } = useVerifyEmail({
    onSuccess: () => {
      const notification = new Notification();
      notification.success('The email for verification has been sent to your inbox.');
    },
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'You could not confirm email');
    },
  });
  const queryClient = useQueryClient();
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    submitForm,
    setValues,
    isSubmitting,
    validateForm,
    setTouched,
  } = useFormik<LandlordProfileValues>({
    initialValues: {
      dateOfBirth: '',
      firstName: '',
      lastName: '',
      image: null,
      email: '',
      phoneNumber: '',
      jobTitle: '',
      addressField: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
    },
    validationSchema: landlordProfileValidation,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const getCommonInputProps = (name: keyof Omit<LandlordProfileValues, 'image'>, placeholder: string): InputProps => {
    return {
      name,
      placeholder,
      value: values[name],
      onChange: handleChange,
      invalid: Boolean(errors[name] && touched[name]),
    };
  };

  const handleFormSubmit = async (values: LandlordProfileValues) => {
    await Promise.all([
      updateCurrentUser(values),
      // updateUserProfile(values),
      updateCurrentUserPhone({ phoneNumber: values.phoneNumber }),
    ]);
    // if (values.image) {
    //   await updateUserProfileImage(values.image);
    // }
    // if (!values.image && !imageUrl) {
    //   await removeUserProfileImage();
    // }
    await queryClient.invalidateQueries({ queryKey: ['user'] });
    notification.success('Thank you for updating your profile. Your changes are now saved.');
  };

  const { mutateAsync: deleteProfileImage, isPending: isLoadingRemovingImg } = useDeleteProfileImage({});
  const removeImage = async () => {
    await deleteProfileImage();
    setFieldValue('image', null);
    setImageUrl('');
  };

  const addImage = async (image: File) => {
    await updateUserProfileImage(image);
    setFieldValue('image', image);
    setImageUrl(URL.createObjectURL(image));
    await queryClient.invalidateQueries({ queryKey: ['user'] });
  };

  const handleCloseModal = () => {
    setIsCropImageOpen(false);
  };

  const showError = (fieldName: keyof LandlordProfileValues): boolean => {
    return Boolean(errors[fieldName] && touched[fieldName]);
  };

  const handleCalendarChange = (fieldName: 'dateOfBirth', date: Date | null) => {
    setFieldValue(fieldName, date?.toDateString()).then(() => {
      setTouched({ ...touched, [fieldName]: true }, true);
    });
  };

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, [imageUrl]);

  useEffect(() => {
    if (userProfileVerification?.errors && !userProfileVerification?.isProfileValid) {
      const isEmailNotVerified = userProfileVerification.errors.includes(VerificationErrors.ConfirmEmail);
      isEmailNotVerified ? setEmailValidation(false) : setEmailValidation(true);
    }
  }, [userProfileVerification]);

  useEffect(() => {
    if (!userProfile) return;
    setValues({
      jobTitle: userProfile?.jobTitle ?? '',
      firstName: userProfile?.firstName ?? '',
      lastName: userProfile?.lastName ?? '',
      email: userProfile?.email ?? '',
      phoneNumber: userProfile?.phoneNumber ?? '',
      address: userProfile?.address ?? '',
      state: userProfile?.state ?? '',
      city: userProfile?.city ?? '',
      zipCode: userProfile?.zipCode ?? '',
      addressField: formatAddress({
        address: userProfile.address,
        state: userProfile.state,
        city: userProfile.city,
        zip: userProfile.zipCode,
      }),
      image: null,
      dateOfBirth: userProfile?.dateOfBirth ?? '',
    });
    setImageUrl(userProfile.thumbnailUrl);
  }, [userProfile]);

  useEffect(() => {
    validateForm();
  }, [values.addressField]);

  return (
    <div className='flex flex-col flex-1 gap-0 md:gap-9 mb-5'>
      <Modal isOpen={isCropImageOpen} onClose={handleCloseModal} styles={{ content: { borderRadius: 15 } }}>
        <CropImage title={'Edit Photo'} onClose={handleCloseModal} onCrop={addImage} />
      </Modal>
      <ConfirmModal
        open={confirmModalOpen}
        onOk={async () => {
          await removeImage();
          setConfirmModalOpen(false);
        }}
        onCancel={() => setConfirmModalOpen(false)}
        title='Do you want to delete your profile photo ?'
        isLoadingSubmitBtn={isLoadingRemovingImg}
      />
      <div className='personal-details w-full flex flex-col gap-9 px-4 md:px-[34px] py-4 md:py-6 shadow-white-xl'>
        {isUserProfileLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className='personal-details-form'>
              <FormDescription {...personalDetailsFormDescription} />
              <div className='flex flex-col gap-[18px] pt-4'>
                <Label label={'Profile photo'}>
                  <div className={'flex justify-start gap-5'}>
                    <Avatar src={imageUrl} />
                    {!imageUrl && <Button onClick={() => setIsCropImageOpen(true)} label={'Add photo'} />}
                    {imageUrl && (
                      <Button theme={'red'} onClick={() => setConfirmModalOpen(true)} label={'Delete photo'} />
                    )}
                  </div>
                </Label>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                  <div className='w-full'>
                    <Label label={'First Name *'}>
                      <ErrorValidationMessage touched={touched.firstName} message={errors.firstName}>
                        <Input {...getCommonInputProps('firstName', 'First Name')} />
                      </ErrorValidationMessage>
                    </Label>
                  </div>
                  <div className='w-full'>
                    <Label label={'Last Name *'}>
                      <ErrorValidationMessage touched={touched.lastName} message={errors.lastName}>
                        <Input {...getCommonInputProps('lastName', 'Last Name')} />
                      </ErrorValidationMessage>
                    </Label>
                  </div>
                </div>
                <div>
                  <Label label={'Date of Birth *'}>
                    <CustomDatePicker
                      name='dateOfBirth'
                      value={values.dateOfBirth}
                      onChange={(date) => handleCalendarChange('dateOfBirth', date)}
                      invalid={Boolean(errors['dateOfBirth'] && touched['dateOfBirth'])}
                    />
                    {showError('dateOfBirth') && <p className='text-xs text-error py-1'>{errors.dateOfBirth}</p>}
                  </Label>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                  <div className='w-full'>
                    <Label label={'Email *'}>
                      <Input 
                        //iconComponent={<EditTwoTone onClick={() => setIsVerifyEmailModalOpen(true)}/>} 
                        {...getCommonInputProps('email', 'Email')} 
                        disabled
                        stylesConfig={{inputStyle: { display: 'inline', marginRight: '7px' }}} />
                      {showError('email') && <p className='text-xs text-error py-1'>{errors.email}</p>}
                      <EmailVerificationModal 
                        userInfo={userProfile} 
                        setIsVerifyEmailModalOpen={setIsVerifyEmailModalOpen} 
                        isVerifyEmailModalOpen={isVerifyEmailModalOpen}
                        onCloseCallback={(newEmail) => setFieldValue('email', newEmail)}/>
                      {!(userProfileVerification && isEmailValid) &&
                        <div className='flex flex-wrap items-center gap-3 mt-4'>
                          <p className='text-error'>Email verification missing</p>
                          <Button
                            theme='red-outline'
                            label={'Verify'}
                            onClick={async () => await verifyEmail()}
                            disabled={isVerificationOfEmail}
                          ></Button>
                        </div>
                      }
                    </Label>
                  </div>
                  <div className='w-full'>
                    <Label label={'Phone Number *'}>
                      <ErrorValidationMessage touched={touched.phoneNumber} message={errors.phoneNumber}>
                        <PhoneInput
                          isValid={!Boolean(errors.phoneNumber && touched.phoneNumber)}
                          value={values.phoneNumber}
                          onChange={(value) => {
                            setFieldValue('phoneNumber', value);
                          }}
                          // TODO: CHANGE WHEN WE WILL ADD FLOW TO UPDATE NUMBER FROM PROFILE
                          disabled
                          inputStyle={phoneNumberDisabledInputStyles}
                          disableDropdown
                          buttonStyle={phoneNumberDisabledButtonStyles}
                        />
                      </ErrorValidationMessage>
                    </Label>
                  </div>
                </div>
                <Label label={'Job title'}>
                  <Input {...getCommonInputProps('jobTitle', 'Job title')} />
                </Label>
                <div className='address-form'>
                  <FormDescription
                    title={'Current Residential Address *'}
                    subTitle={'To avoid service restrictions, ensure you fill out your address details.'}
                  />
                  <div className='flex flex-col gap-[18px] pt-4'>
                    <Label label={'Home address'}>
                      <div className='place-autocomplete relative'>
                        <RenterPersonalDetailsFormAddressField
                          value={values.addressField}
                          setValue={(value) => {
                            setFieldValue('addressField', value.address);
                            if (!isNotEmptyString(value.address)) {
                              setFieldValue('address', '');
                              setFieldValue('city', '');
                              setFieldValue('state', '');
                              setFieldValue('zipCode', '');
                            }
                          }}
                          onSelectAddress={(value) => {
                            setFieldValue('address', `${value.streetName} ${value.streetNumber}`);
                            setFieldValue('city', `${value.city}`);
                            setFieldValue('state', `${value.state}`);
                            setFieldValue('zipCode', `${value.zip}`);
                            setFieldValue(
                              'addressField',
                              formatAddress({
                                address: `${value.streetName}${value.streetNumber ? ' ' + value.streetNumber : ''}`,
                                city: value.city,
                                state: value.state,
                                zip: value.zip,
                              }),
                            );
                          }}
                          fieldName={'addressField'}
                          invalid={Boolean(errors.addressField && touched.addressField)}
                        />
                        {Boolean(errors.addressField && touched.addressField) && (
                          <div className='text-red-500 text-sm text-red font-medium mt-2'>{errors.addressField}</div>
                        )}
                      </div>
                    </Label>
                  </div>
                </div>
              </div>
            </div>

            <div className='button-wrapper flex justify-end'>
              <Button
                label={'Save'}
                theme={'primary'}
                onClick={submitForm}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </Fragment>
        )}

        {/* <div className='business-information-form'>
          <FormDescription title={'Business information'} />
          <div className='flex flex-col gap-[18px] pt-4'>
            <div className='flex gap-4'>
              <div className='w-1/2'>
                <Label label={'Company name'}>
                  <Input {...getCommonInputProps('companyName', 'Company name')} />
                </Label>
              </div>
              <div className='w-1/2'>
                <Label label={'Company website'}>
                  <Input {...getCommonInputProps('companyWebsite', 'Company website')} />
                </Label>
              </div>
            </div>
            <div className='flex gap-4'>
              <div className='w-1/2'>
                <Label label={'Business email'}>
                  <Input {...getCommonInputProps('businessEmail', 'Business email')} />
                </Label>
              </div>
              <div className='w-1/2'>
                <Label label={'Business phone'}>
                  <PhoneInput
                    isValid={!errors.businessPhone}
                    value={values.businessPhone}
                    onChange={(value, data, event, formattedValue) => {
                      setFieldValue('businessPhone', value);
                    }}
                  />
                </Label>
              </div>
            </div>
            <Label label={'Company address'}>
              <Input {...getCommonInputProps('companyAddress', 'Company address')} />
            </Label>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export { LandlordPersonalDetailsForm };
