import { PropertyPlan } from "common/enums/enums";
import { FC } from "react";

type EstateSubscriptionPlanProps = {
    plan: PropertyPlan;
}

export const EstateSubscriptionPlan: FC<EstateSubscriptionPlanProps> = ({ plan }) => {
    return (
        <p className="text-[#C4C4C4] text-[11px] text-end my-1">{'Subscription plan: '}{plan}</p>
    );
};
