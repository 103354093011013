export enum OffersApiPath {
  GET_RENTER_OFFERS = 'irent/RentApplications/my',
  GET_OFFERS = 'irent/RentApplications/applied',
  GET_ESTATE_OFFERS = 'irent/RentApplications/applied/byEstate',
  GET_ESTATE_OFFERS_BY_UNIT = 'irent/RentApplications/applied/byUnit',
  GET_ADMIN_OFFERS = 'irent/admin/RentApplications/',
  ACCEPT_OFFER = 'irent/RentApplications',
  REJECT_OFFER = 'irent/RentApplications',
  CREATE_OFFER = 'irent/RentApplications',
  OFFER_PAYMENT = 'billings/Payments/application',
  PRE_OFFER_PAYMENT = 'billings/Payments/pre-application',
  MAIN_OFFER_PATH = 'irent/RentApplications',
}

export const buildOfferPendingInProgressPaymentUrl = (applicationId: string) =>
  `${OffersApiPath.MAIN_OFFER_PATH}/${applicationId}/payment`;
