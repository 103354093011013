import { PropertyAccessKey } from 'common/enums/enums';
import { PropertyBuildingAccessValues } from './property-building-access';

export const defaultAccessValues: PropertyBuildingAccessValues = {
  keyAccess: PropertyAccessKey.Lockbox,
  accessInstructions: '',
  files: [],
  accountId: '',
  deviceId: '',
  forAllUnits: true,
};

export const propertyKeyAccessToLabelName: Record<PropertyAccessKey, string> = {
  [PropertyAccessKey.Lockbox]: 'Key in lockbox',
  [PropertyAccessKey.LeasingOffice]: 'Key at leasing office',
  [PropertyAccessKey.DiscreetLocation]: 'Key in discreet location',
  [PropertyAccessKey.QRCode]: 'QR code in building',
  [PropertyAccessKey.ElectronicLock]: 'Electronic lock',
  [PropertyAccessKey.OtherKey]: 'Other',
};

export const propertyKeyAccessTypeList = Object.values(PropertyAccessKey).map((value) => ({
  value,
  label: propertyKeyAccessToLabelName[value],
}));
