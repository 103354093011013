import dayjs from 'dayjs';
import * as Yup from 'yup';
import { TestContext } from 'yup';

export const propertyAvailabilityValidationSchema = () => {
  const testRange = (value: string | undefined, context: TestContext): boolean => {
    const include = context.from?.[1].value.include;
    return include ? Boolean(value) : true;
  };

  const testTimeTo = (value: string | undefined, context: TestContext): boolean => {
    const path = context.path.slice(0, -9).split('.');

    //@ts-ignore
    const isInclude = context?.options?.context?.availability?.tourAvailability[path[2]].include ?? true;

    const convertTo24 = (time: string | number) =>
      Number((time ? dayjs(`1/1/1 ${time}`).format('HH:mm:00') : null)?.slice(0, 2));
    return isInclude ? Boolean(convertTo24(context.parent.to) > convertTo24(context.parent.from)) : true;
  };

  const getRangeSchema = () =>
    Yup.object({
      from: Yup.string().test('from', 'required', testRange),
      to: Yup.string()
        .test('to', 'required', testRange)
        .test('to', 'Closing time should be higher than opening time', testTimeTo),
    });

  const getDaySchema = () =>
    Yup.object().shape({
      include: Yup.boolean(),
      range: getRangeSchema(),
    });

  return Yup.object().shape({
    tourAvailability: Yup.object().shape({
      monday: getDaySchema(),
      tuesday: getDaySchema(),
      wednesday: getDaySchema(),
      thursday: getDaySchema(),
      friday: getDaySchema(),
      saturday: getDaySchema(),
      sunday: getDaySchema(),
    }),
  });
};
