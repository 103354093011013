import React from 'react';
import styles from './styles';
import styleModule from './styles.module.css';
import clsx from 'clsx';
import parse from 'html-react-parser';

export type EstatesDescriptionProps = {
  description: string;
  title?: string;
};

const EstateDescription = ({ description, title }: EstatesDescriptionProps) => {
  const [isReadMore, setIsReadMore] = React.useState(false);

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>{title ?? 'About Property'}</h2>
      <div className={styles.wrapper}>
        <p
          className={clsx(styleModule.TextOverflow, isReadMore && styleModule.ReadMore)}
          style={{
            textOverflow: isReadMore ? 'unset' : 'text-ellipsis overflow-hidden ...',
          }}
        >
          {parse(description ? description : '')}
        </p>
      </div>
      {description.length > 500 && (
        <div className={styles.toggle} onClick={() => setIsReadMore((prevState) => !prevState)}>
          {`Read ${isReadMore ? 'less' : 'more'}`}
        </div>
      )}
    </section>
  );
};

export { EstateDescription };
