import React from 'react';

export type LocationPinProps = {
  type?: 'solid' | 'regular';
  width?: number;
  height?: number;
  color?: string;
  lat?: number;
  lng?: number;
  style?: React.CSSProperties;
};

const LocationPin = ({ width = 14, height = 18, color = '#66CCFF', type = 'regular', style }: LocationPinProps) => {
  return (
    <div style={style}>
      {type === 'regular' ? (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 14 18' fill='none'>
          <path
            d='M6.9987 9.00002C7.45703 9.00002 7.84953 8.83669 8.1762 8.51002C8.50231 8.18391 8.66536 7.79169 8.66536 7.33335C8.66536 6.87502 8.50231 6.48252 8.1762 6.15585C7.84953 5.82974 7.45703 5.66669 6.9987 5.66669C6.54036 5.66669 6.14814 5.82974 5.82203 6.15585C5.49536 6.48252 5.33203 6.87502 5.33203 7.33335C5.33203 7.79169 5.49536 8.18391 5.82203 8.51002C6.14814 8.83669 6.54036 9.00002 6.9987 9.00002ZM6.9987 15.125C8.69314 13.5695 9.95009 12.1561 10.7695 10.885C11.589 9.61446 11.9987 8.48613 11.9987 7.50002C11.9987 5.98613 11.5159 4.74641 10.5504 3.78085C9.58536 2.81585 8.40148 2.33335 6.9987 2.33335C5.59592 2.33335 4.41175 2.81585 3.4462 3.78085C2.4812 4.74641 1.9987 5.98613 1.9987 7.50002C1.9987 8.48613 2.40842 9.61446 3.22786 10.885C4.04731 12.1561 5.30425 13.5695 6.9987 15.125ZM6.9987 17.3334C4.76259 15.4306 3.09259 13.6631 1.9887 12.0309C0.884253 10.3992 0.332031 8.88891 0.332031 7.50002C0.332031 5.41669 1.00231 3.75696 2.34286 2.52085C3.68286 1.28474 5.23481 0.666687 6.9987 0.666687C8.76259 0.666687 10.3145 1.28474 11.6545 2.52085C12.9951 3.75696 13.6654 5.41669 13.6654 7.50002C13.6654 8.88891 13.1134 10.3992 12.0095 12.0309C10.9051 13.6631 9.23481 15.4306 6.9987 17.3334Z'
            fill={color}
          />
        </svg>
      ) : (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 33 41' fill='none'>
          <path
            d='M16.4987 20.5001C17.5758 20.5001 18.4982 20.1162 19.2658 19.3486C20.0322 18.5822 20.4154 17.6605 20.4154 16.5834C20.4154 15.5063 20.0322 14.584 19.2658 13.8163C18.4982 13.0499 17.5758 12.6667 16.4987 12.6667C15.4216 12.6667 14.4999 13.0499 13.7335 13.8163C12.9659 14.584 12.582 15.5063 12.582 16.5834C12.582 17.6605 12.9659 18.5822 13.7335 19.3486C14.4999 20.1162 15.4216 20.5001 16.4987 20.5001ZM16.4987 40.0834C11.2438 35.6119 7.31934 31.4583 4.7252 27.6225C2.12975 23.7881 0.832031 20.239 0.832031 16.9751C0.832031 12.0792 2.40718 8.1789 5.55749 5.27404C8.70649 2.36918 12.3536 0.916748 16.4987 0.916748C20.6438 0.916748 24.2909 2.36918 27.4399 5.27404C30.5902 8.1789 32.1654 12.0792 32.1654 16.9751C32.1654 20.239 30.8683 23.7881 28.2742 27.6225C25.6787 31.4583 21.7536 35.6119 16.4987 40.0834Z'
            fill={color}
          />
        </svg>
      )}
    </div>
  );
};

export { LocationPin };
