import { createRoot } from 'react-dom/client';
import App from 'app/app';
import reportWebVitals from './reportWebVitals';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { navigation as navigationService } from 'services/services';
import { NotificationModal, Toast } from 'legacy-components/componets';
import 'react-phone-input-2/lib/material.css';
import 'react-quill/dist/quill.snow.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-dropdown/assets/index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import 'simplebar-react/dist/simplebar.min.css';
import './index.css';
import { YbugProvider } from 'ybug-react';
import { ENV } from 'common/enums/enums';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { hotjar } from 'react-hotjar';
import { ScrollToTop } from 'components/atoms/ScrollToTop';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { antdTheme } from 'config/theme';
import queryClient from 'config/query-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const { history } = navigationService;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');
const container = document.getElementById('root')!;
const root = createRoot(container);
if (!Number.isNaN(ENV.HOTJAR_ID)) {
  hotjar.initialize(ENV.HOTJAR_ID, ENV.HOTJAR_SNIPPET_VERSION);
}

root.render(
  <QueryClientProvider client={queryClient}>
    <YbugProvider ybugId={ENV.REACT_APP_YBUG_ID}>
      {/*@ts-ignore*/}
      <HistoryRouter history={history}>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            removeDefaultsFromUrl: true,
          }}
        >
          <ConfigProvider theme={antdTheme}>
            <ScrollToTop />
            <App />
            <Toast />
            <NotificationModal />
          </ConfigProvider>
        </QueryParamProvider>
      </HistoryRouter>
    </YbugProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
);

reportWebVitals();
