import { Button, Loader, Modal } from 'legacy-components/componets';
import { CloseOutlined } from '@ant-design/icons';
import { title } from './PreviewLeaseDocument.constants';
import { PreviewLeaseDocumentsProps } from './PreviewLeaseDocument.types';
import { PdfDocumentViewer } from 'legacy-components/pdf/document-viewer';
import { useEffect, useState } from 'react';
import { Notification } from 'services/notification.service';
import { isFile, isString } from 'common/utils/check-type';
import { useWindowSize } from 'hooks/use-windows-size.hook';

export const PreviewLeaseDocument = ({ open, onClose, file: fileUrl, title: titleProps }: PreviewLeaseDocumentsProps) => {
  const { isMobile } = useWindowSize();

  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File>({} as File);

  useEffect(() => {
    if (fileUrl && isString(fileUrl)) {
      fetchFile(fileUrl);
    }
    if (fileUrl && isFile(fileUrl)) {
      setFile(fileUrl);
    }
  }, [open, fileUrl]);

  const fetchFile = async (url: string) => {
    try {
      setIsLoading(true);
      const response = await fetch(url);

      const blob = await response.blob();
      const filename = getFileNameFromUrl(url);
      const downloadedFile = new File([blob], filename);
      setFile(downloadedFile);
    } catch (error) {
      const notification = new Notification();
      notification.error('Sory, but we could not upload file');

      onClose();
      setFile({} as File);
    } finally {
      setIsLoading(false);
    }
  };

  const getFileNameFromUrl = (url: string) => {
    const segments = url.split('/');
    return segments[segments.length - 1];
  };

  const handleViewFile = () => {
    if (fileUrl) {

      if (typeof fileUrl === 'string') {
        window.open(fileUrl, '_blank', 'fullscreen=yes');
      }

      if (typeof fileUrl === 'object') {
        const url = URL.createObjectURL(fileUrl);
        window.open(url, '_blank', 'fullscreen=yes');
      }

    }
  }

  return (
    <>
      <Modal
        isOpen={open}
        onClose={onClose}
        styles={{ content: { maxHeight: '100%', ...(isMobile && { maxHeight: '100%' }) } }}
      >
        <div className='p-4 bg-white rounded-lg shadow min-w-[360px] sm:min-w-[500px]'>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center justify-center gap-4'>
              <div className='flex-grow flex items-center justify-center'>
                <h3 className='text-black font-semibold text-xl whitespace-nowrap'>{titleProps || title}</h3>
              </div>
              <button onClick={onClose} type={'button'}>
                <CloseOutlined className={'text-primary text-[22px]'} />
              </button>
            </div>
            <div>
              <Button
                label={'View in Browser'}
                disabled={!fileUrl}
                onClick={handleViewFile}
                isFullWidth
              />
            </div>
            {isLoading ? <Loader /> : <PdfDocumentViewer file={file} />}
          </div>
        </div>
      </Modal>
    </>
  );
};
