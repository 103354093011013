import React from 'react';

export type SliderProps = {
  name?: string;
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Slider = ({ name, value, min, max, step, onChange }: SliderProps) => {
  const getPercent = (min: number, max: number) => (value: number) => (100 * (value - min)) / (max - min);

  return (
    <div className={'flex items-center grow relative'}>
      <div
        className={'absolute top-0 bottom-0 mt-auto mb-auto h-1 bg-primary rounded-xl'}
        style={{ width: `${getPercent(min, max)(value)}%` }}
      />
      <input
        type='range'
        className='accent-primary h-1 w-full cursor-pointer appearance-none rounded-full border-transparent bg-gray'
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
      />
    </div>
  );
};

export { Slider };
