import { TableColumnFilter } from 'common/types/components/ui/table/table-props.type';
import { useEffect, useId, useState } from 'react';
import { Button, Checkbox } from 'legacy-components/componets';
import Dropdown from 'rc-dropdown';
import { FilterFilled } from '@ant-design/icons';
import { twMerge } from 'tailwind-merge';

type FilterButtonProps = {
  filters: TableColumnFilter[];
  filteredValue: string[];
  onFilter: (filters: string[]) => void;
};

export const FilterButton = ({ filters, filteredValue: filteredValueProp, onFilter }: FilterButtonProps) => {
  const id = useId();
  const [visible, setVisible] = useState(false);
  const [filteredValue, setFilteredValue] = useState<string[]>([]);

  useEffect(() => {
    setFilteredValue(filteredValueProp);
  }, [filteredValueProp]);

  const overlay = (
    <div className={'bg-white shadow p-2'} onClick={(e) => e.stopPropagation()}>
      <ul>
        {filters.map((filter) => {
          const checked = filteredValue.includes(filter.value);
          return (
            <li key={filter.value}>
              <Checkbox
                label={filter.text}
                checked={checked}
                name={`${filter.value} ${id} table column filter`}
                value={checked}
                onChange={() => {
                  setFilteredValue((prev) =>
                    checked ? prev.filter((v) => v !== filter.value) : [...prev, filter.value],
                  );
                }}
              />
            </li>
          );
        })}
      </ul>
      <div className={'flex mt-1 gap-2'}>
        <Button
          label={'Reset'}
          theme={'gray'}
          onClick={() => {
            onFilter([]);
            setVisible(false);
          }}
        />
        <Button
          label={'Ok'}
          onClick={() => {
            onFilter(filteredValue);
            setVisible(false);
          }}
        />
      </div>
    </div>
  );

  return (
    <Dropdown
      trigger={['click']}
      overlay={overlay}
      visible={visible}
      onVisibleChange={(visible) => {
        setVisible(visible);
        if (!visible) {
          onFilter(filteredValue);
        }
      }}
    >
      <button onClick={() => setVisible(true)} className={'flex items-center'}>
        <FilterFilled
          className={twMerge(
            'text-[14px] text-neutral-400 hover:text-neutral-500',
            filteredValueProp.length > 0 && 'text-primary hover:text-primary',
          )}
        />
      </button>
    </Dropdown>
  );
};
