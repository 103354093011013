import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { ToursScheduledRequestDto } from 'common/types/services/api/tours/tours-scheduled-request-dto.type';
import { ToursScheduledDto } from '../../../common/types/services/api/tours/tours-scheduled-dto.type';
import { getLandlordScheduledTours } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useLandlordScheduledTours(
  params: ToursScheduledRequestDto,
  options?: Omit<
    UndefinedInitialDataOptions<ToursScheduledDto, Error, ToursScheduledDto, (string | ToursScheduledRequestDto)[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    ...options,
    queryKey: [QueryKey.Tours, 'landlord', 'scheduled', params],
    queryFn: () => getLandlordScheduledTours(params),
  });
}
