import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import {
  LandlordProfileInformation,
  Offers,
  LandlordListing,
  ApplicationSummary,
  AccessAccounts,
} from 'legacy-pages/pages';
import { LeaseDocuments } from 'legacy-pages/landlord/profile/lease-documents/LeaseDocuments';
// import { ToursSchedulePage } from 'components/templates/Landlord/ToursSchedulePage/ToursSchedulePage';
// import { EstateForm } from 'components/templates/EstateForm';
// import { EstateFormEdit, EstateFormEditInitial } from 'components/templates/EstateForm/Edit/EstateFormEdit';
import { AccountSecurity } from 'components/templates/Renter/AccountSecurity/AccountSecurity';

const ChangePassword = lazy(() => import('legacy-pages/change-password/change-password'));
const LandlordProfile = lazy(() => import('legacy-pages/landlord/profile/landlord-profile'));
const LandlordTours = lazy(() => import('components/templates/Landlord/Tours/LandlordTours'));
const PropertyDetails = lazy(() => import('legacy-pages/landlord/property-details/property-details'));
const EstateFormEdit = lazy(() => import('components/templates/EstateForm/Edit/EstateFormEdit'));
const EstateFormEditInitial = lazy(() => import('components/templates/EstateForm/Edit/EstateFormEditInitial'));

export const landlordRoutes = [
  { path: '', element: <Navigate to='properties' replace /> },
  {
    path: 'profile',
    element: <LandlordProfile />,
    children: [
      { path: '', element: <Navigate to={'info'} replace /> },
      { path: 'info/*', element: <LandlordProfileInformation />, index: true },
      { path: 'listing', element: <LandlordListing /> },
      { path: 'schedule/*', element: <LandlordTours /> },
      { path: 'offers', element: <Offers /> },
      { path: 'lease-documents', element: <LeaseDocuments /> },
      { path: 'offers/:applicationId', element: <ApplicationSummary /> },
      { path: 'access-accounts', element: <AccessAccounts /> },
      { path: 'security', element: <AccountSecurity /> },
    ],
  },
  {
    path: 'properties',
    element: <EstateFormEditInitial />,
  },
  {
    path: 'properties/:estateID/*',
    element: <EstateFormEdit />,
  },
  { path: 'properties/details/:id', element: <PropertyDetails /> },
  { path: 'change-password', element: <ChangePassword /> },
];
