import { AccessAccountsDto, AccessAccountsTableRow } from 'common/types/types';
import { accessAccountsStatusDtoToTableStatus } from './access-accounts-status-dto-to-table-status.mappers';
import { AccessAccountsStatusDto } from 'common/enums/services/api/accessAccounts/access-accounts-status-dto.enum';

export const accessAccountsDtoToTable = (items: AccessAccountsDto[]): AccessAccountsTableRow[] => {
  return items.map((item) => ({
    id: item.id,
    name: {
      name: item.accountType,
      email: item.email,
    },
    status: accessAccountsStatusDtoToTableStatus[item.status],
    date: item.createdAt,
    action: {
      isConfirmed: item.status === AccessAccountsStatusDto.Finished,
      onConfirm: () => window.open(item.url, '_blank'),
    },
  }));
};
