import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { updatePropertyPlan } from 'services/api/property-api.service';
import { PropertyUpdatePlanRequestDtoType } from 'common/types/services/api/property/property-update-plan-request-dto.type';

type Store = {
  isUpdatingPropertyPlanLoading: boolean;
  selectedPlan: string;
  updatePropertyPlan: (data: PropertyUpdatePlanRequestDtoType) => Promise<void>;
};

const useLandlordPropertyStore = create<Store>()(
  immer((set) => ({
    isUpdatingPropertyPlanLoading: false,
    selectedPlan: '',
    updatePropertyPlan: async (data) => {
      try {
        set((state) => {
          state.selectedPlan = data.plan;
          state.isUpdatingPropertyPlanLoading = true;
        });
        await updatePropertyPlan(data);
      } catch (e) {
      } finally {
        set((state) => {
          state.isUpdatingPropertyPlanLoading = false;
        });
      }
    },
  })),
);

export default useLandlordPropertyStore;
