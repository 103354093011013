import { PropertyEstateRentSpecial, PropertyRentSpecial, PropertyUnitRentSpecial } from "./PropertyRentSpecial.types";

export const defaultUnitRentSpecial: PropertyUnitRentSpecial = {
    rentSpecialTitle: '',
    rentSpecialStartDate: '',
    rentSpecialEndDate: '',
    rentSpecialDescription: '',
};

export const defaultEstateRentSpecial: PropertyEstateRentSpecial = {
    ...defaultUnitRentSpecial,
    forAllUnits: true
};

export const defaultRentSpecials = (numberOfUnits: number): PropertyRentSpecial => {
    return {
        estateRentSpecial: defaultEstateRentSpecial,
        estateUnitsRentSpecial: Array(numberOfUnits).fill(defaultUnitRentSpecial),
    }
};