import { SubscriptionStatusType } from "common/enums/services/api/estates/estate-status-dto.enum";
import { ACCEPTED_SUB, CANCELED_SUB, PENDING_SUB } from "./SubcriptionStatus.const";

export type SubcriptionStatusProps = {
    status: SubscriptionStatusType;
    size?: 'small' | 'medium' | 'large';
}

export const subscriptionText: Record<SubscriptionStatusType, string> = {
    [SubscriptionStatusType.Active]: ACCEPTED_SUB,
    [SubscriptionStatusType.PendingCanceling]: PENDING_SUB,
    [SubscriptionStatusType.Inactive]: CANCELED_SUB,
};
