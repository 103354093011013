import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getPaymentCards } from 'services/api/payment-api.service';
import { PaymentCardsDto } from 'common/types/services/api/payment/payment-cards-dto.type';
import { QueryKey } from 'common/enums/query-key';

export default function usePaymentCards(
  options?: Omit<
    UndefinedInitialDataOptions<PaymentCardsDto, Error, PaymentCardsDto, string[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.PaymentCards],
    queryFn: getPaymentCards,
    ...options,
  });
}
