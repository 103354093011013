type ComingSoonProps = {
  additionalStyles?: string;
}
const ComingSoon = ({ additionalStyles = '' }: ComingSoonProps) => (
  <h1 className={`mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 ${additionalStyles}`}>
    Coming soon! <mark className='px-2 text-white bg-primary rounded'>Axxelist</mark>
  </h1>
);

export { ComingSoon };
