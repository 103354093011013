import { CellProps } from 'react-table';
import { ToursScheduleTableRowType, ToursScheduleTablePropertyCellType } from '../types';
import { PropertyImage } from 'components/atoms/PropertyImage/PropertyImage';

const UpcomingToursPropertyCell = ({
  value,
}: CellProps<ToursScheduleTableRowType, ToursScheduleTablePropertyCellType>) => {
  const { location, img, isMulti, unitNumber } = value;

  return (
    <div className='flex gap-3'>
      <div className='w-[64px] h-[48px]'>
        <PropertyImage image={img} />
      </div>
      <div className={'text-sm'}>
        <div>{location}</div>
        {isMulti && <div>{`(Unit ${unitNumber})`}</div>}
      </div>
    </div>
  );
};

export { UpcomingToursPropertyCell };
