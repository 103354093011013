import { ReactComponent as ReactAddressBookIcon } from 'assets/icons/address-book.svg';
import { ReactComponent as ReactLockKeyIcon } from 'assets/icons/lock-key.svg';
import { ReactComponent as ReactWalletIcon } from 'assets/icons/wallet.svg';
import { ReactComponent as ReactBellRingingIcon } from 'assets/icons/bell-ringing.svg';
import { ReactComponent as ReactUserCirclePlusIcon } from 'assets/icons/user-circle-plus.svg';
import { RenterAccountSideBarItems } from 'common/enums/app/i-rent.enum';

export const settingTiles = [
  {
    id: 'personalInfo',
    title: 'Personal Information',
    description: 'Sapien non nec purus feugiat sit sagittis egestas nisl.',
    icon: ReactAddressBookIcon,
    link: '/i-rent/profile',
    state: RenterAccountSideBarItems.ProfileInformation,
  },
  {
    id: 'loginAndSecurity',
    title: 'Login and Security',
    description: 'Porta etiam diam convallis interdum orci massa sagittis vitae.',
    icon: ReactLockKeyIcon,
    link: '',
    state: undefined,
  },
  {
    id: 'payment',
    title: 'Payment',
    description: 'Fermentum vitae amet dignissim curabitur pellentesque mauris.',
    icon: ReactWalletIcon,
    link: '',
    state: undefined,
  },
  {
    id: 'notifications',
    title: 'Notifications',
    description: 'Semper diam bibendum consequat cursus eget.',
    icon: ReactBellRingingIcon,
    link: '',
    state: undefined,
  },
  {
    id: 'profiles',
    title: 'Profiles',
    description: 'Tellus a volutpat ac porta.',
    icon: ReactUserCirclePlusIcon,
    link: '',
    state: undefined,
  },
  {
    id: 'messages',
    title: 'Messages',
    description: 'At adipiscing scelerisque scelerisque ullamcorper maecenas nulla sodales nullam nulla.',
    icon: ReactWalletIcon,
    link: '',
    state: undefined,
  },
];
