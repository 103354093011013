import { ToursApiPath, buildRenterDocumentTourPath } from 'common/enums/services/api/paths/tours-api-path.enum';
import {
  TourAccessDto,
  TourAvailableSlotsDto,
  TourAvailableSlotsRequestDto,
  TourBillingPaymentRequestDto,
  TourDetailsDto,
  TourLeaveFeedbackDto,
  TourPaymentIntentDto,
  TourScheduleRequestDto,
  ToursDto,
} from 'common/types/types';
import qs from 'qs';
import { ToursScheduledDto } from 'common/types/services/api/tours/tours-scheduled-dto.type';
import { ToursScheduledRequestDto } from 'common/types/services/api/tours/tours-scheduled-request-dto.type';
import dayjs from 'dayjs';
import { UploadTourDocumentRequestDto } from 'common/types/services/api/tours/upload-tour-document-request-dto.type';
import { TourLandlordLeaveFeedbackDto } from 'common/types/services/api/tours/tour-landlord-leave-feedback-dto';
import { RescheduleTourBillingPaymentRequestDto } from 'common/types/services/api/tours/tour-billing-request-dto.type';
import { RescheduleTourPaymentIntentDto } from 'common/types/services/api/tours/tour-payment-intent-dto.type';
import { axiosInstance } from './axiosInstance';
import { UpcomingToursRequestDtoType } from 'common/types/services/api/tours/upcoming-tours-request-dto.type';
import { ToursHistoryRequestDto } from 'common/types/services/api/tours/tours-history-request-dto.type';
import { RescheduleTourResponse } from 'common/types/services/api/tours/reschedule-tour-response.type';
import { RescheduleTourInfo } from 'common/types/services/api/tours/reschedule-tour-info.type';
import { TourDocumentDto } from 'common/generated/types/tour-document-dto';

export const getAvailableTours = ({
  id,
  forDate,
  unitId
}: TourAvailableSlotsRequestDto): Promise<TourAvailableSlotsDto> => {
  const queryParams = {
    forDate,
    unitId
  };
  const queryString = qs.stringify(queryParams, { encode: false, arrayFormat: 'repeat' });
  return axiosInstance.get(`${ToursApiPath.GET_AVAILABLE_TOURS}/${id}?${queryString}`);
};

export const getLandlordScheduledTours = (query: ToursScheduledRequestDto): Promise<ToursScheduledDto> => {
  const { filters } = query;
  const queryParams = {
    filters: {
      Slot: [filters?.from ? `>=${filters.from}` : undefined, filters?.to ? `<${filters.to}` : undefined],
      status: filters?.status ? filters.status.map((s) => `OR%${s}`) : undefined,
      isConfirmed: filters?.isConfirmed,
    },
    sorters: query.sorters,
    page: query.page ? query.page : undefined,
    perPage: query.perPage ? query.perPage : undefined,
  };

  const queryString = qs.stringify(queryParams, { encode: false, arrayFormat: 'repeat' });
  const url = `${ToursApiPath.GET_SCHEDULED_TOURS}?${queryString}`;
  return axiosInstance.get(url);
};

export const getAdminScheduledTours = (query: ToursScheduledRequestDto): Promise<ToursScheduledDto> => {
  const { filters } = query;
  const queryParams = {
    filters: {
      Slot: [filters?.from ? `>=${filters.from}` : undefined, filters?.to ? `<${filters.to}` : undefined],
      status: filters?.status ? filters.status.map((s) => `OR%${s}`) : undefined,
    },
    sorters: query.sorters,
    page: query.page ? query.page : undefined,
    perPage: query.perPage ? query.perPage : undefined,
  };
  const queryString = qs.stringify(queryParams, { encode: false, arrayFormat: 'repeat' });
  const url = `${ToursApiPath.GET_ADMIN_TOURS}?${queryString}`;
  return axiosInstance.get(url);
};

export const scheduleTour = (payload: TourScheduleRequestDto): Promise<string> => {
  return axiosInstance.post(`${ToursApiPath.SCHEDULE_TOUR}`, payload);
};

export const getTourPayment = ({ tourId, unitId }: TourBillingPaymentRequestDto): Promise<TourPaymentIntentDto> => {
  return axiosInstance.post(`${ToursApiPath.POST_TOUR_PAYMENT}/${tourId}`, { unitId });
};

export const getEstatePayment = (estateId: string): Promise<TourPaymentIntentDto> => {
  return axiosInstance.post(`${ToursApiPath.GET_ESTATE_PAYMENT}/${estateId}`);
};

export const getTourKeyInfo = (tourId: string): Promise<TourAccessDto> => {
  return axiosInstance.get(`${ToursApiPath.SCHEDULE_TOUR}/${tourId}/access`);
};

export const getUpcomingTours = (params: UpcomingToursRequestDtoType): Promise<ToursDto> => {
  return axiosInstance.get(`${ToursApiPath.GET_UPCOMMING_TOURS}?${qs.stringify(params)}`);
};

export const getTourDetails = (id: string): Promise<TourDetailsDto> => {
  return axiosInstance.get(`${ToursApiPath.GET_TOUR_DETAILS}/${id}`);
};

export const cancelTour = (id: string): Promise<boolean> => {
  return axiosInstance.delete(`${ToursApiPath.CANCEL_TOUR}/${id}`);
};

export const startTour = (id: string): Promise<boolean> => {
  return axiosInstance.put(`${ToursApiPath.GET_TOUR_DETAILS}/${id}/start`);
};

export const finishTour = (id: string): Promise<boolean> => {
  return axiosInstance.put(`${ToursApiPath.GET_TOUR_DETAILS}/${id}/finish`);
};

export const getToursHistory = (query: ToursHistoryRequestDto): Promise<ToursDto> => {
  const queryParams = {
    filters: {
      tourStatus: query.filters?.tourStatus ? query.filters.tourStatus.map((status) => `OR%${status}`) : undefined,
    },
    sorters: query.sorters,
    page: query.page ? query.page : undefined,
    perPage: query.perPage ? query.perPage : undefined,
  };
  const queryString = qs.stringify(queryParams, { encode: false, arrayFormat: 'repeat' });
  const url = `${ToursApiPath.GET_TOURS_HISTORY}?${queryString}`;
  return axiosInstance.get(url);
};

export const leaveFeedbackAboutTour = (tourId: string, payload: TourLeaveFeedbackDto): Promise<void> => {
  return axiosInstance.put(`${ToursApiPath.SCHEDULE_TOUR}/${tourId}/feedback`, payload);
};

export const leaveLandlordsFeedbackAboutTour = (
  tourId: string,
  payload: TourLandlordLeaveFeedbackDto,
): Promise<void> => {
  return axiosInstance.put(`${ToursApiPath.SCHEDULE_TOUR}/${tourId}/landlordFeedback`, payload);
};

export const rescheduleTour = (tourId: string, slot: Date): Promise<RescheduleTourResponse> => {
  return axiosInstance.put(`${ToursApiPath.SCHEDULE_TOUR}/${tourId}`, {
    slot: dayjs(slot).utc(true).format('YYYY-MM-DD HH:mm:ss'),
  });
};

export const getToursByEstateId = (
  estateId: string,
): Promise<{ unitId: string; tourId: string; isNeedHold: boolean }[]> => {
  return axiosInstance.get(`${ToursApiPath.GET_UPCOMMING_TOURS}/${estateId}`);
};

export const uploadTourDocument = ({ file, type, tourId }: UploadTourDocumentRequestDto) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  return axiosInstance.put(`${ToursApiPath.SCHEDULE_TOUR}/${tourId}/document`, formData);
};

export const getRescheduleTourInfo = (tourId: string): Promise<RescheduleTourInfo> => {
  return axiosInstance.get(`${ToursApiPath.SCHEDULE_TOUR}/${tourId}/reschedule`);
};

export const getRescheduleTourPayment = (
  request: RescheduleTourBillingPaymentRequestDto,
): Promise<RescheduleTourPaymentIntentDto> => {
  return axiosInstance.post(`${ToursApiPath.POST_RESCHEDULE_TOUR_PAYMENT}`, request);
};

export const postRescheduleTourPaymentStatus = (tourId: string): Promise<void> => {
  return axiosInstance.post(`${ToursApiPath.SCHEDULE_TOUR}/${tourId}/reschedule/paid`);
};

export const getLandlordTourDetails = (id: string): Promise<TourDetailsDto> => {
  return axiosInstance.get(`${ToursApiPath.GET_TOUR_DETAILS}/own/${id}`);
};

export const cancelTourByLandlord = (tourId: string, reason: string): Promise<void> => {
  return axiosInstance.post(`${ToursApiPath.SCHEDULE_TOUR}/${tourId}/reject`, { reason });
};

export const confirmTourByLandlord = (tourId: string): Promise<void> => {
  return axiosInstance.put(`${ToursApiPath.SCHEDULE_TOUR}/${tourId}/confirm`);
};

export const getTourFiles = (tourId: string): Promise<TourDocumentDto[]> => {
  return axiosInstance.get(buildRenterDocumentTourPath(tourId));
};

export const paymentPendingForTour = (tourId: string): Promise<void> => {
  return axiosInstance.post(`${ToursApiPath.MAIN_PATH}/${tourId}/paymentPending`);
};
