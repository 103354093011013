import React from 'react';

export type FormDescriptionProps = {
  title: string;
  subTitle?: string;
  isOptional?: boolean;
};

const FormDescription = ({ title, subTitle, isOptional, }: FormDescriptionProps) => (
  <div className='flex flex-col gap-1'>
    <h2 className='title text-xl font-medium'>
      {title}
      {isOptional && <span className="text-xs font-bold pl-2 text-trueGray" style={{opacity: 0.6}}>optional</span>} 
    </h2>
    {subTitle && <div className='description text-trueGray'>{subTitle}</div>}
  </div>
);

export { FormDescription };
