import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';
import { VerificationPaymentDto } from 'common/types/services/api/payment/verification-payment-dto.type';
import { VerificationPaymentRequest } from 'common/types/services/api/payment/verification-payment-request.type';
import { requestVerificationPayment, updateVerificationPayment } from 'services/api/payment-api.service';

export function useRequestPersonaVerificationPayment(options?: UseMutationOptions<VerificationPaymentDto, AxiosError<BackendError>, VerificationPaymentRequest, unknown>) {
  return useMutation({
    ...options,
    mutationFn: requestVerificationPayment,
  });
}

export function useUpdatePersonaVerificationPayment(options?: UseMutationOptions<VerificationPaymentDto, AxiosError<BackendError>, (string | undefined), unknown>) {
  return useMutation({
    ...options,
    mutationFn: updateVerificationPayment,
  });
}