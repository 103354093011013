import {
  UndefinedInitialDataOptions,
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';
import {
  CommissionCreateDto,
  CommissionDto,
  CommissionEditRequest,
  CommissionItem,
  CommissionRequest,
} from 'common/types/services/api/commissions/commission-dto.types';
import {
  addNewCommission,
  deleteCommission,
  editCommission,
  getCommissionsList,
  getDefaultCommission,
} from 'services/api/commissions-api.service';

export const useCreateNewCommission = (
  options?: Omit<UseMutationOptions<void, AxiosError<BackendError>, CommissionCreateDto, unknown>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addNewCommission,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['commission', 'admin'] });
    },
    ...options,
  });
};

export const useEditCommission = (
  options?: Omit<UseMutationOptions<void, AxiosError<BackendError>, CommissionEditRequest, unknown>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editCommission,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['commission', 'admin'] });
    },
    ...options,
  });
};

export const useGetCommissions = (
  params: CommissionRequest,
  options?: Omit<
    UndefinedInitialDataOptions<CommissionDto, Error, CommissionDto, (string | CommissionRequest)[]>,
    'queryFn' | 'queryKey'
  >,
) => {
  return useQuery({
    queryKey: ['commission', 'admin', params],
    queryFn: () => {
      return getCommissionsList(params);
    },
    ...options,
  });
};

export const useDeleteCommissions = (
  options?: Omit<UseMutationOptions<void, AxiosError<BackendError>, string, unknown>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteCommission,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['commission', 'admin'] });
    },
    ...options,
  });
};

export const useGetDefaultCommissions = (
  options?: Omit<
    UndefinedInitialDataOptions<CommissionItem, Error, CommissionItem, (string | CommissionRequest)[]>,
    'queryFn' | 'queryKey'
  >,
) => {
  return useQuery({
    queryKey: ['commission', 'admin', 'default'],
    queryFn: () => {
      return getDefaultCommission();
    },
    ...options,
  });
};
