import { SelectOption, EstateUnitDto } from 'common/types/types';
import { joinValues } from 'helpers/helpers';

export const getUnitsOptions = (units: EstateUnitDto[]): SelectOption[] => {
  return units.map((item) => ({
    value: item.id,
    label: joinValues(['Unit', item.unitNumber], ' '),
  }));
};

export enum VerificationErrors {
  UserAddress = 'UserAddress',
  IdentityDocument = 'IdentityDocument',
  ProfileImage = 'ProfileImage',
  PersonalInfo = 'PersonalInfo',
  VerificationDocument = 'VerificationDocument',
  ConfirmEmail = 'emailConfirmed'
};