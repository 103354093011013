import React from 'react';

export type EstateTypeBadgeProps = {
  title: string;
  color?: string;
  backgroundColor?: string;
  styles?: React.CSSProperties;
};

const EstateTypeBadge = ({ title, color, backgroundColor, styles }: EstateTypeBadgeProps) => {
  return (
    <div className='flex items-center'>
      <div
        className='text-sm py-1 px-3 font-semibold bg-secondary text-trueGray rounded-[7px]'
        style={{ color, backgroundColor, ...styles }}
      >
        {title}
      </div>
    </div>
  );
};

export { EstateTypeBadge };
