import { Outlet } from 'react-router-dom';
import { Logo } from 'legacy-components/componets';
import { AuthLayoutBackground } from './Background';
import SimpleBar from 'simplebar-react';

const AuthLayout = () => {
  return (
    <div className='h-full'>
      <section className='flex'>
        <div className='md:w-1/2 w-full relative overflow-y-auto h-screen'>
          <div className='logo-container px-10 py-7'>
            <Logo />
          </div>
          <SimpleBar>
            <Outlet />
          </SimpleBar>
        </div>
        <div className='hidden md:block w-1/2 h-screen bg-secondary relative select-none pointer-events-none overflow-hidden'>
          <AuthLayoutBackground />
        </div>
      </section>
    </div>
  );
};

export { AuthLayout };
