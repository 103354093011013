import { VerificationDetailsFormValues } from './types';
import { VerificationFileType } from 'common/types/services/api/accessAccounts/verification-file.dto';

export const verificationFormFieldKeyToVerificationFileTypeMap: Record<
  keyof VerificationDetailsFormValues,
  VerificationFileType
> = {
  passport: VerificationFileType.Passport,
  driverLicence: VerificationFileType.DriverLicense,
  studentId: VerificationFileType.Education,
  employeeId: VerificationFileType.Employee,
  employmentOfferLetter: VerificationFileType.OfferLetter,
  guarantorLetter: VerificationFileType.GuarantorLetter,
};

export const verificationFileTypeToVerificationFormFieldKeyMap: Record<
  VerificationFileType,
  keyof VerificationDetailsFormValues
> = {
  [VerificationFileType.Passport]: 'passport',
  [VerificationFileType.DriverLicense]: 'driverLicence',
  [VerificationFileType.Education]: 'studentId',
  [VerificationFileType.Employee]: 'employeeId',
  [VerificationFileType.OfferLetter]: 'employmentOfferLetter',
  [VerificationFileType.GuarantorLetter]: 'guarantorLetter',
};

export const verificationFormFieldKeyToTitleMap: Record<keyof VerificationDetailsFormValues, string> = {
  passport: 'Passport',
  driverLicence: 'Driver Licence',
  studentId: 'Student ID',
  employeeId: 'Employee ID',
  employmentOfferLetter: 'Employment Offer Letter',
  guarantorLetter: `Guarantor's Letter`,
};
