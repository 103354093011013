import React from 'react';
import { CellProps } from 'react-table';
import { Icon } from 'legacy-components/componets';
import { AdminActionsCell, AdminCommissionTableRow } from '../AdminCommissionTable.types';
import { EllipsisOutlined } from '@ant-design/icons';
import Dropdown from 'rc-dropdown';

export const AdminActionsMenuCell = ({ value }: CellProps<AdminCommissionTableRow, AdminActionsCell>) => {
  const { onCancel, onEdit, state, city } = value;
  const options: {
    label: string;
    icon?: React.JSX.Element;
    onClick: () => void;
  }[] = [
    {
      label: 'Edit',
      icon: <Icon name={'commision-edit'} width={'1em'} height={'1em'} />,
      onClick: onEdit,
    },
    {
      label: 'Delete',
      icon: <Icon name={'xmark'} width={'1em'} height={'1em'} />,
      onClick: onCancel,
    },
  ];

  const overlay = (
    <div
      className={
        'w-[150px] py-6 bg-white rounded-2xl shadow border border-zinc-100 flex-col justify-start items-start flex gap-3'
      }
    >
      {options.map((o) => (
        <button
          type={'button'}
          key={o.label}
          className={
            'w-full px-6 py-4 hover:bg-sky-50 border-r border-transparent hover:border-sky-300 justify-start items-center gap-2.5 flex hover:text-sky-600'
          }
          onClick={o.onClick}
        >
          {o.icon && React.cloneElement(o.icon, { className: 'text-[16px]' })}
          <span className={'text-base font-normal leading-tight'}>{o.label}</span>
        </button>
      ))}
    </div>
  );

  return (
    <>
      {state && city ? (
        <Dropdown trigger={['click']} overlay={overlay} placement={'bottomRight'}>
          <button type={'button'}>
            <EllipsisOutlined className={'text-[24px]'} />
          </button>
        </Dropdown>
      ) : null}
    </>
  );
};
