import { Button, Input } from 'legacy-components/componets';
import { DEFAULT_COMMISSION_END, DEFAULT_COMMISSION_PRICE, DEFAULT_COMMISSION_START } from '../AdminCommission.const';
import { useEditCommission } from 'hooks/query';
import { openNotificationModal } from 'store/use-notification-modal-store';
import { NotificationType } from 'common/enums/enums';
import { useGetDefaultCommissions } from 'hooks/query/use-admin-commission';
import { useState } from 'react';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { stringAsFloatNumber } from 'helpers/input-field.helper';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';

export interface IDefaultCommissionValues {
  defaultCommissionState: string;
}

export const AdminDefaultCommission = () => {
  const { data: defaultCommission } = useGetDefaultCommissions();

  const [isEditDefaultCommision, setEditDefaultCommision] = useState(false);
  const { values, errors, touched, submitForm, setFieldValue } = useFormik<IDefaultCommissionValues>({
    initialValues: { defaultCommissionState: defaultCommission?.fixedRate?.toString() || '' },
    validationSchema: Yup.object({
      defaultCommissionState: Yup.string()
        .test({
          message: 'Default commission must be greater than or equal to 0.',
          test: (value) => {
            const stringValue = value?.toString() || '';
            if (isNotEmptyString(stringValue)) {
              const numericValue = Number(stringValue);
              return !isNaN(numericValue) && numericValue >= 0;
            }
            return true;
          },
        })
        .test({
          message: 'Default commission must be less than 100,000.',
          test: (value) => {
            const stringValue = value?.toString() || '';
            if (isNotEmptyString(stringValue)) {
              const numericValue = Number(stringValue);
              return !isNaN(numericValue) && numericValue <= 100000;
            }
            return true;
          },
        }),
    }),
    onSubmit: (values) => {
      if (defaultCommission?.id) {
        editCommission(
          {
            body: {
              state: null,
              city: null,
              percent: null,
              fixedRate: parseFloat(values?.defaultCommissionState),
            },
            id: defaultCommission?.id || '',
          },
          {
            onSuccess() {
              setEditDefaultCommision(false);
            },
          },
        );
      }
    },
  });

  const { mutate: editCommission, isPending: isLoadingEditCommission } = useEditCommission({
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'Something went wrong',
      });
    },
  });

  const closeEditModeAndSetValue = () =>
    setFieldValue('defaultCommissionState', defaultCommission?.fixedRate?.toString() || '');

  return (
    <>
      {defaultCommission ? (
        <>
          <div className='p-4 bg-secondary my-3 md:text-lg lg:text-xl text-center rounded-xl flex items-center'>
            <div className='flex-grow'>
              {DEFAULT_COMMISSION_START}
              <span className='font-bold'>{DEFAULT_COMMISSION_PRICE(Number(defaultCommission?.fixedRate)) || ''}</span>
              {DEFAULT_COMMISSION_END}
            </div>
            <Button
              icon={'commision-edit'}
              label='Edit'
              theme={isEditDefaultCommision ? 'primary-text' : 'black-text'}
              className='text-base'
              onClick={() => {
                setEditDefaultCommision((prevState) => !prevState);
                closeEditModeAndSetValue();
              }}
              iconClassName='cursor-pointer'
            />
          </div>
          {isEditDefaultCommision && (
            <div className='flex flex-col gap-7 mb-3'>
              <ErrorValidationMessage touched={touched.defaultCommissionState} message={errors.defaultCommissionState}>
                <Input
                  name={'default-commission'}
                  label='Edit default fee'
                  value={values?.defaultCommissionState}
                  onChange={(e) => stringAsFloatNumber(e, `defaultCommissionState`, setFieldValue)}
                />
              </ErrorValidationMessage>
              <div className='flex gap-4'>
                <Button
                  label='Save changes'
                  onClick={submitForm}
                  disabled={!isNotEmptyString(values?.defaultCommissionState) || isLoadingEditCommission}
                />
                <Button
                  label='Close'
                  theme='black-outline'
                  onClick={() => {
                    setEditDefaultCommision(false);
                    closeEditModeAndSetValue();
                  }}
                />
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};
