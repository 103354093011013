import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Modal } from 'legacy-components/componets';
import { FC, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  onReady: () => void;
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void | Promise<void>;
  secret: string;
};

const AddPaymentMethodModal: FC<Props> = ({ onReady, onClose, isOpen, secret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return null;
    }

    const elementsSubmit = await elements.submit();

    const addressEl = elements.getElement(AddressElement);

    if (elementsSubmit.error) {
      return null;
    }

    setSubmitLoading(true);

    stripe
      .confirmSetup({
        elements,
        clientSecret: secret,
        confirmParams: {
          return_url: '',
        },
        // @ts-ignore
        redirect: 'if_required',
      })
      .then(() => {
        addressEl?.clear();
        queryClient.invalidateQueries({ queryKey: ['payment-cards'] });
        onClose();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='p-6 max-h-[98vh] overflow-y-auto'>
        <PaymentElement onReady={onReady} />
        <h3 className='mt-3'>Billing</h3>
        <AddressElement options={{ mode: 'billing' }} />
        <div className='mt-5 flex items-center justify-center'>
          <Button isLoading={submitLoading} label={'Submit'} theme={'green'} onClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export { AddPaymentMethodModal };
