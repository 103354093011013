import { useEffect, useState } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {  ENV } from 'common/enums/enums';
import { Button } from 'legacy-components/componets';
import { CheckoutForm } from 'components/organisms/CheckoutForm/CheckoutForm';
import { PersonaEmbeddedInquiryVerification } from 'components/organisms/Persona/PersonaEmbeddedInquiry';
import { useRequestUserPersonaVerification } from 'hooks/query/auth/use-user-persona-verification';
import { useUserProfileValidation } from 'hooks/query';
import { FormDescription } from 'legacy-components/componets';
import { Loader } from 'legacy-components/componets';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { personaVerificationFee } from 'common/constants/personaVerification';
import { useSignalR, useHub, useClientMethod } from 'hooks';
import { Notification } from 'services/notification.service';
import useGetUserPersonaVerification from 'hooks/query/user/use-persona-verification';
import useGetPersonaVerificationPayment from 'hooks/query/persona/use-get-verification-payment';
import { useRequestPersonaVerificationPayment, useUpdatePersonaVerificationPayment } from 'hooks/query/persona/use-request-verification-payment';



const LoaderPanel = () => {
  return (
    <div className='px-4 w-full min-h-[420px]'>
      <Loader className='h-full z-10 opacity-75 bg-white my-8' />
    </div>
  );
}

const RenterVerificationIdentityForm = () => {
  const [ canVerify, setCanVerify ] = useState(false);
  const [ isStripeLoading, setIsStripLoading ] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const stripePromise = loadStripe(ENV.REPLENISH_PUBLIC_KEY);
  const hubConnection = useSignalR();
  useHub(hubConnection);

  const { data: validationCtx, isLoading: isValidationQueryLoading } =  useUserProfileValidation();
  
  const { data: personaVerification, isLoading: isPersonaVerificationQueryLoading } = 
    useGetUserPersonaVerification({ 
      enabled: false === validationCtx?.isIdentityValid 
    });
  
  const { data: paymentContext, isLoading: isPersonaVerificationPaymentQueryLoading } = 
    useGetPersonaVerificationPayment({
      verificationId: personaVerification?.id
    },{
      enabled: personaVerification?.id != null 
    });
  
  const { mutate: requestPersonaVerification, isPending: isRequestPersonaVerificationPending } =
    useRequestUserPersonaVerification({
      onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: [QueryKey.User, QueryKey.PersonaVerification] });
      },
      // onError: (e) => {
      //   const notification = new Notification();
      //   notification.error(e?.response?.data?.detail ?? 'Something went wrong');
      // }
  }); 

  const { mutate: requestVerificationPayment, isPending: isRequestPersonaVerificationPaymentPending } =  useRequestPersonaVerificationPayment({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.PersonaVerification, 'payment', { verificationId: personaVerification?.id }] });
    },
  // onError: (e) => {
  //   const notification = new Notification();
  //   notification.error(e?.response?.data?.detail ?? 'Something went wrong');
  // }
  });

  const { mutate: updateVerificationPayment, isPending: isUpdatePersonaVerificationPaymentPending } =  useUpdatePersonaVerificationPayment({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.PersonaVerification, 'payment', { verificationId: personaVerification?.id }] });
    },
    // onError: (e) => {
    //   const notification = new Notification();
    //   notification.error(e?.response?.data?.detail ?? 'Something went wrong');
    // }
  });
  
  useClientMethod(hubConnection, 'paymentIntentSucceeded', (message) => {
    if(message?.paymentKey === paymentContext?.paymentKey) {
      setCanVerify(true);
      queryClient.invalidateQueries({ queryKey: [QueryKey.PersonaVerification, 'payment', { verificationId: personaVerification?.id }] });
    }
  });

  // initialize new verification procedure 
  // or get old pending one 
  // useEffect(() => {
  //   if(promptConfirmed) {
  //     requestPersonaVerification();
  //   }       
  // }, [promptConfirmed, requestPersonaVerification]);

  // create verification payment if verification process requested
  // but no pending payment found
  useEffect(() => {
    if(null != personaVerification?.id && null == paymentContext?.id) {
      requestVerificationPayment({ verificationId: personaVerification?.id });
    }
  }, [personaVerification?.id]);


  useEffect(() => {
    if(paymentContext?.status === 'Succeeded') {
      setCanVerify(true);
    }
  }, [paymentContext?.status]);

  /** */
  const isAnyQueryFetching = () => isValidationQueryLoading 
                                || isPersonaVerificationQueryLoading
                                || isPersonaVerificationPaymentQueryLoading
                                || isRequestPersonaVerificationPending
                                || isRequestPersonaVerificationPaymentPending
                                || isUpdatePersonaVerificationPaymentPending;                       

   
  if (isAnyQueryFetching()) {
    return ( <LoaderPanel />);
  }

  /** */
  if (validationCtx?.isIdentityValid) {
    return (
      <div className='md:px-9 px-4 md:py-6 py-4 shadow-white-xl w-full min-h-[420px]'>
        <div className='flex flex-col gap-3'>
          <div className='title text-xl font-medium'>
            Identity Verification <CheckCircleFilled className={'text-lime-300 text-[24px]'} />
          </div>
          <div className='description text-trueGray'>You have successfully verified your identity!</div>
        </div>
      </div>
    );
  }
 
  const handleRequestVerificationClick = () => {
    requestPersonaVerification();   
  }

  /** */
  const isPaymentFormReady = () => paymentContext?.paymentKey !== null
      && paymentContext?.status === 'RequiresPaymentMethod';

  /** */
  const shouldShowPromptButton = () => false === validationCtx?.isIdentityValid
                                    && null == personaVerification?.id;                                    

  const formSubtitleProvider = () => {  
    if(!validationCtx?.isIdentityValid && null == personaVerification?.id) {
      return 'Press the button below to request identity verification.\n' + 
        `You will be charged for a small fee of $${personaVerificationFee}.`;
    }

    if(paymentContext?.status === 'RequiresPaymentMethod') {
      return 'Put your card details in a form below to proceed with payment.'
    }

    return canVerify 
      ? 'Press start button to verify your identity, it shouldn\'t take more than 5 minutes.'
      : 'Please wait while we are processing the request.';
  }

  const options = {
    clientSecret: paymentContext?.paymentKey,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  
  return (
    <div className='md:px-9 px-4 md:py-6 py-4 shadow-white-xl w-full min-h-[420px]'>  
      <FormDescription
        title={'Identity Verification'}
        subTitle={formSubtitleProvider()}
      />      

     {isPaymentFormReady()
        ? (<>
            <div className='p-6 flex justify-center flex-col items-center gap-9'>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm
                  isStripeLoading={isStripeLoading}
                  setIsStripLoading={setIsStripLoading}
                  clientSecret={paymentContext?.paymentKey}
                  callback={() => updateVerificationPayment(personaVerification?.id)}
                  paidSum={paymentContext?.amount}
                />
              </Elements>          
            </div>
          </>         
          )
        : 
          shouldShowPromptButton()
            ? (
              <div className={'flex flex-col gap-3 mb-9 mt-2'}>  
                <Button 
                  className='min-h-[55px]'
                  label={'IDENTITY VERIFICATION'} 
                  onClick={handleRequestVerificationClick}
                  disabled={false}
                  isLoading={false} 
                />
              </div>
               )
            : canVerify
              ? (
                  <div className={'flex flex-col gap-3 mb-9 mt-2'}>
                    <PersonaEmbeddedInquiryVerification verificationId={personaVerification?.id} referenceId={personaVerification?.referenceId} />
                  </div>
                )
              : (<LoaderPanel />)
     }    
     
    </div>
  );
};

export { RenterVerificationIdentityForm as RenterVerificationDetailsForm };

// import { Fragment, useEffect, useMemo, useState } from 'react';
// import { useFormik } from 'formik';
// import { Button, FormDescription, Loader } from 'legacy-components/componets';
// import { validationSchema } from './validationSchema';
// import { FileValue, VerificationDetailsFormValues } from './types';
// import { fields } from './fields';
// import { UploadFileCard } from './upload-file-card';
// import { verificationFileTypeToVerificationFormFieldKeyMap } from './mappings';
// import { useVerificationFiles } from 'hooks/query';
// import { notification } from 'services/services';
// import { useRenterVerificationFiles } from 'hooks/query/auth/use-renter-verification-files';
// import { Progress } from 'antd';
// import { AxiosProgressEvent } from 'axios';
// import { colors } from 'colors';

// const RenterVerificationDetailsForm = () => {
//   const { data: verificationFiles, isLoading: isVerificationFilesLoading } = useVerificationFiles();
//   const [dataUploadingPercent, setDataUploadingPercent] = useState<Record<string, number>>({
//     passport: 0,
//     driverLicence: 0,
//     studentId: 0,
//     employeeId: 0,
//     employmentOfferLetter: 0,
//     guarantorLetter: 0,
//   });
//   const [isUpoadingProgressVisible, setIsUpoadingProgressVisible] = useState(false);
//   const { mutateAsync: updateVerificationFiles, isPending: isPendingVerificationDocuments } =
//     useRenterVerificationFiles({
//       onSuccess: () => {
//         notification.success('Verification files updated successfully');
//       },
//       onError: () => {
//         notification.error('An error occurred while updating the verification files.');
//       },
//     });

//   const updateDataUoadingPercent = (key: string, progress: AxiosProgressEvent) => {
//     setDataUploadingPercent((prevState) => {
//       return {
//         ...prevState,
//         [key]: progress.total ? Math.floor((progress.loaded * 100) / progress.total) : 0,
//       };
//     });
//   };

//   const { values, errors, setFieldValue, handleSubmit, isSubmitting } = useFormik<VerificationDetailsFormValues>({
//     initialValues: {
//       passport: {},
//       driverLicence: {},
//       studentId: {},
//       employeeId: {},
//       employmentOfferLetter: {},
//       guarantorLetter: {},
//     },
//     validationSchema,
//     onSubmit: async (values) => {
//       const filesToUpload = Object.entries(values).filter(([, value]) => value.file);
//       const filesToRemove = Object.entries(values).filter(([, value]) => !!value.id && !value.file && !value.url);
//       setDataUploadingPercent(() => {
//         const filesToUploadPercentList = filesToUpload.reduce((acc, [key]) => {
//           acc[key] = 0;
//           return acc;
//         }, {} as Record<string, number>);
//         return {
//           ...filesToUploadPercentList,
//         };
//       });
//       setIsUpoadingProgressVisible(Boolean(filesToUpload.length));
//       await updateVerificationFiles({ filesToUpload, filesToRemove, onUploadProgress: updateDataUoadingPercent });
//       setIsUpoadingProgressVisible(false);
//     },
//   });

//   const totalDataUploadingPercent = useMemo<number>(() => {
//     const values = Object.values<number>(dataUploadingPercent);
//     return Math.floor(values.reduce((accumulator: number, value: number) => accumulator + value, 0) / values.length);
//   }, [dataUploadingPercent]);

//   useEffect(() => {
//     if (!verificationFiles) return;
//     verificationFiles.forEach((file) => {
//       const fieldValue: FileValue = { url: file.fileUrl, id: file.id };
//       const field = verificationFileTypeToVerificationFormFieldKeyMap[file.type];
//       setFieldValue(field, fieldValue);
//     });
//   }, [verificationFiles]);

//   return (
//     <form className='md:px-9 px-4 md:py-6 py-4 shadow-white-xl w-full' onSubmit={handleSubmit}>
//       <FormDescription title={'Documents Verification'} subTitle={'Please, upload at least 1 document from the list'} />
//       {isVerificationFilesLoading ? (
//         <Loader />
//       ) : (
//         <Fragment>
//           <div className={'flex flex-col gap-3 mt-9'}>
//             {fields.map(({ title, name, format, type }) => (
//               <UploadFileCard
//                 key={name}
//                 type={type}
//                 title={title}
//                 format={format}
//                 onChange={(file) => {
//                   setFieldValue(name, { ...values[name], url: URL.createObjectURL(file), file });
//                 }}
//                 name={name}
//                 fileValue={values[name]}
//                 error={errors[name]?.file}
//                 onDelete={() => setFieldValue(name, { id: values[name].id })}
//               />
//             ))}
//           </div>
//           <div className={'mt-9 flex justify-end gap-3 items-center'}>
//             <Progress
//               strokeColor={colors.primary}
//               percent={totalDataUploadingPercent}
//               className={`m-0 ${isUpoadingProgressVisible ? '' : 'opacity-0'} transition ease-in-out delay-150`}
//             />
//             <Button
//               label={'Submit'}
//               type={'submit'}
//               isLoading={isSubmitting || isPendingVerificationDocuments}
//               disabled={isSubmitting || isPendingVerificationDocuments}
//             />
//           </div>
//         </Fragment>
//       )}
//     </form>
//   );
// };
