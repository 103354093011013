import { Icon } from 'legacy-components/componets';
import { IconProps } from 'common/types/types';
import { formatToLocaleString, joinValues } from 'helpers/helpers';

export type EstateShortPropertyInfoProps = {
  beds: number | string;
  bathrooms: number | string;
  propertyArea: number;
  iconProps?: Omit<IconProps, 'name'>;
};

const EstateShortPropertyInfo = ({ beds, bathrooms, propertyArea, iconProps }: EstateShortPropertyInfoProps) => {
  return (
    <div className='info-icon w-full'>
      <div className='flex justify-between text-sm text-trueGray font-normal'>
        <div className='flex flex-col justify-center gap-2'>
          <Icon name={'bed'} {...iconProps} />
          <span>{`${beds} Beds`}</span>
        </div>
        <div className='flex flex-col justify-center gap-2'>
          <Icon name={'bath'} {...iconProps} />
          <span>{`${bathrooms} Baths`}</span>
        </div>
        <div className='flex flex-col justify-center gap-2'>
          <Icon name={'triangle'} {...iconProps} />
          <span>{joinValues([formatToLocaleString(propertyArea), 'sq ft'], ' ')}</span>
        </div>
      </div>
    </div>
  );
};

export { EstateShortPropertyInfo };
