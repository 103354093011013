import { PREVIEW } from '../LeaseDocuments.constants';
import { downloadFileByUrl } from '../LeaseDocuments.utils';
import { DeleteFilled, DownloadOutlined } from '@ant-design/icons';
import { ReactComponent as LeaseDocumentSvg } from 'assets/icons/lease-document.svg';
import { UploadedFileCardProps } from './UploadedLeaseDocument.types';
import { CSSProperties } from 'react';

export const UploadedLeaseDocument = ({ document, openModal, deleteLeaseDocument }: UploadedFileCardProps) => {
  const { fileName, title, fileId, fileUrl, id: leaseId } = document;

  const textOverflowStyle: CSSProperties = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
  return (
    <div key={`${fileId}`} className='p-4 md:p-8 bg-sky-50 rounded-xl justify-between items-center flex gap-2'>
      <div className='flex items-center gap-4' style={{ overflow: 'hidden' }}>
        <div className='bg-primary p-2 rounded-full'>
          <LeaseDocumentSvg className={`text-white text-[24px]`} />
        </div>
        <div style={{ overflow: 'hidden' }}>
          <div className='text-sky-600 text-base font-bold leading-normal grow' style={{ ...textOverflowStyle }}>
            {title}
          </div>
          <div className='text-trueGray text-sm leading-normal grow' style={{ ...textOverflowStyle }}>
            {fileName}
          </div>
        </div>
      </div>
      <div className='flex items-center gap-10'>
        <button onClick={openModal} type={'button'}>
          <p className={'text-sky-600 text-[18px] font-bold underline'}>{PREVIEW}</p>
        </button>
        <div className='flex items-center gap-2'>
          <button onClick={() => downloadFileByUrl(fileUrl)} type={'button'}>
            <DownloadOutlined className={'text-primary text-[22px]'} />
          </button>
          <button
            onClick={() => {
              deleteLeaseDocument();
            }}
            type={'button'}
          >
            <DeleteFilled className={'text-primary text-[22px]'} />
          </button>
        </div>
      </div>
    </div>
  );
};
