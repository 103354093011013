// @ts-nocheck
import {
  GoogleAddressComponents,
  GooglePlaceDetails,
} from 'legacy-components/fields/place-autocomplete/place-autocomplete';
import {
  PropertyCreateBasicInfoRequestDto,
  PropertyCreateRequestDto,
  PropertyUpdateAccessRequestDto,
  PropertyUpdateAccessScheduleDto,
  PropertyUpdateUnitAccessRequestDto,
  UnitPropertyUpdateAccessScheduleDto,
} from 'common/types/types';
import {
  PropertyAccessDayDto,
  PropertyAccessKey,
  PropertyCategory,
  PropertyDtoAccessKey,
  PropertyDtoCategory,
  PropertyDtoType,
  PropertyPlan,
  PropertyUnitType,
} from 'common/enums/enums';
import {
  PropertyDetailValues,
  PropertyUnitValues,
} from 'legacy-pages/landlord/properties/property-detail/property-detail';
import { dateFormatYYYY_MM_DD, getDate } from 'helpers/date';
import { PropertyBuildingAccessValues } from 'legacy-pages/landlord/properties/property-building-access/property-building-access';
import {
  PropertyBuildingAvailabilityValues,
  PropertyTourAvailability,
} from 'legacy-pages/landlord/properties/property-building-availability/property-building-availability';
import { PropertyValues } from 'legacy-pages/landlord/properties/landlord-properties';
import dayjs from 'dayjs';
import { defaultFeeValue, defaultOtherFeeValue } from 'legacy-pages/landlord/properties/property-detail/config';
import { defaultAvailabilityValues } from 'legacy-pages/landlord/properties/property-building-availability/config';
import {
  defaultEstateRentSpecial,
  defaultRentSpecials,
  defaultUnitRentSpecial,
} from 'legacy-pages/landlord/properties/property-rental-specials/PropertyRentSpecial.config';
import {
  PropertyEstateRentSpecial,
  PropertyUnitRentSpecial,
} from 'legacy-pages/landlord/properties/property-rental-specials/PropertyRentSpecial.types';
import { checkRentSpecialEstateResponse } from 'legacy-pages/landlord/properties/property-rental-specials/PropertyRentSpecial.utils';
import { isNotEmptyString } from 'common/utils/check-empty-string';

export const PType = {
  SingleUnit: PropertyUnitType.Single,
  MultiUnit: PropertyUnitType.Multiple,
};

export type EstateLeaseDocument = {
  fileName: string;
  file: string | File;
};

export function transformDocuments(documentsResponseArray: DocumentsResponse[]): EstateLeaseDocument[] {
  const estateLeaseDocuments: EstateLeaseDocument[] = [];

  documentsResponseArray.forEach((documentResponse) => {
    const { fileName, url } = documentResponse;
    const estateLeaseDocument: EstateLeaseDocument = {
      fileName,
      file: url,
    };
    estateLeaseDocuments.push(estateLeaseDocument);
  });

  return estateLeaseDocuments;
}

function transformSpecialsEstateResponseToRentSpecial(response: PropertyResponse | null): PropertyEstateRentSpecial {
  const specialsResponse = response?.rentSpecial;
  if (specialsResponse?.forAllUnits === false) {
    // case when rent specials are specific for each unit
    return { ...defaultEstateRentSpecial, forAllUnits: false };
  } else if (specialsResponse === null || !checkRentSpecialEstateResponse(specialsResponse)) {
    // case if same rent specials null, it is first time when user create rent special
    return defaultEstateRentSpecial;
  }
  // case when rent specials are the same for each unit
  return {
    rentSpecialTitle: specialsResponse?.title || '',
    rentSpecialStartDate: new Date(specialsResponse?.startDate) || '',
    rentSpecialEndDate: new Date(specialsResponse?.endDate) || '',
    rentSpecialDescription: specialsResponse?.description || '',
    forAllUnits: specialsResponse?.forAllUnits || true,
    estateId: response?.id || '',
  };
}

function transformSpecialsUnitResponseToRentSpecial(response: PropertyResponse | null): PropertyUnitRentSpecial[] {
  // check if same rent specials or type of rent specials does not exist
  if (response?.rentSpecial === null || response?.rentSpecial?.forAllUnits === true) {
    return Array(response?.units?.length).fill({ ...defaultUnitRentSpecial });
  }

  const updatedUnits = response?.units?.map((unit) => {
    // if unit rent special data is incorrect
    if (!checkRentSpecialEstateResponse(unit.rentSpecial)) return defaultUnitRentSpecial;
    // case when rent specials are the specific for each unit
    return {
      rentSpecialTitle: unit.rentSpecial?.title || '',
      rentSpecialStartDate: unit.rentSpecial?.startDate || '',
      rentSpecialEndDate: unit.rentSpecial?.endDate || '',
      rentSpecialDescription: unit.rentSpecial?.description || '',
      unitId: unit?.id || '',
    };
  });
  return updatedUnits ?? Array(response?.units?.length).fill(defaultUnitRentSpecial);
}

export function transformSpecialsResponseToRentSpecial(propertyResponse: PropertyResponse) {
  return {
    estateRentSpecial: transformSpecialsEstateResponseToRentSpecial(propertyResponse),
    estateUnitsRentSpecial: transformSpecialsUnitResponseToRentSpecial(propertyResponse),
  };
}

export const propertyToFormUnits = (resp: PropertyResponse): PropertyUnitValues[] => {
  const defaultUnitValues = {
    availableOn: '',
    baths: 0,
    beds: 0,
    description: '',
    unitNumber: '',
    squareFeet: '',
    leaseDuration: 0,
    rent: '',
    deposit: '',
    firstMonth: '',
    lastMonth: '',
    securityDeposit: defaultFeeValue,
    otherFees: defaultFeeValue,
    otherFeestable: [defaultOtherFeeValue],
    amenities: {},
    access: {
      forAllUnits: true,
      accessInstructions: '',
      accountId: '',
      deviceId: '',
      files: [],
      //@ts-ignore
      keyAccess: PropertyAccessKey.Lockbox,
    },
    availability: defaultAvailabilityValues,
  };

  // TODO: TEMPORARY SOLUTION
  function mapUnitNumber(unitNumber: string) {
    return unitNumber === null || unitNumber === undefined || unitNumber.toLowerCase() === 'n/a' ? '' : unitNumber;
  }

  function fillUnitArrayByDefaultValues(length, inputArray) {
    const result = new Array(length).fill(defaultUnitValues);
    inputArray.slice(0, length).forEach(
      (el, index) =>
        (result[index] = {
          id: el.id,
          availableOn: el.availableOn,
          baths: el.baths,
          beds: el.beds,
          description: el.description ?? '',
          unitNumber: el.unitNumber ? mapUnitNumber(el.unitNumber) : '',
          squareFeet: el.propertyArea.toString() ?? '',
          leaseDuration: el.leaseLength.toString() ?? '',
          rent: el.price.toString() ?? '',
          deposit: el.depositValue.toString() ?? '',
          amenities: el.amenities
            ? el.amenities?.reduce((result, amenity) => {
                result[amenity] = true;
                return result;
              }, {})
            : {},
          access: {
            accessInstructions: el.access?.description ?? '',
            accountId: '',
            deviceId: el.access?.deviceId ?? '',
            files: el.access?.files?.length ? el.access?.files : [],
            //@ts-ignore
            keyAccess: el.access?.accessType
              ? unitAccessToPropertyAccess[el.access?.accessType]
              : PropertyAccessKey.Lockbox,
          },
          availability: el?.schedule
            ? {
                tourAvailability: createAvailability(el.schedule?.dailyTimetables),
                unavailableDates:
                  el?.schedule?.unavailableDates?.length > 0
                    ? el.schedule?.unavailableDates.map((date) => {
                        return {
                          range: {
                            from: getDate(date.from),
                            to: getDate(date.to),
                          },
                          reason: date.reason,
                        };
                      })
                    : [],
              }
            : defaultAvailabilityValues,
          firstMonth: el.firstMonthPrice !== 0 ? el.firstMonthPrice : '',
          lastMonth: el.lastMonthPrice !== 0 ? el.lastMonthPrice : '',
          otherFeestable:
            el.otherFee?.length > 0
              ? el.otherFee?.map((fee) => {
                  return {
                    checked: true,
                    name: fee.name,
                    price: fee.price,
                  };
                })
              : [defaultOtherFeeValue],
        }),
    );
    return result;
  }

  if (resp.units?.length === 0 || !resp?.unitCount || !resp?.units) {
    return [...new Array(resp?.unitCount ?? 0)].map((_) => defaultUnitValues);
  }

  return fillUnitArrayByDefaultValues(resp.unitCount, resp.units);
};

export enum UnitAccess {
  KeyInLockbox = 'KeyInLockbox',
  KeyAtLeasingOffice = 'KeyAtLeasingOffice',
  KeyInDiscreetLocation = 'KeyInDiscreetLocation',
  QRCodeInBuilding = 'QRCodeInBuilding',
  ElectronicLock = 'ElectronicLock',
  Other = 'Other',
}

export const unitAccessToPropertyAccess: Record<UnitAccess, PropertyAccessKey> = {
  [UnitAccess.KeyInLockbox]: PropertyAccessKey.Lockbox,
  [UnitAccess.KeyAtLeasingOffice]: PropertyAccessKey.LeasingOffice,
  [UnitAccess.KeyInDiscreetLocation]: PropertyAccessKey.DiscreetLocation,
  [UnitAccess.QRCodeInBuilding]: PropertyAccessKey.QRCode,
  [UnitAccess.ElectronicLock]: PropertyAccessKey.ElectronicLock,
  [UnitAccess.Other]: PropertyAccessKey.OtherKey,
};

function createAvailability(dailyTimetables: ScheduleDay[]) {
  const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const availability = {};

  // Initialize the availability object with default values
  daysOfWeek.forEach((day) => {
    availability[day] = {
      include: false,
      range: {
        from: '08:00 AM',
        to: '08:00 PM',
      },
    };
  });

  // Iterate through dailyTimetables and update availability accordingly
  dailyTimetables.forEach((timetable) => {
    const dayOfWeek = daysOfWeek[timetable.day];

    // Update include to true
    availability[dayOfWeek].include = true;

    // Update range with fromTime and toTime
    availability[dayOfWeek].range.from = convertFrom24(timetable.fromTime);
    availability[dayOfWeek].range.to = convertFrom24(timetable.toTime);
  });
  return availability;
}

const convertTo24 = (time: string | number) => (time ? dayjs(`1/1/1 ${time}`).format('HH:mm:00') : getDate());
const convertFrom24 = (time: string): string => {
  const parsedTime = dayjs(`1/1/1 ${time}`);
  const formattedTime = parsedTime.format('hh:mm A');
  return formattedTime;
};
export const propertyToForm = (resp: PropertyResponse): PropertyValues => {
  return {
    basics: {
      category: resp.category ?? PropertyCategory.Townhouse,
      unitCount: resp.type === PropertyUnitType.Single ? 1 : resp.unitCount,
      type: resp.type ? PType[resp.type] : PropertyUnitType.Single,
      location: {
        address: [resp.street, resp.state, resp.city, resp.zipcode].filter(Boolean).join(', '),
        street: resp.street,
        state: resp.state,
        city: resp.city,
        zipcode: resp.zipcode,
        lat: resp.latitude,
        lng: resp.longitude,
      },
    },
    plan: resp.chargePlan || PropertyPlan.Basic,
    details: {
      images: resp.images?.length ? resp?.images : [],
      videos: [],
      units: propertyToFormUnits(resp),
      amenities: resp.amenities
        ? resp.amenities?.reduce((result, amenity) => {
            result[amenity] = true;
            return result;
          }, {})
        : {},
      generalDescription: resp.description,
    },
    access: {
      forAllUnits:
        resp.access?.forAllUnits !== null && resp.access?.forAllUnits !== undefined ? resp.access?.forAllUnits : true,
      accessInstructions: resp.access?.description ?? '',
      accountId: '',
      deviceId: resp.access?.deviceId ?? '',
      files: resp.access?.files?.length ? resp.access?.files : [],
      //@ts-ignore
      keyAccess: resp.access?.accessType
        ? unitAccessToPropertyAccess[resp.access?.accessType]
        : PropertyAccessKey.Lockbox,
    },
    availability: resp?.schedule
      ? {
          forAllUnits:
            resp.schedule?.forAllUnits !== null && resp.schedule?.forAllUnits !== undefined
              ? resp.schedule?.forAllUnits
              : true,
          tourAvailability: createAvailability(resp.schedule?.dailyTimetables),
          unavailableDates:
            resp?.schedule?.unavailableDates?.length > 0
              ? resp.schedule?.unavailableDates.map((el) => {
                  return {
                    range: {
                      from: getDate(el.from),
                      to: getDate(el.to),
                    },
                    reason: el.reason,
                  };
                })
              : [],
        }
      : defaultAvailabilityValues,
    lease: {
      files: transformDocuments(resp.documents) ?? [],
      link: '',
      title: '',
      checkbox: false,
      leaseId: '',
      chosenNewFile: !!resp.documents?.length,
      canBeSubmitted: false,
      isNewDocument: !!resp.documents?.length,
    },
    specials: transformSpecialsResponseToRentSpecial(resp) ?? defaultRentSpecials(resp?.units?.length),
  };
};

export const propertyDataToDtoCreateProperty = (
  details: GooglePlaceDetails | null,
  type: PropertyDtoType,
  category: PropertyDtoCategory,
): PropertyCreateBasicInfoRequestDto => {
  const getAddressComponentName = (item: GoogleAddressComponents, searchTypes: string[]) => {
    return item.types.every((type) => searchTypes.includes(type));
  };

  const city =
    details?.address_components.find((item) => getAddressComponentName(item, ['locality', 'political']))?.long_name ||
    '' ||
    details?.address_components.find((item) =>
      getAddressComponentName(item, ['administrative_area_level_3', 'political']),
    )?.long_name ||
    details?.address_components.find((item) =>
      getAddressComponentName(item, ['administrative_area_level_1', 'political']),
    )?.long_name ||
    '';

  const state = details.adr_address.split('</span>').find((el) => el.includes('region'))
    ? details.adr_address
        .split('</span>')
        .find((el) => el.includes('region'))
        .split('">')[1]
    : '';

  const street = details.adr_address.split('</span>').find((el) => el.includes('street'))
    ? details.adr_address
        .split('</span>')
        .find((el) => el.includes('street'))
        .split('">')[1]
    : '';

  const zipcode =
    details?.address_components.find((item) => getAddressComponentName(item, ['postal_code']))?.long_name || '';

  return {
    state,
    city,
    street,
    zipcode,
    type,
    category,
  };
};

// TODO: TEMPORARY SOLUTION
const mapUnitNumber = (unitNumber: string, typeOfEstate: PropertyUnitType) => {
  return typeOfEstate === PropertyUnitType.Single && !isNotEmptyString(unitNumber.trim()) ? 'n/a' : unitNumber;
};

export const propertyUnitsToPropertyUnitsDto = (
  estateId: string,
  details: PropertyDetailValues,
  typeOfEstate: PropertyUnitType,
): PropertyCreateRequestDto => {
  return {
    estateId,
    units: details.units.map((unit) => {
      return {
        estateId,
        id: unit.id ?? '',
        unitNumber: mapUnitNumber(unit.unitNumber, typeOfEstate),
        beds: Number(unit.beds),
        baths: Number(unit.baths),
        propertyArea: Number(unit.squareFeet),
        availableOn: unit.availableOn,
        depositType: 'Months',
        depositValue: Number(unit.deposit),
        price: Number(unit.rent),
        description: unit.description,
        leaseLength: Number(unit.leaseDuration),
        firstMonthPrice: Number(unit.firstMonth),
        lastMonthPrice: Number(unit.lastMonth),
        otherFee:
          unit.otherFeestable.filter((fee) => isNotEmptyString(fee?.name) && Number(fee?.price) > 0).length > 0
            ? unit.otherFeestable
                .filter((fee) => isNotEmptyString(fee?.name) && Number(fee?.price) > 0)
                .map((otherFee) => {
                  return {
                    name: otherFee?.name,
                    price: Number(otherFee?.price),
                  };
                })
            : [],
        amenities: Object.entries(unit.amenities)
          .filter(([, value]) => value)
          .map(([key]) => key),
      };
    }),
    generalDescription: details.generalDescription,
    amenities: Object.entries(details.amenities)
      .filter(([, value]) => value)
      .map(([key]) => key),
    images: details.images || [],
  };
};

export const propertyKeyAccessToPropertyKeyAccessRequest: Record<PropertyAccessKey, PropertyDtoAccessKey> = {
  [PropertyAccessKey.Lockbox]: PropertyDtoAccessKey.KeyInLockbox,
  [PropertyAccessKey.QRCode]: PropertyDtoAccessKey.QRCodeInBuilding,
  [PropertyAccessKey.DiscreetLocation]: PropertyDtoAccessKey.KeyInDiscreetLocation,
  [PropertyAccessKey.LeasingOffice]: PropertyDtoAccessKey.KeyAtLeasingOffice,
  [PropertyAccessKey.ElectronicLock]: PropertyDtoAccessKey.ElectronicLock,
  [PropertyAccessKey.OtherKey]: PropertyDtoAccessKey.Other,
};

export const propertyScheduleDayToPropertyScheduleDayDto: Record<keyof PropertyTourAvailability, PropertyAccessDayDto> =
  {
    sunday: PropertyAccessDayDto.Sunday,
    monday: PropertyAccessDayDto.Monday,
    tuesday: PropertyAccessDayDto.Tuesday,
    wednesday: PropertyAccessDayDto.Wednesday,
    thursday: PropertyAccessDayDto.Thursday,
    friday: PropertyAccessDayDto.Friday,
    saturday: PropertyAccessDayDto.Saturday,
  };

export const propertyAccessToPropertyAccessDto = (
  estateId: string,
  values: PropertyBuildingAccessValues,
): PropertyUpdateAccessRequestDto => {
  return {
    estateId,
    accessKeyData: {
      estateId,
      accessType: propertyKeyAccessToPropertyKeyAccessRequest[values.keyAccess],
      description: values.accessInstructions,
      deviceId: values.deviceId,
      forAllUnits: true,
    },
    //@ts-ignore
    accessFilesData: values.files.map((file) => ({
      estateId,
      file,
    })),
  };
};

export const propertyUnitAccessToPropertyAccessDto = (
  estateId: string,
  values: PropertyBuildingAccessValues,
  unitId: string,
): PropertyUpdateUnitAccessRequestDto => {
  return {
    estateId,
    unitId,
    accessKeyData: {
      estateId,
      unitId,
      accessType: propertyKeyAccessToPropertyKeyAccessRequest[values.keyAccess],
      description: values.accessInstructions,
      deviceId: values.deviceId,
      forAllUnits: false,
    },
    //@ts-ignore
    accessFilesData: values.files.map((file) => ({
      estateId,
      file,
      unitId,
    })),
  };
};

export const getAvailabilityDays = (days: PropertyTourAvailability) => {
  return Object.keys(days)
    .filter((el) => {
      const key = el as keyof PropertyTourAvailability;
      return days[key]?.include;
    })
    .map((key) => {
      const kayName = key as keyof PropertyTourAvailability;
      return {
        day: propertyScheduleDayToPropertyScheduleDayDto[kayName],
        fromTime: convertTo24(days[kayName]?.range?.from),
        toTime: convertTo24(days[kayName]?.range?.to),
      };
    });
};

export const propertyAccessToPropertyScheduleDto = (
  estateId: string,
  values: PropertyBuildingAvailabilityValues,
): PropertyUpdateAccessScheduleDto => {
  const availabilityDays = getAvailabilityDays(values?.tourAvailability);
  const unavailableDays = values?.unavailableDates
    .map((el) => ({
      from: el.range.from !== null ? dateFormatYYYY_MM_DD(el.range.from) : null,
      to:
        el.range.to !== null
          ? dateFormatYYYY_MM_DD(el.range.to)
          : el.range.from !== null
          ? dateFormatYYYY_MM_DD(el.range.from)
          : null,
      reason: el.reason,
    }))
    .filter((unavailableDate) => unavailableDate?.from !== null);

  return {
    estateId,
    fromDate: getDate(),
    dailyTimetables: availabilityDays,
    unavailableDays,
    forAllUnits: true,
  };
};

export const propertyAccessToUnitPropertyScheduleDto = (
  estateId: string,
  values: PropertyBuildingAvailabilityValues,
  unitId: string,
): UnitPropertyUpdateAccessScheduleDto => {
  const availabilityDays = Object.keys(values?.tourAvailability)
    .filter((el) => {
      const key = el as keyof PropertyTourAvailability;
      return values?.tourAvailability[key]?.include;
    })
    .map((key) => {
      const keyName = key as keyof PropertyTourAvailability;
      return {
        day: propertyScheduleDayToPropertyScheduleDayDto[keyName],
        fromTime: convertTo24(values?.tourAvailability[keyName].range.from),
        toTime: convertTo24(values?.tourAvailability[keyName].range.to),
      };
    });

  const unavailableDays = values?.unavailableDates
    .map((el) => ({
      from: el.range.from !== null ? dateFormatYYYY_MM_DD(el.range.from) : null,
      to:
        el.range.to !== null
          ? dateFormatYYYY_MM_DD(el.range.to)
          : el.range.from !== null
          ? dateFormatYYYY_MM_DD(el.range.from)
          : null,
      reason: el.reason,
    }))
    .filter((unavailableDate) => unavailableDate?.from !== null);

  return {
    estateId,
    unitId,
    fromDate: getDate(),
    dailyTimetables: availabilityDays,
    unavailableDays,
  };
};

export enum PropertyStatus {
  Incomplete = 'Incomplete',
  PendingApproval = 'PendingApproval',
  Available = 'Available',
}

export enum PropertyType {
  SingleUnit = 'SingleUnit',
  MultiUnit = 'MultiUnit',
}

export enum UnitDepositType {
  Months = 'Months',
  Money = 'Money',
}

export enum UnitsCountType {
  OneToTen = 'oneToTen',
  ElevenToTwenty = 'elevenToTwenty',
  OverTwenty = 'overTwenty',
}

export type OtherFeeType = {
  name: string;
  price: number;
};

export type UnitResponse = {
  id?: string;
  unitNumber: string | null;
  beds: number;
  baths: number;
  propertyArea: number;
  leaseLength: number;
  availableOn: string | Date;
  price: number;
  depositType: UnitDepositType | null;
  depositValue: number;
  description: string | null;
  firstMonthPrice: number;
  lastMonthPrice: number;
  otherFee: OtherFeeType[];
  amenities: string[] | null;
  access: AccessResponse | null;
  rentSpecial: UnitSpecialsResponse | null;
};

export type ImageResponse = {
  id: string;
  originalUrl: string;
  thumbnailUrl: string;
  isDefault: boolean;
};

export type LocalImage = {
  file: File;
  isDefault: boolean;
};

type AccessResponse = {
  accessType: UnitAccess | null;
  description: string | null;
  deviceId: string | null;
  files: AccessFile[] | null;
  forAllUnits: boolean | null;
};

export type AccessFile = {
  id: string;
  thumbnailUrl: string;
  originalUrl: string;
  isDefault: boolean;
};

export type ScheduleResponse = {
  fromDate: string;
  toDate: string | null;
  dailyTimetables: ScheduleDay[] | null;
  forAllUnits: boolean | null;
  unavailableDates: UnAvailableDateForTour[];
};

export type UnAvailableDateForTour = {
  from: string;
  to: string;
  reason: string;
};

type ScheduleDay = {
  [key: number]: any;
  day: number;
  fromTime: string;
  toTime: string;
};

export type DocumentsResponse = {
  id: string;
  fileName: string | null;
  url: string | null;
};

export type SpecialsResponse = {
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  forAllUnits: boolean;
};

export type UnitSpecialsResponse = Omit<SpecialsResponse, 'forAllUnits'> & {
  unitId: string;
};

export type PropertyResponse = {
  id: string;
  status: PropertyStatus | null;
  unitCount: number | null;
  street: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  type: PropertyType | null;
  category: PropertyCategory | null;
  chargePlan: PropertyPlan | null;
  amenities: string[] | null;
  units: UnitResponse[] | null;
  images: ImageResponse[] | null;
  access: AccessResponse | null;
  schedule: ScheduleResponse | null;
  documents: Array<DocumentsResponse | null> | null;
  rentSpecial: SpecialsResponse | null;
  description: string | null;
};
