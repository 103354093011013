import styles from './styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ScrollButton } from 'legacy-components/scroll-button';
import { EstateTypeDto } from 'common/enums/enums';
import { EstateCard } from 'components/organisms/EstateCard';
import { EstateListDashboardDto } from 'common/types/services/api/estates/estate-list-dto.type';
import { FC, Fragment, ReactNode } from 'react';
import { EstateCardSkeletonVertical } from 'components/organisms/EstateCard/EstateCardSkeletonVertical';
import { Link } from 'react-router-dom';

export type EstatesCardListProps = {
  estates: EstateListDashboardDto | null;
  onLoad: () => void;
  loader: ReactNode;
  hasNextPage: boolean;
};

const EstatesCardList: FC<EstatesCardListProps> = ({ estates, onLoad, loader, hasNextPage }) => {
  const getCardLink = (type: EstateTypeDto, id: string, unitId: string) => {
    // if (type === EstateTypeDto.MultiUnit) {
    //   return `/estates/${id}?unitId=${unitId}`;
    // } else {
    //   return `/estates/${id}`;
    // }
    return `/estates/${id}?unitId=${unitId}`;
  };

  if (!estates) {
    return (
      <Fragment>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.list + ' w-full'}>
              <EstateCardSkeletonVertical />
              <EstateCardSkeletonVertical />
              <EstateCardSkeletonVertical />
              <EstateCardSkeletonVertical />
              <EstateCardSkeletonVertical />
              <EstateCardSkeletonVertical />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <InfiniteScroll
          className='w-full'
          style={{
            overflow: 'visible',
          }}
          dataLength={Number(estates?.items?.length)}
          next={onLoad}
          hasMore={hasNextPage}
          loader={loader}
        >
          <div className={styles.list}>
            {estates.items.map((estate, idx) => (
              <Link to={getCardLink(estate.type, estate.id, estate.unitId)} key={estate.id + idx} aria-label={`Go to ${estate.location}`}>
                <EstateCard
                  status={estate.status}
                  city={estate.city}
                  location={estate.location}
                  price={estate.price}
                  priceMin={estate.priceMin}
                  priceMax={estate.priceMax}
                  category={estate.category}
                  units={estate.units}
                  beds={estate.beds}
                  bathrooms={estate.bathrooms}
                  propertyArea={estate.propertyArea}
                  type={estate.type}
                  thumbnailUrl={estate.thumbnailUrl}
                />
              </Link>
            ))}
          </div>
        </InfiniteScroll>
      </div>
      <ScrollButton />
    </div>
  );
};

export { EstatesCardList };
