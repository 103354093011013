import React from 'react';
import ReactSelect, { MultiValue } from 'react-select';
import { MultiSelectProps, SelectOption } from 'common/types/types';

import styles from './styles';

const MultiSelect = ({
  name,
  options,
  value,
  defaultValue,
  label,
  placeholder,
  theme = 'default',
  disabled,
  invalid,
  selectStyle,
  labelStyle,
  onChange,
  isSearchable = false,
  selectRef,
  selectDropdownRef,
}: MultiSelectProps) => {
  const selectedValue = React.useMemo(() => {
    if (options && value) {
      return value.map(v => {
        const selectedElement = options.find((option) => option.value?.toString() === v?.toString());
        return selectedElement && selectedElement?.value !== '' && selectedElement;
      });
    }

    return null;
  }, [value, options]);

  const handleChange = (option: any) => {
    onChange(option.map(o => o.value));
  };

  return (
    <div ref={selectRef}>
      {label && (
        <div className={styles.label[theme]} style={labelStyle}>
          {label}
        </div>
      )}
      <ReactSelect
        isMulti
        ref={selectDropdownRef}
        name={name}
        options={options}
        defaultValue={defaultValue}
        styles={styles.select(theme, invalid, selectStyle)}
        onChange={handleChange}
        value={selectedValue}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isDisabled={disabled}
      />
    </div>
  );
};

export { MultiSelect };
