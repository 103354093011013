import { Link, useNavigate } from 'react-router-dom';
import { Divider } from 'legacy-components/componets';
import { adminDropdownMenuList } from './config';
import styles from './styles';
import { logout } from 'services/api/auth-api.service';

const AdminProfileDropdown = () => {
  const navigate = useNavigate();

  const handleLandlordChangePasswordMenuItemClick = () => {
    navigate('admin/change-password');
  };

  return (
    <div className={styles.container}>
      {[...adminDropdownMenuList].map(({ title, id, link }) => (
        <Link key={id} className={styles.item} to={link}>
          {title}
        </Link>
      ))}
      <Divider />
      <div className={styles.item} onClick={handleLandlordChangePasswordMenuItemClick}>
        Change Password
      </div>
      <div className={styles.item} onClick={logout}>
        Log Out
      </div>
    </div>
  );
};

export { AdminProfileDropdown };
