enum ApplicationsTableAccessor {
  Tenant = 'tenant',
  Income = 'income',
  Applied = 'applied',
  Status = 'status',
  CreditScore = 'creditScore',
  Payload = 'payload',
  Offered = 'offered',
}

export { ApplicationsTableAccessor };
