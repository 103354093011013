import { AuthPermission } from 'common/enums/enums';
import { useAuth } from 'hooks';
import { HasPermissions } from 'common/types/types';

export const useHasPermissions = (permissions: HasPermissions) => {
  const { isAuthenticated, user } = useAuth();

  const allowedPermissions = Array.isArray(permissions) ? permissions : [permissions];

  if (!isAuthenticated) {
    return false;
  }

  return user?.permissions.some((permission: AuthPermission) => allowedPermissions.includes(permission));
};
