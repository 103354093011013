import { Select } from 'antd';
import { ContactUsFormDtoStatusEnum } from 'common/generated/types/contact-us-form-dto';
import { adminFeedbackStatusMapToText } from 'components/atoms/AdminFeedbackStatus/AdminFeedbackStatus.types';
import { FiltersMenuFormProps } from 'components/molecules/Filters/Filters';
import { questionTitleOptions } from 'legacy-pages/help-support/HelpSupportModal.config';
import { FC } from 'react';

const AdminFeedbacksFiltersForm: FC<FiltersMenuFormProps> = ({ values, setFieldValue, id }) => {
  const { status, title } = values;

  const getSelectPopupContainer = (): HTMLElement => document.getElementById(id) as HTMLElement;

  return (
    <div className='flex flex-col gap-3 flex-1 w-full'>
      <div className='flex flex-col w-full'>
        <label className={'block mb-2 text-base font-semibold text-black'}>{'Status'}</label>
        <Select
          style={{
            maxWidth: '375px',
          }}
          allowClear
          mode='multiple'
          placeholder='Select statuses'
          getPopupContainer={getSelectPopupContainer}
          size='large'
          value={status || null}
          onChange={(value: string) => {
            setFieldValue('status', value);
          }}
          options={Object.values(ContactUsFormDtoStatusEnum).map((v) => ({
            value: v,
            label: adminFeedbackStatusMapToText[v],
          }))}
        />
      </div>
      {/* <div className='flex flex-col w-full'>
        <label className={'block mb-2 text-base font-semibold text-black'}>{'Title of Question'}</label>
        <Select
          style={{
            minWidth: '300px',
            height: '40px'
          }}
          allowClear
          placeholder='Select title'
          getPopupContainer={getSelectPopupContainer}
          size='large'
          value={title || null}
          onChange={(value: string) => {
            setFieldValue('title', value);
          }}
          options={questionTitleOptions}
        />
      </div> */}
    </div>
  );
};

export { AdminFeedbacksFiltersForm };
