import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';
import { ToastMessage } from 'legacy-components/ui/toast-message/toast-message';

const DEFAULT_MESSAGE = 'Unexpected error';

class Notification {
  public error(message = DEFAULT_MESSAGE, toastOption?: ToastOptions): void {
    toast.error<string>((props) => ToastMessage({ ...props, data: message }), {
      ...toastOption,
    });
  }

  public success(message = DEFAULT_MESSAGE, toastOption?: ToastOptions): void {
    toast.success<string>((props) => ToastMessage({ ...props, data: message }), { ...toastOption });
  }

  public warning(message = DEFAULT_MESSAGE, toastOption?: ToastOptions): void {
    toast.warning<string>((props) => ToastMessage({ ...props, data: message }), { ...toastOption });
  }

  public info(message = DEFAULT_MESSAGE, toastOption?: ToastOptions): void {
    toast.info<string>((props) => ToastMessage({ ...props, data: message }), {
      ...toastOption,
    });
  }
}

export { Notification };
