import { PropertyBuildingAvailabilityValues, PropertyTourAvailability } from './property-building-availability.types';
export const defaultTourAvailability = {
  sunday: { include: true, range: { from: '08:00 AM', to: '08:00 PM' } },
  monday: { include: true, range: { from: '08:00 AM', to: '08:00 PM' } },
  tuesday: { include: true, range: { from: '08:00 AM', to: '08:00 PM' } },
  wednesday: { include: true, range: { from: '08:00 AM', to: '08:00 PM' } },
  thursday: { include: true, range: { from: '08:00 AM', to: '08:00 PM' } },
  friday: { include: true, range: { from: '08:00 AM', to: '08:00 PM' } },
  saturday: { include: true, range: { from: '08:00 AM', to: '08:00 PM' } },
};

export const defaultAvailabilityValues: PropertyBuildingAvailabilityValues = {
  forAllUnits: true,
  tourAvailability: defaultTourAvailability,
  unavailableDates: [],
};

export const tourAvailabilityDays: Array<keyof PropertyTourAvailability> = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
