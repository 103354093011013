import { CellProps } from 'react-table';
import { AdminCommissionTableRow, TextCellProps } from '../AdminCommissionTable.types';

export const AdminTextCell = ({ value: lanlordName }: CellProps<AdminCommissionTableRow, TextCellProps>) => {
    return (
        <div className='flex gap-3'>
            <div className={'text-black text-base text-start'}>
                {lanlordName}</div>
        </div>
    );
};
