import { Fragment, useState } from 'react';
import { List } from 'antd';
import { Loader } from 'legacy-components/componets';
import {
  useGetAdminFeedbacks,
  useSetDoneFeedback,
  useSetInProgressFeedback,
  useSetNewFeedback,
} from 'hooks/query/use-admin-feedbacks';
import { NumberParam, StringParam, createEnumDelimitedArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { TablePagination } from 'legacy-components/ui/table/table-pagination';
import { openNotificationModal } from 'store/use-notification-modal-store';
import { NotificationType } from 'common/enums/enums';
import { AdminFeedbacksListItem } from './AdminFeedbacksListItem';
import { Modal } from 'components/organisms/Modal';
import { ContactUsFormDto, ContactUsFormDtoStatusEnum } from 'common/generated/types/contact-us-form-dto';
import { notification } from 'services/services';

const maxItemPerPage = 5;

const AdminFeedbacksList = () => {
  const [previewModalData, setPreviewModalData] = useState<{
    isOpen: boolean;
    id: ContactUsFormDto['id'] | null;
  }>({
    isOpen: false,
    id: null,
  });

  const [query, setQuery] = useQueryParams({
    title: withDefault(StringParam, ''),
    status: withDefault(
      createEnumDelimitedArrayParam(
        Object.values(ContactUsFormDtoStatusEnum) as `${ContactUsFormDtoStatusEnum}`[],
        ',',
      ),
      [],
    ),
    page: withDefault(NumberParam, 1),
  });

  const { data, isLoading, isFetching } = useGetAdminFeedbacks(
    {
      page: query.page,
      perPage: maxItemPerPage,
      sorters: { createdAt: 'desc' },
      filters: {
        title: query.title,
        status: query.status,
      },
    },
    {
      placeholderData: (previousValue) => previousValue,
    },
  );

  const { mutate: setDoneFeedback, isPending: isSettingDonePending } = useSetDoneFeedback({
    onSuccess: () => {
      notification.success('You have successfully setted feedback to DONE status');
    },
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'Something went wrong',
      });
    },
  });

  const { mutate: setInProgressFeedback, isPending: isSettingInProgressPending } = useSetInProgressFeedback({
    onSuccess: () => {
      notification.success('You have successfully setted feedback to IN PROGRESS status');
    },
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'Something went wrong',
      });
    },
  });

  const { mutate: setNewFeedback, isPending: isSettingNewPending } = useSetNewFeedback({
    onSuccess: () => {
      notification.success('You have successfully setted feedback to NEW status');
    },
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'Something went wrong',
      });
    },
  });

  const closePreviewModal = () => {
    setPreviewModalData((prevState) => {
      setTimeout(() => {
        setPreviewModalData({
          isOpen: false,
          id: null,
        });
      }, 300);
      return {
        isOpen: false,
        id: prevState.id,
      };
    });
  };

  if (isLoading || !data?.items) {
    return <Loader style={{ minHeight: 600 }} />;
  }

  return (
    <Fragment>
      <div className='relative'>
        {(isFetching || isSettingDonePending || isSettingInProgressPending || isSettingNewPending) && (
          <Loader className='h-full absolute z-10 opacity-75 bg-white' />
        )}
        <List
          style={{
            minHeight: 550
          }}
          itemLayout='vertical'
          dataSource={data.items}
          renderItem={(item) => (
            <AdminFeedbacksListItem
              data={item}
              actions={{
                onViewMore: () => {
                  setPreviewModalData({
                    isOpen: true,
                    id: item.id ?? '',
                  });
                },
                onSetDone: () => setDoneFeedback(item.id ?? ''),
                onSetInProgress: () => setInProgressFeedback(item.id ?? ''),
                onSetNew: () => setNewFeedback(item.id ?? ''),
              }}
            />
          )}
        />
        <div className='mt-3 mx-[-34px]'>
          <TablePagination
            currentPage={query.page}
            totalCount={data.totalCount ?? 0}
            pageSize={maxItemPerPage}
            onPageChange={(newPage) => {
              setQuery((prev) => ({ ...prev, page: newPage }));
            }}
          />
        </div>
      </div>
      <Modal title="Feedback preview" isOpen={previewModalData.isOpen} contentClassName='w-[715px]' onClose={closePreviewModal}>
        {previewModalData.id && (
          <List itemLayout='vertical'>
            <AdminFeedbacksListItem data={data.items.find((item) => item.id === previewModalData.id)!} hasFullMessage />
          </List>
        )}
      </Modal>
    </Fragment>
  );
};

export { AdminFeedbacksList };
