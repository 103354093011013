import { Modal } from 'components/organisms/Modal';
import { FC } from 'react';
import { ViewRejectionReasonModalProps } from './ViewRejectionReason.types';
import { OfferStatusDto } from 'common/enums/enums';
import { NotificationTypeIcon } from 'legacy-components/componets';
import { isNotEmptyString } from 'common/utils/check-empty-string';

export const WARNING_MSG = 'This offer was rejected';
export const REASON = 'Rejection reason:';
export const REASON_NOT_EXISTS = 'Landlord did not provide a reason for rejection.';

export const ViewRejectionReasonModal: FC<ViewRejectionReasonModalProps> = ({ isOpen, onClose, offer }) => {
  return (
    <Modal title='Rejection reason' isOpen={isOpen} contentClassName='w-[715px]' onClose={onClose}>
      {offer && (
        <>
          <div>
            {offer?.status === OfferStatusDto.Reject && isNotEmptyString(offer?.rejectReason) && (
              <>
                <div className='flex p-2 items-center justify-start gap-3 rounded-xl bg-secondary w-max'>
                  <NotificationTypeIcon type='error' />
                  <div className='text-base text-error'>{WARNING_MSG}</div>
                </div>
                <div className='flex flex-col p-2 items-start'>
                  <div className='text-base font-bold mb-2'>{REASON}</div>
                  <div className='text-base border border-primary w-full pl-4 p-2 rounded-lg text-wrapper text-ellipsis overflow-hidden ...'>
                    {offer?.rejectReason}
                  </div>
                </div>
              </>
            )}
          </div>
          {!isNotEmptyString(offer?.rejectReason) && (
            <div className='flex flex-col p-2 items-start'>
              <div className='text-base font-bold mb-2'>{REASON}</div>
              <div className='text-base text-trueGray w-full pl-4 p-2 rounded-lg text-wrapper text-ellipsis overflow-hidden ...'>
                {REASON_NOT_EXISTS}
              </div>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};
