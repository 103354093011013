import { CloseOutlined } from "@ant-design/icons";
import { Modal, NotificationTypeIcon } from "legacy-components/componets";
import { NotificationModalProps } from "./NotificationModal.types";
import { getNotificationMessageColorClass } from "helpers/notification";

export const NotificationModal: React.FC<NotificationModalProps> = ({ open, onClose, title, description, notificationType }) => {
    const messageColor = getNotificationMessageColorClass(notificationType);
    return (
        <Modal isOpen={open} onClose={onClose} styles={{
            content: {
                borderRadius: '20px',
                maxHeight: '100%',
            }
        }}>
            <div className='p-3 bg-white min-w-[320px] w-full max-w-[550px]'>
                <div className='flex flex-col gap-6 m-4'>
                    <div className='flex items-top justify-center'>
                        <div className='flex-grow flex items-center justify-center'>
                            <NotificationTypeIcon type={notificationType} size={'l'} />
                        </div>
                        <button onClick={onClose} type={'button'} className='flex justify-start'>
                            <CloseOutlined className={'text-primary text-[22px]'} />
                        </button>
                    </div>
                    <div className='flex items-center justify-center text-center'>
                        <h4 className={`text-2xl ${messageColor}`}>{title}</h4>
                    </div>
                    {description && <div className='flex items-center justify-center'>
                        <p className='text-trueGray text-opacity-80 text-base text-center'>{description}</p>
                    </div>}
                </div>
            </div>
        </Modal>
    );
};
