import { FormDescription } from 'legacy-components/componets';
import { PaymentCardsListControlled } from 'legacy-components/payment-cards-list/controlled';
import { AddPaymentMethod } from 'legacy-components/payment/add-payment-method';
import { useDeletePaymentCard, usePaymentCards } from 'hooks/query';

const LandlordPaymentDetailsForm = () => {
  const { data: paymentCards } = usePaymentCards();
  const { mutate: deletePaymentCard } = useDeletePaymentCard();

  return (
    <div className='flex flex-1 flex-col gap-9'>
      <div className='personal-details px-4 md:px-9 py-6 shadow-white-xl'>
        <div className='mb-3'>
          <FormDescription title={'Payment cards'} />
        </div>
        <PaymentCardsListControlled list={paymentCards} handleDeleteItem={deletePaymentCard} />
        <AddPaymentMethod />
      </div>
    </div>
  );
};

export { LandlordPaymentDetailsForm };
