// @ts-nocheck
import { useState, useRef, useEffect, FC } from 'react';

type Props = {
  callback?: (code: string) => void;
  reset?: any;
  isLoading?: boolean;
  isError?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>
};

const InputCode: FC<Props> = ({ callback, reset, isLoading, isError, onChange }) => {
  const [code, setCode] = useState('');

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  const resetCode = () => {
    inputRefs.forEach((ref) => {
      ref.current.value = '';
    });
    inputRefs[0].current.focus();
    setCode('');
  };

  useEffect(() => {
    if (code.length === 6) {
      if (typeof callback === 'function') callback(code);
    }
  }, [code]); //eslint-disable-line

  // Listen for external reset toggle
  useEffect(() => {
    resetCode();
  }, [reset]); //eslint-disable-line

  function handleInput(e, index) {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    // Update code state with single digit
    const newCode = [...code];
    // Convert lowercase letters to uppercase
    if (/^[a-z]+$/.test(input.value)) {
      const uc = input.value.toUpperCase();
      newCode[index] = uc;
      inputRefs[index].current.value = uc;
    } else {
      newCode[index] = input.value;
    }
    setCode(newCode.join(''));

    input.select();

    if (input.value === '') {
      // If the value is deleted, select previous input, if exists
      if (previousInput) {
        previousInput.current.focus();
      }
    } else if (nextInput) {
      // Select next input on entry, if exists
      nextInput.current.select();
    }
  }

  function handleFocus(e) {
    e.target.select();
  }

  // Handle backspace key
  function handleKeyDown(e, index) {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    if ((e.keyCode === 8 || e.keyCode === 46) && input.value === '') {
      e.preventDefault();
      setCode((prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1));
      if (previousInput) {
        previousInput.current.focus();
      }
    }
  }

  // Capture pasted characters
  const handlePaste = (e) => {
    const pastedCode = e.clipboardData.getData('text');
    if (pastedCode.length === 6) {
      setCode(pastedCode);
      inputRefs.forEach((inputRef, index) => {
        inputRef.current.value = pastedCode.charAt(index);
      });
    }
  };

  return (
    <div className='flex justify-between gap-3 xl:gap-[18px] relative'>
      {inputRefs.map((_, index) => (
        <input
          className={`text-3xl min-[470px]:text-[40px] ${
            isError ? 'text-error border border-error ' : 'text-primary border focus:border-primary border-gray'
          } w-[40px] min-[470px]:w-[50px] sm:text-[40px] sm:w-[60px] md:w-[50px] lg:w-[70px] lg:text-[50px] flex p-2 text-center rounded-md outline-none`}
          key={index}
          type='text'
          maxLength={1}
          onChange={(e) => {
            handleInput(e, index)
            onChange?.(e)
          }}
          ref={inputRefs[index]}
          autoFocus={index === 0}
          onFocus={handleFocus}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          disabled={isLoading}
        />
      ))}
    </div>
  );
};

export { InputCode };
