import React, { FC } from 'react';
import { Label } from 'legacy-components/ui/label/label';
import { UploadFiles } from 'legacy-components/fields/upload-files/upload-files';
import { UploadedImagesIndex } from 'legacy-components/uploaded-images-index/uploaded-images-index';

type Props = {
  videos: File[];
  setVideos: (videos: File[]) => void;
};

const PropertyVideo: FC<Props> = ({ videos, setVideos }) => {
  const handleRemoveUploadedVideo = (index: number) => {
    setVideos(videos.filter((_, i) => i !== index));
  };

  return (
    <div className='relative flex items-center justify-center w-full h-full p-3'>
      <div className='absolute inset-0 bg-[#E5E5E5] opacity-30'></div>
      <div className='z-10 font-semibold absolute inset-0 flex items-center justify-center'>
        <h1 className='mb-4 xs:text-2xl md:text-3xl lg:text-4xl font-extrabold leading-none tracking-tight text-gray-900 '>
          Coming soon! <mark className='px-2 text-white bg-primary rounded'>Axxelist</mark>
        </h1>
      </div>
      <div className='pointer-events-none'>
        <div className='upload-video'>
          <Label label={'Add videos of your property'}>
            <UploadFiles
              title={'Videos must be in mp4 format'}
              accept={{ 'video/mp4': ['.mp4'] }}
              onDrop={(videos) => setVideos([...videos, ...videos])}
            />
            {!!videos.length && (
              <UploadedImagesIndex
                videos={videos.map((video) => URL.createObjectURL(video))}
                onRemove={handleRemoveUploadedVideo}
              />
            )}
          </Label>
        </div>
      </div>
    </div>
  );
};

export default PropertyVideo;
