import * as Yup from 'yup';
import { PropertyPlan, PropertyUnitType } from 'common/enums/enums';
import dayjs from 'dayjs';
import _ from 'lodash';
import { isNotEmptyString } from 'common/utils/check-empty-string';

const maxPropertyImageSize = 1024 * 1024 * 20; // 20MB
const supportedPropertyImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/heic', 'image/heif'];

const propertyFeesValidationSchema = Yup.object().shape({
  checked: Yup.boolean(),
  price: Yup.number()
    .moreThan(0)
    .when('checked', (checkbox, schema) => {
      return checkbox.at(0)
        ? schema.required('Required field').min(500, 'Minimum amount is 500$').max(20_000, 'Maximum amount is 20 000$')
        : schema.notRequired();
    }),
});

export const propertyDetailsValidationSchema = (
  plan: PropertyPlan,
  category: PropertyUnitType,
  // NOTE: if it will be used for EDIT mode, need to have an isEdit variable for properly validation
  isEdit: boolean = false,
) => {
  const planLength = () => {
    if (plan === PropertyPlan.Basic) {
      return 20;
    } else if (plan === PropertyPlan.Pro) {
      return 30;
    }
  };

  return Yup.object().shape({
    units: Yup.array()
      .required()
      .min(1)
      .of(
        Yup.object().shape({
          unitNumber: Yup.string()
            .test('is-required', 'Unit number is required', function (value) {
              const { createError, path } = this;

              if (category === PropertyUnitType.Multiple) {
                if (!value) {
                  return createError({
                    path,
                    message: 'Unit number is required',
                  });
                }
              }

              return true;
            })
            .max(10, 'Unit number cannot exceed 10 characters'),

          beds: Yup.number().required().moreThan(0).min(1).max(5),
          baths: Yup.number().required().moreThan(0).min(1).max(5),
          squareFeet: Yup.number().required().moreThan(0),
          leaseDuration: Yup.number().required().moreThan(0),
          availableOn: isEdit
            ? Yup.string().notRequired()
            : Yup.string()
                .required('Available on is required')
                .test('test-date', 'Date must be today or a future date', function (value) {
                  const { path } = this;
                  if (!value || value === 'Invalid Date') {
                    throw new Yup.ValidationError(`Invalid type of date`, value, `${path}`);
                  }
                  const availableOnDate = dayjs(value);
                  const today = dayjs().startOf('day');
                  if (availableOnDate.isBefore(today)) {
                    throw new Yup.ValidationError(`Date must be today or a future date`, value, `${path}`);
                  }
                  return true;
                }),
          rent: Yup.number()
            .typeError('Rent is a required field')
            .required('Rent is a required field')
            .min(500, 'Minimum rent amount is 500$')
            .max(20_000, 'Maximum rent amount is 20 000$'),
          deposit: Yup.number().required().moreThan(0),
          description: Yup.string(),
          firstMonth: Yup.number()
            .typeError('Required field')
            .required('Required field')
            .min(500, 'Minimum amount is 500$')
            .max(20_000, 'Maximum amount is 20 000$'),
          lastMonth: Yup.string().test('can-be-saved', 'Please input valid price', function (value) {
            if (isNotEmptyString(value || '')) {
              const amount = Number(value);
              if (amount < 500) {
                throw new Yup.ValidationError('Minimum amount is 500$', value, this.path);
              } else if (amount > 20000) {
                throw new Yup.ValidationError('Maximum amount is 20 000$', value, this.path);
              }
            }
            return true;
          }),
          securityDeposit: propertyFeesValidationSchema,
          otherFeestable: Yup.array()
            .required()
            .min(1)
            .of(
              Yup.object().shape({
                // checked: Yup.boolean().notRequired(),
                // name: Yup.string().when('checked', (checkbox, schema) => {
                //   return checkbox.at(0) ? schema.required() : schema.notRequired();
                // }),
                // price: Yup.number()
                //   .moreThan(0)
                //   .max(20000, 'Maximum amount is 20 000$')
                //   .when('checked', (checkbox, schema) => {
                //     return checkbox.at(0)
                //       ? schema
                //           .required('Required field')
                //           .min(0.1, 'Minimum amount is 0.1$')
                //           .max(20_000, 'Maximum amount is 20 000$')
                //       : schema.notRequired();
                //   }),
                name: Yup.string().test('test-name', 'Enter both the name and the price of the fee', function (value) {
                  if (isNotEmptyString(value || '')) {
                    const price = this.resolve(Yup.ref('price')) as string;
                    return isNotEmptyString(price);
                  }
                  return true;
                }),
                price: Yup.string()
                  .test('test-price', 'Enter both the name and the price of the fee', function (value) {
                    if (isNotEmptyString(value?.toString() || '')) {
                      const name = this.resolve(Yup.ref('name')) as string;
                      return isNotEmptyString(name);
                    }
                    return true;
                  })
                  .test('test-price-amount', 'Minimum amount is 0.1$', function (value) {
                    if (isNotEmptyString(value?.toString() || '')) {
                      const price = Number(value);
                      return price > 0;
                    }
                    return true;
                  })
                  .test('test-price-amount', 'Maximum amount is 20 000$', function (value) {
                    if (isNotEmptyString(value?.toString() || '')) {
                      const price = Number(value);
                      return price < 20000;
                    }
                    return true;
                  }),
              }),
            ),
        }),
      )
      .test('unique-unit-numbers', 'Each unit number must be unique', function (value) {
        const unitNumbers = value.map((unit) => unit.unitNumber);
        const { path } = this;

        const errors: Yup.ValidationError[] = [];
        for (let firstItem = 0; firstItem < unitNumbers.length; firstItem++) {
          for (let secondItem = 0; secondItem < firstItem; secondItem++) {
            if (unitNumbers[firstItem] === unitNumbers[secondItem]) {
              errors.push(
                new Yup.ValidationError(
                  `Unit number must be unique`,
                  value[firstItem],
                  `${path}[${firstItem}].unitNumber`,
                ),
              );
            }
          }
        }

        if (errors.length > 0) {
          throw new Yup.ValidationError(errors);
        }

        return true;
      }),
    generalDescription: Yup.string().notRequired(),
    images: Yup.array()
      .of(
        Yup.mixed()
          .test('fileSize', 'Photos must be in jpg or png format and no larger than 20MB in size', (img: any) => {
            const isFileSizeValid = img?.file?.size <= maxPropertyImageSize; // 20MB
            if (isFileSizeValid || !!img.id) return true;
          })
          .test('fileType', 'The file type must be jpg or png format', (img: any) => {
            const isFileTypeValid = supportedPropertyImageTypes.includes(img?.file?.type);
            if (isFileTypeValid || !!img.id) return true;
          }),
      )
      .test('plan', `The maximum number of photos in your plan is ${planLength()}`, (value) => {
        if (value?.length !== undefined && value?.length <= planLength()!) return true;
        else return false;
      }),
  });
};
