import { CellProps } from 'react-table';
import { DropdownMenu, Icon } from 'legacy-components/componets';
import { DropdownMenuItem } from 'legacy-components/dropdown-menu/common/types/dropdown-menu-item';

import Dropdown from 'rc-dropdown';
import { ToursScheduleTableActionsCellType, ToursScheduleTableRowType } from '../types';
import { AuthRole, TourStatusDto } from 'common/enums/enums';
import { useHasRoles } from 'hooks';

export const LandlordToursActionsMenuCell = ({
  value,
}: CellProps<ToursScheduleTableRowType, ToursScheduleTableActionsCellType>) => {
  const { onCancel, onView, tourStatus: status, onViewDocuments, onConfirm, isConfirmed } = value;
  const isLandlord = useHasRoles([AuthRole.Landlord]);

  const menuItems: DropdownMenuItem[] = [
    {
      id: 1,
      title: 'Confirm Tour',
      onClick: () => onConfirm?.(),
      icon: 'check',
      visible: status === TourStatusDto?.Scheduled && !isConfirmed,
    },
    {
      id: 2,
      title: 'View details',
      onClick: () => onView?.(),
      icon: 'faEye',
      visible: true,
    },
    // {
    //   id: 3,
    //   title: `View renter's documents`,
    //   onClick: () => onViewDocuments?.(),
    //   icon: 'files',
    //   visible: true,
    // },
    // NOTE: HIDE OPTION ( AX-738 )
    // {
    //   id: 3,
    //   title: 'Cancel',
    //   onClick: () => onCancel?.(),
    //   icon: 'shape',
    //   visible: isLandlord && (status === TourStatusDto?.Scheduled || status === TourStatusDto?.Reserved),
    // },
  ].filter((o) => o.visible) as DropdownMenuItem[];

  return (
    <Dropdown trigger={['click']} overlay={<DropdownMenu menuItems={menuItems} headerTitle={'Actions'} />}>
      <Icon name={'dots-menu'} onClick={() => {}} />
    </Dropdown>
  );
};
