import React from 'react';
import { BadgeProps } from 'common/types/types';

import styles from './styles';
import { twMerge } from 'tailwind-merge';

const Badge = ({ title, theme = 'gray', size = 's', style, className }: BadgeProps) => {
  return (
    <span className={twMerge(styles[theme][size], className)} style={style}>
      {title}
    </span>
  );
};

export { Badge };
