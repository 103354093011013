import clsx from 'clsx';
import { PropertyStepperItems } from 'common/enums/enums';
import { StepperProps } from 'common/types/types';

const Stepper = ({ steps, isStepSubmitted, isStepVisited, activeItemId, onClick, className }: StepperProps) => {
  return (
    <div className={`flex flex-col p-4 gap-9 ${className}`}>
      {steps.map(({ title, id, extra }, index) => {
        const isActive = activeItemId === id;

        const isSubmitted = isStepSubmitted
          ? isStepSubmitted[id as PropertyStepperItems] || Object.values(isStepSubmitted).every(Boolean)
          : true;

        const isVisited = isStepVisited ? isStepVisited[id as PropertyStepperItems] : true;

        const onClickHandler = () => {
          if ((isSubmitted || isVisited) && !!onClick) onClick(id);
        };

        return (
          <div
            key={index}
            onClick={onClickHandler}
            className={clsx(
              'step-item flex items-center gap-2 cursor-pointer',
              !isSubmitted && !isActive && !isVisited && 'opacity-50',
            )}
          >
            <div
              className={clsx(
                'number flex-center text-sm rounded-full font-bold text-trueGray bg-gray',
                isActive && 'text-primary bg-white',
              )}
            >
              <span className={clsx('flex-center w-[25px] h-[25px]', isActive && 'text-primary')}>{index + 1}</span>
            </div>

            <div className={`title text-base ${isActive ? 'text-primaryDark font-bold' : 'text-trueGray'}`}>
              {title}
            </div>

            {extra}
          </div>
        );
      })}
    </div>
  );
};

export { Stepper };
