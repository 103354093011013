import { NumberParam, StringParam, withDefault } from 'use-query-params';
import { CommissionFilters } from './AdminCommission.types';
import { ENV } from 'common/enums/enums';

export const defaultFilters: CommissionFilters = {
  state: '',
  city: '',
  commissionType: 'percent',
};

export const filtersOptions = [
  { value: 'fixedRate', label: 'Fixed' },
  { value: 'percent', label: 'Percentage' },
];

export const defaultGoogleConfig = {
  apiKey: ENV.REACT_APP_GOOGLE,
  libraries: ['places'],
  debounce: 200,
  options: {
    componentRestrictions: { country: ['us', 'ca'] },
  },
  language: 'en',
};

export const stateGoogleConfig = {
  apiKey: ENV.REACT_APP_GOOGLE,
  debounce: 200,
  options: {
    componentRestrictions: { country: ['us', 'ca'] },
    types: ['administrative_area_level_1'],
  },
  language: 'en',
};

export const cityGoogleConfig = {
  apiKey: ENV.REACT_APP_GOOGLE,
  debounce: 200,
  options: {
    componentRestrictions: { country: ['us', 'ca'] },
    types: ['locality', 'administrative_area_level_3'],
  },
  language: 'en',
};

export const defaultParams = {
  state: withDefault(StringParam, ''),
  city: withDefault(StringParam, ''),
  page: withDefault(NumberParam, 1),
};
