import ModalContainer, { Styles } from 'react-modal';
import { ModalProps } from 'common/types/types';

const Modal = ({ children, isOpen, onClose, styles, isMobile }: ModalProps) => {
  const customStyles: Styles = {
    overlay: {
      zIndex: 999,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(23, 23, 23, 0.4)',
      ...styles?.overlay,
    },
    content: isMobile
      ? {
          left: '0%',
          top: '0%',
          right: 'auto',
          bottom: 'auto',
          transform: 'none',
          padding: 0,
          border: 0,
          ...styles?.content,
        }
      : {
          // top: '50%',
          // left: '50%',
          // right: 'auto',
          // bottom: 'auto',
          // marginRight: '-50%',
          // transform: 'translate(-50%, -50%)',
          // padding: 0,
          // border: 0,

          // position: 'absolute',
          minWidth: '340px',
          maxWidth: '100%',
          left: '50%',
          top: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          border: 0,
          ...styles?.content,
        },
  };

  return (
    <ModalContainer isOpen={isOpen} onRequestClose={onClose} style={customStyles} ariaHideApp={false}>
      {children}
    </ModalContainer>
  );
};

export { Modal };
