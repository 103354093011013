import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import { NavLink } from 'react-router-dom';
import { Button, Icon } from 'legacy-components/componets';
import { changePasswordValidationSchema } from 'validations/change-password.validation';
import { ValidationError } from 'yup';
import { groupBy, mapValues } from 'lodash-es';
import PasswordInput from 'legacy-components/ui/password-input/password-input';
import { resetPassword } from 'services/api/auth-api.service';
import { AppRoute } from 'common/enums/route/app-route.enum';
import { notification } from 'services/services';
import { useNavigate } from 'react-router';

export interface IChangePasswordValues {
  password: string;
  confirmPassword: string;
}

type Props = {
  token: string;
};

const ChangePasswordForm: FC<Props> = ({ token }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { values, errors, touched, handleChange, submitForm, isSubmitting } = useFormik<IChangePasswordValues>({
    initialValues: { password: '', confirmPassword: '' },
    validate: async (values) => {
      try {
        await changePasswordValidationSchema.validate(values, { abortEarly: false });
        return {};
      } catch (err) {
        if (!ValidationError.isError(err)) return {};
        return mapValues(groupBy(err.inner, 'path'), (e) => e.flatMap((e) => e.errors));
      }
    },
    onSubmit: async (values) => await handleSubmit(values),
  });
  const navigate = useNavigate();

  const handleSubmit = async ({ password }: IChangePasswordValues) => {
    await resetPassword({ token, password });
    navigate(AppRoute.SIGN_IN);
    notification.success('Password has been changed');
  };

  const showError = (fieldName: keyof IChangePasswordValues): boolean => {
    return Boolean(errors[fieldName] && touched[fieldName]);
  };

  return (
    <div className='flex justify-center items-center pt-[calc(100vh/2_-_95px_-_260px)]'>
      <div className='flex-col md:w-[350px] w-[327px]'>
        <div className={`title text-[24px] font-bold text-primary md:text-center text-left w-full mb-9`}>
          Change password
        </div>
        {errorMessage && (
          <div className={`text-sm text-error p-2 bg-red-100 rounded-lg flex justify-between mb-9`}>
            <p>{errorMessage}</p>
            <div className='cursor-pointer' onClick={() => setErrorMessage('')}>
              <Icon name={'times-circle'} />
            </div>
          </div>
        )}
        <div className={showError('password') ? 'mb-2' : 'mb-9'}>
          <PasswordInput
            showError={showError('password')}
            showHintPopup
            errors={errors.password}
            name={'password'}
            placeholder={'Password'}
            value={values.password}
            onChange={handleChange}
          />
        </div>
        <div className={showError('confirmPassword') ? 'mb-2' : 'mb-9'}>
          <PasswordInput
            showError={showError('confirmPassword')}
            errors={errors.confirmPassword}
            name={'confirmPassword'}
            placeholder={'Confirm Password'}
            value={values.confirmPassword}
            onChange={handleChange}
          />
        </div>
        <div className='mb-6'>
          <Button
            label={'Submit'}
            type='submit'
            theme='primary'
            onClick={submitForm}
            isFullWidth
            isLoading={isSubmitting}
            disabled={isSubmitting}
          />
        </div>
        <div className='w-full text-sm text-warmGray text-left'>
          <NavLink to={'/auth/sign-in'} className='text-primary'>
            Go back to Login
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export { ChangePasswordForm };
