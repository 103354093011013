import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { TourDetailsDto } from 'common/types/services/api/tours/tour-details-dto.type';
import { getLandlordTourDetails } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useLandlordTourDetails(
  tourId: string,
  options?: Omit<
    UndefinedInitialDataOptions<TourDetailsDto | undefined, Error, TourDetailsDto, string[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    ...options,
    queryKey: [QueryKey.Tours, tourId],
    queryFn: () => getLandlordTourDetails(tourId),
  });
}
