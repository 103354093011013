import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';
import { deletePropertyImage } from 'services/api/property-api.service';

export default function useDeletePropertyImage(
  options?: UseMutationOptions<void, AxiosError<BackendError>, { estateId: string; id: string }, unknown>,
) {
  return useMutation({
    ...options,
    mutationFn: deletePropertyImage,
  });
}
