import React from 'react';
import { dateFormatMMMM_D_YYYY } from 'helpers/date';

export type EstatesDateInfoProps = {
  createdAt?: Date | string;
  updateAt?: Date | string;
};

const EstatesDateInfo = ({ createdAt, updateAt }: EstatesDateInfoProps) => {
  const uploadedDate = React.useMemo<string>(() => {
    return `Uploaded: ${dateFormatMMMM_D_YYYY(createdAt)}`;
  }, [createdAt]);

  const updatedDate = React.useMemo<string>(() => {
    return `Updated: ${dateFormatMMMM_D_YYYY(updateAt)}`;
  }, [updateAt]);

  return createdAt || uploadedDate ? (
    <div className='date-info text-base text-trueGray'>
      {createdAt && <div className='create-at mt-[12px]'>{uploadedDate}</div>}
      {/* {uploadedDate && <div className='update-at mt-[12px]'>{updatedDate}</div>} */}
    </div>
  ) : null;
};

export { EstatesDateInfo };
