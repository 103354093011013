import { CellProps } from 'react-table';
import { ApplicationsTableRow } from '../common/types/types';
import { ApplicationsStatusCell } from '../common/types/applications-table-row';
import { OfferStatus } from 'components/atoms/OfferStatus/OfferStatus';

const OffersStatusCell = ({ value }: CellProps<ApplicationsTableRow, ApplicationsStatusCell>) => {
  const { status } = value;

  return <OfferStatus offerStatus={status} />;
};

export { OffersStatusCell };
