import React from 'react';
import avatar from 'assets/icons/avatar-default.svg';
import { useEstateExtra } from 'hooks/query';

export type EstateManagementCompanyProps = {
  estateId: string;
};

const EstateManagementCompany = ({ estateId }: EstateManagementCompanyProps) => {
  const { data: estateExtra } = useEstateExtra(estateId);

  if (!estateExtra) return null;

  return (
    <div className={'relative'}>
      <div className='bg-secondary p-[24px] rounded-2xl'>
        <div className='font-bold lg:text-lg text-sm mb-4'>Listed by management company</div>
        <div className='flex lg:flex-row flex-col justify-between items-center gap-4'>
          <div className='flex lg:w-auto w-full items-center'>
            <img
              src={estateExtra?.ownerThumbnailUrl || avatar}
              className='rounded-full w-[51px] h-[51px] mx-auto cursor-pointer'
              alt='Avatar'
            />
            <div className='pl-[11px] grow'>
              <h5 className='text-black lg:text-xl text-base font-medium leading-tight mb-2.5'>
                {estateExtra?.ownerName}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { EstateManagementCompany };
