import { LocationPin } from 'legacy-components/location-pin/location-pin';
import { LocationMarker } from 'common/types/types';

const MapLocationPin = ({ lat, lng }: LocationMarker) => {
  return (
    <LocationPin
      lat={lat}
      lng={lng}
      width={32}
      height={40}
      type={'solid'}
      style={{
        transform: 'translate(-50%, -50%)',
        zIndex: 999,
        position: 'relative',
        pointerEvents: 'none',
        userSelect: 'none',
      }}
    />
  );
};

export { MapLocationPin };
