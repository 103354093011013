import { DatePicker, Select } from 'antd';
import { AuthRole } from 'common/enums/enums';
import { PaidTourStatusDto, TourStatusDto } from 'common/enums/services/api/tours/tour-status-dto.enum';
import { mapTourStatus } from 'components/atoms/TourStatus/TourStatus.utils';
import { FiltersMenuFormProps } from 'components/molecules/Filters/Filters';
import dayjs from 'dayjs';
import { useAuth } from 'hooks';
import { FC } from 'react';

const LandlordToursFiltersForm: FC<FiltersMenuFormProps> = ({ values, setFieldValue, id }) => {
  const { status, showOnly, dateFrom, dateTo } = values;
  const { user } = useAuth();
  const isAdmin = user?.roles.includes(AuthRole.SuperUser);
  const isLandlord = user?.roles.includes(AuthRole.Landlord);

  const getSelectPopupContainer = (): HTMLElement => document.getElementById(id) as HTMLElement;

  return (
    <div className='flex flex-col gap-3 flex-1 w-full'>
      <div className='flex flex-col w-full'>
        <label className={'block mb-2 text-base font-semibold text-black'}>{'Status'}</label>
        <Select
          style={{
            maxWidth: '375px',
          }}
          allowClear
          mode='multiple'
          placeholder='Select statuses'
          getPopupContainer={getSelectPopupContainer}
          size='large'
          value={status || null}
          onChange={(value: string) => {
            setFieldValue('status', value);
          }}
          options={Object.values(isAdmin ? TourStatusDto : PaidTourStatusDto).map((v) => ({
            value: v,
            label: mapTourStatus[v],
          }))}
        />
      </div>
      <div className='flex items-center gap-3 w-full'>
        <div className='flex flex-col w-full'>
          <label className={'block mb-2 text-base font-semibold text-black'}>{'Date range'}</label>
          <DatePicker
            name={`dateFrom`}
            value={dateFrom ? dayjs(dateFrom) : null}
            placeholder='From'
            format={'YYYY-MM-DD'}
            inputReadOnly
            size='large'
            getPopupContainer={getSelectPopupContainer}
            onChange={(date) => {
              const formattedDate = dayjs(date).format('YYYY-MM-DD');
              if (formattedDate === 'Invalid Date') {
                setFieldValue('dateFrom', '');
              } else {
                setFieldValue('dateFrom', formattedDate);
              }
            }}
          />
        </div>
        <div className='flex flex-col w-full'>
          <DatePicker
            className='mt-8'
            name={`dateTo`}
            inputReadOnly
            format={'YYYY-MM-DD'}
            placeholder='To'
            value={dateTo ? dayjs(dateTo) : null}
            size='large'
            getPopupContainer={getSelectPopupContainer}
            onChange={(date) => {
              const formattedDate = dayjs(date).format('YYYY-MM-DD');
              if (formattedDate === 'Invalid Date') {
                setFieldValue('dateTo', '');
              } else {
                setFieldValue('dateTo', formattedDate);
              }
            }}
          />
        </div>
      </div>
      {isLandlord && (
        <div className='flex flex-col w-full'>
          <label className={'block mb-2 text-base font-semibold text-black'}>{'Show only Tours'}</label>
          <Select
            style={{
              maxWidth: '375px',
            }}
            allowClear
            placeholder='Select statuses'
            getPopupContainer={getSelectPopupContainer}
            size='large'
            value={showOnly || null}
            onChange={(value: string) => {
              setFieldValue('showOnly', value);
            }}
            options={[
              {
                value: 'confirmed',
                label: 'Confirmed',
              },
              {
                value: 'unconfirmed',
                label: 'Unconfirmed',
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export { LandlordToursFiltersForm };
