import { SavedSearchApiPath } from 'common/enums/services/api/paths/saved-search-api-path.enum';
import {
  CreateSavedSearchFiltersDto,
  CreateSavedSearchRequest,
  GetSavedSearchResultRequestDto,
  SavedSearchEstateDto,
  SavedSearchUnreadResult,
} from 'common/types/services/api/user/user-saved-search.type';
import qs from 'qs';
import { axiosInstance } from './axiosInstance';

export const createSavedSearch = (data: CreateSavedSearchRequest): Promise<string> => {
  return axiosInstance.post(`${SavedSearchApiPath.SAVED_SEARCH}`, data);
};

export const getSavedSearch = (): Promise<CreateSavedSearchFiltersDto[]> => {
  return axiosInstance.get(`${SavedSearchApiPath.SAVED_SEARCH}`);
};

export const deleteSavedSearch = (id: string): Promise<boolean> => {
  return axiosInstance.delete(`${SavedSearchApiPath.SAVED_SEARCH}/${id}`);
};

export const getSavedSearchUnreadCount = (): Promise<SavedSearchUnreadResult> => {
  return axiosInstance.get(`${SavedSearchApiPath.SAVED_SEARCH}/unread`);
};

export const getSavedSearchResult = (query: GetSavedSearchResultRequestDto): Promise<SavedSearchEstateDto> => {
  const queryString = qs.stringify(query);
  return axiosInstance.get(`${SavedSearchApiPath.SAVED_SEARCH}/result?${queryString}`);
};

export const readAllSavedSearch = (): Promise<string> => {
  return axiosInstance.post(`${SavedSearchApiPath.SAVED_SEARCH}/read`);
};

export const deleteSavedSearchResult = (id: string): Promise<boolean> => {
  return axiosInstance.delete(`${SavedSearchApiPath.SAVED_SEARCH}/result/${id}`);
};
