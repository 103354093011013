import { ArrowProps } from 'common/types/types';

const ArrowLeft = ({ width, height, color = 'white', onClick }: ArrowProps) => (
  <div
    className='absolute top-0 left-0 flex-center h-full pl-6 pr-2 cursor-pointer z-10'
    onClick={onClick}
  >
    <div className='flex flex-center bg-opacity-75 bg-primary rounded-full w-12 h-12'>
      <svg aria-label='Previous picture' xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 17 30' fill='none'>
        <path
          d='M16.3219 1.31999C16.1478 1.14537 15.9409 1.00684 15.7131 0.912314C15.4853 0.81779 15.2411 0.769135 14.9944 0.769135C14.7478 0.769135 14.5036 0.81779 14.2758 0.912314C14.048 1.00684 13.8411 1.14537 13.6669 1.31999L1.05195 13.935C0.912892 14.0738 0.80257 14.2386 0.727298 14.42C0.652026 14.6015 0.613281 14.796 0.613281 14.9925C0.613281 15.1889 0.652026 15.3835 0.727298 15.5649C0.80257 15.7464 0.912892 15.9112 1.05195 16.05L13.6669 28.665C14.4019 29.4 15.5869 29.4 16.3219 28.665C17.0569 27.93 17.0569 26.745 16.3219 26.01L5.31195 15L16.3369 3.97499C17.0569 3.23999 17.0569 2.05499 16.3219 1.31999Z'
          fill={color}
        />
      </svg>
    </div>
  </div>
);

export default ArrowLeft;
