import { EstatePlanDto } from 'common/types/services/api/estates/estate-plan-dto.type';

export function checkFreePeriodDates(data: EstatePlanDto[] | undefined): string | null {
  if (!data) return null;

  const uniqueDates = new Set(data.map((plan) => plan.freePeriodUntilDate));

  if (uniqueDates.size === 1) {
    return uniqueDates.values().next().value;
  } else {
    return null;
  }
}
