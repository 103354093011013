import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { RescheduleTourResponse } from 'common/types/services/api/tours/reschedule-tour-response.type';
import { rescheduleTour } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

type Variables = { id: string; slot: Date };

export default function useRescheduleTour(
  options?: UseMutationOptions<RescheduleTourResponse, Error, Variables, unknown>,
) {
  const queryClient = useQueryClient();

  return useMutation<RescheduleTourResponse, Error, Variables, unknown>({
    mutationFn: ({ id, slot }) => rescheduleTour(id, slot),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Tours] });
    },
    ...options,
  });
}
