import { isObject } from 'lodash-es';

export const getDeepObjectEntries = (object: Object): [string, any][] => {
  const entries: [string, any][] = [];
  if (Array.isArray(object)) {
    object.forEach((v, i) => {
      if (isObject(v)) {
        getDeepObjectEntries(v)
          .map(([key2, value2]) => [`[${i}]${Array.isArray(value2) ? '' : '.'}${key2}`, value2] as [string, any])
          .forEach((entry) => {
            entries.push(entry);
          });
      } else {
        entries.push([`[${i}]`, v]);
      }
    });
  } else {
    Object.entries(object).forEach(([key1, value1]) => {
      if (isObject(value1)) {
        getDeepObjectEntries(value1)
          .map(([key2, value2]) => [`${key1}${Array.isArray(value1) ? '' : '.'}${key2}`, value2] as [string, any])
          .forEach((entry) => {
            entries.push(entry);
          });
      } else {
        entries.push([key1, value1]);
      }
    });
  }
  return entries;
};
