import { TextareaTheme } from 'common/types/types';

const styles: Record<TextareaTheme, { container: string; label: string; textarea: string; error: string }> = {
  default: {
    container: '',
    label: 'block mb-1 text-sm font-medium text-black',
    textarea:
      'block shadow-input-shadow w-full h-[100px] px-3 py-2 text-black border border-neutral-200 rounded-md hover:border-primary focus:border-primary focus:outline-none transition',
    error: '!border-error !focus:border-error',
  },
  primary: {
    container: '',
    label: 'block mb-1 text-sm font-medium text-black',
    textarea:
      'block w-full h-[100px] px-3 py-2 text-black border border-warmGray rounded-[6px] focus:border-primary focus:outline-none',
    error: '!border-b-error !focus:border-b-error',
  },
};

export default styles;
