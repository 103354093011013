import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { cancelSubscription } from 'services/api/payment-api.service';
import { notification } from 'services/services';

export default function useCancelSubscription(
  options?: Omit<UseMutationOptions<void, Error, string, unknown>, 'mutationFn'>,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: cancelSubscription,
    onSuccess: () => {
      notification.success('You successfully canceled a subscription. Please wait subscription status updating');
    },
    onError: (e) => {
      notification.error('You could not cancel subscription. Try again...');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Estates, 'subscription'] });
    },
    ...options,
  });
}
