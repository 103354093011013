import React from 'react';
import { CellProps } from 'react-table';
import { AccessAccountsTableRow } from 'common/types/types';
import { getDayjs } from 'helpers/date';

const AccessAccountsDateCell = ({ value }: CellProps<AccessAccountsTableRow, Date | string>) => {
  return <div className='text-base font-medium'>{getDayjs(value).format('D, MMM YYYY, h:mm A')}</div>;
};

export { AccessAccountsDateCell };
