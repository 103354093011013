const CanadaStates = [
  {
    name: 'Alberta',
    abbreviation: 'AB',
  },
  {
    name: 'British Columbia',
    abbreviation: 'BC',
  },
  {
    name: 'Manitoba',
    abbreviation: 'MB',
  },
  {
    name: 'New Brunswick',
    abbreviation: 'NB',
  },
  {
    name: 'Newfoundland and Labrador',
    abbreviation: 'NL',
  },
  {
    name: 'Nova Scotia',
    abbreviation: 'NS',
  },
  {
    name: 'Northwest Territories',
    abbreviation: 'NT',
  },
  {
    name: 'Nunavut',
    abbreviation: 'NU',
  },
  {
    name: 'Ontario',
    abbreviation: 'ON',
  },
  {
    name: 'Prince Edward Island',
    abbreviation: 'PE',
  },
  {
    name: 'Québec',
    abbreviation: 'QC',
  },
  {
    name: 'Saskatchewan',
    abbreviation: 'SK',
  },
  {
    name: 'Yukon',
    abbreviation: 'YT',
  },
];

export default CanadaStates