export const YOUR_SUBSCRIPTION = 'Your subscription';
export const SUBSCRIPTION_FEE = 'Subscription Fee';
export const DOLLAR = '$';
export const CANCEL_SUBSCRIPTION = 'Cancel subscription';

export const NEXT_CHARGE_DATE = 'Next charge date';
export const SUBSCRIPTION_AVAILABLE_UNTIL = 'Subscription available until';
export const SUBSCRIPTION_WAS_ACTIVE = 'Subscription was active till';

export const PENDING_SUB_WARNING = 'Auto-renewal is turned off for the subscription';
