import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type Store = {
  isAuthorized: boolean;
  signInLocal: () => void;
};

const useLocalPasswordStore = create<Store>()(
  immer((set) => ({
    isAuthorized: false,
    signInLocal: () =>
      set((state) => {
        localStorage.setItem('isLocalAuthorized', 'true');
        state.isAuthorized = true;
      }),
  })),
);

export default useLocalPasswordStore;
