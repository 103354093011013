import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { TourScheduleRequestDto } from 'common/types/services/api/tours/tour-schedule-request-dto.type';
import { scheduleTour } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useScheduleTour(options?: UseMutationOptions<string, Error, TourScheduleRequestDto, unknown>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: scheduleTour,
    onSettled: (data, error, variables, context) => {
      options?.onSettled?.(data, error, variables, context);
      queryClient.invalidateQueries({ queryKey: [QueryKey.Tours] });
    },
  });
}
