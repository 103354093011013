import { useFormik } from 'formik';
import { InputProps } from 'common/types/types';
import { Button, Icon, Input, Modal } from 'legacy-components/componets';
import * as React from 'react';
import * as Yup from 'yup';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { useNavigate } from 'react-router-dom';
import { ENV } from 'common/enums/enums';
import { PasswordValidation } from 'validations/password.validation';
import useLocalPasswordStore from 'store/use-local-password-store';

export interface IValues {
  password: string;
}

export const PasswordModalWindow = () => {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [showPassword, setShowPassword] = React.useState(false);
  const isAuthorized = useLocalPasswordStore((state) => state.isAuthorized);
  const signInLocal = useLocalPasswordStore((state) => state.signInLocal);
  const navigate = useNavigate();

  const handlePasswordSubmit = (values: IValues) => {
    if (values.password === ENV.REACT_APP_PASSWORD) {
      signInLocal();
    } else {
      setErrors({ password: 'Incorrect password' });
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit, setErrors } = useFormik<IValues>({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(PasswordValidation.Required)
        .min(8, PasswordValidation.MinLength)
        .matches(/[a-z]+/, PasswordValidation.LowerCase)
        .matches(/[A-Z]+/, PasswordValidation.UpperCase),
    }),
    onSubmit: (values: IValues) => handlePasswordSubmit(values),
  });

  const getCommonInputProps = (name: keyof IValues, label: string, placeholder: string): InputProps => {
    return {
      name,
      label,
      placeholder,
      theme: 'default',
      value: values[name],
      onChange: ({ target: { value } }) => setFieldValue(name, value),
      invalid: Boolean(errors[name] && touched[name]),
    };
  };

  const showError = (fieldName: keyof IValues): boolean => {
    return Boolean(errors[fieldName] && touched[fieldName]);
  };

  const { isMobile } = useWindowSize();

  React.useEffect(() => {
    if (isAuthorized && localStorage.getItem('isLocalAuthorized')) {
      navigate('/');
    }
  }, [isAuthorized, localStorage]);

  return (
    <Modal
      isOpen={true}
      styles={{
        content: {
          borderRadius: '20px',
          maxHeight: '100%',
          width: '100%',
          padding: '40px 0',
          ...(isMobile && { maxHeight: '100%' }),
        },
        overlay: { backgroundColor: 'white' },
      }}
      isMobile={isMobile}
    >
      <div className='flex flex-col p-5 bg-[#F1FBFF] gap-2 max-w-[700px] mx-auto'>
        <p className='text-[30px] text-primaryDark'>Welcome to Axxelist!</p>
        <label className='w-full text-justify text-[15.5px] text-[#737373]'>This website is password-protected</label>
        {errorMessage && (
          <div
            className={`text-sm text-error p-2 bg-red-100 rounded-lg flex justify-between ${
              errorMessage ? 'mt-4 mb-4' : ''
            }`}
          >
            <p>{errorMessage}</p>
            <div className='cursor-pointer' onClick={() => setErrorMessage('')}>
              <Icon name={'times-circle'} />
            </div>
          </div>
        )}
        <form className={`flex flex-col mb-6}`} onSubmit={handleSubmit}>
          <div
            className={`flex md:flex-row flex-col gap-4 
              ${showError('password') ? '' : 'mb-6'}`}
          >
            <div className='md:w-1/2 w-full'>
              <Input
                {...getCommonInputProps('password', 'Password', 'Enter password')}
                type={showPassword ? 'text' : 'password'}
                icon={{ name: showPassword ? 'hide' : 'show', color: '#66CCFF' }}
                onIconClick={() => setShowPassword((prevState) => !prevState)}
              />
              {showError('password') && <p className='text-sm text-error py-1'>{errors.password}</p>}
            </div>
          </div>
          <div className='w-full flex justify-start'>
            <Button className='px-7 mt-3' label={'Submit'} type={'submit'} theme={'black'} onClick={() => {}} />
          </div>
        </form>
      </div>
    </Modal>
  );
};
