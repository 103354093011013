import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  // TODO: USE CONST ROUTE NAME
  useEffect(() => {
    if (!pathname.includes('profile') && !pathname.includes('properties')) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export { ScrollToTop };
