import { Table } from 'legacy-components/componets';
import { FC } from 'react';
import { ToursScheduleTableRowType } from './types';
import { AuthRole } from 'common/enums/enums';
import { useHasRoles } from 'hooks';
import { ToursTableAccessor } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/components/upcoming-tours-table/common/enum/tour-table-accessor';
import { LandlordToursActionsMenuCell } from './columns/actions';
import { TablePaginationProps } from 'common/types/types';
import { SortOrder } from 'common/types/sort-order';
import { TableColumn } from 'common/types/components/ui/table/table-props.type';
import { ScheduledToursDateCell } from './columns/date';
import { UpcomingToursPropertyCell } from './columns/property';
import { UpcomingToursTenantCell } from './columns/tenant';
import { TourStatusCell } from './columns/status';
import { SetQuery } from 'use-query-params';
import { ToursScheduleQueryParamConfig } from 'components/templates/Landlord/ToursSchedulePage/ToursSchedulePage';
import { UpcomingToursConfirmedCell } from './columns/confirmed';

type Props = {
  isLoading: boolean;
  data: ToursScheduleTableRowType[];
  paginationProps: TablePaginationProps;
  order: SortOrder;
  setQuery: SetQuery<ToursScheduleQueryParamConfig>;
};

const LandlordUpcomingToursTable: FC<Props> = ({ isLoading, data, paginationProps, order, setQuery }) => {
  const isLandlord = useHasRoles([AuthRole.Landlord]);

  const columns: TableColumn<ToursScheduleTableRowType>[] = [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ScheduledToursDateCell,
      sorter: true,
      sortOrder: order,
      onSort: (sortOrder) => setQuery((prev) => ({ ...prev, page: 1, order: sortOrder })),
      width: '20%',
      minWidth: 150,
    },
    {
      Header: 'Property',
      accessor: 'property',
      Cell: UpcomingToursPropertyCell,
      width: '30%',
      minWidth: 250,
    },
    {
      Header: 'Tenant',
      accessor: 'tenant',
      Cell: UpcomingToursTenantCell,
      minWidth: 250,
    },
    {
      Header: 'Status',
      accessor: ToursTableAccessor.Status,
      Cell: TourStatusCell,
      width: '10%',
      minWidth: 120,
    },
  ];

  const tableColumns = isLandlord
    ? columns.concat([
        {
          Header: 'Confirmed',
          accessor: ToursTableAccessor.Confirmed,
          Cell: UpcomingToursConfirmedCell,
          width: '10%',
          minWidth: 120,
        },
        {
          Header: 'Actions',
          accessor: ToursTableAccessor.Actions,
          Cell: LandlordToursActionsMenuCell,
          width: '10%',
          minWidth: 50,
        },
      ])
    : columns;

  return (
    <Table
      data={data}
      columns={tableColumns}
      isLoading={isLoading}
      placeholder={{
        title: 'No tours are scheduled for this day',
      }}
      paginationProps={paginationProps}
    />
  );
};

export { LandlordUpcomingToursTable };
