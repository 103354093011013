import { NotificationType, TourStatusDto } from 'common/enums/enums';

export const getStatusTitle = (status: TourStatusDto | undefined): string => {
  switch (status) {
    case TourStatusDto.Canceled:
      return 'Your self-tour has been canceled';
    case TourStatusDto.Rejected:
      return 'Your self-tour has been canceled by landlord';
    case TourStatusDto.Finished:
      return 'Your self-tour has been finished successfully';
    case TourStatusDto.Expired:
      return 'Your self-tour has expired';
    case TourStatusDto.Skipped:
      return 'Your self-tour has been skipped';
    default:
      return '';
  }
};

export const getStatusDescription = (status: TourStatusDto | undefined, tourDate: string | undefined): string => {
  switch (status) {
    case TourStatusDto.Canceled:
      return 'We regret to inform you that the self-tour has been canceled. We apologize for any inconvenience this may cause.';
    case TourStatusDto.Rejected:
      return 'We regret to inform you that landlord canceled your self-tour.';
    case TourStatusDto.Finished:
      return `You toured this property${tourDate ? ` on ${tourDate}` : ''}.`;
    case TourStatusDto.Expired:
      return 'We apologize for notifying you that your self-tour has expired.';
    case TourStatusDto.Skipped:
      return 'We regret to inform you that your scheduled self-tour has been skipped.';
    default:
      return '';
  }
};

export const getStatusIcon = (status: TourStatusDto | undefined): NotificationType => {
  switch (status) {
    case TourStatusDto.Canceled:
      return NotificationType.ERROR;
    case TourStatusDto.Rejected:
      return NotificationType.ERROR;
    case TourStatusDto.Finished:
      return NotificationType.SUCCESS;
    case TourStatusDto.Expired:
      return NotificationType.WARNING;
    case TourStatusDto.Skipped:
      return NotificationType.WARNING;
    default:
      return NotificationType.INFO;
  }
};
