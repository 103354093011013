import React from 'react';
import { NotificationType } from 'common/enums/enums';
import { TypeOptions } from 'react-toastify/dist/types';
import { IconProps } from 'common/types/types';
import { Icon } from 'legacy-components/componets';

export type NotificationTypeIconProps = {
  type?: TypeOptions;
  size?: 's' | 'l';
};

const NotificationTypeIcon = ({ type = 'info', size = 's' }: NotificationTypeIconProps) => {
  const iconSize = ((): Pick<IconProps, 'width' | 'height'> => {
    switch (size) {
      case 's':
        return { width: 20, height: 20 };
      case 'l':
        return { width: 40, height: 40 };
    }
  })();

  const icon = (() => {
    switch (type) {
      case NotificationType.SUCCESS:
        return <Icon name={'circle-checked-regular'} color={'#05A660'} {...iconSize} />;
      case NotificationType.ERROR:
        return <Icon name={'info-circle'} {...iconSize} />;
      case NotificationType.INFO:
        return <Icon name={'information'} {...iconSize} />;
      case NotificationType.WARNING:
        return <Icon name={'danger'} {...iconSize} />;
    }
  })();

  const iconBackgroundColor = (() => {
    switch (type) {
      case NotificationType.SUCCESS:
        return 'bg-[#EBF8F1]';
      case NotificationType.ERROR:
        return 'bg-[#FFEBEB]';
      case NotificationType.INFO:
        return 'bg-[#EBEFF7]';
      case NotificationType.WARNING:
        return 'bg-[#FFF3D8]';
    }
  })();

  const iconContainerPadding = (() => {
    switch (size) {
      case 's':
        return 'p-2.5';
      case 'l':
        return 'p-5';
    }
  })();

  return <div className={`flex-center rounded-full ${iconContainerPadding} ${iconBackgroundColor}`}>{icon}</div>;
};

export { NotificationTypeIcon };
