import React, { CSSProperties, FC } from 'react';
import { Button, Icon, Modal } from 'legacy-components/componets';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  styles?: {
    overlay?: CSSProperties;
    content?: CSSProperties;
  };
};

export const StartTourModal: FC<Props> = ({ isOpen, onClose, onContinue, styles }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} styles={{ ...styles, overlay: { ...styles?.overlay, zIndex: 100 } }}>
      <div className=' relative w-[550px] max-w-full p-8 bg-neutral-50 rounded-[15px] flex-col justify-center items-center gap-8 inline-flex'>
        <button onClick={onClose} className={'absolute top-8 right-8'}>
          <Icon name={'xmark'} className={'cursor-pointer text-primary'} width={24} height={24} />
        </button>
        <div className={'bg-slate-100 rounded-full w-20 h-20 flex justify-center items-center'}>
          <Icon name={'information'} width={48} height={48} />
        </div>
        <div className='self-stretch flex-col justify-center items-center gap-8 flex'>
          <div className='self-stretch h-[88px] flex-col justify-center items-center gap-3 flex'>
            <div className='text-sky-600 text-2xl font-bold leading-loose'>Start Your Self-Tour</div>
            <div className='w-[345.96px] text-center text-neutral-500 text-base font-medium leading-snug'>
              This will start your self-tour. Are you sure you want to continue?
            </div>
          </div>
        </div>
        <Button label={'Continue'} className={'w-full'} onClick={onContinue} />
      </div>
    </Modal>
  );
};
