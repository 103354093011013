import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { cancelTour } from 'services/api/tours-api.service';
import { Notification } from 'services/notification.service';
import { QueryKey } from 'common/enums/query-key';

export default function useCancelTour(options?: UseMutationOptions<boolean, Error, string, unknown>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: cancelTour,
    onError: (e) => {
      const notification = new Notification();
      notification.error(e.message);
    },
    onSuccess: (data, variables, context) => {
      const notification = new Notification();
      notification.success('Tour canceled');
      options?.onSuccess?.(data, variables, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Tours] });
    },
  });
}
