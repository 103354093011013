import { Icon } from 'legacy-components/ui/ui';
import { useEffect, useRef, useState } from 'react';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);
  const ref = useRef<null | HTMLButtonElement>(null);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else if (scrolled <= 800) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);

    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (visible) {
        ref.current?.classList.remove('hidden');
      } else {
        ref.current?.classList.add('hidden');
      }
    }, 300);
  }, [visible]);

  return (
    <button
      ref={ref}
      onClick={scrollToTop}
      className={`${
        visible ? 'opacity-1' : 'opacity-0'
      } drop-shadow-lg rounded-full bg-primary fixed right-[7%] bottom-[7%] w-[50px] h-[50px] cursor-pointer z-10 transition ease-out duration-300`}
    >
      <Icon width={20} height={20} clickable name={'arrow-up'} color={'white'} />
    </button>
  );
};

export { ScrollButton };
