import { colors } from 'colors';
import { Icon } from '../icon/icon';

const NO_ITEMS_PAGE_COUNT = 0;

export type TablePaginationProps = {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (newPage: number) => void;
};

const TablePagination = ({ pageSize, totalCount, currentPage, onPageChange }: TablePaginationProps) => {
  const totalPages = Math.ceil(totalCount / pageSize);

  const handleNextPageChange = (): void => {
    onPageChange(currentPage + 1);
  };

  const handlePreviousPageChange = (): void => {
    onPageChange(currentPage - 1);
  };

  const isPrevBtnDisabled = currentPage === 1;

  const isNextBtnDisabled = currentPage === totalPages || totalPages === NO_ITEMS_PAGE_COUNT;

  if (totalCount <= pageSize) {
    return null;
  }

  const currentItemsCount = currentPage * pageSize;
  const startCount = (currentPage - 1) * pageSize + 1
  const endCount = currentItemsCount > totalCount ? totalCount : currentItemsCount;

  return (
    <div className='flex justify-between items-center px-[34px]'>
      <div>
        <span>{`${startCount} - ${endCount} of ${totalCount}`}</span>
      </div>
      <div className='flex items-center gap-5'>
        <div>
          <span>{`${currentPage} of ${totalPages}`}</span>
        </div>
        <div className='flex items-center gap-4'>
          <div
            className={`w-8 h-8 rounded-full border shadow-share-icon flex-center cursor-pointer
                        ${!isPrevBtnDisabled ? 'border-primary' : 'border-trueGray'}
                        `}
            onClick={!isPrevBtnDisabled ? handlePreviousPageChange : undefined}
          >
            <Icon
              width={15}
              height={15}
              color={!isPrevBtnDisabled ? colors.primary : colors.trueGray}
              name={'arrow-left'}
              clickable
            />
          </div>
          <div
            className={`w-8 h-8 rounded-full border shadow-share-icon flex-center cursor-pointer
                    ${!isNextBtnDisabled ? 'border-primary' : 'border-trueGray'}
                    `}
            onClick={!isNextBtnDisabled ? handleNextPageChange : undefined}
          >
            <Icon
              width={15}
              height={15}
              color={!isNextBtnDisabled ? colors.primary : colors.trueGray}
              name={'arrow-right'}
              clickable
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { TablePagination };
