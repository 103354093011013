import { useState } from 'react';
import { MakeOfferModalProps } from './types/make-offer-modal-props.type';
import { MakeOfferModal } from './make-offer-modal';
import { ConfirmEscapingModal } from './confirm-escaping-modal';

type Props = MakeOfferModalProps & {
  tourId: string;
};

const MakeOfferModalWithConfirmationOnClose = ({ tourId, ...restProps }: Props) => {
  const [confirmEscapingModalOpen, setConfirmEscapingModalOpen] = useState(false);
  const [open, setOpen] = useState(true);

  const handleToggleModal = (isEscapeModalOpen: boolean, isModalOpen: boolean) => {
    setConfirmEscapingModalOpen(isEscapeModalOpen);
    setOpen(isModalOpen);
  };

  return (
    <>
      <ConfirmEscapingModal
        onClose={() => {
          handleToggleModal(false, true);
        }}
        open={confirmEscapingModalOpen}
        onContinue={() => {
          handleToggleModal(false, true);
        }}
        onCancel={() => {
          restProps?.onSubmit?.();
          handleToggleModal(false, false);
        }}
      />
      <MakeOfferModal
        {...restProps}
        open={restProps.open && open}
        onSubmit={() => {
          restProps?.onSubmit?.();
          handleToggleModal(false, false);
        }}
        onClose={() => {
          handleToggleModal(true, false);
        }}
      />
    </>
  );
};

export default MakeOfferModalWithConfirmationOnClose;
