import { Link, NavLink } from 'react-router-dom';
import { Button, Facebook, Icon, Instagram, Linkedin, Logo, Twitter } from 'legacy-components/componets';
import { Fragment, useState } from 'react';
import { HelpSupportModal } from 'legacy-pages/help-support/HelpSupportModals';
import { twMerge } from 'tailwind-merge';
import { useWindowSize, useAuth } from 'hooks';
import { useSubscribeEmailNotification } from 'hooks/query';
import { useFormik } from 'formik';
import { notification } from 'services/services';
import * as Yup from 'yup';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import { AuthRole, ENV } from 'common/enums/enums';
import { BooleanParam, useQueryParams, withDefault } from 'use-query-params';

const Footer = () => {
  const [query, setQuery] = useQueryParams(
    {
      contactUs: withDefault(BooleanParam, false),
    },
    {
      updateType: 'replace',
    },
  );

  const { isMobile } = useWindowSize();
  const { isAuthenticated, user } = useAuth();
  const isAdmin = user?.roles.includes(AuthRole.SuperUser);

  const { values, errors, setFieldValue, touched, submitForm } = useFormik<{ email: string }>({
    initialValues: { email: '' },
    onSubmit: async ({ email }) => {
      if (isNotEmptyString(email)) {
        subscribeEmailNotification({ email });
      }
    },
    validationSchema: Yup.object({ email: Yup.string().notRequired().email('Email is invalid') }),
  });

  const { mutate: subscribeEmailNotification, isPending } = useSubscribeEmailNotification({
    onSuccess: () => {
      notification.success('Thank you for signing up for our latest updates. We have sent you a confirmation email.');
    },
    onError: (e) => {
      notification.error(
        e?.response?.data?.detail ||
          'Oops! It seems like the email provided is incorrect. Please double-check and re-enter a valid email address.',
      );
    },
  });

  const logo = (() => {
    return (
      <div className='mb-14 md:mb-0'>
        <Logo className={'w-[142px] h-[32px]'} />
      </div>
    );
  })();

  const highlightedLinkIndex = 3;

  const navigation = (() => {
    const navLinks = [
      {
        title: 'Register',
        to: '/auth/sign-up',
        visible: !isAuthenticated,
      },
    ];

    return (
      <nav>
        <h6 className='mb-5 md:hidden md:mb-0 block text-sm font-bold uppercase'>Quick Links</h6>
        <ul className='flex flex-col text-sm  text-middle text-trueGray md:flex-row md:flex-wrap md:items-center md:mb-6'>
          {navLinks.map(({ title, to, visible }, index) =>
            visible ? (
              <li
                key={index}
                className={twMerge(
                  `mb-4 md:mb-0 md:px-2.5 md:py-0.5 md:rounded-3xl md:hover:bg-secondary md:hover:text-primaryDark`,
                  index === highlightedLinkIndex ? 'text-primary' : 'text-trueGray',
                )}
              >
                <NavLink to={to}>{title}</NavLink>
              </li>
            ) : null,
          )}
        </ul>
        {!isAdmin && (
          <Fragment>
            <div
              className='flex items-center border-[#DEEFF7] border-2 bg-[#F7FBFD] rounded-lg p-3 cursor-pointer'
              onClick={() => setQuery({ contactUs: true })}
            >
              <Icon name={'help_support'} color={'#05A660'} />
              <p className='ml-4 text-trueGray'>Help & Support</p>
            </div>
            <HelpSupportModal
              open={query.contactUs}
              onClose={() => {
                setQuery({ contactUs: false });
              }}
            />
          </Fragment>
        )}
      </nav>
    );
  })();

  const socialIcons = (() => {
    return (
      <div className='flex items-center md:justify-between justify-center'>
        <div className='flex space-x-6 md:justify-center md:mt-4'>
          <Facebook />
          <Twitter />
          <Instagram />
          <Linkedin />
        </div>
      </div>
    );
  })();

  const legal = (() => {
    return (
      <div>
        <h6 className='mb-5 md:hidden md:mb-0 block text-sm font-bold uppercase'>Legal</h6>
        <div className='flex flex-col md:flex-row text-sm gap-4 md:gap-8'>
          <span className='text-trueGray'>v{ENV.REACT_APP_VERSIONN}</span>
          {!isMobile && <span className='text-trueGray'>© 2024 Axxelist</span>}
          <Link to={'/terms-of-service'} className='text-trueGray hover:text-black'>
            Terms of Service
          </Link>
          <Link to={'/privacy-policy'} className='text-trueGray hover:text-black'>
            Privacy Policy
          </Link>
        </div>
      </div>
    );
  })();

  const subscribe = (() => {
    return (
      <div className='relative subscribe text-sm flex flex-col mb-[82px] md:mb-0 md:items-end md:max-w-[438px] gap-6'>
        <div className='text-trueGray'>Get the latest updates about Axxelist’s new features and product updates.</div>
        <div className='support flex items-start gap-3 w-full justify-between'>
          <div className='w-full'>
            <input
              value={values?.email}
              onChange={(e) => setFieldValue('email', e.target.value)}
              type='text'
              className='w-full text-[#A3A3A3] grow border border-[#F0F0F0] p-3 rounded-[6px] focus:text-black focus:border-warmGray focus:outline-none flex-shrink-0'
              placeholder='Enter your email address'
            />
            {errors?.email && touched?.email && (
              <p className='text-xs text-error py-1 h-6 text-start'>{errors?.email}</p>
            )}
          </div>
          <Button label={'Submit'} theme={'black'} onClick={submitForm} isLoading={isPending} />
        </div>
      </div>
    );
  })();

  const desktopView = (() => {
    return (
      <>
        <div className='flex justify-between'>
          <div className='flex flex-col gap-[34px]'>
            {logo}
            {navigation}
          </div>
          {subscribe}
        </div>
        <hr className='mt-16 mb-8 border-gray' />
        <div className='flex justify-between items-center'>
          {socialIcons}
          {legal}
        </div>
      </>
    );
  })();

  const mobileView = (() => {
    return (
      <>
        {logo}
        <div className='flex justify-between mb-[54px]'>
          {navigation}
          {legal}
        </div>
        {subscribe}
        <div className='text-trueGray text-base font-medium text-center mb-6'>
          ©{new Date().getFullYear()} Axxelist. All rights reserved
        </div>
        {socialIcons}
      </>
    );
  })();

  return (
    <footer className='md:px-28 px-6 py-16 bg-white rounded-lg'>
      <div className='hidden md:block'>{desktopView}</div>
      <div className='block md:hidden'>{mobileView}</div>
    </footer>
  );
};

export { Footer };
