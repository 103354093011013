import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { ToursDto } from 'common/types/services/api/tours/tours-dto.type';
import { getToursHistory } from 'services/api/tours-api.service';
import { ToursHistoryRequestDto } from 'common/types/services/api/tours/tours-history-request-dto.type';
import { QueryKey } from 'common/enums/query-key';

export default function useToursHistory<TData = ToursDto>(
  params: ToursHistoryRequestDto,
  options?: Omit<
    UndefinedInitialDataOptions<ToursDto, Error, TData, (string | ToursHistoryRequestDto)[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Tours, 'history', params],
    queryFn: () => getToursHistory(params),
    ...options,
  });
}
