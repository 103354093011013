import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { removeUserProfileImage } from 'services/api/auth-api.service';

export default function useDeleteProfileImage(options?: UseMutationOptions<boolean, Error, void, unknown>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: removeUserProfileImage,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.User] });
    },
  });
}
