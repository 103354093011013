import React from 'react';
import styles from './styles';

export type FavouriteIconProps = {
  isOn?: boolean;
  onClick?: () => void;
  className?: string;
};

const FavouriteIcon = ({ isOn = false, className = '', onClick }: FavouriteIconProps) => {
  return (
    <div className={styles.container.concat(' ', className)} onClick={onClick}>
      <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_183_35122)'>
          <path
            d='M23.1294 15.6796L14.0779 25.0405C13.4854 25.6532 12.5183 25.6537 11.9258 25.041L2.86957 15.6801C0.239883 12.9608 0.385664 8.4702 3.29848 5.94645C5.84191 3.74426 9.73723 4.14458 12.0763 6.56145L12.9997 7.51161L13.9232 6.56145C16.2646 4.14458 20.1154 3.74426 22.7029 5.94645C25.6138 8.46552 25.7591 12.9608 23.1294 15.6796Z'
            fill={isOn ? styles.fillColor : 'transparent'}
          />
          <g clipPath='url(#clip1_183_35122)'>
            <path
              d='M22.6612 5.94655C21.569 4.96499 20.1675 4.45781 18.7518 4.49999C16.9945 4.49999 15.2208 5.22187 13.9237 6.56155L13.0003 7.51171L12.0768 6.56155C10.7784 5.22187 9.00653 4.49999 7.24871 4.49999C5.83309 4.49999 4.43293 4.96546 3.29902 5.94655C0.38621 8.46796 0.240428 12.9637 2.86637 15.6778L11.9226 25.0387C12.2222 25.3453 12.6065 25.5 12.9956 25.5C13.3862 25.5 13.7779 25.3468 14.0742 25.0404L23.1258 15.6795C25.7597 12.9609 25.6143 8.46561 22.6612 5.94655ZM21.5128 14.114L13.0003 22.9172L4.48496 14.114C2.97371 12.5531 2.61559 9.47343 4.77184 7.64765C6.64215 6.02859 9.20575 6.82733 10.4625 8.12437L13.0017 10.7456L15.5404 8.12437C16.7789 6.84608 19.3495 6.02015 21.2311 7.64765C23.3643 9.49686 23.055 12.5203 21.5128 14.114Z'
              fill='white'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_183_35122'>
            <rect width='25' height='25' fill='white' />
          </clipPath>
          <clipPath id='clip1_183_35122'>
            <rect width='24' height='24' fill='white' transform='translate(1 3)' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export { FavouriteIcon };
