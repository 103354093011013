import cn from 'clsx';
import { TextareaProps } from 'common/types/types';
import styles from './styles';
import { twMerge } from 'tailwind-merge';

const Textarea = ({
  name,
  value,
  theme = 'default',
  placeholder,
  onChange,
  label,
  invalid,
  inputClassName: inputClassNameProp,
}: TextareaProps) => {
  const inputClassName = cn(styles[theme].textarea, invalid && styles[theme].error);

  return (
    <div className={styles[theme].container}>
      {label && (
        <label htmlFor={name} className={styles[theme].label}>
          {label}
        </label>
      )}
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        className={twMerge(inputClassName, inputClassNameProp)}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export { Textarea };
