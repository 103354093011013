import { isNotEmptyString } from "common/utils/check-empty-string";
import { PropertyEstateRentSpecial, PropertyUnitRentSpecial } from "./PropertyRentSpecial.types";
import { SpecialsResponse, UnitSpecialsResponse } from "common/mappers/property";

export function checkRentSpecialRequest(rentSpecial: PropertyEstateRentSpecial | PropertyUnitRentSpecial | null | undefined): boolean {
    if (rentSpecial === null || rentSpecial === undefined) {
        return false;
    }

    return (
        isNotEmptyString(rentSpecial.rentSpecialTitle)
        && isNotEmptyString(rentSpecial?.rentSpecialStartDate.toString())
        && isNotEmptyString(rentSpecial?.rentSpecialEndDate.toString())
    );
}

export function checkRentSpecialEstateResponse(rentSpecial: SpecialsResponse | UnitSpecialsResponse | null | undefined): boolean {
    if (rentSpecial === null || rentSpecial === undefined) {
        return false;
    }

    return (
        isNotEmptyString(rentSpecial.title)
        && isNotEmptyString(rentSpecial?.startDate)
        && isNotEmptyString(rentSpecial?.startDate)
    );
};