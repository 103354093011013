import React, { CSSProperties } from 'react';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { Modal } from 'legacy-components/ui/modal/modal';
import { Button } from 'legacy-components/componets';

type ConfirmCancelOfferModalProps = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  styles?: {
    overlay?: CSSProperties;
    content?: CSSProperties;
  };
};

export const ConfirmCancelOfferModal = ({ open, onCancel, onOk, styles }: ConfirmCancelOfferModalProps) => {
  const { isMobile } = useWindowSize();

  return (
    <Modal
      isOpen={open}
      styles={{
        ...styles,
        content: {
          ...styles?.content,
          maxHeight: '100%',
          ...(isMobile && { bottom: 0, height: 'auto', top: 'auto', transform: 'none', left: 0, right: 0 }),
        },
      }}
    >
      <div className='md:w-[500px] bg-white rounded-lg p-4 md:p-8 md:max-w-full w-screen md:h-auto relative flex flex-col gap-9'>
        <h3 className='text-center text-xl whitespace-nowrap text-center text-neutral-800 text-[40px] font-semibold leading-10'>
          Do you wish to withdraw your offer?
        </h3>
        <div className={'flex justify-between gap-4'}>
          <Button label={'No'} theme={'red-text'} onClick={onCancel} className={'w-full'} />
          <Button label={'Yes'} onClick={onOk} className={'w-full'} />
        </div>
      </div>
    </Modal>
  );
};
