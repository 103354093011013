import { CloseOutlined } from '@ant-design/icons';
import { Icon, Loader, Modal } from 'legacy-components/componets';
import { SavedSearchModalProps } from './SavedSearchModal.types';
import { EMPTY_FILTERS_LIST, MODAL_TITLE } from './SavedSearchModal.const';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { AppRoute } from 'common/enums/enums';
import { useNavigate } from 'react-router-dom';
import { CreateSavedSearchFiltersDto } from 'common/types/services/api/user/user-saved-search.type';
import { createFilteredObject, createStringFromObject } from './SavedSearchModal.utils';
import { useDeleteSavedSearch, useSavedSearch } from 'hooks/query';
import { FC } from 'react';

export const SavedSearchModal: FC<SavedSearchModalProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { data: savedSearch, isLoading } = useSavedSearch({ enabled: open });
  const filters = savedSearch || [];
  const { mutate: deleteSavedSearch } = useDeleteSavedSearch();

  const navidateToMainPageWithFilters = (item: CreateSavedSearchFiltersDto) => {
    const queryString = new URLSearchParams(createFilteredObject(item)).toString();
    navigate({
      pathname: `${AppRoute.ESTATES}/grid`,
      search: `?${queryString}`,
    });
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      styles={{
        overlay: { zIndex: 100 },
        content: {
          maxHeight: '100%',
          ...(isMobile && { bottom: 'auto', height: '100%', top: 0, transform: 'none', left: 0, right: 0 }),
        },
      }}
    >
      <div className='p-3 bg-white min-w-[340px] sm:min-w-[560px] md:min-w-[700px]'>
        <div className='flex flex-col gap-6 m-4'>
          <div className='flex items-center justify-center gap-6'>
            <div className='flex-grow flex items-center justify-center text-xl md:text-2xl whitespace-nowrap'>
              {MODAL_TITLE}
            </div>
            <button onClick={onClose} type={'button'} className='flex justify-start'>
              <CloseOutlined className={'text-primary text-[22px]'} />
            </button>
          </div>
          {isLoading ? (
            <Loader style={{ height: 68 }} />
          ) : filters.length > 0 ? (
            <div className='flex flex-col gap-2 md:max-h-[200px] overflow-auto pr-3 scrollbar'>
              {filters.map((item) => {
                return (
                  <div className='flex justify-between'>
                    <div
                      className='flex flex-grow items-center justify-center gap-6 cursor-pointer'
                      onClick={() => navidateToMainPageWithFilters(item)}
                    >
                      <div>
                        <Icon name={'fa-magnifying-glass'} color={'#222222'} clickable />
                      </div>
                      <div className='flex-grow flex'>
                        <p className='text-[#414141] text-base'>{createStringFromObject(item)}</p>
                      </div>
                    </div>
                    <Icon name={'faTrash'} color={'#222222'} clickable onClick={() => deleteSavedSearch(item?.id)} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div>{EMPTY_FILTERS_LIST}</div>
          )}
        </div>
      </div>
    </Modal>
  );
};
