import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, GoBackLink, Icon, Loader } from 'legacy-components/componets';
import { dateFormatMM_DD_YY, dateFormatMMMM_DD, dateTimeFormatHHMM_a, getDayjs } from 'helpers/date';
import { TourStatusDto } from 'common/enums/enums';
import { staticText } from './config';
import { CollectKeyModal } from 'legacy-components/modals/collect-key-modal/collect-key-modal';
import { CancelTourModal } from 'legacy-components/cancel-tour-modal/cancel-tour-modal';
import dayjs from 'dayjs';
import { RentModal } from 'legacy-components/rent-modal/rent-modal';
import { EstateCard } from 'components/organisms/EstateCard';
import { Divider } from 'antd';
import { useWindowSize } from 'hooks';
import { StartTourModal } from 'legacy-components/modals/start-tour-modal/StartTourModal';
import { splitPascalCaseWords } from 'helpers/string.helpers';
import {
  useEstateDetails,
  useTourKeyInfo,
  useTourDetails,
  useCancelTour,
  useStartTour,
  useFinishTour,
} from 'hooks/query';

const DetailsTour = () => {
  const { tourId = '' } = useParams<{ tourId: string }>();
  const { isDesktop } = useWindowSize();
  const navigate = useNavigate();
  const [cancelTourModalOpen, setCancelTourModalOpen] = useState(false);
  const [rentModalOpen, setRentModalOpen] = useState(false);
  const [isStartTourModalOpen, setIsStartTourModalOpen] = useState(false);
  const { data: tourDetails, isLoading: isTourDetailsLoading } = useTourDetails(tourId);
  const { data: estateDetails } = useEstateDetails(tourDetails?.estateId);
  const { data: keyInfo, refetch: refetchTourKeyInfo } = useTourKeyInfo(tourId, { enabled: false });
  const { mutate: cancelTour } = useCancelTour({
    onSuccess: () => {
      setCancelTourModalOpen(false);
      navigate('/i-rent/profile/tours/upcoming');
    },
  });
  const { mutate: startTour } = useStartTour({
    onSuccess: () => {
      setCollectKeyModalOpen(false);
    },
  });
  const { mutate: finishTour, isPending: isFinishingTour } = useFinishTour({
    onSuccess: () => {
      setRentModalOpen(true);
    },
  });

  const [collectKeyModalOpen, setCollectKeyModalOpen] = React.useState<boolean>(false);

  const handleClose = () => {
    setCollectKeyModalOpen(false);
  };

  const handleRevealKey = async () => {
    setCollectKeyModalOpen(true);
    await refetchTourKeyInfo();
  };

  const handleViewFullListing = () => {
    navigate(`/estates/${tourDetails?.estateId}`);
  };

  const isAvailableToStartTour = useMemo(
    () =>
      tourDetails
        ? dayjs().isBetween(
            dayjs(tourDetails.slotUtc).subtract(15, 'minutes'),
            dayjs(tourDetails.slotUtc).add(60, 'minutes'),
            null,
            '[]',
          )
        : false,
    [tourDetails?.slotUtc],
  );

  const handleFinishTour = async () => {
    finishTour(tourId);
  };

  if (isTourDetailsLoading) {
    return <Loader />;
  }

  const utcDate = dayjs(tourDetails?.slot).utc();

  return (
    <>
      <CancelTourModal
        open={cancelTourModalOpen}
        onClose={() => setCancelTourModalOpen(false)}
        onOk={async () => {
          cancelTour(tourId);
        }}
      />
      {tourDetails && (
        <RentModal
          open={rentModalOpen}
          onClose={() => setRentModalOpen(false)}
          tour={tourDetails}
          availableOn={estateDetails?.units.find((u) => u.id === tourDetails?.unitId)?.availableOn}
        />
      )}
      <div className='tour-details flex flex-col gap-2 md:gap-9 w-full mb-[30px] px-2 md:px-0'>
        <GoBackLink title={'Back to tours'} onClick={() => navigate('/i-rent/profile/tours/upcoming')} />
        <div className='flex flex-col gap-9 mt-1 shadow-white-xl p-[34px] flex-grow'>
          <div className='tour-date text-2xl text-trueGray font-bold uppercase mb-[14px]'>
            {dateFormatMMMM_DD(utcDate)}
          </div>
          <div className='flex justify-center w-full'>
            {tourDetails && (
              <EstateCard
                style={{
                  width: '100%',
                }}
                isHorizontal={isDesktop}
                city={tourDetails?.city}
                location={tourDetails?.location}
                price={tourDetails?.price}
                category={tourDetails?.category}
                beds={tourDetails?.beds}
                bathrooms={tourDetails?.bathrooms}
                propertyArea={tourDetails?.propertyArea}
                type={tourDetails?.type}
                thumbnailUrl={tourDetails?.thumbnailUrl}
              />
            )}
          </div>
          <Button label={'View full listing'} theme={'secondary'} onClick={handleViewFullListing} />
          <div className='description flex flex-col gap-3 border border-gray p-6 rounded-lg'>
            <div className='font-bold'>Tour Details</div>
            <div className='text-base font-normal gap-9 grid grid-cols-1 md:flex'>
              <div className='w-full md:w-1/2 flex gap-9'>
                <div className='w-1/2 flex flex-col'>
                  <span>Tour Date:</span>
                  <span>Arrival time:</span>
                  <span>Tour duration</span>
                  <span>Tour ends:</span>
                </div>
                <div className='w-1/2 flex flex-col text-trueGray'>
                  <span>{dateFormatMM_DD_YY(utcDate)}</span>
                  <span>{dateTimeFormatHHMM_a(utcDate)}</span>
                  <span>30 minutes</span>
                  <span>{dateTimeFormatHHMM_a(getDayjs(utcDate).add(30, 'minutes'))}</span>
                </div>
              </div>
              <div className='w-full md:w-1/2 text-trueGray'>{staticText.tourDetail}</div>
            </div>
            {tourDetails?.tourStatus === TourStatusDto.Scheduled && (
              <div className='text-base text-trueGray bg-secondary rounded-lg py-6 text-center'>
                <span>Something wrong?</span>
                {'  '}
                {/*TODO Add functionality to edit tour time and date in the future */}
                {/*<button*/}
                {/*  className='text-primary font-bold cursor-pointer'*/}
                {/*>*/}
                {/*  Edit tour time and date*/}
                {/*</button>*/}
                {/*<span>or</span>*/}
                <button className='text-primary font-bold cursor-pointer' onClick={() => setCancelTourModalOpen(true)}>
                  Cancel tour
                </button>
              </div>
            )}
          </div>
          <div className=' border border-primary rounded-lg p-6'>
            <div className='access flex flex-col gap-3 text-base font-normal'>
              <div className='font-bold'>Key Access</div>
              <div className='flex gap-6 flex-col sm:flex-row'>
                <div className='bg-secondary px-4 py-3 sm:whitespace-nowrap rounded-lg flex items-center'>
                  <Icon name='key' />
                  <span className='font-bold ml-2 mr-1'>Access type:</span>
                  {tourDetails?.accessType ? splitPascalCaseWords(tourDetails.accessType) : ''}
                </div>
                <div className='text-xs text-trueGray font-medium'>{staticText.keyDetail}</div>
              </div>
            </div>
            <div className='start-tour flex flex-col gap-3 text-base text-trueGray font-normal mt-6'>
              <div className='flex flex-col sm:flex-row gap-6'>
                <Button
                  label={'Collect Your Key'}
                  icon={'key'}
                  theme={'primary-outline'}
                  onClick={handleRevealKey}
                  isFullWidth
                  disabled={!isAvailableToStartTour || tourDetails?.tourStatus === TourStatusDto.Finished}
                  stylesConfig={{ alignSelf: 'start' }}
                />
                {isAvailableToStartTour && tourDetails?.tourStatus === TourStatusDto.Scheduled ? (
                  <Button
                    isFullWidth
                    icon={'play'}
                    label={'Start Tour'}
                    theme={'primary'}
                    onClick={() => {
                      setIsStartTourModalOpen(true);
                    }}
                    stylesConfig={{ alignSelf: 'start' }}
                  />
                ) : null}

                {tourDetails?.tourStatus === TourStatusDto.InProgress && (
                  <Button
                    isLoading={isFinishingTour}
                    isFullWidth
                    label={'Finish tour'}
                    theme={'primary'}
                    onClick={handleFinishTour}
                    stylesConfig={{ alignSelf: 'start' }}
                  />
                )}
              </div>
              <Divider />
              <div>
                <div className='font-bold'>Late checkout and time extensions</div>
                <div>{staticText.checkoutDetail}</div>
              </div>
            </div>
          </div>

          {/*TODO Uncomment whenever contact us page form is ready*/}
          {/*<div className='contact-us flex flex-col gap-3 text-base text-trueGray bg-secondary p-2.5'>*/}
          {/*  <div className='font-bold'>Have a question?</div>*/}
          {/*  <div>{staticText.contactUs}</div>*/}
          {/*  <Button*/}
          {/*    label={'Contact us'}*/}
          {/*    theme={'primary'}*/}
          {/*    onClick={handleContactUs}*/}
          {/*    stylesConfig={{ alignSelf: 'start' }}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </div>
      <StartTourModal
        isOpen={isStartTourModalOpen}
        onClose={() => {
          setIsStartTourModalOpen(false);
        }}
        onContinue={() => {
          setIsStartTourModalOpen(false);
          startTour(tourId);
        }}
      />
      <CollectKeyModal
        isAvailableToStartTour={isAvailableToStartTour}
        tour={tourDetails || null}
        keyInfo={{
          id: keyInfo?.id || '',
          type: keyInfo?.type || '',
          description: keyInfo?.description || '',
          images: keyInfo?.files.map((file) => file.thumbnailUrl) || [],
        }}
        onStartTour={startTour}
        open={collectKeyModalOpen}
        onClose={handleClose}
      />
    </>
  );
};

export { DetailsTour };
