import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
};

const DoubleCheckIcon = ({ width = 24, height = 24, color = '#66CCFF', className }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_1325_91110)'>
        <path
          d='M7 12.709L12 17.709L22 7.70898'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12 12.709L17 7.70898M2 12.709L7 17.709L2 12.709Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1325_91110'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.708984)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DoubleCheckIcon;
