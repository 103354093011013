import { FC, useState } from 'react';
import { Button, Icon } from 'legacy-components/componets';
import HowItWorksImg from 'assets/images/how_it_works.png';
import { Image } from 'antd';
import { useAuth, useHasRoles } from 'hooks';
import { useUserProfileValidation } from 'hooks/query';
import { AuthRole } from 'common/enums/enums';
import { RenterIncompleteProfileActionAlert } from 'components/organisms/Alert/RenterIncompleteProfileActionAlert';

export type EstateTourRequestCardProps = {
  onClick: () => void;
  isEnabledTours?: boolean;
};

const EstateTourRequestCard: FC<EstateTourRequestCardProps> = ({ onClick, isEnabledTours = true }) => {
  const { isAuthenticated } = useAuth();
  const isRenter = useHasRoles([AuthRole.Renter]);
  const { data: userProfileValidation, isFetching } = useUserProfileValidation({ enabled: isAuthenticated });
  const isProfileValid = userProfileValidation?.isIdentityValid && userProfileValidation.isProfileValid;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className='grid gap-6 p-6 border border-primary shadow-card rounded-2xl w-full'>
      <h5 className='text-lg font-extrabold text-black'>Request Self Tour</h5>
      {isRenter && !isFetching && !isProfileValid && <RenterIncompleteProfileActionAlert />}
      {isEnabledTours ? (
        <div className='price flex flex-col'>
          <p className='text-base font-medium text-trueGray ml-[5px]'>
            Thank you for your interest in our rental property! To schedule a self tour, please select your preferred
            date and time by clicking below
          </p>
        </div>
      ) : (
        <div className='flex gap-4'>
          <Icon name='warning-red' />
          <p className='text-base text-trueGray'>{'The landlord did not specify the hours for scheduling a tour.'}</p>
        </div>
      )}
      <Button
        icon={'map-location-dot'}
        label={'Schedule a tour'}
        theme={'primary'}
        onClick={onClick}
        disabled={!isEnabledTours || isFetching || (isRenter && !isProfileValid)}
      />
      <div className='text-sm text-primary text-center cursor-pointer' onClick={handleOpenModal}>
        Learn How it Works
      </div>
      <Image
        style={{ display: 'none' }}
        src={HowItWorksImg}
        preview={{
          visible: isModalOpen,
          onVisibleChange: (value) => {
            setIsModalOpen(value);
          },
        }}
      />
    </div>
  );
};

export { EstateTourRequestCard };
