import { ToastContainer } from 'react-toastify';
import { ToastProps } from 'common/types/types';

const Toast = (props: ToastProps) => {
  return (
    <ToastContainer
      icon={false}
      hideProgressBar={true}
      closeButton={false}
      style={{ width: '100%', maxWidth: 928 }}
      position={'top-center'}
      pauseOnHover
      {...props}
    />
  );
};

export { Toast };
