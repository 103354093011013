import React from 'react';
import { CellProps } from 'react-table';
import { TourDateCellProps, ToursTableRow } from '../common/types/types';
import { dateFormatMMMM_DD, dateTimeFormatHHMM_A } from 'helpers/date';
import dayjs from 'dayjs';

const TourDateCell = ({ value }: CellProps<ToursTableRow, TourDateCellProps>) => {
  const utcDate = dayjs(value).utc();

  return (
    <div className='flex flex-col text-base text-trueGray gap-0.5 pl-2.5'>
      <span>{`${dateFormatMMMM_DD(utcDate)},`}</span>
      <span>{dateTimeFormatHHMM_A(utcDate)}</span>
    </div>
  );
};

export { TourDateCell };
