/* tslint:disable */
/* eslint-disable */
/**
 * Axxelist API
 * Axxelist API for modular monolith .NET application.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EstateListDto
 */
export interface EstateListDto {
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'unitId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'type'?: EstateListDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'category'?: EstateListDtoCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EstateListDto
     */
    'priceMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof EstateListDto
     */
    'priceMax'?: number;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'price'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'status'?: EstateListDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof EstateListDto
     */
    'units'?: number;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'beds'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EstateListDto
     */
    'bedsMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof EstateListDto
     */
    'bedsMax'?: number;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'bathrooms'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EstateListDto
     */
    'bathroomsMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof EstateListDto
     */
    'bathroomsMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof EstateListDto
     */
    'propertyArea'?: number;
    /**
     * 
     * @type {number}
     * @memberof EstateListDto
     */
    'yardArea'?: number;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'thumbnailUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof EstateListDto
     */
    'lng'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EstateListDto
     */
    'lat'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'postedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EstateListDto
     */
    'street'?: string | null;
}

export const EstateListDtoTypeEnum = {
    SingleUnit: 'SingleUnit',
    MultiUnit: 'MultiUnit'
} as const;

export type EstateListDtoTypeEnum = typeof EstateListDtoTypeEnum[keyof typeof EstateListDtoTypeEnum];
export const EstateListDtoCategoryEnum = {
    SingleFamilyHouse: 'SingleFamilyHouse',
    MultiFamilyHouse: 'MultiFamilyHouse',
    Apartment: 'Apartment',
    Condo: 'Condo',
    GuestsHouse: 'GuestsHouse',
    Cabin: 'Cabin',
    Barn: 'Barn',
    Farm: 'Farm',
    Mobile: 'Mobile',
    HouseBoat: 'HouseBoat',
    TinyHome: 'TinyHome',
    House: 'House',
    Townhouse: 'Townhouse'
} as const;

export type EstateListDtoCategoryEnum = typeof EstateListDtoCategoryEnum[keyof typeof EstateListDtoCategoryEnum];
export const EstateListDtoStatusEnum = {
    Incomplete: 'Incomplete',
    PendingApproval: 'PendingApproval',
    PendingPayment: 'PendingPayment',
    Available: 'Available',
    Occupied: 'Occupied',
    Unavailable: 'Unavailable',
    Rejected: 'Rejected'
} as const;

export type EstateListDtoStatusEnum = typeof EstateListDtoStatusEnum[keyof typeof EstateListDtoStatusEnum];


