import { amenitiesIconMapper } from "common/mappers/components/ui/icon/amenities-icon-mappers";
import { AmenitiesIconProps } from "common/types/components/ui/icon/amenities-icon.type";
import { sanitizeObject } from "common/utils/url";

export const EstateAmenitiesIcon = ({ name, width, height, color, onClick, clickable, className }: AmenitiesIconProps) => {
    const SelectedIcon = amenitiesIconMapper[name];
    const iconProps = sanitizeObject({ width, height, fill: color });

    if (!SelectedIcon) {
        const DefaultIcon = amenitiesIconMapper['default'];
        return <div
        className={`flex-center ${onClick || clickable ? 'cursor-pointer' : 'cursor-auto'} ${className}`}
        style={{ color }}
        onClick={onClick}
    >
        <DefaultIcon {...iconProps} />
    </div>
    }

    return (
        <div
            className={`flex-center ${onClick || clickable ? 'cursor-pointer' : 'cursor-auto'} ${className}`}
            style={{ color }}
            onClick={onClick}
        >
            <SelectedIcon {...iconProps} />
        </div>
    );
};
