import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { ENV } from 'common/enums/enums';

const BackgroundCheckStickyButton = () => {
  return (
    <Link className={styles['backgroundCheckStickyButton']} title='Hassle-Free Tenant Screening. Background Checks Credit Check. Check Get Started NOW!' target={"_blank"} to={ENV.REACT_APP_RENTPREP_AXXELIST_URL}></Link>
  )
}

export default BackgroundCheckStickyButton