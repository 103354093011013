import { CellProps } from 'react-table';
import { ApplicationsTableRow, ApplicationsTenantCell } from '../common/types/types';
import { Icon } from 'legacy-components/componets';

const OffersTenantCell = ({ value }: CellProps<ApplicationsTableRow, ApplicationsTenantCell>) => {
  const { userName } = value;
  return (
    <div className='flex gap-1 justify-center items-center max-w-[100%]'>
      <Icon name={'circle-checked'} className='text-[#27AE60]' height={12} width={12} />
      <div className={'text-black text-base text-start truncate'}>{userName}</div>
    </div>
  );
};

export { OffersTenantCell };
