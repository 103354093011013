export type AmenitiesIconName = 'default'
    | 'refrigerator'
    | 'stove'
    | 'microwave'
    | 'oven'
    | 'dishwasher'
    | 'cookware'
    | 'marbleFloor'
    | 'carpetFloor'
    | 'hardwood'
    | 'smartThermostats'
    | 'centralAC'
    | 'centralHeating'
    | 'furnished'
    | 'semiFurnished'
    | 'unfurnished'
    | 'TV'
    | 'internet/Wi-Fi'
    | 'designerLighting'
    | 'smartControlAppliances'
    | 'fireplace'
    | 'officeRoom'
    | 'balcony'
    | 'window'
    | 'closet'
    | 'wardrobe'
    | 'smartLEDLighting'
    | 'shower'
    | 'soakingTub'
    | 'jacuzzi'
    | 'washer'
    | 'dryer'
    | 'storage'
    | 'coveredParking'
    | 'streetParking'
    | 'garage'
    | 'keyFob'
    | 'CCTV'
    | 'smartLock'
    | 'gymFitnessCenter'
    | 'BBQStations'
    | 'playArea'
    | 'petGarden'
    | 'theater'
    | 'spaRoom'
    | 'meditationRoom'
    | 'basketballCourt'
    | 'volleyballCourt'
    | 'tennisCourt'
    | 'golfArea'
    | 'guestArea'
    | 'conferenceRooms'
    | 'arcade'
    | 'courtyards'
    | 'swimmingPool'
    | 'rooftopDeck';

export const amenitiesMapping = (
    (key: string) => {
        switch (key) {
            case 'Refrigerator':
                return 'refrigerator';
            case 'Stove':
                return 'stove';
            case 'Microwave':
                return 'microwave';
            case 'Oven':
                return 'oven';
            case 'Dishwasher':
                return 'dishwasher';
            case 'Cookware':
                return 'cookware';
            case 'Marble Floor':
                return 'marbleFloor';
            case 'Carpet Floor':
                return 'carpetFloor';
            case 'Hardwood':
                return 'hardwood';
            case 'Smart Thermostats':
                return 'smartThermostats';
            case 'Central AC':
                return 'centralAC';
            case 'Central Heating':
                return 'centralHeating';
            case 'Furnished':
                return 'furnished';
            case 'Semi-Furnished':
                return 'semiFurnished';
            case 'Unfurnished':
                return 'unfurnished';
            case 'TV':
                return 'TV';
            case 'Internet/Wi-Fi':
                return 'internet/Wi-Fi';
            case 'Designer Lightning':
                return 'designerLighting';
            case 'Smart Control Appliances':
                return 'smartControlAppliances';
            case 'Fire Place':
                return 'fireplace';
            case 'Office Room':
                return 'officeRoom';
            case 'Balcony':
                return 'balcony';
            case 'Window':
                return 'window';
            case 'Closet':
                return 'closet';
            case 'Wardrobe':
                return 'wardrobe';
            case 'Smart LED Lightning':
                return 'smartLEDLighting';
            case 'Shower':
                return 'shower';
            case 'Soaking Tub':
                return 'soakingTub';
            case 'Jacuzzi':
                return 'jacuzzi';
            case 'Washer':
                return 'washer';
            case 'Dryer':
                return 'dryer';
            case 'Storage ':
                return 'storage';
            case 'Covered Parking':
                return 'coveredParking';
            case 'Street Parking':
                return 'streetParking';
            case 'Garage':
                return 'garage';
            case 'Key Fob':
                return 'keyFob';
            case 'CCTV':
                return 'CCTV';
            case 'Smart Lock':
                return 'smartLock';
            case 'Gym/Fitness Center':
                return 'gymFitnessCenter';
            case 'BBQ Stations':
                return 'BBQStations';
            case 'Play Area':
                return 'playArea';
            case 'Pet Garden':
                return 'petGarden';
            case 'Theater':
                return 'theater';
            case 'Spa Room':
                return 'spaRoom';
            case 'Meditation Room':
                return 'meditationRoom';
            case 'Basketball Court':
                return 'basketballCourt';
            case 'Volleyball Court':
                return 'volleyballCourt';
            case 'Tennis Court':
                return 'tennisCourt';
            case 'Golf Area':
                return 'golfArea';
            case 'Guest Area':
                return 'guestArea';
            case 'Conference Rooms':
                return 'conferenceRooms';
            case 'Arcade':
                return 'arcade';
            case 'Courtyards':
                return 'courtyards';
            case 'Swimming Pool':
                return 'swimmingPool';
            case 'Rooftop Deck':
                return 'rooftopDeck';
            default:
                return 'default';
        }
    }
);


export type AmenitiesIconProps = React.SVGProps<SVGSVGElement> & {
    name: AmenitiesIconName;
    width?: number | string;
    height?: number | string;
    color?: string;
    onClick?: () => void;
    clickable?: boolean;
    className?: string;
};
