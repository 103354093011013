import { AuthRole } from 'common/enums/enums';
import { useAuth } from 'hooks';
import { HasRoles } from 'common/types/types';

export const useHasRoles = (roles: HasRoles) => {
  const { isAuthenticated, user } = useAuth();

  const allowedRoles = Array.isArray(roles) ? roles : [roles];

  if (!isAuthenticated) {
    return false;
  }

  return user?.roles.some((role: AuthRole) => allowedRoles.includes(role));
};
