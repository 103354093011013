import {
  UndefinedInitialDataOptions,
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ContactUsFormDtoPagedResults } from 'common/generated/types/contact-us-form-dto-paged-results';
import { BackendError } from 'common/types/errors/backendError';
import { FeedbacksListRequest } from 'common/types/services/api/notification/notifications-dto.type';
import { getFeedbacksList, setDoneFeedback, setInProgressFeedback, setNewFeedback } from 'services/api/notification-api.service';

export const useGetAdminFeedbacks = (
  params: FeedbacksListRequest,
  options?: Omit<
    UndefinedInitialDataOptions<
      ContactUsFormDtoPagedResults,
      Error,
      ContactUsFormDtoPagedResults,
      (string | FeedbacksListRequest)[]
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  return useQuery({
    queryKey: ['feedbacks', 'admin', params],
    queryFn: () => {
      return getFeedbacksList(params);
    },
    ...options,
  });
};

export const useSetDoneFeedback = (
  options?: Omit<UseMutationOptions<void, AxiosError<BackendError>, string, unknown>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setDoneFeedback,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['feedbacks', 'admin'] });
      options?.onSettled?.(data, error, variables, context);
    },
    ...options,
  });
};

export const useSetInProgressFeedback = (
  options?: Omit<UseMutationOptions<void, AxiosError<BackendError>, string, unknown>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setInProgressFeedback,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['feedbacks', 'admin'] });
      options?.onSettled?.(data, error, variables, context);
    },
    ...options,
  });
};

export const useSetNewFeedback = (
  options?: Omit<UseMutationOptions<void, AxiosError<BackendError>, string, unknown>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setNewFeedback,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['feedbacks', 'admin'] });
      options?.onSettled?.(data, error, variables, context);
    },
    ...options,
  });
};
