import { CSSObjectWithLabel } from 'react-select';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import { SelectCustomStyles, SelectTheme } from 'common/types/types';

const select = (theme: SelectTheme, isError = false, customStyle?: Partial<SelectCustomStyles>): StylesConfig => {
  switch (theme) {
    case 'primary':
      return {
        container: (base: CSSObjectWithLabel) => ({
          ...base,
          padding: 0,
          margin: 0,
          ...customStyle?.container,
        }),
        control: (base: CSSObjectWithLabel) => ({
          ...base,
          width: '100%',
          height: 44,
          border: 'none',
          boxShadow: 'none',
          borderRadius: 'unset',
          borderBottom: '1px solid #C4C4C4',
          ...customStyle?.control,
        }),
        indicatorsContainer: (base: CSSObjectWithLabel) => ({
          ...base,
          ...customStyle?.indicatorsContainer,
        }),
        indicatorSeparator: (base: CSSObjectWithLabel) => ({
          ...base,
          width: 0,
          ...customStyle?.indicatorSeparator,
        }),
        valueContainer: (base: CSSObjectWithLabel) => ({
          ...base,
          height: 40,
          padding: 0,
          margin: 0,
          ...customStyle?.valueContainer,
        }),
        singleValue: (base: CSSObjectWithLabel) => ({
          ...base,
          fontSize: 16,
          fontWeight: 500,
          color: '#222222',
          margin: 0,
          ...customStyle?.singleValue,
        }),
        placeholder: (base: CSSObjectWithLabel) => ({
          ...base,
          fontSize: 18,
          ...customStyle?.placeholder,
        }),
        dropdownIndicator: (base: CSSObjectWithLabel) => ({
          ...base,
          color: '#222222',
          ...customStyle?.dropdownIndicator,
        }),
        clearIndicator: (base: CSSObjectWithLabel) => ({
          ...base,
          ...customStyle?.clearIndicator,
        }),
        menu: (base: CSSObjectWithLabel) => ({
          ...base,
          marginTop: 4,
          marginLeft: 0,
          ...customStyle?.menu,
        }),
        menuList: (base: CSSObjectWithLabel) => ({
          ...base,
          padding: 0,
          ...customStyle?.menuList,
        }),
        option: (base: CSSObjectWithLabel) => ({
          ...base,
          height: 42,
          color: '#222222',
          display: 'flex',
          cursor: 'pointer',
          alignItems: 'center',
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: '#F0F0F0',
          },
          ...customStyle?.option,
        }),
      };

    case 'default':
      return {
        container: (base: CSSObjectWithLabel) => ({
          ...base,
          padding: 0,
          margin: 0,
          ...customStyle?.container,
        }),
        control: (base: CSSObjectWithLabel, state: { isFocused: boolean }) => ({
          ...base,
          width: '100%',
          height: 44,
          border: `1px solid ${state.isFocused ? (isError ? '#FF385C' : '#66ccff') : isError ? '#FF385C' : '#e5e5e5'}`,
          borderRadius: 8,
          boxShadow: 'none',
          '&:hover': {
            border: `1px solid ${isError ? '#FF385C' : '#66ccff'}`,
            boxShadow: 'none',
          },
          ...customStyle?.control,
        }),
        indicatorsContainer: (base: CSSObjectWithLabel) => ({
          ...base,
          ...customStyle?.indicatorsContainer,
        }),
        indicatorSeparator: (base: CSSObjectWithLabel) => ({
          ...base,
          width: 0,
          ...customStyle?.indicatorSeparator,
        }),
        valueContainer: (base: CSSObjectWithLabel) => ({
          ...base,
          height: 40,
          // padding: 0,
          // margin: 0,
          ...customStyle?.valueContainer,
        }),
        singleValue: (base: CSSObjectWithLabel) => ({
          ...base,
          fontSize: 16,
          fontWeight: 500,
          color: '#222222',
          margin: 0,
          paddingLeft: 16,
          ...customStyle?.singleValue,
        }),
        placeholder: (base: CSSObjectWithLabel) => ({
          ...base,
          fontSize: 16,
          paddingLeft: 16,
          fontWeight: 500,
          ...customStyle?.placeholder,
        }),
        dropdownIndicator: (base: CSSObjectWithLabel) => ({
          ...base,
          color: '#222222',
          ...customStyle?.dropdownIndicator,
        }),
        clearIndicator: (base: CSSObjectWithLabel) => ({
          ...base,
          ...customStyle?.clearIndicator,
        }),
        menu: (base: CSSObjectWithLabel) => ({
          ...base,
          marginTop: 4,
          marginLeft: 0,
          zIndex: 10,
          ...customStyle?.menu,
        }),
        menuList: (base: CSSObjectWithLabel) => ({
          ...base,
          padding: 0,
          ...customStyle?.menuList,
        }),
        option: (base: CSSObjectWithLabel, { isDisabled }) => ({
          ...base,
          height: 42,
          color: isDisabled ? 'red' : '#222222',
          display: 'flex',
          cursor: 'pointer',
          alignItems: 'center',
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: '#F0F0F0',
          },
          '&:disabled': { color: 'red', background: 'yellow' },
          ...customStyle?.option,
        }),
        // ...customStyle
      };
  }
};

const label: Record<SelectTheme, string> = {
  primary: 'text-base font-bold',
  default: 'text-base font-bold',
};

const styles = { select, label };

export default styles;
