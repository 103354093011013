import React from 'react';
import { CellProps } from 'react-table';
import { ApplicationsTableRow } from '../common/types/types';
import { getDayjs } from 'helpers/date';

const OffersAppliedCell = ({ value }: CellProps<ApplicationsTableRow, Date>) => {
  return <div className='text-base font-medium'>{getDayjs(value).format('D, MMM YYYY, h:mm A')}</div>;
};

export { OffersAppliedCell };
