import { NavLink } from 'react-router-dom';

import { useHasRoles } from 'hooks/use-has-roles.hook';

import { Dropdown } from 'legacy-components/componets';

import { AuthRole } from 'common/enums/enums';
import { registerDropdownMenuList } from './config';

import arrowDownIcon from 'assets/icons/arrow-down.svg';

import styles from './styles';

export type NavBarProps = {
  isAuthenticated: boolean;
};

const NavBar = ({ isAuthenticated }: NavBarProps) => {
  const isLandlord = useHasRoles([AuthRole.Landlord]);

  const targetDropdownItem = (title: string, showItem?: boolean) => {
    return (
      <div className={styles.navItem}>
        <div className='mr-3'>{title}</div>
        {showItem && <img src={arrowDownIcon} alt='' />}
      </div>
    );
  };

  // const dropdownMenu = (() => {
  //     return (
  //         <div className={styles.dropdownMenu}>
  //             {navDropdownMenu.map((item, index) => (
  //                     <NavLink to={item.to} key={index}>
  //                         <div className={styles.dropdownItem}>{item.title}</div>
  //                     </NavLink>
  //                 )
  //             )}
  //         </div>
  //     )
  // })();

  const registerDropdownMenu = (() => {
    return (
      <div className={styles.dropdownMenu}>
        {registerDropdownMenuList.map((item, index) => (
          <NavLink to={item.to} key={index} state={item.state}>
            <div className={styles.dropdownItem}>{item.title}</div>
          </NavLink>
        ))}
      </div>
    );
  })();

  const listPropertyLink = isAuthenticated ? 'i-landlord/properties' : 'auth/sign-in';

  return (
    <nav className={styles.container}>
      <div className='left-link flex xl:pl-[149px] lg:pl-[100px] md:pl-[10px] xl:gap-14 lg:gap-8 grow'>
        <div className={'items-center text-sm font-bold hidden xl:flex'}>Your rental navigation co-pilot</div>
        {/* {navLinks.map((nav, index) => {
                        return nav.title === NavLinksTitle.ManageProperty
                            ? <Dropdown key={index} target={targetDropdownItem(nav.title)} menu={dropdownMenu}/>
                            : (
                                <NavLink key={index} to={nav.to} className="">
                                    <div className={styles.navItem}>{nav.title}</div>
                                </NavLink>
                            )
                    })} */}
      </div>
      <div className='flex right-link lg:pr-[18px] md:pr-2 gap-[18px]'>
        {/* <NavLink to={''}>
          <div className={styles.navItem}>iRent</div>
        </NavLink> */}

        {(isLandlord || !isAuthenticated) && (
          <NavLink to={listPropertyLink} className=''>
            <div className={styles.navItem}>List a property</div>
          </NavLink>
        )}

        {!isAuthenticated && <Dropdown target={targetDropdownItem('Register')} menu={registerDropdownMenu} />}
      </div>
    </nav>
  );
};
export { NavBar };
