import { TablePlaceholderProps } from 'common/types/types';
import { FC } from 'react';

const TablePlaceholder: FC<TablePlaceholderProps> = ({
  title = 'No data to display',
  height = 200,
  className = '',
}) => {
  return (
    <div style={{ height }} className={`flex-center ${className}`}>
      {title}
    </div>
  );
};

export { TablePlaceholder };
