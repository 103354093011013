import { CellProps } from "react-table";
import { OfferPriceCellProps, OffersTableRow } from '../common/types/OfferTable.types';
import { getPricePerMonth } from "../common/utils/OfferPage.utils";

export const OfferPriceCell = ({ value }: CellProps<OffersTableRow, OfferPriceCellProps>) => {
    return (
        <div>
            <p className="text-trueGray">
                {getPricePerMonth(value)}
            </p>
        </div>
    )
};
