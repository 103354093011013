import { Button, Icon, Modal, NotificationTypeIcon } from 'legacy-components/componets';
import { PaidRescheduleTourModalProps } from './PaidRescheduleTourModal.types';
import { NotificationType } from 'common/enums/enums';
import {
  CANCEL_RESCHEDULE_TOUR,
  MODAL_DESCRIPTION,
  MODAL_DESCRIPTION_QUESTION,
  MODAL_TITLE,
  MODAL_WARNING_MSG,
  RESCHEDULE_TOUR,
} from './PaidRescheduleTourModal.const';
import { CloseOutlined } from '@ant-design/icons';

export const PaidPaidRescheduleTourModal: React.FC<PaidRescheduleTourModalProps> = ({ open, onClose, onConfirm }) => {
  return (
    <Modal
      isOpen={open}
      styles={{
        content: {
          padding: '25px',
          maxHeight: '100%',
          maxWidth: '550px',
        },
      }}
      onClose={onClose}
    >
      <div className='flex flex-col gap-3'>
        <div className='flex justify-end'>
          <button onClick={onClose} type='button'>
            <CloseOutlined className={`text-primary text-[22px]`} />
          </button>
        </div>
        <div className='flex-grow flex items-center justify-center'>
          <NotificationTypeIcon type={NotificationType.INFO} size={'l'} />
        </div>
        <div className='flex flex-col gap-3'>
          <div className='text-xl text-center font-semibold'>{MODAL_TITLE}</div>
          <div className='flex flex-col gap-1'>
            <div className='text-[#87898E] text-base font-semibold text-center'>{MODAL_DESCRIPTION_QUESTION}</div>
            <div className='text-[#87898E] text-base text-center'>{MODAL_DESCRIPTION}</div>
          </div>
        </div>
        <div className={'flex items-center gap-4 text-xs select-none bg-[#FFF1F0] p-2 mb-2 rounded-xl'}>
          <Icon name={'info-circle'} height={24} width={24} />
          <div>
            <div className={'font-medium mb-2 text-[#E53535] text-base'}>{MODAL_WARNING_MSG}</div>
          </div>
        </div>
        <div className='flex flex-col sm:flex-row justify-center items-center gap-4'>
          <Button label={CANCEL_RESCHEDULE_TOUR} onClick={onClose} isFullWidth />
          <Button
            label={RESCHEDULE_TOUR}
            theme={'gray-outline'}
            isFullWidth
            onClick={onConfirm}
            stylesConfig={{ color: 'black', borderColor: '#87898E' }}
          />
        </div>
      </div>
    </Modal>
  );
};
