import { PropertyCategory, PropertyDtoType } from 'common/enums/enums';
import { IconSVGName } from 'common/types/components/ui/ui';
import { SelectOption } from 'common/types/types';
import { EstateSearchBarValues } from '../estate-search-bar/estate-search-bar';

export const ITEMS_PER_PAGE = 12;

export const categoryTypeToCategoryName: Record<PropertyCategory, string> = {
  // [PropertyCategory.SingleFamilyHouse]: 'Single-Units',
  // [PropertyCategory.MultiFamilyHouse]: 'Multi-Units',
  [PropertyCategory.Townhouse]: 'Townhouse',
  [PropertyCategory.Apartment]: 'Apartment',
  [PropertyCategory.Condo]: 'Condo',
  [PropertyCategory.House]: 'House',
  // [PropertyCategory.GuestHouse]: 'Guest House',
  // [PropertyCategory.Cabin]: 'Cabin',
  // [PropertyCategory.Barn]: 'Barn',
  // [PropertyCategory.Farm]: 'Farm',
  // [PropertyCategory.Mobile]: 'Mobile',
  // [PropertyCategory.HouseBoat]: 'House Boat',
  // [PropertyCategory.TinyHome]: 'Tiny renter-layout'
};

export const estateTypeToTypeName: Record<PropertyDtoType, string> = {
  [PropertyDtoType.Single]: 'Single Unit',
  [PropertyDtoType.Multiple]: 'Multi Unit',
};

export const estateCategoryToTypeName: Record<PropertyCategory, string> = {
  [PropertyCategory.Townhouse]: 'Townhouse',
  [PropertyCategory.Apartment]: 'Apartment',
  [PropertyCategory.Condo]: 'Condo',
  [PropertyCategory.House]: 'House',
};

export const categoryTypeToIcon: Record<PropertyCategory, IconSVGName> = {
  // [PropertyCategory.SingleFamilyHouse]: 'singleFamilyHouse',
  // [PropertyCategory.MultiFamilyHouse]: 'multiFamilyHouse',
  [PropertyCategory.Townhouse]: 'townhouse',
  [PropertyCategory.Apartment]: 'apartment',
  [PropertyCategory.Condo]: 'condo',
  [PropertyCategory.House]: 'tinyHome',
  // [PropertyCategory.GuestHouse]: guestHouseIcon,
  // [PropertyCategory.Cabin]: cabinIcon,
  // [PropertyCategory.Barn]: barnIcon,
  // [PropertyCategory.Farm]: farmIcon,
  // [PropertyCategory.Mobile]: mobileHouseIcon,
  // [PropertyCategory.HouseBoat]: houseBoatIcon,
  // [PropertyCategory.TinyHome]: tinyHomeIcon
};

export const estateCategories: SelectOption[] = Object.values(PropertyCategory).map((value, index, array) => ({
  value,
  label: categoryTypeToCategoryName[value],
}));

export const estateTypes: SelectOption[] = Object.values(PropertyDtoType).map((value, index, array) => ({
  value,
  label: estateTypeToTypeName[value],
}));

export const defaultQueryParams: Omit<EstateSearchBarValues, 'page' | 'perPage'> = {
  zip: '',
  street: '',
  city: '',
  state: '',
  location: '',
  type: '',
  categories: [],
  beds: '',
  bathrooms: '',
  minPrice: '',
  maxPrice: '',
  sortBy: undefined,
  order: undefined
};
