export const phoneNumberDisabledInputStyles: React.CSSProperties = {
  pointerEvents: 'none',
  backgroundColor: '#FAFAFA',
  border: '1px solid #E5E5E5',
  color: 'black',
};
export const phoneNumberDisabledButtonStyles: React.CSSProperties = {
  pointerEvents: 'none',
  cursor: 'default',
};
