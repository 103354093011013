export const colors = {
  white: '#FFFFFF',
  primary: '#66CCFF',
  primaryDark: '#0996CE',
  secondary: '#F1FBFF',
  black: '#222222',
  trueGray: '#6C6C6C',
  warmGray: '#C4C4C4',
  lightGrey: '#E5E5E5',
  gray: '#F0F0F0',
  error: '#FF385C',
  danger: '#FF8800',
};
