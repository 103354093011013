import { Button, NotificationTypeIcon } from 'legacy-components/componets';
import { OverdueInformationBlockProps } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/types/overdue-information-block-props.type';

import styles from './styles';

const OverdueInformationBlock = ({ title, overdueTime, onNeedHelp }: OverdueInformationBlockProps) => {
  return (
    <div className={styles.container}>
      <div className={`flex w-10 h-10`}>
        <NotificationTypeIcon type='warning' />
      </div>
      <div className='flex flex-col gap-2 w-full'>
        <div className={styles.title}>{title}</div>
        <div>
          You have not pressed
          <span> </span>
          <span className='font-bold'>Start Tour.</span>
          {/* <span> </span>
          There are.
          <span className='font-bold'>{overdueTime}</span>
          <span> </span>
          remaining of this tour. */}
        </div>
        {/* <div className='grid gap-6 grid-cols-1 md:grid-cols-3'>
          <Button onClick={onNeedHelp} theme={'primary-outline'} label={'Need help'} />
          <Button onClick={onNeedHelp} theme={'primary-outline'} label={'Need to cancel/resecheule'} />
          <Button onClick={onNeedHelp} theme={'primary-outline'} label={'Forgot to press start'} />
        </div> */}
      </div>
    </div>
  );
};

export { OverdueInformationBlock };
