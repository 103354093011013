import { Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, GoBackLink, Loader, MakeOfferModal, NotificationTypeIcon } from 'legacy-components/componets';
import { dateFormatMM_DD_YY, dateFormatMMMM_DD, dateTimeFormatHHMM_a, getDayjs } from 'helpers/date';
import { TourStatusDto } from 'common/enums/enums';
import {
  ARRIVAL_TIME,
  MAKE_OFFER_BTN,
  QUESTION,
  REASON,
  TOUR_DATE,
  TOUR_DETAILS,
  TOUR_DURATION,
  TOUR_DURATION_MIN,
  TOUR_ENDS,
} from './TourHistoryTourDetailsPage.const';
import dayjs from 'dayjs';
import { EstateCard } from 'components/organisms/EstateCard';
import { useWindowSize } from 'hooks';
import { useEstateDetails, useTourDetails } from 'hooks/query';
import { Divider } from 'antd';
import { getStatusDescription, getStatusIcon, getStatusTitle } from './TourHistoryTourDetailsPage.utils';

export const TourHistoryTourDetailsPage = () => {
  const { tourId = '' } = useParams<{ tourId: string }>();
  const { isDesktop } = useWindowSize();
  const navigate = useNavigate();
  const [rentModalOpen, setRentModalOpen] = useState(false);
  const { data: tourDetails, isLoading: isTourDetailsLoading } = useTourDetails(tourId);
  const { data: estateDetails } = useEstateDetails(tourDetails?.estateId);

  const handleViewFullListing = () => {
    navigate(`/estates/${tourDetails?.estateId}`);
  };

  if (isTourDetailsLoading) {
    return <Loader />;
  }

  const utcDate = dayjs(tourDetails?.slot).utc();

  return (
    <>
      {tourDetails && (
        <MakeOfferModal
          open={rentModalOpen}
          onClose={() => setRentModalOpen(false)}
          estateId={tourDetails?.estateId}
          availableOn={estateDetails?.units.find((u) => u.id === tourDetails?.unitId)?.availableOn}
          unitId={tourDetails?.unitId}
        />
      )}
      <div className='tour-details flex flex-col gap-2 md:gap-9 w-full mb-[30px] px-2 md:px-0'>
        <GoBackLink title={'Back to tours'} onClick={() => navigate('/i-rent/profile/tours/upcoming')} />
        <div className='flex flex-col gap-9 mt-1 shadow-white-xl p-[34px] flex-grow'>
          <div className='tour-date text-2xl text-trueGray font-bold uppercase mb-[14px]'>
            {dateFormatMMMM_DD(utcDate)}
          </div>
          <div className='flex justify-center w-full'>
            {tourDetails && (
              <EstateCard
                style={{
                  width: '100%',
                }}
                isHorizontal={isDesktop}
                city={tourDetails?.city}
                location={tourDetails?.location}
                price={tourDetails?.price}
                category={tourDetails?.category}
                beds={tourDetails?.beds}
                bathrooms={tourDetails?.bathrooms}
                propertyArea={tourDetails?.propertyArea}
                type={tourDetails?.type}
                thumbnailUrl={tourDetails?.thumbnailUrl}
              />
            )}
          </div>
          <Button label={'View full listing'} theme={'secondary'} onClick={handleViewFullListing} />

          <div className='description flex flex-col gap-3 p-6 rounded-lg shadow-white-xl'>
            <div className='flex-grow flex items-center justify-start'>
              <NotificationTypeIcon type={getStatusIcon(tourDetails?.tourStatus)} size={'s'} />
            </div>

            <div className='font-bold text-base sm:text-xl'>{getStatusTitle(tourDetails?.tourStatus)}</div>
            <div className='text-sm'>
              {getStatusDescription(tourDetails?.tourStatus, utcDate.format('MMM D, YYYY'))}
            </div>

            {tourDetails?.tourStatus === TourStatusDto.Finished && (
              <Fragment>
                <div className='text-sm w-full'>
                  {QUESTION}
                  {tourDetails?.location}
                </div>
                <Button
                  label={MAKE_OFFER_BTN}
                  theme={'primary'}
                  icon={'file-lines'}
                  onClick={() => setRentModalOpen(true)}
                />
              </Fragment>
            )}

            {tourDetails?.tourStatus === TourStatusDto.Rejected && (
              <div className='flex flex-col p-2 items-start'>
                <div className='text-base font-bold mb-2'>{REASON}</div>
                <div className='text-base border border-primary w-full pl-4 p-2 rounded-lg text-wrapper text-ellipsis overflow-hidden ...'>
                  {tourDetails?.rejectReason}
                </div>
              </div>
            )}
          </div>
          <div className='description flex flex-col gap-3 border border-gray p-6 rounded-lg'>
            <div className='font-bold'>{TOUR_DETAILS}</div>
            <div className='text-base font-normal gap-9 grid grid-cols-1 md:flex'>
              <div className='w-full md:w-1/2 flex gap-9'>
                <div className='w-1/2 flex flex-col'>
                  <span>{TOUR_DATE}</span>
                  <span>{ARRIVAL_TIME}</span>
                </div>
                <div className='w-1/2 flex flex-col text-trueGray'>
                  <span>{dateFormatMM_DD_YY(utcDate)}</span>
                  <span>{dateTimeFormatHHMM_a(utcDate)}</span>
                </div>
              </div>
              <Divider type='vertical' className='h-full' />
              <div className='w-full md:w-1/2 flex gap-9'>
                <div className='w-1/2 flex flex-col'>
                  <span>{TOUR_DURATION}</span>
                  <span>{TOUR_ENDS}</span>
                </div>
                <div className='w-1/2 flex flex-col text-trueGray'>
                  <span>{TOUR_DURATION_MIN}</span>
                  <span>{dateTimeFormatHHMM_a(getDayjs(utcDate).add(30, 'minutes'))}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
