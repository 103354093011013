import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { OfferPaymentIntentDto } from 'common/types/services/api/offers/offer-payment-intent-dto.type';
import { getPreOfferPayment } from 'services/api/offers-api.service';

export default function usePreOfferPayment(
  offerId: string,
  options?: Omit<
    UndefinedInitialDataOptions<
    OfferPaymentIntentDto,
      Error,
      OfferPaymentIntentDto,
      string[]
    >,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    ...options,
    queryKey: [QueryKey.Offers, offerId, 'pre-payment'],
    queryFn: () => getPreOfferPayment(offerId),
  });
}


// import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
// import { getOfferPayment } from 'services/api/offers-api.service';
// import { OfferPaymentIntentDto } from 'common/types/services/api/offers/offer-payment-intent-dto.type';
// import { QueryKey } from 'common/enums/query-key';

// export default function useOfferPayment(
//   offerId: string,
//   options?: Omit<
//     UndefinedInitialDataOptions<OfferPaymentIntentDto, Error, OfferPaymentIntentDto, string[]>,
//     'queryKey' | 'queryFn'
//   >,
// ) {
//   return useQuery({
//     queryKey: [QueryKey.Offers, offerId, 'payment'],
//     queryFn: () => getOfferPayment(offerId),
//     ...options,
//   });
// }
