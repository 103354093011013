import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { cancelOffer } from 'services/api/offers-api.service';
import { QueryKey } from 'common/enums/query-key';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';

export default function useCancelOffer(
  options?: Omit<UseMutationOptions<boolean, AxiosError<BackendError>, string, unknown>, 'mutationFn'>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: cancelOffer,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Offers] });
    },
    ...options,
  });
}
