import React, { FC } from 'react';
import dayjs from 'dayjs';
import Countdown from 'antd/es/statistic/Countdown';
import { InfoToolTip } from 'components/atoms/InfoToolTip/InfoToolTip';

type Props = {
  scheduleAt: string;
  valueStyle?: React.CSSProperties;
  onFinish?: () => void;
};

const RenterTourPaymentCountdown: FC<Props> = ({ scheduleAt, valueStyle, onFinish }) => {
  const deadline = dayjs(scheduleAt).add(1, 'hour').format();
  return (
    <div className='flex gap-2'>
      <InfoToolTip toolTipText='If you do not pay for the tour before the time is up, then it will automatically expired' />
      <Countdown value={deadline} valueStyle={valueStyle} onFinish={onFinish} />
    </div>
  );
};

export { RenterTourPaymentCountdown };
