import React, { FC, useMemo } from 'react';
import { usePropertyAmenities } from 'hooks/query';
import { useFormikContext } from 'formik';
import { PropertyValues } from '../landlord-properties';
import Amenities from './Amenities';
import { PropertyUnitValues } from './property-detail';

type Props = {
  unit: PropertyUnitValues;
  unitIndex: number;
};

const PropertyUnitAmenities: FC<Props> = ({ unit, unitIndex }) => {
  const { data: amenitiesList } = usePropertyAmenities({});
  const { setFieldValue } = useFormikContext<PropertyValues>();
  const filteredAmenities = useMemo(
    () => (amenitiesList ? amenitiesList.filter((item) => item.type === 'Unit') : []),
    [amenitiesList],
  );

  return (
    <Amenities
      amenities={filteredAmenities}
      onChange={async (amenity) => {
        const fieldName = `units[${unitIndex}].amenities[${amenity.id}]`;
        await setFieldValue(fieldName, !unit.amenities[amenity.id], false);
      }}
      values={unit.amenities}
      checkboxNameFn={(amenityId) => `details.units[${unitIndex}].amenities[${amenityId}]`}
    />
  );
};

export default PropertyUnitAmenities;
