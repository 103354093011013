import { CellProps } from 'react-table';
import { OffersPropertyCellProps, OffersTableRow } from '../common/types/OfferTable.types';
import { getPricePerMonth } from '../common/utils/OfferPage.utils';
import { PropertyImage } from 'components/atoms/PropertyImage/PropertyImage';

export const OffersPropertyCell = ({ value }: CellProps<OffersTableRow, OffersPropertyCellProps>) => {
  const { location, price, image, unitNumber, isMulti } = value;
  return (
    <div className='flex gap-3 items-center'>
      <div className='w-[64px] h-[48px] min-w-[64px]'>
        <PropertyImage image={image} />
      </div>
      <div className={'text-sm'}>
        <div>{location}</div>
        {isMulti && <div>{`(Unit ${unitNumber})`}</div>}
        <p className='text-trueGray'>{getPricePerMonth(price)}</p>
      </div>
    </div>
  );
};
