import React, { CSSProperties, ReactNode } from 'react';
import { useOutsideClickHook } from 'hooks/use-outside-click.hook';

export interface IDropdownProps {
  target: ReactNode;
  menu: ReactNode;
  styles?: CSSProperties;
}

const Dropdown = ({ target, menu, styles }: IDropdownProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const ref = React.useRef(null);
  useOutsideClickHook(ref, () => setIsOpen(false));
  return (
    <div className='relative' onClick={() => setIsOpen((prevState) => !prevState)} ref={ref} style={styles}>
      {target}
      {isOpen && menu}
    </div>
  );
};

export { Dropdown };
