import { Column } from 'react-table';
import { ApplicationsTableRow } from 'legacy-pages/landlord/profile/offers/common/types/applications-table-row';
import { ApplicationsTableAccessor } from 'legacy-pages/landlord/profile/offers/common/enums/applications-table-accessor';
import { OffersTenantCell } from 'legacy-pages/landlord/profile/offers/components/offers-tenant-cell';
import { OffersAppliedCell } from 'legacy-pages/landlord/profile/offers/components/offers-applied-cell';
import { OffersCreditStoreCell } from 'legacy-pages/landlord/profile/offers/components/offers-credit-store-cell';
import { OffersActionsMenuCell } from 'legacy-pages/landlord/profile/offers/components/offers-actions-menu-cell';

export const columns: Column<ApplicationsTableRow>[] = [
  {
    Header: 'Tenant',
    accessor: ApplicationsTableAccessor.Tenant,
    Cell: OffersTenantCell,
    width: '35%',
    minWidth: 250,
  },
  {
    Header: 'Applied',
    accessor: ApplicationsTableAccessor.Applied,
    Cell: OffersAppliedCell,
    width: '35%',
    minWidth: 120,
  },
  // {
  //   Header: 'Credit Score',
  //   accessor: ApplicationsTableAccessor.CreditScore,
  //   Cell: OffersCreditStoreCell,
  //   width: '17%',
  //   minWidth: 130,
  // },
  {
    Header: 'Actions',
    accessor: ApplicationsTableAccessor.Payload,
    Cell: OffersActionsMenuCell,
    width: '30%',
    minWidth: 100,
  },
];
