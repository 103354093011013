import cn from 'clsx';
import { LabelProps } from 'common/types/types';

import styles from './styles';

const Label = ({
  label,
  children,
  theme = 'default',
  size = 's',
  styleConfig,
  className,
  labelChildren,
}: LabelProps) => {
  return (
    <div style={styleConfig?.container} className={className}>
      <div className={cn('label', styles[theme][size], 'flex gap-2')} style={styleConfig?.label}>
        {label}
        {labelChildren}
      </div>
      {children}
    </div>
  );
};

export { Label };
