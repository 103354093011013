import dayjs, { ConfigType, Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(duration);

export const getDayjs = (date?: ConfigType): Dayjs => dayjs(date);

export const getDate = (date?: ConfigType): Date => dayjs(date || undefined).toDate();

export const isDateValid = (date: ConfigType): boolean => {
  return dayjs(date).isValid();
};

export const dateFormatMMMM_D_YYYY = (date: ConfigType) => {
  if (!date || !isDateValid(date)) {
    return '';
  }
  return dayjs(date).format('MMMM D, YYYY');
};

export const dateFormatMMMM_DD_YYYY_HH_MM_A = (date: ConfigType) => {
  if (!date || !isDateValid(date)) {
    return '';
  }
  return dayjs(date).format('MMMM DD, YYYY hh:mm A');
};

export const dateFormatYYYY_MM_DD = (date: ConfigType): string => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format('YYYY-MM-DD');
};

export const dateFormatMM_DD_YY = (date: ConfigType): string => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format('MM/DD/YY');
};

export const dateFormatMMM_D_YYYY = (date: ConfigType): string => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format('MMM D, YYYY');
};

export const dateFormatMMMM_DD = (date: ConfigType): string => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format('MMMM DD');
};

export const dateTimeFormatHHMM_a = (date: ConfigType): string => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format('hh:mm a');
};

export const dateTimeFormatHHMM_A = (date: ConfigType): string => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format('hh:mm A');
};

export const dateTimeFormatHH_MM = (date: ConfigType): string => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format('hh:mm');
};

export const dateFormatMMMM = (date: Date | string | null | undefined) => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format('MMMM');
};

export const getDateByTimezone = (date: ConfigType, timezone: string): Date => {
  return dayjs.tz(date, timezone).toDate();
};

export const getDateByTimezoneToISOString = (date: ConfigType, timezone: string) => {
  return getDateByTimezone(date, timezone).toISOString();
};

export const getDateToISOString = (date: ConfigType) => getDayjs(date).toISOString();

export const dateFormatMMMMYYYY = (date: Date | string | null | undefined) => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }
  return dayjs(date).format('MMMM YYYY');
};

export const getDateWithZeroHours = () => {
  return dayjs().startOf('day').toDate();
};
