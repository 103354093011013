import React from 'react';
import { ErrorValidationMessageProps } from './types/types';
import { joinValues } from 'helpers/helpers';

const ErrorValidationMessage = ({ message, touched, children, style }: ErrorValidationMessageProps) => {
  const errorMessage = joinValues([Array.isArray(message) ? message : [message]], ' ');
  const invalid = Boolean(message && touched);

  return (
    <div>
      {children}
      {invalid && errorMessage && (
        <p className='text-xs text-error py-1 h-6' style={style}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export { ErrorValidationMessage };
