const styles = {
  container: 'flex-center relative min-w-[36px] h-[36px] rounded-full border border-gray mr-4 relative select-none',
  counter: `
       bg-primary 
       flex-center
       h-[16px]
       w-[16px]
       p-0.5
       text-[8px]
       text-white
       rounded-full
       z-10
       absolute
       inline-block
       top-[12px]
       right-[12px]
       bottom-auto
       left-auto
       translate-x-2/4
       -translate-y-1/2
       rotate-0
       skew-x-0
       skew-y-0
       scale-x-100
       scale-y-100`,
  bell: 'text-sm font-semibold text-black',
};

export default styles;
