import React, { useMemo, useState } from 'react';
import { ADD_NEW_BTN, CREATE_COMMISSION_TITLE, EDIT_COMMISSION_TITLE, PAGE_TITLE } from './AdminCommission.const';
import { AdminEstateTable } from './AdminCommissionTable/AdminCommissionTable';
import { useQueryParams } from 'use-query-params';
import {
  createAdminTableData,
  useCreateQueryParamConfig,
  useCreateTablePaginationProps,
} from './AdminCommission.utils';
import { getNewCurrentPage } from 'helpers/pagination';
import { Button } from 'legacy-components/componets';
import { EditCommissionProps } from './AdminCommission.types';
import { AdminCommissionModal } from './AdminCommissionModal/AdminCommissionModal';
import { useCreateNewCommission, useDeleteCommissions, useEditCommission, useGetCommissions } from 'hooks/query';
import { CommissionDto } from 'common/types/services/api/commissions/commission-dto.types';
import { AdminCommissionModalValues } from './AdminCommissionModal/AdminCommissionModal.types';
import { openNotificationModal } from 'store/use-notification-modal-store';
import { NotificationType } from 'common/enums/enums';
import { AdminDefaultCommission } from './AdminDefaultCommission/AdminDefaultCommission';
import { AdminCommissionsFilters } from 'components/organisms/Filters/AdminCommissionsFilters';
import { useWindowSize } from 'hooks';

const maxItemPerPage = 5;

export const AdminCommissions: React.FC<any> = () => {
  const { isMobile } = useWindowSize();
  const [openModal, toggleModal] = useState(false);
  const [selectedId, toggleSelectedId] = useState('');
  const [query, setQuery] = useQueryParams(useCreateQueryParamConfig());
  const [initState, setInitState] = useState<AdminCommissionModalValues | null>(null);
  const { data, isLoading, isFetching } = useGetCommissions(
    {
      page: query.page,
      perPage: maxItemPerPage,
      filters: {
        state: query?.state,
        city: query?.city,
      },
    },
    {
      placeholderData: (previousValue) => previousValue,
    },
  );

  const total = React.useMemo<number>(() => data?.totalCount || 0, [data]);
  const list = React.useMemo<CommissionDto['items']>(() => data?.items || [], [data]);

  const changeCurrentPage = (newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  };

  const updateCurrentPage = () => {
    const newCurrentPage = getNewCurrentPage(total || 0, maxItemPerPage, query.page);
    if (newCurrentPage !== query.page) changeCurrentPage(newCurrentPage);
  };
  const { mutate: deleteCommission, isPending: isDeletingPending } = useDeleteCommissions({
    onSuccess: () => {
      updateCurrentPage();
    },
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'Something went wrong',
      });
    },
  });
  const { mutate: createCommission, isPending: isCreatingPending } = useCreateNewCommission({
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'Something went wrong',
      });
    },
  });
  const { mutate: editCommission, isPending: isEditingPending } = useEditCommission({
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'Something went wrong',
      });
    },
  });

  const openEditCommissionModal = React.useCallback(
    (commissionData: EditCommissionProps) => {
      setInitState((prevInitState) => ({
        ...prevInitState,
        amount: commissionData?.amount,
        commissionType: commissionData?.commissionType,
        location: {
          ...prevInitState?.location,
          state: commissionData?.location?.state,
          city: commissionData?.location?.city,
          address: commissionData?.location?.address,
        },
      }));
      toggleModal(true);
      toggleSelectedId(commissionData?.id);
    },
    [query.page, total, maxItemPerPage, query, initState],
  );

  const onDeleteEstate = React.useCallback(
    (commissionId: string) => {
      deleteCommission(commissionId);
    },
    [query.page, total, maxItemPerPage, query],
  );

  const tableData = useMemo(() => createAdminTableData(list, openEditCommissionModal, onDeleteEstate), [list]);

  const tablePaginationProps = useCreateTablePaginationProps(query.page, total, maxItemPerPage, changeCurrentPage);

  const handleSubmitCreation = (values: AdminCommissionModalValues) => {
    createCommission({
      state: values?.location?.state,
      city: values?.location?.city,
      percent: values?.commissionType === 'percent' ? Number(values?.amount) / 100 : null,
      fixedRate: values?.commissionType === 'fixedRate' ? Number(values?.amount) : null,
    });
  };

  const handleSubmitUpdate = (values: AdminCommissionModalValues) => {
    editCommission({
      body: {
        state: values?.location?.state,
        city: values?.location?.city,
        percent: values?.commissionType === 'percent' ? Number(values?.amount) / 100 : null,
        fixedRate: values?.commissionType === 'fixedRate' ? Number(values?.amount) : null,
      },
      id: selectedId,
    });
  };

  const havdleCreateModal = () => {
    setInitState(null);
    toggleModal(true);
  };

  const modalProps = useMemo(() => {
    return {
      onClose: () => toggleModal(false),
      open: openModal,
      title: !initState ? CREATE_COMMISSION_TITLE : EDIT_COMMISSION_TITLE,
      onSubmit: !initState ? handleSubmitCreation : handleSubmitUpdate,
      initStateProps: initState,
    };
  }, [openModal, initState]);

  return (
    <div className='grow overflow-x-auto shadow-white-xl px-4 py-4 md:px-[34px] md:py-[28px]'>
      <div className='profile-info-header flex gap-1 justify-between items-center mb-2 md:mb-9'>
        <div className='title text-xl font-bold'>{PAGE_TITLE}</div>
        <div className='flex justify-end gap-2'>
          <AdminCommissionsFilters />
          <Button
            label={isMobile ? 'Add' : ADD_NEW_BTN}
            onClick={havdleCreateModal}
            theme={'primary-outline'}
            icon={'add-new-item'}
          />
        </div>
      </div>
      <AdminDefaultCommission />
      <AdminEstateTable
        paginationProps={tablePaginationProps}
        isLoading={isLoading || isFetching || isDeletingPending || isCreatingPending || isEditingPending}
        data={tableData}
      />
      <AdminCommissionModal {...modalProps} />
    </div>
  );
};
