export enum ToursApiPath {
  GET_AVAILABLE_TOURS = 'irent/Tours/available',
  GET_SCHEDULED_TOURS = 'irent/Tours/scheduled',
  GET_ADMIN_TOURS = 'irent/admin/tour',
  GET_UPCOMMING_TOURS = 'irent/Tours/upcomming',
  GET_TOUR_DETAILS = 'irent/Tours',
  CANCEL_TOUR = 'irent/Tours',
  GET_TOURS_HISTORY = 'irent/Tours/history',
  SCHEDULE_TOUR = 'irent/Tours',
  POST_TOUR_PAYMENT = 'billings/payments/tour',
  POST_RESCHEDULE_TOUR_PAYMENT = 'billings/payments/reschedule',
  GET_ESTATE_PAYMENT = 'billings/Payments/estate',
  MAIN_PATH = 'irent/Tours',
}

export const buildRenterDocumentTourPath = (tourId: string) => `${ToursApiPath.MAIN_PATH}/${tourId}/document`;
