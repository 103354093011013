import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { NotificationsDto } from 'common/types/services/api/notification/notifications-dto.type';
import { getNotifications } from 'services/api/notification-api.service';
import { PaginationDtoRequest } from 'common/types/services/api/common/pagination-dto.type';
import { QueryKey } from 'common/enums/query-key';

export default function useNotifications(
  params: PaginationDtoRequest,
  options?: Omit<
    UndefinedInitialDataOptions<NotificationsDto, Error, NotificationsDto, (string | PaginationDtoRequest)[]>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    ...options,
    queryKey: [QueryKey.Notifications, params],
    queryFn: () => getNotifications(params),
  });
}
