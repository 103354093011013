import { CSSProperties } from 'react';

export const basestyle: CSSProperties = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 35,
    borderWidth: 2,
    borderColor: '#C4C4C4',
    borderStyle: 'dashed',
    backgroundColor: '#ffffff',
    color: '#222222',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    borderRadius: 6,
  };