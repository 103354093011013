import { useEffect, useState } from 'react';
import { EstateCard } from 'components/organisms/EstateCard';
import { EstateCardProps } from 'components/organisms/EstateCard/types';
import { EstateCardSkeletonVertical } from 'components/organisms/EstateCard/EstateCardSkeletonVertical';
import { getLastEstate } from 'services/api/estates-api.service';

const AuthLayoutBackground = () => {
  const [cardData, setCardData] = useState<EstateCardProps | null>(null);

  const mapRange = (minValue: number, maxValue: number) => {
    return minValue === maxValue ? minValue : `${minValue}-${maxValue}`;
  };

  useEffect(() => {
    getLastEstate().then((resp) => {
      const estate = resp.items?.[0];
      setCardData({
        city: estate.city,
        location: estate.location,
        price: estate.price,
        priceMin: estate.priceMin,
        priceMax: estate.priceMax,
        category: estate.category,
        units: estate.units,
        beds: mapRange(estate.bedsMin, estate.bedsMax),
        bathrooms: mapRange(estate.bathroomsMin, estate.bathroomsMax),
        propertyArea: estate.propertyArea,
        type: estate.type,
        thumbnailUrl: estate.thumbnailUrl,
      });
    });
  }, []);

  return (
    <div className='flex absolute w-[358px] h-[520px] mx-auto left-0 right-0 my-auto top-0 bottom-0'>
      <div className='relative'>
        <div className='absolute w-[40px] h-[256px] bg-primaryDark -left-[49px] top-[91px] skew-y-[30deg]' />
        <div className='absolute w-[40px] h-screen bg-primary -left-[29px] bottom-[235px] skew-y-[30deg]' />
        <div className='absolute w-[40px] h-[186px] bg-primaryDark left-[370px] top-[305px] skew-y-[30deg]' />
        <div className='absolute w-[57px] h-screen bg-primary  left-[333px] top-[266px] skew-y-[30deg]' />
      </div>
      <div className='relative w-full mt-14'>
        {cardData ? <EstateCard {...cardData} style={{ maxWidth: '100%' }} /> : <EstateCardSkeletonVertical />}
      </div>
    </div>
  );
};

export { AuthLayoutBackground };
