import { AccessAccountsApiPath } from 'common/enums/enums';
import {
  AccessAccountLockDto,
  ConnectedAccessAccountsDto,
  CreateAccessAccountDto,
  PaginationDtoRequest,
} from 'common/types/types';
import { axiosInstance } from './axiosInstance';
import qs from 'qs';

export function getConnectedAccessAccounts(query?: PaginationDtoRequest): Promise<ConnectedAccessAccountsDto> {
  return axiosInstance.get(`${AccessAccountsApiPath.GET_ACCESS_ACCOUNTS}?${qs.stringify(query)}`);
}

export function getAccessAccountLocks(accountId: string): Promise<AccessAccountLockDto[]> {
  return axiosInstance.get(`${AccessAccountsApiPath.GET_ACCESS_ACCOUNT_LOCKS}/${accountId}/locks`);
}

export function createAccessAccount(): Promise<CreateAccessAccountDto> {
  return axiosInstance.post(`${AccessAccountsApiPath.CREATE_ACCESS_ACCOUNT}`);
}
