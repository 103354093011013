import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { colors } from 'colors';

export type GoBackLinkProps = {
  title: string;
  onClick?: () => void;
  iconColor?: string;
  titleStyles?: CSSProperties;
};

const GoBackLink = ({ title, onClick, iconColor, titleStyles }: GoBackLinkProps) => {
  const navigate = useNavigate();

  const handleGoBackClick = () => {
    onClick ? onClick() : navigate(-1);
  };

  return (
    <div className='flex items-center cursor-pointer' onClick={handleGoBackClick}>
      <ArrowLeftIcon width={8} height={12} fill={iconColor ?? colors.primaryDark} />
      <span className='text-base text-primaryDark ml-[17px]' style={titleStyles}>
        {title}
      </span>
    </div>
  );
};

export { GoBackLink };
