import { TourDto } from 'common/types/types';
import { IEstateCard } from 'legacy-components/card/card';
import { fromEstateCategoryDtoToEstateCategory, fromRentEstateStatusDtoToEstateStatus } from './estates';
import { getPropertyImage } from 'helpers/image';

export const fromRentTourDtoToEstateCard = (
  tour: TourDto,
  onClick: (data: TourDto) => void,
  isAuth?: boolean,
): IEstateCard => {
  return {
    isAuth,
    id: tour.id,
    title: tour.city,
    address: tour.location,
    category: fromEstateCategoryDtoToEstateCategory(tour.category),
    beds: tour.beds,
    bathrooms: tour.bathrooms,
    propertyArea: tour.propertyArea,
    price: tour.price,
    images: [getPropertyImage(tour.thumbnailUrl)],
    theme: 'horizontal',
    status: fromRentEstateStatusDtoToEstateStatus[tour.estateStatus],
    scheduleAt: tour.scheduleAt,
    onClick: () => onClick(tour),
  };
};

