import { ReactComponent as ReactLogo } from 'assets/icons/logo.svg';
import { defaultAppRoutes } from 'common/utils/defaultAppRoutes';
import { useAuth } from 'hooks';
import { Link } from 'react-router-dom';

export type LogoProps = {
  className?: string;
};

const Logo = ({ className }: LogoProps) => {
  const { user } = useAuth();
  const route = defaultAppRoutes(user?.roles || []);
  return (
    <div className='flex flex-shrink-0 items-center'>
      <Link to={route} aria-label='Axxelist logo'>
        <ReactLogo className={`block md:w-[194px] md:h-[39px] w-[154px] h-[31px] ${className || ''}`} />
      </Link>
    </div>
  );
};

export { Logo };
