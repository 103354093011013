import React, { useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { Icon, Loader } from 'legacy-components/componets';
import { UploadFilesProps } from 'common/types/types';
import { styles as s } from './styles';
import { formatFiles } from './helpers';
import { Notification } from 'services/notification.service';

const UploadFiles = ({
  title,
  onDrop,
  accept,
  maxFiles,
  invalid,
  stylesConfig,
  multiple = true,
  children,
  styles,
  name,
}: UploadFilesProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDropFiles: DropzoneOptions['onDrop'] = async (acceptedFiles, fileRejections, event) => {
    setIsLoading(true);
    const formattedFiles = await formatFiles(acceptedFiles);
    onDrop?.(formattedFiles, fileRejections, event);
    setTimeout(() => {
      setIsLoading(false);
    }, 150);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept,
    maxFiles,
    onDrop: handleDropFiles,
    onDropRejected: (fileRejections) => {
      const notification = new Notification();
      if (fileRejections.length === 1) {
        notification.error(`File ${fileRejections[0].file.name} has unaloved format`);
      } else {
        const fileNames = fileRejections.map((el) => el.file.name).join(', ')
        notification.error(
          `Files ${fileNames} have unaloved format`,
        );
      }
    },
    multiple,
  });

  const internalStyles = React.useMemo(
    () => ({
      ...s.basestyle,
      ...(isFocused ? s.focusedStyle : undefined),
      ...(isDragAccept ? s.acceptStyle : undefined),
      ...(isDragReject || invalid ? s.rejectStyle : undefined),
      ...stylesConfig,
    }),
    [isFocused, isDragAccept, isDragReject, invalid, stylesConfig],
  );

  return (
    <section className='cursor-pointer relative'>
      {isLoading && <Loader className='h-full absolute z-10 opacity-75 bg-white' />}
      <div {...getRootProps({ className: 'dropzone', style: styles ?? internalStyles })}>
        <input {...getInputProps()} name={name} />
        {children ?? (
          <div className='flex flex-col justify-center items-center gap-2.5'>
            <Icon name={'download'} />
            <div className='flex flex-col items-center text-xs md:flex-row md:text-sm font-medium rounded-md whitespace-nowrap'>
              <p>Drag and drop or</p>
              <span className='hidden md:block'>&nbsp;</span>
              <p className='text-primary'>Choose file</p>
              <span className='hidden md:block'>&nbsp;</span>
              <p>to upload</p>
            </div>
            {title && <div className='italic text-trueGray text-center text-sm'>{title}</div>}
          </div>
        )}
      </div>
    </section>
  );
};

export { UploadFiles };
