import { CellProps } from 'react-table';
import { TourPropertyCellProps, ToursTableRow } from '../common/types/tours-table-row';
import { joinValues } from 'helpers/helpers';
import { formatPriceValue } from 'helpers/string.helpers';
import { PropertyImage } from 'components/atoms/PropertyImage/PropertyImage';

const TourPropertyCell = ({ value }: CellProps<ToursTableRow, TourPropertyCellProps>) => {
  const { address, price, image, isMulti, unitNumber } = value;

  return (
    <div className={'flex gap-3'}>
      <div className='w-[64px] h-[48px]'>
        <PropertyImage image={image} />
      </div>
      <div className={'text-sm'}>
        <div>{address}</div>
        {isMulti && <div>{`(Unit ${unitNumber})`}</div>}
        <div className='text-trueGray'>${joinValues([formatPriceValue(price), 'mo'], ' / ')}</div>
      </div>
    </div>
  );
};

export { TourPropertyCell };
