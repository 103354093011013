import { FC } from 'react';
import { EstateCardStatusTagProps } from './types';
import { Tag } from 'antd';
import { EstateStatusDto } from 'common/enums/enums';

const config = {
  [EstateStatusDto.Available]: {
    color: '#66CCFF',
    text: 'Available',
  },
  [EstateStatusDto.Incomplete]: {
    color: '#FF385C',
    text: 'Incomplete',
  },
  [EstateStatusDto.Occupied]: {
    color: '#A0D911',
    text: 'Rented',
  },
  [EstateStatusDto.PendingApproval]: {
    color: '#F3C521',
    text: 'Pending Approval',
  },
  [EstateStatusDto.Unavailable]: {
    color: '#222222',
    text: 'Unavailable',
  },
  [EstateStatusDto.Rejected]: {
    color: '#F5222D',
    text: 'Rejected',
  },
  [EstateStatusDto.PendingPayment]: {
    color: '#F3C521',
    text: 'Pending Payment',
  },
};

const EstateCardStatusTag: FC<EstateCardStatusTagProps> = ({ status }) => {
  if (!status || !config[status]) {
    return null;
  }

  return <Tag color={config[status].color}>{config[status].text}</Tag>;
};

export { EstateCardStatusTag };
