import { FC, ReactNode, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useLocalPasswordStore from 'store/use-local-password-store';

type Props = {
  children: ReactNode;
};

const LocalPasswordGuard: FC<Props> = ({ children }) => {
  const isAuthorized = useLocalPasswordStore((state) => state.isAuthorized);
  const { state, pathname } = useLocation();

  const navigateToEstate = useMemo(() => {
    if (!isAuthorized && !localStorage.getItem('isLocalAuthorized')) {
      return <Navigate to='/password-auth' state={{ ...state, from: { pathname } }} replace />;
    } else {
      return children;
    }
  }, [children, pathname, isAuthorized, localStorage]);

  return <>{navigateToEstate}</>;
};

export { LocalPasswordGuard };
