import { Modal } from 'components/organisms/Modal';
import { FormikHelpers, useFormik } from 'formik';
import { Button, Label, Textarea } from 'legacy-components/componets';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';
import { FC } from 'react';
import { object, string } from 'yup';

type FormValues = {
  rejectionReason: string;
};

type Props = {
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => Promise<void>;
};

const initialFormValues = {
  rejectionReason: '',
};

const AdminProfileListingRejectModal: FC<Props> = ({ isOpen, isSubmitting, onClose, onSubmit }) => {
  const { values, errors, touched, setFieldValue, resetForm, submitForm } = useFormik<FormValues>({
    initialValues: initialFormValues,
    validationSchema: object({
      rejectionReason: string()
        .required('Rejection reason is required')
        .min(15, 'Rejection reason must be at least 15 characters')
        .max(250, 'Rejection reason cannot exceed 250 characters'),
    }),
    onSubmit,
  });

  const handleCloseModal = () => {
    onClose();
    resetForm();
  };

  return (
    <Modal
      title={<div className='text-xl'>Are you sure you want to reject this estate?</div>}
      isOpen={isOpen}
      onClose={handleCloseModal}
      contentClassName={'w-[500px]'}
      footer={
        <div className='flex gap-3 justify-end'>
          <Button label={'Cancel'} theme={'secondary'} onClick={handleCloseModal} className={'w-full sm:w-auto'} />
          <Button
            label={'Reject'}
            theme={'red'}
            onClick={submitForm}
            isLoading={isSubmitting}
            className={'w-full sm:w-auto'}
          />
        </div>
      }
    >
      <Label label={'Add a rejection reason note to the renter'} className={'col-span-2'}>
        <ErrorValidationMessage touched={touched.rejectionReason} message={errors.rejectionReason}>
          <Textarea
            name={'comment'}
            value={values.rejectionReason}
            onChange={(e) => setFieldValue('rejectionReason', e.target.value)}
            invalid={Boolean(touched.rejectionReason && errors.rejectionReason)}
          />
        </ErrorValidationMessage>
      </Label>
    </Modal>
  );
};

export { AdminProfileListingRejectModal };
