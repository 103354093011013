import { NotificationButton } from 'legacy-pages/notification/NotificationButton';
import { useUserProfile } from 'hooks/query';
import { ProfileInfo } from '../profile-info/profile-info';
import { useAuth } from 'hooks';
import { AuthRole } from 'common/enums/enums';

const MAX_USER_NAME_LENTH = 100;

const ProfileSection = () => {
  const { data: userProfile } = useUserProfile();
  const { user } = useAuth();
  const isAdmin = user?.roles.includes(AuthRole.SuperUser);
  const userName = `${user?.firstName} ${user?.lastName}`;
  const shortenedName = (userName: string) =>
    userName.length > MAX_USER_NAME_LENTH ? `${userName.slice(0, MAX_USER_NAME_LENTH)}...` : userName;

  return (
    <div className='flex-center'>
      {!isAdmin && <NotificationButton />}
      <ProfileInfo
        profile={{
          thumbnailUrl: userProfile?.thumbnailUrl || '',
          name: shortenedName(userName),
        }}
        roles={user?.roles || []}
      />
    </div>
  );
};

export default ProfileSection;
