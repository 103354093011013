import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { UserDto } from 'common/types/services/api/user/user-dto.type';
import { getCurrentUser } from 'services/api/auth-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useCurrentUser(
  options?: Omit<UndefinedInitialDataOptions<UserDto, Error, UserDto, string[]>, 'queryFn' | 'queryKey'>,
) {
  return useQuery({
    queryKey: [QueryKey.User],
    queryFn: () => getCurrentUser(),
    ...options,
  });
}
