import { ButtonThemes } from 'common/types/types';

const disabledStyles = 'disabled:opacity-50 disabled:cursor-not-allowed';

const commonStyles = `h-10 flex-center text-sm font-bold px-5 py-2.5 rounded-lg focus:outline-none ${disabledStyles}`;

const styles: Record<ButtonThemes, string> = {
  default: `${commonStyles} bg-primaryDark text-white`,
  primary: `${commonStyles} bg-primary text-white`,
  secondary: `${commonStyles} bg-secondary text-primary`,
  'primary-outline': `${commonStyles} bg-white text-primary border border-primary`,
  black: `${commonStyles} bg-black text-white`,
  'black-outline': `${commonStyles} bg-white text-black border border-black`,
  gray: `${commonStyles} bg-gray text-warmGray`,
  red: `${commonStyles} bg-[#F06565] text-white`,
  'red-outline': `${commonStyles} bg-white text-[#F06565] border border-[#F06565]`,
  'green-outline': `${commonStyles} bg-white text-[#27AE60] border border-[#27AE60]`,
  green: `${commonStyles} bg-[#52C41A] text-white`,
  text: `${commonStyles} text-primary`,
  'red-text': `${commonStyles} text-red-500 hover:bg-red-100 transition-colors border border-gray`,
  'gray-outline': `${commonStyles} bg-white text-trueGray border border-trueGray`,
  'black-text': `${commonStyles} text-black`,
  'primary-text': `${commonStyles} text-primary`,
  'green-text': `${commonStyles} text-[#52C41A]`,
  'yellow-text': `${commonStyles} text-[#F3C521]`,
  'sky-text': `${commonStyles} text-sky-300 hover:text-sky-400 disabled:text-sky-300 disabled:hover:text-sky-300`
};

export default styles;
