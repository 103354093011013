import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { createPropertyAccessFile, updatePropertyAccess } from 'services/api/property-api.service';
import { QueryKey } from 'common/enums/query-key';
import { PropertyUpdateAccessRequestDto } from 'common/types/services/api/property/property-update-access-request-dto.type';

export default function useUpdatePropertyAccess(
  options?: Omit<UseMutationOptions<void, Error, PropertyUpdateAccessRequestDto, unknown>, 'mutationFn'>,
) {
  return useMutation({
    mutationFn: async (data: PropertyUpdateAccessRequestDto) => {
      await updatePropertyAccess(data);
      await Promise.all(
        data.accessFilesData.map((fileData) => {
          // check if file is type of File to create a new
          if (fileData?.file instanceof File) createPropertyAccessFile(fileData);
        }),
      );
    },
    mutationKey: [QueryKey.PropertyAccess],
    ...options,
  });
}
