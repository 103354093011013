import {
  Calendar as BigCalendar,
  CalendarProps,
  dayjsLocalizer,
  NavigateAction,
  SlotInfo,
  View,
} from 'react-big-calendar';
import dayjs from 'dayjs';

import { FC, useCallback, useMemo } from 'react';
import { Icon } from 'legacy-components/componets';


const localizer = dayjsLocalizer(dayjs);

type Props = {
  events?: CalendarProps['events'];
  selectedDay?: Date | null;
  handleSelectSlot?: (slotInfo: SlotInfo) => void;
  date: Date;
  handleNavigate?: (newDate: Date, view: View, action: NavigateAction) => void;
};

const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
};

const commonArrowStyle = {
  width: 20,
  height: 20,
};

const CustomToolbar = ({ ...props }) => {
  return (
    <div className='mb-9'>
      <div className='text-neutral-500 text-xl font-bold uppercase leading-normal'>{props.label}</div>

      <div className='flex absolute top-1 right-10 gap-4'>
        <div className='p-1 cursor-pointer' onClick={() => props.onNavigate(navigate.PREVIOUS)}>
          <Icon
            onClick={() => {}}
            name={'arrow-left'}
            color={'#6C6C6C'} // TODO: Add color
            {...commonArrowStyle}
          />
        </div>
        <div className='p-1 cursor-pointer' onClick={() => props.onNavigate(navigate.NEXT)}>
          <Icon onClick={() => {}} name={'arrow-right'} color={'#6C6C6C'} {...commonArrowStyle} />
        </div>
      </div>
    </div>
  );
};

const Calendar: FC<Props> = ({ events, handleSelectSlot, selectedDay, date, handleNavigate }) => {
  const components = useMemo(() => ({ toolbar: CustomToolbar }), []);

  const slotPropGetter = useCallback(
    (slotDate: Date) => {
      return {
        style: {
          ...(slotDate.toDateString() === selectedDay?.toDateString() && {
            border: '2px solid #6CF',
            background: '#F1FBFF',
            color: 'black',
          }),
        },
      };
    },
    [selectedDay],
  );

  return (
    <div className='tours-schedule-calendar'>
      <BigCalendar
        components={{ ...components }}
        date={date}
        localizer={localizer}
        selectable
        events={events}
        popup
        views={['month']}
        longPressThreshold={0}
        style={{ width: '100%', height: 540, maxWidth: 742 }}
        dayPropGetter={slotPropGetter}
        onSelectSlot={handleSelectSlot}
        onNavigate={handleNavigate}
        eventPropGetter={() => {
          return {
            style: {
              background: '#F1FBFF',
              color: '#0996CE',
              fontSize: 12,
              cursor: 'default',
              pointerEvents: 'none',
            },
          };
        }}
      />
    </div>
  );
};

export default Calendar;
