import React, { createContext, FC, useLayoutEffect, useState } from 'react';
import { Loader } from 'legacy-components/componets';
import { useCurrentUser } from 'hooks/query';
import { UserDto } from '../common/types/services/api/user/user-dto.type';

type State = {
  user?: UserDto;
  isAuthenticated: boolean;
};

const initialState: State = {
  isAuthenticated: false,
  user: undefined,
};

const AuthContext = createContext<State>({ ...initialState });

type Props = {
  children: React.ReactNode;
};

export const AuthProvider: FC<Props> = ({ children }) => {
  const { isLoading, isSuccess, data: user } = useCurrentUser();
  const isAuthenticated = isSuccess;
  const [userData, setUserData] = useState<UserDto | undefined>(undefined);

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      setUserData(undefined);
      return;
    } else {
      setUserData(user);
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <Loader />;
  }

  return <AuthContext.Provider value={{ isAuthenticated, user: userData }}>{children}</AuthContext.Provider>;
};

export { AuthContext };
