import { CellProps } from 'react-table';
import { dateFormatMMMM_DD, dateTimeFormatHHMM_A } from 'helpers/date';
import dayjs from 'dayjs';
import { AdminEstateTableRow, EstateDateCellProps } from '../AdminProfileListingTable.types';

export const AdminDateCell = ({ value }: CellProps<AdminEstateTableRow, EstateDateCellProps>) => {
    const utcDate = dayjs(value).utc();

    return (
        <div className='flex flex-col text-base text-trueGray gap-0.5 pl-2.5'>
            <span>{`${dateFormatMMMM_DD(utcDate)},`}</span>
            <span>{dateTimeFormatHHMM_A(utcDate)}</span>
        </div>
    );
};
