import { getEstateUnitPrice } from 'services/api/estates-api.service';
import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { EstatePrice } from 'common/types/services/api/estates/estate-dto.type';
import { QueryKey } from 'common/enums/query-key';

export default function useEstateUnitPrice(
  { estateId, unitId }: { estateId: string; unitId: string },
  options?: Omit<
    UndefinedInitialDataOptions<EstatePrice | undefined, Error, EstatePrice | undefined, (string | undefined)[]>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    ...options,
    queryKey: [QueryKey.Estates, estateId, 'units', unitId, 'price'],
    queryFn: () => getEstateUnitPrice({ estateId, unitId }),
  });
}
