import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QueryKey } from 'common/enums/query-key';
import { PersonaVerificationRequest } from 'common/generated/types/persona-verification-request';
import { UserPersonaVerificationDto } from 'common/generated/types/user-persona-verification-dto';
import { BackendError } from 'common/types/errors/backendError';
import { requestUserProfilePersonaVerification, updateUserProfilePersonaVerification } from 'services/api/auth-api.service';
import { Notification } from 'services/notification.service';

export function useRequestUserPersonaVerification(options?: UseMutationOptions<UserPersonaVerificationDto, AxiosError<BackendError>, void, unknown>) {
  return useMutation({
    ...options,
    mutationFn: requestUserProfilePersonaVerification,
  });
}

export function useUpdateUserPersonaVerification(options?: UseMutationOptions<string, AxiosError<BackendError>, PersonaVerificationRequest, unknown>) {
    const queryClient = useQueryClient();
    return useMutation({
      ...options,
      mutationFn: updateUserProfilePersonaVerification,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.User, 'profileValidation'] });
      },
      onError: (e) => {
        const notification = new Notification();
        notification.error(e?.response?.data?.detail ?? 'Something went wrong');
      },
    });
  }
