import { useState } from 'react';
import { Modal } from '../ui/modal/modal';
import { Button, Icon } from 'legacy-components/componets';
import { TourDetailsDto } from 'common/types/services/api/tours/tour-details-dto.type';
import { LetUsKnowWhyModal } from './let-us-know-why-modal';
import { useNavigate } from 'react-router-dom';
import { getDayjs } from 'helpers/date';
import { useWindowSize } from 'hooks';
import MakeOfferModalWithConfirmationOnClose from '../modals/make-offer-modal/make-offer-modal-with-confirmation-on-close';
import { formatPriceValue } from 'helpers/string.helpers';
import { PropertyImage } from 'components/atoms/PropertyImage/PropertyImage';

type RentModalProps = {
  open: boolean;
  onClose: () => void;
  tour: TourDetailsDto;
  availableOn?: string;
};

export const RentModal = ({ open: openProp, onClose, tour, availableOn }: RentModalProps) => {
  const { isMobile } = useWindowSize();
  const [open, setOpen] = useState(true);
  const [makeOfferModalOpen, setMakeOfferModalOpen] = useState(false);
  const [letUsKnowWhyModalOpen, setLetUsKnowWhyModalOpen] = useState(false);
  const navigate = useNavigate();

  const finishedTourAtDate = tour.finishedAt ? getDayjs(tour.finishedAt).utc(true) : getDayjs();
  const finishedTourAtStr = `${finishedTourAtDate.format('MMM DD')}, ${finishedTourAtDate.format(
    'YYYY',
  )} at ${finishedTourAtDate.format('hh:mma')}`;

  const handleLetUsKnowWhyModalClose = () => {
    setOpen(true);
    setLetUsKnowWhyModalOpen(false);
  };

  const handleMakeOfferModalClose = () => {
    setOpen(true);
    setMakeOfferModalOpen(false);
  };

  return (
    <>
      <MakeOfferModalWithConfirmationOnClose
        tourId={tour.id}
        availableOn={availableOn}
        withGoBackButton
        open={makeOfferModalOpen}
        unitId={tour.unitId}
        estateId={tour?.estateId}
        onGoBack={() => {
          handleMakeOfferModalClose();
        }}
        onClose={() => {
          handleMakeOfferModalClose();
          onClose();
        }}
        onSubmit={async () => {
          handleMakeOfferModalClose();
          onClose();
        }}
      />
      <LetUsKnowWhyModal
        tour={tour}
        open={letUsKnowWhyModalOpen}
        onGoBack={() => {
          handleLetUsKnowWhyModalClose();
        }}
        onClose={() => {
          handleLetUsKnowWhyModalClose();
          onClose();
        }}
        onSubmit={async () => {
          handleLetUsKnowWhyModalClose();
          onClose();
          navigate('/i-rent/profile/tours/upcoming');
        }}
      />
      <Modal
        isOpen={open && openProp}
        styles={{ overlay: { zIndex: 100 }, content: { maxHeight: '100%', ...(isMobile && { height: '100%' }) } }}
      >
        <div className='p-4 bg-white rounded-lg shadow max-w-screen-sm w-screen h-full flex flex-col gap-3 sm:gap-6 md:gap-9'>
          <h3 className='text-xl whitespace-nowrap text-center text-neutral-800 text-[40px] font-semibold leading-10'>
            Would you like to rent this property?
          </h3>
          <p className='text-center'>
            <span className='text-sky-600 text-[18px] font-normal leading-relaxed'>You completed a self-tour on </span>
            <span className='text-sky-600 text-[18px] font-bold leading-relaxed'>{finishedTourAtStr}</span>
            <span className='text-sky-600 text-[18px] font-normal leading-relaxed'> for this house.</span>
          </p>
          <div className='p-4 md:p-8 bg-sky-50 rounded-xl justify-start items-center gap-4 flex flex-col md:flex-row'>
            <PropertyImage
              image={tour.thumbnailUrl}
              className='rounded md:w-[82.49px] md:h-[61.87px] object-cover w-full h-[200px] border border-trueGray'
            />
            <div className='flex-col justify-start items-start gap-[5px] inline-flex'>
              <div className='text-neutral-800 text-[20px] font-bold leading-normal'>{tour.city}</div>
              <div className='grow shrink basis-0 text-neutral-800 text-[14px] font-normal'>{tour.location}</div>
            </div>
            <div className='grow shrink basis-0 h-[29px] justify-end items-center gap-[5px] flex'>
              <div className='w-[18px] h-[18px] relative' />
              <Icon name={'information'} width={18} height={18} />
              <div className='text-sky-600 text-[24px] font-bold leading-7'>${formatPriceValue(tour.price)}</div>
              <div className='text-neutral-500 text-[16px] font-normal'>/mo *</div>
            </div>
          </div>
          <p className='text-center'>
            <span className='text-neutral-800 text-[18px] font-normal leading-relaxed'>
              We hope your visit to the property left you with a big smile on your face.{' '}
            </span>
            <span className='text-neutral-800 text-[18px] font-bold leading-relaxed'>
              Are you interested in renting the property or did it not meet your needs?
            </span>
          </p>
          <div className='flex justify-between items-center flex-col gap-2.5 md:flex-row'>
            {/* <Button label={`Ask me Later`} theme={'text'} onClick={onClose} /> */}
            <div className={'flex space-x-2.5'}>
              <Button
                label={`Don't Rent it`}
                theme={'primary-outline'}
                onClick={() => {
                  setOpen(false);
                  setLetUsKnowWhyModalOpen(true);
                }}
              />
              <Button
                label={'Rent it'}
                onClick={() => {
                  setOpen(false);
                  setMakeOfferModalOpen(true);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
