import { ContactUsFormDtoStatusEnum } from "common/generated/types/contact-us-form-dto";

export type AdminFeedbackStatusProps = {
  status: ContactUsFormDtoStatusEnum;
  size?: 's' | 'm' | 'l';
};

export const adminFeedbackStatusMapToText: Record<ContactUsFormDtoStatusEnum, string> = {
  [ContactUsFormDtoStatusEnum.New]: 'New',
  [ContactUsFormDtoStatusEnum.InProgress]: 'In Progress',
  [ContactUsFormDtoStatusEnum.Done]: 'Done',
};