import { BannerResponse } from 'common/types/services/api/banners/banner-dto.type';
import { axiosInstance } from './axiosInstance';
import { BannerApiPath } from 'common/enums/services/api/paths/banner-api-path.enum';
import qs from 'qs';

export const getAllBanners = (): Promise<BannerResponse> => {
  const queryParams = {
    filters: {
      isActive: true,
    },
  };
  const queryString = qs.stringify(queryParams, { encode: false, arrayFormat: 'repeat' });
  return axiosInstance.get(`${BannerApiPath.GET_ALL_BANNERS}?${queryString}`);
};
