import { useGetBanners } from 'hooks/query';
import { FC } from 'react';
import { Banner } from 'components/atoms/Banner/Banner';

type BannerWrapperProps = {
  isActive: boolean;
};

export const BannerWrapper: FC<BannerWrapperProps> = ({ isActive }) => {
  const { data } = useGetBanners({
    enabled: isActive,
    placeholderData: (previousValue) => previousValue,
    staleTime: 30 * 60 * 1000, // 30 min
  });

  if (!isActive) {
    return null;
  }

  const bannerProps = data?.items?.[0];

  return <Banner {...bannerProps} />;
};
