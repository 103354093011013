import cn from 'clsx';
import { RadioButtonProps } from 'common/types/types';

import styles from './styles';

const RadioButton = ({
  name,
  value,
  checked,
  theme = 'default',
  label,
  placeholder,
  invalid,
  onChange,
  stylesConfig,
  disabled
}: RadioButtonProps) => {
  const inputClassName = cn(styles[theme].input, invalid && styles[theme].error);
  // This id is for attaching a label to the input. 
  // We make it unique so that there are no problems with an array of elements
  const uniqueId = `${name}-${value}`;

  return (
    <div className={styles[theme].container}>
      <input
        id={uniqueId}
        name={name}
        type='radio'
        placeholder={placeholder}
        className={inputClassName}
        onChange={onChange}
        value={value}
        checked={checked}
        autoComplete='off'
        style={stylesConfig?.inputStyle}
        disabled={disabled}
      />
      {label && (
        <label htmlFor={uniqueId} className={styles[theme].label} style={stylesConfig?.labelStyle}>
          {label}
        </label>
      )}
    </div>
  );
};

export { RadioButton };
