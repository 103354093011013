import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { ToursDto } from 'common/types/services/api/tours/tours-dto.type';
import { UpcomingToursRequestDtoType } from 'common/types/services/api/tours/upcoming-tours-request-dto.type';
import { getUpcomingTours } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useUpcomingTours<TData = ToursDto>(
  params: UpcomingToursRequestDtoType,
  options?: Omit<
    UndefinedInitialDataOptions<ToursDto, Error, TData, (string | UpcomingToursRequestDtoType)[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    ...options,
    queryKey: [QueryKey.Tours, 'upcoming', params],
    queryFn: () => getUpcomingTours(params),
  });
}
