export enum PropertyApiPath {
  CREATE_PROPERTY_ADDRESS = 'irent/Estates',
  //
  CREATE_PROPERTY_UNIT = 'irent/Estates',
  UPDATE_PROPERTY_UNIT = 'irent/Estates',
  DELETE_PROPERTY_UNIT = 'irent/Estates',
  //
  UPDATE_PROPERTY_PLAN = 'irent/Estates',
  UPDATE_PROPERTY_ACCESS = 'irent/Estates',
  UPDATE_PROPERTY_SCHEDULER = 'irent/Estates',
  CREATE_PROPERTY_ACCESS_FILE = 'irent/Estates',
  UPLOAD_PROPERTY_IMAGES = 'irent/images',
  DELETE_PROPERTY_IMAGE = 'irent/Images',
  GET_PROPERTY_AMENITIES = 'irent/Amenities',
  UPDATE_PROPERTY_AMENITIES = 'irent/Estates',
  CREATE_PROPERTY_DOCUMENT = 'irent/Documents',
  PUBLISH_PROPERTY = 'irent/Estates',
  GET_LISTING = 'irent/Estates/my',
  UPDATE_PROPERTY_RENTAL_SPECIALS = 'irent/Estates',
}
