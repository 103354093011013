import { CellProps } from 'react-table';
import { ApplicationsIncomeCell, ApplicationsTableRow } from '../common/types/types';
import { formatPriceValue } from 'helpers/string.helpers';

const OffersIncomeCell = ({ value }: CellProps<ApplicationsTableRow, ApplicationsIncomeCell>) => {
  const { income, price } = value;
  return (
    <div className='flex flex-col font-medium gap-0.5'>
      <span className='text-base'>{`$${formatPriceValue(price || 0)}/mo`}</span>
      <span className='text-sm text-trueGray'>{`${income || 0}X Income to Rent`}</span>
    </div>
  );
};

export { OffersIncomeCell };
