import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { isEmailExists } from 'services/api/auth-api.service';

export default function useIsUserEmailExists(
  options?: UseMutationOptions<any, Error, string, unknown>,
) {
  return useMutation({
    mutationFn: isEmailExists,
    ...options,
  });
}