import React, { ReactNode } from 'react';
import GoogleMapReact, { Coords } from 'google-map-react';
import { ENV } from 'common/enums/enums';
import { MapLocationPin } from 'legacy-components/componets';

export type GoogleMapLocation = {
  lat?: number;
  lng?: number;
};

/**
 * @param markers {lat: number;lng: number} required props for each marker element
 */
export type GoogleMapProps = {
  location: GoogleMapLocation;
  markers?: ReactNode;
  zoom?: number;
  width?: number | string;
  height?: number | string;
  styles?: {
    container?: React.CSSProperties;
  };
};

const GoogleMap = ({ location, markers, zoom = 15, width, height, styles }: GoogleMapProps) => {
  const isCoordinate = location.lng && location.lat;
  const centerCoords: Coords | undefined = isCoordinate
    ? ({ lat: location.lat, lng: location.lng } as Coords)
    : undefined;

  return (
    <div className='w-full h-full rounded-2xl overflow-hidden' style={{ width, height, ...styles?.container }}>
      <GoogleMapReact bootstrapURLKeys={{ key: ENV.REACT_APP_GOOGLE }} center={centerCoords} zoom={zoom}>
        {markers ? (
          markers
        ) : location.lng && location.lat ? (
          <MapLocationPin lat={location.lat} lng={location.lng} />
        ) : null}
      </GoogleMapReact>
    </div>
  );
};

export { GoogleMap };
