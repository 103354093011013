import React from 'react';
import { CellProps } from 'react-table';
import { ApplicationsTableRow, ApplicationsTenantCell } from '../common/types/types';
import { Avatar } from 'legacy-components/componets';
import { joinValues } from 'helpers/helpers';

const OffersTenantCell = ({ value }: CellProps<ApplicationsTableRow, ApplicationsTenantCell>) => {
  const { userName, img, country, region } = value;
  return (
    <div className='flex gap-4 justify-start max-w-[100%]'>
      {!img ? (
        <div className='w-[50px] h-[50px] rounded-full bg-slate-400 flex justify-center items-center flex-shrink-0'>
          <div className='text-white text-2xl'>{userName[0].toUpperCase()}</div>
        </div>
      ) : (
        <Avatar src={img} height={50} width={50} />
      )}

      <div className={'text-black text-base text-start truncate'}>{userName}</div>
    </div>
  );
};

export { OffersTenantCell };
