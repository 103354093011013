import React from 'react';
import { ENV } from 'common/enums/enums';
import { Input } from 'legacy-components/componets';

import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

export type GooglePlace = {
  description: string;
  place_id: string;
  types: string[];
};

export type GoogleAddressComponents = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type GooglePlaceDetails = {
  address_components: GoogleAddressComponents[];
  formatted_address: string;
  geometry: {
    location: {
      lng: Function;
      lat: Function;
    };
  };
};

export type SelectedLocation = {
  address: string;
  lng?: number;
  lat?: number;
};

export type PlaceAutocompleteProps = {
  value: string;
  fieldName: string;
  invalid?: boolean;
  disabled?: boolean;
  setValue: (location: SelectedLocation) => void;
  onSelectAddress: (address: {
    streetNumber: string;
    streetName: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  }) => void;
};

const RenterPersonalDetailsFormAddressField = ({
  value,
  fieldName,
  invalid,
  setValue,
  disabled,
  onSelectAddress,
}: PlaceAutocompleteProps) => {
  const [hideAddresses, setHideAddresses] = React.useState(true);

  const { placesService, placePredictions, getPlacePredictions } = useGoogle({
    apiKey: ENV.REACT_APP_GOOGLE,
    libraries: ['places'],
    debounce: 200,
    options: {
      componentRestrictions: { country: ['us', 'ca'] },
      input: '',
    },
    language: 'en'
  });

  const handleSelectAddress = (place: GooglePlace) => {
    placesService?.getDetails({ placeId: place.place_id, language: 'en' }, (placeDetails: any) => {
      let address = {
        streetNumber: '',
        streetName: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      };

      for (const component of placeDetails.address_components) {
        const type = component.types[0];
        switch (type) {
          case 'street_number':
            address.streetNumber = component.long_name;
            break;
          case 'premise':
            address.streetName = component.long_name;
            break;
          case 'route':
            address.streetName = component.long_name;
            break;
          case 'postal_town':
            address.city = component.long_name;
            break;
          case 'administrative_area_level_1':
            address.state = component.long_name;
            break;
          case 'administrative_area_level_2':
            address.city = component.long_name;
            break;
          case 'neighborhood':
            if ((address.city = '')) {
              address.city = component.long_name;
            }
            break;
          case 'country':
            address.country = component.long_name;
            break;
          case 'postal_code':
            address.zip = component.long_name;
            break;
          default:
            break;
        }
      }

      setHideAddresses(true);
      return onSelectAddress(address);
    });
  };

  const getRenderAddressItem = (address: any, index: number) => {
    return (
      <div
        key={index}
        className='flex items-center pl-2 py-2 hover:bg-gray'
        onClick={() => handleSelectAddress(address)}
      >
        {address.description}
      </div>
    );
  };

  return (
    <>
      <Input
        disabled={disabled}
        value={value}
        name={fieldName}
        placeholder='Address'
        invalid={invalid}
        onChange={(evt) => {
          setHideAddresses(false);
          getPlacePredictions({ input: evt.target.value, language: 'en' });
          setValue({ address: evt.target.value });
        }}
      />
      {!hideAddresses && placePredictions.length > 0 && (
        <div className='py-2 absolute z-10 rounded-lg bg-white shadow-blue cursor-pointer mt-2 w-full'>
          {placePredictions.map((item, index) => getRenderAddressItem(item, index))}
        </div>
      )}
    </>
  );
};

export { RenterPersonalDetailsFormAddressField };
