import React, { FC, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Icon } from '../../legacy-components/ui/icon/icon';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { twMerge } from 'tailwind-merge';

type Props = {
  title?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  isOpen?: boolean;
  closable?: boolean;
  withGoBackButton?: boolean;
  onGoBack?: () => void;
  contentClassName?: string;
  titleClassName?: string;
  isFullScreen?: boolean;
  onOpenChange?: (open: boolean) => void;
  onClose?: () => void;
};

export const Modal: FC<Props> = ({
  title,
  children,
  isOpen,
  closable = true,
  withGoBackButton,
  onGoBack,
  contentClassName,
  isFullScreen,
  footer,
  onOpenChange,
  onClose,
  titleClassName,
}) => {
  //TODO: DELETE THIS EFFECT & CREATE ANOTHER SOLUTION
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isOpen]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange} modal={false}>
      <Dialog.Portal>
        <Dialog.Overlay
          className={twMerge(
            'fixed inset-0 z-50 bg-black bg-opacity-40',
            'data-[state=open]:animate-in data-[state=closed]:animate-out',
            'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
          )}
        />
        <Dialog.Content
          className={twMerge(
            'flex flex-col max-h-[calc(100%-64px)] fixed left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 bg-white shadow-[0px_0px_0px_2000px_rgba(0,0,0,0.4)] duration-200 transition-[opacity,transform] max-w-[calc(100%-64px)] rounded-lg py-4 sm:py-[30px] px-3 sm:px-6',
            'data-[state=open]:animate-in data-[state=closed]:animate-out',
            'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
            'data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
            'data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-1/2 data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-1/2',
            isFullScreen && 'h-full max-h-full w-full max-w-full rounded-none',
            contentClassName,
          )}
        >
          {closable && (
            <Dialog.Close
              className={'absolute p-1 right-[12px] top-[20px] -translate-y-1/2 md:top-[24px]'}
              aria-label='Close'
            >
              <Icon onClick={onClose} name={'xmark'} className={'cursor-pointer text-primary'} width={18} height={18} />
            </Dialog.Close>
          )}
          <div className={twMerge('relative', (closable || title || withGoBackButton) && 'mb-3')}>
            <Dialog.Title
              className={twMerge(
                'text-center text-neutral-800 text-[24px] leading-[36px] md:text-[32px] font-semibold md:leading-[48px] px-8',
                titleClassName,
              )}
            >
              {title}
            </Dialog.Title>
            {withGoBackButton && (
              <button
                onClick={onGoBack}
                aria-label='Go back'
                className={'absolute left-0 top-[18px] md:top-[24px] -translate-y-1/2'}
              >
                <ArrowLeftOutlined className={'text-primary text-[24px] block'} />
              </button>
            )}
          </div>
          <div className={'flex-1 overflow-y-auto pr-2 scrollbar'}>{children}</div>
          <div className={twMerge(footer && 'mt-9')}>{footer}</div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
