import { IconProps, IconSVGName, TablePlaceholderProps } from 'common/types/types';

export const generateIconComponentsProps = (content: IconSVGName | IconProps): IconProps => {
  const isIconProps = typeof content !== 'string';
  return isIconProps ? (content as IconProps) : { name: content as IconSVGName };
};

export const generateTablePlaceholderProps = (content: string | TablePlaceholderProps): TablePlaceholderProps => {
  const isPlaceholderProps = typeof content !== 'string';
  return isPlaceholderProps ? (content as TablePlaceholderProps) : { title: content as string };
};
