import { useState, useEffect } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { loadStripe } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import {  ENV } from 'common/enums/enums';
import { PersonaEmbeddedInquiryVerification } from 'components/organisms/Persona/PersonaEmbeddedInquiry';
import { useClientMethod, useHub, useSignalR } from 'hooks';
import { useUserProfileValidation } from 'hooks/query';
import useGetPersonaVerificationPayment from 'hooks/query/persona/use-get-verification-payment';
import useGetUserPersonaVerification from 'hooks/query/user/use-persona-verification';
import { Button, FormDescription, Loader } from 'legacy-components/componets';
import { useRequestUserPersonaVerification } from 'hooks/query/auth/use-user-persona-verification';
import { Notification } from 'services/notification.service';
import { useRequestPersonaVerificationPayment, useUpdatePersonaVerificationPayment } from 'hooks/query/persona/use-request-verification-payment';
import { QueryKey } from 'common/enums/query-key';
import { personaVerificationFee } from 'common/constants/personaVerification';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from 'components/organisms/CheckoutForm/CheckoutForm';


const LoaderPanel = () => {
  return (
    <div className='px-4 w-full min-h-[420px]'>
      <Loader className='h-full z-10 opacity-75 bg-white my-8' />
    </div>
  );
}

const LandlordVerificationIdentityForm = () => {
  const [ canVerify, setCanVerify ] = useState(false);
  const [ isStripeLoading, setIsStripLoading ] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const stripePromise = loadStripe(ENV.REPLENISH_PUBLIC_KEY);
  const hubConnection = useSignalR();
  useHub(hubConnection);

  const { data: validationCtx, isLoading: isValidationQueryLoading } =  useUserProfileValidation();
  
  const { data: personaVerification, isLoading: isPersonaVerificationQueryLoading } = 
    useGetUserPersonaVerification({ 
      enabled: false === validationCtx?.isIdentityValid 
    });
  
  const { data: paymentContext, isLoading: isPersonaVerificationPaymentQueryLoading } = 
    useGetPersonaVerificationPayment({
      verificationId: personaVerification?.id
    },{
      enabled: personaVerification?.id != null 
    });
  
  const { mutate: requestPersonaVerification, isPending: isRequestPersonaVerificationPending } =
      useRequestUserPersonaVerification({
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [QueryKey.User, QueryKey.PersonaVerification] });
        },
        // onError: (e) => {
        //   const notification = new Notification();
        //   notification.error(e?.response?.data?.detail ?? 'Something went wrong');
        // }
  }); 

  const { mutate: requestVerificationPayment, isPending: isRequestPersonaVerificationPaymentPending } =  useRequestPersonaVerificationPayment({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.PersonaVerification, 'payment', { verificationId: personaVerification?.id }] });
    },
    // onError: (e) => {
    //   const notification = new Notification();
    //   notification.error(e?.response?.data?.detail ?? 'Something went wrong');
    // }
  });

  const { mutate: updateVerificationPayment, isPending: isUpdatePersonaVerificationPaymentPending } =  useUpdatePersonaVerificationPayment({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.PersonaVerification, 'payment', { verificationId: personaVerification?.id }] });
    },
    // onError: (e) => {
    //   const notification = new Notification();
    //   notification.error(e?.response?.data?.detail ?? 'Something went wrong');
    // }
  });
  
  useClientMethod(hubConnection, 'paymentIntentSucceeded', (message) => {
    if(message?.paymentKey === paymentContext?.paymentKey) {
        setCanVerify(true);
        queryClient.invalidateQueries({ queryKey: [QueryKey.PersonaVerification, 'payment', { verificationId: personaVerification?.id }] });
    }
  });

  // initialize new verification procedure 
  // or get old pending one 
  // useEffect(() => {
  //   if(promptConfirmed) {
  //     requestPersonaVerification();
  //   }       
  // }, [promptConfirmed, requestPersonaVerification]);

  // create verification payment if verification process requested
  // but no pending payment found
  useEffect(() => {
    if(null != personaVerification?.id && null == paymentContext?.id) {
      requestVerificationPayment({ verificationId: personaVerification?.id });
    }
  }, [personaVerification?.id]);


  useEffect(() => {
    if(paymentContext?.status === 'Succeeded') {
      setCanVerify(true);
    }
  }, [paymentContext?.status]);

  /** */
  const isAnyQueryFetching = () => isValidationQueryLoading 
                                || isPersonaVerificationQueryLoading
                                || isPersonaVerificationPaymentQueryLoading
                                || isRequestPersonaVerificationPending
                                || isRequestPersonaVerificationPaymentPending
                                || isUpdatePersonaVerificationPaymentPending;                       

   
  if (isAnyQueryFetching()) {
    return (<LoaderPanel />);
  }

  /** */
  if (validationCtx?.isIdentityValid) {
    return (
      <div className='md:px-9 px-4 md:py-6 py-4 shadow-white-xl w-full min-h-[420px]'>
        <div className='flex flex-col gap-3'>
          <div className='title text-xl font-medium'>
            Identity Verification <CheckCircleFilled className={'text-lime-300 text-[24px]'} />
          </div>
          <div className='description text-trueGray'>You have successfully verified your identity!</div>
        </div>
      </div>
    );
  }

  const handleRequestVerificationClick = () => {
    requestPersonaVerification();   
  }

  /** */
  const isPaymentFormReady = () => paymentContext?.paymentKey !== null
                                && paymentContext?.status === 'RequiresPaymentMethod';

  /** */
  const shouldShowPromptButton = () => false === validationCtx?.isIdentityValid
                                    && null == personaVerification?.id;

  const formSubtitleProvider = () => {  
    if(!validationCtx?.isIdentityValid && null == personaVerification?.id) {
      return 'Press the button below to request identity verification.\n' + 
        `You will be charged for a small fee of $${personaVerificationFee}.`;
    }

    if(paymentContext?.status === 'RequiresPaymentMethod') {
      return 'Put your card details in a form below to proceed with payment.'
    }

    return canVerify 
      ? 'Press start button to verify your identity, it shouldn\'t take more than 5 minutes.'
      : 'Please wait while we are processing the request';
  }

  const options = {
    clientSecret: paymentContext?.paymentKey,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  return (
    <div className='md:px-9 px-4 md:py-6 py-4 shadow-white-xl w-full min-h-[420px]'>  
      <FormDescription
        title={'Identity Verification'}
        subTitle={formSubtitleProvider()}
      />      

     {isPaymentFormReady()
        ? (<>
            <div className='p-6 flex justify-center flex-col items-center gap-9'>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm
                  isStripeLoading={isStripeLoading}
                  setIsStripLoading={setIsStripLoading}
                  clientSecret={paymentContext?.paymentKey}
                  callback={() => updateVerificationPayment(personaVerification?.id)}
                  paidSum={paymentContext?.amount}
                />
              </Elements>          
            </div>
          </>         
          )
        : 
          shouldShowPromptButton()
            ? (
              <div className={'flex flex-col gap-3 mb-9 mt-2'}>  
                <Button 
                  className='min-h-[55px]'
                  label={'IDENTITY VERIFICATION'} 
                  onClick={handleRequestVerificationClick}
                  disabled={false}
                  isLoading={false} 
                />
              </div>
               )
            : canVerify 
              ? (
                  <div className={'flex flex-col gap-3 mb-9 mt-2'}>
                    <PersonaEmbeddedInquiryVerification verificationId={personaVerification?.id} referenceId={personaVerification?.referenceId} />
                  </div>
                )
              : (<LoaderPanel />)
     }    
     
    </div>
  );
};

export { LandlordVerificationIdentityForm as LandlordVerificationDetailsForm };

// import React from 'react';
// import { useFormik } from 'formik';
// import { Button, ComingSoon, FormDescription, Input, Label, ProgressBar } from 'legacy-components/componets';
// import { InputProps } from 'common/types/types';
// import { profileVerificationFormDescription } from './config';
// import { progressValueByKeyObject } from 'helpers/progres';

// export type VerificationDetailsValues = {
//   address: string;
//   city: string;
//   state: string;
//   zip: string;
// };

// const LandlordVerificationDetailsForm = () => {
//   const { values, errors, touched, setFieldValue } = useFormik<VerificationDetailsValues>({
//     initialValues: { address: '', city: '', zip: '', state: '' },
//     onSubmit: () => {},
//     enableReinitialize: true,
//   });

//   const getCommonInputProps = (name: keyof VerificationDetailsValues, placeholder: string): InputProps => {
//     return {
//       name,
//       placeholder,
//       value: values[name],
//       onChange: ({ target: { value } }) => setFieldValue(name, value),
//       invalid: Boolean(errors[name] && touched[name]),
//     };
//   };

//   const handleSubmit = () => {};

//   return (
//     <div className='flex flex-1 flex-col gap-9'>
//       <div className='personal-details px-[34px] py-6 shadow-white-xl h-[500px]'>
//         <ComingSoon />
//       </div>
//     </div>
//   );

//   return (
//     <div className='flex flex-col gap-9'>
//       <div className='personal-details w-[581px] flex flex-col gap-9 px-[34px] py-6 shadow-white-xl'>
//         <FormDescription {...profileVerificationFormDescription} />
//         <div className='flex flex-col gap-[18px]'>
//           <Label label={'Home address'}>
//             <Input {...getCommonInputProps('address', 'e.g. adddress')} />
//           </Label>
//           <div className='flex gap-4'>
//             <div className='w-1/3'>
//               <Label label={'City'}>
//                 <Input {...getCommonInputProps('city', 'City')} />
//               </Label>
//             </div>
//             <div className='w-1/3'>
//               <Label label={'State'}>
//                 <Input {...getCommonInputProps('state', 'State')} />
//               </Label>
//             </div>
//             <div className='w-1/3'>
//               <Label label={'Zip'}>
//                 <Input {...getCommonInputProps('zip', 'Zip')} />
//               </Label>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className='button-wrapper flex justify-end'>
//         <Button label={'Submit'} theme={'primary'} onClick={handleSubmit} />
//       </div>
//       {/* <ProgressBar progressPercentage={progressValueByKeyObject(values)} /> */}
//     </div>
//   );
// };
