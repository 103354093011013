import { ThemeConfig } from "antd";

export const antdTheme: ThemeConfig = {
    token: {
      fontFamily: 'Manrope',
    },
    components: {
      Select: {
        colorPrimary: '#66CCFF',
        colorPrimaryHover: '#66CCFF',
        colorBorder: '#DEEFF7',
        borderRadius: 8,
        singleItemHeightLG: 48,
        optionHeight: 44,
        optionActiveBg: '#EFF7FB',
        optionLineHeight: 2,
        fontSizeIcon: 16,
        optionSelectedBg: '#EFF7FB',
        optionSelectedColor: '#66CCFF',
        optionSelectedFontWeight: 700,
        optionPadding: '8px 16px'

      },
      InputNumber: {
        colorPrimary: '#66CCFF',
        colorPrimaryHover: '#66CCFF',
        colorBorder: '#DEEFF7',
        borderRadius: 8,
        fontSizeIcon: 16,
        controlHeight: 44,
      },
      DatePicker: {
        colorPrimary: '#66CCFF',
        colorPrimaryHover: '#66CCFF',
        colorBorder: '#DEEFF7',
        borderRadius: 8,
        fontSizeIcon: 16,
        controlHeight: 44,
      },
    },
  }