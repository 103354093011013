import { FC } from 'react';

type Props = {
  isDefault: boolean;
  onClick?: () => void;
};

const EstateUploadedImageMainButton: FC<Props> = ({ isDefault, onClick }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onClick?.();
  };

  return (
    <button
      onClick={handleClick}
      className={`rounded-b-lg ${
        isDefault ? 'bg-primary' : 'bg-gray'
      } absolute bottom-0 w-full color-trueGray text-[10px] p-1 text-center`}
    >
      {isDefault ? '✓ Main photo' : '  Main photo'}
    </button>
  );
};

export { EstateUploadedImageMainButton };
