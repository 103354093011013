import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getOfferPayment } from 'services/api/offers-api.service';
import { OfferPaymentIntentDto } from 'common/types/services/api/offers/offer-payment-intent-dto.type';
import { QueryKey } from 'common/enums/query-key';

export default function useOfferPayment(
  offerId: string,
  options?: Omit<
    UndefinedInitialDataOptions<OfferPaymentIntentDto, Error, OfferPaymentIntentDto, string[]>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Offers, offerId, 'payment'],
    queryFn: () => getOfferPayment(offerId),
    ...options,
  });
}
