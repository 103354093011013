import { IconProps } from 'common/types/types';
import { iconNameToIconComponent } from 'common/mappers/mappers';
import { sanitizeObject } from 'common/utils/url';

const Icon = ({ name, width, height, color, onClick, clickable, className }: IconProps) => {
  const SelectedIcon = iconNameToIconComponent[name];
  const iconProps = sanitizeObject({ width, height, fill: color });

  if (!SelectedIcon) {
    return null;
  }

  return (
    <div
      className={`flex-center ${onClick || clickable ? 'cursor-pointer' : 'cursor-inherit'} ${className}`}
      style={{ color }}
      onClick={onClick}
    >
      <SelectedIcon {...iconProps} />
    </div>
  );
};

export { Icon };
