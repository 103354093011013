import { InfoCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';

export const InfoToolTip: React.FC<{ toolTipText: string }> = ({ toolTipText }) => {
  return (
    <Tooltip placement='topLeft' title={toolTipText} arrow={false}>
      <InfoCircleTwoTone twoToneColor='#FF385C' />
    </Tooltip>
  );
};
