import React from 'react';
import { SavedSearchTableAccessor, SavedSearchTableRow, SavedSearchToursTableProps } from './SavedSearchTable.types';
import { TableColumn } from 'common/types/components/ui/table/table-props.type';
import { SavedSearchDateCell } from './saved-search-table-cells/SavedSearchDateCell';
import { SavedSearchPropertyCell } from './saved-search-table-cells/SavedSearchPropertyCell';
import { SavedSearchActionsMenuCell } from './saved-search-table-cells/SavedSearchActionsCell';
import { styleConfig } from './SavedSearch.styles';
import { Table } from 'legacy-components/componets';

export const SavedSearchTable: React.FC<SavedSearchToursTableProps> = ({
  data,
  isLoading,
  paginationProps,
  order,
  setQuery,
}) => {
  const columns: TableColumn<SavedSearchTableRow>[] = [
    {
      Header: 'Date',
      sorter: true,
      sortOrder: order,
      onSort: (sortOrder) => setQuery((prev) => ({ ...prev, page: 1, order: sortOrder })),
      accessor: SavedSearchTableAccessor.Date,
      Cell: SavedSearchDateCell,
      width: '20%',
      minWidth: 150,
    },
    {
      Header: 'Property',
      accessor: SavedSearchTableAccessor.Property,
      Cell: SavedSearchPropertyCell,
      width: '70%',
      minWidth: 300,
    },
    {
      Header: 'Actions',
      accessor: SavedSearchTableAccessor.Actions,
      Cell: SavedSearchActionsMenuCell,
      width: '10%',
      minWidth: 120,
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      isLoading={isLoading}
      styleConfig={styleConfig}
      paginationProps={paginationProps}
      placeholder={{
        title: `Currently, you don't have any saved search`,
        height: 400,
      }}
    />
  );
};
