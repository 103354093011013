import { Steps, message } from 'antd';
import { Modal } from 'components/organisms/Modal';
import useIsUserEmailExists from 'hooks/query/user/use-is-user-email-exists';
import useSendUserVerificationCode from 'hooks/query/user/use-send-user-verification-code';
import useUpdateUserEmail from 'hooks/query/user/use-update-user-email';
import { Button, Input, Label, RadioButton } from 'legacy-components/componets';
import { useState } from 'react';
import { notification } from 'services/services';

const enum VerifyOption {
    EMAIL, SMS
}

const EmailVerificationStep: any = ({
    userInfo,
    verifyEmailOption,
    setVerifyEmailOption,
    verificationCode, 
    setVerificationCode,
    verificationError
}) => {
    const { mutateAsync: sendUserVerificationCode } = useSendUserVerificationCode({
        onSuccess: () => {
            message.success(`We've sent you the code. Please check your ${verifyEmailOption == VerifyOption.EMAIL ? "email" : "phone"}`);
        }
    });
      
    const [isGetUserCodeDisabled, setIsGetUserCodeDisabled] = useState(false);
    
    const handleGetEmailVerificationClick = () => {
        setIsGetUserCodeDisabled(true);
        
        if (verifyEmailOption === VerifyOption.EMAIL) {
            sendUserVerificationCode("email");
        } else if (verifyEmailOption === VerifyOption.SMS) {
            sendUserVerificationCode("sms");
        }
    }

    const handleChangeVerificationMethodClick = () => {
        setIsGetUserCodeDisabled(false);
        setVerificationCode(null);
    }

    return <>
        <RadioButton
            label={`Email verification (${userInfo.email})`}
            value={VerifyOption.EMAIL}
            name='Email'
            disabled={isGetUserCodeDisabled}
            checked={verifyEmailOption == VerifyOption.EMAIL}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVerifyEmailOption(VerifyOption.EMAIL)}
        />
        <RadioButton
            label={`Phone verification (${userInfo.phoneNumber})`}
            value={VerifyOption.SMS}
            name='Phone'
            disabled={isGetUserCodeDisabled}
            checked={verifyEmailOption == VerifyOption.SMS}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVerifyEmailOption(VerifyOption.SMS)}
        />

        <div className='flex justify-between mt-4'>
            <Button label={'Get the code'} theme={'primary'} onClick={handleGetEmailVerificationClick} className={'w-full sm:w-auto'} disabled={verifyEmailOption == null || isGetUserCodeDisabled} />
        </div>

        <Label label={'Please enter the veridfication code *'} className='mt-4'>
            <Input value={verificationCode} name='verificationCode' onChange={(e) => setVerificationCode(e.target.value)} disabled={!isGetUserCodeDisabled}/>
            {verificationError && <p className='text-sm text-error py-1'>{verificationError}</p>}
        </Label>

        {isGetUserCodeDisabled && <>
        <div className='text-primary text-xs font-bold mt-2 cursor-pointer' onClick={handleGetEmailVerificationClick}>Request new code</div>
        <div className='text-primary text-xs font-bold mt-2 cursor-pointer' onClick={handleChangeVerificationMethodClick}>Change verification method</div>
        </>}
    </>
}

const EnterNewEmailStep: any = ({ oldEmail, newEmail, setNewEmail, emailValidationError, setEmailValidationError }) => {
    const validate = (email) => {
        if (email?.length == 0) {
            setEmailValidationError('Required');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setEmailValidationError('Invalid email address');
        } else if (email == oldEmail) {
            setEmailValidationError("Email is the same with your current one");
        } else {
            setEmailValidationError(null);
        }
    }

    return <>
        <div className='mt-4 w-full text-trueGray text-base font-normal text-left mb-3'>
          In order to change the email we need you to verify it's you on the next step
        </div>
        <Label label={'Please enter new email'}>
            <Input value={newEmail} type='email' name='email' onChange={(e) => { setNewEmail(e.target.value); validate(e.target.value) }} />
        </Label>
        {emailValidationError && <p className='text-sm text-error py-1'>{emailValidationError}</p>}
    </>
}

export const EmailVerificationModal: any = ({
    userInfo,
    isVerifyEmailModalOpen,
    setIsVerifyEmailModalOpen,
    onCloseCallback
}) => {
    const { mutateAsync: updateUserEmail, isPending } = useUpdateUserEmail({
        onSuccess: (result) => {
            if (result) {
                setIsVerifyEmailModalOpen(false);
                onCloseCallback(newEmail);
                resetState();
                notification.success("Email has been updated");
            } else {
                setVerificationError("Incorrect code");
            }
        }
    });
    const { mutateAsync: isEmailExists } = useIsUserEmailExists({
        onSuccess: (result) => {
            if (result) {
                setEmailValidationError("Email already exists");
            } else {
                setCurrent(current + 1);
            }
        },
        onError: (error) => {
        
        },
    });
    const [current, setCurrent] = useState(0);
    const [newEmail, setNewEmail] = useState<any>();
    const [verifyEmailOption, setVerifyEmailOption] = useState<any>();
    const [verificationCode, setVerificationCode] = useState<any>();
    const [verificationError, setVerificationError] = useState<any>();
    const [emailValidationError, setEmailValidationError] = useState<any>();

    const resetState = () => {
        setNewEmail(null);
        setVerifyEmailOption(null);
        setVerificationCode(null);
        setVerificationError(null);
        setCurrent(0);
    }

    const next = () => {
        isEmailExists(newEmail);
    };

    const prev = () => {
        setVerificationError(null);
        setCurrent(current - 1);
    };

    const handleVerifyUserCodeClick = () => {
        const data = {
            Method: verifyEmailOption === VerifyOption.EMAIL ? "email" : (verifyEmailOption === VerifyOption.SMS ? "sms" : ""),
            Email: newEmail,
            Code: verificationCode
        } as any;

        updateUserEmail(data);
    }

    const steps = [
        {
            title: 'New Email',
            content: <EnterNewEmailStep
                oldEmail={userInfo.email} 
                newEmail={newEmail} 
                setNewEmail={setNewEmail}
                emailValidationError={emailValidationError}
                setEmailValidationError={setEmailValidationError}/>,
        },
        {
            title: 'Verify',
            content: <EmailVerificationStep 
                userInfo={userInfo} 
                verifyEmailOption={verifyEmailOption}
                setVerifyEmailOption={setVerifyEmailOption}
                verificationCode={verificationCode}
                setVerificationCode={setVerificationCode}
                verificationError={verificationError}
                />
        }
    ];
    
    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    return (
        <Modal
            title={'Change Email'}
            isOpen={isVerifyEmailModalOpen}
            onClose={() => { setIsVerifyEmailModalOpen(false); resetState(); }}
        >
            <Steps current={current} items={items} />
            <div style={{ height: 300, width: 350 }} className='p-2'>{steps[current].content}</div>
            <div style={{ marginTop: 24 }} className={current === 0 ? 'flex flex-end' : 'flex flex-space-between'}>
                {current < steps.length - 1 && (
                    <Button theme={'primary'} onClick={() => next()} label='Next' disabled={newEmail == null || emailValidationError != null}/>
                )}
                {current > 0 && (
                    <Button label='Back' onClick={() => prev()} />
                )}
                {current === steps.length - 1 && (
                    <Button label='Verify' onClick={handleVerifyUserCodeClick} disabled={isPending || verificationCode == null || verificationCode?.length == 0}/>
                )}
            </div>
        </Modal>
    );
};