import { FormikErrors, FormikTouched } from 'formik';
import { PropertyUnitValues } from '../property-detail/property-detail';
import { SeparateBuildingAvailabilityComponentProps } from './property-building-availability.types';
import clsx from 'clsx';
import { Icon, Label } from 'legacy-components/componets';
import { EstateDetailsShortInfo } from 'legacy-components/estate/estate-details-header/EstateDetailsShortInfo';
import { GenericPropertyBuildingAvailability } from './GenericPropertyBuildingAvailability';
import { getSeparateMinDate } from './property-building-availability.utils';

export const SeparateBuildingAvailabilityComponent = ({
  values,
  errors,
  touched,
  index,
  useExpandableList,
  setFieldValue,
}: SeparateBuildingAvailabilityComponentProps) => {
  const unitErrors = errors?.details?.units?.[index] as FormikErrors<PropertyUnitValues>;
  const unitTouched = touched?.details?.units?.[index] as FormikTouched<PropertyUnitValues>;
  const unitData = values?.details?.units[index];

  const { expandedIds, handleExpand } = useExpandableList;
  const isExpanded = expandedIds.includes(index);

  return (
    <>
      <div key={index} className='flex flex-col gap-9 my-4'>
        <div
          className={`flex px-5 py-2 rounded-lg flex-col md:flex-row gap-[14px] bg-[#FAFAFA] border box-border relative border-[#FAFAFA]`}
        >
          <div className={`flex flex-col w-full `}>
            <div className='mb-4 flex flex-col lg:flex-row w-full'>
              <div className={clsx('w-full', 'max-w-[400px] mr-0 md:mr-2')}>
                <Label label={'Unit / Apt. number'}>
                  <p>{unitData?.unitNumber}</p>
                </Label>
              </div>
              <div className='flex items-center justify-between min-w-[250px] max-w-[289px] gap-2'>
                <EstateDetailsShortInfo
                  values={values.details}
                  unitErrors={unitErrors}
                  unitTouched={unitTouched}
                  index={index}
                />
              </div>
            </div>
            {isExpanded && (
              <GenericPropertyBuildingAvailability
                setFieldValue={setFieldValue}
                nameOfValue={`details.units.[${index}].availability`}
                itemAvailabilityValue={unitData?.availability}
                errorAvailabilityType={unitErrors?.availability}
                touchedAvailabilityType={unitTouched?.availability}
                minDate={getSeparateMinDate(unitData?.availableOn)}
              />
            )}
          </div>

          {
            <div className='p-1 cursor-pointer absolute right-4 top-2' onClick={() => handleExpand(index)}>
              <Icon name={!isExpanded ? 'arrow-down' : 'arrow-up'} color={'black'} />
            </div>
          }
        </div>
      </div>
    </>
  );
};
