import * as Yup from 'yup';

const phoneErrorMessage = 'Enter the number in the international format with the + symbol, for example +1899999999';
const emailErrorMessage = 'Enter the valid email, for example john.dowry@example.com';
const emailOrPhoneErrorMessage =
  'Please, enter the valid phone number or email, phone example: +1899999999, email example: john.dowry@example.com ';
const emailOrPhoneRequiredErrorMessage = 'Please, enter the phone number or email';

const similarToEmailRegex = /^(?=.*[0-9+])(?=.*[a-zA-Z@_.])|[a-zA-Z@_.]+$/;
const similarToPhoneRegex = /^(?=.*[0-9+])(?!.*[a-zA-Z]).+$/;
const phoneRegex = /^(\+[0-9]{1,5})[0-9]{10}$/;

export const signInValidationSchema = Yup.object({
  email: Yup.string()
    .required(emailOrPhoneRequiredErrorMessage)
    .test(
      'email',
      ({ value }) => {
        if (similarToEmailRegex.test(value)) {
          return emailErrorMessage;
        }

        if (similarToPhoneRegex.test(value)) {
          return phoneErrorMessage;
        }

        return emailOrPhoneErrorMessage;
      },
      async function (value) {
        const emailSchema = Yup.string().email().required();

        return phoneRegex.test(value) || (await emailSchema.isValid(value));
      },
    ),
  password: Yup.string().required(),
});
