import { SubcriptionStatusProps, subscriptionText } from "./SubcriptionStatus.types";
import clsx from 'clsx';
import { generateStyles, styles } from "./SubcriptionStatus.styles";
export const SubcriptionStatus: React.FC<SubcriptionStatusProps> = ({ status, size = 'small' }) => {
    const defaultStyles = generateStyles(size);
    const containerStyle = clsx(defaultStyles.container, styles[status].container);
    const labelStyle = clsx(defaultStyles.label, styles[status].label);
    return (
        <div >
            <div className={containerStyle}>
                <p className={labelStyle}>{subscriptionText[status]}</p>
            </div>
        </div>
    )
}