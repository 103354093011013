import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ContactUsDto } from 'common/types/services/api/notification/notifications-dto.type';
import { contactUs } from 'services/api/notification-api.service';

export default function useContactUs(options?: UseMutationOptions<boolean, Error, ContactUsDto, unknown>) {
  return useMutation({
    ...options,
    mutationFn: contactUs,
  });
}
