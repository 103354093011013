import { Link } from 'react-router-dom';
import { useAuth } from 'hooks';

import styles from './styles';
import { EstateStatusDto } from 'common/enums/enums';
import { EstateStatus, Rating } from 'legacy-components/componets';
import dayjs from 'dayjs';

export type EstatesStatsProps = {
  availableOn: string;
  offers: string;
  views: string;
  tours: string;
  rating: number;
  status: EstateStatusDto | null;
  isOwner: boolean;
};

const EstateStats = ({ availableOn, offers, views, tours, rating, status, isOwner }: EstatesStatsProps) => {
  const { isAuthenticated } = useAuth();

  return (
    <section className={'mb-8 flex flex-col lg:flex-row'}>
      <div className={'grid grid-cols-3 lg:w-1/2 mb-6 lg:mb-0'}>
        <div className={styles.wrapper}>
          <div className={styles.label}>Pending Offers</div>
          {
            // isAuthenticated ? (
            isOwner ? (
              <Link to={'/i-landlord/profile/offers'} className={`${styles.value} text-primary text-[16px]`}>
                {offers}
              </Link>
            ) : (
              <div className={styles.value}>{offers}</div>
            )
            // )
            // : (
            //   <Link to={'/auth/sign-up'} className={`${styles.value} text-primary text-[16px]`}>
            //     Create account
            //   </Link>
            // )
          }
        </div>
        <div className={styles.wrapper}>
          <div className={styles.label}>Views</div>
          <div className={styles.value}>{views}</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.label}>Tours</div>
          <div className={styles.value}>{tours}</div>
        </div>
        {/* <div className={styles.wrapper}>
          <div className={styles.label}>Rating</div>
          <Rating value={rating} />
        </div> */}
      </div>
      <div className={'grid grid-cols-2 lg:w-1/2'}>
        <div className={styles.wrapper}>
          <div className={styles.label}>Rating</div>
          <Rating value={rating} />
        </div>
        {status && (
          <div className={styles.wrapper}>
            <div className={styles.label}>Status</div>
            <EstateStatus status={status} />
            <div className={styles.date}>{dayjs(availableOn).format('MM/DD/YYYY')}</div>
          </div>
        )}
      </div>
    </section>
  );
};

export { EstateStats };
