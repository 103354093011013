import { SelectOption } from 'common/types/types';

export const getTimeOptions = (): SelectOption[] => {
  const amTimesOptions = [...Array(12)].map((time, index) => {
    return {
      label: `${index + 1} am`,
      value: `${index + 1}:00 am`,
    };
  });

  const pmTimesOptions = [...Array(12)].map((time, index) => {
    return {
      label: `${index + 1} pm`,
      value: `${index + 1}:00 pm`,
    };
  });

  return [...amTimesOptions, ...pmTimesOptions];
};

const addZero = (value: number) => {
  let pad = '00';
  return pad.substring(0, pad.length - value.toString().length) + value;
};

export const timeList = () => {
  let times: { label: string; value: string }[] = [];

  for (var i = 1; i < 25; i++) {
    let am = 'AM',
      pm = 'PM',
      ampm,
      timeUnit;

    timeUnit = i > 12 ? i - 12 : i;
    ampm = i < 12 || i > 23 ? am : pm;

    times.push({ label: `${addZero(timeUnit)} ${ampm}`, value: `${addZero(timeUnit)}:00 ${ampm}` });
  }

  return times;
};
