import * as Yup from 'yup';

const maxPropertyImageSize = 1024 * 1024 * 75; // 75MB
const supportedPropertyImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/heic', 'image/heif'];

export const propertyAccessValidationSchema = () => {
  return Yup.object().shape({
    keyAccess: Yup.string().required(),
    accessInstructions: Yup.string().notRequired(),
    accountId: Yup.string().when('keyAccess', (keyAccess, field) => {
      return keyAccess[0] !== 'electronicLock' ? field : field.required('Account is required');
    }),
    deviceId: Yup.string().when('keyAccess', (keyAccess, field) => {
      return keyAccess[0] !== 'electronicLock' ? field : field.required('Device is required');
    }),
    files: Yup.array().of(
      Yup.mixed()
        .test('fileSize', 'The file size is too large', (file: any) => {
          const isFileSizeValid = file?.size <= maxPropertyImageSize; // 75MB
          if (isFileSizeValid || !!file.id) return true;
        })
        .test('fileType', 'The file type not valid', (file: any) => {
          const isFileTypeValid = supportedPropertyImageTypes.includes(file.type);
          if (isFileTypeValid || !!file.id) return true;
        }),
    ),
  });
};
