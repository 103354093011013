import { ENV } from 'common/enums/enums';
import { useUpdateUserPersonaVerification } from 'hooks/query/auth/use-user-persona-verification';
import Persona, { Client } from 'persona';
import { InquiryError, InquiryOptions } from 'persona/dist/lib/interfaces';
import { useEffect, useRef, useState } from 'react';

const options = {
  environmentId: ENV.REACT_APP_PERSONA_ENVIRONMENT_ID,
  templateId: ENV.REACT_APP_PERSONA_TEMPLATE_ID,
};

function checkPersonaIsConfigured() {
  if (!options.environmentId || !options.templateId) {
    throw new Error('Persona is not configured!');
  }
}

const usePersonaEmbeddedInqury = (verificationId) => {
  const embeddedClientRef = useRef<Client | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<InquiryError | null>(null);
  const [configError, setConfigError] = useState<boolean>(false);

  const { mutate: updateUserPersonaVerification } = useUpdateUserPersonaVerification();

  /**
   * Creates a new instance of the Persona client. Note that if this is called
   * when the popup is closed, an entirely new inquiry will be created (as
   * opposed to if you wre to call client.open()).
   */
  const createPersonaClient = ({ referenceId }: { referenceId: InquiryOptions['referenceId'] }) => {
    if (configError) {
      return;
    }

    setIsLoading(true);
    const client = new Persona.Client({
      ...options,
      referenceId,
      frameWidth: '400px',
      frameHeight: '650px',
      onLoad: () => {
        client.open();
        setIsLoading(false);
      },
      onError: (error: InquiryError) => {
        setIsLoading(false);
        setError(error);
      },
      onComplete: ({ inquiryId, status, fields }) => {
        updateUserPersonaVerification({
          verificationId,
          inquiryId,
          status,
        });
      },
    });
    embeddedClientRef.current = client;

    // @ts-ignore
    window.exit = (force: any) => (client ? client.exit(force) : alert('Initialize client first'));
  };

  useEffect(() => {
    try {
      checkPersonaIsConfigured();
    } catch (e) {
      console.error(e);
      setConfigError(true);
    }
  }, [options]);

  return {
    createPersonaClient,
    isLoading,
    isError: Boolean(error),
    configError,
  };
};

export { usePersonaEmbeddedInqury };
