import { SelectOption } from "common/types/types";

export const userProfileTypeOptions: SelectOption[] = [
    { label: 'Renter', value: 'Renter' },
    { label: 'Landlord', value: 'Landlord' },
    { label: 'Other', value: 'General' },
    // { label: 'Realtor', value: 'Realtor', iconName: 'userTypeCard' },
    // { label: 'Property Manager', value: 'PropertyManager', iconName: 'userTypeCard' },
    // { label: 'General', value: 'General', iconName: 'userTypeCard' },
];

export const questionTitleOptions: SelectOption[] = [
    { label: 'Assistance Needed with Account', value: 'Assistance Needed with Account' },
    { label: 'Assistance Needed with Tours', value: 'Assistance Needed with Tours' },
    { label: 'Assistance Needed with Offers', value: 'Assistance Needed with Offers' },
    { label: 'System Enhancement Proposal', value: 'System Enhancement Proposal' },
    { label: 'Other', value: 'Other' },
];

export const labelStyleConfig = {
    label: {
        fontSize: 14,
        fontWeight: 700,
        color: '#222222',
        fontFamily: 'Manrope',
    }
};