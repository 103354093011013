import React, { FC } from 'react';
import { FormikErrors } from 'formik/dist/types';
import { PropertyOtherFeeValues } from './property-detail';
import { Checkbox } from 'legacy-components/fields/checkbox/checkbox';
import { Input } from 'legacy-components/fields/input/input';
import { stringAsFloatNumber } from 'helpers/input-field.helper';
import { Icon } from 'legacy-components/ui/icon/icon';
import { FormikProps } from 'formik';
import { PropertyValues } from '../landlord-properties';
import { InputProps } from 'common/types/components/fields/input/input-props.type';

type UnitFeesTableFields = 'name' | 'price';

type Props = {
  error?: FormikErrors<PropertyOtherFeeValues[]>;
  index: number;
  unitIndex: number;
  setFieldValue: FormikProps<PropertyValues>[`setFieldValue`];
  otherFeestable: PropertyOtherFeeValues;
  handleDelete: () => void;
};

const OtherFeesTable: FC<Props> = ({ error, index, unitIndex, otherFeestable, setFieldValue, handleDelete }) => {
  const feesErrors = error?.at(index) as FormikErrors<PropertyOtherFeeValues>;

  const getInputFeeTableProps = (
    name: UnitFeesTableFields,
    placeholder: string,
    unitIndex: number,
    feesIndex: number,
  ): InputProps => {
    const unitFieldValue = name === 'name' ? otherFeestable.name : otherFeestable.price;
    const fieldName = `details.units[${unitIndex}].otherFeestable[${feesIndex}].[${unitFieldValue}]`;
    return {
      value: unitFieldValue,
      name: fieldName,
      placeholder,
      onChange: (e) => {
        setFieldValue(fieldName, e.target.value);
      },
    };
  };

  return (
    <div className='w-full' key={index}>
      <div className='flex gap-2'>
        {/* <Checkbox
          name={`otherFeestable[${index}].checked`}
          value={otherFeestable.checked}
          checked={otherFeestable.checked}
          onChange={() => {
            setFieldValue(`details.units[${unitIndex}].otherFeestable[${index}].checked`, !otherFeestable.checked);
          }}
        /> */}
        <div className='w-full flex gap-4'>
          <div className='w-full'>
            <Input
              {...getInputFeeTableProps(`name`, 'Name', unitIndex, index)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue(`details.units[${unitIndex}].otherFeestable[${index}].name`, e.target.value)
              }
              invalid={Boolean(!!feesErrors?.name)}
            />
          </div>
          <div className='w-full'>
            <Input
              {...getInputFeeTableProps(`price`, '$', unitIndex, index)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                stringAsFloatNumber(e, `details.units[${unitIndex}].otherFeestable[${index}].price`, setFieldValue)
              }
              invalid={Boolean(!!feesErrors?.price)}
            />
            {feesErrors?.price && <p className='text-sm text-error py-1'>{feesErrors?.price}</p>}
          </div>
        </div>
        <div className='w-3 flex justify-center align-middle'>
          {index !== 0 && (
            <button onClick={handleDelete}>
              <Icon name={'delete'} width={20} height={20} clickable />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtherFeesTable;
