import { UpcomingToursTitleProps } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/types/upcoming-tours-title-props.type';

const ToursTitle = ({ title }: UpcomingToursTitleProps) => {
  return (
    <div className='profile-info-header flex justify-between items-center mb-2 md:mb-9'>
      <div className='title text-xl font-bold'>{title}</div>
    </div>
  );
};

export { ToursTitle };
