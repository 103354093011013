import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { TourPaymentIntentDto } from 'common/types/services/api/tours/tour-payment-intent-dto.type';
import { getEstatePayment } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useEstatePayment(
  estateId: string,
  options?: Omit<
    UndefinedInitialDataOptions<TourPaymentIntentDto, Error, TourPaymentIntentDto, string[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Estates, estateId, 'payment'],
    queryFn: () => getEstatePayment(estateId),
    ...options,
  });
}
