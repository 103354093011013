import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { UserAuthPhoneVerificationDto } from 'common/types/services/api/user/user-auth-phone-verification-dto.type';
import { sendPhoneConfirmation } from 'services/api/auth-api.service';

export default function useSendPhoneConfirmation(
  options?: UseMutationOptions<any, Error, UserAuthPhoneVerificationDto, unknown>,
) {
  return useMutation({
    mutationFn: sendPhoneConfirmation,
    ...options,
  });
}
