const LandlordSettingsPage = () => {
  return (
    <div className="relative flex w-full h-[400px] mx-2 mt-2 overflow-hidden pointer-events-none z-0">
      <div className='absolute inset-0 bg-[#E5E5E5] opacity-30'></div>
      <div
        className='z-10 font-semibold absolute inset-0 flex items-center justify-center'
        style={{ transform: 'rotate(-45deg)' }}
      >
        <h1 className='mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 '>
          Coming soon! <mark className='px-2 text-white bg-primary rounded'>Axxelist</mark>
        </h1>
      </div>
    </div>
  );
};

export { LandlordSettingsPage };
