import { Outlet } from 'react-router-dom';

const RenterLayout = () => {
  return (
    <div className='renter-container grow'>
      <Outlet />
    </div>
  );
};

export { RenterLayout };
