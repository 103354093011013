import { Filters } from 'components/molecules/Filters/Filters';
import { RenterTourHistoryFiltersForm } from './RenterTourHistoryFiltersForm';
import { TourStatusDto } from 'common/enums/services/api/tours/tour-status-dto.enum';
import { QueryParamConfig, createEnumDelimitedArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { useMemo } from 'react';

type QueryParamConfigType = {
  tourStatuses: QueryParamConfig<`${TourStatusDto}`[]>;
};

const defaultQueryParams = {
  tourStatuses: [],
};

const RenterTourHistoryFilters = () => {
  const queryParamConfig = useMemo<QueryParamConfigType>(
    () => ({
      tourStatuses: withDefault(
        createEnumDelimitedArrayParam(Object.values(TourStatusDto) as `${TourStatusDto}`[], ','),
        [],
      ),
    }),
    [],
  );

  const [query, setQuery] = useQueryParams(queryParamConfig, {
    updateType: 'replace',
  });

  const handleSubmit = (values: any) => {
    setQuery(values);
  };

  const handleReset = () => {
    setQuery(defaultQueryParams);
  };

  return (
    <Filters
      initialValues={defaultQueryParams}
      filters={query}
      onSubmit={handleSubmit}
      onReset={handleReset}
      FiltersMenuForm={RenterTourHistoryFiltersForm}
    />
  );
};

export { RenterTourHistoryFilters };
