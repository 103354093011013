// @ts-nocheck
import { useState } from 'react';
import { Input } from 'legacy-components/componets';

import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { defaultGoogleConfig } from './AdminCommission.config';

export type GooglePlace = {
  description: string;
  place_id: string;
  types: string[];
};

export type GoogleAddressComponents = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type GooglePlaceDetails = {
  address_components: GoogleAddressComponents[];
  formatted_address: string;
  geometry: {
    location: {
      lng: Function;
      lat: Function;
    };
  };
};

export type SelectedLocation = {
  address: string;
  lng?: number;
  lat?: number;
};

export type PlaceAutocompleteProps = {
  value: string;
  fieldName: string;
  invalid?: boolean;
  disabled?: boolean;
  setValue: (location: SelectedLocation) => void;
  setGooglePlaceDetails: (placeDetails: GooglePlaceDetails) => void;
  googleConfig?: any;
  placeholder?: string;
};

export const AdminCommisionPlaceAutomplete = ({
  value,
  fieldName,
  invalid,
  setValue,
  setGooglePlaceDetails,
  disabled,
  googleConfig = defaultGoogleConfig,
  placeholder = 'Address'
}: PlaceAutocompleteProps) => {
  const [hideAddresses, setHideAddresses] = useState(true);

  const { placesService, placePredictions, getPlacePredictions } = useGoogle(googleConfig);

  const handleSelectAddress = (place: GooglePlace) => {
    placesService?.getDetails({ placeId: place.place_id, language: 'en' }, (placeDetails: GooglePlaceDetails) => {
      // @ts-ignore
      setGooglePlaceDetails({ ...placeDetails, terms: place.terms });
      setValue({
        address: place.description,
        lng: placeDetails.geometry.location.lng(),
        lat: placeDetails.geometry.location.lat(),
      });
    });
    setHideAddresses(true);
  };

  const getRenderAddressItem = (address: any, index: number) => {
    return (
      <div
        key={index}
        className='flex items-center  h-10 pl-2 hover:bg-gray'
        onClick={() => handleSelectAddress(address)}
      >
        {address.description}
      </div>
    );
  };

  return (
    <>
      <div className="relative">
        <Input
          value={value}
          name={fieldName}
          placeholder={placeholder}
          invalid={invalid}
          disabled={disabled}
          onChange={(evt) => {
            setHideAddresses(false);
            getPlacePredictions({ input: evt.target.value, language: 'en' });
            setValue({ address: evt.target.value });
          }}
        />
        {!hideAddresses && placePredictions.length > 0 && (
          <div className='absolute z-50 p-2 rounded-lg bg-white shadow-blue cursor-pointer mt-2 w-full'>
            {placePredictions.map((item, index) => getRenderAddressItem(item, index))}
          </div>
        )}
      </div>
    </>

  );
};

