import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Loader } from 'legacy-components/componets';
import { FC, Suspense, useState } from 'react';

type Props = {
  isLoading?: boolean;
  onReady?: () => void;
  onSubmitCallback?: () => void | Promise<void>;
  secret: string;
};

const PropertyPublishSetupPaymentForm: FC<Props> = ({ onReady, onSubmitCallback, secret, isLoading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      setError('Error, payment elements not loaded');
      return null;
    }

    const elementsSubmit = await elements.submit();

    if (elementsSubmit.error) {
      return null;
    }

    setSubmitLoading(true);
    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret: secret,
      confirmParams: {
        return_url: '',
      },
      // @ts-ignore
      redirect: 'if_required',
    });

    if (error) {
      setError(error.message || 'An error occurred during confirmation');
    } else {
      onSubmitCallback?.();
    }

    setSubmitLoading(false);
  };

  return (
    <Suspense fallback={<Loader />}>
      <PaymentElement onReady={onReady} />
      <div className='mt-5 flex items-center justify-center'>
        <Button
          label={'Submit'}
          theme={'primary'}
          isFullWidth
          disabled={!stripe || !elements || isLoading || submitLoading}
          onClick={handleSubmit}
        />
      </div>
      {error && <div className='text-sm text-error p-2'>{error}</div>}
    </Suspense>
  );
};

export { PropertyPublishSetupPaymentForm };
