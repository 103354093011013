import { useNavigate } from 'react-router-dom';
import { notification } from 'services/services';

//TODO: MAKE FUNCTION MORE SHARED/FLEXIBLE
const useQueryCustomErrorHandler = () => {
  const navigate = useNavigate();

  const onError = (error: any) => {
    const isBadReqError = error?.response?.status === 400;
    const isNotFound = error?.response?.status === 404;

    if (isBadReqError) {
      navigate('/not-found');
    } else if(isNotFound) {
      notification.error(error?.response?.data?.detail);
    } else {
      notification.error('Oops! Something went wrong...');
    }
    return undefined;
  };

  return { onError };
};

export { useQueryCustomErrorHandler };
