import { FC } from 'react';

type Props = {
  numberOtherImages?: number;
  onClick?: () => void;
};

const EstateUploadedImageShowMoreLayer: FC<Props> = ({ numberOtherImages, onClick }) => {
  return (
    <div
      onClick={onClick}
      className='absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed'
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
    >
      <div className='flex h-full items-center justify-center'>
        <p className='text-white opacity-100 font-bold'>{`+ ${numberOtherImages} more`}</p>
      </div>
    </div>
  );
};

export { EstateUploadedImageShowMoreLayer };
