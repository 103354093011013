import { EstateListDtoStatusEnum } from "common/generated/types/estate-list-dto";

export const styles = {
  [EstateListDtoStatusEnum.Available]: 'bg-[#F1FFF5] text-[#A0D911]',
  [EstateListDtoStatusEnum.Rejected]: 'bg-[#FEF2F2] text-[#FF385C]',
  [EstateListDtoStatusEnum.Unavailable]: 'bg-[#FEF2F2] text-[#FF385C]',
  [EstateListDtoStatusEnum.PendingApproval]: 'bg-[#F1FBFF] text-[#66CCFF]',
  [EstateListDtoStatusEnum.Incomplete]: 'bg-[#FFFBEB] text-[#F3C521]',
  [EstateListDtoStatusEnum.Occupied]: 'bg-[#F1FBFF] text-[#0996CE]',
  [EstateListDtoStatusEnum.PendingPayment]: 'bg-[#FFFBEB] text-[#F3C521]',
};

export const commonStatusStyle = 'font-medium leading-tight px-[10px] py-[2px] rounded-3xl text-center max-w-[110px]';

export const textStyle = (size: 's' | 'm' | 'l') => {
  switch (size) {
    case 's':
      return 'text-xs';
    case 'm':
      return 'text-sm';
    case 'l':
      return 'text-base';
  }
};
