import { ENV } from 'common/enums/env/env';
import { InvestorAuthGuard } from 'guard/investor-guard/investor-guard';
import { LocalPasswordGuard } from 'guard/local-password-guard';
import { RouteObject } from 'react-router-dom';

export const wrapRoutesWithAuthGuard = (routes: RouteObject[]): RouteObject[] => {
  if (ENV.REACT_APP_STRICT_AUTH) {
    return routes
      .filter((el) => el.path !== 'password-auth')
      .map((route) => {
        if (route.element && route.path !== 'strict-auth') {
          route.element = <InvestorAuthGuard>{route.element}</InvestorAuthGuard>;
        }
        return route;
      });
  }

  if (!ENV.REACT_APP_STRICT_AUTH && ENV.REACT_APP_PASSWORD) {
    return routes
      .filter((el) => el.path !== 'strict-auth')
      .map((route) => {
        if (route.element && route.path !== 'password-auth') {
          route.element = <LocalPasswordGuard>{route.element}</LocalPasswordGuard>;
        }

        return route;
      });
  }

  return routes.filter((el) => el.path !== 'strict-auth' && el.path !== 'password-auth');
};
