import { TablePaginationProps } from 'common/types/types';
import { useMemo } from 'react';

export const useCreateTablePaginationProps = (
  currentPage: number,
  totalCount: number | undefined,
  maxItemPerPage: number,
  setCurrentPage: (newPage: number) => void,
): TablePaginationProps =>
  useMemo(() => {
    return {
      currentPage,
      totalCount: totalCount || 0,
      pageSize: maxItemPerPage || 5,
      onPageChange: setCurrentPage,
    };
  }, [currentPage, totalCount, maxItemPerPage]);
