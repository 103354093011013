import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { finishTour } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';
import { Notification } from 'services/notification.service';

export default function useFinishTour(
  options?: Omit<UseMutationOptions<boolean, AxiosError<BackendError>, string, unknown>, 'mutationFn'>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: finishTour,
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'The tour has not been completed');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Tours] });
    },
    ...options,
  });
}
