import React, { ReactNode, useState } from 'react';
import { CellProps } from 'react-table';
import { Icon } from 'legacy-components/componets';
import { TourActionsCell, ToursTableRow } from '../common/types/types';
import { CancelTourModal } from 'legacy-components/cancel-tour-modal/cancel-tour-modal';
import Dropdown from 'rc-dropdown';
import { TourStatusDto } from 'common/enums/enums';
import { EllipsisOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { RenterTourPaymentCountdown } from 'components/molecules/RenterTourPaymentCountdown/RenterTourPaymentCountdown';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';

const TourActionsMenuCell = ({ value }: CellProps<ToursTableRow, TourActionsCell>) => {
  const { onView, onOffer, onCancel, status, scheduleAt, onEdit, id } = value;
  const queryClient = useQueryClient();
  const [cancelTourModalOpen, setCancelTourModalOpen] = useState(false);
  const navigate = useNavigate();

  const onPaymentCountdownFinish = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.Tours] });
  }

  const options: {
    key: string;
    label: ReactNode;
    icon?: React.JSX.Element;
    onClick: () => void;
    visible: boolean;
  }[] = [
    {
      key: 'details',
      label: 'View/edit details',
      icon: <Icon name={'pen'} width={'1em'} height={'1em'} />,
      visible: true,
      onClick: onView,
    },
    {
      key: 'pay',
      label: (
        <div className='flex justify-between items-center w-full'>
          Pay
          <RenterTourPaymentCountdown
            scheduleAt={scheduleAt}
            valueStyle={{
              fontSize: 18,
            }}
            onFinish={onPaymentCountdownFinish}
          />
        </div>
      ),
      visible: status === TourStatusDto.Reserved,
      icon: <Icon name={'credit-card'} width={'1em'} height={'1em'} />,
      onClick: () => {
        navigate(`/i-rent/schedule-tour/checkout/${id}`);
      },
    },
    {
      key: 'edit_tour',
      label: 'Edit time and date',
      icon: <Icon name={'clock'} width={'1em'} height={'1em'} />,
      visible: status === TourStatusDto.Scheduled || status === TourStatusDto.Reserved,
      onClick: onEdit,
    },
    {
      key: 'cancel_tour',
      label: 'Cancel self-tour',
      icon: <Icon name={'xmark'} width={'1em'} height={'1em'} />,
      visible: status !== TourStatusDto.InProgress && status !== TourStatusDto.PendingPayment,
      onClick: () => setCancelTourModalOpen(true),
    },
    {
      key: 'make_offer',
      label: 'Make an offer',
      icon: <Icon name={'check'} width={'1em'} height={'1em'} />,
      visible: true,
      onClick: onOffer,
    },
  ].filter((o) => o.visible);

  const overlay = (
    <div
      className={
        'w-[277px] py-6 bg-white rounded-2xl shadow border border-zinc-100 flex-col justify-start items-start flex gap-3'
      }
    >
      {options.map((o) => (
        <button
          type={'button'}
          key={o.key}
          className={
            'w-full px-6 py-4 hover:bg-sky-50 border-r border-transparent hover:border-sky-300 justify-start items-center gap-2.5 flex hover:text-sky-600'
          }
          onClick={o.onClick}
        >
          {o.icon && React.cloneElement(o.icon, { className: 'text-[16px]' })}
          <div className={'text-base font-normal leading-tight w-full text-start'}>{o.label}</div>
        </button>
      ))}
    </div>
  );

  return (
    <>
      <CancelTourModal
        onClose={() => setCancelTourModalOpen(false)}
        open={cancelTourModalOpen}
        onOk={async () => {
          await onCancel();
          setCancelTourModalOpen(false);
        }}
      />
      <Dropdown trigger={['click']} overlay={overlay} placement={'bottomRight'}>
        <button type={'button'}>
          <EllipsisOutlined className={'text-[24px]'} />
        </button>
      </Dropdown>
    </>
  );
};

export { TourActionsMenuCell };
