import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getConnectedAccessAccounts } from 'services/api/access-accounts-api.service';
import { PaginationDtoRequest } from 'common/types/services/api/common/pagination-dto.type';
import { ConnectedAccessAccountsDto } from 'common/types/services/api/accessAccounts/connected-access-accounts-dto.type';
import { QueryKey } from 'common/enums/query-key';

export default function useConnectedAccessAccounts(
  params: PaginationDtoRequest,
  options?: Omit<
    UndefinedInitialDataOptions<
      ConnectedAccessAccountsDto,
      Error,
      ConnectedAccessAccountsDto,
      (string | PaginationDtoRequest)[]
    >,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.AccessAccounts, params],
    queryFn: () => getConnectedAccessAccounts(params),
    ...options,
  });
}
