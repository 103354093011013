import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  createPropertyUnitAccessFile,
  updatePropertyAccess,
  updatePropertyUnitAccess,
} from 'services/api/property-api.service';
import { QueryKey } from 'common/enums/query-key';
import {
  PropertyUpdateAccessRequestDto,
  PropertyUpdateUnitAccessRequest,
} from 'common/types/services/api/property/property-update-access-request-dto.type';
import { PropertyDtoAccessKey } from 'common/enums/app/property.enum';

export default function useUpdatePropertyUnitsAccess(
  options?: Omit<UseMutationOptions<void, Error, PropertyUpdateUnitAccessRequest, unknown>, 'mutationFn'>,
) {
  return useMutation({
    mutationFn: async (data: PropertyUpdateUnitAccessRequest) => {
      const { estateId, units } = data;
      const estateAccess: PropertyUpdateAccessRequestDto = {
        accessKeyData: {
          estateId: estateId,
          accessType: PropertyDtoAccessKey.KeyInLockbox,
          description: '',
          deviceId: '',
          forAllUnits: false,
        },
        estateId: '',
        accessFilesData: [],
      };
      await updatePropertyAccess(estateAccess);
      await Promise.all(
        units.map(async (unit) => {
          await updatePropertyUnitAccess(unit);
          await Promise.all(
            unit.accessFilesData.map((fileData) => {
              // check if file is type of File to create a new
              if (fileData?.file instanceof File) createPropertyUnitAccessFile(fileData);
            }),
          );
        }),
      );
    },
    mutationKey: [QueryKey.PropertyAccess],
    ...options,
  });
}
