import { CSSProperties } from 'react';

const basestyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 35,
  borderWidth: 2,
  borderColor: '#C4C4C4',
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  color: '#222222',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  borderRadius: 6,
};

const focusedStyle: CSSProperties = {
  borderColor: '#66CCFF',
};

const acceptStyle: CSSProperties = {
  borderColor: '#00e676',
};

const rejectStyle: CSSProperties = {
  borderColor: '#ff1744',
};

export const styles = {
  basestyle,
  focusedStyle,
  acceptStyle,
  rejectStyle,
};
