import { CheckboxTheme } from 'common/types/types';

const styles: Record<CheckboxTheme, { container: string; label: string; input: string; error: string }> = {
  default: {
    container: 'flex h-11 gap-2 items-center',
    label: 'text-sm font-semibold text-black cursor-pointer',
    input: 'h-[18px] w-[18px] !checked:bg-primary text-primary cursor-pointer rounded shrink-0',
    error: 'border-error focus:border-error',
  },
  primary: {
    container: 'flex h-11 gap-2 items-center',
    label: 'text-sm font-semibold text-black',
    input: 'h-[18px] w-[18px] checked:bg-primary text-primary cursor-pointer rounded',
    error: 'border-b-error focus:border-b-error',
  },
};

export default styles;
