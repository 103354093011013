import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { EstatePlanDto } from 'common/types/services/api/estates/estate-plan-dto.type';
import { getEstatePlans } from 'services/api/estates-api.service';

export const useGetPlans = (
  options?: Omit<
    UndefinedInitialDataOptions<EstatePlanDto[], Error, EstatePlanDto[], string[]>,
    'queryFn' | 'queryKey'
  >,
) => {
  return useQuery({
    ...options,
    queryKey: ['plans'],
    queryFn: getEstatePlans,
  });
};
