import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { readAllSavedSearch } from 'services/api/saved-search-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useReadAllSavedSearch(
  options?: Omit<UseMutationOptions<string, Error, void, unknown>, 'mutationFn'>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: readAllSavedSearch,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.SavedSearch, 'unread-count'] });
    },
    ...options,
  });
}
