import { SortOrder } from 'common/types/sort-order';
import { TablePaginationProps } from 'common/types/types';
import { QueryParamConfig, SetQuery } from 'use-query-params';

export type SavedSearchDateCellProps = Date;

export type SavedSearchPropertyCellProps = {
  address: string;
  price: number | string;
  priceMin: number;
  priceMax: number;
  image: string;
};

type SavedSearchActions = () => void;

export type SavedSearchActionsCell = {
  onView: SavedSearchActions;
  onDelete: SavedSearchActions;
};

export type SavedSearchTableRow = {
  date: SavedSearchDateCellProps;
  property: SavedSearchPropertyCellProps;
  actions: SavedSearchActionsCell;
};

export type SavedSearchQueryParamConfig = {
  order: QueryParamConfig<SortOrder>;
  page: QueryParamConfig<number>;
};

export enum SavedSearchTableAccessor {
  Date = 'date',
  Property = 'property',
  Actions = 'actions',
}

export type SavedSearchToursTableProps = {
  data: SavedSearchTableRow[];
  isLoading: boolean;
  paginationProps: TablePaginationProps;
  order: SortOrder;
  setQuery: SetQuery<SavedSearchQueryParamConfig>;
};

export type SavedSearchMobileCardProps = {
  el: SavedSearchTableRow;
  key: number;
};
