import { formatPriceValue } from "helpers/string.helpers";

export const PAGE_TITLE = 'Commissions';
export const EMPTY_TITLE = `Currently, you don't have any commissions`;
export const STATE_LABEL = 'State';
export const CITY_LABEL = 'City';
export const AMOUNT_LABEL = 'Amount';
export const COMMISSION_TYPE_LABEL = 'Commission type';
export const COMMISSION_TYPE_PLACEHOLDER = 'Fixed or %';

export const FILTERS_BTN = 'Filter';
export const ADD_NEW_BTN = 'Add new';

export const CREATE_COMMISSION_TITLE = 'Create new commission';
export const EDIT_COMMISSION_TITLE = 'Edit a commission';

export const ADDRESS_LABEL = 'Address';

export const DEFAULT_COMMISSION_START = 'A default fee of ';
export const DEFAULT_COMMISSION_PRICE = (price: number) => `$${formatPriceValue(price)} `;
export const DEFAULT_COMMISSION_END = 'is assigned to all cities not included in the list.';