import { Fragment } from 'react';
import { OffersTableRow } from 'legacy-pages/renter/profile/renter-profile/offers/common/types/OfferTable.types';
import { Dropdown, DropdownMenu } from 'legacy-components/componets';
import { getDayjs } from 'helpers/date';
import { OFFER } from 'legacy-pages/renter/profile/renter-profile/offers/common/constants/OfferPage.constants';
import { getPricePerMonth } from 'legacy-pages/renter/profile/renter-profile/offers/common/utils/OfferPage.utils';
import { PROPERTY } from 'legacy-pages/renter/profile/renter-profile/offers/common/constants/OfferTableActions.constants';
import { getMenuItems } from 'legacy-pages/renter/profile/renter-profile/offers/common/utils/OfferPageActions.utils';
import { defaultStatusStyle } from './OfferMobileVersion.styles';
import clsx from 'clsx';
import { offerStatusMap } from 'common/enums/services/api/offers/offer-status-dto.enum';
import { formatPriceValue } from 'helpers/string.helpers';
import { styles } from 'components/atoms/OfferStatus/OfferStatus.styles';
import defaultEstatePhoto from 'assets/images/default_estate_image.png';
import { isNotEmptyString } from 'common/utils/check-empty-string';

export const OfferMobileVersion = (offer: OffersTableRow, key: number) => {
  const {
    actions: {
      onResend,
      onCancel,
      onViewEstateProfile,
      onViewPreApplicationPayment,
      onViewSignLease,
      status,
      onViewRejectionReason,
      rejectionReason,
    },
  } = offer;
  const menuItems = getMenuItems(
    onResend,
    onViewRejectionReason,
    onCancel,
    onViewEstateProfile,
    onViewPreApplicationPayment,
    onViewSignLease,
    status,
    rejectionReason,
  );

  const statusStyle = styles[status];
  const combinedStyle = clsx(statusStyle, defaultStatusStyle);
  const imageSrc = isNotEmptyString(offer.property.image) ? offer.property.image : defaultEstatePhoto;

  return (
    <Fragment key={key}>
      <div className='shadow-lg rounded-lg flex flex-col relative'>
        <Dropdown
          target={
            <button
              id='dropdownButton'
              data-dropdown-toggle='dropdown'
              className='inline-block text-gray-500 hover:bg-gray-100 focus:outline-none rounded-lg text-sm p-1.5 absolute bg-white opacity-80 top-2 right-2'
              type='button'
            >
              <svg
                className='w-6 h-6'
                aria-hidden='true'
                fill='black'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z'></path>
              </svg>
            </button>
          }
          menu={<DropdownMenu key={offer.date.toString()} hasDivider menuItems={menuItems} headerTitle={PROPERTY} />}
        />
        <div className='w-full h-40 flex-shrink-0 mr-3 rounded-t-lg overflow-hidden'>
          <img src={imageSrc} alt='property' className='w-full h-full object-cover' />
        </div>

        <div className={combinedStyle}>{offerStatusMap[offer.status]}</div>

        <div className='p-3'>
          <div className='flex justify-between mb-2'>
            <div className='price flex justify-start items-center'>
              <p className='text-primaryDark text-lg font-bold'>${formatPriceValue(offer.property.price)}</p>
              <p className='text-sm font-normal text-trueGray ml-[5px]'>/mo</p>
            </div>
          </div>
          <div className='font-bold mb-2'>{getDayjs(offer.date).format('MMMM D, hh:mm A').toString()}</div>
          <div className='flex items-center justify-between gap-[5px]'>
            <p className='font-bold'>{OFFER}</p>
            <p className='text-base font-normal text-trueGray ml-[5px]'>{getPricePerMonth(offer.proposedRent)}</p>
          </div>
          <div className='text'>{offer.property.location}</div>
        </div>
      </div>
    </Fragment>
  );
};
