import React from 'react';
import { FormDescription } from 'legacy-components/componets';
import { BillingAddressFormValues } from 'legacy-components/billing-address-form/billing-address-form';
import { PaymentDetailsFormValues } from 'legacy-components/payment-details-form/payment-details-form';
import { PaymentCardsListControlled } from 'legacy-components/payment-cards-list/controlled';
import { AddPaymentMethod } from 'legacy-components/payment/add-payment-method';
import { useDeletePaymentCard, usePaymentCards } from 'hooks/query';

export type RenterPaymentDetailsValues = PaymentDetailsFormValues & BillingAddressFormValues;

const RenterPaymentDetailsForm = () => {
  const { data: paymentCards } = usePaymentCards();
  const { mutate: deletePaymentCard } = useDeletePaymentCard();

  return (
    <div className='flex flex-col gap-9 flex-1'>
      <div className='personal-details px-4 md:px-9 py-6 shadow-white-xl'>
        <div className='mb-3'>
          <FormDescription title={'Payment cards'} />
        </div>
        <PaymentCardsListControlled list={paymentCards} handleDeleteItem={deletePaymentCard} />
        <AddPaymentMethod />
      </div>
    </div>
  );
};

export { RenterPaymentDetailsForm };
