import React from 'react';

export type StarIconProps = {
  width?: number;
  height?: number;
  color?: string;
  type?: 'solid' | 'regular';
};

const StarIcon = ({ width = 16, height = 16, color = '#66CCFF', type = 'solid' }: StarIconProps) => {
  return (
    <>
      {type === 'regular' ? (
        <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M7.99933 13.3L4.29933 16.125C4.116 16.275 3.916 16.3456 3.69933 16.337C3.48266 16.329 3.291 16.2666 3.12433 16.15C2.95766 16.0333 2.82866 15.875 2.73733 15.675C2.64533 15.475 2.641 15.2583 2.72433 15.025L4.14933 10.4L0.52433 7.82498C0.32433 7.69164 0.19933 7.51664 0.14933 7.29998C0.0993303 7.08331 0.107664 6.88331 0.17433 6.69998C0.240997 6.51664 0.357664 6.35398 0.52433 6.21198C0.690997 6.07064 0.890997 5.99998 1.12433 5.99998H5.59933L7.04933 1.19998C7.13266 0.966642 7.262 0.787309 7.43733 0.661976C7.612 0.537309 7.79933 0.474976 7.99933 0.474976C8.19933 0.474976 8.38666 0.537309 8.56133 0.661976C8.73666 0.787309 8.866 0.966642 8.94933 1.19998L10.3993 5.99998H14.8743C15.1077 5.99998 15.3077 6.07064 15.4743 6.21198C15.641 6.35398 15.7577 6.51664 15.8243 6.69998C15.891 6.88331 15.8993 7.08331 15.8493 7.29998C15.7993 7.51664 15.6743 7.69164 15.4743 7.82498L11.8493 10.4L13.2743 15.025C13.3577 15.2583 13.3537 15.475 13.2623 15.675C13.1703 15.875 13.041 16.0333 12.8743 16.15C12.7077 16.2666 12.516 16.329 12.2993 16.337C12.0827 16.3456 11.8827 16.275 11.6993 16.125L7.99933 13.3Z'
            fill={color}
          />
        </svg>
      ) : (
        <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M16.5 6.375L11.9375 5.71875L9.875 1.5625C9.53125 0.84375 8.46875 0.8125 8.09375 1.5625L6.0625 5.71875L1.46875 6.375C0.65625 6.5 0.34375 7.5 0.9375 8.09375L4.21875 11.3125L3.4375 15.8438C3.3125 16.6562 4.1875 17.2812 4.90625 16.9062L9 14.75L13.0625 16.9062C13.7812 17.2812 14.6562 16.6562 14.5312 15.8438L13.75 11.3125L17.0312 8.09375C17.625 7.5 17.3125 6.5 16.5 6.375ZM12.125 10.7812L12.875 15.0938L9 13.0625L5.09375 15.0938L5.84375 10.7812L2.6875 7.71875L7.03125 7.09375L9 3.15625L10.9375 7.09375L15.2812 7.71875L12.125 10.7812Z'
            fill={color}
          />
        </svg>
      )}
    </>
  );
};

export { StarIcon };
