import React, { FC, useState } from 'react';
import { Button } from 'legacy-components/componets';
import { joinValues } from 'helpers/string.helpers';
import { getDate, getDayjs } from 'helpers/date';
import { useNavigate } from 'react-router-dom';
import { RequiredInformationBlockProps } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/types/required-information-block-props.type';
import { OverdueInformationBlock } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/components/overdue-information-block/overdue-information-block';
import { OverdueInformationBlockProps } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/types/overdue-information-block-props.type';
import { RequiredInformationBlock } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/components';
import { TourDotsMenuProps } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/types/tour-dots-menu-props.type';
import { TourStatusDto } from 'common/enums/enums';
import { TourCardProps } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/types/tour-card-props.type';
import { TourTimeInfoProps } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/types/tour-time-info-props.type';
import { RentModal } from '../rent-modal/rent-modal';
import { EstateCard } from 'components/organisms/EstateCard';
import { useWindowSize } from 'hooks';
import { StartTourModal } from 'legacy-components/modals/start-tour-modal/StartTourModal';
import { useEstateDetails, useFinishTour } from 'hooks/query';

const TourCard: FC<TourCardProps> = ({
  card,
  tourStatus,
  startTourDate,
  actions,
  isIncompleteProfile,
  onFinishTour,
  tour,
  estateInfo,
}) => {
  const { width } = useWindowSize();
  const isHorizontal = !width || Number(width) > 500;
  const { onView, onEdit, onCancel, onOffer, onCollectKey, onStartTour } = actions;
  const tourId = card.id;
  const navigate = useNavigate();
  const [rentModalOpen, setRentModalOpen] = useState(false);
  const { data: estateDetails } = useEstateDetails(tour?.estateId);
  const [isStartTourModalOpen, setIsStartTourModalOpen] = useState(false);

  const getReservedSeconds = React.useCallback(() => {
    const deadline = getDayjs(card.scheduleAt).add(1, 'hour');
    return getDayjs(deadline).diff(getDate(), 'seconds');
  }, [card]);

  const getMinutesToStartTour = React.useCallback(() => {
    const deadline = getDayjs(startTourDate);
    return getDayjs(deadline).diff(getDate(), 'minutes');
  }, [startTourDate]);

  const getSecondsFromStartTour = React.useCallback(() => {
    return getDayjs(getDate()).diff(getDayjs(startTourDate), 'seconds');
  }, [startTourDate]);

  const [reservedSeconds, setReservedSeconds] = React.useState<number>(getReservedSeconds());
  const [minutesToStartTour, setMinutesToStartTour] = React.useState<number>(getMinutesToStartTour());
  const [secondsFromStartTour, setSecondsFromStartTour] = React.useState<number>(getSecondsFromStartTour());
  const { mutateAsync: finishTour, isPending: isFinishingTour } = useFinishTour({
    onSuccess: () => {
      setRentModalOpen(true);
    },
    onSettled: () => {},
  });

  const totalHoursToStartTour = Math.floor(minutesToStartTour / 60);
  const totalMinutesToStartTour = minutesToStartTour - totalHoursToStartTour * 60;

  // TODO DELETE LATER
  // React.useEffect(() => {
  //   dispatch(getTourPayment({ tourId, unitId: tour?.estateId }));
  // }, [tourId, dispatch]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setReservedSeconds(getReservedSeconds());
    }, 1000);

    return () => clearInterval(interval);
  }, [getReservedSeconds]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setMinutesToStartTour(getMinutesToStartTour());
    }, 1000);

    return () => clearInterval(interval);
  }, [getMinutesToStartTour]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSecondsFromStartTour(getSecondsFromStartTour());
    }, 1000);

    return () => clearInterval(interval);
  }, [getSecondsFromStartTour]);

  const isCollectYourKeyDisabled = !getDayjs().isBetween(
    getDayjs(startTourDate).subtract(15, 'minutes'),
    getDayjs(startTourDate).add(60, 'minutes'),
    null,
    '[]',
  );

  const handleVisitProfile = React.useCallback(() => {
    navigate('/i-rent/profile/info');
  }, [navigate]);

  const handleCollectKey = () => {
    onCollectKey(tourId);
  };

  const handleStartTour = () => {
    onStartTour(tourId);
  };

  const handleEndTour = () => {
    finishTour(tourId);
  };

  const handleNeedHelp = React.useCallback(() => {}, []);

  const showHours = totalHoursToStartTour > 0;
  const showMinutes = totalMinutesToStartTour > 0;

  const timeToStartTour = React.useMemo<string>(() => {
    const totalMinutes = Math.floor(reservedSeconds / 60);
    const totalSeconds = reservedSeconds - totalMinutes * 60;
    return joinValues([`${totalMinutes}m`, `${totalSeconds}s`], ' ');
  }, [reservedSeconds]);

  const requiredInformationBlockProps = React.useMemo<RequiredInformationBlockProps>(() => {
    return {
      title: 'Additional information required',
      timeToStart: timeToStartTour,
      actionTitle: 'Visit Profile',
      onClick: handleVisitProfile,
    };
  }, [timeToStartTour, handleVisitProfile]);

  const remainingTimeTour = React.useMemo<string>(() => {
    const totalMinutes = Math.floor(secondsFromStartTour / 60);
    const totalSeconds = secondsFromStartTour - totalMinutes * 60;
    return joinValues([`${totalMinutes}m`, `${totalSeconds}s`], ' ');
  }, [secondsFromStartTour]);

  const overdueInformationBlockProps = React.useMemo<OverdueInformationBlockProps>(() => {
    return {
      title: 'Is everything okay?',
      overdueTime: remainingTimeTour,
      onNeedHelp: handleNeedHelp,
    };
  }, [handleNeedHelp, remainingTimeTour]);

  // eslint-disable-next-line
  const tourDotsMenuProps = React.useMemo<TourDotsMenuProps>(() => {
    return { onView, onEdit, onCancel, onOffer };
  }, [onView, onEdit, onCancel, onOffer]);

  const timeToStart = React.useMemo<string>(() => {
    return minutesToStartTour > 0
      ? `(${joinValues(
          [
            showHours ? `${totalHoursToStartTour}h` : undefined,
            showMinutes ? `${totalMinutesToStartTour}m` : undefined,
          ],
          ', ',
        )})`
      : '';
  }, [showHours, showMinutes, totalHoursToStartTour, totalMinutesToStartTour, minutesToStartTour]);

  // eslint-disable-next-line
  const tourTimeInfoProps = React.useMemo<TourTimeInfoProps>(() => {
    return {
      timeToStart,
      status: tourStatus,
      scheduleAt: card.scheduleAt || '',
      remainingTime: remainingTimeTour,
    };
  }, [card, tourStatus, timeToStart, remainingTimeTour]);

  const showRequiredInformationBlock =
    isIncompleteProfile && getDayjs().isBefore(getDayjs(card.scheduleAt).add(1, 'hour'));
  // tour must be in Progress
  const showOverdueInformationBlock =
    tourStatus === TourStatusDto.Scheduled && getDayjs().isAfter(getDayjs(startTourDate));

  return (
    <>
      {showRequiredInformationBlock && <RequiredInformationBlock {...requiredInformationBlockProps} />}
      {showOverdueInformationBlock && <OverdueInformationBlock {...overdueInformationBlockProps} />}
      {/*<div className="flex justify-between items-center gap-6">*/}
      {/*    <div className="tour-date text-2xl text-trueGray font-bold uppercase mb-[14px]">*/}
      {/*        Next self-tour*/}
      {/*    </div>*/}
      {/*    <div className="flex items-center gap-6">*/}
      {/*        <TourTimeInfo {...tourTimeInfoProps}/>*/}
      {/*        <TourDotsMenu {...tourDotsMenuProps} />*/}
      {/*    </div>*/}
      {/*</div>*/}
      {
        <RentModal
          availableOn={estateDetails?.units.find((u) => u.id === tour?.unitId)?.availableOn}
          open={rentModalOpen}
          onClose={() => {
            setRentModalOpen(false);
            onFinishTour();
          }}
          tour={tour}
        />
      }
      <div className={`${!isHorizontal && 'flex justify-center'}`}>
        <EstateCard {...estateInfo} isHorizontal={isHorizontal} />
      </div>
      <div className='flex justify-end gap-3'>
        {tourStatus === TourStatusDto.Scheduled && (
          <>
            <Button
              isFullWidth
              label={'Collect Your Key'}
              theme={isCollectYourKeyDisabled ? 'gray' : 'primary-outline'}
              onClick={handleCollectKey}
              disabled={isCollectYourKeyDisabled}
              icon={'key'}
            />
            <Button
              isFullWidth
              label={'Start Tour'}
              theme={isCollectYourKeyDisabled ? 'gray' : 'primary'}
              onClick={() => {
                setIsStartTourModalOpen(true);
              }}
              disabled={isCollectYourKeyDisabled}
              icon={'play'}
            />
          </>
        )}
        {tourStatus === TourStatusDto.InProgress && (
          <>
            {/*<Button label={'Extend Tour'} theme={'primary-outline'} onClick={() => { }} stylesConfig={{ width: 182 }} />*/}
            <Button
              isLoading={isFinishingTour}
              label={'Finish Tour'}
              theme={'red'}
              icon={'xmark'}
              onClick={handleEndTour}
              isFullWidth
            />
          </>
        )}
      </div>
      <StartTourModal
        isOpen={isStartTourModalOpen}
        onClose={() => {
          setIsStartTourModalOpen(false);
        }}
        onContinue={() => {
          setIsStartTourModalOpen(false);
          handleStartTour();
        }}
      />
    </>
  );
};

export { TourCard };
