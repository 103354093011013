import { IPlanCardProps } from 'legacy-pages/landlord/properties/property-plans/plan-card/plan-card';
import { PropertyDtoPlan, PropertyPlan } from 'common/enums/enums';

export const propertyPlansConfig: Omit<IPlanCardProps, 'action' | 'unitCount' | 'type'>[] = [
  {
    id: PropertyPlan.Basic,
    name: 'Basic',
    duration: 'First 28 days free, then $29.99 for 28 days',
    price: 29.99,
    popular: true,
    planIncludes: [
      { title: 'Up to 20 photos' },
      { title: 'Virtual Tour'},
      { title: 'Verified Tenants'},
    ],
  },
  {
    id: PropertyPlan.Pro,
    name: 'PRO',
    popular: false,
    duration: '28-day listing',
    price: 69.99,
    planIncludes: [
      { title: 'Up to 30 photos'},
      { title: 'Virtual Tour'},
      { title: 'Verified Tenants'},
      { title: 'Data from touring'},
    ],
  },
];

export const propertyPlanMaxPhoto = {
  [PropertyPlan.Basic]: 20,
  [PropertyPlan.Pro]: 30
};

export const planIdToDtoPlanId: Record<PropertyPlan, PropertyDtoPlan> = {
  [PropertyPlan.Basic]: PropertyDtoPlan.Basic,
  [PropertyPlan.Pro]: PropertyDtoPlan.Pro,
};
