const styles = {
  container: `
            select-none
            flex
            flex-col
            gap-3
            py-6 
            bg-white 
            w-[277px] 
            rounded-lg 
            shadow-blue 
            overflow-hidden 
            absolute 
            z-50
            top-5 
            right-0`,
  item: `
            flex
            items-center
            gap-3.5
            grow
            px-6 
            py-3.5 
            text-base
            text-black 
            hover:text-primary 
            hover:font-bold 
            hover:border-r-2 
            hover:border-r-primary 
            hover:bg-secondary 
            cursor-pointer`,
  line: `
            h-1
            m-0
            border-l-2
            border-blue-400
            w-[277px]
          `,
};

export default styles;
