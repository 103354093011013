import { ListingFilters } from './landlord-listing';
import { SelectOption } from 'common/types/types';
import { EstateCategoryDto, EstateStatusDto, EstateTypeDto } from 'common/enums/enums';
import { useRef } from 'react';

export const maxItemPerPage = 5;

export const defaultFilters: ListingFilters = {
  status: '',
  category: '',
  type: '',
  published: '',
  page: 1,
};

export const useFiltersRefs = () => {
  const statusRef = useRef<string | null>(null);
  const categoryRef = useRef<string | null>(null);
  const typeRef = useRef<string | null>(null);

  return {
    status: statusRef,
    category: categoryRef,
    type: typeRef,
  };
};

export const clearAllFilters = (filtersRefs: any) => {
  Object.values(filtersRefs).forEach((ref: any) => {
    if (ref?.current) {
      ref.current.clearValue();
    }
  });
};

const availabilityLabelName: Record<EstateStatusDto, string> = {
  [EstateStatusDto.Available]: 'Available',
  [EstateStatusDto.Incomplete]: 'Incomplete',
  [EstateStatusDto.PendingApproval]: 'Pending Approval',
  [EstateStatusDto.Unavailable]: 'Unavailable',
  [EstateStatusDto.Occupied]: 'Rented',
  [EstateStatusDto.Rejected]: 'Rejected',
  [EstateStatusDto.PendingPayment]: 'Pending Payment',
};

const getAvailabilityOption = (value: EstateStatusDto): SelectOption => ({
  value,
  label: availabilityLabelName[value],
});

const statusOptions = Object.values(EstateStatusDto)
  .map(getAvailabilityOption)
  .filter((item) => item.label);

const propertyLabelName: Partial<Record<EstateCategoryDto, string>> = {
  [EstateCategoryDto.Apartment]: 'Apartment',
  [EstateCategoryDto.Townhouse]: 'Townhouse',
  [EstateCategoryDto.Condo]: 'Condo',
  [EstateCategoryDto.House]: 'House',
};

const getPropertyOption = (value: EstateCategoryDto): SelectOption => ({
  value,
  label: propertyLabelName[value],
});

const categoryOptions = Object.values(EstateCategoryDto)
  .map(getPropertyOption)
  .filter((item) => item.label);

const typeLabelName: Partial<Record<EstateTypeDto, string>> = {
  [EstateTypeDto.SingleUnit]: 'Single Unit',
  [EstateTypeDto.MultiUnit]: 'Multi Unit',
};

const getTypeOption = (value: EstateTypeDto): SelectOption => ({
  value,
  label: typeLabelName[value],
});

const typeOptions = Object.values(EstateTypeDto)
  .map(getTypeOption)
  .filter((item) => item.label);

export const filtersOptions: Record<keyof Omit<ListingFilters, 'page'>, SelectOption[]> = {
  status: statusOptions,
  category: categoryOptions,
  type: typeOptions,
  published: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ],
};
