import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

const AgreeAndContinue = () => (
  <div className='text-xs font-bold text-trueGray'>
    <span>By selecting Agree and Continue. I agree to Axxelist’s</span>
    <span>&nbsp;</span>
    <Link to={'/terms-of-service'} target='_blank' rel='noopener noreferrer' className='text-primary cursor-pointer'>
      Terms of Service
    </Link>
    <span>,&nbsp;</span>
    <Link
      to={'/payment-terms-of-service'}
      target='_blank'
      rel='noopener noreferrer'
      className='text-primary cursor-pointer'
    >
      Payments Terms of Service
    </Link>
    <span>,&nbsp;</span>

    <Link
      to={'/non-discrimination-policy'}
      target='_blank'
      rel='noopener noreferrer'
      className='text-primary cursor-pointer'
    >
      Nondiscrimination Policy
    </Link>
    <span>, and Acknowledge the&nbsp;</span>
    <Link to={'/privacy-policy'} target='_blank' rel='noopener noreferrer' className='text-primary cursor-pointer'>
      Privacy Policy
    </Link>
  </div>
);

export { AgreeAndContinue };
