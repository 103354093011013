import { useState, useEffect } from 'react'; 
import { Button } from 'legacy-components/componets';
import { Fragment } from 'react';
import { usePersonaEmbeddedInqury } from './use-persona-embedded-inquiry';
import IdcardOutlined from '@ant-design/icons/IdcardOutlined';
//import { useRequestUserPersonaVerification } from 'hooks/query/auth/use-user-persona-verification';
//import { Notification } from 'services/notification.service';

const PersonaEmbeddedInquiryVerification = ({ verificationId, referenceId }) => {
  const [ promptConfirmed, setPromptConfirmed ] = useState<boolean>(false);
  const { createPersonaClient, isLoading, configError } = usePersonaEmbeddedInqury(verificationId);

  // const { mutate: requestUserPersonaVerification, isPending: isPendingRequestUserPersonaVerification } =
  //   useRequestUserPersonaVerification({
  //     onSuccess: (data) => {
  //       createPersonaClient({ referenceId: data.referenceId });
  //     },
  //     onError: (e) => {
  //       const notification = new Notification();
  //       notification.error(e?.response?.data?.detail ?? 'Something went wrong');
  //     },
  //   });

  useEffect(() => {
      if(promptConfirmed && referenceId) {
        createPersonaClient({ referenceId: referenceId });
      }  
  }, [referenceId, promptConfirmed]);

  const handleClick = () => {
    setPromptConfirmed(true); //requestUserPersonaVerification();
  };

  return (
    <Fragment>
      <Button
        isFullWidth
        startIcon={<IdcardOutlined className='text-xl' />}
        label={'START VERIFYCATION'}
        className='min-h-[55px]'
        onClick={handleClick}
        disabled={configError}
        isLoading={isLoading /*|| isPendingRequestUserPersonaVerification*/}
      />
    </Fragment>
  );
};

export { PersonaEmbeddedInquiryVerification };
