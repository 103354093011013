import React from 'react';
import { CellProps } from 'react-table';
import { joinValues } from 'helpers/helpers';
import { SavedSearchPropertyCellProps, SavedSearchTableRow } from '../SavedSearchTable.types';
import { formatPriceValue } from 'helpers/string.helpers';
import { PropertyImage } from 'components/atoms/PropertyImage/PropertyImage';

const mapRangePrice = (minValue: number, maxValue: number) => {
  return minValue === maxValue
    ? formatPriceValue(minValue)
    : `${formatPriceValue(minValue)}-${formatPriceValue(maxValue)}`;
};

const SavedSearchPropertyCell: React.FC<CellProps<SavedSearchTableRow, SavedSearchPropertyCellProps>> = ({ value }) => {
  const { address, price, priceMax, priceMin, image } = value;

  const isString = typeof price === 'string';
  const isPriceRangeExist = priceMax && priceMin;

  const formattedPrice = isString
    ? Number(price)
      ? formatPriceValue(Number(price))
      : isPriceRangeExist
      ? mapRangePrice(priceMin, priceMax)
      : price
    : formatPriceValue(price);

  return (
    <div className='flex gap-3'>
      <div className='w-[64px] h-[48px]'>
        <PropertyImage image={image} />
      </div>
      <div className={'text-sm'}>
        <div>{address}</div>
        <div className='text-trueGray'>${joinValues([formattedPrice, 'mo'], ' / ')}</div>
      </div>
    </div>
  );
};

export { SavedSearchPropertyCell };
