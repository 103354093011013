import { getEstateDetails } from 'services/api/estates-api.service';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { useQueryCustomErrorHandler } from 'hooks/query/use-query-custom-error-handler';

export default function useEstateDetails(estateId?: string) {
  const { onError } = useQueryCustomErrorHandler();
  return useQuery({
    queryKey: [QueryKey.Estates, estateId],
    queryFn: () => {
      if (!estateId) return;
      return getEstateDetails(estateId).catch(onError);
    },
    enabled: !!estateId,
  });
}
