import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { getDate } from 'helpers/date';
import { Input } from 'legacy-components/componets';
import { DatepickerProps } from 'common/types/types';

import styles from './styles';

const Datepicker = <T extends boolean | undefined>({
  name,
  label,
  selectedDate,
  theme = 'default',
  invalid,
  onSelect,
  onChange,
  selectsRange,
  startDate,
  endDate,
  maxTime,
  maxDate,
  minDate,
  minTime,
  showMonthYearPicker,
  stylesConfig,
  ...restProps
}: DatepickerProps<T>) => {
  const CustomInput = forwardRef((props: any, ref) => {
    return <Input {...props} onChange={() => null} inputRef={ref} icon={{ name: 'calendar', color: '#66CCFF' }} />;
  });

  const handleChange = (
    date: T extends false | undefined ? Date | null : [Date | null, Date | null],
    event: React.SyntheticEvent<any> | undefined,
  ) => {
    onChange(date, event);
  };

  return (
    <div className={styles[theme].container} style={stylesConfig?.container}>
      {label && (
        <label htmlFor={name} className={styles[theme].label} style={stylesConfig?.label}>
          {label}
        </label>
      )}
      <DatePicker
        name={name}
        selected={selectedDate ? getDate(selectedDate) : undefined}
        onSelect={onSelect}
        onChange={handleChange}
        className={styles[theme].input}
        selectsRange={selectsRange}
        startDate={startDate}
        endDate={endDate}
        maxDate={maxDate}
        minDate={minDate}
        maxTime={maxTime}
        minTime={minTime}
        showPopperArrow={false}
        customInput={<CustomInput invalid={invalid} />}
        dropdownMode='select'
        {...restProps}
      />
    </div>
  );
};

export { Datepicker };
