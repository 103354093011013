import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { postRescheduleTourPaymentStatus } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function usePostRescheduleTourPaymentStatus(
  options?: Omit<UseMutationOptions<void, Error, string, unknown>, 'mutationFn'>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postRescheduleTourPaymentStatus,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Tours] });
    },
    ...options,
  });
}
