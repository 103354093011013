import { FC } from 'react';
import { Modal } from 'legacy-components/ui/modal/modal';
import { CloseOutlined } from '@ant-design/icons';
import { Icon } from 'legacy-components/ui/icon/icon';
import { Button } from 'legacy-components/ui/button/button';
import { useWindowSize } from 'hooks';

type Props = {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  onSecondary: () => void;
};

const WarningMoveInFeesModal: FC<Props> = ({ open, isLoading, onClose, onSubmit, onSecondary }) => {
  const { isMobile } = useWindowSize();
  const iconSize = isMobile ? 50 : 80;

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      styles={{
        content: {
          ...(isMobile && {
            bottom: 'auto',
            height: 'auto',
            top: '25%',
            transform: 'none',
            left: 0,
            right: 0,
            margin: '10px',
          }),
        },
      }}
    >
      <div className='p-6 bg-white rounded-lg shadow flex flex-col jsustify-center align-middle'>
        <div className='flex justify-end'>
          <button onClick={onClose} type='button'>
            <CloseOutlined className={`text-primary text-[22px]`} />
          </button>
        </div>
        <div className='flex-grow flex items-center justify-center'>
          <Icon name={'warning-round'} height={iconSize} width={iconSize} />
        </div>
        <h3 className='mb-4 font-semibold xs:text-base md:text-lg lg:text-xl text-center'>
          <span className='text-[#FA8C16]'>Please Review Move-In Total Section</span>
        </h3>
        <h3 className='mb-4 text-black font-semibold xs:text-sm md:text-base lg:text-base text-center'>
          Take a moment to review the Move-In Total section for precise information entry
        </h3>
        <div className={`flex ${isMobile ? 'flex-col' : ''} justify-center items-center gap-2`}>
          <Button
            className='py-5 px-5 whitespace-nowrap'
            label={'Re-Check Move-In Section'}
            theme={'primary-outline'}
            onClick={onSecondary}
            disabled={isLoading}
            isFullWidth={isMobile}
          />
          <Button
            className='py-5 px-5 whitespace-nowrap'
            label={'Confirm and continue'}
            onClick={onSubmit}
            isLoading={isLoading}
            isFullWidth={isMobile}
          />
        </div>
      </div>
    </Modal>
  );
};

export default WarningMoveInFeesModal;
