import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';
import { AppRoute, AuthRole } from 'common/enums/enums';
import { AuthGuardProps } from 'common/types/types';
import { useEffect } from 'react';

const AuthGuard = ({ children, allowedRoles }: AuthGuardProps) => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const { state, pathname, search } = useLocation();
  const hasPermissions = user?.roles.some((role: AuthRole) => allowedRoles.includes(role));

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(AppRoute.SIGN_IN, { state: { ...state, from: { pathname, search } }, replace: true });
    } else {
      if (!hasPermissions) {
        navigate(AppRoute.ESTATES_GRID, { replace: true });
      }
    }
  }, [isAuthenticated, hasPermissions]);

  if (!isAuthenticated) return null;

  return <>{children}</>;
};

export { AuthGuard };
