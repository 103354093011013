import { ReactElement, useMemo } from 'react';
import { TableCellProps, TableHeaderProps, useFlexLayout, useResizeColumns, useTable } from 'react-table';
import { generateTablePlaceholderProps } from 'helpers/components';
import { TableProps } from 'common/types/types';
import { Loader } from 'legacy-components/componets';
import { TablePagination } from './table-pagination';
import { TablePlaceholder } from './table-placeholder';
import { twMerge } from 'tailwind-merge';
import { ArrowUpOutlined } from '@ant-design/icons';
import { FilterButton } from './FilterButton';
import SimpleBar from 'simplebar-react';

const Table = <Data extends object>({
  columns,
  data,
  paginationProps,
  placeholder,
  title,
  isLoading,
  styleConfig,
}: TableProps<Data>): ReactElement => {
  const generatedPlaceholderProps =
    placeholder && Boolean(Object.keys(placeholder).length) && generateTablePlaceholderProps(placeholder);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 20,
      width: 200,
      maxWidth: 400,
    }),
    [],
  );

  const tableInstance = useTable<Data>(
    {
      columns,
      data,
      defaultColumn,
    },
    useFlexLayout,
    useResizeColumns,
  );

  const getStyles = (
    props: Partial<TableHeaderProps> | Partial<TableCellProps>,
  ): (Partial<TableHeaderProps> | Partial<TableCellProps>)[] => [
    props,
    {
      style: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
      },
      className: 'table-row-cell',
    },
  ];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const hasData = Boolean(data.length);

  return (
    <>
      <div
        className='w-full text-black flex flex-col gap-4 md:gap-[110px] pb-7 min-h-[634px] relative'
        style={
          !hasData
            ? { ...styleConfig?.mainContainer?.dataIsNotExist }
            : { ...styleConfig?.mainContainer?.dataExists }
        }
      >
        {isLoading && <Loader className='h-full absolute z-10 opacity-75 bg-white' />}
        {title && <h4 className='text-xl font-bold'>{title}</h4>}
        <SimpleBar autoHide={false} className='overflow-y-hidden'>
          <table
            {...getTableProps()}
            className='table-wrapper bg-white w-full min-h-[464px]'
            style={{ ...styleConfig?.table }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps({
                    style: {
                      height: 40,
                      borderBottom: '2px solid #DEEFF7',
                      ...styleConfig?.header,
                    },
                    className: 'header-group',
                  })}
                >
                  {headerGroup.headers.map((header, i) => {
                    const column = columns[i];
                    return (
                      <th
                        {...header.getHeaderProps(getStyles)}
                        style={{
                          ...header.getHeaderProps(getStyles).style,
                          width: header.width,
                        }}
                        className='column-header'
                      >
                        {column.sorter ? (
                          <button
                            className={'w-full flex gap-3 items-center'}
                            onClick={() => {
                              if (column.onSort) {
                                column.onSort(column.sortOrder === 'asc' ? 'desc' : 'asc');
                              }
                            }}
                          >
                            <span>{header.render('Header')}</span>
                            <span className={'flex flex-col'}>
                              <ArrowUpOutlined
                                className={twMerge(
                                  'text-[14px] text-primary transition-transform duration-300',
                                  column.sortOrder === 'desc' && '-rotate-180',
                                )}
                              />
                            </span>
                          </button>
                        ) : column.filters ? (
                          <div className={'w-full flex gap-3 items-center'}>
                            <span>{header.render('Header')}</span>
                            <FilterButton
                              filters={column.filters}
                              filteredValue={column.filteredValue || []}
                              onFilter={(filters) => {
                                if (column.onFilter) {
                                  column.onFilter(filters);
                                }
                              }}
                            />
                          </div>
                        ) : (
                          header.render('Header')
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {!hasData && !isLoading && (
                <tr className='table-placeholder'>
                  <td>
                    <TablePlaceholder {...generatedPlaceholderProps} />
                  </td>
                </tr>
              )}

              {rows.map((row) => {
                prepareRow(row);

                return (
                  <tr
                    {...row.getRowProps({
                      style: {
                        height: 80,
                        alignItems: 'center',
                        borderBottom: '1px solid #EFF7FB',
                        ...styleConfig?.row,
                      },
                      className: 'table-row',
                    })}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps(getStyles)}
                          width={cell.column.width}
                          style={{
                            ...cell.getCellProps(getStyles).style,
                            width: cell.column.width,
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SimpleBar>

        {paginationProps && <TablePagination {...paginationProps} />}
      </div>
    </>
  );
};

export { Table };
