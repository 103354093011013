import { UndefinedInitialDataOptions, UseMutationOptions, useMutation, useQuery } from '@tanstack/react-query';
import { deleteRenterOfferFile, getRenterOfferFiles } from 'services/api/offers-api.service';
import { QueryKey } from 'common/enums/query-key';
import { OfferFileDeleteRequest, OfferFileDto } from 'common/types/services/api/offers/offer-file-dto.type';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';

export default function useOfferDetails(
  offerId: string | undefined,
  options?: Omit<
    UndefinedInitialDataOptions<OfferFileDto[] | undefined, Error, OfferFileDto[], (string | undefined)[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Offers, offerId, 'documents'],
    queryFn: () => {
      if (!offerId) return;
      return getRenterOfferFiles(offerId);
    },
    enabled: !!offerId,
    ...options,
  });
}

export const useDeleteOfferFile = (
  options?: Omit<UseMutationOptions<void, AxiosError<BackendError>, OfferFileDeleteRequest, unknown>, 'mutationFn'>,
) => {
  return useMutation({
    mutationFn: deleteRenterOfferFile,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};
