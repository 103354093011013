import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { LandlordSettingsPage } from 'legacy-pages/landlord/settings';
import { AdminProfileInformation } from 'legacy-pages/admin/profile-information';
import { AdminProfileListing } from 'legacy-pages/admin/listing/AdminProfileListing';
import { AdminOffers } from 'legacy-pages/admin/offers/offers';
import { AdminCommissions } from 'legacy-pages/admin/commission/AdminCommission';
// import { RefferalAdmin } from 'components/templates/Admin/Referral/RefferalAdmin';
import { AccountSecurity } from 'components/templates/Renter/AccountSecurity/AccountSecurity';
// import { GiftsAdmin } from 'components/templates/Admin/Gifts/GiftsAdmin';
import { AdminFeedbacks } from 'components/templates/Admin/Feedbacks/AdminFeedbacks';
import AdminTours from 'components/templates/Admin/Tours/AdminTours';

const ChangePassword = lazy(() => import('legacy-pages/change-password/change-password'));
const LandlordProperties = lazy(() => import('legacy-pages/landlord/properties/landlord-properties'));
const PropertyDetails = lazy(() => import('legacy-pages/landlord/property-details/property-details'));
const AdminProfile = lazy(() => import('legacy-pages/admin/profile'));

export const adminRoutes = [
  { path: '', element: <Navigate to='properties' replace /> },
  {
    path: 'profile',
    element: <AdminProfile />,
    children: [
      { path: '', element: <Navigate to={'info'} replace /> },
      { path: 'info', element: <AdminProfileInformation />, index: true },
      { path: 'listing', element: <AdminProfileListing /> },
      { path: 'schedule/*', element: <AdminTours /> },
      { path: 'offers', element: <AdminOffers /> },
      { path: 'commissions', element: <AdminCommissions /> },
      // { path: 'gifts', element: <GiftsAdmin /> },
      // { path: 'referral', element: <RefferalAdmin /> },
      { path: 'feedbacks', element: <AdminFeedbacks /> },
      { path: 'security', element: <AccountSecurity /> },
    ],
  },
  { path: 'properties', element: <LandlordProperties /> },
  { path: 'properties/:id', element: <LandlordProperties /> },
  { path: 'properties/details/:id', element: <PropertyDetails /> },
  { path: 'settings', element: <LandlordSettingsPage /> },
  { path: 'change-password', element: <ChangePassword /> },
];
