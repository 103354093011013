import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getAccessAccountLocks } from 'services/api/access-accounts-api.service';
import { AccessAccountLockDto } from 'common/types/services/api/accessAccounts/access-account-lock-dto.type';
import { QueryKey } from '../../../common/enums/query-key';

export default function useAccessAccountLocks(
  accountId: string,
  options?: Omit<
    UndefinedInitialDataOptions<AccessAccountLockDto[], Error, AccessAccountLockDto[], string[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.AccessAccounts, 'locks', accountId],
    queryFn: () => getAccessAccountLocks(accountId),
    ...options,
  });
}
