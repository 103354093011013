import { useMemo, useState } from 'react';
import { Button, Card, Modal } from 'legacy-components/componets';
import { FormikProps } from 'formik';
import { PropertyValues } from '../landlord-properties';
import { IEstateCard } from 'legacy-components/card/card';
import { propertyPlansConfig } from '../property-plans/config';
import { CheckoutForm } from 'legacy-pages/renter/schedule-tour-checkout/checkout-form';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ENV, EstateStatusDto } from 'common/enums/enums';
import { useNavigate } from 'react-router-dom';
import { notification } from 'services/services';
import { PropertyPublishSetupPaymentForm } from './property-publish-setup-payment-form';
import { useEstatePayment, usePaymentPendingEstate } from 'hooks/query';

export type PropertyPublishProps = Pick<FormikProps<PropertyValues>, 'submitForm' | 'values'> & {
  stepTitle?: string;
  card: IEstateCard;
  paymentDone: boolean;
  setPaymentDone: (value: boolean) => void;
  estateId: string;
  // AX-609
  estateStatus: EstateStatusDto | null;
};

const PropertyPublish = ({
  values,
  card,
  stepTitle,
  paymentDone,
  setPaymentDone,
  submitForm,
  estateId,
  estateStatus,
}: PropertyPublishProps) => {
  const navigate = useNavigate();

  const [isStripeLoading, setIsStripeLoading] = useState<boolean>(true);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const stripePromise = loadStripe(ENV.REPLENISH_PUBLIC_KEY);

  const { data: estatePayment } = useEstatePayment(card.id);
  const { sum = 0, isPaymentSetupIntent = false, secret: estatePaymentId = '' } = estatePayment || {};
  const { mutateAsync: paymentPendingEstate } = usePaymentPendingEstate();

  const isPaymentFormReady = Boolean(estatePaymentId || !isStripeLoading);

  const options = {
    clientSecret: estatePaymentId,
    appearance: {
      /*...*/
    },
  };

  const currentPlan = useMemo(() => {
    return propertyPlansConfig.find((plan) => plan.id === values.plan);
  }, [values.plan]);

  const propertyPublishTitle = (() => {
    return (
      <div className='title flex flex-col gap-3'>
        {stepTitle && <div className='text-primaryDark uppercase font-semibold'>{stepTitle}</div>}
        <div className='title text-xl font-medium'>Congratulations! Let's see your listings</div>
        <div className='description text-trueGray'>Let's take another look at what we've done.</div>
      </div>
    );
  })();

  const propertyPublishPaymentTitle = (() => {
    return (
      <div className='title flex flex-col gap-3'>
        <div className='title text-xl font-medium'>Payment</div>
        <div className='description text-trueGray'>You will not be charged until your property is approved</div>
      </div>
    );
  })();

  const propertyPublishSubmitButton = (() => {
    if (estateStatus === EstateStatusDto?.Available || estateStatus === EstateStatusDto?.PendingApproval) {
      return (
        <div className='button-wrapper flex justify-end'>
          <Button type='button' label={'Publish'} theme={'primary'} onClick={() => publishSubmit()} />
        </div>
      );
    }

    return (
      <div className='button-wrapper flex justify-end'>
        <Button
          type='button'
          label={paymentDone ? 'Payment done!' : 'Pay Now'}
          theme={paymentDone ? 'green-outline' : 'primary'}
          onClick={() => setOpenPaymentModal(true)}
          disabled={paymentDone || !isPaymentFormReady}
        />
      </div>
    );
  })();

  const publishSubmit = async () => {
    submitForm();
    if (estateStatus !== EstateStatusDto?.Available && estateStatus !== EstateStatusDto?.PendingApproval) {
      await paymentPendingEstate(estateId);
    }
    navigate('/i-landlord/profile/listing');
  };

  const paymentModal = (() => {
    return (
      <Modal isOpen={openPaymentModal} onClose={() => setOpenPaymentModal(false)}>
        <div className='p-5 min-w-[332px] max-h-[98vh] overflow-y-auto'>
          <div className='text-lg font-bold	text-center mb-4'>Payment</div>

          <Elements stripe={stripePromise} options={options}>
            {isPaymentSetupIntent ? (
              <PropertyPublishSetupPaymentForm
                isLoading={isStripeLoading}
                secret={estatePaymentId}
                onSubmitCallback={() => {
                  notification.success('Congratulations! Setup payment was successful');
                  setPaymentDone(true);
                  setOpenPaymentModal(false);
                  publishSubmit();
                }}
                onReady={() => {
                  setIsStripeLoading(false);
                }}
              />
            ) : (
              <CheckoutForm
                clientSecret={estatePaymentId}
                callback={publishSubmit}
                onCloseModal={() => setOpenPaymentModal(false)}
                isStripeLoading={isStripeLoading}
                setPaymentDone={setPaymentDone}
                setIsStripLoading={setIsStripeLoading}
              />
            )}
          </Elements>
        </div>
      </Modal>
    );
  })();

  return (
    <>
      {isPaymentFormReady && paymentModal}

      <div className='property-basics flex flex-col gap-9'>
        {propertyPublishTitle}
        <Card {...card} stylesConfig={{ container: { width: 296 } }} />
      </div>

      <div className='property-basics flex flex-col gap-9'>
        {propertyPublishPaymentTitle}

        <div className='flex gap-2.5 items-center'>
          <div className={'w-[167px] font-bold'}>
            {currentPlan?.duration} <span className='capitalize'>{currentPlan?.name}</span>
          </div>
          <div>${sum}</div>
          <div className='text-center text-[#A3A3A3] font-regular'>
            {'('}
            {currentPlan?.price}x{values.basics.unitCount}
            {')'}
          </div>
        </div>

        <div className='flex gap-2.5 mb-4'>
          <div className={'w-[167px] font-bold uppercase'}>Total</div>
          <div className={'text-primary font-bold text-[18px]'}>${sum}</div>
        </div>

        {/* <div className='flex gap-2.5'>
          <div className={'w-[167px] font-bold'}>Payment Mode</div>
        </div> */}

        <div className={'w-[315px]'}>
          {/* <div className={'w-[315px] p-[14px] bg-gray mb-6'}>
            <div className={'flex items-center gap-3'}>
              <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M14 0H2C1.1675 0 0.5075 0.6675 0.5075 1.5L0.5 10.5C0.5 11.3325 1.1675 12 2 12H14C14.8325 12 15.5 11.3325 15.5 10.5V1.5C15.5 0.6675 14.8325 0 14 0ZM13.25 10.5H2.75C2.3375 10.5 2 10.1625 2 9.75V6H14V9.75C14 10.1625 13.6625 10.5 13.25 10.5ZM14 3H2V2.25C2 1.8375 2.3375 1.5 2.75 1.5H13.25C13.6625 1.5 14 1.8375 14 2.25V3Z'
                  fill='black'
                />
              </svg>
              <div className={'font-medium flex-grow'}>Saved card details</div>
              <div className={'cursor-pointer'} onClick={() => setPaymentCardSave((prevState) => !prevState)}>
                {!paymentCardSave ? (
                  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8 14C4.685 14 2 11.315 2 8C2 4.685 4.685 2 8 2C11.315 2 14 4.685 14 8C14 11.315 11.315 14 8 14Z'
                      fill='black'
                    />
                  </svg>
                ) : (
                  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <g clip-path='url(#clip0_900_189263)'>
                      <path
                        d='M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.685 15 3 12.315 3 9C3 5.685 5.685 3 9 3C12.315 3 15 5.685 15 9C15 12.315 12.315 15 9 15Z'
                        fill='black'
                      />
                      <path
                        d='M9 12.75C11.0711 12.75 12.75 11.0711 12.75 9C12.75 6.92893 11.0711 5.25 9 5.25C6.92893 5.25 5.25 6.92893 5.25 9C5.25 11.0711 6.92893 12.75 9 12.75Z'
                        fill='black'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_900_189263'>
                        <rect width='18' height='18' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
            </div>
          </div> */}

          <div className='w-full'></div>
        </div>

        {propertyPublishSubmitButton}
      </div>

      {/* <ProgressBar progressPercentage={99} /> */}
    </>
  );
};

export { PropertyPublish };
