import { Outlet } from 'react-router-dom';
import { Footer, Header } from 'legacy-components/componets';
import { ENV } from 'common/enums/enums';
import { BannerWrapper } from 'components/templates/Banner/BannerWrapper';
import BackgroundCheckStickyButton from 'legacy-components/header/BackgroundCheckButton/BackgroundCheckStickyButton';

const MainLayout = () => {
  return (
    <div className='!font-manrope overflow-hidden overflow-y-auto'>
      <BannerWrapper isActive={ENV.REACT_APP_BANNER} />
      <BackgroundCheckStickyButton/>
      <div className='flex flex-col mx-auto max-w-[2520px]'>
        <Header />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export { MainLayout };
