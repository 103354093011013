export enum LandlordAccountSideBarItems {
  ProfileInformation = 'profileInformation',
  Listings = 'listings',
  Offers = 'offers',
  TourSchedule = 'tourSchedule',
  LeaseDocuments = 'leaseDocuments',
  AccessAccounts = 'accessAccounts',
  Security = 'security',
}

export enum LandlordAccountNavItems {
  ListProperty = 'listProperty',
  ProfileInformation = 'profileInformation',
  Listings = 'listings',
  Offers = 'offers',
  TourSchedule = 'tourSchedule',
  LeaseDocuments = 'leaseDocuments',
  AccessAccounts = 'accessAccounts',
  Security = 'security',
}
