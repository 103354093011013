import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { RescheduleTourInfo } from 'common/types/services/api/tours/reschedule-tour-info.type';
import { getRescheduleTourInfo } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useRescheduleTourInfo(
  tourId: string,
  options?: Omit<
    UndefinedInitialDataOptions<RescheduleTourInfo, Error, RescheduleTourInfo, string[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Tours, tourId, 'reschedule'],
    queryFn: () => getRescheduleTourInfo(tourId),
    ...options,
  });
}
