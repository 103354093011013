import { EstateFormSteps } from './types';

const estateFormStepperMenuConfig: Record<EstateFormSteps, { title: string; key: string; pageNumber: number }> = {
  [EstateFormSteps.Basics]: {
    title: 'Property basics',
    key: 'estate_form_basics',
    pageNumber: 1,
  },
  [EstateFormSteps.Plan]: {
    title: 'Pricing plan',
    key: 'estate_form_pricing_plan',
    pageNumber: 2,
  },
  [EstateFormSteps.Detail]: {
    title: 'Property detail',
    key: 'estate_form_property_detail',
    pageNumber: 3,
  },
  [EstateFormSteps.BuildingAccess]: {
    title: 'Building access',
    key: 'estate_form_building_access',
    pageNumber: 4,
  },
  [EstateFormSteps.BuildingAvailability]: {
    title: 'Building availability',
    key: 'estate_form_building_availability',
    pageNumber: 5,
  },
  [EstateFormSteps.Publish]: {
    title: 'Publish',
    key: 'estate_form_publish',
    pageNumber: 6,
  },
};

const estateFormStepNumberToStep: Record<number, EstateFormSteps> = {
  [estateFormStepperMenuConfig.basics.pageNumber]: EstateFormSteps.Basics,
  [estateFormStepperMenuConfig.plan.pageNumber]: EstateFormSteps.Plan,
  [estateFormStepperMenuConfig.detail.pageNumber]: EstateFormSteps.Detail,
  [estateFormStepperMenuConfig.access.pageNumber]: EstateFormSteps.BuildingAccess,
  [estateFormStepperMenuConfig.availability.pageNumber]: EstateFormSteps.BuildingAvailability,
  [estateFormStepperMenuConfig.publish.pageNumber]: EstateFormSteps.Publish,
};

export { estateFormStepperMenuConfig, estateFormStepNumberToStep };

export const TOOLTIP_TEXT =
  'Your property edits require reapproval to maintain accuracy and compliance with our guidelines.';
