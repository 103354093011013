import { Icon } from 'legacy-components/componets';
import { RequiredInformationBlockProps } from 'legacy-pages/renter/profile/renter-profile/upcoming-tours/types/required-information-block-props.type';

import styles from './styles';

const RequiredInformationBlock = ({ title, timeToStart, actionTitle, onClick }: RequiredInformationBlockProps) => {
  return (
    <div className={styles.container}>
      <div className='flex-center w-10 h-10 rounded-full bg-[#FFF3D8]'>
        <Icon name={'danger'} height={24} width={24} />
      </div>
      <div className={'text-wrapper'}>
        <div className={styles.title}>{title}</div>
        <div className='pb-2'>
          We are reserving this time-slot for
          <span> </span>
          <span className='font-bold'>{timeToStart}</span>
          <br />
          There is information missing from your profile. Please complete this as soon as possible to avoid automatic
          cancellation.
        </div>
        {actionTitle && (
          <span className={styles.actionTitle} style={{ cursor: onClick ? 'pointer' : 'default' }} onClick={onClick}>
            {actionTitle}
          </span>
        )}
      </div>
    </div>
  );
};

export { RequiredInformationBlock };
