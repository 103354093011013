import { Column } from 'react-table';
import { AccessAccountTableAccessor } from 'common/enums/enums';
import { AccessAccountsTableRow } from 'common/types/types';
import { AccessAccountsNameCell } from '../access-accounts-name-cell';
import { AccessAccountsStatusCell } from '../access-accounts-status-cell';
import { AccessAccountsDateCell } from '../access-accounts-date-cell';
import { AccessAccountsIdCell } from '../access-accounts-id-cell';
import { AccessAccountsActionCell } from '../access-accounts-action-cell';

export const accessAccountTableColumns: Column<AccessAccountsTableRow>[] = [
  {
    Header: 'Name',
    accessor: AccessAccountTableAccessor.Name,
    Cell: AccessAccountsNameCell,
    width: '25%',
    minWidth: 250,
  },
  {
    Header: 'Status',
    accessor: AccessAccountTableAccessor.Status,
    Cell: AccessAccountsStatusCell,
    width: '12%',
    minWidth: 100,
  },
  {
    Header: 'Date added',
    accessor: AccessAccountTableAccessor.Date,
    Cell: AccessAccountsDateCell,
    width: '25%',
    minWidth: 200,
  },
  {
    Header: 'Account ID',
    accessor: AccessAccountTableAccessor.ID,
    Cell: AccessAccountsIdCell,
    width: '30%',
    minWidth: 300,
  },
  {
    Header: 'Action',
    accessor: AccessAccountTableAccessor.Action,
    Cell: AccessAccountsActionCell,
    width: '8%',
    minWidth: 50,
  },
];
