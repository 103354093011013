import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks';
import { AppRoute, AuthRole } from 'common/enums/enums';
import { ReactNode, useLayoutEffect } from 'react';
import { defaultAppRoutes } from 'common/utils/defaultAppRoutes';

export type RolesGuardProps = {
  allowedRoles?: AuthRole[];
  notAllowedRoles?: AuthRole[];
  children?: ReactNode;
};

const RolesGuard = ({ children, notAllowedRoles }: RolesGuardProps) => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const countOfRoles = user?.roles?.length || 0;
  // notAuthenticated does not have any roles
  const haveDeniedRoles = countOfRoles > 0 && user?.roles.some((role: AuthRole) => notAllowedRoles?.includes(role));

  useLayoutEffect(() => {
    if (haveDeniedRoles) {
      const defaultRoute = defaultAppRoutes(user?.roles || []);
      navigate(defaultRoute, { replace: true });
    }
  }, [isAuthenticated, haveDeniedRoles]);

  return <>{children}</>;
};

export { RolesGuard };
