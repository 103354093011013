import { CellProps } from 'react-table';
import { ApplicationsTableRow } from '../common/types/types';
import { ApplicationOfferedCell } from '../common/types/applications-table-row';
import { formatPriceValue } from 'helpers/string.helpers';

const OfferedCell = ({ value }: CellProps<ApplicationsTableRow, ApplicationOfferedCell>) => {
  const { offered } = value;
  return <span className='text-base font-medium'>{`$${formatPriceValue(offered || 0)}/mo`}</span>;
};

export { OfferedCell };
