import * as yup from 'yup';
export const validationSchema = yup.object({
  bankStatements: yup
    .array(
      yup
        .mixed()
        .test({
          message: '75 MB is the maximum allowed size for each file',
          test: (file) => {
            if (file && 'size' in file) return (file as File).size <= 75 * 1024 * 1024;
            return true;
          },
        })
        .test({
          message: 'Only PDF, PNG, JPEG files are allowed',
          test: (file) => {
            if (file && 'type' in file) {
              const acceptedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/heic', 'image/heif'];
              return acceptedTypes.includes((file as File).type);
            }
            return true;
          },
        }),
    )
    .strict(true) // Set strict mode to true
    .min(1, 'At least one bank statement is required'),
});
