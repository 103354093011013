// @ts-nocheck

import { useEffect, useState } from 'react';
import { ENV } from 'common/enums/enums';
import { Input } from 'legacy-components/componets';

import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

export type GooglePlace = {
  description: string;
  place_id: string;
  types: string[];
};

export type GoogleAddressComponents = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type GooglePlaceDetails = {
  address_components: GoogleAddressComponents[];
  formatted_address: string;
  geometry: {
    location: {
      lng: Function;
      lat: Function;
    };
  };
};

export type SelectedLocation = {
  address: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  lng?: number;
  lat?: number;
};

export type PlaceAutocompleteProps = {
  value: string;
  mode: string;
  fieldName: string;
  invalid?: boolean;
  disabled?: boolean;
  setValue: (location: SelectedLocation) => void;
  setGooglePlaceDetails: (placeDetails: GooglePlaceDetails) => void;
};

const PlaceAutocomplete = ({
  value,
  mode,
  fieldName,
  invalid,
  setValue,
  setGooglePlaceDetails,
  disabled,
}: PlaceAutocompleteProps) => {
  const [hideAddresses, setHideAddresses] = useState(true);
  const [editCounter, setEditCounter] = useState<number>(0);

  const { placesService, placePredictions, getPlacePredictions } = useGoogle({
    apiKey: ENV.REACT_APP_GOOGLE,
    libraries: ['places'],
    debounce: 200,
    options: {
      componentRestrictions: { country: ['us', 'ca'] },
    },
    language: 'en',
  });

  useEffect(() => {
    if (mode === 'edit' && editCounter === 0) {
      getPlacePredictions({ input: value, language: 'en' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, value, editCounter]);

  useEffect(() => {
    if (placePredictions[0]) {
      if (mode === 'edit' && editCounter === 0) {
        handleSelectAddress(placePredictions[0]);
        setEditCounter((prevState) => prevState+1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placePredictions, editCounter]);

  const handleSelectAddress = (place: GooglePlace) => {
    placesService.getDetails({ placeId: place.place_id, language: 'en' }, (placeDetails: GooglePlaceDetails) => {
      // @ts-ignore
      setGooglePlaceDetails({ ...placeDetails, terms: place.terms });
      setValue({
        address: place.description,
        lng: placeDetails.geometry.location.lng(),
        lat: placeDetails.geometry.location.lat(),
      });
    });
    setHideAddresses(true);
  };

  const getRenderAddressItem = (address: any, index: number) => {
    return (
      <div
        key={index}
        className='flex items-center  h-10 pl-2 hover:bg-gray'
        onClick={() => handleSelectAddress(address)}
      >
        {address.description}
      </div>
    );
  };

  return (
    <>
      <Input
        value={value}
        name={fieldName}
        placeholder='Address'
        invalid={invalid}
        disabled={disabled}
        onChange={(evt) => {
          setHideAddresses(false);
          getPlacePredictions({ input: evt.target.value, language: 'en' });
          setValue({ address: evt.target.value });
        }}
      />
      {!hideAddresses && placePredictions.length > 0 && (
        <div className='py-2 rounded-lg bg-white shadow-blue cursor-pointer mt-2'>
          {placePredictions.map((item, index) => getRenderAddressItem(item, index))}
        </div>
      )}
    </>
  );
};

export { PlaceAutocomplete };
