import { Storage } from './storage.service';
import { Navigation } from './navigation.service';
import { Notification } from './notification.service';

export const storage = new Storage({
  storage: localStorage,
});

export const navigation = new Navigation();

export const notification = new Notification();
