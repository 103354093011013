import { EstateStatusDto } from "common/enums/enums";

interface StatusStyles {
    isActive: boolean;
    styles: {
        div: string;
        img: string;
        text: string;
    };
}

export const getStatusStyles = (status: EstateStatusDto): StatusStyles => {
    switch (status) {
        case EstateStatusDto.Unavailable:
            return {
                isActive: true,
                styles: {
                    div: 'relative',
                    img: 'blur',
                    text: 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white text-lg p-2 bg-black rounded'
                },
            };
        case EstateStatusDto.Occupied:
            return {
                isActive: true,
                styles: {
                    div: 'relative',
                    img: 'blur',
                    text: 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white text-lg p-2 bg-[#A0D911] rounded'
                },
            };
        default:
            return {
                isActive: false,
                styles: {
                    div: '',
                    img: '',
                    text: ''
                },
            };
    }
}


export const statusText: Record<EstateStatusDto, string> = {
    [EstateStatusDto.Available]: 'Available',
    [EstateStatusDto.Incomplete]: 'Incomplete',
    [EstateStatusDto.PendingApproval]: 'Pending Approval',
    [EstateStatusDto.Unavailable]: 'Unavailable',
    [EstateStatusDto.Occupied]: 'Rented',
    [EstateStatusDto.Rejected]: 'Rejected',
    [EstateStatusDto.PendingPayment]: 'Pending Payment',
};