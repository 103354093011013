import { Modal } from 'components/organisms/Modal';
import { useFormik } from 'formik';
import { Button, Label, Textarea } from 'legacy-components/componets';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';
import { FC } from 'react';
import { object, string } from 'yup';
import {
  RejectOfferModalByLandlordModalFormValues,
  RejectOfferModalByLandlordModalProps,
} from './RejectOfferModalByLandlord.types';
import { TITLE } from './RejectOfferModalByLandlord.const';
import { isNotEmptyString } from 'common/utils/check-empty-string';

const initialFormValues = {
  rejectionReason: '',
};

export const RejectOfferModalByLandlordModal: FC<RejectOfferModalByLandlordModalProps> = ({
  isOpen,
  isSubmitting,
  onClose,
  onSubmit: sendOffer,
  offerId,
}) => {
  const handleCloseModal = () => {
    onClose();
    resetForm();
  };

  const { values, errors, touched, setFieldValue, resetForm, submitForm } =
    useFormik<RejectOfferModalByLandlordModalFormValues>({
      initialValues: initialFormValues,
      validationSchema: object({
        rejectionReason: string()
          .test('test-min-reason-length', 'Rejection reason must be at least 5 characters', function (value) {
            if (isNotEmptyString(value || '')) {
              const reasonLength = value?.length || 0;
              return reasonLength > 5;
            }
            return true;
          })
          .test('test-max-reason-length', 'Rejection reason cannot exceed 250 characters', function (value) {
            if (isNotEmptyString(value || '')) {
              const reasonLength = value?.length || 0;
              return reasonLength < 250;
            }
            return true;
          }),
      }),
      onSubmit: async (values) => {
        await sendOffer({
          applicationId: offerId,
          reason: isNotEmptyString(values?.rejectionReason) ? values?.rejectionReason : null,
        });
        handleCloseModal();
      },
    });

  return (
    <Modal
      title={<div className='text-xl'>{TITLE}</div>}
      isOpen={isOpen}
      onClose={handleCloseModal}
      contentClassName={'w-[500px]'}
      footer={
        <div className='flex gap-3 justify-end'>
          <Button label={'Cancel'} theme={'secondary'} onClick={handleCloseModal} className={'w-full sm:w-auto'} />
          <Button
            label={'Reject'}
            theme={'red'}
            onClick={submitForm}
            isLoading={isSubmitting}
            className={'w-full sm:w-auto'}
          />
        </div>
      }
    >
      <Label label={'Add a rejection reason note to the renter (optional)'} className={'col-span-2'}>
        <ErrorValidationMessage touched={touched.rejectionReason} message={errors.rejectionReason}>
          <Textarea
            name={'comment'}
            value={values.rejectionReason}
            onChange={(e) => setFieldValue('rejectionReason', e.target.value)}
            invalid={Boolean(touched.rejectionReason && errors.rejectionReason)}
          />
        </ErrorValidationMessage>
      </Label>
    </Modal>
  );
};
