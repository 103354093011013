import { EstateStatusDto } from 'common/enums/enums';
import { Icon } from 'legacy-components/ui/ui';

export enum EstatesStatus {
  Available = 'available',
  Pending = 'pending',
  OffMarket = 'offMarket',
}

export type EstateStatusProps = {
  status: EstateStatusDto;
};

const EstateStatus = ({ status }: EstateStatusProps) => {
  const statusIcon = (() => {
    switch (status) {
      case EstateStatusDto.Available:
        return <Icon name='available-status' />;
      case EstateStatusDto.PendingApproval:
        return <Icon name='pendingApproval-status' />;
      case EstateStatusDto.Occupied:
        return <Icon name='rented-status' />;
      case EstateStatusDto.Unavailable:
        return <Icon name='unavailable-status' />;
      case EstateStatusDto.PendingPayment:
        return <Icon name='pendingPayment-status' />;
      case EstateStatusDto.Rejected:
        return <Icon name='rejected-status' />;
      default:
        return null;
    }
  })();

  const statusName = (() => {
    switch (status) {
      case EstateStatusDto.Available:
        return 'Available';
      case EstateStatusDto.PendingApproval:
        return 'Pending Approval';
      case EstateStatusDto.Occupied:
        return 'Rented';
      case EstateStatusDto.Unavailable:
        return 'Unavailable';
      case EstateStatusDto.PendingPayment:
        return 'Pending Payment';
      case EstateStatusDto.Rejected:
        return 'Rejected';
      default:
        return '';
    }
  })();

  const statusColor = (() => {
    switch (status) {
      case EstateStatusDto.Available:
        return 'text-[#66CCFF]';
      case EstateStatusDto.Occupied:
        return 'text-[#A0D911]';
      case EstateStatusDto.Unavailable:
        return 'text-[#FF385C]';
      case EstateStatusDto.Rejected:
        return 'text-[#FF385C]';
      case EstateStatusDto.PendingApproval:
        return 'text-[#F3C521]';
      case EstateStatusDto.PendingPayment:
        return 'text-[#F3C521]';
      default:
        return '';
    }
  })();

  const statusTextSize = (() => {
    switch (status) {
      case EstateStatusDto.PendingApproval:
        return 'text-[17px]';
      case EstateStatusDto.PendingPayment:
        return 'text-[17px]';
      default:
        return 'text-[19px]';
    }
  })();

  return (
    <div className='flex'>
      {statusIcon}
      <div className={`${statusTextSize} font-semibold ${statusColor} ${statusIcon && 'pl-2.5'}`}>{statusName}</div>
    </div>
  );
};

export { EstateStatus };
