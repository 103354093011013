import { EstateCardTheme } from './card';

const price = {
  container: 'price flex justify-start items-center',
  amount: 'text-primaryDark text-lg font-bold',
  unit: 'text-sm font-normal text-trueGray ml-[5px]',
};

const location = {
  container: 'card-location flex-grow',
  title: 'text-black text-xl font-bold mb-0.5',
  address: 'text-[#6C6C6C] font-normal text-sm truncate text-ellipsis',
};

const type = {
  container: 'card-estate-type flex justify-center items-center',
  title: `text-[11px] inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline 
            font-semibold bg-gray text-trueGray rounded-full`,
};

const units = {
  container: 'flex justify-center items-center',
  title: `text-[11px] inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline 
              font-semibold bg-secondary text-primary rounded-full`,
};

const infoIcons = {
  container: 'card-info-icons',
  wrapper: 'flex justify-between text-sm text-trueGray font-normal',
  item: 'flex items-center gap-2',
  img: 'block h-8 h-[20px] mr-2',
};

const favouriteIcon = 'absolute top-3 right-4 z-[1]';
const bookmarkIcon = 'absolute top-3 right-4 z-[1]';
const image = 'carousel-item active relative float-left w-full';

const styles: Record<EstateCardTheme, Record<string, any>> = {
  vertical: {
    container: 'flex flex-col rounded-lg shadow-lg bg-white w-full overflow-hidden',
    imageContainer: 'carousel slide relative rounded-t-lg',
    favouriteIcon,
    bookmarkIcon,
    image,
    price,
    type,
    location,
    infoIcons,
    units,
  },
  horizontal: {
    container: 'lg:flex block rounded-lg shadow-lg bg-white w-full overflow-hidden whitespace-nowrap',
    imageContainer: 'carousel slide relative rounded-t-lg lg:w-[280px] w-full',
    favouriteIcon,
    bookmarkIcon,
    image,
    price,
    type,
    location,
    infoIcons,
    units,
  },
};

export default styles;
