import { NavLink } from 'common/types/types';
import { AppRoute } from 'common/enums/route/app-route.enum';
import { AuthRole } from 'common/enums/enums';

export enum NavLinksTitle {
  IRent = 'iRent',
  IBuy = 'iBuy',
  ISell = 'iSell',
  ManageProperty = 'Manage Property',
  Resources = 'Resources',
}

export const navLinks: NavLink[] = [
  {
    title: NavLinksTitle.IRent,
    to: AppRoute.I_RENT,
  },
  {
    title: NavLinksTitle.IBuy,
    to: '/',
  },
  {
    title: NavLinksTitle.ISell,
    to: '/',
  },
];

export const registerDropdownMenuList: NavLink[] = [
  {
    title: 'I am a landlord',
    to: 'auth/sign-up/landlord',
    state: { role: AuthRole.Landlord },
  },
  {
    title: 'I am a renter',
    to: 'auth/sign-up/renter',
    state: { role: AuthRole.Renter },
  },
];

// TODO: need to clarify menu
export const navDropdownMenu: NavLink[] = [
  {
    title: 'Dropdown Menu Item 1',
    to: '/',
  },
  {
    title: 'Dropdown Menu Item 2',
    to: '/',
  },
  {
    title: 'Dropdown Menu Item 3',
    to: '/',
  },
  {
    title: 'Dropdown Menu Item 4',
    to: '/',
  },
  {
    title: 'Dropdown Menu Item 5',
    to: '/',
  },
];
