import clsx from 'clsx';
import { PropertyPlan, PropertyUnitType } from 'common/enums/enums';
import { Button } from 'legacy-components/componets';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import useLandlordPropertyStore from 'store/use-landlord-property-store';
import { formatPriceValue } from 'helpers/string.helpers';

dayjs.extend(advancedFormat);

export interface IPlanCardProps {
  id: PropertyPlan;
  popular: boolean;
  name: string;
  duration: string;
  price: number;
  unitCount: number;
  type: PropertyUnitType;
  planIncludes?: Array<{ title: string }>;
  comingSoon?: boolean;
  action: () => void;
  freePeriodUntilDate?: string;
  // AX-609
  cardClassName?: string;
}

const PlanCard = ({
  id,
  name,
  duration,
  price,
  action,
  popular,
  comingSoon,
  planIncludes,
  unitCount,
  type,
  freePeriodUntilDate,
  // AX-609
  cardClassName,
}: IPlanCardProps) => {
  const isLoading = useLandlordPropertyStore((state) => state.isUpdatingPropertyPlanLoading);
  const selectedPlan = useLandlordPropertyStore((state) => state.selectedPlan);

  return (
    <div
      className={clsx(
        'w-[240px] relative px-5 pt-[50px] pb-[30px] bg-white rounded border-2 border-zinc-100 flex-col justify-center items-center gap-2.5 inline-flex transition cursor-pointer',
        comingSoon && 'opacity-40 cursor-pointer pointer-events-none', cardClassName // AX-609
      )}
      onClick={action}
    >
      {popular ? (
        <div className='pt-2.5 flex-col justify-center items-center gap-2.5 flex mt-[-30px] mb-[23px]'>
          <div className='px-2.5 py-0.5 bg-indigo-50 rounded-3xl justify-end items-center gap-1.5 inline-flex'>
            <div className='text-center text-indigo-500 text-xs font-medium leading-tight'>Most popular</div>
          </div>
        </div>
      ) : (
        <div className='mt-7'></div>
      )}
      <div className='text-center text-neutral-800 text-[14px] font-bold uppercase leading-tight'>{name}</div>
      <div className='text-center text-primaryDark text-[14px] font-medium min-h-[42px]'>{duration}</div>
      <div className='text-center text-neutral-800 text-[32px] font-medium leading-10'>
        ${formatPriceValue(price * unitCount)}
      </div>
      {type === PropertyUnitType.Multiple && (
        <div className='text-center text-warmGray text-sm font-regular'>${formatPriceValue(price)} / unit</div>
      )}
      <div className='self-stretch px-2 py-1 bg-sky-50 rounded-xl justify-center items-center gap-1 inline-flex'>
        <div className='text-primaryDark text-xs font-bold'>Instant Self-Tour AxxelTour™</div>
      </div>
      <div className='self-stretch min-h-[80px] pt-3 flex-col justify-center items-center flex'>
        {planIncludes &&
          planIncludes.map((el) => {
            return (
              <div key={id + el.title} className='self-stretch justify-center items-center inline-flex'>
                <div className='text-dark text-xs font-normal'>{el.title}</div>
              </div>
            );
          })}
      </div>

      <div className='pt-5 flex-col justify-center items-center gap-2.5 flex'>
        {comingSoon ? (
          <div className='w-[155px] h-11 px-8 py-3 bg-sky-50 rounded-lg border border-blue-100 justify-center items-center gap-2.5 inline-flex'>
            <div className='text-justify text-sky-300 text-[14px] font-bold leading-tight flex-shrink-0'>
              Coming Soon
            </div>
          </div>
        ) : (
          <Button
            label={'Get Started'}
            theme={'primary'}
            isLoading={selectedPlan.toLocaleLowerCase() === id.toLocaleLowerCase() && isLoading}
          />
        )}
        {freePeriodUntilDate && (
          <p className='text-trueGray text-center italic'>
            Enjoy free access until <span className='font-bold'>{dayjs(freePeriodUntilDate).format('MMMM Do')}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export { PlanCard };
