import { PaymentCardDto, PaymentCardsDto } from 'common/types/services/api/payment/payment-cards-dto.type';
import { Loader, RadioButton } from 'legacy-components/componets';
import { FC } from 'react';
import { PaymentCardsListItem } from './item';

type Props = {
  list?: PaymentCardsDto | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  getCheckedValue: (item: PaymentCardDto) => boolean
};

const PaymentCardsListForm: FC<Props> = ({ list, onChange, getCheckedValue }) => {
  if (!list) {
    return <Loader />;
  }

  return (
    <div className='flex flex-col gap-3 max-h-64 overflow-y-auto py-3'>
      {list.map((el, idx) => {
        return (
          <RadioButton
            key={`${idx}_${el.last}_${el.month}_${el.year}`}
            label={<PaymentCardsListItem item={el} />}
            name={el.brand}
            value={el.id}
            checked={getCheckedValue(el)}
            onChange={onChange}
            stylesConfig={{
              labelStyle: {
                width: "90%"
              }
            }}
          />
        );
      })}
    </div>
  );
};

export { PaymentCardsListForm };
