import * as Yup from 'yup';
const validFile = Yup.array().of(
  Yup.object().shape({
    file: Yup
      .mixed<File | string>()
      .test('file-type', 'Only PDF files are allowed', value => {
        if (typeof value === 'string') {
          return true; // For string type, no further validation needed
        }
        return value instanceof File && value.type === 'application/pdf';
      })
      .test('file-size', '75 MB is the maximum allowed size for each file', value => {
        if (typeof value === 'string') {
          return true; // For string type, no further validation needed
        }
        return value instanceof File && value.size <= 75 * 1024 * 1024;
      }),
    fileName: Yup.string().required('File name is required'),
  })
);

export const propertyLeaseValidationSchema = Yup.object({
  leaseId: Yup.string().notRequired(),
  files: validFile.when('leaseId', (leaseId, schema) => {
    return leaseId.at(0) ? schema.notRequired() :
      schema.required().min(1, 'At least 1 file is required, choose or upload file')
  }),
  link: Yup.string().notRequired(),
  checkbox: Yup.bool(),
  title: Yup.string().required()
    .when('checkbox', (checkbox, schema) => {
      return checkbox.at(0) ? schema.required('Title is required when checkbox is selected') : schema.notRequired();
    }),
  chosenNewFile: Yup.bool(),
  canBeSubmitted: Yup.bool().test('can-be-submitted', 'Please choose one of lease documents', function (value) {

    const leaseId = this.resolve(Yup.ref('leaseId')) as string;
    const chosenNewFile = this.resolve(Yup.ref('chosenNewFile')) as boolean;

    if ((leaseId && leaseId !== '') || chosenNewFile === true) return true;
    else return false;
  }).test('can-be-submitted-by-title', 'Please input title of document', function (value) {

    const title = this.resolve(Yup.ref('title')) as string;
    const checkbox = this.resolve(Yup.ref('checkbox')) as boolean;

    if ((checkbox === true && (title && title !== '')) || checkbox === false) return true;
    else return false;
  })
});
