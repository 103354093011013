import { FC } from "react";
import { EstateCardTypeDetailsProps } from "./types";
import { EstateCardCategoryBadge } from "./EstateCardCategoryBadge";
import { EstateTypeDto } from "common/enums/enums";
import { EstateCardUnitsBadge } from "./EstateCardUnitsBadge";

const isMultiUnit = (type: EstateTypeDto) => type === EstateTypeDto.MultiUnit;

export const EstateCardTypeDetails: FC<EstateCardTypeDetailsProps> = ({ category, units, type }) => {
    return (
        <div className='flex gap-1'>
            <EstateCardCategoryBadge category={category} />
            {isMultiUnit(type) ? <EstateCardUnitsBadge units={units} /> : null}
        </div>
    )
}