import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { UserDetailsDto } from 'common/types/services/api/user/user-details-dto.type';
import { getUserProfile } from 'services/api/auth-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useUserProfile(
  options?: Omit<UndefinedInitialDataOptions<UserDetailsDto, Error, UserDetailsDto, string[]>, 'queryFn' | 'queryKey'>,
) {
  return useQuery({
    queryKey: [QueryKey.User, 'profile'],
    queryFn: () => getUserProfile(),
    ...options,
  });
}
