
import { NotificationType } from 'common/enums/enums';
import { TypeOptions } from 'react-toastify/dist/types';

const getNotificationMessageColorClass = (notificationType?: TypeOptions) => {
  switch (notificationType) {
    case NotificationType.SUCCESS:
      return 'text-[#05A660]';
    case NotificationType.ERROR:
      return 'text-[#E53535]';
    case NotificationType.INFO:
      return 'text-[#0996CE]';
    case NotificationType.WARNING:
      return 'text-[#FF8800]';
    default:
      return 'text-[#0996CE]';
  }
};

export { getNotificationMessageColorClass };
