import { RadioButtonTheme } from 'common/types/types';

const styles: Record<RadioButtonTheme, { container: string; label: string; input: string; error: string }> = {
  default: {
    container: 'flex items-center gap-2 h-11',
    label: 'text-sm font-semibold text-black cursor-pointer',
    input: 'h-5 w-5 checked:bg-primary text-primary cursor-pointer',
    error: 'border-error focus:border-error',
  },
  primary: {
    container: 'flex items-center gap-2 h-11',
    label: 'text-sm font-semibold text-black cursor-pointer',
    input: 'h-[18px] w-[18px] checked:bg-primary text-primary cursor-pointer',
    error: 'border-b-error focus:border-b-error',
  },
};

export default styles;
