import { SavedSearchQueryParamConfig, SavedSearchTableRow } from './saved-search-table/SavedSearchTable.types';
import { useMemo } from 'react';
import { TablePaginationProps } from 'common/types/types';
import { SavedSearchEstate } from 'common/types/services/api/user/user-saved-search.type';
import { NumberParam, withDefault } from 'use-query-params';
import SortOrderEnumParam from 'query-params/sortOrderEnumParam';

export const useQueryParamConfig = () => {
  return useMemo<SavedSearchQueryParamConfig>(
    () => ({
      order: withDefault(SortOrderEnumParam, 'desc'),
      page: withDefault(NumberParam, 1),
    }),
    [],
  );
};

export const useCreateTablePaginationProps = (
  currentPage: number,
  totalCount: number | undefined,
  maxItemPerPage: number,
  setCurrentPage: (newPage: number) => void,
): TablePaginationProps =>
  useMemo(() => {
    return {
      currentPage,
      totalCount: totalCount || 0,
      pageSize: maxItemPerPage || 5,
      onPageChange: setCurrentPage,
    };
  }, [currentPage, totalCount, maxItemPerPage]);

type SavedSearchActionType = (item: SavedSearchEstate) => void;

export const createSavedSearchTableData = (
  savedSearches: SavedSearchEstate[],
  onViewEstate: SavedSearchActionType,
  onDeleteEstate: SavedSearchActionType,
): SavedSearchTableRow[] => {
  return savedSearches.map((estate) => {
    return {
      date: new Date(estate?.postedAt),
      property: {
        address: estate?.location,
        price: estate?.price,
        priceMin: estate?.priceMin,
        priceMax: estate?.priceMax,
        image: estate?.thumbnailUrl,
      },
      actions: {
        onView: () => onViewEstate(estate),
        onDelete: () => onDeleteEstate(estate),
      },
    };
  });
};
