import { FormikProps } from 'formik';
import { Loader, PlanCard } from 'legacy-components/componets';
import { PropertyValues } from 'legacy-pages/landlord/properties/landlord-properties';
import { EstateStatusDto, PropertyUnitType } from 'common/enums/enums';
import { useGetPlans } from 'hooks/query';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { checkFreePeriodDates } from 'common/utils/check-free-trial-period';

export type PropertyPlansProps = Pick<FormikProps<PropertyValues>, 'setFieldValue' | 'submitForm' | 'values'> & {
  stepTitle?: string;
  propertyId?: string;
  estateStatus: EstateStatusDto | null;
};

const PropertyPlans = ({ stepTitle, setFieldValue, submitForm, values, estateStatus }: PropertyPlansProps) => {
  const { data, isLoading } = useGetPlans();
  const freeTrialGeneralDate = useMemo(() => checkFreePeriodDates(data), [data]);

  if (isLoading || !data) return <Loader />;

  return (
    <>
      <div className='property-basics flex flex-col gap-9'>
        <div className='title flex flex-col gap-3'>
          {stepTitle && <div className='text-primaryDark uppercase font-semibold'>{stepTitle}</div>}
          <div className='title text-xl font-medium'>Select a pricing plan</div>
          {values.basics.type === PropertyUnitType.Multiple && (
            <div className='text-primary text-base font-bold'>You have chosen {values.basics.unitCount} units</div>
          )}
        </div>
        {freeTrialGeneralDate && (
          <div className='p-4 bg-secondary m-3 text-base text-center rounded-xl'>
            Exciting news: All subscription plans are now{' '}
            <span className='font-bold text-primaryDark'>
              free until {dayjs(freeTrialGeneralDate).format('MMMM Do')}
            </span>
            ! Unlock the full potential of our services without any charges.
          </div>
        )}
        <div className='flex flex-wrap gap-5 justify-center'>
          {data?.map((plan) => {
            const planIncludes: { title: string }[] = plan?.description
              .split(';')
              .map((item) => ({ title: item.trim() }));
            // AX-609
            const getBorderStyle = (
              status: EstateStatusDto,
              planId: string | undefined,
              valuesPlan: string | undefined,
            ): string => {
              if (status === EstateStatusDto.Available) {
                if (planId === valuesPlan) {
                  return 'border-primary';
                } else {
                  return 'pointer-events-none';
                }
              } else {
                return 'hover:border-primary hover:border-2';
              }
            };
            return (
              <PlanCard
                key={plan.id}
                unitCount={values.basics.unitCount}
                type={values.basics.type}
                price={plan?.amount}
                name={plan?.id}
                duration={plan?.duration}
                popular={plan?.isPopular}
                planIncludes={planIncludes}
                id={plan?.id}
                action={() => {
                  setFieldValue('plan', plan.id);
                  submitForm();
                }}
                freePeriodUntilDate={!freeTrialGeneralDate ? plan?.freePeriodUntilDate : undefined}
                cardClassName={estateStatus ? getBorderStyle(estateStatus, plan?.id, values?.plan) : ''}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export { PropertyPlans };
