import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { createOffer } from 'services/api/offers-api.service';
import { CreateOfferRequestDtoWithAddress } from 'common/types/services/api/offers/offer-create-request-dto.type';
import { notification } from 'services/services';
import { QueryKey } from 'common/enums/query-key';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';

export default function useCreateOffer(
  options?: Omit<UseMutationOptions<string, AxiosError<BackendError>, CreateOfferRequestDtoWithAddress, unknown>, 'mutationFn'>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ address, ...query }) => createOffer(query),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Offers] });
    },
    onSuccess: (data, { address }) => {
      const message = address && address !== '' ? `Offer is created (${address})` : 'Offer is created';
      notification.success(message);
    },
    onError: (e) => {
      notification.error(e?.response?.data?.detail || 'The offer has not been created');
    },
    ...options,
  });
}
