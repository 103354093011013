import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { startTour } from 'services/api/tours-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useStartTour(options?: UseMutationOptions<boolean, Error, string, unknown>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: startTour,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Tours] });
    },
  });
}
