import { useFormik } from 'formik';
import { InputProps, InvestorSignInRequestDto } from 'common/types/types';
import { Button, Icon, Input, Modal, PhoneInput } from 'legacy-components/componets';
import * as React from 'react';
import { investorSignUpValidationSchema } from 'validations/investor-modal.validation';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useSignInInvestor } from 'hooks/query';

export interface ISignInInvestorValues {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string;
  companyWebSite: string;
  checkIfInvestor: 'yes' | 'no' | '';
}

export const InvestorModalWindow = () => {
  const [cookies] = useCookies(['user-access-key']);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const navigate = useNavigate();
  const { mutate: signInInvestorMutation } = useSignInInvestor();
  const submit = (values: ISignInInvestorValues) => {
    const request: InvestorSignInRequestDto = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      companyName: values.companyName,
      phoneNumber: values.phone,
      companyWebsite: values.companyWebSite,
      isInvestor: values.checkIfInvestor === 'yes' ? true : false,
    };
    signInInvestorMutation(request);
  };
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik<ISignInInvestorValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      companyName: '',
      companyWebSite: '',
      checkIfInvestor: '',
    },
    validationSchema: investorSignUpValidationSchema,
    onSubmit: (values: ISignInInvestorValues) => submit(values),
  });

  const getCommonInputProps = (
    name: keyof Omit<ISignInInvestorValues, 'checkIfInvestor'>,
    label: string,
    placeholder: string,
  ): InputProps => {
    return {
      name,
      label,
      placeholder,
      theme: 'default',
      value: values[name],
      onChange: ({ target: { value } }) => setFieldValue(name, value),
      invalid: Boolean(errors[name] && touched[name]),
    };
  };

  const showError = (fieldName: keyof ISignInInvestorValues): boolean => {
    return Boolean(errors[fieldName] && touched[fieldName]);
  };

  const [button1Active, setButton1Active] = React.useState(false);
  const [button2Active, setButton2Active] = React.useState(false);

  const handleButton1Click = () => {
    setButton1Active(true);
    setButton2Active(false);
    setFieldValue('checkIfInvestor', 'yes');
  };

  const handleButton2Click = () => {
    setButton2Active(true);
    setButton1Active(false);
    setFieldValue('checkIfInvestor', 'no');
  };

  const { isMobile } = useWindowSize();

  React.useEffect(() => {
    if (cookies['user-access-key']) {
      navigate('/');
    }
  }, [cookies['user-access-key']]);

  return (
    <Modal
      isOpen={true}
      styles={{
        content: { borderRadius: '20px', maxHeight: '100%', padding: '40px 0', ...(isMobile && { maxHeight: '100%' }) },
        overlay: { backgroundColor: 'white' },
      }}
      isMobile={isMobile}
    >
      <div className='flex flex-col p-5 bg-[#F1FBFF] gap-2 max-w-[700px]'>
        <p className='text-[30px] text-primaryDark'>Welcome to Axxelist!</p>
        <label className='w-full text-justify text-[15.5px] text-[#737373]'>
          Thank you for your interest in our company and the desire to learn more about our vision and offerings. Please
          complete the form below and we’ll reach out to you to discuss your interest.
        </label>
        {errorMessage && (
          <div
            className={`text-sm text-error p-2 bg-red-100 rounded-lg flex justify-between ${
              errorMessage ? 'mt-4 mb-4' : ''
            }`}
          >
            <p>{errorMessage}</p>
            <div className='cursor-pointer' onClick={() => setErrorMessage('')}>
              <Icon name={'times-circle'} />
            </div>
          </div>
        )}
        <form className={`flex flex-col mb-6}`} onSubmit={handleSubmit}>
          <div
            className={`flex md:flex-row flex-col gap-4 
              ${showError('firstName') || showError('lastName') ? '' : 'mb-6'}`}
          >
            <div className='md:w-1/2 w-full'>
              <Input {...getCommonInputProps('firstName', 'First Name', 'First Name')} />
              {showError('firstName') && <p className='text-sm text-error py-1'>{errors.firstName}</p>}
            </div>
            <div className='md:w-1/2 w-full'>
              <Input {...getCommonInputProps('lastName', 'Last Name', 'Last Name')} />
              {showError('lastName') && <p className='text-sm text-error py-1'>{errors.lastName}</p>}
            </div>
          </div>
          <div
            className={`flex md:flex-row flex-col gap-4 
              ${showError('companyName') ? '' : 'mb-6'}`}
          >
            <div className='w-full'>
              <Input {...getCommonInputProps('companyName', 'Company Name', 'e.g PerfectHouse')} />
              {showError('companyName') && <p className='text-sm text-error py-1'>{errors.companyName}</p>}
            </div>
          </div>
          <div
            className={`flex md:flex-row flex-col gap-4 
              ${showError('email') ? '' : 'mb-6'}`}
          >
            <div className='md:w-1/2 w-full'>
              <Input {...getCommonInputProps('email', 'Business Email address', 'hi@example.com')} />
              {showError('email') && <p className='text-sm text-error py-1'>{errors.email}</p>}
            </div>
            <div className={`md:w-1/2 w-full ${showError('phone') ? '' : 'mb-6'}`}>
              <label className={'block mb-1 text-sm font-medium text-black'}>{'Phone Number'}</label>
              <PhoneInput
                isValid={!showError('phone')}
                value={values.phone}
                onChange={(value, data, event, formattedValue) => {
                  setFieldValue('phone', value);
                }}
              />
              {showError('phone') && <p className='text-sm text-error py-1'>{errors.phone}</p>}
            </div>
          </div>
          <div
            className={`flex md:flex-row flex-col gap-4 
              ${showError('companyWebSite') ? '' : 'mb-6'}`}
          >
            <div className='w-full'>
              <Input {...getCommonInputProps('companyWebSite', 'Company website', 'e.g PerfectHouse')} />
              {showError('companyWebSite') && <p className='text-sm text-error py-1'>{errors.companyWebSite}</p>}
            </div>
          </div>
          <div className={`flex flex-col gap-4`}>
            <div className='w-full'>
              <label className={'block text-sm font-medium text-black'}>{'Are you an accredited investor?'}</label>
            </div>
            <div className='w-full flex md:flex-row flex-col gap-3'>
              <Button
                className='w-[200px]'
                label={'yes'}
                theme={button1Active ? 'default' : 'gray-outline'}
                onClick={handleButton1Click}
              />
              <Button
                className='w-[200px]'
                label={'no'}
                theme={button2Active ? 'default' : 'gray-outline'}
                onClick={handleButton2Click}
              />
            </div>
            {showError('checkIfInvestor') && <p className='text-sm text-error py-1'>{errors.checkIfInvestor}</p>}
          </div>
          <div className='w-full flex justify-start'>
            <Button className='px-7 mt-3' label={'Submit'} type={'submit'} theme={'black'} onClick={() => {}} />
          </div>
        </form>
      </div>
    </Modal>
  );
};
