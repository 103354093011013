import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { UserByResetPasswordTokenDto } from 'common/types/services/api/user/user-by-reset-passsword-token-dto.type';
import { getUserByResetPasswordToken } from 'services/api/auth-api.service';
import { QueryKey } from 'common/enums/query-key';

export default function useUserByResetPasswordToken(
  token: string,
  options?: Omit<
    UndefinedInitialDataOptions<UserByResetPasswordTokenDto, Error, UserByResetPasswordTokenDto, string[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.User, 'reset-password', token],
    queryFn: () => getUserByResetPasswordToken(token),
    ...options,
  });
}
