import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'legacy-components/componets';
import { SignUpIndependentLandlordForm, SignUpPropertyManagerForm } from 'legacy-pages/pages';
import { LandlordAccountType } from 'common/enums/app/landlord-account-type.enum';
import { ButtonProps } from 'common/types/types';

const SignUpLandlordForms = () => {
  const [activeLandlordType, setActiveLandlordType] = React.useState<LandlordAccountType>(
    LandlordAccountType.Independent,
  );

  const getButtonProps = (label: string, type: LandlordAccountType): ButtonProps => {
    const isButtonActive = type === activeLandlordType;
    return {
      label,
      onClick: () => setActiveLandlordType(type),
      theme: isButtonActive ? 'primary-outline' : 'black-outline',
      stylesConfig: { borderRadius: 8, minWidth: 170, height: 44, padding: 16, wordBreak: "break-word", whiteSpace: "normal" },
    };
  };

  const signUpLandlordFormsContent = (() => {
    switch (activeLandlordType) {
      case LandlordAccountType.Independent:
        return (
          <SignUpIndependentLandlordForm
          />
        );
      case LandlordAccountType.Manager:
        return (
          <SignUpPropertyManagerForm
          />
        );
    }
  })();

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='wrapper md:px-4 px-6 py-6 max-w-[620px]'>
        <div className='w-full text-black text-xl font-semibold text-left mb-1'>List an apartment community</div>
        <div className='w-full text-trueGray text-base font-normal text-left mb-3'>
          Please start filling out your application by filling in your personal information below.
        </div>
        <div className='sign-in-link text-xs mb-3'>
          <span className='font-bold text-trueGray'>Already have an account?</span>
          <span>&nbsp;</span>
          <NavLink to={'/auth/sign-in'} className='text-primary'>
            Log in
          </NavLink>
        </div>
        <div>
          <p className='text-sm font-medium mb-3'>What best describes you?</p>
          <div className='buttons flex gap-3 mb-3'>
            <Button {...getButtonProps('Independent landlord-listing', LandlordAccountType.Independent)} />
            <Button {...getButtonProps('Property Manager', LandlordAccountType.Manager)} />
          </div>
        </div>
        {signUpLandlordFormsContent}
      </div>
    </div>
  );
};

export { SignUpLandlordForms };
