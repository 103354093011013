import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QueryKey } from 'common/enums/query-key';
import { BackendError } from 'common/types/errors/backendError';
import { getTwoFactorState } from 'services/api/auth-api.service';
export default function useGetTwoFactorState(
  options?: Omit<UndefinedInitialDataOptions<boolean, AxiosError<BackendError>, boolean>, 'queryFn' | 'queryKey'>,
) {
  return useQuery({
    queryKey: [QueryKey.User, 'two-factor-auth'],
    queryFn: () => getTwoFactorState(),
    ...options,
  });
}
