import { EstateListDtoStatusEnum } from "common/generated/types/estate-list-dto";

export type LanlordCellProps = string;
export type LocationCellProps = string;
export type CategoryCellProps = string;
export type EstateTypeCellProps = string;
export type EstateStatusCellProps = EstateListDtoStatusEnum
export type EstateDateCellProps = Date;
export type AdminAction = () => void;

export type AdminActionsCell = {
    onView: AdminAction;
    updateToNew: AdminAction
    onApprove: AdminAction;
    onCancel: AdminAction;
    status: EstateListDtoStatusEnum,
    canRejectEstate: boolean
};

export enum AdminEstateTableAccessorEnum {
    Landlord = 'landlord',
    Location = 'location',
    Category = 'category',
    Type = 'type',
    Date = 'date',
    Status = 'status',
    Actions = 'actions',
};

export type AdminEstateTableRow = {
    [AdminEstateTableAccessorEnum.Landlord]: LanlordCellProps;
    [AdminEstateTableAccessorEnum.Location]: LocationCellProps;
    [AdminEstateTableAccessorEnum.Category]: CategoryCellProps;
    [AdminEstateTableAccessorEnum.Type]: EstateTypeCellProps;
    [AdminEstateTableAccessorEnum.Date]: EstateDateCellProps;
    [AdminEstateTableAccessorEnum.Status]: EstateStatusCellProps;
    [AdminEstateTableAccessorEnum.Actions]: AdminActionsCell;
};

