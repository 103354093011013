import {
  PropertyCategory,
  PropertyDtoCategory,
  PropertyDtoType,
  PropertyUnitsCountType,
  PropertyUnitType,
} from 'common/enums/app/property.enum';
import { IconSVGName } from 'common/types/types';
import { IPropertyBasicValues } from './property-basics';

const unitsTitle: Record<PropertyUnitType, string> = {
  [PropertyUnitType.Single]: 'Single unit',
  [PropertyUnitType.Multiple]: 'Multiple unit',
};

const unitsIconName: Record<PropertyUnitType, IconSVGName> = {
  [PropertyUnitType.Single]: 'singleFamilyHouse',
  [PropertyUnitType.Multiple]: 'condo',
};

const getUnits = (unit: PropertyUnitType) => ({
  id: unit,
  title: unitsTitle[unit],
  icon: unitsIconName[unit],
});

export const unitList = Object.values(PropertyUnitType).map(getUnits);

const unitsCountTypeTitle: Record<PropertyUnitsCountType, string> = {
  [PropertyUnitsCountType.OneToTen]: '1-10',
  [PropertyUnitsCountType.ElevenToTwenty]: '11-20',
  [PropertyUnitsCountType.OverTwenty]: '+20',
};

const getCountUnitType = (type: PropertyUnitsCountType) => ({
  id: type,
  title: unitsCountTypeTitle[type],
});

export const unitCountTypeList = Object.values(PropertyUnitsCountType).map(getCountUnitType);

export const categoryTitle: Record<PropertyCategory, string> = {
  // [PropertyCategory.SingleFamilyHouse]: 'Single-Unit',
  // [PropertyCategory.MultiFamilyHouse]: 'Multi-Unit',
  [PropertyCategory.Townhouse]: 'Townhouse',
  [PropertyCategory.Apartment]: 'Apartment',
  [PropertyCategory.Condo]: 'Condo',
  [PropertyCategory.House]: 'House',
};

export const categoryIconName: Record<PropertyCategory, IconSVGName> = {
  // [PropertyCategory.SingleFamilyHouse]: 'singleFamilyHouse',
  // [PropertyCategory.MultiFamilyHouse]: 'multiFamilyHouse',
  [PropertyCategory.Townhouse]: 'townhouse',
  [PropertyCategory.Condo]: 'condo',
  [PropertyCategory.Apartment]: 'apartment',
  [PropertyCategory.House]: 'tinyHome',
};

const getCategory = (unit: PropertyCategory) => ({
  id: unit,
  title: categoryTitle[unit],
  icon: categoryIconName[unit],
});

export const categoryList = Object.values(PropertyCategory).map(getCategory);

export const unitsTypeToDtoType: Record<PropertyUnitType, PropertyDtoType> = {
  [PropertyUnitType.Single]: PropertyDtoType.Single,
  [PropertyUnitType.Multiple]: PropertyDtoType.Multiple,
};

export const categoryTypeToDtoCategoryType: Record<PropertyCategory, PropertyDtoCategory> = {
  // [PropertyCategory.SingleFamilyHouse]: PropertyDtoCategory.SingleFamilyHouse,
  // [PropertyCategory.MultiFamilyHouse]: PropertyDtoCategory.MultiFamilyHouse,
  [PropertyCategory.Townhouse]: PropertyDtoCategory.Townhouse,
  [PropertyCategory.Apartment]: PropertyDtoCategory.Apartment,
  [PropertyCategory.Condo]: PropertyDtoCategory.Condo,
  [PropertyCategory.House]: PropertyDtoCategory.House,
};

export const defaultPropertyBasicValues: IPropertyBasicValues = {
  type: PropertyUnitType.Single,
  unitCount: 1,
  category: PropertyCategory.Townhouse,
  location: {
    address: '',
    street: '',
    city: '',
    state: '',
    zipcode: '',
    lng: undefined,
    lat: undefined,
  },
};
