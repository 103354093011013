import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from 'legacy-pages/renter/schedule-tour-checkout/checkout-form';
import { PayForRescheduleTourProps } from './PayForResheduleTour.types';
import { loadStripe } from '@stripe/stripe-js';
import { ENV } from 'common/enums/enums';
import { FC, useState } from 'react';
import { SUCCESS_MSG } from './PayForResheduleTour.const';
import { Modal } from 'legacy-components/componets';
import { CloseOutlined } from '@ant-design/icons';
import { useRescheduleTourPayment, usePostRescheduleTourPaymentStatus } from 'hooks/query';

export const PayForRescheduleTour: FC<PayForRescheduleTourProps> = ({ open, onClose, estateId, unitId, tourId }) => {
  const [isStripeLoading, setIsStripLoading] = useState<boolean>(true);
  const { data } = useRescheduleTourPayment({ estateId, unitId });
  const { secret: resheduleTourPaymentId = '', sum = 0 } = data || {};

  const { mutate: postRescheduleTourPaymentStatus } = usePostRescheduleTourPaymentStatus();

  const stripePromise = loadStripe(ENV.REPLENISH_PUBLIC_KEY);
  const options = {
    clientSecret: resheduleTourPaymentId,
  };

  const submitPayment = () => {
    postRescheduleTourPaymentStatus(tourId);
    onClose(false);
  };

  return (
    <Modal isOpen={open} onClose={() => onClose(false)}>
      <div className='p-5 min-w-[332px]'>
        <div className='flex justify-end'>
          <button onClick={() => onClose(false)} type='button'>
            <CloseOutlined className={`text-primary text-[22px]`} />
          </button>
        </div>
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            clientSecret={resheduleTourPaymentId}
            onCloseModal={() => onClose(false)}
            isStripeLoading={isStripeLoading}
            setIsStripLoading={setIsStripLoading}
            successMessage={SUCCESS_MSG}
            callback={submitPayment}
            paidSum={sum}
          />
        </Elements>
      </div>
    </Modal>
  );
};
