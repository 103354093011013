import React, { Fragment, useMemo, useState } from 'react';
import { TourDto } from 'common/types/types';
import { maxItemPerPage } from './config';
import { UpcomingToursTable } from './components/upcoming-tours-table/upcoming-tours-table';
import { TablePagination, TablePaginationProps } from 'legacy-components/ui/table/table-pagination';
import { ToursTableRow } from './components/upcoming-tours-table/common/types/tours-table-row';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownMenu, TourCard } from 'legacy-components/componets';
import { CollectKeyModal, MakeOfferModal } from 'legacy-components/modals/modals';
import { fromRentTourDtoToEstateCard } from 'common/mappers/tour';
import { getDayjs } from 'helpers/date';
import { ToursTitle } from './components';
import { TourCardProps } from './types/tour-card-props.type';
import { useWindowSize } from 'hooks';
import { CancelTourModal } from 'legacy-components/cancel-tour-modal/cancel-tour-modal';
import dayjs from 'dayjs';
import { createEnumParam, NumberParam, QueryParamConfig, useQueryParams, withDefault } from 'use-query-params';
import SortOrderEnumParam from 'query-params/sortOrderEnumParam';
import { SortOrder } from 'common/types/sort-order';
import { EstateTypeDto, TourStatusDto } from 'common/enums/enums';
import { RescheduleTourModal } from './components/reschedule-tour-modal';
import { omit } from 'lodash-es';
import { DropdownMenuItem } from 'legacy-components/dropdown-menu/common/types/dropdown-menu-item';
import { getNewCurrentPage } from 'helpers/pagination';
import {
  useEstateDetails,
  useTourKeyInfo,
  useUpcomingTours,
  useTourDetails,
  useCancelTour,
  useStartTour,
  useRescheduleTour,
} from 'hooks/query';
import { TourStatus } from 'components/atoms/TourStatus/TourStatus';
import { formatPriceValue } from 'helpers/string.helpers';
import defaultEstatePhoto from 'assets/images/default_estate_image.png';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import { useUnverifiedProfileActionGuard } from 'hooks/use-unverified-profile-guard';
import { RenterTourPaymentCountdown } from 'components/molecules/RenterTourPaymentCountdown/RenterTourPaymentCountdown';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';

export type UpcomingToursQueryParamConfig = {
  sortBy: QueryParamConfig<'date'>;
  order: QueryParamConfig<SortOrder>;
  page: QueryParamConfig<number>;
};

const UpcomingTours = () => {
  const queryParamConfig = useMemo<UpcomingToursQueryParamConfig>(
    () => ({
      sortBy: withDefault(createEnumParam(['date']), 'date'),
      order: withDefault(SortOrderEnumParam, 'asc'),
      page: withDefault(NumberParam, 1),
    }),
    [],
  );
  const [isMakeOfferOpen, setIsMakeOfferOpen] = React.useState<boolean>(false);
  const [isCollectKeyModalOpen, setIsCollectKeyModalOpen] = React.useState<boolean>(false);
  const [cancelTourModalOpen, setCancelTourModalOpen] = React.useState(false);
  const [makeOfferTour, setMakeOfferTour] = useState<TourDto | null>(null);
  const { data: makeOfferTourEstateDetails } = useEstateDetails(makeOfferTour?.estateId);
  const [query, setQuery] = useQueryParams(queryParamConfig);
  const [rescheduleTourModalOpen, setRescheduleTourModalOpen] = useState(false);
  const [tourIdToReschedule, setTourIdToReschedule] = useState('');
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const [selectedTourId, setSelectedTourId] = useState<string>();
  const queryClient = useQueryClient();
  const { data: keyInfo } = useTourKeyInfo(selectedTourId, { enabled: !!selectedTourId });
  const {
    data: upcomingTours,
    isLoading: isUpcomingToursLoading,
    isFetching: isUpcomingToursFetching,
    refetch: refetchUpcomingTours,
  } = useUpcomingTours(
    {
      page: query.page,
      perPage: maxItemPerPage,
      sorters: { slot: query.order },
    },
    {
      placeholderData: (previousValue) => previousValue,
    },
  );

  const { withActionGuard } = useUnverifiedProfileActionGuard({
    modalProps: {
      actionText: 'make an offer',
    },
  });

  const total = upcomingTours?.totalCount || 0;
  const tours = upcomingTours?.items || [];
  const tourToReschedule = useMemo<TourDto | undefined>(
    () => (tourIdToReschedule ? tours.find((t) => t.id === tourIdToReschedule) : undefined),
    [tourIdToReschedule],
  );
  const { data: nearestTodayTour, refetch: refetchNearestTodayTour } = useUpcomingTours(
    {
      page: 1,
      perPage: 1,
      sorters: { slot: 'asc' },
      filters: {
        slotUtc: [
          `>=${dayjs().utc().format('YYYY-MM-DDT00:00:00[Z]')}]`,
          `<=${dayjs().utc().format('YYYY-MM-DDT23:59:59[Z]')}]`,
        ],
      },
    },
    { select: (data) => data.items[0] },
  );
  const { data: nextTourDetails, isLoading: isNextTourDetailsLoading } = useTourDetails(nearestTodayTour?.id || '', {
    enabled: !!nearestTodayTour?.id,
  });
  const { mutateAsync: cancelTour, isPending: isCancelingTourPending } = useCancelTour();
  const { mutate: startTour, isPending: isStartingTourPending } = useStartTour();
  const { mutate: rescheduleTour, isPending: isReschedulingTourPending } = useRescheduleTour();

  const paginationCallback = (newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  };

  const handleViewTour = React.useCallback(
    (tourId: string) => {
      navigate(`/i-rent/profile/tours/${tourId}`);
    },
    [navigate],
  );

  const handleCancelTour = React.useCallback(
    async (tourId: string) => {
      const newCurrentPage = getNewCurrentPage(total, maxItemPerPage, query.page);
      await cancelTour(tourId);
      setQuery((prev) => ({ ...prev, page: newCurrentPage }));
    },
    [query.page, total, maxItemPerPage, query],
  );

  const handleOfferTour = React.useCallback(
    (tour: TourDto) => {
      withActionGuard(() => {
        setMakeOfferTour(tour);
        setIsMakeOfferOpen(true);
      });
    },
    [withActionGuard],
  );

  const handleCollectKey = (tourId: string) => {
    setSelectedTourId(tourId);
    setIsCollectKeyModalOpen(true);
  };

  const onPaymentCountdownFinish = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.Tours] });
  }

  const isAvailableToStartTour = useMemo(
    () =>
      dayjs().isBetween(
        dayjs(nextTourDetails?.slotUtc).subtract(15, 'minutes'),
        dayjs(nextTourDetails?.slotUtc).add(60, 'minutes'),
        null,
        '[]',
      ),
    [nextTourDetails],
  );

  const editTourFn = (tourId: string) => {
    setRescheduleTourModalOpen(true);
    setTourIdToReschedule(tourId);
  };

  const tableData = React.useMemo<ToursTableRow[]>(() => {
    return tours.map((tour) => {
      return {
        date: new Date(tour.slot),
        property: {
          city: tour.city,
          address: tour.location,
          price: tour.price,
          image: tour.thumbnailUrl,
          unitNumber: tour.unitNumber,
          isMulti: tour.estateType === EstateTypeDto.MultiUnit,
        },
        status: {
          tourStatus: tour.tourStatus,
        },
        actions: {
          onView: () => handleViewTour(tour.id),
          onEdit: () => editTourFn(tour.id),
          onCancel: () => handleCancelTour(tour.id),
          onOffer: () => handleOfferTour(tour),
          id: tour.id,
          status: tour?.tourStatus,
          scheduleAt: tour?.scheduleAt,
        },
      };
    });
  }, [tours, handleViewTour, handleCancelTour, handleOfferTour]);

  const tablePaginationProps = useMemo<TablePaginationProps>(() => {
    return {
      currentPage: query.page,
      totalCount: total,
      pageSize: maxItemPerPage,
      onPageChange: paginationCallback,
    };
  }, [query.page, total, maxItemPerPage]);

  const tourCardActions = React.useMemo<TourCardProps['actions']>(() => {
    return {
      onView: () => handleViewTour(nearestTodayTour?.id || ''),
      onEdit: () => editTourFn(nearestTodayTour?.id || ''),
      onCancel: () => handleCancelTour(nearestTodayTour?.id || ''),
      onOffer: () => handleOfferTour(nearestTodayTour as TourDto),
      onStartTour: startTour,
      onCollectKey: handleCollectKey,
    };
  }, [nearestTodayTour, handleViewTour, handleCancelTour, handleOfferTour, handleCollectKey]);

  const handleCloseMakeOfferModal = () => setIsMakeOfferOpen(false);
  const handleCloseCollectKeyModal = () => setIsCollectKeyModalOpen(false);

  const propertyCard = (el: ToursTableRow, key: number) => {
    const menuItems: (DropdownMenuItem & { visible?: boolean })[] = [
      {
        id: 1,
        title: 'View/edit details',
        onClick: () => el.actions.onView(),
        icon: 'house',
      },
      {
        id: 2,
        title: (
          <div className='flex justify-between items-center w-full'>
            Pay
            <RenterTourPaymentCountdown
              scheduleAt={el.actions.scheduleAt}
              valueStyle={{
                fontSize: 18,
              }}
              onFinish={onPaymentCountdownFinish}
            />
          </div>
        ),
        icon: 'credit-card',
        visible: el.actions.status === TourStatusDto.Reserved,
        onClick: () => {
          navigate(`/i-rent/schedule-tour/checkout/${el.actions.id}`);
        },
      },
      {
        id: 3,
        title: 'Edit time and date',
        icon: 'calendar',
        visible: el.actions.status === TourStatusDto.Scheduled || el.actions.status === TourStatusDto.Reserved,
        onClick: () => el.actions.onEdit(),
      },
      {
        id: 4,
        title: 'Cancel self-tour',
        visible: el.actions.status !== TourStatusDto.InProgress,
        onClick: () => setCancelTourModalOpen(true),
        icon: 'shape',
      },
      {
        id: 5,
        title: 'Make an offer',
        onClick: () => el.actions.onOffer(),
        icon: 'handshake',
      },
    ];
    const visibleMenuItems: DropdownMenuItem[] = menuItems
      .filter((o) => o.visible === undefined || o.visible)
      .map((o) => omit(o, 'visible'));

    const imageSrc = isNotEmptyString(el?.property?.image) ? el?.property?.image : defaultEstatePhoto;
    return (
      <Fragment key={key}>
        <div className='shadow-lg rounded-lg flex flex-col relative'>
          <Dropdown
            target={
              <button
                id='dropdownButton'
                data-dropdown-toggle='dropdown'
                className='inline-block text-gray-500 hover:bg-gray-100 focus:outline-none rounded-lg text-sm p-1.5 absolute bg-white opacity-80 top-2 right-2'
                type='button'
              >
                <svg
                  className='w-6 h-6'
                  aria-hidden='true'
                  fill='black'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z'></path>
                </svg>
              </button>
            }
            menu={<DropdownMenu key={el.date.toString()} menuItems={visibleMenuItems} headerTitle={'Self-Tour'} />}
          />

          <CancelTourModal
            onClose={() => setCancelTourModalOpen(false)}
            open={cancelTourModalOpen}
            onOk={async () => {
              await el.actions.onCancel();
              setCancelTourModalOpen(false);
            }}
          />

          <div className='w-full h-40 flex-shrink-0 mr-3  rounded-t-lg overflow-hidden'>
            <img src={imageSrc} alt='property' className='w-full h-full object-cover' />
          </div>

          <div className='absolute top-2 left-2'>
            <TourStatus tourStatus={el.status.tourStatus} />
          </div>

          <div className='p-3'>
            <div className='flex justify-between mb-2'>
              <div className='price flex justify-start items-center'>
                <p className='text-primaryDark text-lg font-bold'>${formatPriceValue(el.property.price)}</p>
                <p className='text-sm font-normal text-trueGray ml-[5px]'>/mo</p>
              </div>
            </div>
            <div className='font-bold mb-2'>{getDayjs(el.date).format('MMMM D, hh:mm A').toString()}</div>
            <div className='text'>{el.property.address}</div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <div className='grow overflow-x-auto p-4 md:px-[34px] md:py-[28px] shadow-white-xl'>
      <div className='lg:mb-9'>
        <ToursTitle title={'Upcoming Tours'} />
      </div>
      <div className='flex flex-col gap-9'>
        {!isNextTourDetailsLoading && !!nextTourDetails && (
          <TourCard
            onFinishTour={() => {
              refetchUpcomingTours();
              refetchNearestTodayTour();
            }}
            isIncompleteProfile={false}
            card={fromRentTourDtoToEstateCard(nearestTodayTour as TourDto, () => null)}
            estateInfo={{
              city: (nearestTodayTour as TourDto).city,
              location: (nearestTodayTour as TourDto).location,
              price: (nearestTodayTour as TourDto).price,
              category: (nearestTodayTour as TourDto).category,
              beds: (nearestTodayTour as TourDto).beds,
              bathrooms: (nearestTodayTour as TourDto).bathrooms,
              propertyArea: (nearestTodayTour as TourDto).propertyArea,
              type: (nearestTodayTour as TourDto).type,
              thumbnailUrl: (nearestTodayTour as TourDto).thumbnailUrl,
            }}
            startTourDate={(nearestTodayTour as TourDto).slotUtc}
            tour={nextTourDetails}
            actions={tourCardActions}
            tourStatus={(nearestTodayTour as TourDto).tourStatus}
          />
        )}

        {isMobile ? (
          tableData.length ? (
            <div>
              <div className='flex flex-col gap-4 mb-4 '>{tableData.map((el, index) => propertyCard(el, index))}</div>
              <TablePagination {...tablePaginationProps} />
            </div>
          ) : (
            <div>
              Currently, you don't have any upcoming tours scheduled. Don't worry, you can still explore our listings
              and schedule a self tour to find your perfect rental!
            </div>
          )
        ) : (
          <UpcomingToursTable
            setQuery={setQuery}
            paginationProps={tablePaginationProps}
            isLoading={
              isUpcomingToursLoading ||
              isUpcomingToursFetching ||
              isCancelingTourPending ||
              isStartingTourPending ||
              isReschedulingTourPending
            }
            data={tableData}
            order={query.order}
          />
        )}
      </div>
      {tourToReschedule && (
        <RescheduleTourModal
          tour={tourToReschedule}
          onClose={() => {
            setRescheduleTourModalOpen(false);
            setTourIdToReschedule('');
          }}
          open={rescheduleTourModalOpen}
          onConfirm={async (nextDate) => {
            rescheduleTour({ id: tourIdToReschedule, slot: nextDate });
          }}
        />
      )}
      <MakeOfferModal
        open={isMakeOfferOpen}
        unitId={makeOfferTour?.unitId || ''}
        estateId={makeOfferTour?.estateId || ''}
        availableOn={makeOfferTourEstateDetails?.units.find((u) => u.id === makeOfferTour?.unitId)?.availableOn}
        onClose={handleCloseMakeOfferModal}
        onSubmit={handleCloseMakeOfferModal}
        address={
          (makeOfferTour?.estateType === EstateTypeDto.MultiUnit
            ? `${makeOfferTour.location}. Unit - ${makeOfferTour?.unitNumber}`
            : makeOfferTour?.location) || ''
        }
      />
      <CollectKeyModal
        isAvailableToStartTour={isAvailableToStartTour}
        tour={nextTourDetails || null}
        keyInfo={{
          id: keyInfo?.id || '',
          type: keyInfo?.type || '',
          description: keyInfo?.description || '',
          images: keyInfo?.files.map((file) => file.thumbnailUrl) || [],
        }}
        onStartTour={startTour}
        open={isCollectKeyModalOpen}
        onClose={handleCloseCollectKeyModal}
      />
    </div>
  );
};
export { UpcomingTours };
