import axios, { AxiosError, AxiosInstance, isAxiosError, isCancel } from 'axios';
import { AuthApiPath, ENV, StorageKey } from 'common/enums/enums';
import { notification, storage } from 'services/services';
import { logout, renewToken } from './auth-api.service';

export const axiosInstance = axios.create({ baseURL: ENV.API_PATH });

export const axiosAppOriginInstance = axios.create({ baseURL: ENV.APP_ORIGIN_PATH });

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = storage.getItem(StorageKey.ACCESS_TOKEN);
    if (accessToken) {
      config.headers.setAuthorization(`Bearer ${accessToken}`);
    }
    return config;
  },
  (error) => Promise.reject(error),
);

let refreshingFunc: undefined | ReturnType<typeof renewToken> = undefined;

function isUnauthorizedError(error: AxiosError) {
  return error.response?.status === 401;
}

function isBusinessRuleError(error: AxiosError) {
  return error.response?.status === 409;
}

function isResourceNotFoundError(error: AxiosError) {
  return error.response?.status === 404;
}

function isForbiddenError(error: AxiosError) {
  return error.response?.status === 403;
}

function isInvalidRefreshTokenError(error: AxiosError) {
  const { response } = error;
  if (!response) return false;
  const { data, status } = response;
  return (
    status === 400 && typeof data === 'object' && data !== null && 'error' in data && data.error === 'invalid_grant'
  );
}

async function tryToMakeRequestAgain(error: AxiosError) {
  const refreshToken = storage.getItem(StorageKey.REFRESH_TOKEN);
  if (refreshToken) {
    return tryToMakeRequestAgainWithNewRefreshToken(error, refreshToken);
  } else {
    if (!(error.config?.url === AuthApiPath.CURRENT_USER && error.config?.method === 'get')) {
      logout();
    }
    return Promise.reject(error);
  }
}

async function tryToMakeRequestAgainWithNewRefreshToken(error: AxiosError, refreshToken: string) {
  const originalRequest = error.config;
  if (!originalRequest) return Promise.reject(error);
  try {
    if (!refreshingFunc) {
      refreshingFunc = renewToken(refreshToken);
    }
    const { access_token, refresh_token } = await refreshingFunc;
    storage.setItem(StorageKey.ACCESS_TOKEN, access_token);
    storage.setItem(StorageKey.REFRESH_TOKEN, refresh_token);
    originalRequest.headers.Authorization = `Bearer ${access_token}`;
    const response = await axios.request(originalRequest);
    return response.data;
  } catch (e) {
    if (isAxiosError(e) && (isUnauthorizedError(e) || isInvalidRefreshTokenError(e))) {
      logout();
      return Promise.reject(error);
    }
    return Promise.reject(e);
  } finally {
    refreshingFunc = undefined;
  }
}

axiosInstance.interceptors.response.use(
  async (res) => res.data,
  async (error) => {
    if (isAxiosError(error) && isUnauthorizedError(error)) {
      return tryToMakeRequestAgain(error);
    }

    if (!isBusinessRuleError(error)
       && !isResourceNotFoundError(error)
       && !isCancel(error)
       && !isForbiddenError(error)) {
        notification.error('Oops! Something went wrong...');
    }
    
    return Promise.reject(error);
  },
);

axiosAppOriginInstance.interceptors.response.use(
  async (res) => res.data,
  (error) => Promise.reject(error),
);

export const axiosInstanceWithHeaders = (token: string): AxiosInstance => {
  const axiosInstanceWithHeaders = axios.create({ baseURL: ENV.API_PATH });
  axiosInstanceWithHeaders.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return axiosInstanceWithHeaders;
};
