import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { rejectEstate } from 'services/api/estates-api.service';
import { QueryKey } from 'common/enums/query-key';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';

export default function useRejectEstate(
  options?: Omit<
    UseMutationOptions<void, AxiosError<BackendError>, { estateId: string; reason: string }, unknown>,
    'mutationFn'
  >,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: rejectEstate,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Estates, 'admin'] });
    },
    ...options,
  });
}
