import React, { useRef, useState } from 'react';
import { FormikErrors, FormikProps, useFormikContext } from 'formik';
import { Button, FormDescription } from 'legacy-components/componets';
import { joinValues } from 'helpers/string.helpers';
import { PropertyValues } from 'legacy-pages/landlord/properties/landlord-properties';
import { ImageResponse, LocalImage } from 'common/mappers/property';
import { useParams } from 'react-router-dom';
import { PropertyBuildingAccessValues } from '../property-building-access/property-building-access';
import { useIsMutating } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import GeneralPropertyAmenities from './GeneralPropertyAmenities';
import PropertyVideo from './PropertyVideo';
import WarningMoveInFeesModal from './WarningMoveInFeesModal';
import GeneralPropertyInfo from './GeneralPropertyInfo';
import PropertyPhotos from './PropertyPhotos';
import PropertyUnit from './PropertyUnit';
import { PropertyBuildingAvailabilityValues } from '../property-building-availability/property-building-availability.types';
import { getDeepObjectEntries } from 'helpers/get-deep-object-entries';
import { isEmpty } from 'lodash-es';
import { openNotificationModal } from 'store/use-notification-modal-store';
import { isAxiosError } from 'axios';
import { NotificationType } from 'common/enums/services/notification/notification-type.enum';
import { scrollTopTopElement } from 'helpers/scroll-to-top-element';
import delay from 'helpers/delay';

export type PropertyOtherFeeValues = {
  // checked: boolean;
  name: string;
  price: string;
};

export type PropertyFeesValues = {
  checked: boolean;
  price: string;
};

export type PropertyUnitValues = {
  id?: string;
  unitNumber: string;
  beds: number;
  baths: number;
  squareFeet: string;
  leaseDuration: number;
  availableOn: Date | string;
  rent: string;
  deposit: string;
  description: string;

  firstMonth: string;
  lastMonth: string;
  securityDeposit: PropertyFeesValues;
  otherFeestable: PropertyOtherFeeValues[];
  amenities: PropertyAmenity;
  access: PropertyBuildingAccessValues;
  availability: PropertyBuildingAvailabilityValues;
};

export type PropertyAmenity = Record<string, boolean>;

export type PropertyDetailValues = {
  units: PropertyUnitValues[];
  amenities: PropertyAmenity;
  generalDescription: string;
  images: ImageResponse[] | LocalImage[];
  videos: File[];
};

export type PropertyDetailProps = Pick<
  FormikProps<PropertyValues>,
  'values' | 'setFieldValue' | 'errors' | 'touched' | 'submitForm'
> & {
  isMultiUnit: boolean;
  stepTitle?: string;
  propertyId: string;
};

export const PropertyDetail = ({
  isMultiUnit,
  stepTitle,
  values,
  errors,
  touched,
  submitForm,
  propertyId,
}: PropertyDetailProps) => {
  const { id } = useParams();
  const [expandedIds, setExpandedIds] = React.useState<number[]>([]);
  const isLoading = useIsMutating({ mutationKey: [QueryKey.PropertyDetails] }) > 0;
  const errorsRef = useRef<FormikErrors<PropertyValues>>({});
  const { validateField, setFieldValue } = useFormikContext<PropertyValues>();
  errorsRef.current = errors;

  // Method to scroll to the top of the first error input element on the page
  // Note: this method requires that the error input element has a name attribute that matches the error key in the errors object
  const scrollToTopErrorElement = async () => {
    // wait a bit to see if there are any errors because errors aren't available immediately after submitForm is called
    await delay(100);
    const detailsErrors = errorsRef.current.details;
    if (!detailsErrors) return;
    if (Array.isArray(detailsErrors.units)) {
      detailsErrors.units.forEach((unitErrors, index) => {
        if (!unitErrors) return;
        expandUnit(index);
      });
    }
    const entries = getDeepObjectEntries(detailsErrors);
    const keys = entries.map(([key]) => key);
    const elements = keys
      .map((key) => document.querySelector(`[name="details.${key}"]`))
      .filter((element) => !!element) as HTMLElement[];
    scrollTopTopElement(elements);
  };

  const setFieldValueWithOnlyFieldValidation = async (fieldName: string, value: any) => {
    await setFieldValue(fieldName, value, false);
    validateField(fieldName);
  };

  const handleExpand = (id: number) => {
    setExpandedIds((prevExpandedIds) => {
      const isExpanded = prevExpandedIds.includes(id);
      return isExpanded ? prevExpandedIds.filter((expandId) => expandId !== id) : [...prevExpandedIds, id];
    });
  };

  const expandUnit = (id: number) => {
    setExpandedIds((prevExpandedIds) => {
      const isExpanded = prevExpandedIds.includes(id);
      return isExpanded ? prevExpandedIds : [...prevExpandedIds, id];
    });
  };

  const handleCollapseAll = () => setExpandedIds([]);

  const handleExpandAll = () => {
    const allUnitIndexes = values.details.units.map((_, index) => index);
    setExpandedIds(allUnitIndexes);
  };

  const unitsFormTitle = isMultiUnit ? 'Multi-unit property' : 'Single-unit property';

  const propertyDetailTitle = (() => {
    return (
      <div className='title flex flex-col gap-3'>
        {stepTitle && <div className='text-primaryDark uppercase font-semibold'>{stepTitle}</div>}
        <div className='title text-xl font-medium'>Property details</div>
        <div className='description text-trueGray'>Please enter your property details.</div>
      </div>
    );
  })();

  const [warningMoveInFeesModalOpen, setWarningMoveInFeesModalOpen] = useState(false);
  const feesSectionRef = useRef<HTMLDivElement | null>(null);

  const handleWarningMoveInFeesModalOpen = async () => {
    setWarningMoveInFeesModalOpen(true);
  };

  const handleWarningMoveInFeesModalClose = () => {
    setWarningMoveInFeesModalOpen(false);
  };

  const handleWarningMoveInFeesModalRecheckMoveInFees = () => {
    setWarningMoveInFeesModalOpen(false);
    expandUnit(0);
    setTimeout(() => {
      feesSectionRef.current?.scrollIntoView({ block: 'center', inline: 'center' });
    });
  };

  const warningMoveInFeesModalSubmitForm = async () => {
    try {
      await submitForm();
    } catch (e) {
      // wait a bit to see if there are any errors because errors aren't available immediately after submitForm is called
      await delay(100);
      if (isEmpty(errorsRef.current.details)) {
        if (!isAxiosError(e)) return;
        openNotificationModal({
          type: NotificationType.ERROR,
          title: e?.response?.data?.detail ?? 'Something went wrong',
        });
      }
    } finally {
      setWarningMoveInFeesModalOpen(false);
      scrollToTopErrorElement();
    }
  };

  return (
    <>
      <WarningMoveInFeesModal
        onSecondary={handleWarningMoveInFeesModalRecheckMoveInFees}
        open={warningMoveInFeesModalOpen}
        isLoading={isLoading}
        onClose={handleWarningMoveInFeesModalClose}
        onSubmit={warningMoveInFeesModalSubmitForm}
      />
      <div className='property-detail flex flex-col gap-9'>
        {propertyDetailTitle}

        <div className='flex flex-col gap-9'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-2.5'>
              <FormDescription title={unitsFormTitle} />

              {isMultiUnit && (
                <div className='text-sm font-semibold text-primary text-center'>
                  {joinValues([values.details.units.length, 'units'], ' ')}
                </div>
              )}
            </div>

            {isMultiUnit && (
              <div className='flex xs:flex-col sm:flex-row gap-4'>
                <div
                  onClick={handleCollapseAll}
                  className='text-sm font-semibold text-primary  cursor-pointer text-center'
                >
                  Collapse all
                </div>
                <div
                  onClick={handleExpandAll}
                  className='text-sm font-semibold text-primary  cursor-pointer text-center'
                >
                  Expand all
                </div>
              </div>
            )}
          </div>

          <div className='flex flex-col gap-9'>
            {values.details.units.map((unit, index) => (
              <PropertyUnit
                key={index}
                index={index}
                unit={unit}
                isMultiUnit={isMultiUnit}
                error={errors.details?.units?.[index] as FormikErrors<PropertyUnitValues>}
                touched={touched.details?.units?.[index]}
                isExpanded={expandedIds.includes(index)}
                feesSectionRef={index === 0 ? feesSectionRef : undefined}
                onExpand={() => handleExpand(index)}
              />
            ))}
          </div>
          <GeneralPropertyInfo
            isEdit={false}
            isMultiUnit={isMultiUnit}
            generalDescription={values.details.generalDescription}
            setGeneralDescription={(value) => setFieldValueWithOnlyFieldValidation('details.generalDescription', value)}
          />
          <PropertyPhotos
            isEdit={false}
            images={values.details.images}
            setImages={(images) => setFieldValueWithOnlyFieldValidation('details.images', images)}
            estateId={id ?? propertyId}
            touched={touched.details?.images}
            errors={errors.details?.images}
          />
          <PropertyVideo
            videos={values.details.videos}
            setVideos={(videos) => setFieldValueWithOnlyFieldValidation('details.videos', videos)}
          />
          <GeneralPropertyAmenities isMultiUnit={isMultiUnit} />
        </div>
        <div className='button-wrapper flex justify-end'>
          <Button
            type={'submit'}
            label={'Save and continue'}
            theme={'primary'}
            onClick={handleWarningMoveInFeesModalOpen}
            stylesConfig={{ width: 200, borderRadius: 8 }}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
