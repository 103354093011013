import { FC } from 'react';
import { List, Tag, Typography } from 'antd';
import { AdminFeedbackStatus } from 'components/atoms/AdminFeedbackStatus/AdminFeedbackStatus';
import { ContactUsFormDto } from 'common/generated/types/contact-us-form-dto';
import { AdminFeedbacksListItemActions } from './AdminFeedbacksListItemActions';
import styles from './style.module.css';
import { dateFormatMMMM_DD_YYYY_HH_MM_A } from 'helpers/date';

type Props = {
  data: ContactUsFormDto;
  hasFullMessage?: boolean;
  actions?: {
    onViewMore?: () => void;
    onSetDone: () => void;
    onSetInProgress: () => void;
    onSetNew: () => void;
  };
};

const AdminFeedbacksListItem: FC<Props> = ({ data, actions, hasFullMessage }) => {
  const isMessageOverflow = Number(data.message?.length) > 100;

  return (
    <List.Item
      className={styles.listItem}
      style={{
        listStyle: 'none',
      }}
    >
      <List.Item.Meta
        style={{
          marginBlockEnd: '4px',
        }}
        title={
          <div className='flex w-full items-center justify-between'>
            <div className='flex items-center gap-2'>
              <div className='flex items-center gap-2'>
                <span className='font-semibold'>
                  {data.firstName} {data.lastName}
                </span>
                <Tag>{data.profile === 'General' ? 'Other' : data.profile}</Tag>
              </div>
              {data.status && <AdminFeedbackStatus status={data.status} />}
            </div>
            {data.status && actions && (
              <AdminFeedbacksListItemActions actions={actions} status={data.status} isViewMore={true} />
            )}
          </div>
        }
        description={
          <div className='text-black break-words w-11/12'>
            {/* <div className='text-base'>{data.title}</div> */}
            <div className='text-sm'>
              {isMessageOverflow && !hasFullMessage ? `${data.message?.slice(0, 80)}...` : data.message}
            </div>
          </div>
        }
      />
      <div className='flex gap-3'>
        <Typography.Text copyable={{ tooltips: false, text: `${data.phoneNumber}` }}>
          <span className='text-trueGray font-normal text-sm text-ellipsis overflow-hidden'>{data.phoneNumber}</span>
        </Typography.Text>
        <Typography.Text copyable={{ tooltips: false, text: `${data.email}` }}>
          <span className='text-trueGray font-normal text-sm text-ellipsis overflow-hidden'>{data.email}</span>
        </Typography.Text>
      </div>
      <Typography.Text>
        <span className='text-trueGray font-semibold text-sm text-ellipsis overflow-hidden'>{'Sent at: '}</span>
        <span className='text-trueGray font-normal text-sm text-ellipsis overflow-hidden'>
          {dateFormatMMMM_DD_YYYY_HH_MM_A(data?.createdAt)}
        </span>
      </Typography.Text>
    </List.Item>
  );
};

export { AdminFeedbacksListItem };
