import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getAdminEstates } from 'services/api/estates-api.service';
import { EstateFiltersRequestDto } from 'common/types/services/api/estates/estate-filters-request-dto';
import { EstateListForApprovaldDto } from 'common/types/services/api/estates/estate-list-dto.type';
import { QueryKey } from 'common/enums/query-key';

export default function useAdminEstates(
  params: EstateFiltersRequestDto,
  options?: Omit<
    UndefinedInitialDataOptions<
      EstateListForApprovaldDto,
      Error,
      EstateListForApprovaldDto,
      (string | EstateFiltersRequestDto)[]
    >,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.Estates, 'admin', params],
    queryFn: () => getAdminEstates(params),
    ...options,
  });
}
