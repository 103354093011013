import React, { FC } from 'react';
import { Icon } from 'legacy-components/componets';
import { EllipsisOutlined } from '@ant-design/icons';
import Dropdown from 'rc-dropdown';
import { ContactUsFormDtoStatusEnum } from 'common/generated/types/contact-us-form-dto';

type Props = {
  status: ContactUsFormDtoStatusEnum;
  isViewMore?: boolean;
  actions: {
    onViewMore?: () => void;
    onSetDone: () => void;
    onSetInProgress: () => void;
    onSetNew: () => void;
  };
};

export const AdminFeedbacksListItemActions: FC<Props> = ({ isViewMore, status, actions }) => {
  const options: {
    label: string;
    icon?: React.JSX.Element;
    onClick: () => void;
    visible: boolean;
  }[] = [
    {
      label: 'View more',
      icon: <Icon name={'faEye'} width={'1em'} height={'1em'} />,
      onClick: () => actions.onViewMore?.(),
      visible: Boolean(isViewMore) && Boolean(actions.onViewMore),
    },
    {
      label: 'Mark as Done',
      icon: <Icon name={'check'} width={'1em'} height={'1em'} />,
      onClick: () => actions.onSetDone(),
      visible: status === ContactUsFormDtoStatusEnum.InProgress || status === ContactUsFormDtoStatusEnum.New,
    },
    {
      label: 'Mark as In Progress',
      icon: <Icon name={'clock'} width={'1em'} height={'1em'} />,
      onClick: () => actions.onSetInProgress(),
      visible: status === ContactUsFormDtoStatusEnum.New,
    },
    {
      label: 'Mark as New',
      icon: <Icon name={'repeat'} width={'1em'} height={'1em'} />,
      onClick: () => actions.onSetNew(),
      visible: status === ContactUsFormDtoStatusEnum.InProgress || status === ContactUsFormDtoStatusEnum.Done,
    },
  ].filter((opt) => opt.visible);

  const overlay = (
    <div
      className={
        'w-[250px] py-6 bg-white rounded-2xl shadow border border-zinc-100 flex-col justify-start items-start flex gap-3'
      }
    >
      {options.map((o) => (
        <button
          type={'button'}
          key={o.label}
          className={
            'w-full px-6 py-4 hover:bg-sky-50 border-r border-transparent hover:border-sky-300 justify-start items-center gap-2.5 flex hover:text-sky-600'
          }
          onClick={o.onClick}
        >
          {o.icon && React.cloneElement(o.icon, { className: 'text-[16px]' })}
          <span className={'text-base font-normal leading-tight'}>{o.label}</span>
        </button>
      ))}
    </div>
  );

  return (
    <Dropdown trigger={['click']} overlay={overlay} placement={'bottomRight'}>
      <button type={'button'}>
        <EllipsisOutlined className={'text-[24px]'} />
      </button>
    </Dropdown>
  );
};
